import React, { Component } from 'react'
import '../projects.sass'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Steps } from 'antd'
import ProjectStatusStepDescription from './project-status-step-description'
import moment from 'moment'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'

const { Step } = Steps

const CONSUMER_PROJECT_STATE = {
	ALLOCATE_PILOT: 0,
	ALLOTTED: 1,
	IN_FLIGHT: 2,
	COMPLETED: 3,
	DATA_REJECTED_FINAL: 4,
	RESOLVED: 5,
}
const PILOT_PROJECT_STATE = { ALLOTTED: 0, IN_FLIGHT: 1, COMPLETED: 2, DATA_REJECTED_FINAL: 3, RESOLVED: 4 }

export default class ProjectStatusSteps extends Component {
	constructor(props) {
		super(props)
		this.state = {
			consumerTimelineDescription: [
				{ state: 'ALLOCATE_PILOT', des: '' },
				{ state: 'ALLOTTED', des: '' },
				{ state: 'IN_FLIGHT', des: '' },
				{ state: 'COMPLETED', des: '' },
				{ state: 'DATA_REJECTED_FINAL', des: '' },
				{ state: 'RESOLVED', des: '' },
			],
			pilotTimelineDescription: [
				{ state: 'ALLOTTED', des: '' },
				{ state: 'IN_FLIGHT', des: '' },
				{ state: 'COMPLETED', des: '' },
				{ state: 'DATA_REJECTED_FINAL', des: '' },
				{ state: 'RESOLVED', des: '' },
			],
		}
	}
	componentDidMount() {
		const { profileType, timeline } = this.props
		let { consumerTimelineDescription, pilotTimelineDescription } = this.state
		if (profileType === 'CONSUMER') {
			for (let index = 0; index < consumerTimelineDescription.length; index++) {
				for (let j = 0; j < timeline.length; j++) {
					if (consumerTimelineDescription[index].state === timeline[j].state) {
						consumerTimelineDescription[index].des = moment
							.utc(timeline[j].timestamp)
							.local()
							.format('DD/MM/YYYY hh:mm A')
					}
				}
			}
			this.setState({ consumerTimelineDescription: consumerTimelineDescription })
		} else {
			for (let index = 0; index < pilotTimelineDescription.length; index++) {
				for (let j = 0; j < timeline.length; j++) {
					if (pilotTimelineDescription[index].state === timeline[j].state) {
						pilotTimelineDescription[index].des = moment
							.utc(timeline[j].timestamp)
							.local()
							.format('DD/MM/YYYY hh:mm A')
					}
				}
			}
			this.setState({ pilotTimelineDescription: pilotTimelineDescription })
		}
	}

	renderStepIcon = stepNumber => {
		const { data, profileType } = this.props
		if (profileType === 'CONSUMER') {
			if (stepNumber === CONSUMER_PROJECT_STATE[data.state]) {
				if (CONSUMER_PROJECT_STATE[data.state] === 5) {
					return <MatImage alt='completed-icon' src={IMAGES_SRC.COMPLETED_STEP_ICON} />
				} else {
					return <MatImage alt='current-icon' src={IMAGES_SRC.CURRENT_STEP_ICON} />
				}
			} else if (stepNumber < CONSUMER_PROJECT_STATE[data.state]) {
				return <MatImage alt='completed-icon' src={IMAGES_SRC.COMPLETED_STEP_ICON} />
			} else if (stepNumber > CONSUMER_PROJECT_STATE[data.state]) {
				return <MatImage style={{ padding: '9px' }} src={IMAGES_SRC.NEXT_STEP_DOT_ICON} />
			}
		} else {
			if (stepNumber === PILOT_PROJECT_STATE[data.state]) {
				if (PILOT_PROJECT_STATE[data.state] === 4) {
					return <MatImage alt='completed-icon' src={IMAGES_SRC.COMPLETED_STEP_ICON} />
				} else {
					return <MatImage alt='current-icon' src={IMAGES_SRC.CURRENT_STEP_ICON} />
				}
			} else if (stepNumber < PILOT_PROJECT_STATE[data.state]) {
				return <MatImage alt='completed-icon' src={IMAGES_SRC.COMPLETED_STEP_ICON} />
			} else if (stepNumber > PILOT_PROJECT_STATE[data.state]) {
				return <MatImage style={{ padding: '9px' }} src={IMAGES_SRC.NEXT_STEP_DOT_ICON} />
			}
		}
	}

	render() {
		const { data, profileType } = this.props
		const { consumerTimelineDescription, pilotTimelineDescription } = this.state
		return (
			<>
				{profileType === 'CONSUMER' ? (
					<Steps responsive labelPlacement='vertical' size='small' current={CONSUMER_PROJECT_STATE[data.state]}>
						<Step
							title='Open for bidding'
							icon={this.renderStepIcon(0)}
							description={
								<ProjectStatusStepDescription
									statusTime={consumerTimelineDescription}
									statusName={'ALLOCATE_PILOT'}
									currentState={data.state}
									statusIndex={0}
									profileType={'CONSUMER'}
									{...this.props}
								/>
							}
						/>
						<Step
							title='Bid Accepted'
							icon={this.renderStepIcon(1)}
							description={
								<ProjectStatusStepDescription
									statusTime={consumerTimelineDescription}
									statusName={'ALLOTTED'}
									currentState={data.state}
									statusIndex={1}
									profileType={'CONSUMER'}
									{...this.props}
								/>
							}
						/>
						<Step
							title='In Flight'
							icon={this.renderStepIcon(2)}
							description={
								<ProjectStatusStepDescription
									statusTime={consumerTimelineDescription}
									statusName={'IN_FLIGHT'}
									currentState={data.state}
									statusIndex={2}
									profileType={'CONSUMER'}
									{...this.props}
								/>
							}
						/>
						<Step
							title='Data Delivered'
							icon={this.renderStepIcon(3)}
							description={
								<ProjectStatusStepDescription
									statusTime={consumerTimelineDescription}
									statusName={'COMPLETED'}
									currentState={data.state}
									statusIndex={3}
									profileType={'CONSUMER'}
									{...this.props}
								/>
							}
						/>
						{data.state === 'DATA_REJECTED_FINAL' ? (
							<Step
								title='Data Rejected'
								icon={<CloseCircleOutlined style={{ color: '#FA541C' }} />}
								description={
									<ProjectStatusStepDescription
										statusTime={consumerTimelineDescription}
										statusName={'DATA_REJECTED_FINAL'}
										currentState={data.state}
										statusIndex={4}
										profileType={'CONSUMER'}
										{...this.props}
									/>
								}
							/>
						) : null}
						<Step
							title='Completed'
							icon={this.renderStepIcon(5)}
							description={
								<ProjectStatusStepDescription
									statusTime={consumerTimelineDescription}
									statusName={'RESOLVED'}
									currentState={data.state}
									statusIndex={5}
									profileType={'CONSUMER'}
									{...this.props}
								/>
							}
						/>
					</Steps>
				) : (
					<Steps responsive labelPlacement='vertical' size='small' current={PILOT_PROJECT_STATE[data.state]}>
						<Step
							title={data.enterprise_project ? 'Project Allocated' : 'Bid Accepted'}
							icon={this.renderStepIcon(0)}
							description={
								<ProjectStatusStepDescription
									statusTime={pilotTimelineDescription}
									statusName={'ALLOTTED'}
									currentState={data.state}
									statusIndex={0}
									profileType={'PILOT'}
									{...this.props}
								/>
							}
						/>
						<Step
							title='In Flight'
							icon={this.renderStepIcon(1)}
							description={
								<ProjectStatusStepDescription
									statusTime={pilotTimelineDescription}
									statusName={'IN_FLIGHT'}
									currentState={data.state}
									statusIndex={1}
									profileType={'PILOT'}
									{...this.props}
								/>
							}
						/>
						<Step
							title='Data Delivered'
							icon={this.renderStepIcon(2)}
							description={
								<ProjectStatusStepDescription
									statusTime={pilotTimelineDescription}
									statusName={'COMPLETED'}
									currentState={data.state}
									statusIndex={2}
									profileType={'PILOT'}
									{...this.props}
								/>
							}
						/>
						{data.state === 'DATA_REJECTED_FINAL' ? (
							<Step
								title='Data Rejected'
								icon={<CloseCircleOutlined style={{ color: '#FA541C' }} />}
								description={
									<ProjectStatusStepDescription
										statusTime={pilotTimelineDescription}
										statusName={'DATA_REJECTED_FINAL'}
										currentState={data.state}
										statusIndex={3}
										profileType={'PILOT'}
										{...this.props}
									/>
								}
							/>
						) : null}
						<Step
							title='Completed'
							icon={this.renderStepIcon(4)}
							description={
								<ProjectStatusStepDescription
									statusTime={pilotTimelineDescription}
									statusName={'RESOLVED'}
									currentState={data.state}
									statusIndex={4}
									profileType={'PILOT'}
									{...this.props}
								/>
							}
						/>
					</Steps>
				)}
			</>
		)
	}
}
