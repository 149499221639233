import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { message } from 'antd'
import { verifySharableLink } from '../../Stores/UserSession/action'
import { goTo } from '../../Services/NavigationService'

class ShareReferral extends Component {
	componentDidMount() {
		const referralCode = this.props.match.params.referralCode
		if (referralCode) {
			this.props.verifySharableLink({ referralCode: referralCode })
		} else {
			message.error('No referral code found!')
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.verifySharableLinkData !== nextProps.verifySharableLinkData) {
			if (nextProps.verifySharableLinkData) {
				const data = { firstName: nextProps.verifySharableLinkData.firstName, referralCode: nextProps.verifySharableLinkData.referralCode }
				goTo('', data)
			}
		}
	}

	render() {
		return <React.Fragment></React.Fragment>
	}
}

const mapStateToProps = state => {
	return {
		verifySharableLinkData: state.UserSessionReducer.verifySharableLinkData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		verifySharableLink: bindActionCreators(verifySharableLink, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ShareReferral)
