/*eslint-disable*/
import React from 'react'
import './on-boarding.sass'
import { Statistic, message } from 'antd'
import { connect } from 'react-redux'
import { getOtp, getWebConfig } from '../../Stores/UserSession/action'
import { bindActionCreators } from 'redux'

const { Countdown } = Statistic

class ResendOtp extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			deadline: Date.now() + 1000 * 60,
			stageState: 1,
			configData: props.webConfig,
		}
	}

	componentDidMount() {
		if (!this.state.configData) {
			this.props.getWebConfig()
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.getOtpData !== nextProps.getOtpData) {
			if (nextProps.getOtpData) {
				this.props.setOtpKey(nextProps.getOtpData)
				this.setState({ stageState: 2, deadline: Date.now() + 1000 * 60 })
			}
		}
		if (this.props.webConfig !== nextProps.webConfig) {
			if (nextProps.webConfig) {
				this.setState({ configData: nextProps.webConfig })
			}
		}
	}

	checkPhoneLength = mobileNumber => {
		const mob = /^[0-9]{1}[0-9]{7,9}$/
		if (mob.test(mobileNumber) === false) {
			message.error('Mobile number invalid!')
			return false
		} else {
			return true
		}
	}

	checkState = () => {
		if (this.state.stageState === 1) {
			const phoneNumber = this.props.getPhoneNumber()
			const lengthCheck = this.checkPhoneLength(phoneNumber)
			if (lengthCheck) {
				this.props.getOtp({ mobileNumber: this.state.configData.isdCode + phoneNumber })
			}
		} else {
			message.error('Please wait for a few more seconds!')
		}
	}

	countdownCheck = () => {
		this.setState({ stageState: 1 })
	}

	render() {
		const { deadline, stageState } = this.state
		return (
			<div onClick={this.checkState} style={{ cursor: 'pointer' }}>
				{stageState === 1 ? 'Get Verification Code' : <Countdown valueStyle={{ fontSize: '14px' }} value={deadline} format='ss' onFinish={this.countdownCheck} />}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		getOtpData: state.UserSessionReducer.getOtpData,
		webConfig: state.UserSessionReducer.webConfig,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getOtp: bindActionCreators(getOtp, dispatch),
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ResendOtp)
