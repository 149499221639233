import moment from 'moment'

function capitalize(input) {
	return input
		.toLowerCase()
		.split(' ')
		.map(s => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ')
}

const timeDiffString = createdAt => {
	const minutes = moment().diff(moment.utc(createdAt).local(), 'minutes')
	const hours = moment().diff(moment.utc(createdAt).local(), 'hours')
	const days = moment().diff(moment.utc(createdAt).local(), 'days')
	const months = moment().diff(moment.utc(createdAt).local(), 'months')
	const years = moment().diff(moment.utc(createdAt).local(), 'years')
	if (minutes <= 60) {
		return `${minutes} minutes`
	} else if (hours <= 48) {
		return `${hours} hours`
	} else if (days <= 31) {
		return `${days} days`
	} else if (months <= 12) {
		return `${months} months`
	} else {
		return `${years} years`
	}
}

const debounce = (fn, timeout = 1000) => {
	let timer
	return (...args) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			fn.apply(this, args)
		}, timeout)
	}
}

const textShortner = (text, chars) => (text.length <= chars ? text : text.substring(0, chars) + '...')

const validateMobileNumber = (rule, value, callback) => {
	const mob = /^[0-9]{1}[0-9]{7,9}$/
	if (mob.test(value) === false) {
		callback('Invalid mobile number!')
	} else {
		callback()
	}
}

const validateEmailAddress = (rule, value, callback) => {
	const regForEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (value && value.match(regForEmail)) {
		callback()
	} else {
		callback('Please ensure the email address is in correct format!')
	}
}

const urlConfig = () => {
	const baseUrl = window.location.origin
	const env = process.env.REACT_APP_ENV
	let currentUrl = null
	switch (env) {
		case 'develop':
			currentUrl = {
				'http://localhost:3000': { countryId: 1, redirection: '', appleUrl: 'v1/forwardLogin/APPLE/', currency: 'AUD' },
				'https://aus-dev.aerologix.com': { countryId: 1, redirection: '', appleUrl: 'v1/forwardLogin/APPLE/', currency: 'AUD' },
				'https://nz-dev.aerologix.com': { countryId: 160, redirection: 'NZ', appleUrl: 'v1/forwardLogin/APPLE/NZ/', currency: 'NZD' },
				'https://www.aus-dev.aerologix.com': { countryId: 1, redirection: '', appleUrl: 'v1/forwardLogin/APPLE/', currency: 'AUD' },
				'https://www.nz-dev.aerologix.com': { countryId: 160, redirection: 'NZ', appleUrl: 'v1/forwardLogin/APPLE/NZ/', currency: 'NZD' },
			}
			break
		case 'qa':
			currentUrl = {
				'http://localhost:3000': { countryId: 1, redirection: '', appleUrl: 'v1/forwardLogin/APPLE/', currency: 'AUD' },
				'https://aus-qa.aerologix.com': { countryId: 1, redirection: '', appleUrl: 'v1/forwardLogin/APPLE/', currency: 'AUD' },
				'https://nz-qa.aerologix.com': { countryId: 160, redirection: 'NZ', appleUrl: 'v1/forwardLogin/APPLE/NZ/', currency: 'NZD' },
				'https://www.aus-qa.aerologix.com': { countryId: 1, redirection: '', appleUrl: 'v1/forwardLogin/APPLE/', currency: 'AUD' },
				'https://www.nz-qa.aerologix.com': { countryId: 160, redirection: 'NZ', appleUrl: 'v1/forwardLogin/APPLE/NZ/', currency: 'NZD' },
			}
			break
		case 'preprod':
			currentUrl = {
				'https://aus-preprod.aerologix.com': { countryId: 1, redirection: '', appleUrl: 'v1/forwardLogin/APPLE/', currency: 'AUD' },
				'https://nz-preprod.aerologix.com': { countryId: 160, redirection: 'NZ', appleUrl: 'v1/forwardLogin/APPLE/NZ/', currency: 'NZD' },
				'https://www.aus-preprod.aerologix.com': { countryId: 1, redirection: '', appleUrl: 'v1/forwardLogin/APPLE/', currency: 'AUD' },
				'https://www.nz-preprod.aerologix.com': { countryId: 160, redirection: 'NZ', appleUrl: 'v1/forwardLogin/APPLE/NZ/', currency: 'NZD' },
			}
			break
		case 'production':
			currentUrl = {
				'https://web.aerologix.com': { countryId: 1, redirection: '', appleUrl: 'v1/forwardLogin/APPLE/', currency: 'AUD' },
				'https://nz-web.aerologix.com': { countryId: 160, redirection: 'NZ', appleUrl: 'v1/forwardLogin/APPLE/NZ/', currency: 'NZD' },
				'https://www.web.aerologix.com': { countryId: 1, redirection: '', appleUrl: 'v1/forwardLogin/APPLE/', currency: 'AUD' },
				'https://www.nz-web.aerologix.com': { countryId: 160, redirection: 'NZ', appleUrl: 'v1/forwardLogin/APPLE/NZ/', currency: 'NZD' },
			}
			break
		default:
			return null
	}
	return currentUrl[baseUrl]
}

export { capitalize, timeDiffString, debounce, textShortner, urlConfig, validateMobileNumber, validateEmailAddress }
