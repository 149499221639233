import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateContribution, bulkUpdate } from '../../../../../Stores/Getty/action'

import styles from './Metadata.module.scss'
import moment from 'moment'
import { Form, Input, DatePicker, Alert, Select, Button, message, Tooltip } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'

import { InfoCircleOutlined, WarningOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import SampleDataModal from '../Modals/SampleDataModal'
import CopyMetadataModal from '../Modals/CopyMetadataModal'
import AeroModal from '../../../../../AeroComponents/Modal'

import { BULK_UPDATE_COPY_METADATA } from '../../constants'
import { capitalize } from '../../../../../Services/UtilityService'

const { Option } = Select
const dateFormat = 'DD/MM/YYYY'

function Metadata(props) {
	const [form] = Form.useForm()

	// extract props
	const {
		bulkUpdateSuccess, //state

		contribution,
		contributions,
		dataChanged,
		setDataChanged,
		setshowGettyReasonModal,
		setGettyReasonTitle,
		setGettyReasonContent, // from parent

		updateContribution,
	} = props

	// variables
	const errorText = 'This field is required'

	//state
	const [filename, setFilename] = useState('')
	const [sampleDataModal, setSampledataModal] = useState(false)
	const [allContributions, setAllContributions] = useState(false)
	const [showCopyMetadataModal, setShowCopyMetadataModal] = useState(false)

	const [isFirstRender, setIsFirstRender] = useState(true)

	/* Effects */
	useEffect(() => {
		if (contribution) {
			setFilename(contribution.fileName)
			let keywordsList = []
			for (let keyword of contribution.keywords) {
				keywordsList.push(keyword['term'])
			}
			if (contribution.recordedDate) {
				contribution.recordedDate = contribution.recordedDate
					.split('-')
					.reverse()
					.join('/')
				form.setFieldsValue({ creationdate: moment(contribution.recordedDate, dateFormat) })
			} else {
				form.resetFields(['creationdate'])
			}
			contribution.iptcSubjects ? form.setFieldsValue({ supplemental: contribution.iptcSubjects }) : form.resetFields(['supplemental'])
			form.setFieldsValue({
				headline: contribution.headline,
				description: contribution.caption,
				country: contribution.countryOfShoot,
				city: contribution.city,
				state: contribution.provinceState,
				clip: contribution.clipType,
				category: contribution.iptcCategory,
				speed: contribution.shotSpeed,
				copyright: contribution.copyright,
				keywords: keywordsList,
			})
		} else {
			setFilename('')
		}
	}, [contribution])

	useEffect(() => {
		setAllContributions(contributions)
	}, [contributions])

	useEffect(() => {
		if (!isFirstRender && bulkUpdateSuccess && bulkUpdateSuccess.type === BULK_UPDATE_COPY_METADATA) {
			message.success('Details successfully copied to other videos.')
			setDataChanged({})
		}
	}, [bulkUpdateSuccess])

	useEffect(() => {
		setIsFirstRender(false)
	}, [])

	/* Functions */

	function disabledDate(current) {
		return current && current > moment().endOf('day')
	}

	function onFinish() {
		let final_headline = capitalize(form.getFieldValue('headline'))
		form.setFieldsValue({ headline: final_headline })

		let final_description = form.getFieldValue('description')
		let final_country = form.getFieldValue('country')
		let final_city = form.getFieldValue('city')
		let final_state = form.getFieldValue('state')
		let final_creationdate = form.getFieldValue('creationdate').format(dateFormat)
		let final_clip = form.getFieldValue('clip')
		let final_category = form.getFieldValue('category')
		let final_supplemental = form.getFieldValue('supplemental')
		let final_speed = form.getFieldValue('speed')
		let final_copyright = form.getFieldValue('copyright')
		let final_keywords = []
		for (let keyword of form.getFieldValue('keywords')) {
			if (keyword !== '') {
				final_keywords.push({ term: keyword })
			}
		}
		updateContribution({
			caption: final_description,
			city: final_city,
			clipType: final_clip,
			countryOfShoot: final_country,
			copyright: final_copyright,
			headline: final_headline,
			id: contribution.id,
			iptcCategory: final_category,
			iptcSubjects: final_supplemental,
			keywords: final_keywords,
			provinceState: final_state,
			recordedDate: final_creationdate,
			shotSpeed: final_speed,
			submissionId: contribution.submissionId,
			mixpanel_type: 'file_saved',
		})
			.then(result => {
				message.success({ content: 'Updated successfully', icon: <CheckCircleFilled style={{ position: 'relative', top: '-3px' }} /> })
			})
			.catch(error => {
				message.error('error occured while updatating metadata')
			})
		setDataChanged({})
	}

	function changeData(event, item) {
		let changedData = { ...dataChanged }
		if (['countryOfShoot', 'clipType', 'iptcCategory', 'shotSpeed'].includes(item)) {
			if (event !== contribution[item]) {
				changedData[item] = event
				if (contribution[item] === null && event === '') {
					delete changedData[item]
				}
			} else {
				delete changedData[item]
			}
		} else if ('iptcSubjects' === item) {
			if (contribution[item] == null) {
				if (event.length === 0) {
					delete changedData[item]
				} else {
					changedData[item] = event
				}
			} else {
				if (event.sort().join() !== contribution[item].sort().join()) {
					changedData[item] = event
				} else {
					delete changedData[item]
				}
			}
		} else if ('keywords' === item) {
			let keywordsList = []
			for (let keyword of contribution.keywords) {
				keywordsList.push(keyword['term'])
			}
			if (keywordsList.sort().join() !== event.sort().join()) {
				changedData[item] = event
			} else {
				delete changedData[item]
			}
		} else if ('recordedDate' === item) {
			if (event === null) {
				if (event === contribution[item]) {
					delete changedData[item]
				} else {
					changedData[item] = event
				}
			} else {
				if (event.format(dateFormat) !== contribution[item]) {
					changedData[item] = event
				} else {
					delete changedData[item]
				}
			}
		} else {
			if (event.target.value !== contribution[item]) {
				changedData[item] = event
				if (contribution[item] === null && event.target.value === '') {
					delete changedData[item]
				}
			} else {
				delete changedData[item]
			}
		}

		setDataChanged(changedData)
	}

	function getKeywords() {
		let keywordsList = []
		for (let keyword of contribution.keywords) {
			keywordsList.push(keyword['term'])
		}
		return keywordsList
	}

	function getGettyReasonsDiv() {
		let title = ''
		let reason = ''
		let details = ''
		let notes = ''

		if (contribution) {
			if (contribution.gettyStatus === 'REVISABLE') {
				if (contribution.gettyReason) {
					let reasons = contribution.gettyReason.split('_GETTY_COMMENTS_DELIMITER_')
					title = 'Revision Details'
					reason = reasons[1]
					details = reasons[2]
					notes = reasons[3]
				}
				setGettyReasonTitle(title)
				setGettyReasonContent(details)

				return (
					<div className={styles['getty-reasons-revision']}>
						<div className={styles['getty-reason']}>
							<div>Revision Reason:</div>
							<div>{reason}</div>
						</div>

						{notes ? (
							<>
								{' '}
								<hr />
								<div className={styles['getty-note']}>
									<div>Revision Notes:</div>
									<div>{notes}</div>
								</div>
							</>
						) : (
							''
						)}
					</div>
				)
			} else if (contribution.gettyStatus === 'REJECTED') {
				if (contribution.gettyReason) {
					let reasons = contribution.gettyReason.split('_GETTY_COMMENTS_DELIMITER_')
					title = 'Rejected Details'
					reason = reasons[1]
					details = reasons[2]
					notes = reasons[3]
				}
				setGettyReasonTitle(title)
				setGettyReasonContent(details)

				return (
					<div className={styles['getty-reasons-rejected']}>
						<div className={styles['getty-reason']}>
							<div>Rejection Reason:</div>
							<div>{reason}</div>
						</div>

						{notes ? (
							<>
								{' '}
								<hr />
								<div className={styles['getty-note']}>
									<div>Rejection Notes:</div>
									<div>{notes}</div>
								</div>
							</>
						) : (
							''
						)}
					</div>
				)
			}
		}
	}
	const toggleSampleDataModal = () => {
		setSampledataModal(sampleDataModal => !sampleDataModal)
	}

	function validateFields() {
		let result = true

		result = !form.getFieldValue('headline') ? false : result
		result = !form.getFieldValue('description') ? false : result
		result = !form.getFieldValue('country') ? false : result
		result = !form.getFieldValue('creationdate') ? false : result
		result = !form.getFieldValue('clip') ? false : result
		result = !form.getFieldValue('category') ? false : result
		result = !form.getFieldValue('speed') ? false : result

		result = !form.getFieldValue('supplemental') ? false : form.getFieldValue('supplemental').length === 0 ? false : result
		result = !form.getFieldValue('keywords') ? false : form.getFieldValue('keywords').length === 0 ? false : result

		return result
	}

	function buildDataForCopyMetadata() {
		let requestData = {
			contributions: [],
			submissionId: contribution.submissionId,
			type: BULK_UPDATE_COPY_METADATA,
		}

		requestData.contributions = allContributions.map(contributionItem => {
			if (!contributionItem.isUploading) {
				let tempContribution = { ...contributionItem }

				tempContribution.headline = capitalize(form.getFieldValue('headline'))
				tempContribution.caption = form.getFieldValue('description')
				tempContribution.countryOfShoot = form.getFieldValue('country')
				tempContribution.recordedDate = form.getFieldValue('creationdate').format(dateFormat)
				tempContribution.clipType = form.getFieldValue('clip')
				tempContribution.iptcCategory = form.getFieldValue('category')
				tempContribution.shotSpeed = form.getFieldValue('speed')
				tempContribution.iptcSubjects = form.getFieldValue('supplemental')

				tempContribution['keywords'] = []
				for (let keyword of form.getFieldValue('keywords')) {
					if (keyword !== '') {
						tempContribution['keywords'].push({ term: keyword })
					}
				}

				tempContribution.id = contributionItem.id
				tempContribution.city = form.getFieldValue('city')
				tempContribution.provinceState = form.getFieldValue('state')

				return tempContribution
			}
		})

		return requestData
	}

	function copyMetadata() {
		if (allContributions.length <= 1) {
			message.warning('Cannot copy details as there is only one video.')
			return
		}
		if (validateFields()) {
			setShowCopyMetadataModal(true)
		} else {
			message.warning('You need to fill all details before you can copy to other videos.')
		}
	}

	return (
		<div className={styles['metadata-card-holder']}>
			<div className={styles['metadata-card-header']}>
				Video Details{filename === '' ? '' : ' : ' + filename}
				<div>
					{' '}
					<div className={styles['sample-data']} onClick={toggleSampleDataModal}>
						{' '}
						Sample Data
					</div>{' '}
					<div
						className={styles['invalid-file']}
						style={
							contribution && contribution.gettyStatus && contribution.gettyStatus === 'INVALID_FILE'
								? { visibility: 'visible', height: 'max-content', position: 'relative' }
								: { visibility: 'hidden' }
						}
					>
						Invalid file &nbsp;
						<WarningOutlined style={{ fontSize: '15px', padding: '0px 8px' }} />
					</div>
				</div>
			</div>
			<div className={styles['metadata-card-body']}>
				{contribution && contribution.gettyStatus && (contribution.gettyStatus === 'REVISABLE' || contribution.gettyStatus === 'REJECTED') ? (
					<div
						className={styles['getty-reasons-holder']}
						style={
							contribution && contribution.gettyStatus && contribution.gettyStatus === 'REVISABLE' ? { backgroundColor: '#ee5826' } : { backgroundColor: '#FF4D4F' }
						}
					>
						{getGettyReasonsDiv()}

						<div className={styles['getty-learn-more-holder']}>
							{' '}
							<span className={styles['getty-learn-more']} onClick={() => setshowGettyReasonModal(true)}>
								Learn More{' '}
							</span>
						</div>
					</div>
				) : (
					''
				)}

				{contribution && contribution.status === 'PENDING_FILE_SUBMISSION' && !contribution.isUploading ? (
					<Form name='metadataForm' className='metadataForm' initialValues={{ remember: true }} form={form} onFinish={onFinish} scrollToFirstError={true}>
						Editorial Video Headline <span className={styles['star']}>*</span>
						<Tooltip title='Title of the video'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>{' '}
						<br />
						<Form.Item
							name='headline'
							label=''
							tooltip=''
							rules={[
								{
									required: true,
									message: errorText,
									whitespace: true,
								},
							]}
						>
							<Input placeholder='Aerial Views of Sydney Harbour' className={styles['textbox']} onChange={event => changeData(event, 'headline')} />
						</Form.Item>
						Editorial Video Description <span className={styles['star']}>*</span>
						<Tooltip title='2-3 sentences describing the video including the date and location'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>{' '}
						<br />
						<Form.Item
							name='description'
							label=''
							tooltip=''
							rules={[
								{
									required: true,
									message: errorText,
									whitespace: true,
								},
							]}
						>
							<Input.TextArea placeholder='Type here' row={3} className={styles['textbox']} onChange={event => changeData(event, 'caption')} />
						</Form.Item>
						Country of Shoot <span className={styles['star']}>*</span>
						<Tooltip title='Country where the video was captured'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>{' '}
						<br />
						<Form.Item
							name='country'
							label=''
							tooltip=''
							rules={[
								{
									required: true,
									message: errorText,
									whitespace: true,
								},
							]}
						>
							<Select showSearch placeholder='Australia' onChange={event => changeData(event, 'countryOfShoot')}>
								<Option value='Afghanistan'>Afghanistan</Option>
								<Option value='Aland Islands'>Aland Islands</Option>
								<Option value='Albania'>Albania</Option>
								<Option value='Algeria'>Algeria</Option>
								<Option value='American Samoa'>American Samoa</Option>
								<Option value='Andorra'>Andorra</Option>
								<Option value='Angola'>Angola</Option>
								<Option value='Anguilla'>Anguilla</Option>
								<Option value='Antarctica'>Antarctica</Option>
								<Option value='Antigua and Barbuda'>Antigua and Barbuda</Option>
								<Option value='Argentina'>Argentina</Option>
								<Option value='Armenia'>Armenia</Option>
								<Option value='Aruba'>Aruba</Option>
								<Option value='At Sea'>At Sea</Option>
								<Option value='Australia'>Australia</Option>
								<Option value='Austria'>Austria</Option>
								<Option value='Azerbaijan'>Azerbaijan</Option>

								<Option value='Bahamas'>Bahamas</Option>
								<Option value='Bahrain'>Bahrain</Option>
								<Option value='Bangladesh'>Bangladesh</Option>
								<Option value='Barbados'>Barbados</Option>
								<Option value='Belarus'>Belarus</Option>
								<Option value='Belgium'>Belgium</Option>
								<Option value='Belize'>Belize</Option>
								<Option value='Benin'>Benin</Option>
								<Option value='Bermuda'>Bermuda</Option>
								<Option value='Bhutan'>Bhutan</Option>
								<Option value='Bolivia'>Bolivia</Option>
								<Option value='Bonaire, Sint Eustatius and Saba'>Bonaire, Sint Eustatius and Saba</Option>
								<Option value='Bosnia and Herzegovina'>Bosnia and Herzegovina</Option>
								<Option value='Botswana'>Botswana</Option>
								<Option value='Bouvet Island'>Bouvet Island</Option>
								<Option value='Brazil'>Brazil</Option>
								<Option value='British Indian Ocean Territories'>British Indian Ocean Territories</Option>
								<Option value='British Virgin Islands'>British Virgin Islands</Option>
								<Option value='Brunei Darussalam'>Brunei Darussalam</Option>
								<Option value='Bulgaria'>Bulgaria</Option>
								<Option value='Burkina Faso'>Burkina Faso</Option>
								<Option value='Burma'>Burma</Option>
								<Option value='Burundi'>Burundi</Option>

								<Option value='Cambodia'>Cambodia</Option>
								<Option value='Cameroon'>Cameroon</Option>
								<Option value='Canada'>Canada</Option>
								<Option value='Cape Verde'>Cape Verde</Option>
								<Option value='Cayman Islands'>Cayman Islands</Option>
								<Option value='Central African Republic'>Central African Republic</Option>
								<Option value='Chad'>Chad</Option>
								<Option value='Chile'>Chile</Option>
								<Option value='China'>China</Option>
								<Option value='Christmas Island'>Christmas Island</Option>
								<Option value='Cocos Islands'>Cocos Islands</Option>
								<Option value='Colombia'>Colombia</Option>
								<Option value='Comoros'>Comoros</Option>
								<Option value='Congo'>Congo</Option>
								<Option value='Cook Islands'>Cook Islands</Option>
								<Option value='Costa Rica'>Costa Rica</Option>
								<Option value='Croatia'>Croatia</Option>
								<Option value='Cuba'>Cuba</Option>
								<Option value='Curacao'>Curacao</Option>
								<Option value='Cyprus'>Cyprus</Option>
								<Option value='Czech Republic'>Czech Republic</Option>

								<Option value='Democratic Republic of Congo'>Democratic Republic of Congo</Option>
								<Option value='Denmark'>Denmark</Option>
								<Option value='Djibouti'>Djibouti</Option>
								<Option value='Dominica'>Dominica</Option>
								<Option value='Dominican Republic'>Dominican Republic</Option>

								<Option value='East Timor'>East Timor</Option>
								<Option value='Ecuador'>Ecuador</Option>
								<Option value='Egypt'>Egypt</Option>
								<Option value='El Salvador'>El Salvador</Option>
								<Option value='Equatorial Guinea'>Equatorial Guinea</Option>
								<Option value='Eritrea'>Eritrea</Option>
								<Option value='Estonia'>Estonia</Option>
								<Option value='Ethiopia'>Ethiopia</Option>
								<Option value='Faeroe Islands'>Faeroe Islands</Option>
								<Option value='Falkland Islands'>Falkland Islands</Option>
								<Option value='Federal Republic of Germany'>Federal Republic of Germany</Option>
								<Option value='Fiji'>Fiji</Option>
								<Option value='Finland'>Finland</Option>
								<Option value='France'>France</Option>
								<Option value='French Guiana'>French Guiana</Option>
								<Option value='French Polynesia'>French Polynesia</Option>
								<Option value='French Southern Territories'>French Southern Territories</Option>

								<Option value='Gabon'>Gabon</Option>
								<Option value='Gambia'>Gambia</Option>
								<Option value='Gaza'>Gaza</Option>
								<Option value='Georgia'>Georgia</Option>
								<Option value='German Democratic Republic'>German Democratic Republic</Option>
								<Option value='Germany'>Germany</Option>
								<Option value='Ghana'>Ghana</Option>
								<Option value='Gibraltar'>Gibraltar</Option>
								<Option value='Greece'>Greece</Option>
								<Option value='Greenland'>Greenland</Option>
								<Option value='Grenada'>Grenada</Option>
								<Option value='Guadeloupe'>Guadeloupe</Option>
								<Option value='Guam'>Guam</Option>
								<Option value='Guatemala'>Guatemala</Option>
								<Option value='Guernsey'>Guernsey</Option>
								<Option value='Guinea'>Guinea</Option>
								<Option value='Guinea-Bissau'>Guinea-Bissau</Option>
								<Option value='Guyana'>Guyana</Option>
								<Option value='Haiti'>Haiti</Option>
								<Option value='Heard and Mc Donald Islands'>Heard and Mc Donald Islands</Option>
								<Option value='Honduras'>Honduras</Option>
								<Option value='Hong Kong'>Hong Kong</Option>
								<Option value='Hungary'>Hungary</Option>
								<Option value='Iceland'>Iceland</Option>
								<Option value='In Flight'>In Flight</Option>

								<Option value='In Space'>In Space</Option>
								<Option value='India'>India</Option>
								<Option value='Indonesia'>Indonesia</Option>
								<Option value='Invalid Country'>Invalid Country</Option>
								<Option value='Iran'>Iran</Option>
								<Option value='Iraq'>Iraq</Option>
								<Option value='Ireland'>Ireland</Option>
								<Option value='Isle of Man'>Isle of Man</Option>
								<Option value='Israel'>Israel</Option>
								<Option value='Italy'>Italy</Option>
								<Option value='Ivory Coast'>Ivory Coast</Option>
								<Option value='Jamaica'>Jamaica</Option>

								<Option value='Japan'>Japan</Option>
								<Option value='Jericho'>Jericho</Option>
								<Option value='Jersey'>Jersey</Option>
								<Option value='Jordan'>Jordan</Option>
								<Option value='Kazakhstan'>Kazakhstan</Option>
								<Option value='Kenya'>Kenya</Option>
								<Option value='Kiribati'>Kiribati</Option>
								<Option value='Kosovo'>Kosovo</Option>
								<Option value='Kuwait'>Kuwait</Option>
								<Option value='Kyrgyzstan'>Kyrgyzstan</Option>
								<Option value='Laos'>Laos</Option>
								<Option value='Latvia'>Latvia</Option>
								<Option value='Lebanon'>Lebanon</Option>
								<Option value='Lesotho'>Lesotho</Option>
								<Option value='Liberia'>Liberia</Option>
								<Option value='Libya'>Libya</Option>
								<Option value='Liechtenstein'>Liechtenstein</Option>
								<Option value='Lithuania'>Lithuania</Option>
								<Option value='Luxembourg'>Luxembourg</Option>

								<Option value='Macau'>Macau</Option>
								<Option value='Macedonia'>Macedonia</Option>
								<Option value='Madagascar'>Madagascar</Option>
								<Option value='Malawi'>Malawi</Option>
								<Option value='Malaysia'>Malaysia</Option>
								<Option value='Maldives'>Maldives</Option>
								<Option value='Mali'>Mali</Option>
								<Option value='Malta'>Malta</Option>
								<Option value='Marshall Islands'>Marshall Islands</Option>
								<Option value='Martinique'>Martinique</Option>
								<Option value='Mauritania'>Mauritania</Option>
								<Option value='Mauritius'>Mauritius</Option>
								<Option value='Mayotte'>Mayotte</Option>
								<Option value='Mexico'>Mexico</Option>
								<Option value='Micronesia'>Micronesia</Option>
								<Option value='Moldova'>Moldova</Option>

								<Option value='Monaco'>Monaco</Option>

								<Option value='Mongolia'>Mongolia</Option>
								<Option value='Montenegro'>Montenegro</Option>
								<Option value='Montserrat'>Montserrat</Option>
								<Option value='Morocco'>Morocco</Option>
								<Option value='Mozambique'>Mozambique</Option>
								<Option value='Myanmar'>Myanmar</Option>
								<Option value='Namibia'>Namibia</Option>
								<Option value='Nauru'>Nauru</Option>
								<Option value='Nepal'>Nepal</Option>
								<Option value='Netherlands'>Netherlands</Option>
								<Option value='Netherlands Antilles'>Netherlands Antilles</Option>
								<Option value='Neutral Zone'>Neutral Zone</Option>
								<Option value='New Caledonia'>New Caledonia</Option>
								<Option value='New Zealand'>New Zealand</Option>
								<Option value='Nicaragua'>Nicaragua</Option>
								<Option value='Niger'>Niger</Option>
								<Option value='Nigeria'>Nigeria</Option>
								<Option value='Niue'>Niue</Option>
								<Option value='Norfolk Island'>Norfolk Island</Option>
								<Option value='North Korea'>North Korea</Option>
								<Option value='Northern Mariana Islands'>Northern Mariana Islands</Option>

								<Option value='Norway'>Norway</Option>
								<Option value='Oman'>Oman</Option>
								<Option value='Pakistan'>Pakistan</Option>
								<Option value='Palau'>Palau</Option>
								<Option value='Palestine'>Palestine</Option>
								<Option value='Panama'>Panama</Option>
								<Option value='Papua New Guinea'>Papua New Guinea</Option>
								<Option value='Paraguay'>Paraguay</Option>
								<Option value='Peru'>Peru</Option>
								<Option value='Philippines'>Philippines</Option>
								<Option value='Pitcairn'>Pitcairn</Option>
								<Option value='Poland'>Poland</Option>

								<Option value='Portugal'>Portugal</Option>
								<Option value='Puerto Rico'>Puerto Rico</Option>
								<Option value='Qatar'>Qatar</Option>
								<Option value='Reunion'>Reunion</Option>
								<Option value='Romania'>Romania</Option>
								<Option value='Russia'>Russia</Option>
								<Option value='Rwanda'>Rwanda</Option>
								<Option value='Saint Barthelemy'>Saint Barthelemy</Option>
								<Option value='Saint Helena'>Saint Helena</Option>
								<Option value='Saint Kitts and Nevis'>Saint Kitts and Nevis</Option>
								<Option value='Saint Lucia'>Saint Lucia</Option>
								<Option value='Saint Martin French part'>Saint Martin French part</Option>
								<Option value='Saint Pierre and Miquelon'>Saint Pierre and Miquelon</Option>
								<Option value='Saint Vincent and The Grenadines'>Saint Vincent and The Grenadines</Option>
								<Option value='Samoa'>Samoa</Option>
								<Option value='San Marino'>San Marino</Option>

								<Option value='Sao Tome and Principe'>Sao Tome and Principe</Option>
								<Option value='Saudi Arabia'>Saudi Arabia</Option>
								<Option value='Senegal'>Senegal</Option>
								<Option value='Serbia'>Serbia</Option>
								<Option value='Serbia and Montenegro'>Serbia and Montenegro</Option>
								<Option value='Seychelles'>Seychelles</Option>
								<Option value='Sierra Leone'>Sierra Leone</Option>
								<Option value='Singapore'>Singapore</Option>
								<Option value='Sint Maarten Dutch part'>Sint Maarten Dutch part</Option>
								<Option value='Slovakia'>Slovakia</Option>
								<Option value='Slovenia'>Slovenia</Option>
								<Option value='Solomon Islands'>Solomon Islands</Option>
								<Option value='Somalia'>Somalia</Option>
								<Option value='South Africa'>South Africa</Option>

								<Option value='South Georgia and The South Sandwich Islands'>South Georgia and The South Sandwich Islands</Option>
								<Option value='South Korea'>South Korea</Option>
								<Option value='South Sudan'>South Sudan</Option>
								<Option value='Soviet Union'>Soviet Union</Option>
								<Option value='Spain'>Spain</Option>
								<Option value='Sri Lanka'>Sri Lanka</Option>
								<Option value='Sudan'>Sudan</Option>
								<Option value='Suriname'>Suriname</Option>
								<Option value='Svalbard and Jan Mayen Islands'>Svalbard and Jan Mayen Islands</Option>
								<Option value='Swaziland'>Swaziland</Option>
								<Option value='Sweden'>Sweden</Option>
								<Option value='Switzerland'>Switzerland</Option>

								<Option value='Syria'>Syria</Option>
								<Option value='Taiwan'>Taiwan</Option>
								<Option value='Tajikistan'>Tajikistan</Option>
								<Option value='Tanzania'>Tanzania</Option>
								<Option value='Thailand'>Thailand</Option>
								<Option value='Timor-Leste'>Timor-Leste</Option>
								<Option value='Togo'>Togo</Option>
								<Option value='Tokelau'>Tokelau</Option>
								<Option value='Tonga'>Tonga</Option>
								<Option value='Trinidad And Tobago'>Trinidad And Tobago</Option>
								<Option value='Tunisia'>Tunisia</Option>
								<Option value='Turkey'>Turkey</Option>
								<Option value='Turkmenistan'>Turkmenistan</Option>
								<Option value='Turks and Caicos Islands'>Turks and Caicos Islands</Option>
								<Option value='Tuvalu'>Tuvalu</Option>
								<Option value='US Outlying Islands'>US Outlying Islands</Option>
								<Option value='US Virgin Islands'>US Virgin Islands</Option>
								<Option value='Uganda'>Uganda</Option>
								<Option value='Ukraine'>Ukraine</Option>
								<Option value='United Arab Emirates'>United Arab Emirates</Option>

								<Option value='United Kingdom'>United Kingdom</Option>
								<Option value='United States'>United States</Option>
								<Option value='Unspecified'>Unspecified</Option>
								<Option value='Uruguay'>Uruguay</Option>
								<Option value='Uzbekistan'>Uzbekistan</Option>
								<Option value='Vanuatu'>Vanuatu</Option>
								<Option value='Vatican'>Vatican</Option>
								<Option value='Venezuela'>Venezuela</Option>
								<Option value='Vietnam'>Vietnam</Option>
								<Option value='Wallis and Futuna Islands'>Wallis and Futuna Islands</Option>
								<Option value='West Bank'>West Bank</Option>
								<Option value='Western Sahara'>Western Sahara</Option>
								<Option value='Yemen'>Yemen</Option>
								<Option value='Yugoslavia'>Yugoslavia</Option>
								<Option value='Zaire'>Zaire</Option>
								<Option value='Zambia'>Zambia</Option>
								<Option value='Zimbabwe'>Zimbabwe</Option>
							</Select>
						</Form.Item>
						City {''}
						<Tooltip title='City where the video was captured'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>{' '}
						<br />
						<Form.Item
							name='city'
							label=''
							tooltip=''
							rules={[
								{
									required: false,
									message: errorText,
									whitespace: true,
								},
							]}
						>
							<Input placeholder='Sydney' className={styles['textbox']} onChange={event => changeData(event, 'city')} />
						</Form.Item>
						Province/State <span className={styles['star']}>*</span>
						<Tooltip title='Province/ State where the video was shot'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>
						<br />
						<Form.Item
							name='state'
							label=''
							tooltip=''
							rules={[
								{
									required: true,
									message: errorText,
									whitespace: true,
								},
							]}
						>
							<Input placeholder='NSW' className={styles['textbox']} onChange={event => changeData(event, 'provinceState')} />
						</Form.Item>
						Video Creation Date <span className={styles['star']}>*</span>{' '}
						<Tooltip title='Date when the video was captured'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>
						<br />
						<Form.Item
							name='creationdate'
							label=''
							tooltip=''
							rules={[
								{
									type: 'object',
									required: true,
									message: errorText,
								},
							]}
						>
							<DatePicker
								placeholder='01-02-2022'
								onChange={event => changeData(event, 'recordedDate')}
								disabledDate={disabledDate}
								className={styles['textbox']}
								format={dateFormat}
							/>
						</Form.Item>
						Clip type <span className={styles['star']}>*</span>
						<Tooltip title='Single-shot capture (Raw) or Collage of multiple shots (Produced)'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>{' '}
						<br />
						<Form.Item
							name='clip'
							label=''
							tooltip=''
							rules={[
								{
									required: true,
									message: errorText,
									whitespace: true,
								},
							]}
						>
							<Select placeholder='Raw' onChange={event => changeData(event, 'clipType')}>
								<Option value='PRODUCED'>Produced</Option>
								<Option value='RAW'>Raw</Option>
							</Select>
						</Form.Item>
						Category <span className={styles['star']}>*</span>{' '}
						<Tooltip title='Video category'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>
						<br />
						<Form.Item
							name='category'
							label=''
							tooltip=''
							rules={[
								{
									required: true,
									message: errorText,
									whitespace: true,
								},
							]}
						>
							<Select placeholder='Entertainment' onChange={event => changeData(event, 'iptcCategory')}>
								<Option value='ENTERTAINMENT'>Entertainment</Option>
								<Option value='NON_US_DOMESTIC_NEWS'>Non-US Domestic News</Option>
								<Option value='SPORTS'>Sports</Option>
								<Option value='US_DOMESTIC_NEWS'>US Domestic News</Option>
							</Select>
						</Form.Item>
						Supplemental Categories <span className={styles['star']}>*</span>
						<Tooltip title='Specific categories that relate to the video'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>{' '}
						<br />
						<Form.Item
							name='supplemental'
							label=''
							tooltip=''
							rules={[
								{
									required: true,
									message: errorText,
									whitespace: true,
									type: 'array',
								},
							]}
						>
							<Select placeholder='Cityscape, Nature' mode='multiple' onChange={event => changeData(event, 'iptcSubjects')}>
								<Option data-option-array-index='0' value='2010 Soccer World Cup'>
									{' '}
									2010 Soccer World Cup{' '}
								</Option>

								<Option data-option-array-index='1' value="ABL - Women's Pro Basketball">
									{' '}
									ABL - Women's Pro Basketball{' '}
								</Option>

								<Option data-option-array-index='2' value='Abortion'>
									{' '}
									Abortion{' '}
								</Option>

								<Option data-option-array-index='3' value='Accidents (General)'>
									{' '}
									Accidents (General){' '}
								</Option>

								<Option data-option-array-index='4' value='Acts of Terror'>
									{' '}
									Acts of Terror{' '}
								</Option>

								<Option data-option-array-index='5' value='Adventure Sports'>
									{' '}
									Adventure Sports{' '}
								</Option>

								<Option data-option-array-index='6' value='Aerobic Exercises'>
									{' '}
									Aerobic Exercises{' '}
								</Option>

								<Option data-option-array-index='7' value='Agriculture'>
									{' '}
									Agriculture{' '}
								</Option>

								<Option data-option-array-index='8' value='AIDS'>
									{' '}
									AIDS{' '}
								</Option>

								<Option data-option-array-index='9' value='Aikido'>
									{' '}
									Aikido{' '}
								</Option>

								<Option data-option-array-index='10' value='Airplane Racing'>
									{' '}
									Airplane Racing{' '}
								</Option>

								<Option data-option-array-index='11' value='Alliance of American Football'>
									{' '}
									Alliance of American Football{' '}
								</Option>

								<Option data-option-array-index='12' value='Alpine Skiing'>
									{' '}
									Alpine Skiing{' '}
								</Option>

								<Option data-option-array-index='13' value='Alternative Energy'>
									{' '}
									Alternative Energy{' '}
								</Option>

								<Option data-option-array-index='14' value='American Le Mans Series'>
									{' '}
									American Le Mans Series{' '}
								</Option>

								<Option data-option-array-index='15' value='Animals'>
									{' '}
									Animals{' '}
								</Option>

								<Option data-option-array-index='16' value='Archaeology'>
									{' '}
									Archaeology{' '}
								</Option>

								<Option data-option-array-index='17' value='Archery'>
									{' '}
									Archery{' '}
								</Option>

								<Option data-option-array-index='18' value='Architecture'>
									{' '}
									Architecture{' '}
								</Option>

								<Option data-option-array-index='19' value='Armed Conflict'>
									{' '}
									Armed Conflict{' '}
								</Option>

								<Option data-option-array-index='20' value='Arts (General)'>
									{' '}
									Arts (General){' '}
								</Option>

								<Option data-option-array-index='21' value='Arts, Culture and Entertainment'>
									{' '}
									Arts, Culture and Entertainment{' '}
								</Option>

								<Option data-option-array-index='22' value='Asia Pac'>
									{' '}
									Asia Pac{' '}
								</Option>

								<Option data-option-array-index='23' value='ASP Surfing League'>
									{' '}
									ASP Surfing League{' '}
								</Option>

								<Option data-option-array-index='24' value='Astronomy'>
									{' '}
									Astronomy{' '}
								</Option>

								<Option data-option-array-index='25' value='Awards'>
									{' '}
									Awards{' '}
								</Option>

								<Option data-option-array-index='26' value='Badminton'>
									{' '}
									Badminton{' '}
								</Option>

								<Option data-option-array-index='27' value='Ballet Skiing'>
									{' '}
									Ballet Skiing{' '}
								</Option>

								<Option data-option-array-index='28' value='Ballooning'>
									{' '}
									Ballooning{' '}
								</Option>

								<Option data-option-array-index='29' value='Base Jumping'>
									{' '}
									Base Jumping{' '}
								</Option>

								<Option data-option-array-index='30' value='Baseball - American League'>
									{' '}
									Baseball - American League{' '}
								</Option>

								<Option data-option-array-index='31' value='Baseball - College'>
									{' '}
									Baseball - College{' '}
								</Option>

								<Option data-option-array-index='32' value='Baseball - National League'>
									{' '}
									Baseball - National League{' '}
								</Option>

								<Option data-option-array-index='33' value='Baseball - Other'>
									{' '}
									Baseball - Other{' '}
								</Option>

								<Option data-option-array-index='34' value='Basketball - BIG3'>
									{' '}
									Basketball - BIG3{' '}
								</Option>

								<Option data-option-array-index='35' value='Basketball - International Leagues'>
									{' '}
									Basketball - International Leagues{' '}
								</Option>

								<Option data-option-array-index='36' value="Basketball - Men's College">
									{' '}
									Basketball - Men's College{' '}
								</Option>

								<Option data-option-array-index='37' value='Basketball - NBA Development League'>
									{' '}
									Basketball - NBA Development League{' '}
								</Option>

								<Option data-option-array-index='38' value='Basketball - NBA Pro'>
									{' '}
									Basketball - NBA Pro{' '}
								</Option>

								<Option data-option-array-index='39' value='Basketball - Other'>
									{' '}
									Basketball - Other{' '}
								</Option>

								<Option data-option-array-index='40' value="Basketball - Women's College">
									{' '}
									Basketball - Women's College{' '}
								</Option>

								<Option data-option-array-index='41' value="Basketball - Women's Pro">
									{' '}
									Basketball - Women's Pro{' '}
								</Option>

								<Option data-option-array-index='42' value='Beach Volleyball'>
									{' '}
									Beach Volleyball{' '}
								</Option>

								<Option data-option-array-index='43' value='Benefits'>
									{' '}
									Benefits{' '}
								</Option>

								<Option data-option-array-index='44' value='Biathlon'>
									{' '}
									Biathlon{' '}
								</Option>

								<Option data-option-array-index='45' value='Bicycle Stunt Riding'>
									{' '}
									Bicycle Stunt Riding{' '}
								</Option>

								<Option data-option-array-index='46' value='Billiards'>
									{' '}
									Billiards{' '}
								</Option>

								<Option data-option-array-index='47' value='Bobsleigh'>
									{' '}
									Bobsleigh{' '}
								</Option>

								<Option data-option-array-index='48' value='Body Building'>
									{' '}
									Body Building{' '}
								</Option>

								<Option data-option-array-index='49' value='Boules'>
									{' '}
									Boules{' '}
								</Option>

								<Option data-option-array-index='50' value='Bowling'>
									{' '}
									Bowling{' '}
								</Option>

								<Option data-option-array-index='51' value='Boxing'>
									{' '}
									Boxing{' '}
								</Option>

								<Option data-option-array-index='52' value='Boxing - Heavyweight'>
									{' '}
									Boxing - Heavyweight{' '}
								</Option>

								<Option data-option-array-index='53' value="Boxing - Women's">
									{' '}
									Boxing - Women's{' '}
								</Option>

								<Option data-option-array-index='54' value='Bull Fighting'>
									{' '}
									Bull Fighting{' '}
								</Option>

								<Option data-option-array-index='55' value='Bungee Jumping'>
									{' '}
									Bungee Jumping{' '}
								</Option>

								<Option data-option-array-index='56' value='Camel Racing'>
									{' '}
									Camel Racing{' '}
								</Option>

								<Option data-option-array-index='57' value='Canadian Football League'>
									{' '}
									Canadian Football League{' '}
								</Option>

								<Option data-option-array-index='58' value='Canoeing'>
									{' '}
									Canoeing{' '}
								</Option>

								<Option data-option-array-index='59' value='CART'>
									{' '}
									CART{' '}
								</Option>

								<Option data-option-array-index='60' value='Cave Diving'>
									{' '}
									Cave Diving{' '}
								</Option>

								<Option data-option-array-index='61' value='Celebrities'>
									{' '}
									Celebrities{' '}
								</Option>

								<Option data-option-array-index='62' value='Champions Hockey League'>
									{' '}
									Champions Hockey League{' '}
								</Option>

								<Option data-option-array-index='63' value='Champions Tour'>
									{' '}
									Champions Tour{' '}
								</Option>

								<Option data-option-array-index='64' value='Chemicals'>
									{' '}
									Chemicals{' '}
								</Option>

								<Option data-option-array-index='65' value='Chess'>
									{' '}
									Chess{' '}
								</Option>

								<Option data-option-array-index='66' value='Cinema'>
									{' '}
									Cinema{' '}
								</Option>

								<Option data-option-array-index='67' value='Cityscape'>
									{' '}
									Cityscape{' '}
								</Option>

								<Option data-option-array-index='68' value='Civil Unrest'>
									{' '}
									Civil Unrest{' '}
								</Option>

								<Option data-option-array-index='69' value='Clay Pigeon Shooting'>
									{' '}
									Clay Pigeon Shooting{' '}
								</Option>

								<Option data-option-array-index='70' value='Club Soccer'>
									{' '}
									Club Soccer{' '}
								</Option>

								<Option data-option-array-index='71' value='Commonwealth Games 2006'>
									{' '}
									Commonwealth Games 2006{' '}
								</Option>

								<Option data-option-array-index='72' value='Computing and Information Technology'>
									{' '}
									Computing and Information Technology{' '}
								</Option>

								<Option data-option-array-index='73' value='Conflicts (General)'>
									{' '}
									Conflicts (General){' '}
								</Option>

								<Option data-option-array-index='74' value='Conservation'>
									{' '}
									Conservation{' '}
								</Option>

								<Option data-option-array-index='75' value='Construction and Property'>
									{' '}
									Construction and Property{' '}
								</Option>

								<Option data-option-array-index='76' value='Consumer Goods'>
									{' '}
									Consumer Goods{' '}
								</Option>

								<Option data-option-array-index='77' value='County Cricket'>
									{' '}
									County Cricket{' '}
								</Option>

								<Option data-option-array-index='78' value="Coup d'Etat">
									{' '}
									Coup d'Etat{' '}
								</Option>

								<Option data-option-array-index='79' value='Craft'>
									{' '}
									Craft{' '}
								</Option>

								<Option data-option-array-index='80' value='Cricket'>
									{' '}
									Cricket{' '}
								</Option>

								<Option data-option-array-index='81' value='Crime, Law and Justice'>
									{' '}
									Crime, Law and Justice{' '}
								</Option>

								<Option data-option-array-index='82' value='Curiosities'>
									{' '}
									Curiosities{' '}
								</Option>

								<Option data-option-array-index='83' value='Curling'>
									{' '}
									Curling{' '}
								</Option>

								<Option data-option-array-index='84' value='Cycling'>
									{' '}
									Cycling{' '}
								</Option>

								<Option data-option-array-index='85' value='Cyclo-Cross'>
									{' '}
									Cyclo-Cross{' '}
								</Option>

								<Option data-option-array-index='86' value='Dance'>
									{' '}
									Dance{' '}
								</Option>

								<Option data-option-array-index='87' value='Darts'>
									{' '}
									Darts{' '}
								</Option>

								<Option data-option-array-index='88' value='Decathlon'>
									{' '}
									Decathlon{' '}
								</Option>

								<Option data-option-array-index='89' value='Defense'>
									{' '}
									Defense{' '}
								</Option>

								<Option data-option-array-index='90' value='Diplomacy'>
									{' '}
									Diplomacy{' '}
								</Option>

								<Option data-option-array-index='91' value='Disabled Sports'>
									{' '}
									Disabled Sports{' '}
								</Option>

								<Option data-option-array-index='92' value='Disasters (General)'>
									{' '}
									Disasters (General){' '}
								</Option>

								<Option data-option-array-index='93' value='Disasters and Accidents'>
									{' '}
									Disasters and Accidents{' '}
								</Option>

								<Option data-option-array-index='94' value='Diseases'>
									{' '}
									Diseases{' '}
								</Option>

								<Option data-option-array-index='95' value='Diving'>
									{' '}
									Diving{' '}
								</Option>

								<Option data-option-array-index='96' value='Dog Sled Racing'>
									{' '}
									Dog Sled Racing{' '}
								</Option>

								<Option data-option-array-index='97' value='Donruss'>
									{' '}
									Donruss{' '}
								</Option>

								<Option data-option-array-index='98' value='Dressage'>
									{' '}
									Dressage{' '}
								</Option>

								<Option data-option-array-index='99' value='Drought'>
									{' '}
									Drought{' '}
								</Option>

								<Option data-option-array-index='100' value='Earthquake'>
									{' '}
									Earthquake{' '}
								</Option>

								<Option data-option-array-index='101' value='Economy, Business and Finance'>
									{' '}
									Economy, Business and Finance{' '}
								</Option>

								<Option data-option-array-index='102' value='Editorial'>
									{' '}
									Editorial{' '}
								</Option>

								<Option data-option-array-index='103' value='Education'>
									{' '}
									Education{' '}
								</Option>

								<Option data-option-array-index='104' value='Election Campaign'>
									{' '}
									Election Campaign{' '}
								</Option>

								<Option data-option-array-index='105' value='Elections'>
									{' '}
									Elections{' '}
								</Option>

								<Option data-option-array-index='106' value='Emergency Incidents'>
									{' '}
									Emergency Incidents{' '}
								</Option>

								<Option data-option-array-index='107' value='Emergency Planning'>
									{' '}
									Emergency Planning{' '}
								</Option>

								<Option data-option-array-index='108' value='Employment'>
									{' '}
									Employment{' '}
								</Option>

								<Option data-option-array-index='109' value='Energy and Resources'>
									{' '}
									Energy and Resources{' '}
								</Option>

								<Option data-option-array-index='110' value='Engineering'>
									{' '}
									Engineering{' '}
								</Option>

								<Option data-option-array-index='111' value='Entertainment'>
									{' '}
									Entertainment{' '}
								</Option>

								<Option data-option-array-index='112' value='Environmental Issues'>
									{' '}
									Environmental Issues{' '}
								</Option>

								<Option data-option-array-index='113' value='Environmental Politics'>
									{' '}
									Environmental Politics{' '}
								</Option>

								<Option data-option-array-index='114' value='Environmental Pollution'>
									{' '}
									Environmental Pollution{' '}
								</Option>

								<Option data-option-array-index='115' value='Epidemic and Plague'>
									{' '}
									Epidemic and Plague{' '}
								</Option>

								<Option data-option-array-index='116' value='Equestrian'>
									{' '}
									Equestrian{' '}
								</Option>

								<Option data-option-array-index='117' value='Espionage and Intelligence'>
									{' '}
									Espionage and Intelligence{' '}
								</Option>

								<Option data-option-array-index='118' value='esports'>
									{' '}
									esports{' '}
								</Option>

								<Option data-option-array-index='119' value='Eurocup Basketball'>
									{' '}
									Eurocup Basketball{' '}
								</Option>

								<Option data-option-array-index='120' value='Euroleague Basketball'>
									{' '}
									Euroleague Basketball{' '}
								</Option>

								<Option data-option-array-index='121' value='Europe'>
									{' '}
									Europe{' '}
								</Option>

								<Option data-option-array-index='122' value='European Ladies PGA Tour'>
									{' '}
									European Ladies PGA Tour{' '}
								</Option>

								<Option data-option-array-index='123' value='European PGA Tour'>
									{' '}
									European PGA Tour{' '}
								</Option>

								<Option data-option-array-index='124' value='Exclusive'>
									{' '}
									Exclusive{' '}
								</Option>

								<Option data-option-array-index='125' value='Extreme In-Line Skating'>
									{' '}
									Extreme In-Line Skating{' '}
								</Option>

								<Option data-option-array-index='126' value='Extreme Skateboarding'>
									{' '}
									Extreme Skateboarding{' '}
								</Option>

								<Option data-option-array-index='127' value='Extreme Sports and X Games'>
									{' '}
									Extreme Sports and X Games{' '}
								</Option>

								<Option data-option-array-index='128' value='Famine'>
									{' '}
									Famine{' '}
								</Option>

								<Option data-option-array-index='129' value='Fans'>
									{' '}
									Fans{' '}
								</Option>

								<Option data-option-array-index='130' value='Fashion'>
									{' '}
									Fashion{' '}
								</Option>

								<Option data-option-array-index='131' value='Fencing'>
									{' '}
									Fencing{' '}
								</Option>

								<Option data-option-array-index='132' value='Festive Events (including Carnivals)'>
									{' '}
									Festive Events (including Carnivals){' '}
								</Option>

								<Option data-option-array-index='133' value='Field Hockey'>
									{' '}
									Field Hockey{' '}
								</Option>

								<Option data-option-array-index='134' value='Figure Skating'>
									{' '}
									Figure Skating{' '}
								</Option>

								<Option data-option-array-index='135' value='Filters'>
									{' '}
									Filters{' '}
								</Option>

								<Option data-option-array-index='136' value='Financial and Business Services'>
									{' '}
									Financial and Business Services{' '}
								</Option>

								<Option data-option-array-index='137' value='Fire'>
									{' '}
									Fire{' '}
								</Option>

								<Option data-option-array-index='138' value='Fitness'>
									{' '}
									Fitness{' '}
								</Option>

								<Option data-option-array-index='139' value='Fleer'>
									{' '}
									Fleer{' '}
								</Option>

								<Option data-option-array-index='140' value='Flood'>
									{' '}
									Flood{' '}
								</Option>

								<Option data-option-array-index='141' value='Football - Arena Football'>
									{' '}
									Football - Arena Football{' '}
								</Option>

								<Option data-option-array-index='142' value='Football - Australian Rules'>
									{' '}
									Football - Australian Rules{' '}
								</Option>

								<Option data-option-array-index='143' value='Football - College'>
									{' '}
									Football - College{' '}
								</Option>

								<Option data-option-array-index='144' value='Football - NFL'>
									{' '}
									Football - NFL{' '}
								</Option>

								<Option data-option-array-index='145' value='Football - Other'>
									{' '}
									Football - Other{' '}
								</Option>

								<Option data-option-array-index='146' value='Football - Soccer'>
									{' '}
									Football - Soccer{' '}
								</Option>

								<Option data-option-array-index='147' value='Foreign Aid'>
									{' '}
									Foreign Aid{' '}
								</Option>

								<Option data-option-array-index='148' value='Formula One'>
									{' '}
									Formula One{' '}
								</Option>

								<Option data-option-array-index='149' value='Fox Hunting'>
									{' '}
									Fox Hunting{' '}
								</Option>

								<Option data-option-array-index='150' value='Free Style Ski Jump/Aerials'>
									{' '}
									Free Style Ski Jump/Aerials{' '}
								</Option>

								<Option data-option-array-index='151' value='Freestyle Wrestling'>
									{' '}
									Freestyle Wrestling{' '}
								</Option>

								<Option data-option-array-index='152' value='Fresh Water Fishing'>
									{' '}
									Fresh Water Fishing{' '}
								</Option>

								<Option data-option-array-index='153' value='Frisbee'>
									{' '}
									Frisbee{' '}
								</Option>

								<Option data-option-array-index='154' value='Gaelic Football'>
									{' '}
									Gaelic Football{' '}
								</Option>

								<Option data-option-array-index='155' value='General Exercise'>
									{' '}
									General Exercise{' '}
								</Option>

								<Option data-option-array-index='156' value='General Views'>
									{' '}
									General Views{' '}
								</Option>

								<Option data-option-array-index='157' value='Gliding'>
									{' '}
									Gliding{' '}
								</Option>

								<Option data-option-array-index='158' value='Golden Boy Promotions'>
									{' '}
									Golden Boy Promotions{' '}
								</Option>

								<Option data-option-array-index='159' value='Golf'>
									{' '}
									Golf{' '}
								</Option>

								<Option data-option-array-index='160' value='Government'>
									{' '}
									Government{' '}
								</Option>

								<Option data-option-array-index='161' value='Grand Slam Events'>
									{' '}
									Grand Slam Events{' '}
								</Option>

								<Option data-option-array-index='162' value='Grand-Am Racing'>
									{' '}
									Grand-Am Racing{' '}
								</Option>

								<Option data-option-array-index='163' value='Great Britain'>
									{' '}
									Great Britain{' '}
								</Option>

								<Option data-option-array-index='164' value='Greco Roman Wrestling'>
									{' '}
									Greco Roman Wrestling{' '}
								</Option>

								<Option data-option-array-index='165' value='Green Bowls'>
									{' '}
									Green Bowls{' '}
								</Option>

								<Option data-option-array-index='166' value='Greyhound Racing'>
									{' '}
									Greyhound Racing{' '}
								</Option>

								<Option data-option-array-index='167' value='Guerilla Activites'>
									{' '}
									Guerilla Activites{' '}
								</Option>

								<Option data-option-array-index='168' value='Gulf War'>
									{' '}
									Gulf War{' '}
								</Option>

								<Option data-option-array-index='169' value='Gymnastics'>
									{' '}
									Gymnastics{' '}
								</Option>

								<Option data-option-array-index='170' value='Handball'>
									{' '}
									Handball{' '}
								</Option>

								<Option data-option-array-index='171' value='Hang Gliding'>
									{' '}
									Hang Gliding{' '}
								</Option>

								<Option data-option-array-index='172' value='Health'>
									{' '}
									Health{' '}
								</Option>

								<Option data-option-array-index='173' value='Health and Safety at Work'>
									{' '}
									Health and Safety at Work{' '}
								</Option>

								<Option data-option-array-index='174' value='Health Organizations'>
									{' '}
									Health Organizations{' '}
								</Option>

								<Option data-option-array-index='175' value='Heptathlon'>
									{' '}
									Heptathlon{' '}
								</Option>

								<Option data-option-array-index='176' value='High School Baseball'>
									{' '}
									High School Baseball{' '}
								</Option>

								<Option data-option-array-index='177' value='Hiking'>
									{' '}
									Hiking{' '}
								</Option>

								<Option data-option-array-index='178' value='Hockey (Ice) - College'>
									{' '}
									Hockey (Ice) - College{' '}
								</Option>

								<Option data-option-array-index='179' value='Hockey (Ice) - International'>
									{' '}
									Hockey (Ice) - International{' '}
								</Option>

								<Option data-option-array-index='180' value='Hockey (Ice) - NHL'>
									{' '}
									Hockey (Ice) - NHL{' '}
								</Option>

								<Option data-option-array-index='181' value='Hockey - Other'>
									{' '}
									Hockey - Other{' '}
								</Option>

								<Option data-option-array-index='182' value='Homelessness'>
									{' '}
									Homelessness{' '}
								</Option>

								<Option data-option-array-index='183' value='Horse Racing'>
									{' '}
									Horse Racing{' '}
								</Option>

								<Option data-option-array-index='184' value='Horticulture'>
									{' '}
									Horticulture{' '}
								</Option>

								<Option data-option-array-index='185' value='Human Interest'>
									{' '}
									Human Interest{' '}
								</Option>

								<Option data-option-array-index='186' value='Human Rights'>
									{' '}
									Human Rights{' '}
								</Option>

								<Option data-option-array-index='187' value='Human Sciences'>
									{' '}
									Human Sciences{' '}
								</Option>

								<Option data-option-array-index='188' value='Hunting'>
									{' '}
									Hunting{' '}
								</Option>

								<Option data-option-array-index='189' value='Hunting, Shooting, Fishing'>
									{' '}
									Hunting, Shooting, Fishing{' '}
								</Option>

								<Option data-option-array-index='190' value='Hurling'>
									{' '}
									Hurling{' '}
								</Option>

								<Option data-option-array-index='191' value='Ice Boarding'>
									{' '}
									Ice Boarding{' '}
								</Option>

								<Option data-option-array-index='192' value='Ice Climbing'>
									{' '}
									Ice Climbing{' '}
								</Option>

								<Option data-option-array-index='193' value='Indoor Volleyball'>
									{' '}
									Indoor Volleyball{' '}
								</Option>

								<Option data-option-array-index='194' value='Industrial Accident'>
									{' '}
									Industrial Accident{' '}
								</Option>

								<Option data-option-array-index='195' value='Indy Racing League'>
									{' '}
									Indy Racing League{' '}
								</Option>

								<Option data-option-array-index='196' value='Interior Design'>
									{' '}
									Interior Design{' '}
								</Option>

								<Option data-option-array-index='197' value='International Baseball'>
									{' '}
									International Baseball{' '}
								</Option>

								<Option data-option-array-index='198' value='International Champions Cup'>
									{' '}
									International Champions Cup{' '}
								</Option>

								<Option data-option-array-index='199' value='International Team Soccer'>
									{' '}
									International Team Soccer{' '}
								</Option>

								<Option data-option-array-index='200' value='Jai Alai'>
									{' '}
									Jai Alai{' '}
								</Option>

								<Option data-option-array-index='201' value='Japanese LPGA'>
									{' '}
									Japanese LPGA{' '}
								</Option>

								<Option data-option-array-index='202' value='Jet Skiing'>
									{' '}
									Jet Skiing{' '}
								</Option>

								<Option data-option-array-index='203' value='Jogging'>
									{' '}
									Jogging{' '}
								</Option>

								<Option data-option-array-index='204' value='Judiciary (System of Justice)'>
									{' '}
									Judiciary (System of Justice){' '}
								</Option>

								<Option data-option-array-index='205' value='Judo'>
									{' '}
									Judo{' '}
								</Option>

								<Option data-option-array-index='206' value='Justice and Rights'>
									{' '}
									Justice and Rights{' '}
								</Option>

								<Option data-option-array-index='207' value='Karate'>
									{' '}
									Karate{' '}
								</Option>

								<Option data-option-array-index='208' value='Kayaking'>
									{' '}
									Kayaking{' '}
								</Option>

								<Option data-option-array-index='209' value='Kick Boxing'>
									{' '}
									Kick Boxing{' '}
								</Option>

								<Option data-option-array-index='210' value="Kids' Soccer">
									{' '}
									Kids' Soccer{' '}
								</Option>

								<Option data-option-array-index='211' value='Labor'>
									{' '}
									Labor{' '}
								</Option>

								<Option data-option-array-index='212' value='Labor Dispute'>
									{' '}
									Labor Dispute{' '}
								</Option>

								<Option data-option-array-index='213' value='Labor Legislation'>
									{' '}
									Labor Legislation{' '}
								</Option>

								<Option data-option-array-index='214' value='Lacrosse'>
									{' '}
									Lacrosse{' '}
								</Option>

								<Option data-option-array-index='215' value='Land Sailing'>
									{' '}
									Land Sailing{' '}
								</Option>

								<Option data-option-array-index='216' value='Landscape'>
									{' '}
									Landscape{' '}
								</Option>

								<Option data-option-array-index='217' value='Launches'>
									{' '}
									Launches{' '}
								</Option>

								<Option data-option-array-index='218' value='Laws'>
									{' '}
									Laws{' '}
								</Option>

								<Option data-option-array-index='219' value='Leisure and Pastimes'>
									{' '}
									Leisure and Pastimes{' '}
								</Option>

								<Option data-option-array-index='220' value='Lifestyle and Leisure'>
									{' '}
									Lifestyle and Leisure{' '}
								</Option>

								<Option data-option-array-index='221' value='Literature'>
									{' '}
									Literature{' '}
								</Option>

								<Option data-option-array-index='222' value='Luge'>
									{' '}
									Luge{' '}
								</Option>

								<Option data-option-array-index='223' value='Macro Economics'>
									{' '}
									Macro Economics{' '}
								</Option>

								<Option data-option-array-index='224' value='Major League Lacrosse'>
									{' '}
									Major League Lacrosse{' '}
								</Option>

								<Option data-option-array-index='225' value='Major League Soccer'>
									{' '}
									Major League Soccer{' '}
								</Option>

								<Option data-option-array-index='226' value='Majors'>
									{' '}
									Majors{' '}
								</Option>

								<Option data-option-array-index='227' value='Marathon'>
									{' '}
									Marathon{' '}
								</Option>

								<Option data-option-array-index='228' value='Markets and Exchanges'>
									{' '}
									Markets and Exchanges{' '}
								</Option>

								<Option data-option-array-index='229' value='Martial Arts'>
									{' '}
									Martial Arts{' '}
								</Option>

								<Option data-option-array-index='230' value='Massacre'>
									{' '}
									Massacre{' '}
								</Option>

								<Option data-option-array-index='231' value='Medals and Trophies'>
									{' '}
									Medals and Trophies{' '}
								</Option>

								<Option data-option-array-index='232' value='Media'>
									{' '}
									Media{' '}
								</Option>

								<Option data-option-array-index='233' value='Media Coverage'>
									{' '}
									Media Coverage{' '}
								</Option>

								<Option data-option-array-index='234' value='Medical Research'>
									{' '}
									Medical Research{' '}
								</Option>

								<Option data-option-array-index='235' value='Medicines'>
									{' '}
									Medicines{' '}
								</Option>

								<Option data-option-array-index='236' value="Men's Field">
									{' '}
									Men's Field{' '}
								</Option>

								<Option data-option-array-index='237' value="Men's Tennis">
									{' '}
									Men's Tennis{' '}
								</Option>

								<Option data-option-array-index='238' value="Men's Track">
									{' '}
									Men's Track{' '}
								</Option>

								<Option data-option-array-index='239' value='Meteorological Disaster'>
									{' '}
									Meteorological Disaster{' '}
								</Option>

								<Option data-option-array-index='240' value='Mexican Football (FMF)'>
									{' '}
									Mexican Football (FMF){' '}
								</Option>

								<Option data-option-array-index='241' value='Mexico'>
									{' '}
									Mexico{' '}
								</Option>

								<Option data-option-array-index='242' value='Middle East'>
									{' '}
									Middle East{' '}
								</Option>

								<Option data-option-array-index='243' value='Military'>
									{' '}
									Military{' '}
								</Option>

								<Option data-option-array-index='244' value='Minor Leagues, Professional Baseball'>
									{' '}
									Minor Leagues, Professional Baseball{' '}
								</Option>

								<Option data-option-array-index='245' value='Minority Groups'>
									{' '}
									Minority Groups{' '}
								</Option>

								<Option data-option-array-index='246' value='Mixed Martial Arts'>
									{' '}
									Mixed Martial Arts{' '}
								</Option>

								<Option data-option-array-index='247' value='Mogul Skiing'>
									{' '}
									Mogul Skiing{' '}
								</Option>

								<Option data-option-array-index='248' value='Motocross'>
									{' '}
									Motocross{' '}
								</Option>

								<Option data-option-array-index='249' value='Motorbike Racing'>
									{' '}
									Motorbike Racing{' '}
								</Option>

								<Option data-option-array-index='250' value='Motorsports'>
									{' '}
									Motorsports{' '}
								</Option>

								<Option data-option-array-index='251' value='Mountain Bikes'>
									{' '}
									Mountain Bikes{' '}
								</Option>

								<Option data-option-array-index='252' value='Mountaineering'>
									{' '}
									Mountaineering{' '}
								</Option>

								<Option data-option-array-index='253' value='Mud Wrestling'>
									{' '}
									Mud Wrestling{' '}
								</Option>

								<Option data-option-array-index='254' value='Music'>
									{' '}
									Music{' '}
								</Option>

								<Option data-option-array-index='255' value='NASCAR'>
									{' '}
									NASCAR{' '}
								</Option>

								<Option data-option-array-index='256' value='National Political Convention'>
									{' '}
									National Political Convention{' '}
								</Option>

								<Option data-option-array-index='257' value='Nationwide Tour'>
									{' '}
									Nationwide Tour{' '}
								</Option>

								<Option data-option-array-index='258' value='Natural Resources'>
									{' '}
									Natural Resources{' '}
								</Option>

								<Option data-option-array-index='259' value='Natural Sciences'>
									{' '}
									Natural Sciences{' '}
								</Option>

								<Option data-option-array-index='260' value='Nature'>
									{' '}
									Nature{' '}
								</Option>

								<Option data-option-array-index='261' value='NCAA College Sports'>
									{' '}
									NCAA College Sports{' '}
								</Option>

								<Option data-option-array-index='262' value='Netball'>
									{' '}
									Netball{' '}
								</Option>

								<Option data-option-array-index='263' value='NHRA'>
									{' '}
									NHRA{' '}
								</Option>

								<Option data-option-array-index='264' value='Nightclubs'>
									{' '}
									Nightclubs{' '}
								</Option>

								<Option data-option-array-index='265' value='Nordic Combined'>
									{' '}
									Nordic Combined{' '}
								</Option>

								<Option data-option-array-index='266' value='Nordic Skiing'>
									{' '}
									Nordic Skiing{' '}
								</Option>

								<Option data-option-array-index='267' value='Nuclear Accident'>
									{' '}
									Nuclear Accident{' '}
								</Option>

								<Option data-option-array-index='268' value='Obituary'>
									{' '}
									Obituary{' '}
								</Option>

								<Option data-option-array-index='269' value='Off Road Racing'>
									{' '}
									Off Road Racing{' '}
								</Option>

								<Option data-option-array-index='270' value='Officials'>
									{' '}
									Officials{' '}
								</Option>

								<Option data-option-array-index='271' value='Olympics'>
									{' '}
									Olympics{' '}
								</Option>

								<Option data-option-array-index='272' value='One Day Cricket'>
									{' '}
									One Day Cricket{' '}
								</Option>

								<Option data-option-array-index='273' value='Open Championships'>
									{' '}
									Open Championships{' '}
								</Option>

								<Option data-option-array-index='274' value='Orienteering'>
									{' '}
									Orienteering{' '}
								</Option>

								<Option data-option-array-index='275' value='Other'>
									{' '}
									Other{' '}
								</Option>

								<Option data-option-array-index='276' value='Painting'>
									{' '}
									Painting{' '}
								</Option>

								<Option data-option-array-index='277' value='Parachuting'>
									{' '}
									Parachuting{' '}
								</Option>

								<Option data-option-array-index='278' value='Parasailing'>
									{' '}
									Parasailing{' '}
								</Option>

								<Option data-option-array-index='279' value='Paraskiing'>
									{' '}
									Paraskiing{' '}
								</Option>

								<Option data-option-array-index='280' value='Parent Organizations'>
									{' '}
									Parent Organizations{' '}
								</Option>

								<Option data-option-array-index='281' value='Parties'>
									{' '}
									Parties{' '}
								</Option>

								<Option data-option-array-index='283' value='Pelota'>
									{' '}
									Pelota{' '}
								</Option>

								<Option data-option-array-index='284' value='Pentathlon (Modern)'>
									{' '}
									Pentathlon (Modern){' '}
								</Option>

								<Option data-option-array-index='285' value='People'>
									{' '}
									People{' '}
								</Option>

								<Option data-option-array-index='286' value='PGA of America'>
									{' '}
									PGA of America{' '}
								</Option>

								<Option data-option-array-index='287' value='PGA TOUR Canada'>
									{' '}
									PGA TOUR Canada{' '}
								</Option>

								<Option data-option-array-index='288' value='PGA TOUR China'>
									{' '}
									PGA TOUR China{' '}
								</Option>

								<Option data-option-array-index='289' value='PGA TOUR Latinoamerica'>
									{' '}
									PGA TOUR Latinoamerica{' '}
								</Option>

								<Option data-option-array-index='290' value='Police'>
									{' '}
									Police{' '}
								</Option>

								<Option data-option-array-index='291' value='Politics'>
									{' '}
									Politics{' '}
								</Option>

								<Option data-option-array-index='292' value='Pollution'>
									{' '}
									Pollution{' '}
								</Option>

								<Option data-option-array-index='293' value='Pool'>
									{' '}
									Pool{' '}
								</Option>

								<Option data-option-array-index='294' value='Population'>
									{' '}
									Population{' '}
								</Option>

								<Option data-option-array-index='295' value='Pornography'>
									{' '}
									Pornography{' '}
								</Option>

								<Option data-option-array-index='296' value='Portrait'>
									{' '}
									Portrait{' '}
								</Option>

								<Option data-option-array-index='297' value='Postwar'>
									{' '}
									Postwar{' '}
								</Option>

								<Option data-option-array-index='298' value='Poverty'>
									{' '}
									Poverty{' '}
								</Option>

								<Option data-option-array-index='299' value='Powerboat Racing'>
									{' '}
									Powerboat Racing{' '}
								</Option>

								<Option data-option-array-index='300' value='Premier Boxing Champions'>
									{' '}
									Premier Boxing Champions{' '}
								</Option>

								<Option data-option-array-index='301' value='Preview Sets - Winter Olympics 2006'>
									{' '}
									Preview Sets - Winter Olympics 2006{' '}
								</Option>

								<Option data-option-array-index='302' value='Preview Sets - World Cup 2006'>
									{' '}
									Preview Sets - World Cup 2006{' '}
								</Option>

								<Option data-option-array-index='303' value='Prison'>
									{' '}
									Prison{' '}
								</Option>

								<Option data-option-array-index='304' value='Professional Wrestling'>
									{' '}
									Professional Wrestling{' '}
								</Option>

								<Option data-option-array-index='305' value='Prosecution'>
									{' '}
									Prosecution{' '}
								</Option>

								<Option data-option-array-index='306' value='Prostitution'>
									{' '}
									Prostitution{' '}
								</Option>

								<Option data-option-array-index='307' value='Racism'>
									{' '}
									Racism{' '}
								</Option>

								<Option data-option-array-index='308' value='Racqetball'>
									{' '}
									Racqetball{' '}
								</Option>

								<Option data-option-array-index='309' value='Rafting'>
									{' '}
									Rafting{' '}
								</Option>

								<Option data-option-array-index='310' value='Relief and Aid Organizations'>
									{' '}
									Relief and Aid Organizations{' '}
								</Option>

								<Option data-option-array-index='311' value='Religion and Belief'>
									{' '}
									Religion and Belief{' '}
								</Option>

								<Option data-option-array-index='312' value='Research'>
									{' '}
									Research{' '}
								</Option>

								<Option data-option-array-index='313' value='Retirement'>
									{' '}
									Retirement{' '}
								</Option>

								<Option data-option-array-index='314' value='Rhythmic Gymnastics'>
									{' '}
									Rhythmic Gymnastics{' '}
								</Option>

								<Option data-option-array-index='315' value='Riots'>
									{' '}
									Riots{' '}
								</Option>

								<Option data-option-array-index='316' value='Road and X-Country Racing'>
									{' '}
									Road and X-Country Racing{' '}
								</Option>

								<Option data-option-array-index='317' value='Rock Climbing'>
									{' '}
									Rock Climbing{' '}
								</Option>

								<Option data-option-array-index='318' value='Rodeo'>
									{' '}
									Rodeo{' '}
								</Option>

								<Option data-option-array-index='319' value='Roller Hockey'>
									{' '}
									Roller Hockey{' '}
								</Option>

								<Option data-option-array-index='320' value='Rollerblading'>
									{' '}
									Rollerblading{' '}
								</Option>

								<Option data-option-array-index='321' value='Rollerskating'>
									{' '}
									Rollerskating{' '}
								</Option>

								<Option data-option-array-index='322' value='Rowing'>
									{' '}
									Rowing{' '}
								</Option>

								<Option data-option-array-index='323' value='Royalty'>
									{' '}
									Royalty{' '}
								</Option>

								<Option data-option-array-index='324' value='Rugby'>
									{' '}
									Rugby{' '}
								</Option>

								<Option data-option-array-index='325' value='Rugby League'>
									{' '}
									Rugby League{' '}
								</Option>

								<Option data-option-array-index='326' value='Rugby Union'>
									{' '}
									Rugby Union{' '}
								</Option>

								<Option data-option-array-index='327' value='Sailing'>
									{' '}
									Sailing{' '}
								</Option>

								<Option data-option-array-index='328' value='Salt Water Fishing, Deep Sea'>
									{' '}
									Salt Water Fishing, Deep Sea{' '}
								</Option>

								<Option data-option-array-index='329' value='Sand Skiing'>
									{' '}
									Sand Skiing{' '}
								</Option>

								<Option data-option-array-index='330' value='Schools'>
									{' '}
									Schools{' '}
								</Option>

								<Option data-option-array-index='331' value='Science (General)'>
									{' '}
									Science (General){' '}
								</Option>

								<Option data-option-array-index='332' value='Science and Technology'>
									{' '}
									Science and Technology{' '}
								</Option>

								<Option data-option-array-index='333' value='Scientific Exploration'>
									{' '}
									Scientific Exploration{' '}
								</Option>

								<Option data-option-array-index='334' value='Scuba Diving'>
									{' '}
									Scuba Diving{' '}
								</Option>

								<Option data-option-array-index='335' value='Sculling'>
									{' '}
									Sculling{' '}
								</Option>

								<Option data-option-array-index='336' value='Senior Tour'>
									{' '}
									Senior Tour{' '}
								</Option>

								<Option data-option-array-index='337' value='Sepak Takraw'>
									{' '}
									Sepak Takraw{' '}
								</Option>

								<Option data-option-array-index='338' value='Shell'>
									{' '}
									Shell{' '}
								</Option>

								<Option data-option-array-index='339' value='Short Track Skating'>
									{' '}
									Short Track Skating{' '}
								</Option>

								<Option data-option-array-index='340' value='Show Jumping'>
									{' '}
									Show Jumping{' '}
								</Option>

								<Option data-option-array-index='341' value='Sightings'>
									{' '}
									Sightings{' '}
								</Option>

								<Option data-option-array-index='342' value='Skateboarding'>
									{' '}
									Skateboarding{' '}
								</Option>

								<Option data-option-array-index='343' value='Skeleton'>
									{' '}
									Skeleton{' '}
								</Option>

								<Option data-option-array-index='344' value='Ski Boarding'>
									{' '}
									Ski Boarding{' '}
								</Option>

								<Option data-option-array-index='345' value='Ski Jumping'>
									{' '}
									Ski Jumping{' '}
								</Option>

								<Option data-option-array-index='346' value='Sky Diving'>
									{' '}
									Sky Diving{' '}
								</Option>

								<Option data-option-array-index='347' value='Sky Surfing'>
									{' '}
									Sky Surfing{' '}
								</Option>

								<Option data-option-array-index='348' value='Snocross'>
									{' '}
									Snocross{' '}
								</Option>

								<Option data-option-array-index='349' value='Snooker'>
									{' '}
									Snooker{' '}
								</Option>

								<Option data-option-array-index='350' value='Snow Mountain Bike Racing'>
									{' '}
									Snow Mountain Bike Racing{' '}
								</Option>

								<Option data-option-array-index='351' value='Snowboarding'>
									{' '}
									Snowboarding{' '}
								</Option>

								<Option data-option-array-index='352' value='Snowmobile'>
									{' '}
									Snowmobile{' '}
								</Option>

								<Option data-option-array-index='353' value='Social Issues'>
									{' '}
									Social Issues{' '}
								</Option>

								<Option data-option-array-index='354' value='Softball'>
									{' '}
									Softball{' '}
								</Option>

								<Option data-option-array-index='355' value='South America'>
									{' '}
									South America{' '}
								</Option>

								<Option data-option-array-index='356' value='Space Programs'>
									{' '}
									Space Programs{' '}
								</Option>

								<Option data-option-array-index='357' value='Special Effects Shots'>
									{' '}
									Special Effects Shots{' '}
								</Option>

								<Option data-option-array-index='358' value='Speed Skating'>
									{' '}
									Speed Skating{' '}
								</Option>

								<Option data-option-array-index='359' value='Speed Skiing'>
									{' '}
									Speed Skiing{' '}
								</Option>

								<Option data-option-array-index='360' value='Spelunking, Pot Holing'>
									{' '}
									Spelunking, Pot Holing{' '}
								</Option>

								<Option data-option-array-index='361' value='Sport Climbing'>
									{' '}
									Sport Climbing{' '}
								</Option>

								<Option data-option-array-index='362' value='Sports'>
									{' '}
									Sports{' '}
								</Option>

								<Option data-option-array-index='363' value='Squash'>
									{' '}
									Squash{' '}
								</Option>

								<Option data-option-array-index='364' value='State Budget and Taxes'>
									{' '}
									State Budget and Taxes{' '}
								</Option>

								<Option data-option-array-index='365' value='Street Luge'>
									{' '}
									Street Luge{' '}
								</Option>

								<Option data-option-array-index='366' value='Street Scenes'>
									{' '}
									Street Scenes{' '}
								</Option>

								<Option data-option-array-index='367' value='Strike'>
									{' '}
									Strike{' '}
								</Option>

								<Option data-option-array-index='368' value='Sumo Wrestling'>
									{' '}
									Sumo Wrestling{' '}
								</Option>

								<Option data-option-array-index='369' value='Surfing'>
									{' '}
									Surfing{' '}
								</Option>

								<Option data-option-array-index='370' value='Swimming'>
									{' '}
									Swimming{' '}
								</Option>

								<Option data-option-array-index='371' value='Synchronized Swimming'>
									{' '}
									Synchronized Swimming{' '}
								</Option>

								<Option data-option-array-index='372' value='Table Tennis'>
									{' '}
									Table Tennis{' '}
								</Option>

								<Option data-option-array-index='373' value='Tae Kwan Do'>
									{' '}
									Tae Kwan Do{' '}
								</Option>

								<Option data-option-array-index='374' value='Target Shooting'>
									{' '}
									Target Shooting{' '}
								</Option>

								<Option data-option-array-index='375' value='Teachers Unions'>
									{' '}
									Teachers Unions{' '}
								</Option>

								<Option data-option-array-index='376' value='Technology (General)'>
									{' '}
									Technology (General){' '}
								</Option>

								<Option data-option-array-index='377' value='Television'>
									{' '}
									Television{' '}
								</Option>

								<Option data-option-array-index='378' value='Ten Pin Bowling'>
									{' '}
									Ten Pin Bowling{' '}
								</Option>

								<Option data-option-array-index='379' value='Tennis'>
									{' '}
									Tennis{' '}
								</Option>

								<Option data-option-array-index='380' value='Test Cricket'>
									{' '}
									Test Cricket{' '}
								</Option>

								<Option data-option-array-index='381' value='Theater'>
									{' '}
									Theater{' '}
								</Option>

								<Option data-option-array-index='382' value='Three Day Eventing'>
									{' '}
									Three Day Eventing{' '}
								</Option>

								<Option data-option-array-index='383' value='Top Rank Boxing'>
									{' '}
									Top Rank Boxing{' '}
								</Option>

								<Option data-option-array-index='384' value='Topps'>
									{' '}
									Topps{' '}
								</Option>

								<Option data-option-array-index='385' value='Tour De France'>
									{' '}
									Tour De France{' '}
								</Option>

								<Option data-option-array-index='386' value='Tour Du Pont'>
									{' '}
									Tour Du Pont{' '}
								</Option>

								<Option data-option-array-index='387' value='TPC Courses'>
									{' '}
									TPC Courses{' '}
								</Option>

								<Option data-option-array-index='388' value='Track and Field'>
									{' '}
									Track and Field{' '}
								</Option>

								<Option data-option-array-index='389' value='Trampoline'>
									{' '}
									Trampoline{' '}
								</Option>

								<Option data-option-array-index='390' value='Transport Accident'>
									{' '}
									Transport Accident{' '}
								</Option>

								<Option data-option-array-index='391' value='Transportation'>
									{' '}
									Transportation{' '}
								</Option>

								<Option data-option-array-index='392' value='Travel'>
									{' '}
									Travel{' '}
								</Option>

								<Option data-option-array-index='393' value='Treaties and International Organizations'>
									{' '}
									Treaties and International Organizations{' '}
								</Option>

								<Option data-option-array-index='394' value='Trials'>
									{' '}
									Trials{' '}
								</Option>

								<Option data-option-array-index='395' value='Triathlon'>
									{' '}
									Triathlon{' '}
								</Option>

								<Option data-option-array-index='396' value='UGC - Animals'>
									{' '}
									UGC - Animals{' '}
								</Option>

								<Option data-option-array-index='397' value='UGC - Fails and Funnies'>
									{' '}
									UGC - Fails and Funnies{' '}
								</Option>

								<Option data-option-array-index='398' value='UGC - Heartwarming'>
									{' '}
									UGC - Heartwarming{' '}
								</Option>

								<Option data-option-array-index='399' value='UGC - Lifestyle'>
									{' '}
									UGC - Lifestyle{' '}
								</Option>

								<Option data-option-array-index='400' value='UGC - News'>
									{' '}
									UGC - News{' '}
								</Option>

								<Option data-option-array-index='401' value='UGC - Science and Tech'>
									{' '}
									UGC - Science and Tech{' '}
								</Option>

								<Option data-option-array-index='402' value='UGC - Sport'>
									{' '}
									UGC - Sport{' '}
								</Option>

								<Option data-option-array-index='403' value='UGC - Weather'>
									{' '}
									UGC - Weather{' '}
								</Option>

								<Option data-option-array-index='404' value='Ultimate Fighting Championship'>
									{' '}
									Ultimate Fighting Championship{' '}
								</Option>

								<Option data-option-array-index='405' value='Unemployment'>
									{' '}
									Unemployment{' '}
								</Option>

								<Option data-option-array-index='406' value='Unions'>
									{' '}
									Unions{' '}
								</Option>

								<Option data-option-array-index='407' value='University'>
									{' '}
									University{' '}
								</Option>

								<Option data-option-array-index='408' value='Unrest, Conflicts and War'>
									{' '}
									Unrest, Conflicts and War{' '}
								</Option>

								<Option data-option-array-index='409' value='Upper Deck'>
									{' '}
									Upper Deck{' '}
								</Option>

								<Option data-option-array-index='410' value='US Civil'>
									{' '}
									US Civil{' '}
								</Option>

								<Option data-option-array-index='411' value='US Masters Golf'>
									{' '}
									US Masters Golf{' '}
								</Option>

								<Option data-option-array-index='412' value='USA'>
									{' '}
									USA{' '}
								</Option>

								<Option data-option-array-index='413' value='User Generated Content'>
									{' '}
									User Generated Content{' '}
								</Option>

								<Option data-option-array-index='414' value='USGA Event'>
									{' '}
									USGA Event{' '}
								</Option>

								<Option data-option-array-index='415' value='USLPGA Tour Event'>
									{' '}
									USLPGA Tour Event{' '}
								</Option>

								<Option data-option-array-index='416' value='USPGA Tour Event'>
									{' '}
									USPGA Tour Event{' '}
								</Option>

								<Option data-option-array-index='417' value='Variety'>
									{' '}
									Variety{' '}
								</Option>

								<Option data-option-array-index='418' value='VCD/Subscription'>
									{' '}
									VCD/Subscription{' '}
								</Option>

								<Option data-option-array-index='419' value='Velo Collection'>
									{' '}
									Velo Collection{' '}
								</Option>

								<Option data-option-array-index='420' value='Velodrome'>
									{' '}
									Velodrome{' '}
								</Option>

								<Option data-option-array-index='421' value='Venues and Sites'>
									{' '}
									Venues and Sites{' '}
								</Option>

								<Option data-option-array-index='422' value='Vietnam'>
									{' '}
									Vietnam{' '}
								</Option>

								<Option data-option-array-index='423' value='Violent Demonstrations'>
									{' '}
									Violent Demonstrations{' '}
								</Option>

								<Option data-option-array-index='424' value='Volcanic Eruption'>
									{' '}
									Volcanic Eruption{' '}
								</Option>

								<Option data-option-array-index='425' value='Volleyball'>
									{' '}
									Volleyball{' '}
								</Option>

								<Option data-option-array-index='426' value='Wake Boarding'>
									{' '}
									Wake Boarding{' '}
								</Option>

								<Option data-option-array-index='427' value='War'>
									{' '}
									War{' '}
								</Option>

								<Option data-option-array-index='428' value='Waste'>
									{' '}
									Waste{' '}
								</Option>

								<Option data-option-array-index='429' value='Water Polo'>
									{' '}
									Water Polo{' '}
								</Option>

								<Option data-option-array-index='430' value='Water Skiing'>
									{' '}
									Water Skiing{' '}
								</Option>

								<Option data-option-array-index='431' value='Watersports'>
									{' '}
									Watersports{' '}
								</Option>

								<Option data-option-array-index='432' value='Weapon'>
									{' '}
									Weapon{' '}
								</Option>

								<Option data-option-array-index='433' value='Weather'>
									{' '}
									Weather{' '}
								</Option>

								<Option data-option-array-index='434' value='Weight Training'>
									{' '}
									Weight Training{' '}
								</Option>

								<Option data-option-array-index='435' value='Weightlifting'>
									{' '}
									Weightlifting{' '}
								</Option>

								<Option data-option-array-index='436' value='Welfare'>
									{' '}
									Welfare{' '}
								</Option>

								<Option data-option-array-index='437' value='Wind Surfing'>
									{' '}
									Wind Surfing{' '}
								</Option>

								<Option data-option-array-index='438' value='Wintersports'>
									{' '}
									Wintersports{' '}
								</Option>

								<Option data-option-array-index='439' value="WNBA - Women's Pro Basketball">
									{' '}
									WNBA - Women's Pro Basketball{' '}
								</Option>

								<Option data-option-array-index='440' value="Women's Baseball">
									{' '}
									Women's Baseball{' '}
								</Option>

								<Option data-option-array-index='441' value="Women's Field">
									{' '}
									Women's Field{' '}
								</Option>

								<Option data-option-array-index='442' value="Women's Professional Soccer">
									{' '}
									Women's Professional Soccer{' '}
								</Option>

								<Option data-option-array-index='443' value="Women's Soccer - International">
									{' '}
									Women's Soccer - International{' '}
								</Option>

								<Option data-option-array-index='444' value="Women's Super League">
									{' '}
									Women's Super League{' '}
								</Option>

								<Option data-option-array-index='445' value="Women's Track">
									{' '}
									Women's Track{' '}
								</Option>

								<Option data-option-array-index='446' value='World Championships, World Cup'>
									{' '}
									World Championships, World Cup{' '}
								</Option>

								<Option data-option-array-index='447' value='World Culture'>
									{' '}
									World Culture{' '}
								</Option>

								<Option data-option-array-index='448' value='World Cup Cricket'>
									{' '}
									World Cup Cricket{' '}
								</Option>

								<Option data-option-array-index='449' value='World Cup of Hockey'>
									{' '}
									World Cup of Hockey{' '}
								</Option>

								<Option data-option-array-index='450' value='World Cup Soccer'>
									{' '}
									World Cup Soccer{' '}
								</Option>

								<Option data-option-array-index='451' value='World Golf Championship'>
									{' '}
									World Golf Championship{' '}
								</Option>

								<Option data-option-array-index='452' value='World League Football'>
									{' '}
									World League Football{' '}
								</Option>

								<Option data-option-array-index='453' value='World War I'>
									{' '}
									World War I{' '}
								</Option>

								<Option data-option-array-index='454' value='World War II'>
									{' '}
									World War II{' '}
								</Option>

								<Option data-option-array-index='455' value='Wrestling'>
									{' '}
									Wrestling{' '}
								</Option>

								<Option data-option-array-index='456' value='WTA Tour Tennis'>
									{' '}
									WTA Tour Tennis{' '}
								</Option>

								<Option data-option-array-index='457' value="WUSA Women's Soccer">
									{' '}
									WUSA Women's Soccer{' '}
								</Option>

								<Option data-option-array-index='458' value='XFL Football'>
									{' '}
									XFL Football{' '}
								</Option>

								<Option data-option-array-index='459' value='Youth Baseball'>
									{' '}
									Youth Baseball{' '}
								</Option>
							</Select>
						</Form.Item>
						Shot speed <span className={styles['star']}>*</span>
						<Tooltip title='Speed of the video when it was captured'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>{' '}
						<Form.Item
							name='speed'
							label=''
							tooltip=''
							rules={[
								{
									required: true,
									message: errorText,
									whitespace: true,
								},
							]}
						>
							<Select placeholder='Select One' onChange={event => changeData(event, 'shotSpeed')}>
								<Option value='REAL_TIME'>Real Time</Option>
								<Option value='SLOW_MOTION'>Slow Motion</Option>
								<Option value='TIME_LAPSE'>Time Lapse</Option>
							</Select>
						</Form.Item>
						Keywords <span className={styles['star']}>*</span>
						<Tooltip title='Specific keywords that relate to the video'>
							<QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
						</Tooltip>{' '}
						<br />
						<Form.Item
							name='keywords'
							label=''
							tooltip=''
							rules={[
								{
									required: true,
									message: errorText,
									whitespace: true,
									type: 'array',
								},
							]}
						>
							<Select placeholder='Surfing, Opera House' mode='tags' onChange={event => changeData(event, 'keywords')}>
								<Option value='Aerologix'> Aerologix</Option>
							</Select>
						</Form.Item>
						<br />
						<Form.Item>
							<div className={styles['button-holder']}>
								<div>
									<Button className={styles['button']} type='primary' htmlType='submit'>
										Save Video
									</Button>
									<Button className={styles['button']} type='primary' htmlType='button' onClick={copyMetadata}>
										Copy Details To All Videos
									</Button>
								</div>
							</div>
						</Form.Item>
					</Form>
				) : contribution && !contribution.isUploading ? (
					<div className={styles['content-static']}>
						Editorial Video Headline: <span className={styles['content-bold']}> {contribution.headline} </span>
						<br />
						Editorial Video Description: <span className={styles['content-bold']}> {contribution.caption}</span>
						<br />
						Country of Shoot: <span className={styles['content-bold']}>{contribution.countryOfShoot} </span>
						<br />
						City: <span className={styles['content-bold']}>{contribution.city} </span>
						<br />
						Province/State: <span className={styles['content-bold']}> {contribution.provinceState}</span>
						<br />
						Video Creation Date: <span className={styles['content-bold']}>{contribution.recordedDate} </span>
						<br />
						Clip Type: <span className={styles['content-bold']}>{contribution.clipType} </span>
						<br />
						Shot Speed: <span className={styles['content-bold']}> {contribution.shotSpeed}</span>
						<br />
						Categories: <span className={styles['content-bold']}>{contribution.iptcCategory} </span>
						<br />
						Supplemental Categories: <span className={styles['content-bold']}>{contribution.iptcSubjects ? contribution.iptcSubjects.join(' , ') : ''} </span>
						<br />
						keywords: <span className={styles['content-bold']}>{getKeywords() ? getKeywords().join(', ') : ''} </span>
						<br />
						Copyright: <span className={styles['content-bold']}>{contribution.copyright} </span>
						<br />
						Amount Earned: <span className={styles['content-bold']}>{contribution.amountEarned} </span>
						<br />
					</div>
				) : contribution && contribution.isUploading ? (
					<div>
						<Alert
							closable
							closeText={<span style={{ color: 'white' }}>X</span>}
							message={
								<span
									style={{ color: 'white' }}
									showIcon
									icon={<InfoCircleOutlined style={{ color: 'white', fontSize: '35px', paddingTop: '5px' }}></InfoCircleOutlined>}
								>
									You will be able to add metadata to the video once upload is complete
								</span>
							}
							className={[styles['alert'], styles['warning-alert']]}
						></Alert>
					</div>
				) : (
					<span></span>
				)}
			</div>
			<AeroModal
				bodyStyle={{ backgroundColor: '#F5F5F5' }}
				width='1200px'
				height='400px'
				title={'Aerologix Footage Metadata Guide'}
				visible={sampleDataModal}
				onCancel={toggleSampleDataModal}
				footer={false}
			>
				<SampleDataModal />
			</AeroModal>
			<CopyMetadataModal
				setDataChanged={setDataChanged}
				setShowCopyMetadataModal={setShowCopyMetadataModal}
				showCopyMetadataModal={showCopyMetadataModal}
				buildDataForCopyMetadata={buildDataForCopyMetadata}
			></CopyMetadataModal>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		bulkUpdateSuccess: state.GettyReducer.bulkUpdateSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		updateContribution: bindActionCreators(updateContribution, dispatch),
		bulkUpdate: bindActionCreators(bulkUpdate, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Metadata)
