import React from 'react'
import { Button, Popover } from 'antd'
import moment from 'moment'
import { CheckCircleFilled, DownloadOutlined, ExclamationCircleFilled, EyeOutlined } from '@ant-design/icons'

const PROJECT_STATE = {
	COMPLETED: 'Completed',
	RESOLVED: 'Resolved',
	DATA_REJECTED_FINAL: 'Data Rejected',
}

export const ordersCols = scope => {
	return [
		{
			title: 'Order name',
			dataIndex: 'name',
			key: 'name',
			render: (name, { ...data }) => (
				<Button
					style={{ position: 'relative', right: '20px' }}
					onClick={() => {
						scope.goToOrderDetails(data.id)
					}}
					type='link'
				>
					{name}
				</Button>
			),
		},
		{
			title: 'Order Start Date',
			dataIndex: 'startDate',
			key: 'startDate',
			render: startDate => (startDate ? moment(startDate).format('DD/MM/YYYY hh:mm A') : '--'),
		},
		{
			title: 'Order Quote',
			dataIndex: 'assignedCredits',
			key: 'assignedCredits',
		},
		{
			title: 'Manager',
			dataIndex: 'id',
			key: 'id',
			render: (id, { ...data }) => data.accountManager.name,
		},
	]
}

export const dataCols = scope => {
	return [
		{
			title: 'Project name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Status',
			dataIndex: 'state',
			key: 'state',
			render: state => PROJECT_STATE[state],
		},
		{
			title: 'Data',
			dataIndex: 'projectId',
			key: 'projectId',
			render: (projectId, { ...data }) => (
				<>
					{data.isBoxSupported ? (
						<>
							{data.processing_id ? (
								<>
									<Popover
										content={
											<>
												<Button onClick={() => scope.downloadProjectData(data)} style={{ width: '100%' }} type='text'>
													<DownloadOutlined style={{ position: 'relative', bottom: '3px', fontSize: '16px', color: '#00B3FF' }} />{' '}
													<span style={{ width: '100%', textAlign: 'left' }}>Raw Data</span>
												</Button>
												<br />
												<Button onClick={() => scope.openProcessedDataDownload(data.processing_id)} type='text'>
													<DownloadOutlined style={{ position: 'relative', bottom: '3px', fontSize: '16px', color: '#00B3FF' }} /> Processed Data
												</Button>
											</>
										}
									>
										<Button style={{ position: 'relative', right: '20px', fontSize: '18px', color: '#00B3FF' }} type='link'>
											<DownloadOutlined />
										</Button>
									</Popover>
									<Popover
										content={
											<>
												<Button onClick={() => scope.previewProjectData(data)} style={{ width: '100%' }} type='text'>
													<EyeOutlined style={{ position: 'relative', bottom: '3px', fontSize: '16px', color: '#00B3FF' }} />{' '}
													<span style={{ width: '100%', textAlign: 'left' }}>Raw Data</span>
												</Button>
												<br />
												<Button href={data.mappa_processing.processedData} target='_blank' type='text'>
													<EyeOutlined style={{ position: 'relative', bottom: '3px', fontSize: '16px', color: '#00B3FF' }} /> Processed Data
												</Button>
											</>
										}
									>
										<Button style={{ position: 'relative', right: '20px', fontSize: '18px', color: '#00B3FF' }} type='link'>
											<EyeOutlined />
										</Button>
									</Popover>
								</>
							) : (
								<>
									<Button
										style={{ position: 'relative', right: '20px', fontSize: '18px', color: '#00B3FF' }}
										type='link'
										onClick={() => scope.downloadProjectData(data)}
									>
										<DownloadOutlined />
									</Button>
									<Button
										style={{ position: 'relative', right: '20px', fontSize: '18px', color: '#00B3FF' }}
										type='link'
										onClick={() => scope.previewProjectData(data)}
									>
										<EyeOutlined />
									</Button>
								</>
							)}
						</>
					) : (
						<Button style={{ fontSize: '18px' }} type='link' onClick={() => scope.downloadProjectData(data)}>
							<DownloadOutlined />
						</Button>
					)}
				</>
			),
		},
		{
			title: 'Data Delivery',
			dataIndex: 'project_additional_information',
			key: 'additionalData',
			render: (project_additional_information, { ...data }) => (
				<>
					{project_additional_information.length ? (
						<Button type='link' onClick={() => scope.openAdditionalDataModal(data)}>
							View
						</Button>
					) : (
						<span style={{ position: 'relative', left: '20px' }}>--</span>
					)}
				</>
			),
		},
		{
			title: 'Action',
			dataIndex: 'state',
			key: 'state',
			render: (state, { ...data }) => (
				<React.Fragment>
					{state === 'COMPLETED' ? (
						<span style={{ position: 'relative', right: '20px' }}>
							<Button onClick={() => scope.openApproveModal(data)} type='link' style={{ color: '#52C41A' }}>
								Approve
							</Button>{' '}
							|{' '}
							<Button onClick={() => scope.openDisapproveModal(data)} type='link' style={{ color: '#F5242D' }}>
								Reject
							</Button>
						</span>
					) : null}
					{state === 'RESOLVED' ? (
						<span>
							Approved <CheckCircleFilled style={{ color: '#52C41A', position: 'relative', bottom: '3px', left: '3px' }} />
						</span>
					) : null}
					{state === 'DATA_REJECTED_FINAL' ? (
						<Popover content={'You have rejected the uploaded data. Your Account Manager is working with the pilot to correct and re-upload project data.'}>
							Rejected <ExclamationCircleFilled style={{ color: '#F5242D', position: 'relative', bottom: '3px', left: '3px' }} />
						</Popover>
					) : null}
				</React.Fragment>
			),
		},
	]
}
