import { Alert } from 'antd'
import React from 'react'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'

const PilotAlerts = props => {
	const { profileData, enterprisePilotBanner } = props
	return (
		<>
			{profileData && !profileData['completed'] ? (
				<Alert
					style={{ marginBottom: '20px' }}
					message={<div style={{ color: 'white', paddingLeft: '10px' }}>Complete profile setup</div>}
					description={<div style={{ color: 'white', paddingLeft: '10px', fontSize: '13px' }}>Complete Profile Details to start receiving jobs</div>}
					type='warning'
					showIcon
					icon={
						<span>
							<MatImage src={IMAGES_SRC.BIG_WHITE_EXCLAMATION_ICON} />
						</span>
					}
					className='payment-page-alert'
				/>
			) : null}
			{enterprisePilotBanner ? (
				<Alert
					style={{ marginBottom: '20px' }}
					message={<span style={{ color: 'white', paddingLeft: '15px' }}>You are eligible to be an enterprise pilot and increase your average earnings!</span>}
					description={
						<span>
							<span style={{ color: 'white', paddingLeft: '15px', fontSize: '13px' }}>
								Head over to the Settings tab and fill in the relevant information to apply
							</span>
							{/* <span className='remind-me-later'>Remind me later</span> */}
						</span>
					}
					type='info'
					closable
					closeText={<span style={{ color: 'white' }}>X</span>}
					showIcon
					icon={
						<span>
							<MatImage src={IMAGES_SRC.INTRODUCE_ICON} />
						</span>
					}
					className='enterprise-pilot-eligiblity-alert'
				/>
			) : null}
		</>
	)
}

export default PilotAlerts
