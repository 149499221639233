import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import './settings.sass'
import { Button, Upload, message, Switch, TimePicker, Popover, Tabs } from 'antd'
import { Icon } from '@ant-design/compatible'
import { Form } from '@ant-design/compatible'
import { getValueFromStorage } from '../../Services/LocalStorageService'
import { changePassword, updateProfile, createEnterprisePilot, getEnterprisePilotData, getProfile, updateEnterprisePilot } from '../../Stores/UserSession/action'
import ChangePassword from './Components/change-password'
import ProjectPrefrences from '../OnBoarding/project-preferences'
import AeroModal from '../../AeroComponents/Modal/index'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import VacationDatePicker from './Components/vacation-date-picker'
import SettingsAlert from './Components/settings-alert'
import { SettingsTabs } from './settings.constants'
import { pushEventToMixpanel } from '../../Services/MixpanelService'

const { RangePicker } = TimePicker

class SettingsForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			profileData: {},
			profileType: getValueFromStorage('PROFILE_TYPE'),
			visible: false,
			enterprisePilotOnboardingPopup: props.location?.state?.enterprisePilotOnboardingPopup,
			specialityListData: [],
			activeInsuranceResponse: true,
			fileList: [],
			successPrompt: false,
			enterprisePilotData: {},
			enterprisePilotBanner: false,
			enterprisePilotUpdatePopup: false,
			vacationConfirmPopover: false,
			vacationMode: false,
			vacationClickLoader: false,
			vacationDatePicker: false,
			vacationDatesRender: false,
			vacationDateView: false,
			hasActiveProjects: false,
			avlWeekday: false,
			avlWeekend: false,
			conWeekday: false,
			conWeekend: false,
			yesActive: true,
			openUpdatePopupFromHome: props.location?.state?.enterprisePilotUpdatePopup,
			currentStep: 1,
			oldState: {},
		}
	}

	componentDidMount() {
		this.props.getProfile()
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.profileData !== nextProps.profileData) {
			this.checkCallEnterpriseData(nextProps.profileData)
			this.setState({
				profileData: nextProps.profileData,
				enterprisePilotBanner:
					nextProps.profileData.enterpriseBannerDetails?.visibility && nextProps.profileData.enterpriseBannerDetails.state === 'NOT_INITIATED' ? true : false,
			})
		}
		if (this.props.updateProfileSuccess !== nextProps.updateProfileSuccess) {
			if (nextProps.updateProfileSuccess) {
				this.handleCancel()
			}
		}
		if (this.props.createEnterprisePilotSuccess !== nextProps.createEnterprisePilotSuccess) {
			this.setState({
				enterprisePilotData: nextProps.createEnterprisePilotSuccess,
				enterprisePilotOnboardingPopup: false,
				successPrompt: true,
			})
		}
		if (this.props.enterprisePilotData !== nextProps.enterprisePilotData) {
			this.updateDataAfterApiCall(nextProps.enterprisePilotData)
		}
		if (this.props.updateEnterprisePilotData !== nextProps.updateEnterprisePilotData) {
			this.updateDataAfterApiCall(nextProps.updateEnterprisePilotData)
			this.setState({ enterprisePilotUpdatePopup: false })
		}
		if (this.props.changePasswordSuccess !== nextProps.changePasswordSuccess) {
			this.handleCancel()
		}
	}

	handleSubmit = () => {
		const { fileList, enterprisePilotData } = this.state
		if (fileList.length === 0) {
			message.error(<span style={{ position: 'relative', top: '3px' }}>Insurance is required to be an enterprise pilot</span>)
		} else {
			let body = new FormData()
			let preferenceData = { specialities: enterprisePilotData.specialities }
			let fileData
			fileList.forEach(file => {
				fileData = file
			})
			body.append('activeDroneInsurance', fileData)
			body.append('preferenceData', JSON.stringify(preferenceData))
			this.props.createEnterprisePilot(body, { preferenceData, mixpanel_type: 'drone_insurance' })
		}
	}

	handlePreferenceUpdate = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const { enterprisePilotData, vacationMode } = this.state

				let body = new FormData()
				const daywiseAvailabilities = [
					{
						day: 'WEEKDAYS',
						startTime: String(moment(values.availabilityWeekdaysRange[0]).format('HH:mm')),
						endTime: String(moment(values.availabilityWeekdaysRange[1]).format('HH:mm')),
						active: values.availabilityWeekdaysActive,
					},
					{
						day: 'WEEKENDS',
						startTime: String(moment(values.availabilityWeekendsRange[0]).format('HH:mm')),
						endTime: String(moment(values.availabilityWeekendsRange[1]).format('HH:mm')),
						active: values.availabilityWeekendsActive,
					},
				]
				const preferredContactTimings = [
					{
						day: 'WEEKDAYS',
						startTime: String(moment(values.contactWeekdaysRange[0]).format('HH:mm')),
						endTime: String(moment(values.contactWeekdaysRange[1]).format('HH:mm')),
						active: values.contactWeekdaysActive,
					},
					{
						day: 'WEEKENDS',
						startTime: String(moment(values.contactWeekendsRange[0]).format('HH:mm')),
						endTime: String(moment(values.contactWeekendsRange[1]).format('HH:mm')),
						active: values.contactWeekendsActive,
					},
				]
				const vacationModeDetails = {
					from: values.vacationRangeValue ? String(moment(values.vacationRangeValue[0]).unix()) : String(moment().unix()),
					to: values.vacationRangeValue ? String(moment(values.vacationRangeValue[1]).unix()) : String(moment().unix()),
				}
				const preferenceData = {
					specialities: enterprisePilotData.specialities,
					vacationMode: vacationMode,
					vacationModeDetails: vacationModeDetails,
					daywiseAvailabilities: daywiseAvailabilities,
					preferredContactTimings: preferredContactTimings,
				}
				body.append('preferenceData', JSON.stringify(preferenceData))
				this.props.updateEnterprisePilot({
					body: body,
					mixpanel_type: 'enterprise_project_preferences_updated',
					enterprisePilotData: this.state.oldState,
				})
			}
		})
	}

	handleOk = () => {
		this.setState({ loading: true })
		setTimeout(() => {
			this.setState({ loading: false, visible: false })
		}, 3000)
	}

	handleCancel = () => {
		this.setState({ visible: false })
	}

	openMadal = modalKey => {
		switch (modalKey) {
			case 'CHANGE_PASSWORD':
				this.setState({
					visible: true,
					modalTitle: 'Change Password',
					modalComponent: <ChangePassword updatePassword={this.completeProfile} />,
				})
				break
			case 'preferences':
				this.setState({
					visible: true,
					modalTitle: 'Project Preferences',
					modalComponent: <ProjectPrefrences data={this.state.profileData['preferences']} updateProfile={this.props.updateProfile} />,
				})
				break
			default:
				break
		}
	}

	completeProfile = data => {
		this.props.changePassword({ ...data, mixpanel_type: 'password_updated' })
	}

	renderAddDetailsBtn = modalKey => {
		switch (modalKey) {
			default:
				break
			case 'preferences':
				return (
					<Button
						style={{ display: 'flex', alignItems: 'center' }}
						type='primary'
						onClick={() => {
							this.openMadal(modalKey)
						}}
					>
						{this.state.profileData && this.state.profileData['preferences'] ? <Icon type='edit'></Icon> : <Icon type='plus'></Icon>}
						{this.state.profileData && this.state.profileData['preferences'] ? 'Edit' : 'Add Preferences'}
					</Button>
				)
			case 'enterprise':
				return (
					<React.Fragment>
						{this.state.enterprisePilotData?.verification?.state === 'NOT_INITIATED' ? (
							<Button
								style={{ display: 'flex', alignItems: 'center' }}
								type='primary'
								onClick={() => {
									this.setState({ enterprisePilotOnboardingPopup: true })
								}}
							>
								<Icon type='plus'></Icon> Add Details
							</Button>
						) : this.state.enterprisePilotData?.verification?.state === 'VERIFICATION_SUCCEEDED' ? (
							<Button
								style={{ display: 'flex', alignItems: 'center' }}
								type='primary'
								onClick={() => {
									this.handleUpdatePopupOpen()
								}}
							>
								<Icon type='edit'></Icon> Edit
							</Button>
						) : null}
					</React.Fragment>
				)
		}
	}

	handleUpdatePopupOpen = () => {
		if (this.state.vacationMode && this.state.hasActiveProjects) {
			this.setState({ enterprisePilotUpdatePopup: true, vacationDatesRender: true, vacationDateView: true })
		} else {
			this.setState({ enterprisePilotUpdatePopup: true })
		}
	}

	handleEnPiOnCancel = () => {
		this.setState({
			enterprisePilotOnboardingPopup: false,
		})
	}

	changeActiveDroneInsuranceResponse = resp => {
		if (resp === 'YES') {
			this.setState({ activeInsuranceResponse: true, yesActive: true })
		} else {
			this.setState({ activeInsuranceResponse: false, yesActive: false })
		}
	}

	turnSpecialityActive = key => {
		let enterprisePilotData = this.state.enterprisePilotData
		let specialityList = enterprisePilotData.specialities
		for (let index = 0; index < specialityList.length; index++) {
			if (specialityList[index].key === key) {
				if (specialityList[index].active) {
					specialityList[index].active = false
				} else {
					specialityList[index].active = true
				}
			}
		}
		enterprisePilotData.specialities = specialityList
		this.setState({ enterprisePilotData: enterprisePilotData })
	}

	closeSubmitionPrompt = () => {
		this.setState({ successPrompt: false })
	}

	checkCallEnterpriseData = data => {
		if (this.state.profileType === 'PILOT' && data['enterpriseEligibility']) {
			this.props.getEnterprisePilotData()
		}
	}

	closeUpdatePopup = () => {
		this.setState({ enterprisePilotUpdatePopup: false, vacationConfirmPopover: false, vacationDatePicker: false, vacationClickLoader: false })
	}

	handleVacation = () => {
		if (!this.state.vacationMode) {
			this.setState({
				vacationConfirmPopover: true,
				vacationClickLoader: true,
			})
		} else {
			this.setState({
				vacationMode: false,
				vacationDatesRender: false,
				vacationDateView: false,
			})
		}
	}

	handleVacationAccept = () => {
		if (this.state.hasActiveProjects) {
			this.setState({
				vacationConfirmPopover: false,
				vacationClickLoader: false,
				vacationMode: true,
				vacationDatePicker: true,
				vacationDatesRender: true,
			})
		} else {
			this.setState({
				vacationConfirmPopover: false,
				vacationClickLoader: false,
				vacationMode: true,
			})
		}
	}

	handleVacationCancel = () => {
		this.setState({
			vacationMode: false,
			vacationClickLoader: false,
			vacationConfirmPopover: false,
		})
	}

	storeVacationDate = () => {
		this.setState({
			vacationDatePicker: false,
			vacationDateView: true,
		})
	}

	handleSwitchDisable = stateName => {
		this.setState(prevState => ({
			[stateName]: !prevState[stateName],
		}))
	}

	updateDataAfterApiCall = responseData => {
		this.setState({
			oldState: { ...this.state.enterprisePilotData },
			enterprisePilotData: { ...responseData },
			vacationMode: responseData.vacationMode,
			hasActiveProjects: responseData.hasActiveProjects,
			avlWeekday: !responseData.daywiseAvailabilities[0].active,
			avlWeekend: !responseData.daywiseAvailabilities[1].active,
			conWeekday: !responseData.preferredContactTimings[0].active,
			conWeekend: !responseData.preferredContactTimings[1].active,
		})
		if (this.state.openUpdatePopupFromHome) {
			this.handleUpdatePopupOpen()
		}
	}

	openCreateEnterprisePilotPopup = () => {
		this.setState({ enterprisePilotOnboardingPopup: true })
	}

	handleTabChange = val => {
		this.setState({ currentStep: Number(val) })
	}

	render() {
		const {
			visible,
			modalTitle,
			modalComponent,
			profileType,
			profileData,
			enterprisePilotOnboardingPopup,
			activeInsuranceResponse,
			fileList,
			successPrompt,
			enterprisePilotData,
			enterprisePilotBanner,
			enterprisePilotUpdatePopup,
			vacationConfirmPopover,
			vacationMode,
			vacationClickLoader,
			vacationDatePicker,
			vacationDatesRender,
			vacationDateView,
			avlWeekday,
			avlWeekend,
			conWeekday,
			conWeekend,
			yesActive,
			currentStep,
		} = this.state
		const { getFieldDecorator } = this.props.form
		const props = {
			onRemove: file => {
				this.setState(state => {
					const index = state.fileList.indexOf(file)
					const newFileList = state.fileList.slice()
					newFileList.splice(index, 1)
					return {
						fileList: newFileList,
					}
				})
			},
			beforeUpload: file => {
				this.setState(state => ({
					fileList: [...state.fileList, file],
				}))
				return false
			},
			fileList,
		}
		return (
			<React.Fragment>
				<div className='projects-heading' style={{ marginLeft: '30px', marginBottom: '20px' }}>
					Settings{' '}
					{profileData.enterprisePilot ? (
						<span className='enterprise-flag-box'>
							<MatImage src={IMAGES_SRC.ENTERPRISE_FLAG_ICON} />
							&nbsp; Enterprise Pilot
						</span>
					) : null}
				</div>
				{profileType === 'PILOT' ? (
					<>
						<Tabs
							className='create-new-project-form'
							size='small'
							activeKey={String(currentStep)}
							onChange={val => {
								this.handleTabChange(val)

								// Sending settings sub menu click events to Mixpanel
								pushEventToMixpanel('', `${SettingsTabs(this)[val - 1].name} Clicked`)
							}}
							style={{ padding: '10px' }}
							tabPosition='left'
							tabBarStyle={{ height: '90vh', color: '#84818A' }}
						>
							{SettingsTabs(this).map(item => (
								<Tabs.TabPane tab={item.tab} key={item.key}>
									<SettingsAlert enterprisePilotBanner={enterprisePilotBanner} />
									{item.component}
								</Tabs.TabPane>
							))}
						</Tabs>
						<Tabs
							className='create-new-project-form-mobile'
							activeKey={String(currentStep)}
							onChange={val => {
								this.handleTabChange(val)

								// Sending settings sub menu click events to Mixpanel
								pushEventToMixpanel('', `${SettingsTabs(this)[val - 1].name} Clicked`)
							}}
							style={{ padding: '20px' }}
						>
							{SettingsTabs(this).map(item => (
								<>
									{item.key === '3' ? (
										profileData.enterprisePilot ? (
											<Tabs.TabPane tab={item.tab} key={item.key}>
												<SettingsAlert enterprisePilotBanner={enterprisePilotBanner} />
												{item.component}
											</Tabs.TabPane>
										) : null
									) : item.key === '1' ? (
										profileData.socialMediaChannel === 'EMAIL' ? (
											<Tabs.TabPane tab={item.tab} key={item.key}>
												<SettingsAlert enterprisePilotBanner={enterprisePilotBanner} />
												{item.component}
											</Tabs.TabPane>
										) : null
									) : (
										<Tabs.TabPane tab={item.tab} key={item.key}>
											<SettingsAlert enterprisePilotBanner={enterprisePilotBanner} />
											{item.component}
										</Tabs.TabPane>
									)}
								</>
							))}
						</Tabs>
					</>
				) : (
					<>
						<Tabs
							className='create-new-project-form'
							size='small'
							activeKey={String(currentStep)}
							onChange={this.handleTabChange}
							style={{ padding: '10px' }}
							tabPosition='left'
							tabBarStyle={{ height: '90vh', color: '#84818A' }}
						>
							<Tabs.TabPane tab='Password' key='1'>
								<ChangePassword updatePassword={this.completeProfile} />
							</Tabs.TabPane>
						</Tabs>
						<Tabs className='create-new-project-form-mobile' activeKey={String(currentStep)} onChange={this.handleTabChange} style={{ padding: '20px' }}>
							<Tabs.TabPane tab='Password' key='1'>
								<ChangePassword updatePassword={this.completeProfile} />
							</Tabs.TabPane>
						</Tabs>
					</>
				)}

				{visible && (
					<AeroModal style={{ width: '70%' }} visible={visible} title={modalTitle} onOk={this.handleOk} onCancel={this.handleCancel} footer={null}>
						{modalComponent}
					</AeroModal>
				)}
				<AeroModal
					bodyStyle={{ background: '#F5F5F5' }}
					title='Enterprise Pilot Onboarding'
					visible={enterprisePilotOnboardingPopup}
					footer={false}
					onCancel={this.handleEnPiOnCancel}
				>
					<div className='en-onboarding-elem'>
						<span className='main-label-text'>1. Do you have an active Drone Insurance?</span>
					</div>
					<div className='en-onboarding-elem'>
						<Button
							onClick={() => {
								this.changeActiveDroneInsuranceResponse('YES')
							}}
							type={yesActive ? 'primary' : ''}
							style={{ width: '128px' }}
						>
							Yes
						</Button>
						<Button
							onClick={() => {
								this.changeActiveDroneInsuranceResponse('NO')
							}}
							type={yesActive ? '' : 'primary'}
							style={{ width: '128px' }}
						>
							No
						</Button>
					</div>
					{activeInsuranceResponse ? (
						<React.Fragment>
							<div className='en-onboarding-elem'>
								<span className='main-label-text'>Insurance Plan</span> <span style={{ color: 'red' }}>*</span>{' '}
								<span style={{ color: '#858585' }}>(jpeg, png or PDF)</span>
							</div>
							<div style={{ paddingLeft: '10px' }}>
								<Upload {...props}>
									<Button style={{ width: '254px' }}>
										<Icon style={{ position: 'relative', top: '-4px' }} type='upload-o' /> Click to Upload
									</Button>
								</Upload>
							</div>
						</React.Fragment>
					) : (
						<div className='en-onboarding-elem'>
							<Button href='/upgrades' target='_blank' style={{ width: '254px' }} type='primary'>
								<MatImage src={IMAGES_SRC.BUY_INSURANCE_BTN_ICON} /> &nbsp;&nbsp;Buy Insurance
							</Button>
						</div>
					)}

					<div style={{ paddingTop: '30px' }} className='en-onboarding-elem'>
						<span className='main-label-text'>2. Do you specialize in drone data collection in any of the following domains:</span>
						<div style={{ paddingTop: '10px' }}>
							{enterprisePilotData?.specialities?.map((value, index) => (
								<div
									onClick={() => {
										this.turnSpecialityActive(value.key)
									}}
									key={index}
									className={value.active ? 'speciality-box-active' : 'speciality-box-default'}
								>
									{value.name}
								</div>
							))}
						</div>
					</div>
					<div className='speciality-footer-create'>
						<Button onClick={this.handleEnPiOnCancel} style={{ marginRight: '20px' }}>
							Cancel
						</Button>
						<Button onClick={this.handleSubmit} type='primary'>
							Submit
						</Button>
					</div>
				</AeroModal>
				<AeroModal visible={successPrompt} footer={false} onCancel={this.closeSubmitionPrompt}>
					<div className='modal-content'>
						<MatImage src={IMAGES_SRC.SUCCESS_ICON} />
						<div style={{ fontSize: '20px', color: 'black', paddingTop: '35px' }}>Thanks for applying to be an Aerologix Enterprise Pilot! </div>
						<div style={{ paddingTop: '20px' }} className='line-2'>
							Your application will be processed within 2 working days
						</div>
					</div>
				</AeroModal>
				<AeroModal
					bodyStyle={{ background: '#F5F5F5' }}
					title='Edit Enterprise Project Prefernces'
					visible={enterprisePilotUpdatePopup}
					footer={false}
					onCancel={this.closeUpdatePopup}
					width={1000}
				>
					<Form onSubmit={this.handlePreferenceUpdate} encType='multipart/form-data'>
						<div className={vacationDatesRender ? 'update-pref-left-item-vacation' : 'update-pref-left-item'}>
							<div style={{ paddingBottom: '10px' }}>
								<span className='main-label-text'>1. Drone Insurance</span>
							</div>

							<div style={{ color: '#262626' }}>Insurance Plan</div>

							<Icon type='paper-clip-o' />
							<span style={{ position: 'relative', top: '3px', right: '5px' }}>
								<Button target='_blank' href={enterprisePilotData.activeDroneInsuranceUrl} type='link'>
									Click to view
								</Button>
							</span>
							<div style={{ position: 'relative', bottom: '-102px' }}>
								<span className='main-label-text'>3. Day and time preferences</span>
								<span style={{ marginLeft: '100px' }} className='main-label-text'>
									Vacation Mode{' '}
									<Popover
										visible={vacationConfirmPopover}
										content={
											<div style={{ width: '336px', padding: '25px', textAlign: 'justify' }}>
												<div style={{ color: 'black', marginBottom: '15px' }}>
													<MatImage src={IMAGES_SRC.RED_EXCLAMATION_ICON} /> Turn on Vacation Mode ?
												</div>
												<div style={{ color: '#595959' }}>
													Turning ON "Vacation Mode" only stops NEW projects from being allocated to you. In case you have already been assigned a
													project, you will be required to complete the project before the deadline.
												</div>
												<div style={{ position: 'relative', top: '20px', left: '135px' }}>
													<Button onClick={this.handleVacationCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;
													<Button onClick={this.handleVacationAccept} type='primary'>
														Accept
													</Button>
												</div>
											</div>
										}
										placement='topRight'
										trigger='click'
									>
										<Switch onClick={this.handleVacation} loading={vacationClickLoader} checked={vacationMode} style={{ marginLeft: '10px' }} />
									</Popover>
								</span>
								{vacationDatesRender && enterprisePilotData && (
									<VacationDatePicker
										storeVacationDate={this.storeVacationDate}
										vacationDateView={vacationDateView}
										vacationDatePicker={vacationDatePicker}
										data={enterprisePilotData}
										{...this.props}
									/>
								)}
								<div style={{ marginTop: '15px' }}>
									<div className='days-box-tag'>Weekdays</div>
									{getFieldDecorator('availabilityWeekdaysRange', {
										initialValue: [
											moment(enterprisePilotData.daywiseAvailabilities ? enterprisePilotData.daywiseAvailabilities[0].startTime : '09:00 AM', 'hh:mm A'),
											moment(enterprisePilotData.daywiseAvailabilities ? enterprisePilotData.daywiseAvailabilities[0].endTime : '07:00 PM', 'hh:mm A'),
										],
									})(
										<RangePicker
											disabled={vacationMode || avlWeekday}
											use12Hours
											style={{ marginLeft: '14px', width: '205px', borderRadius: '4px' }}
											format='hh:mm A'
										/>,
									)}
									{getFieldDecorator('availabilityWeekdaysActive')(
										<Switch
											defaultChecked={enterprisePilotData.daywiseAvailabilities ? enterprisePilotData.daywiseAvailabilities[0].active : true}
											style={{ marginLeft: '15px' }}
											disabled={vacationMode}
											onChange={() => {
												this.handleSwitchDisable('avlWeekday')
											}}
										/>,
									)}
								</div>
								<div style={{ marginTop: '15px' }}>
									<div className='days-box-tag'>Weekends</div>
									{getFieldDecorator('availabilityWeekendsRange', {
										initialValue: [
											moment(enterprisePilotData.daywiseAvailabilities ? enterprisePilotData.daywiseAvailabilities[1].startTime : '09:00 AM', 'hh:mm A'),
											moment(enterprisePilotData.daywiseAvailabilities ? enterprisePilotData.daywiseAvailabilities[1].endTime : '07:00 PM', 'hh:mm A'),
										],
									})(
										<RangePicker
											use12Hours
											disabled={vacationMode || avlWeekend}
											style={{ marginLeft: '14px', width: '205px', borderRadius: '4px' }}
											format='hh:mm A'
										/>,
									)}
									{getFieldDecorator('availabilityWeekendsActive')(
										<Switch
											disabled={vacationMode}
											onChange={() => {
												this.handleSwitchDisable('avlWeekend')
											}}
											defaultChecked={enterprisePilotData.daywiseAvailabilities ? enterprisePilotData.daywiseAvailabilities[0].active : true}
											style={{ marginLeft: '15px' }}
										/>,
									)}
								</div>
							</div>
						</div>

						<div className='update-pref-right-item'>
							<span className='main-label-text'>2. Do you specialize in drone data collection in any of the following domains:</span>
							<div style={{ paddingTop: '10px' }}>
								{enterprisePilotData?.specialities?.map((value, index) => (
									<div
										onClick={() => {
											this.turnSpecialityActive(value.key)
										}}
										key={index}
										className={value.active ? 'speciality-box-active' : 'speciality-box-default'}
									>
										{value.name}
									</div>
								))}
							</div>
							<div style={{ position: 'relative', bottom: '-15px' }}>
								<span className='main-label-text'>
									4. Preferred contact hours{' '}
									<Popover
										content='In case there is a job outside your current Date & Time preferences, we want to be able to contact you at a time of your preference'
										trigger='hover'
										style={{ background: 'black', color: 'white' }}
									>
										<span>
											<MatImage src={IMAGES_SRC.INFO_CIRCLE_ICON} />
										</span>
									</Popover>
								</span>
								<div style={{ marginTop: '15px' }}>
									<div className='days-box-tag'>Weekdays</div>
									{getFieldDecorator('contactWeekdaysRange', {
										initialValue: [
											moment(enterprisePilotData.preferredContactTimings ? enterprisePilotData.preferredContactTimings[0].startTime : '09:00 AM', 'hh:mm A'),
											moment(enterprisePilotData.preferredContactTimings ? enterprisePilotData.preferredContactTimings[0].endTime : '07:00 PM', 'hh:mm A'),
										],
									})(<RangePicker disabled={conWeekday} use12Hours style={{ marginLeft: '14px', width: '205px', borderRadius: '4px' }} format='hh:mm A' />)}
									{getFieldDecorator('contactWeekdaysActive')(
										<Switch
											onChange={() => {
												this.handleSwitchDisable('conWeekday')
											}}
											defaultChecked={enterprisePilotData.preferredContactTimings ? enterprisePilotData.preferredContactTimings[0].active : true}
											style={{ marginLeft: '15px' }}
										/>,
									)}
								</div>
								<div style={{ marginTop: '15px' }}>
									<div className='days-box-tag'>Weekends</div>
									{getFieldDecorator('contactWeekendsRange', {
										initialValue: [
											moment(enterprisePilotData.preferredContactTimings ? enterprisePilotData.preferredContactTimings[1].startTime : '09:00 AM', 'hh:mm A'),
											moment(enterprisePilotData.preferredContactTimings ? enterprisePilotData.preferredContactTimings[1].endTime : '07:00 PM', 'hh:mm A'),
										],
									})(<RangePicker disabled={conWeekend} use12Hours style={{ marginLeft: '14px', width: '205px', borderRadius: '4px' }} format='hh:mm A' />)}
									{getFieldDecorator('contactWeekendsActive')(
										<Switch
											onChange={() => {
												this.handleSwitchDisable('conWeekend')
											}}
											defaultChecked={enterprisePilotData.preferredContactTimings ? enterprisePilotData.preferredContactTimings[1].active : true}
											style={{ marginLeft: '15px' }}
										/>,
									)}
								</div>
							</div>
						</div>

						<div className='speciality-footer'>
							<Button onClick={this.closeUpdatePopup} style={{ marginRight: '20px' }}>
								Cancel
							</Button>
							<Button htmlType='submit' type='primary'>
								Save Changes
							</Button>
						</div>
					</Form>
				</AeroModal>
			</React.Fragment>
		)
	}
}

const Settings = Form.create({ name: 'SETTINGS_FORM' })(SettingsForm)

const mapStateToProps = (state, ownProps) => {
	return {
		profileData: state.UserSessionReducer.profileData,
		updateProfileSuccess: state.UserSessionReducer.updateProfileSuccess,
		createEnterprisePilotSuccess: state.UserSessionReducer.createEnterprisePilotSuccess,
		enterprisePilotData: state.UserSessionReducer.enterprisePilotData,
		updateEnterprisePilotData: state.UserSessionReducer.updateEnterprisePilotData,
		changePasswordSuccess: state.UserSessionReducer.changePasswordSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	// ... normally is an object full of action creators
	return {
		changePassword: bindActionCreators(changePassword, dispatch),
		updateProfile: bindActionCreators(updateProfile, dispatch),
		createEnterprisePilot: bindActionCreators(createEnterprisePilot, dispatch),
		getEnterprisePilotData: bindActionCreators(getEnterprisePilotData, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
		updateEnterprisePilot: bindActionCreators(updateEnterprisePilot, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Settings)
