import { post, get, put, deleteMethod } from '../../Api/http-interceptor'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import { getProjectStatus } from '../../Services/GetProjectStatus'

export const createProjectApi = async body => {
	const res = await post('CREATE_PROJECT', body)

	// Sending Project Created event to Mixpanel
	if (body.mixpanel_type === 'project_created' && res.status_code === 200) {
		pushEventToMixpanel('', 'Project Created', body.mixpanel_data)
	}

	return res
}

export const updateProjectApi = async body => {
	const res = await put('UPDATE_PROJECT', body)

	// Sending Project Scope Modified event to Mixpanel
	if (body.mixpanel_type === 'project_scope_modified')
		pushEventToMixpanel('', 'Project Scope Modified', {
			...body.mixpanel_data,
			'Is The Modification Successful': res.status_code === 200,
			'Date And Time': new Date().getTime(),
		})

	return res
}

export const sendMessageApi = body => {
	return post('SEND_MESSAGE', body)
}

export const createTransactionApi = body => {
	return post('CREATE_TRANSACTION', body)
}

export const paymentApi = async body => {
	const res = await post('PAYMENT', body)

	// Sending Bid Accepted event to Mixpanel
	if (body.mixpanel_type === 'bid_accepted') {
		pushEventToMixpanel('', 'Bid Accepted', {
			'Project ID': body.consumerProjectData.projectId,
			'Project Budget': body.consumerProjectData.total_fee,
			'Pilot ID': body.pilotData.id,
			'Bid Amount': res.data?.amount,
			'Was Payment Successful': res.status_code === 200,
			'Is Budget Neogtiable': body.consumerProjectData.is_negotiable,
		})
	}

	return res
}

export const getProjectsApi = () => {
	return get('GET_PROJECTS')
}

export const getAllQuotesApi = () => {
	return get('GET_ALL_QUOTES')
}

export const updateQuoteApi = async body => {
	const res = await put('UPDATE_QUOTE', body)

	// Sending "Project Invitation Accepted" and "Project Invitation Rejected" events to Mixpanel
	if ((body.mixpanel_type === 'project_invitation_accepted' || body.mixpanel_type === 'project_invitation_rejected') && res.status_code === 200) {
		pushEventToMixpanel('', `${body.mixpanel_type === 'project_invitation_accepted' ? 'Project Invitation Accepted' : 'Project Invitation Rejected'}`, {
			'Project ID': body.projectData.projectId,
			'Project Name': body.projectData.name,
			Timestamp: new Date().getTime(),
			'Pilot Earnings': body.projectData.quote?.amount,
			'Project Sub Type': body.projectData.type
				.split('_')
				.map(el => `${el.charAt(0).toUpperCase()}${el.slice(1).toLowerCase()}`)
				.join(' '),
		})
	}

	// Sending Bid Rejected event to Mixpanel
	if (body.mixpanel_type === 'bid_rejected' && res.status_code === 200) {
		pushEventToMixpanel('', 'Bid Rejected', {
			'Project ID': body.consumerProjectData.projectId,
			'Project Budget': body.consumerProjectData.total_fee,
			'Pilot ID': res.data.pilotId,
			'Bid Amount': res.data.amount,
			'Is Budget Negotiable': body.consumerProjectData.is_negotiable,
		})
	}

	return res
}

export const getPilotsApi = queryParams => {
	return get('GET_PILOTS', null, queryParams)
}

export const getQuotesApi = queryParams => {
	return get('GET_QUOTES', null, queryParams)
}

export const getMessagesApi = pathParams => {
	return get('GET_MESSAGES', pathParams)
}

export const invitePilotsApi = async body => {
	const res = await post('INVITE_PILOTS', body)
	const data = body[0]

	// Sending Bid Submitted event to Mixpanel
	if (data.mixpanel_type === 'bid_submitted') {
		pushEventToMixpanel('', 'Bid Submitted', {
			'Bid Amount': data.amount,
			'Project ID': data.project_id,
			'Project Name': data.projectData.name,
			'Project Budget': data.projectData.total_fee,
			// 'Re-Submitting After Rejection': data.projectData.quote?.status.toLowerCase() === 'rejected',
			// 'Re-Submitting After Withdrawal': data.projectData.quote?.status.toLowerCase() === 'withdrawn',
			'Was The Submission Successful': res.status_code === 200,
		})
	}

	return res
}

export const getDownloadUrlApi = pathParams => {
	return get('GET_DOWNLOAD_URL', pathParams)
}

export const getPreviewUrlApi = pathParams => {
	return get('GET_PREVIEW_URL', pathParams)
}

export const markInFlightApi = async body => {
	const res = await put('MARK_IN_FLIGHT', body)

	// Sending Mark In-Flight event to Mixpanel
	if (body.mixpanel_type === 'markInFlight' && res.status_code === 200) {
		pushEventToMixpanel('', 'Marked In-Flight', {
			Timestamp: new Date().getTime(),
			'Project ID': body.projectData.projectId,
			'Project Name': body.projectData.name,
			'Project Type': body.projectData.order_id ? 'Enterprise' : 'Commercial',
		})
	}

	return res
}

export const markReadyToProcessApi = async body => {
	const res = await put('MARK_READY_TO_PROCESS', body)

	// Sending Data Submitted For Review event to Mixpanel
	if (body.mixpanel_type === 'markReadyToProcess' && res.status_code === 200) {
		pushEventToMixpanel('', 'Data Submitted For Review', {
			Timestamp: new Date().getTime(),
			'Project ID': body.projectData.projectId,
			'Project Name': body.projectData.name,
			'Project Type': body.projectData.order_id ? 'Enterprise' : 'Commercial',
			'Was The Data Rejected By Client Previously': !!body.projectData.unsatisfactoryReason,
		})
	}

	return res
}

export const markCompleteApi = body => {
	return put('MARK_COMPLETE', body)
}

export const markResolvedApi = async body => {
	const res = await put('MARK_RESOLVED', body)

	// Sending Satisfactory Data (Project Completed) event to Mixpanel
	if (body.mixpanel_type === 'markResolved' && res.status_code === 200) {
		pushEventToMixpanel('', 'Satisfactory Data (Project Completed)', {
			'Project ID': body.project_id,
			'Pilot ID': body.pilot_id,
		})
	}

	return res
}

export const getProjectForConsumerApi = async (pathParams, queryParams, mixpanelData) => {
	const res = await get('GET_PROJECT_FOR_CONSUMER', pathParams, queryParams)

	// Sending project details viewed event to Mixpanel
	if (mixpanelData?.mixpanel_type === 'project_details_viewed' && res.status_code === 200) {
		let payload = {
			'Project ID': res.data.projectId,
			'Project Name': res.data.name,
			'Project Status': getProjectStatus('COMMERCIAL', res.data.state, res.data.quote?.status ?? ''),
			'Project Location': res.data.address,
			'Consumer ID': res.data.consumer_id,
			'Consumer Name': res.data.consumer.name,
			Budget: res.data.total_fee,
			'Number Of Bids Received': mixpanelData.totalBids,
		}
		if (res.data.state === 'ALLOTTED') {
			payload['Final Project Amount'] = res.data.total_fee
		}
		pushEventToMixpanel('', 'Project Details Viewed', payload)
	}

	return res
}

export const getProjectForPilotApi = async (pathParams, queryParams, mixpanelData) => {
	const res = await get('GET_PROJECT_FOR_PILOT', pathParams, queryParams)

	// Sending project details viewed event to Mixpanel
	if (mixpanelData?.mixpanel_type === 'project_details_viewed' && res.status_code === 200) {
		pushEventToMixpanel('', 'Project Details Viewed', {
			'Project Type': res.data.order_id ? 'Enterprise' : 'Commercial',
			'Project ID': res.data.projectId,
			'Project Name': res.data.name,
			'Project Status': getProjectStatus(res.data.order_id ? 'ENTERPRISE' : 'COMMERCIAL', res.data.state, res.data.quote?.status ?? ''),
			'Project location': res.data.address,
			'Consumer ID': res.data.consumer_id,
			'Consumer Name': res.data.consumer.name,
			Budget: res.data.total_fee,
			'Project Sub Type': res.data.type
				.split('_')
				.map(el => `${el.charAt(0).toUpperCase()}${el.slice(1).toLowerCase()}`)
				.join(' '),
		})
	}

	return res
}

export const checkUploadedPreviewApi = pathParams => {
	return get('CHECK_UPLOADED_PREVIEW', pathParams)
}

export const checkUploadedDataApi = pathParams => {
	return get('CHECK_UPLOADED_DATA', pathParams)
}

export const getPaymentHistoryApi = queryParams => {
	return get('GET_PAYMENT_HISTORY', null, queryParams)
}

export const getProjectTimelineApi = queryParams => {
	return get('GET_PROJECT_TIMELINE', null, queryParams)
}

export const disapprovePreviewDataApi = body => {
	return post('DISAPPROVE_PREVIEW_DATA', body)
}

export const disapproveFinalDataApi = async (body, mixpanelData) => {
	const res = await post('DISAPPROVE_FINAL_DATA', body)

	// Sending Unsatisfactory Data event to Mixpanel
	if (mixpanelData?.mixpanel_type === 'unsatisfactory_data' && res.status_code === 200) {
		pushEventToMixpanel('', 'Unsatisfactory Data', {
			'Project ID': mixpanelData.projectId,
			'Pilot ID': mixpanelData.pilotId,
		})
	}

	return res
}

export const conflictResolutionPreviewApi = body => {
	return put('CONFLICT_RESOLUTION_PREVIEW', body)
}

export const conflictResolutionFinalApi = async body => {
	const res = await put('CONFLICT_RESOLUTION_FINAL', body)

	// Sending Data Submitted For Review event to Mixpanel
	if (body.mixpanel_type === 'conflictResolutionFinal' && res.status_code === 200) {
		pushEventToMixpanel('', 'Data Submitted For Review', {
			Timestamp: new Date().getTime(),
			'Project ID': body.projectData.projectId,
			'Project Name': body.projectData.name,
			'Project Type': body.projectData.order_id ? 'Enterprise' : 'Commercial',
			'Was The Data Rejected By Client Previously': !!body.projectData.unsatisfactoryReason,
		})
	}

	return res
}

export const getAllActiveLiveStreamsApi = () => {
	return get('GET_ALL_ACTIVE_LIVE_STREAMS')
}

export const getStreamDetailsApi = pathParams => {
	return get('GET_STREAM_DETAILS', pathParams)
}

export const getProjectsOverviewApi = () => {
	return get('GET_PROJECTS_OVERVIEW')
}

export const getTransactionsPageDataApi = () => {
	return get('GET_TRANSACTIONS_PAGE_DATA')
}

export const getAccountManagerApi = () => {
	return get('GET_ACCOUNT_MANAGER')
}

export const createSupportTicketApi = body => {
	return post('CREATE_SUPPORT_TICKET', body)
}

export const getTransactionsHistoryApi = queryParams => {
	return get('GET_TRANSACTIONS_HISTORY', null, queryParams)
}

export const getAllCollaboratorsApi = () => {
	return get('GET_ALL_COLLABORATORS')
}

export const getAllOrdersApi = queryParams => {
	return get('GET_ALL_ORDERS', null, queryParams)
}

export const getAllProjectsForOrderApi = queryParams => {
	return get('GET_ALL_PROJECTS_FOR_ORDER', null, queryParams)
}

export const getOrderDetailsApi = queryParams => {
	return get('GET_ORDER_DETAILS', null, queryParams)
}

export const getProjectsForCardsApi = queryParams => {
	return get('GET_PROJECTS_FOR_CARDS', null, queryParams)
}

export const uploadProjectDescriptionApi = body => {
	return post('UPLOAD_PROJECT_DESCRIPTION', body)
}

export const getAllProjectsByStatusApi = queryParams => {
	return get('GET_ALL_PROJECTS_BY_STATUS', null, queryParams)
}

export const getAllInvitationsApi = queryParams => {
	return get('GET_ALL_INVITATIONS', null, queryParams)
}

export const getAllProjectsHistoryApi = queryParams => {
	return get('GET_ALL_PROJECTS_HISTORY', null, queryParams)
}

export const getInvitationsCountApi = queryParams => {
	return get('GET_INVITATIONS_COUNT', null, queryParams)
}
export const getMultipartUrlsApi = queryParams => {
	return get('GET_MULTIPART_URLS', null, queryParams)
}

export const uploadMultipartFileApi = (body, abortSignal) => {
	return put('UPLOAD_MULTIPART_FILE', body, null, abortSignal, true)
}

export const completeMultipartUploadApi = (body, abortSignal) => {
	return post('COMPLETE_MULTIPART_UPLOAD', body, abortSignal)
}

export const abortMultipartUploadApi = body => {
	return post('ABORT_MULTIPART_UPLOAD', body, null)
}

export const downloadAdditionalDataApi = queryParams => {
	return get('DOWNLOAD_ADDITIONAL_DATA', null, queryParams)
}

export const getBoxCredsApi = pathParams => {
	return get('GET_BOX_CREDS', pathParams)
}

export const downloadBoxFolderApi = async (queryParams, mixpanelData) => {
	const res = await get('DOWNLOAD_BOX_FOLDER', null, queryParams)

	// Sending Data Folder Downloaded event to Mixpanel
	if (mixpanelData?.mixpanel_type === 'data_folder_downloaded') {
		pushEventToMixpanel('', 'Data Folder Downloaded', {
			'Project ID': queryParams.project_id,
		})
	}

	return res
}

export const getBiddableProjectsApi = queryParams => {
	return get('GET_BIDDABLE_PROJECTS', null, queryParams)
}

export const getCommercialCommisionApi = queryParams => {
	return get('GET_COMMERCIAL_COMMISION', null, queryParams)
}

export const getBidHistoryApi = async queryParams => {
	const res = await get('GET_BID_HISTORY', null, queryParams)

	// Sending bid history event to Mixpanel
	pushEventToMixpanel('', 'Bid History Viewed')
	return res
}

export const modifyBidApi = async body => {
	const res = await put('MODIFY_BID', body)

	// Sending Bid Modified event to Mixpanel
	if (body.mixpanel_type === 'bid_modified') {
		pushEventToMixpanel('', 'Bid Modified', {
			'Modified Bid Amount': Number(body.amount),
			'Initial Bid Amount': body.projectData.quote.amount,
			'Project ID': body.projectData.projectId,
			'Project Name': body.projectData.name,
			'Project Budget': body.projectData.total_fee,
			'Re-Submitting After Rejection': body.projectData.quote.status.toLowerCase() === 'rejected',
			'Re-Submitting After Withdrawal': body.projectData.quote.status.toLowerCase() === 'withdrawn',
			'Was The Submission Successful': res.status_code === 200,
		})
	}
	// Sending Bid Withdrawn event to Mixpanel
	else if (body.mixpanel_type === 'bid_withdrawn') {
		pushEventToMixpanel('', 'Bid Withdrawn', {
			'Bid Amount': body.projectData.quote.amount,
			'Project ID': body.projectData.projectId,
			'Project Name': body.projectData.name,
			'Project Budget': body.projectData.total_fee,
			'Was The Submission Successful': res.status_code === 200,
		})
	}

	return res
}

export const cancelProjectApi = async body => {
	const res = await put('CANCEL_PROJECT', body)

	// Sending Project Cancelled event to Mixpanel
	if (body?.mixpanel_type === 'project_cancelled' && res.status_code === 200) {
		pushEventToMixpanel('', 'Project Cancelled', {
			'Project ID': body.consumerProjectData.projectId,
			'Project Name': body.consumerProjectData.name,
			'Project Location': body.consumerProjectData.address,
			'Project Budget': body.consumerProjectData.total_fee,
			// 'No Of Bids Received': body.consumerProjectData.
			'Date And Time': new Date().getTime(),
			'Project Creation Date': new Date(`${body.consumerProjectData.created_at}`).getTime(),
		})
	}

	return res
}

export const enquireForOrderApi = body => {
	return post('ENQUIRE_FOR_ORDER', body, null)
}

export const chatWindowConsumerStatusApi = body => {
	return put('CHAT_WINDOW_CONSUMER_STATUS', body)
}

export const chatWindowPilotStatusApi = body => {
	return put('CHAT_WINDOW_PILOT_STATUS', body)
}

export const uploadFlightLogFileApi = body => {
	return post('UPLOAD_FLIGHT_LOG_FILE', body, null)
}

export const deleteFlightLogFileApi = queryParams => {
	return deleteMethod('DELETE_FLIGHT_LOG_FILE', null, queryParams)
}
