import { BellOutlined } from '@ant-design/icons'
import { Button, Card, Popover, Divider, Skeleton } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import React, { useEffect, useState } from 'react'
import { getAllNotifications, updateNotificationReadStatus } from '../../Stores/UserSession/action'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { goTo } from '../../Services/NavigationService'
import { SIDE_MENU_LIST } from '../../App/SideBarMenu/side-bar-menu-constants'
import { getValueFromStorage } from '../../Services/LocalStorageService'

const NotificationList = props => {
	const { notifications, getAllNotifications, allNotifications, updateNotificationReadStatus, reduceUnreadCount } = props
	const [notificationData, setNotificationsData] = useState(notifications.data)
	const [notificationCount, setNotificationCount] = useState(notifications.count)
	const [loading, setLoading] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		const prevValue = notificationData
		if (prevValue.length && currentPage === 1) {
			setNotificationsData(allNotifications.data)
		} else {
			setNotificationsData([...prevValue, ...allNotifications.data])
		}
		setNotificationCount(allNotifications.count)
		setLoading(false)
	}, [allNotifications])

	const loadMoreData = () => {
		if (loading) return
		setLoading(true)
		setCurrentPage(prevState => prevState + 1)
		getAllNotifications({ pageNumber: currentPage + 1, pageSize: 10 })
	}

	const callReadApi = selectedObj => {
		setVisible(false)
		if (!selectedObj.readStatus) {
			const updatedList = notificationData
			updateNotificationReadStatus({ id: selectedObj.id })
			const resultIndex = updatedList.findIndex(item => item.id === selectedObj.id)
			updatedList[resultIndex].readStatus = true
			if (notifications.unreadCount > 0) reduceUnreadCount()
			setNotificationsData(updatedList)
		}

		const subrole = getValueFromStorage('SUBROLE')
		const profileType = getValueFromStorage('PROFILE_TYPE')
		const obj = SIDE_MENU_LIST[subrole && subrole in SIDE_MENU_LIST ? subrole : profileType].find(item => item.id === selectedObj.module)
		if (obj) {
			const path = obj.pathName.slice(1)
			goTo(path)
		}
	}

	return (
		<Popover
			trigger='click'
			placement='bottom'
			arrow={true}
			open={visible}
			content={
				<div style={{ maxHeight: '70vh' }}>
					<div style={{ fontSize: '20px', display: 'flex', justifyContent: 'space-between' }}>
						<strong>Notifications</strong>
						<Button
							onClick={() => {
								setVisible(false)
								updateNotificationReadStatus({ id: 'ALL' })
							}}
							type='text'
							style={{ fontSize: '12px' }}
						>
							Mark all as read
						</Button>
					</div>

					<div id='notificationsScrollableDiv' style={{ overflowY: 'auto', maxHeight: '60vh' }}>
						<InfiniteScroll
							dataLength={notificationData.length}
							next={loadMoreData}
							hasMore={notificationData.length < notificationCount}
							loader={
								<Skeleton
									paragraph={{
										rows: 3,
									}}
									active
								/>
							}
							endMessage={<Divider></Divider>}
							scrollableTarget='notificationsScrollableDiv'
						>
							{notificationData.map(item => (
								<Card
									onClick={() => callReadApi(item)}
									style={{ margin: '10px', cursor: 'pointer', background: item.readStatus ? 'white' : '#c0c0c0' }}
									key={item.id}
									title={item.title}
								>
									<p>{item.body}</p>
								</Card>
							))}
						</InfiniteScroll>
					</div>
				</div>
			}
		>
			<Button type='text' style={{ position: 'relative', bottom: '5px', right: '10px' }}>
				<BellOutlined onClick={() => setVisible(prevVal => !prevVal)} style={{ fontSize: '25px' }} />
			</Button>
		</Popover>
	)
}

const mapStateToProps = state => {
	return {
		allNotifications: state.UserSessionReducer.allNotifications,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAllNotifications: bindActionCreators(getAllNotifications, dispatch),
		updateNotificationReadStatus: bindActionCreators(updateNotificationReadStatus, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(NotificationList)
