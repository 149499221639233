import React, { useState } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Input, Upload, message } from 'antd'
import { AeroModal } from '../../../../AeroComponents'
import MatImage from '../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../AeroComponents/Image/image.constants'
import { disapproveFinalData } from '../../../../Stores/Projects/action'
import { Form } from '@ant-design/compatible'
import styles from './modal.module.scss'

const { TextArea } = Input

function DisapproveModalForm(props) {
	// extract props
	const {
		closeDisapproveModal,
		disapproveModal,
		selectedProjectData,
		disapproveData, // from parent
		form, //from Form(antd)
	} = props

	/* state */
	const [fileList, setFileList] = useState([]) // filelist to be uploaded

	/* constants */
	const { getFieldDecorator } = form

	const uploadProps = {
		// for upload  component
		onRemove: file => {
			setFileList([])
		},
		beforeUpload: file => {
			const isJpgOrPngOrPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
			if (!isJpgOrPngOrPdf) {
				message.error('You can only upload JPG/PNG/PDF file!')
				return false
			}
			const isLt2M = file.size / 1024 / 1024 < 10
			if (!isLt2M) {
				message.error('File must be smaller than 10 MB!')
				return false
			}
			setFileList([file])
			return false
		},
		fileList,
	}

	// Get the form data aling with uploaded files and send it to BE
	const handleSubmit = e => {
		e.preventDefault()
		props.form.validateFields((err, values) => {
			if (!err) {
				closeDisapproveModal()
				disapproveData()
				props.form.resetFields()
				// TODO: check with marvin whether to upload one/file check file type/size
				let body = new FormData()
				if (fileList.length !== 0) {
					let fileData
					fileList.forEach(file => {
						fileData = file
					})
					body.append('proofFile', fileData)
				}
				body.append('disapprovalReason', values.disapprovalReason)
				body.append('projectId', selectedProjectData.projectId)
				props.disapproveFinalData(body)
			}
		})
	}
	return (
		<AeroModal footer={false} onCancel={closeDisapproveModal} visible={disapproveModal}>
			<div className={styles['modal-wrapper']}>
				<MatImage src={IMAGES_SRC.DISAPPROVE_ICON} />
				<div>
					<div className={styles['header']}>Disapprove Project Data?</div>
					<div className={styles['header-info']}>Your Account Manager will be notified about this</div>

					{/* Form to get details about  user issue */}
					<Form className={styles['body']} layout='vertical' onSubmit={handleSubmit} encType='multipart/form-data'>
						<Form.Item label='Please describe your issue in detail'>
							{getFieldDecorator('disapprovalReason', { rules: [{ required: true, whitespace: true, message: 'Please mention the issue!' }] })(
								<TextArea rows={4} placeholder={'Start Typing...'} />,
							)}
						</Form.Item>
						<Form.Item label='Upload Attachment (jpeg, png or pdf)'>
							<Upload {...uploadProps}>
								<Button className={styles['upload-button']}>Click to Upload</Button>
							</Upload>
						</Form.Item>
						<div className={styles['body']}>
							<Button className={styles['button']} onClick={closeDisapproveModal}>
								Cancel
							</Button>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<Button className={styles['button']} type='primary' htmlType='submit'>
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</AeroModal>
	)
}

const DisapproveModal = Form.create({ name: 'ORDER_DETAILS_FORM' })(DisapproveModalForm) // wrap the component with antd Form

const mapDispatchToProps = dispatch => {
	return {
		disapproveFinalData: bindActionCreators(disapproveFinalData, dispatch),
	}
}

export default connect(mapDispatchToProps)(DisapproveModal)
