import React from 'react'
import { findDOMNode } from 'react-dom'
import '@ant-design/compatible/assets/index.css'
import { Breadcrumb, message, Alert, Card, Upload, Spin, Modal, Image } from 'antd'
import { getPortfolioUploadUrl, getPilotPortfolio, deletePortfolioMedia } from '../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { InfoCircleOutlined, PlusOutlined, DeleteOutlined, PlayCircleOutlined, EyeOutlined } from '@ant-design/icons'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'

class PilotPortfolio extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			images: [],
			videos: [],
			banner: '',
			fileList: [],
			uploadingImage: false,
			uploadingVideo: false,
			visible: false,
			selectedFile: {},
			currentMediaType: '',
		}
	}

	componentDidMount() {
		this.props.getPilotPortfolio({ [this.props.match.params.pilotId]: 'portfolio' })
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.portfolioUploadUrl !== nextProps.portfolioUploadUrl) {
			if (nextProps.portfolioUploadUrl) {
				this.handleUpload(nextProps.portfolioUploadUrl.url)
			}
		}
		if (this.props.deletePortfolioMediaSuccess !== nextProps.deletePortfolioMediaSuccess) {
			if (nextProps.deletePortfolioMediaSuccess) {
				if (this.state.currentMediaType === 'VIDEO') {
					this.setState({ uploadingVideo: false })
				} else {
					this.setState({ uploadingImage: false })
				}

				this.props.getPilotPortfolio({ [this.props.match.params.pilotId]: 'portfolio' })
			}
		}
		if (this.props.pilotPortfolioData !== nextProps.pilotPortfolioData) {
			if (nextProps.pilotPortfolioData) {
				this.setState({
					images: nextProps.pilotPortfolioData.photos,
					videos: nextProps.pilotPortfolioData.videos,
					banner: nextProps.pilotPortfolioData.bannerText,
				})
			}
		}
	}

	handleUpload = uploadDetails => {
		const { fileList, currentMediaType } = this.state
		const uploadedFile = fileList[0]
		if (currentMediaType === 'IMAGE') {
			this.setState({
				uploadingImage: true,
			})
		} else {
			this.setState({
				uploadingVideo: true,
			})
		}

		let requestObj = {
			method: 'put',
			redirect: 'follow',
			headers: new Headers(),
			body: uploadedFile,
		}
		fetch(uploadDetails, requestObj).then(() => {
			setTimeout(() => {
				this.props.getPilotPortfolio({ [this.props.match.params.pilotId]: 'portfolio' })
				if (currentMediaType === 'IMAGE') {
					this.setState({ fileList: [], uploadingImage: false })
				} else {
					this.setState({ fileList: [], uploadingVideo: false })
				}
			}, 3000)
		})
	}

	deleteItem = (key, type) => {
		this.setState({ uploadingVideo: true, visible: false, currentMediaType: 'VIDEO' })
		this.props.deletePortfolioMedia({
			key: key,
			type: type,
			mixpanel_type: 'portfolio',
			imageLength: this.props.pilotPortfolioData.photos.length,
			videoLength: this.props.pilotPortfolioData.videos.length,
		})
	}

	setVisible = vis => {
		this.setState({ visible: vis })
	}

	downloadVideo = () => {
		window.location.replace(this.state.videos[0].file)
	}

	deletePhoto = key => {
		this.setState({ uploadingImage: true, currentMediaType: 'IMAGE' })
		this.props.deletePortfolioMedia({
			key: key,
			type: 'PHOTO',
			mixpanel_type: 'portfolio',
			imageLength: this.props.pilotPortfolioData.photos.length,
			videoLength: this.props.pilotPortfolioData.videos.length,
		})
	}

	onClickFullscreen = () => {
		screenfull.request(findDOMNode(this.refs.player))
	}

	render() {
		const { images, videos, banner, fileList, uploadingImage, uploadingVideo, visible, selectedFile } = this.state
		const imgProps = {
			beforeUpload: file => {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
				if (!isJpgOrPng) {
					message.error('You can only upload JPG/PNG file!')
				}
				const isLt2M = file.size / 1024 / 1024 <= 10
				if (!isLt2M) {
					message.error('Image must be smaller than or equal to 10 MB!')
				}
				if (this.state.images.length >= 5) {
					return message.error('You can only upload upto 5 images!')
				}
				if (isJpgOrPng && isLt2M) {
					this.props.getPortfolioUploadUrl({
						type: 'PHOTO',
						fileName: file.name,
						imageLength: this.props.pilotPortfolioData.photos.length,
						videoLength: this.props.pilotPortfolioData.videos.length,
						mixpanel_type: 'portfolio',
					})
					this.setState(state => ({
						fileList: [...state.fileList, file],
						currentMediaType: 'IMAGE',
					}))
					return false
				}
			},
			fileList,
		}
		const vidProps = {
			beforeUpload: file => {
				const isJpgOrPng = file.type === 'video/mp4' || file.type === 'video/mov' || file.type === 'video/quicktime'
				if (!isJpgOrPng) {
					message.error('You can only upload MP4/MOV file!')
				}
				const isLt2M = file.size / 1024 / 1024 <= 32
				if (!isLt2M) {
					message.error('Image must be smaller than or equal to 32 MB!')
				}
				if (isJpgOrPng && isLt2M) {
					this.props.getPortfolioUploadUrl({
						type: 'VIDEO',
						fileName: file.name,
						imageLength: this.props.pilotPortfolioData.photos.length,
						videoLength: this.props.pilotPortfolioData.videos.length,
						mixpanel_type: 'portfolio',
					})
					this.setState(state => ({
						fileList: [...state.fileList, file],
						currentMediaType: 'VIDEO',
					}))
					return false
				}
			},
			fileList,
		}

		return (
			<div style={{ padding: '25px' }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link
							to={{
								pathname: '/profile',
							}}
						>
							Personal Profile
						</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>Portfolio</Breadcrumb.Item>
				</Breadcrumb>
				<div style={{ marginTop: '20px' }}>
					<Alert
						message={<div style={{ color: 'white', paddingLeft: '20px', fontSize: '16px' }}>{banner}</div>}
						type='info'
						showIcon
						icon={
							<span>
								<InfoCircleOutlined style={{ color: 'white', fontSize: '40px' }} />
							</span>
						}
						className='portfolio-page-alert'
					/>
				</div>
				<div style={{ marginTop: '20px' }}>
					<Card
						className='parent-card'
						title={
							<div>
								Select Images{' '}
								<span style={{ color: '#8C8C8C', fontSize: '14px', fontWeight: 'normal' }}>
									( You can upload up to 5 images, each with a maximum filesize of 10MB )
								</span>
							</div>
						}
					>
						<Spin spinning={uploadingImage}>
							<Image.PreviewGroup>
								{images.map(item => (
									<div key={item.key} className='thumbnail-wrapper'>
										<Image
											style={{ float: 'left', width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
											src={item.thumbnails.smallThumbnail}
											placeholder={true}
											preview={{
												src: item.file,
												mask: (
													<>
														<EyeOutlined style={{ margin: '10px' }} />

														<DeleteOutlined
															style={{ margin: '10px' }}
															onClick={e => {
																this.deletePhoto(item.key)
																e.stopPropagation()
															}}
														/>
													</>
												),
											}}
										/>
									</div>
								))}
							</Image.PreviewGroup>
							{images.length >= 5 ? null : (
								<Upload {...imgProps} listType='picture-card' showUploadList={false} className='upload-btn'>
									<div>
										<PlusOutlined />
										<div style={{ marginTop: 8 }}>Upload</div>
										<div style={{ color: '#7A7A7A' }}>png, jpeg</div>
									</div>
								</Upload>
							)}
						</Spin>
					</Card>
				</div>
				<div style={{ marginTop: '20px' }}>
					<Card
						className='parent-card'
						title={
							<div>
								Select Video{' '}
								<span style={{ color: '#8C8C8C', fontSize: '14px', fontWeight: 'normal' }}>(You can upload only 1 video with a maximum filesize of 32MB)</span>
							</div>
						}
					>
						<Spin spinning={uploadingVideo}>
							{videos.length >= 1 ? (
								<div style={{ background: 'black', height: '100px', width: '100px', borderRadius: '8px' }}>
									<ReactPlayer
										playing={true}
										controls={true}
										playIcon={
											<span style={{ color: 'white', fontSize: '20px' }}>
												<PlayCircleOutlined />
												&nbsp;&nbsp;
												<DeleteOutlined onClick={() => this.deleteItem(videos[0].key, 'VIDEO')} />
											</span>
										}
										light={videos[0].thumbnails?.smallThumbnail}
										width={100}
										height={100}
										ref='player'
										// onPlay={this.onClickFullscreen}
										url={videos.length > 0 ? videos[0].file : null}
									/>
								</div>
							) : (
								<Upload {...vidProps} name='avatar' listType='picture-card' className='avatar-uploader' showUploadList={false}>
									<div>
										<PlusOutlined />
										<div style={{ marginTop: 8 }}>Upload</div>
										<div style={{ color: '#7A7A7A' }}>mp4, mov</div>
									</div>
								</Upload>
							)}
						</Spin>
					</Card>
				</div>
				<Modal width={1000} centered footer={false} visible={visible} onCancel={() => this.setState({ visible: false })}>
					<img alt='portfolio' style={{ width: '100%' }} src={selectedFile.name} />
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		portfolioUploadUrl: state.UserSessionReducer.portfolioUploadUrl,
		pilotPortfolioData: state.UserSessionReducer.pilotPortfolioData,
		deletePortfolioMediaSuccess: state.UserSessionReducer.deletePortfolioMediaSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getPortfolioUploadUrl: bindActionCreators(getPortfolioUploadUrl, dispatch),
		getPilotPortfolio: bindActionCreators(getPilotPortfolio, dispatch),
		deletePortfolioMedia: bindActionCreators(deletePortfolioMedia, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PilotPortfolio)
