import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Descriptions, Input, Button, message } from 'antd'

class AboutYouForm extends Component {
	componentDidMount() {
		this.props.form.setFieldsValue(this.props.data)
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				if (values.aboutYou.length > 100) {
					return message.warning('Character limit exceeded! Please keep your input with 100 characters. The character limit is reached!')
				}
				const data = {
					aboutYou: values.aboutYou,
					mixpanel_type: 'about_you',
				}
				this.props.updateAboutYou(data)
			}
		})
	}

	render() {
		const { viewMode, data } = this.props
		const { getFieldDecorator } = this.props.form
		return (
			<Form layout='vertical' onSubmit={this.handleSubmit}>
				{viewMode ? (
					<Descriptions>
						<Descriptions.Item>{data.aboutYou}</Descriptions.Item>
					</Descriptions>
				) : (
					<div style={{ textAlign: 'center' }}>
						<Form.Item label='About You'>
							{getFieldDecorator('aboutYou', {
								rules: [
									{
										required: true,
										whitespace: true,
										message: 'Please input something about yourself!',
									},
								],
							})(<Input style={{ width: '470px' }} />)}
						</Form.Item>
						<Form.Item>
							<Button block type='primary' htmlType='submit'>
								Add
							</Button>
						</Form.Item>
					</div>
				)}
			</Form>
		)
	}
}

const AboutYou = Form.create({ name: 'ABOUT_YOU_FORM' })(AboutYouForm)

export default AboutYou
