/*eslint-disable*/
import typeToReducer from 'type-to-reducer'
import * as ACTION from './actionTypes'
import { message } from 'antd'
import moment from 'moment';

const initialState = {
	submissionList: null,
	contributionList: null,
	bulkUpdateSuccess: null,
	previousSubmissions: null,
	savedSubmissions: null,
	createSubmissionSuccess: null,
	submitSuccess: null,
	submissionName: "",
	uploads: {},
	uploadFileList: {}

}

const GettyReducer = typeToReducer(
	{
		[ACTION.GET_ALL_CONTRIBUTIONS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						contributionList: action.payload.data.contributions,
						submissionName: action.payload.data.submissionName,
						submitSuccess: null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},


		[ACTION.GET_PREVIOUS_SUBMISSIONS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						previousSubmissions: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_SAVED_SUBMISSIONS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						savedSubmissions: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.CREATE_GETTY_SUBMISSION]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						createSubmissionSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},



		[ACTION.UPDATE_CONTRIBUTION]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {

					let newContributionList = [...state.contributionList]
					const temp = newContributionList.findIndex(el => el.id === action.payload.data.id)
					Object.assign(temp >= 0 ? newContributionList[newContributionList.findIndex(el => el.id === action.payload.data.id)] : newContributionList, action.payload.data)
					return Object.assign({}, state, {
						contributionList: newContributionList,

					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.DELETE_CONTRIBUTION]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {

					let newContributionList = [...state.contributionList]
					newContributionList.splice(newContributionList.findIndex(el => el.id === action.payload.data.id), 1)

					return Object.assign({}, state, {
						contributionList: newContributionList,

					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.SUBMIT_SUBMISSION]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						submitSuccess: true
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},


		[ACTION.UPLOAD_CONTRIBUTION]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.data && action.payload.status_code === 200) {

					let submissionId = action.payload.submissionBatchId
					let filesUploading = {}
					if (state.uploads[submissionId]) {
						filesUploading = { ...state.uploads[submissionId] }
					}
					return Object.assign({}, state, {
						uploads: {
							...state.uploads,
							[submissionId]: {
								...filesUploading,
								[action.payload.fileName]: {

									urls: action.payload.data.urls,
									uploadId: action.payload.data.uploadId,
									status: true,
									percentage: 0,
									eachPartPercent: 100 / action.payload.parts,
									fileName: action.payload.fileName
								}
							}
						}
					})

				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},


		[ACTION.UPLOAD_MULTIPART_FILE_GETTY]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload.data && action.payload.status_code == 200) {
					let submissionId = action.payload.submissionBatchId
					let filename = action.payload.filename

					return Object.assign({}, state, {
						uploads: {
							...state.uploads,
							[submissionId]: {
								...state.uploads[submissionId],
								[filename]: {
									...state.uploads[submissionId][filename],
									percentage: state.uploads[submissionId][filename]["eachPartPercent"] + state.uploads[submissionId][filename]["percentage"],

								}
							},
						},
					})
				} else if (action.payload.data && action.payload.status_code !== 200) {
					message.error(action.payload.data.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},


		[ACTION.REMOVE_UPLOAD]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				let filename = action.payload.filename;
				let submissionId = action.payload.submissionBatchId;
				let newUploads = { ...state.uploads }
				delete newUploads[submissionId][filename]

				if (Object.keys(newUploads[submissionId]).length === 0) {
					delete newUploads[submissionId]
				}


				return Object.assign({}, state, {
					uploads: newUploads,

				})
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},

		},

		[ACTION.ADD_TO_UPLOAD]: (state, action) => {


			let submissionId = action.payload.submissionBatchId
			let filesUploading = {}
			if (state.uploads[submissionId]) {
				filesUploading = { ...state.uploads[submissionId] }
			}
			return Object.assign({}, state, {
				uploads: {
					...state.uploads,
					[submissionId]: {
						...filesUploading,
						[action.payload.filename]: {

							file: action.payload.file,
							percentage: 0,
							eachPartPercent: 0,
							fileName: action.payload.filename
						}
					}
				}
			})


		},


		[ACTION.CREATE_CONTRIBUTION]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				let submissionId = action.payload.submissionBatchId
				const dateFormat = 'YYYY-MM-DD HH:mm:ss'

				let filename = action.payload.data.fileName

				let newUploads = { ...state.uploads }
				delete newUploads[submissionId][filename]

				if (Object.keys(newUploads[submissionId]).length === 0) {
					delete newUploads[submissionId]
				}


				let newContributionList = [...state.contributionList]
				let index = newContributionList.findIndex(el => el.fileName === filename)
				newContributionList[index] =
				{
					"adminReason": null,
					"amountEarned": 0,
					"caption": null,
					"city": null,
					"clipType": null,
					"countryOfShoot": null,
					"copyright": null,
					"fileName": action.payload.data.fileName,
					"fileUploaded": false,
					"gettyReason": null,
					"gettyStatus": "PENDING",
					"headline": null,
					"id": action.payload.data.id,
					"iptcCategory": null,
					"iptcSubjects": null,
					"keywords": [],
					"provinceState": null,
					"recordedDate": null,
					"shotSpeed": null,
					"status": "PENDING_FILE_SUBMISSION",
					"storageUrl": action.payload.data.externalFileLocation,
					"submissionId": submissionId,
					"submittable": false,
					"timeline": {
						"timeline": {
							"PENDING_FILE_SUBMISSION": {
								"status": "PENDING_FILE_SUBMISSION",

								"time": moment().format(dateFormat)
							}
						}
					},
					"thumbnailUrl": "Set a default thumbnail url"
				}



				return Object.assign({}, state, {
					uploads: newUploads,
					contributionList: newContributionList
				})
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},



		[ACTION.ADD_CONTRIBUTION]: (state, action) => {
			let filename = action.payload.filename;
			let submissionId = action.payload.submissionId;
			if (state.contributionList.findIndex(el => el.fileName === filename) !== -1) {
				return Object.assign({}, state, {});
			}
			return Object.assign({}, state, {
				contributionList: [...state.contributionList,
				{
					"adminReason": null,
					"amountEarned": 0,
					"caption": null,
					"city": null,
					"clipType": null,
					"countryOfShoot": null,
					"copyright": null,
					"fileName": filename,
					"fileUploaded": false,
					"gettyReason": null,
					"gettyStatus": "PENDING",
					"headline": null,
					"id": filename,
					"iptcCategory": null,
					"iptcSubjects": null,
					"isUploading": true,
					"keywords": [],
					"provinceState": null,
					"recordedDate": null,
					"shotSpeed": null,
					"status": "PENDING_FILE_SUBMISSION",
					"storageUrl": null,
					"submissionId": submissionId,
					"submittable": false,
					"timeline": {
						"timeline": null
					},
					"thumbnailUrl": "Set a default thumbnail url"
				}
				]
			})
		},

		[ACTION.REMOVE_CONTRIBUTION]: (state, action) => {

			let filename = action.payload.filename;

			let newContributionList = [...state.contributionList]
			newContributionList.splice(newContributionList.findIndex(el => el.fileName === filename), 1)

			return Object.assign({}, state, {
				contributionList: newContributionList,

			})
		},

		[ACTION.ADD_FILE]: (state, action) => {

			let file = action.payload.file;

			let submissionId = action.payload.submissionBatchId

			let newFileList = []
			if (state.uploadFileList[submissionId]) {
				newFileList = [...state.uploadFileList[submissionId]]
			}
			newFileList.push(file)
			return Object.assign({}, state, {
				uploadFileList: {
					...state.uploadFileList,
					[submissionId]: newFileList
				}
			})
		},


		[ACTION.REMOVE_FILE]: (state, action) => {
			let name = action.payload.name;

			let submissionId = action.payload.submissionBatchId

			let newFileList = []
			if (state.uploadFileList[submissionId]) {
				newFileList = [...state.uploadFileList[submissionId]]
			}
			newFileList.splice(newFileList.findIndex(el => el.name === name), 1)
			return Object.assign({}, state, {
				uploadFileList: {
					...state.uploadFileList,
					[submissionId]:
						newFileList

				}
			})
		},


		[ACTION.BULK_UPDATE]: {

			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload.data && action.payload.status_code == 200) {

					let newContributionList = action.payload.request.contributions;

					return Object.assign({}, state, {
						contributionList: newContributionList,
						bulkUpdateSuccess: {
							type: action.payload.request.type,
							value: Math.random()
						}

					})
				} else if (action.payload.data && action.payload.status_code !== 200) {
					message.error(action.payload.data.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},


	},
	initialState,
)

export default GettyReducer
