
import React from 'react'
import styles from './DetailsModal.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'
import { Button } from "antd";

function DetailsModal(props) {

  // extract props
  const {
    setShowDetailsModal, // from parent  
    showDetailsModal
  } = props


  return (
    <AeroModal
      bodyStyle={{ backgroundColor: '#F5F5F5', borderRadius: '0px 0px 12px 12px' }}
      title="What can I upload?" footer={false} onCancel={() => { setShowDetailsModal(false) }} visible={showDetailsModal}>
      <div   >


        <div>
          <span className={styles['bold-text']} > File Type: </span> .mov, .mp4 or .mxf
        </div>
        <br />
        <div>

          <div className={styles['details-card-content']}>
            <span className={styles['bold-text']} > Maximum File Size: </span> 5GB
          </div>
          <br />
          <div>
            <span className={styles['bold-text']} >Maximum Editorial Video Length:</span> Editorial Videos ranging between 30 seconds - 60 seconds are preferred and most likely to get Accepted and Published by Getty. A maximum of 25 videos can be uploaded as a single Submission.
          </div>

          <br></br>
          <Button block className={styles['button']} type='primary' onClick={() => { setShowDetailsModal(false) }}>Okay, Got it</Button>

        </div>

      </div>
    </AeroModal>
  )
}

export default DetailsModal;