import { Button } from 'antd'
import React from 'react'
import ArnDetails from '../arn-details'

const ArnTab = props => {
	const { profileData, openMadalToAddDetails, updateArnDetails } = props
	return (
		<>
			<div style={{ fontSize: '16px', color: '#47464A' }}>Aviation Reference Number (ARN)</div>
			<div style={{ color: '#84818A', marginTop: '10px' }}>
				Pilot requires a unique Aviation Reference Number (ARN) in order to be able to fly a drone in Australian airspace. You can apply for ARN{' '}
				<Button
					style={{ position: 'relative', right: '10px' }}
					type='link'
					href='https://www.casa.gov.au/licences-and-certification/individual-licensing/aviation-reference-numbers'
					target='_blank'
					rel='noopener noreferrer'
				>
					here
				</Button>
			</div>
			<div style={{ marginTop: '20px' }}>
				{profileData && profileData['arnLicense'] ? (
					<ArnDetails
						viewMode
						data={profileData['arnLicense']}
						retry={openMadalToAddDetails}
						closeModal={newData => {
							updateArnDetails(newData)
						}}
					/>
				) : (
					<ArnDetails
						data={profileData['arnLicense']}
						retry={openMadalToAddDetails}
						closeModal={newData => {
							updateArnDetails(newData)
						}}
					/>
				)}
			</div>
		</>
	)
}

export default ArnTab
