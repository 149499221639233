import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { message } from 'antd'
import { getSocialAuth } from '../../Stores/UserSession/action'
import { goTo ,goToWithQueryParam} from '../../Services/NavigationService'
import { getValueFromStorage } from '../../Services/LocalStorageService'
import { urlConfig } from '../../Services/UtilityService'
import { fetchToken } from '../../Services/firebase-service'

class ReceiveAuth extends Component {
	componentDidMount() {
		const authKey = this.props.match.params.authKey
		const role = this.props.match.params.role
		const channel = this.props.match.params.channel
		if (authKey && role && channel) {
			const deviceToken = getValueFromStorage('DEVICE_TOKEN')

			// if (deviceToken) {
			const body = {
				oauth2Token: authKey,
				role: role,
				socialMediaChannel: channel,
				webUrl: window.location.origin,
				device_token: deviceToken,
				redirection: urlConfig().redirection,
			}
			this.props
				.getSocialAuth(body)
				.then(res => {
					if (res.value?.status_code === 200) {
						if (res.action.payload.data.hasEmail) {
							goTo('')
						}
						else {
							goToWithQueryParam('add-email','?channel='+channel)
						}
					} else {
						goTo('signup-select')
					}
				})
				.catch(() => goTo('signup-select'))
			// } else {
			// 	fetchToken(this.props.setTokenFound, this.props.updateDeviceToken).then(() => {
			// 		const body = {
			// 			oauth2Token: authKey,
			// 			role: role,
			// 			socialMediaChannel: channel,
			// 			webUrl: window.location.origin,
			// 			device_token: getValueFromStorage('DEVICE_TOKEN'),
			// 			redirection: urlConfig().redirection,
			// 		}
			// 		this.props
			// 			.getSocialAuth(body)
			// 			.then(res => {
			// 				if (res.value?.status_code === 200) {
			// 					goTo('')
			// 				} else {
			// 					goTo('signup-select')
			// 				}
			// 			})
			// 			.catch(() => goTo('signup-select'))
			// 	})
			// }
		} else {
			message.error('No message received from server!')
		}
	}

	render() {
		return <></>
	}
}

const mapStateToProps = state => {
	return {
		socialAuthData: state.UserSessionReducer.socialAuthData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getSocialAuth: bindActionCreators(getSocialAuth, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ReceiveAuth)
