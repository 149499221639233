import React from 'react'
import styles from './HowRoyalyWorksModal.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'
import { Button } from 'antd'

function HowRoyalyWorksModal(props) {
	// extract props
	const {
		setShowHowRoyalyWorksModal, // from parent
		showHowRoyalyWorksModal,
	} = props

	return (
		<AeroModal
			bodyStyle={{ backgroundColor: '#F5F5F5', borderRadius: '0px 0px 12px 12px' }}
			title='How does this work?'
			footer={false}
			onCancel={() => {
				setShowHowRoyalyWorksModal(false)
			}}
			visible={showHowRoyalyWorksModal}
		>
			<div className={styles['details-modal']}>
				<div>
					<ul>
						<li>
							In the case of a sale of your Uploaded Video on the Getty Platform, Royalty Payments from the Getty Platform to the bank account linked to your
							Aerologix Profile will take place within a span of 2 months from the date of purchase. Note that simply uploading a Video or Submission to the Getty
							Platform does not guarantee a sale or entitlement to Royalty Earnings.
						</li>

						<li>
							You will earn a total Royalty of 35% on the total sale value for every sale of your uploaded Video/Image on the Getty Platform. Royalty payments from
							the Getty Platform will not reflect to your linked bank account will not be displayed in the “Received Payments” tab of the Aerologix application and
							Invoices for Royalty payments cannot be generated by Aerologix
						</li>

						<li>
							We are currently unable to obtain and share real-time information on the total number of views, total sales, and interest levels on any specific
							uploaded media file that is listed on the Getty Platform through Aerologix.
						</li>

						<li>
							Please read our updated{' '}
							<Button type='link' target='_blank' rel='noopener noreferrer' href='https://www.aerologix.com/terms/' className={styles['link-text']}>
								Terms &amp; Conditions
							</Button>{' '}
							before making your first upload
						</li>
					</ul>

					<br></br>
					<Button
						block
						className={styles['button']}
						type='primary'
						onClick={() => {
							setShowHowRoyalyWorksModal(false)
						}}
					>
						Okay, Got it
					</Button>
				</div>
			</div>
		</AeroModal>
	)
}

export default HowRoyalyWorksModal
