import React from 'react'
import { Button } from 'antd'

import styles from '../enterprisePilotPlus.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'
import { IMAGES_SRC } from '../../../../../AeroComponents/Image/image.constants'

const IntroModal = ({ visible, cancelEnterprisePlusPrompt, setIntroModal, closeEnterprisePlusPilotModal, handleNext }) => {
	const steps = [
		{
			stepText: 'Step 1',
			stepContent: 'Go through the contract',
		},
		{
			stepText: 'Step 2',
			stepContent: 'Agree to T&C',
		},
		{
			stepText: 'Step 3',
			stepContent: 'E-sign',
		},
		{
			stepText: 'Step 4',
			stepContent: 'Enter credit card details',
		},
	]

	const cancelPrompt = () => {
		cancelEnterprisePlusPrompt()
		setIntroModal(false)
		closeEnterprisePlusPilotModal()
	}

	return (
		<AeroModal visible={visible} onCancel={cancelPrompt} title='' footer={false}>
			<div className={styles['main']}>
				<img alt='hnd-shk' className={styles['handshake_img']} src={IMAGES_SRC.ENTERPRISE_PLUS_HANDSHAKE} />

				<div className={styles['header']}>
					<h2>
						Welcome to{' '}
						<span>
							<b>Enterprise</b>
						</span>{' '}
						<div className={styles['header_plus_sign']}>
							<div>+</div>
						</div>{' '}
						program
					</h2>
					<p>
						Congratulations! You have been selected for the Enterprise program by Aerologix. Simply follow the steps provided and get ready for potential big projects
						ahead
					</p>
				</div>

				{/* STEPPER */}
				<ul className={styles['events']}>
					{steps.map(el => (
						<li>
							<span>
								<strong>{el.stepText}</strong> {el.stepContent}
							</span>
						</li>
					))}
				</ul>

				<div className={styles['button_parent']}>
					<Button type='primary' onClick={handleNext} block>
						Yes, I am in
					</Button>
				</div>
			</div>
		</AeroModal>
	)
}

export default IntroModal
