/*eslint-disable*/
import React from 'react'
import { injectStripe, CardElement } from 'react-stripe-elements'
import { Button } from 'antd'
import StripeBankInfo from './stripe-bank-info'
import { LockOutlined } from '@ant-design/icons'
import { getConfigData } from '../../../Services/LocalStorageService'

class PaymentProfile extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			antForm: props.id !== 'PAY_NOW' ? false : true,
			paymentEle: {
				PAY_NOW: {
					stripeEle: <CardElement style={{ base: { fontSize: '18px' } }} />,
					submitDisplay: 'Pay',
					cancelDisplay: 'Cancel',
				},
			},
			ibanRef: null,
			btnLoading: false
		}
		this.submitRef = React.createRef()
	}

	handleSubmit = e => {
		e.preventDefault()
		if (this.props.stripe) {
			switch (this.props.id) {
				case 'PAY_NOW':
					this.setState({ btnLoading: true })
					this.props.stripe.createToken({ type: 'card' }).then(result => {
						this.props.updateCardDetails(result.token.id)
					}).finally(() => this.setState({ btnLoading: false }))
					break
			}
		} else {
			console.log("Stripe.js hasn't loaded yet.")
		}
	}

	render() {
		return (
			<div>
				{this.state.antForm ? (
					<form onSubmit={this.handleSubmit}>
						{this.state.paymentEle[this.props.id].stripeEle}
						<div style={{ display: 'flex', marginTop: '15px', justifyContent: 'flex-end' }}>
							{this.state.paymentEle[this.props.id].cancelDisplay && (
								<Button style={{ marginRight: '15px' }} onClick={this.props.cancel}>
									{this.state.paymentEle[this.props.id].cancelDisplay}
								</Button>
							)}
							<Button loading={this.state.btnLoading} htmlType='submit' type='primary'>
								<LockOutlined style={{ display: 'inline-flex', fontSize: '108%' }}></LockOutlined>
								{this.state.paymentEle[this.props.id].submitDisplay}
								&nbsp;{getConfigData().currency + ' ' + this.props.displayAmount}
							</Button>
						</div>
					</form>
				) : (
					<StripeBankInfo {...this.props} />
				)}
			</div>
		)
	}
}

const InjectedPaymentProfile = injectStripe(PaymentProfile)
export default InjectedPaymentProfile
