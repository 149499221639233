/*eslint-disable*/
import React from 'react'
import './on-boarding.sass'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import MatText from '../../AeroComponents/Text'
import { TEXTS } from './on-boarding.constants'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, message, Spin } from 'antd'
import { dev } from '../../aero.constants'
import { AEROLOGIX_ENDPOINTS } from '../../Api/api-endpoints'
import ContactSupport from './ContactSupport'
import { getValueFromStorage } from '../../Services/LocalStorageService'
import { FORGOT_PASSWORD_FILEDS } from '../../AeroComponents/FormFields/Metadata/forgot-password.fields'
import { goTo } from '../../Services/NavigationService'
import { forgotPassword } from '../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AeroField from '../../AeroComponents/FormFields'
const SUBMIT_BTN_TEXT = 'Generate Password'
const GOTO_LOGIN = 'Return to Log In'
const CONTACT_SUPPORT = 'Contact Support'

class ForgotPasswordForm extends React.Component {
	constructor(props) {
		super(props)
		const profileType = getValueFromStorage('PROFILE_TYPE')
		this.state = {
			loading: false,
			profileType: profileType,
			visible: false,
			fieldsToRender: FORGOT_PASSWORD_FILEDS,
			emailToRecovery: null,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.forgotSuccess !== nextProps.forgotSuccess) {
			this.props.history.push({
				state: { email: this.state.emailToRecovery },
				pathname: '/sent-password-recovery',
			})
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({
					emailToRecovery: values.email,
				})
				this.props.forgotPassword(values)
			}
		})
	}

	toggleContactSupport = () => {
		this.setState(prevState => ({
			visible: !prevState.visible,
		}))
	}

	render() {
		const { loading, visible, fieldsToRender } = this.state
		return (
			<div className='flex-container'>
				<MatImage src={IMAGES_SRC.LOGO_BLACK} width='260' height='70' />
				<MatText size='22' text={TEXTS.FORGOT_PASS} />
				<Form layout='vertical' onSubmit={this.handleSubmit}>
					<Spin spinning={loading}>
						<AeroField fieldsToRender={fieldsToRender} {...this.props} />
						<Form.Item>
							<Button style={{ height: '48px' }} block type='primary' htmlType='submit'>
								{SUBMIT_BTN_TEXT}
							</Button>
						</Form.Item>
					</Spin>
				</Form>
				<div>
					<Button type='link' onClick={() => goTo('login')}>
						{GOTO_LOGIN}
					</Button>
					{/* <Button type='link' onClick={this.toggleContactSupport}>
						{CONTACT_SUPPORT}
					</Button> */}
				</div>
				{visible ? <ContactSupport onClose={this.toggleContactSupport} /> : ''}
			</div>
		)
	}
}

const ForgotPassword = Form.create({ name: 'FORGOT_PASSWORD_FORM' })(ForgotPasswordForm)

const mapStateToProps = state => {
	return {
		forgotSuccess: state.UserSessionReducer.forgotSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		forgotPassword: bindActionCreators(forgotPassword, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ForgotPassword)
