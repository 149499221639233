import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, message } from 'antd'
import { getValueFromStorage } from '../../../Services/LocalStorageService'
import { BASIC_DETAILS_FIELDS } from '../../../AeroComponents/FormFields/Metadata/basic-details.fields'
import AeroField from '../../../AeroComponents/FormFields'

class BasicDetailsForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			profileType: getValueFromStorage('PROFILE_TYPE'),
			fieldsToRender: BASIC_DETAILS_FIELDS,
		}
	}

	componentDidMount() {
		this.props.form.setFieldsValue(this.props.data)
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				if (values.firstName.length > 50 || values.middleName?.length > 50 || values.lastName?.length > 50) {
					return message.warning('The character limit is 50!')
				}
				const data = {
					firstName: values.firstName,
					middleName: values.middleName,
					lastName: values.lastName,
				}

				this.props.updateBasicDetails(data)
			}
		})
	}

	render() {
		const { profileType, fieldsToRender } = this.state
		return (
			<Form layout='vertical' onSubmit={this.handleSubmit}>
				<AeroField profileType={profileType} fieldsToRender={fieldsToRender} {...this.props} />

				<Button htmlType='submit' type='primary' style={{ height: '48px', float: 'right', fontSize: '16px', width: '240px' }}>
					Save Details
				</Button>
			</Form>
		)
	}
}

const BasicDetails = Form.create({ name: 'BASIC_DETAILS_FORM' })(BasicDetailsForm)

export default BasicDetails
