import React from 'react'

import { PROJECT_STATE, PROJECT_TYPE } from '../../constants'
import ProjectStatusCard from './ProjectStatusCard'

export default function InProgressProjectsCards(props) {
	// extract props
	const { index, inProgressProjects, openLiveStreamModal, downloadProjectData, openAdditionalDataModal } = props

	// if current index is lesser than scheduled projects length
	if (!(index > inProgressProjects.length - 1)) {
		const mapLink = 'https://www.google.com/maps/place/' + inProgressProjects[index].latitude + ',' + inProgressProjects[index].longitude

		return (
			<ProjectStatusCard
				name={inProgressProjects[index].name}
				mapLink={mapLink}
				address={inProgressProjects[index].address.substring(0, 15) + '...'}
				status='inprogress'
				projectType={PROJECT_TYPE[inProgressProjects[index].type]}
				projectId={inProgressProjects[index].projectId}
				projectState={PROJECT_STATE[inProgressProjects[index].state]}
				completionPercentage={inProgressProjects[index].completionPercentage}
				is_livestream_enabled={inProgressProjects[index].is_livestream_enabled}
				openLiveStreamModal={openLiveStreamModal}
				downloadProjectData={downloadProjectData}
				projectData={inProgressProjects[index]}
				openAdditionalDataModal={openAdditionalDataModal}
			></ProjectStatusCard>
		)
	}
	// else return empty
	else return ''
}
