/*eslint-disable*/
import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Input, message, Upload, DatePicker, Checkbox } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AeroField from '../../../AeroComponents/FormFields'
import { updateAccount, uploadStripeDocument } from '../../../Stores/Payments/action'
import moment from 'moment'

const ADD_CARD_DETAILS_FIELDS = [
	{
		fieldName: 'ACC_HOLDER_NAME',
		fieldLabel: 'Account Holder Name',
		fieldKey: 'accountHolderName',
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter Account Holder Name.',
				},
			],
		},
		fieldToRender: <Input />,
	},
	{
		fieldName: 'BSB_NUM',
		fieldLabel: 'BSB Number',
		fieldKey: 'bsbNumber',
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter BSB Number.',
				},
			],
		},
		fieldToRender: <Input />,
	},
	{
		fieldName: 'ACC_NUM',
		fieldLabel: 'Account Number',
		fieldKey: 'accountNumber',
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter Account Number.',
				},
			],
		},
		fieldToRender: <Input />,
	},
	{
		fieldName: 'DOB',
		fieldLabel: 'Date of birth',
		fieldKey: 'dob',
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter Your Date of Birth.',
				},
			],
		},
		fieldToRender: <DatePicker />,
	},
	{
		fieldName: 'ADD_LINE_1',
		fieldLabel: 'Address Line 1',
		fieldKey: 'addressLine1',
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter Your Line 1 of Address.',
				},
			],
		},
		fieldToRender: <Input />,
	},
	{
		fieldName: 'ADD_LINE_2',
		fieldLabel: 'Address Line 2',
		fieldKey: 'addressLine2',
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter Your Line 2 of Address.',
				},
			],
		},
		fieldToRender: <Input />,
	},
	{
		fieldName: 'CITY',
		fieldLabel: 'City',
		fieldKey: 'city',
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter Your City.',
				},
			],
		},
		fieldToRender: <Input />,
	},
	{
		fieldName: 'STATE',
		fieldLabel: 'State',
		fieldKey: 'state',
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter Your State.',
				},
			],
		},
		fieldToRender: <Input />,
	},
	{
		fieldName: 'POST_CODE',
		fieldLabel: 'Post Code',
		fieldKey: 'postCode',
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter Your Post Code.',
				},
			],
		},
		fieldToRender: <Input />,
	},
	{
		fieldName: 'WEB_URL',
		fieldLabel: 'Website URL',
		fieldKey: 'businessURL',
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter Your Website URL.',
				},
			],
		},
		fieldToRender: <Input />,
	},
]

const DOC_KEYS = [
	{
		purpose: 'VERIFICATION_DOCUMENT_FRONT',
	},
	{
		purpose: 'VERIFICATION_DOCUMENT_BACK',
	},
	{
		purpose: 'ADDITIONAL_DOCUMENT_FRONT',
	},
]

class StripeBankInfoForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			formPhase: 1,
			fileCount: [],
			bankDetailsId: '',
			enableAddButton: true,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.updateAccountData !== nextProps.updateAccountData) {
			if (nextProps.updateAccountData) {
				this.setState({ formPhase: 2, bankDetailsId: nextProps.updateAccountData.bankDetailsId })
			}
		}
	}

	handleSubmit = e => {
		e.preventDefault()

		this.props.form.validateFields((err, values) => {
			if (!err) {
				let body = {
					bsbNumber: values.bsbNumber,
					accountNumber: values.accountNumber,
					accountHolderName: values.accountHolderName,
					addressLine1: values.addressLine1,
					addressLine2: values.addressLine2,
					businessURL: values.businessURL,
					city: values.city,
					dob: String(moment(values.dob).unix()),
					postCode: values.postCode,
					state: values.state,
				}
				this.props.updateAccount(body)
			}
		})
	}

	uploadDoc = options => {
		let body = new FormData()
		let fileData = options.file
		body.append('file', fileData)
		body.append('stripeVerificationDocumentType', options.data['purpose'])
		body.append('bankDetailsId', this.state.bankDetailsId)
		this.props
			.uploadStripeDocument(body)
			.then(
				res => {
					if (res.value.status_code === 200) {
						this.checkModalCloseblity(options.data.purpose)
						options.onSuccess({
							message: 'File uploaded',
						})
					} else {
						options.onError({
							message: 'Unable to upload file.',
						})
					}
				},

				res => {
					console.log('err block', res)
				},
			)
			.catch(res => {
				console.log('catch block', res)
			})
	}

	handleChange = info => {
		if (info.file.status === 'done') {
			message.success(`${info.file.name} file uploaded successfully`)
		} else if (info.file.status === 'error') {
			message.error(`${info.file.name} file upload failed.`)
		}
	}

	beforeUpload = file => {
		const isJpgOrPngOrPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
		if (!isJpgOrPngOrPdf) {
			message.error('You can only upload JPG/PNG/PDF file!')
		}
		const isLt2M = file.size / 1024 / 1024 < 10
		if (!isLt2M) {
			message.error('File must be smaller than 10 MB!')
		}
		return isLt2M && isJpgOrPngOrPdf
	}

	enableAddButton = e => {
		if (e.target.checked) {
			this.setState({ enableAddButton: false })
		} else {
			this.setState({ enableAddButton: true })
		}
	}

	checkModalCloseblity = filePurpose => {
		let fileCount = this.state.fileCount
		const currentFile = filePurpose
		if (!fileCount.includes(currentFile)) {
			fileCount.push(currentFile)
		}
		if (fileCount.length === 3) {
			this.props.closeAddAccount()
		}
	}

	render() {
		const { getFieldDecorator } = this.props.form
		const { enableAddButton } = this.state
		return (
			<Form onSubmit={this.handleSubmit} encType='multipart/form-data'>
				{this.state.formPhase === 1 ? (
					<div>
						<div>
							<strong>Please enter the details exactly like it is on your identity documents.</strong>
						</div>
						<br />
						<AeroField fieldsToRender={ADD_CARD_DETAILS_FIELDS} {...this.props} />
						<div>
							{getFieldDecorator('tos', {
								rules: [
									{
										required: true,
										message: 'Please check this to activate your account.',
									},
								],
							})(<Checkbox onChange={this.enableAddButton}>Accept Services Agreement of Stripe (Payment Gateway)</Checkbox>)}
						</div>
						<div style={{ padding: '10px', fontSize: '10px' }}>
							By registering your account, you agree to Stripe's{' '}
							<Button type='link' href='https://stripe.com/legal' target='_blank'>
								Services Agreement
							</Button>{' '}
							and the{' '}
							<Button type='link' href='https://stripe.com/connect-account/legal' target='_blank'>
								Stripe Connected Account Agreement
							</Button>
							.
						</div>
						<Button disabled={enableAddButton} block type='primary' htmlType='submit'>
							Add
						</Button>
					</div>
				) : (
					<div>
						Please upload your identity verfication documents for receiving payments in <strong>pdf</strong>, <strong>jpg</strong> or <strong>png</strong> formats only.
						<br />
						Following is the list of acceptable documents:
						<ul>
							<li>Passport</li>
							<li>Driver license — scans of front and back are required</li>
							<li>ID card — scans of front and back are required</li>
							<li>Photo card (New South Wales)</li>
						</ul>
						<div className='upload-btn'>
							<Upload
								accepts='.jpg, .png, .pdf'
								name='file'
								data={DOC_KEYS[0]}
								onChange={this.handleChange}
								customRequest={this.uploadDoc}
								beforeUpload={this.beforeUpload}
								showUploadList={{ showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false, removeIcon: false, downloadIcon: false }}
							>
								<Button>Click to Upload Identification Document (front)</Button>
							</Upload>
						</div>
						<div className='upload-btn'>
							<Upload
								accepts='.jpg, .png, .pdf'
								name='file'
								data={DOC_KEYS[1]}
								onChange={this.handleChange}
								customRequest={this.uploadDoc}
								beforeUpload={this.beforeUpload}
								showUploadList={{ showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false, removeIcon: false, downloadIcon: false }}
							>
								<Button>Click to Upload Identification Document (back)</Button>
							</Upload>
						</div>
						<div className='upload-btn'>
							<Upload
								accepts='.jpg, .png, .pdf'
								name='file'
								data={DOC_KEYS[2]}
								onChange={this.handleChange}
								customRequest={this.uploadDoc}
								beforeUpload={this.beforeUpload}
								showUploadList={{ showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false, removeIcon: false, downloadIcon: false }}
							>
								<Button>Click to Upload Additional Identification Document</Button>
							</Upload>
						</div>
					</div>
				)}
			</Form>
		)
	}
}

const StripeBankInfo = Form.create({ name: 'STRIPE_BANK_INFO_FORM' })(StripeBankInfoForm)

const mapStateToProps = state => {
	return {
		updateAccountData: state.PaymentsReducer.updateAccountData,
		uploadStripeDocumentSuccess: state.PaymentsReducer.uploadStripeDocumentSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		updateAccount: bindActionCreators(updateAccount, dispatch),
		uploadStripeDocument: bindActionCreators(uploadStripeDocument, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(StripeBankInfo)
