export const CONTACTUS_PILOT = [
	{
		sectionName: 'Account Administration',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'How do I change my account settings?',
				panelBody: [['ordered','Login to your Aerologix account', "Click 'Profile'", "Update the required information and click on 'Save'"]],
			},
			{
				id: '2',
				panelHeader: 'What details I need to add to start using Aerologix?',
				panelBody: [
					'When you first create an account on Aerologix, we encourage you to complete your profile by filling out all your personal details. This information is never available publicly and securely kept with the utmost care.',
					'In order to accept a project, you’ll need to have added the following:',
					['unordered','ARN','CASA License Type  ', 'Bank account details', 'Mobile number', 'Email id'],
					'We require these details before you engage with Customers so we can maintain safety and security on the platform. It’s quick and easy to set up your account with all these details. It’s worth a little effort at the start to help get the most out of Aerologix in the long run.',
				],
			},
			{
				id: '3',
				panelHeader: 'I forgot my password, how can I reset it?',
				panelBody: [
					'You can easily reset your password any time by doing the below.',
					['ordered', 'Click on forgot password', 'An email is sent with the temporary password', 'Use this password to login'],
				],
			},
			{
				id: '4',
				panelHeader: 'How do I deactivate my account?',
				panelBody: [
					'We’ll be sorry to see you go! If there is anything we can do to keep you with Aerologix, let us know. We will be delighted to have you.',
					'To deactivate your account, follow the steps below:',
					['ordered', 'Log into Aerologix and go to Settings', 'Select Account', 'At the bottom of the page, press Deactivate my account'],
					'Once you’ve done this, your account will be deactivated and your public profile can no longer be viewed on the platform.',
					'Changed your mind and want to get back on the platform? You can easily reactivate by entering your details in the login screen. This will trigger your account to be reopened and your public profile to be viewable again.',
					"If you'd like to delete your account, please contact us so we can do this for you. Deleted accounts cannot be reactivated later.",
				],
			},
		],
	},
	{
		sectionName: 'Troubleshooting',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'I can’t sign up.',
				panelBody: [
					"Thanks for your interest in creating a member account with Aerologix, we can't wait to have you on board! If you're having any troubles signing up, please try the below troubleshooting techniques.",
				],
			},
			{
				id: '2',
				panelHeader: 'To troubleshoot technical issues',
				panelBody: [
					[
						'ordered',
						'Ensure you are running the latest update of the app, or accessing the website on a recent browser.',
						'If issues persist, try uninstalling and reinstalling the app, or clearing your cache and cookies on a browser.',
					],
				],
			},
			{
				id: '3',
				panelHeader: 'To troubleshoot email issues',
				panelBody: [
					[
						'ordered',
						"Aerologix only allows one account per user, so you're not able to use an email address that is already on another account. If you can't access your original account, please contact us. ",
						'Check that there are no unnecessary spaces around your email address or intended password.',
						"If you're reactivating an account, use go to Log In instead, or contact us if you're having troubles.",
					],
				],
			},
			{
				id: '4',
				panelHeader: 'I can’t login.',
				panelBody: ["If you already have an Aerologix account and you're not able to log in, try the below troubleshooting techniques."],
			},
		],
	},
	{
		sectionName: 'When using the app',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'Recommendations',
				panelBody: [
					[
						'ordered',
						'Ensure you are running the most recent version of the app. You can check for updates by heading to the App Store or the Google Play store.',
						'Check that there are no unnecessary spaces around your email address or password.',
						'Close down the app. Reopen it and try logging in again.',
						'If none of these options work, try uninstalling and reinstalling again.',
					],
				],
			},
		],
	},
	{
		sectionName: 'When using a mobile browser',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'Recommendations',
				panelBody: [
					['ordered', 'Check that there are no unnecessary spaces around your email address or password.', 'Close down the window. Reopen it and try logging in again.'],
				],
			},
		],
	},
	{
		sectionName: 'When using a desktop browser',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'Recommendations',
				panelBody: [
					[
						'ordered',
						'We suggest using a more recent browser. So if you are using an old browser, please try switching to a different or updated browser instead.',
						'Clear your cookies and cache.',
						'Check that there are no unnecessary spaces around your email address or password.',
					],
					'If the issue persists, please take screenshots of any error messages and get in touch with us.',
				],
			},
			{
				id: '2',
				panelHeader: 'The site isn’t working.',
				panelBody: [
					'If you have encountered issues using a browser, then do the following',
					[
						'ordered',
						'Clear your cookies and cache',
						'Close all internet browsers/windows and then open Aerologix in a new window',
						'Log out and log back in',
						'Try a different internet browser',
					],
					'If this didn’t work, please contact us. Please include screenshots and give us as many details as possible so that we can fix ASAP.',
				],
			},
			{
				id: '3',
				panelHeader: 'How to report a technical issue?',
				panelBody: ['Please contact us with detailed description, device information, and screenshots and we will come back to you ASAP'],
			},
		],
	},
	{
		sectionName: 'Complete A Project',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'How do I accept a project?',
				panelBody: [
						'Login to your Aerologix account',
						'On ‘Home’ screen, click on ‘Ongoing’ on the top right of the screen ',
						'Review the details of the project and click on ‘Accept’',
					],
					
			},
			{
				id: '2',
				panelHeader: 'How do I know the cost of the project?',
				panelBody: ['Project Cost is displayed on the ‘Home’ screen at the far right as well as on the invitation email/notifications received.'],
			},
			{
				id: '3',
				panelHeader: 'I accepted my project, what’s next?',
				panelBody: ['If the Customer allocates the project to you then It’s time to undertake the project by going to the project location and flying the drone to capture the data. Upload the data to Aerocloud and get paid when the Customer is satisfied with the outcome and accepts it. You can find that information on your dashboard under "Completed".'
				],
			},
		],
	},
	{
		sectionName: 'Changes & Cancellations',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'How do I cancel a project?',
				panelBody: ['After you’ve been allocated to a Project, it is not possible to cancel the Project. However, if there are circumstances beyond your control then please contact us at support@aerologix.com to inform us.'],
			},
		],
	},
	{
		sectionName: 'Payments',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'How do I check my payment history?',
				panelBody: [['ordered', 'Login to your Aerologix account', 'Click on ‘Payments’ ']],
			},
			{
				id: '2',
				panelHeader: 'What are the payment methods on Aerologix?',
				panelBody: [
					'We only accept online payment through our secure system managed by Stripe. You will need to add your bank account details to your account in order to transact.',
					'Please be assured that all your details are secure within Stripe.',
					'Add or update your payment settings with the following steps:',
					['ordered', 'Log in to your account', 'Select Payment', 'You’ll be able to add or update your details.'],
				],
			},
			{
				id: '3',
				panelHeader: 'When do I get paid for the project completed?',
				panelBody: ['When the project has been completed and the customer is happy with the work, then you will get paid into your nominated bank account within two business days.'],
			},
		],
	},
]

export const CONTACTUS_CONSUMER = [
	{
		sectionName: 'Account Administration',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'How do I change my account settings?',
				panelBody: [['ordered','Login to your Aerologix account', "Click 'Profile'", "Update the required information and click on 'Save'"]],
			},
			{
				id: '2',
				panelHeader: 'What details I need to add to start using Aerologix?',
				panelBody: ['When you first create an account on Aerologix, we encourage you to complete your profile by filling out all your personal details. This information is never available publicly and securely kept with the utmost care.'
				],
			},
			{
				id: '3',
				panelHeader: 'I forgot my password, how can I reset it?',
				panelBody: [
					'You can easily reset your password any time by doing the below.',
					['ordered', 'Click on forgot password', 'An email is sent with the temporary password', 'Use this password to login'],
				],
			},
			{
				id: '4',
				panelHeader: 'How do I deactivate my account?',
				panelBody: [
					'We’ll be sorry to see you go! If there is anything we can do to keep you with Aerologix, let us know. We will be delighted to have you.',
					'To deactivate your account, follow the steps below:',
					['ordered', 'Log into Aerologix and go to Settings', 'Select Account', 'At the bottom of the page, press Deactivate my account'],
					'Once you’ve done this, your account will be deactivated and your public profile can no longer be viewed on the platform.',
					'Changed your mind and want to get back on the platform? You can easily reactivate by entering your details in the login screen. This will trigger your account to be reopened and your public profile to be viewable again.',
					"If you'd like to delete your account, please contact us so we can do this for you. Deleted accounts cannot be reactivated later.",
				],
			},
		],
	},
	{
		sectionName: 'Troubleshooting',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'I can’t sign up.',
				panelBody: [
					"Thanks for your interest in creating a member account with Aerologix, we can't wait to have you on board! If you're having any troubles signing up, please try the below troubleshooting techniques.",
				],
			},
			{
				id: '2',
				panelHeader: 'To troubleshoot technical issues',
				panelBody: [
					[
						'ordered',
						'Ensure you are running the latest update of the app, or accessing the website on a recent browser.',
						'If issues persist, try uninstalling and reinstalling the app, or clearing your cache and cookies on a browser.',
					],
				],
			},
			{
				id: '3',
				panelHeader: 'To troubleshoot email issues',
				panelBody: [
					[
						'ordered',
						"Aerologix only allows one account per user, so you're not able to use an email address that is already on another account. If you can't access your original account, please contact us. ",
						'Check that there are no unnecessary spaces around your email address or intended password.',
						"If you're reactivating an account, use go to Log In instead, or contact us if you're having troubles.",
					],
				],
			},
			{
				id: '4',
				panelHeader: 'I can’t login.',
				panelBody: ["If you already have an Aerologix account and you're not able to log in, try the below troubleshooting techniques."],
			},
		],
	},
	{
		sectionName: 'When using the app',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'Recommendations',
				panelBody: [
					[
						'ordered',
						'Ensure you are running the most recent version of the app. You can check for updates by heading to the App Store or the Google Play store.',
						'Check that there are no unnecessary spaces around your email address or password.',
						'Close down the app. Reopen it and try logging in again.',
						'If none of these options work, try uninstalling and reinstalling again.',
					],
				],
			},
		],
	},
	{
		sectionName: 'When using a mobile browser',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'Recommendations',
				panelBody: [
					['ordered', 'Check that there are no unnecessary spaces around your email address or password.', 'Close down the window. Reopen it and try logging in again.'],
				],
			},
		],
	},
	{
		sectionName: 'When using a desktop browser',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'Recommendations',
				panelBody: [
					[
						'ordered',
						'We suggest using a more recent browser. So if you are using an old browser, please try switching to a different or updated browser instead.',
						'Clear your cookies and cache.',
						'Check that there are no unnecessary spaces around your email address or password.',
					],
					'If the issue persists, please take screenshots of any error messages and get in touch with us.',
				],
			},
			{
				id: '2',
				panelHeader: 'The site isn’t working.',
				panelBody: [
					'If you have encountered issues using a browser, then do the following',
					[
						'ordered',
						'Clear your cookies and cache',
						'Close all internet browsers/windows and then open Aerologix in a new window',
						'Log out and log back in',
						'Try a different internet browser',
					],
					'If this didn’t work, please contact us. Please include screenshots and give us as many details as possible so that we can fix ASAP.',
				],
			},
			{
				id: '3',
				panelHeader: 'How to report a technical issue?',
				panelBody: ['Please contact us with detailed description, device information, and screenshots and we will come back to you ASAP'],
			},
		],
	},
	{
		sectionName: 'Order A Project',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'How do I order a project?',
				panelBody: [
					[
						'ordered',
						'Login to your Aerologix account',
						'On ‘Home’ screen, click on ‘Create Project’ on the top right of the screen ',
						'Update the required information and click on ‘Save’',
					],
				],
			},
			{
				id: '2',
				panelHeader: 'How do I know the cost of the project?',
				panelBody: ['There is an indicative price for your reference. However, you can consult the pilots after ordering a project to go back and edit the project details such as the cost. '],
			},
			{
				id: '3',
				panelHeader: 'I ordered my project, what’s next?',
				panelBody: ['Sit back and relax while the Pilot undertakes the project and uploads the data for your preview. You can find that information on your dashboard under ‘Ongoing’ tab and look for the specific project.'],
			},
			{
				id: '4',
				panelHeader: 'How do I request a quote from a specific Pilot?',
				panelBody: ['You can order a project and then ‘Invite’ a specific pilot after searching for the Pilots around the project location.'],
			},
			{
				id: '5',
				panelHeader: 'What happens if I get no offer on my project?',
				panelBody: [
					'If you didn’t receive any offers on your project within 48 hours, we would reach out to you to find alternatives. Also try reaching out to specific Pilots in that area by inviting them to submit a quote.',
				],
			},
		],
	},
	{
		sectionName: 'Changes & Cancellations',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'How can I edit my project?',
				panelBody: [
					'Once you have posted a Project and before inviting the pilots you can click on "Change Details". If the pilot hasn’t started the project then please reach out to him/her. If the changes requested are small then there isn’t any cost involved. Depends entirely on the Pilot though.',
				],
			},
			{
				id: '2',
				panelHeader: 'Can I reassign my project to someone else instead?',
				panelBody: [
					"After you've assigned a Pilot, you won't be able to choose someone else to do the project instead.",
					"If something doesn't go to plan and you need a different Pilot, the first step is to request a cancellation of the original project. Next, you'll just need to reorder the project to have receive quotes and find a new Pilot.",
				],
			},
			{
				id: '3',
				panelHeader: 'How do I cancel a project?',
				panelBody: ['After you’ve assigned a Pilot for a Project, it is not possible to cancel the Project. If you do then you will lose 50% of the cost of the project'],
			},
		],
	},
	{
		sectionName: 'Payments',
		sectionQuestions: [
			{
				id: '1',
				panelHeader: 'How do I check my payment history?',
				panelBody: [['ordered', 'Login to your Aerologix account', 'Click on ‘Payments’']],
			},
			{
				id: '2',
				panelHeader: 'What are the payment methods on Aerologix?',
				panelBody: ['We only accept online payment through our secure system managed by Stripe. Please be assured that all your details are secure within Stripe.'],
				
			},
			{
				id: '3',
				panelHeader: 'When should I release payment?',
				panelBody: [
					'The best time to release payment is when the Pilot has completed your project and you’re happy with the outcome.',
					'When making the decision to release the payment, use the following checklist:',
					['ordered', 'Is the task completed to the description?', 'Are you happy with the result of the Project?', 'Are you satisfied with the quality of work?'],
					"We suggest to only release payment after you've inspected the preview and confirmed it's completed to your requirements.",
					"If you're not ready to release the payment for any reason, try communicating with the Pilot to let them know your feedback.  If you still aren’t able to resolve then please contact us.",
					"Remember: once payment has been released, it is transferred into the Pilot's bank account and is no longer held securely with Stripe. After this time we are unable to help retrieve any funds.",
				],
			},
			{
				id: '4',
				panelHeader: 'How do I release payment to the Pilot?',
				panelBody: [
					"If you're satisfied with the preview and to release payment,to get the complete project zip folder, please follow the steps below:",
					[ 'ordered','Log in to Aerologix.', 'Click on Ongoing', 'Select the relevant Project.', 'On the project page, click on "Complete".'],
				],
			},
			{
				id: '5',
				panelHeader: 'My credit card payment didn’t go through',
				panelBody: [
					'If you’re trying to add a valid card with sufficient funds, and it’s not being accepted, the first step is to review the card details to double check they’re correct. You can do this by logging in to your account, selecting Payment Methods and looking at the following:',
					'When making the decision to release the payment, use the following checklist:',
					[
						'unordered',
						'Your name is as it appears on your card without the title (Mr/Mrs/Ms, etc.)',
						'The card number, expiry and CVC are all correct',
						'There are no spaces before or after any of the numbers',
						'Make sure that if your card is new, you’ve checked it has been activated by your bank.',
					],
					'If all of those details are correct and you’re still having issues, you could try adding your payment details on another device or in a different browser.',
					"If that still it doesn’t work, contact us and provide the first 6 and last 3 digits of the card that you're trying to use, with a screenshot of any errors that you're receiving. For security purposes, please don’t provide the full number.",
				],
			},
		],
	},
]
