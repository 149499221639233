import React from 'react'
import { Input } from 'antd'
import AeroSelect from '../../Select'
import ResendOtp from '../../../Modules/OnBoarding/resend-otp'
import { validateMobileNumber } from '../../../Services/UtilityService'

export const MOBILE_NIUMBER_VERIFICATION_FIELDS = (scope, initialNumber, filedRequired) => {
	return [
		{
			fieldName: 'PN',
			fieldLabel: 'Phone Number (Phone number will be used to send project updates)',
			fieldKey: 'mobileNumber',
			fileObject: {
				rules: [
					{
						required: filedRequired,
						message: 'Please input your phone number!',
					},
					{
						validator: validateMobileNumber,
					},
				],
				initialValue: initialNumber ? initialNumber : '',
			},
			fieldToRender: (
				<Input type='number' size='large' addonBefore={<AeroSelect />} addonAfter={<ResendOtp getPhoneNumber={scope.getPhoneNumber} setOtpKey={scope.setOtpKey} />} />
			),
		},
		{
			fieldName: 'PN_VERIFY_OTP',
			fieldLabel: 'Verification Code',
			fieldKey: 'verificationCode',
			fileObject: {
				rules: [
					{
						required: filedRequired,
						message: 'Please input the OTP received!',
					},
				],
			},
			fieldToRender: <Input type='number' style={{ height: '38px' }} />,
		},
	]
}
