import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'antd'
import { AeroCard, AeroEmpty, AeroSearch } from '../../../../AeroComponents'
import { getOrderDetails, getProjectsForCards } from '../../../../Stores/Projects/action'
import CompletedProjectsCards from './CompletedProjectsCards'
import InProgressProjectsCards from './InProgressProjectsCards'
import ScheduledProjectsCards from './ScheduledProjectsCards'
import styles from './projectCards.module.scss'

function ProjectCards(props) {
	// extract props
	const {
		projectsForCards, // from state
		getOrderDetails,
		getProjectsForCards, // from dispatch
		orderId,
		downloadProjectData,
		openLiveStreamModal, // from parent
		openAdditionalDataModal,
	} = props

	// states
	const [completedProjects, setcompletedProjects] = useState([])
	const [inProgressProjects, setinProgressProjects] = useState([])
	const [scheduledProjects, setscheduledProjects] = useState([])

	// updates local state when projectsForCards gets updated
	useEffect(() => {
		if (projectsForCards) {
			setcompletedProjects(projectsForCards.completed)
			setinProgressProjects(projectsForCards.inProgress)
			setscheduledProjects(projectsForCards.scheduled)
		}
	}, [projectsForCards])

	// Initial render
	useEffect(() => {
		if (orderId) {
			getOrderDetails({ orderId: orderId })
			getProjectsForCards({ orderId: orderId })
		}
	}, [orderId, getOrderDetails, getProjectsForCards])

	// search cards with given value
	const searchProjectsCards = value => {
		if (value) {
			getProjectsForCards({ searchToken: value, orderId: orderId })
		} else {
			getProjectsForCards({ orderId: orderId })
		}
	}

	// Render all the scheduled,completed and inprogress cards
	const renderProjectCards = () => {
		// max number of rows for the table
		const loopSize = Math.max(completedProjects.length, inProgressProjects.length, scheduledProjects.length)
		let finalElem = []
		for (let index = 0; index < loopSize; index++) {
			finalElem.push(
				<Row className={styles['row']}>
					<Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
						<CompletedProjectsCards
							openAdditionalDataModal={openAdditionalDataModal}
							index={index}
							completedProjects={completedProjects}
							downloadProjectData={downloadProjectData}
						></CompletedProjectsCards>
					</Col>
					<Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
						<InProgressProjectsCards
							openAdditionalDataModal={openAdditionalDataModal}
							index={index}
							inProgressProjects={inProgressProjects}
							openLiveStreamModal={openLiveStreamModal}
							downloadProjectData={downloadProjectData}
						></InProgressProjectsCards>
					</Col>
					<Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
						<ScheduledProjectsCards openAdditionalDataModal={openAdditionalDataModal} index={index} scheduledProjects={scheduledProjects}></ScheduledProjectsCards>
					</Col>
				</Row>,
			)
		}

		return finalElem
	}

	return (
		<>
			<AeroCard className={styles['projects-details-card']}>
				{/* If there is no project in any type of card show empty image */}
				{completedProjects.length === 0 && inProgressProjects.length === 0 && scheduledProjects.length === 0 ? (
					<AeroEmpty
						image={AeroEmpty.PRESENTED_IMAGE_SIMPLE}
						description={
							<div className={styles['empty-description']}>
								No projects yet<div>All your projects will be shown here</div>
							</div>
						}
					></AeroEmpty>
				) : (
					// If cards are there display the header and the data
					<div>
						<AeroSearch className={styles['search-box']} placeholder='Search Project Name or Project Type' onSearch={searchProjectsCards} />
						<div className={styles['table']}>
							{/* Header */}
							<Row>
								<Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
									C O M P L E T E D
								</Col>
								<Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
									I N - P R O G R E S S
								</Col>
								<Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
									S C H E D U L E D
								</Col>
							</Row>

							{/* Body */}
							{renderProjectCards()}
						</div>
					</div>
				)}
			</AeroCard>
		</>
	)
}

const mapStateToProps = state => {
	return {
		projectsForCards: state.ProjectsReducer.projectsForCards, // all projects data
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getOrderDetails: bindActionCreators(getOrderDetails, dispatch),
		getProjectsForCards: bindActionCreators(getProjectsForCards, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProjectCards)
