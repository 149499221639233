import React, { Component } from 'react'
import { message, Button, Upload, Spin } from 'antd'

class ProfilePicture extends Component {
	constructor(props) {
		super(props)
		this.state = {
			imageUrl: null,
			loading: false,
		}
	}

	getBase64 = (img, callback) => {
		const reader = new FileReader()
		reader.addEventListener('load', () => callback(reader.result))
		reader.readAsDataURL(img)
	}

	beforeUpload = file => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!')
		}
		const isLt2M = file.size / 1024 / 1024 < 10
		if (!isLt2M) {
			message.error('Image must smaller than 10MB!')
		}
		if (isJpgOrPng && isLt2M) {
			this.setState({
				loading: true,
			})
			this.getBase64(file, imageUrl =>
				this.props.updateProfilePicture({
					profilePicture: imageUrl,
					mixpanel_type: 'profile_picture',
				}),
			)
		}
		return isJpgOrPng && isLt2M
	}

	uploadProfilePicture = options => {
		setTimeout(() => {
			options.onSuccess({ message: 'Uploaded' })
			// todo: improve logic
			this.setState({ loading: false })
		}, 0)
	}

	render() {
		return (
			<Spin spinning={this.state.loading}>
				<Upload showUploadList={false} accepts='.jpg, .png' name='file' customRequest={this.uploadProfilePicture} beforeUpload={this.beforeUpload}>
					<div style={{ padding: '10px', marginLeft: '45px' }}>
						<div>
							<strong>Upload Picture</strong> (Max file size of 10 MB - jpeg or png)
						</div>
						<br />
						<Button style={{ height: '48px' }} block>
							Click to Upload
						</Button>
					</div>
				</Upload>
			</Spin>
		)
	}
}

export default ProfilePicture
