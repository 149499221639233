import React from 'react'
import { Button } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
const S3Uploader = props => {
	const { dataType, isUploading, checkData, handleOpenPicker } = props
	return (
		<div className='view-project-details'>
			<div className='active-card-header'>
				<div className='card-header-content'>
					<div className='heading-active'>PROJECT DATA ({dataType.toUpperCase()})</div>
					<span style={{ float: 'right' }}>
						<Button disabled={isUploading()} onClick={() => handleOpenPicker(dataType.toLowerCase() === 'complete' ? 'upload' : dataType.toLowerCase())}>
							<UploadOutlined /> Upload {dataType === 'Complete' ? 'Data' : dataType}
						</Button>
					</span>
				</div>
			</div>
			<div className='card-content'>
				{checkData ? (
					<a href={checkData}>
						<MatImage src={IMAGES_SRC.ZIP_ICON} />
					</a>
				) : null}
			</div>
		</div>
	)
}

export default S3Uploader
