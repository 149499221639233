import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Descriptions, Button } from 'antd'

class ContactDetailsForm extends Component {
	render() {
		const { data } = this.props
		return (
			<>
				<div style={{ border: '1px solid #ECEAEE', borderRadius: '12px', padding: '20px', paddingTop: '50px', marginBottom: '20px', marginTop: '20px' }}>
					<Descriptions>
						<Descriptions.Item label='Email'>{data.email}</Descriptions.Item>
					</Descriptions>
				</div>
				<div style={{ border: '1px solid #ECEAEE', borderRadius: '12px', padding: '20px', paddingTop: '50px' }}>
					<Descriptions>
						<Descriptions.Item label='Phone Number'>
							<div style={{ position: 'relative', bottom: '4px' }}>
								{data.mobileNumber}{' '}
								<Button
									type='link'
									onClick={() => {
										this.props.updateInfo('PN_VERIFICATION')
									}}
								>
									{' '}
									{data.mobileNumber ? 'Edit' : 'Add phone number'}
								</Button>
							</div>
						</Descriptions.Item>
					</Descriptions>
				</div>
			</>
		)
	}
}

const ContactDetails = Form.create({ name: 'CONTACT_DETAILS_FORM' })(ContactDetailsForm)

export default ContactDetails
