/*eslint-disable*/
import * as ACTION from './actionTypes'
import typeToReducer from 'type-to-reducer'
import { setValueToStorage, getValueFromStorage, clearToken, initializeAuth,checkForEmailAndPhoneNumber } from '../../Services/LocalStorageService'
import { message } from 'antd'

const initialState = {
	authState: {
		isAuthenticated: initializeAuth(),
		authenticate() {
			this.isAuthenticated = true
		},
		signout() {
			this.isAuthenticated = false
			clearToken()
		},
		checkAndAuthenticate( hasEmail,hasMobileNumber,token){
			if(checkForEmailAndPhoneNumber( hasEmail,hasMobileNumber))
			{
				this.authenticate()
				setValueToStorage('TOKEN', token)
			}
			else{
				setValueToStorage('TEMP_TOKEN', token)
			}
		}
	},
	pageLoading: false,
	modalLoading: false,
	token: null,
	profileData: null,
	countriesData: null,
	statesData: null,
	droneTypesData: null,
	casaLicenseTypesData: null,
	citiesData: null,
	forgotSuccess: false,
	verifyWithDvsSuccess: false,
	updateBaseLocationSuccess: null,
	deleteCasaLicenseSuccess: null,
	addCasaLicenseSuccess: null,
	addArnDetailsSuccess: null,
	sendReferralInviteSuccess: null,
	verifySharableLinkData: null,
	socialAuthData: false,
	getOtpData: null,
	updateProfileSuccess: null,
	rejectEnterprisePilotPromptSuccess: false,
	createEnterprisePilotSuccess: null,
	enterprisePilotData: null,
	updateEnterprisePilotData: null,
	deleteDroneByIdData: null,
	updateEmailSuccess: false,
	changePasswordSuccess: null,
	addCollaboratorSuccess: null,
	ordersNotOfCollaborator: null,
	deleteCollaboratorSuccess: null,
	updateCollaboratorPermissionsSuccess: false,
	contactSupportSuccess: false,
	pilotPortfolioData: null,
	portfolioUploadUrl: null,
	deletePortfolioMediaSuccess: null,
	updateCasaLicenseSuccess: null,
	userDeletionFlag: null,
	loginData: null,
	recoverySuccess: null,
	chatAccessToken: null,
	contractDetails: null,
	allNotifications: null,
	webConfig: null,
	allLicenseTypes: null,
	allDroneInsuranceTypes: null,
	addDroneInsuranceSuccess: null,
	allPurchasedInsurance: null,
	deleteDroneInsuranceSuccess: null,
	updateInsuranceSuccess: null,
	getEmailOtpData: null,
}

function mapDroneEnumToDisplayValue(data) {
	let mappedData = []
	data.map(datum => {
		mappedData.push({
			value: datum,
			displayValue: datum.split('_').join(' '),
		})
	})
	return mappedData
}

function manuplateProjectPrefer(data) {
	const cloneData = Object.assign({}, data)
	if (cloneData.preferences) {
		let projectTypeSelected = {
			photos: false,
			videos: false,
			photoVideos: false,
		}
		const data2 = {
			PHOTOS: 'photos',
			VIDEOS: 'videos',
			PHOTOS_AND_VIDEOS: 'photoVideos',
		}

		if (cloneData.preferences.projectType) {
			cloneData.preferences.projectType.forEach(element => {
				projectTypeSelected[data2[element]] = true
			})

			cloneData.preferences = {
				...cloneData.preferences,
				projectTypeSelected,
			}
		}
	}
	return cloneData
}

const UserSessionReducer = typeToReducer(
	{
		LOG_IN: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.data && action.payload.status_code === 200) {
					if (action.payload.data.deletionRequest) {
						return Object.assign({}, state, {
							userDeletionFlag: Math.random(),
							loginData: action.payload.data,
						})
					} else {
						state.authState.authenticate()
						setValueToStorage('TOKEN', action.payload.data.token)
						const webConfig = action.payload.data.configData
						webConfig.pilotModules = JSON.parse(action.payload.data.configData.pilotModules)
						webConfig.consumerModules = JSON.parse(action.payload.data.configData.consumerModules)
						setValueToStorage('WEB_CONFIG_DATA', JSON.stringify(webConfig))
						if (action.payload.data.subrole === 'CLIENT_ENTERPRISE' || action.payload.data.subrole === 'CLIENT_COLLABORATOR') {
							setValueToStorage('SUBROLE', action.payload.data.subrole)
							if (action.payload.data.permissions.length !== 0) {
								setValueToStorage('PERMISSIONS', action.payload.data.permissions)
							}
						}
						return Object.assign({}, state, {
							token: action.payload.data.token,
							webConfig: webConfig,
						})
					}
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		SOCIAL_MEDIA_LOGIN: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.data && action.payload.status_code === 200) {
					if (action.payload.data.deletionRequest) {
						return Object.assign({}, state, {
							userDeletionFlag: Math.random(),
							loginData: action.payload.data,
						})
					} else {
						state.authState.authenticate()
						setValueToStorage('TOKEN', action.payload.data.token)
						const webConfig = action.payload.data.configData
						webConfig.pilotModules = JSON.parse(action.payload.data.configData.pilotModules)
						webConfig.consumerModules = JSON.parse(action.payload.data.configData.consumerModules)
						setValueToStorage('WEB_CONFIG_DATA', JSON.stringify(webConfig))
						if (action.payload.data.subrole === 'CLIENT_ENTERPRISE' || action.payload.data.subrole === 'CLIENT_COLLABORATOR') {
							setValueToStorage('SUBROLE', action.payload.data.subrole)
							if (action.payload.data.permissions.length !== 0) {
								setValueToStorage('PERMISSIONS', action.payload.data.permissions)
							}
						}
						return Object.assign({}, state, {
							token: action.payload.data.token,
							webConfig: webConfig,
						})
					}
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		UPDATE_PROFILE: {
			PENDING: state => {
				return Object.assign({}, state, {
					updateProfileSuccess: null,
				})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Profile update successfully')
					return Object.assign({}, state, {
						profileData: manuplateProjectPrefer(action.payload.data),
						updateProfileSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Not able to update the profile. Please Try Agin Later.')
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		GET_PROFILE: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				return Object.assign({}, state, {
					profileData: action.payload && action.payload.data ? manuplateProjectPrefer(action.payload.data) : null,
				})
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		LOG_OUT: state => {
			state.authState.signout()
			window.localStorage.removeItem('SUBROLE')
			window.localStorage.removeItem('PERMISSIONS')
			window.sessionStorage.removeItem('compBannerVisible')
			return Object.assign({}, state, {
				token: null,
				profileData: null,
			})
		},
		APP_LOADER: (state, action) => {
			let objectToReturn
			switch (action.payload) {
				case 'PAGE':
					objectToReturn = Object.assign({}, state, {
						pageLoading: !state.pageLoading,
					})
					break
				case 'MODAL':
					objectToReturn = Object.assign({}, state, {
						modalLoading: !state.modalLoading,
					})
					break
				default:
					objectToReturn = Object.assign({}, state, {})
					break
			}
			return objectToReturn
		},
		FORGOT_PASS: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Email has been sent successfully')
					return Object.assign({}, state, {
						forgotSuccess: true,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				}
				return Object.assign({}, state, {})
			},
			REJECTED: (state, action) => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_COUNTRIES]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload) {
					return Object.assign({}, state, {
						countriesData: action.payload.data,
					})
				} else {
					message.error('Failed to load countries')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_STATES]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload) {
					return Object.assign({}, state, {
						statesData: action.payload.data,
					})
				} else {
					message.error('Failed to load states')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: (state, action) => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_CITIES]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload) {
					return Object.assign({}, state, {
						citiesData: action.payload.data,
					})
				} else {
					message.error('Failed to load cities')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: (state, action) => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ALL_DRONES]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload) {
					return Object.assign({}, state, {
						droneTypesData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Failed to load drone types')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: (state, action) => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ALL_CASA_LICENSE_TYPES]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload) {
					return Object.assign({}, state, {
						casaLicenseTypesData: mapDroneEnumToDisplayValue(action.payload.data),
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Failed to load CASA license types')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: (state, action) => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.CHANGE_PASSWORD]: {
			PENDING: state => {
				return Object.assign({}, state, {
					changePasswordSuccess: false,
				})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Password Updated Successfully')
					return Object.assign({}, state, {
						changePasswordSuccess: true,
					})
				}
				if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Not able to change password. Please Try Agin Later.')
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.VERIFY_WITH_DVS]: {
			PENDING: state => {
				return Object.assign({}, state, {
					verifyWithDvsSuccess: false,
				})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Profile Verified Successfully with DVS')
					return Object.assign({}, state, {
						verifyWithDvsSuccess: true,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.eror('Not able to verify with DVS. Please Try Agin Later.')
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPDATE_BASE_LOCATION]: {
			PENDING: state => {
				return Object.assign({}, state, {
					updateBaseLocationSuccess: false,
				})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Profile updated successfully')
					return Object.assign({}, state, {
						profileData: manuplateProjectPrefer(action.payload.data),
						updateBaseLocationSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Not able to update the profile. Please Try Agin Later.')
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.DELETE_CASA_LICENSE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('License deleted successfully')
					return Object.assign({}, state, {
						deleteCasaLicenseSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.ADD_CASA_LICENSE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						addCasaLicenseSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.ADD_ARN_DETAILS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload) {
					message.success('ARN details added successfully')
					return Object.assign({}, state, {
						addArnDetailsSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.SEND_REFERRAL_INVITE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success(
						'Thanks for the referral. Remember the more you refer, the more you earn! So why not post your unique referral link on Facebook or simply tweet it!',
					)
					return Object.assign({}, state, {
						sendReferralInviteSuccess: true,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.VERIFY_SHARABLE_LINK]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						verifySharableLinkData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_SOCIAL_AUTH]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					state.authState.checkAndAuthenticate(action.payload.data.hasEmail , action.payload.data.hasMobileNumber , action.payload.data.token )
					
					const webConfig = action.payload.data.configData
					webConfig.pilotModules = JSON.parse(action.payload.data.configData.pilotModules)
					webConfig.consumerModules = JSON.parse(action.payload.data.configData.consumerModules)
					setValueToStorage('WEB_CONFIG_DATA', JSON.stringify(webConfig))
					setValueToStorage('HAS_EMAIL', action.payload.data.hasEmail)
					setValueToStorage('HAS_MOBILE', action.payload.data.hasMobileNumber)
					return Object.assign({}, state, {
						token: action.payload.data.token,
						webConfig: webConfig,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
					return Object.assign({}, state, {})
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_OTP]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('OTP has been sent to your phone number')
					return Object.assign({}, state, {
						getOtpData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
					return Object.assign({}, state, {
						getOtpData: null,
					})
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.REJECT_ENTERPRISE_PILOT_PROMPT]: {
			PENDING: state => {
				return Object.assign({}, state, {
					rejectEnterprisePilotPromptSuccess: false,
				})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						rejectEnterprisePilotPromptSuccess: true,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Not able to reject. Please Try Agin Later.')
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.CREATE_ENTERPRISE_PILOT]: {
			PENDING: state => {
				return Object.assign({}, state, {
					createEnterprisePilotSuccess: false,
				})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						createEnterprisePilotSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Not able to create. Please Try Agin Later.')
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ENTERPRISE_PILOT_DATA]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						enterprisePilotData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Not able to load data. Please Try Agin Later.')
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPDATE_ENTERPRISE_PILOT]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Changes saved successfully')
					return Object.assign({}, state, {
						updateEnterprisePilotData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Not able to upload data. Please Try Agin Later.')
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.DELETE_DRONE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Drone deleted successfully')
					return Object.assign({}, state, {
						deleteDroneByIdData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPDATE_EMAIL]: {
			PENDING: state => {
				return Object.assign({}, state, {
					updateEmailSuccess: false,
				})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						updateEmailSuccess: true,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Not able to send. Please Try Agin Later.')
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.ADD_COLLABORATOR]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Collaborator added successfully')
					return Object.assign({}, state, {
						addCollaboratorSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ORDERS_NOT_OF_COLLABORATOR]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						ordersNotOfCollaborator: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Failed to fetch all orders!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: (state, action) => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.DELETE_COLLABORATOR]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Collaborator deleted successfully')
					return Object.assign({}, state, {
						deleteCollaboratorSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPDATE_COLLABORATOR_PERMISSIONS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Collaborator permissions updated successfully')
					return Object.assign({}, state, {
						updateCollaboratorPermissionsSuccess: true,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.CONTACT_SUPPORT]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Support ticket created successfully')
					return Object.assign({}, state, {
						contactSupportSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_PILOT_PORTFOLIO]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						pilotPortfolioData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_PORTFOLIO_UPLOAD_URL]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						portfolioUploadUrl: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.DELETE_PORTFOLIO_MEDIA]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						deletePortfolioMediaSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPDATE_CASA_LICENSE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						updateCasaLicenseSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.RECOVER_PILOT_ACCOUNT]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						recoverySuccess: Math.random(),
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_CHAT_ACCESS_TOKEN]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						chatAccessToken: action.payload.data.accessToken,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ENTERPRISE_PLUS_CONTRACT_API]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						contractDetails: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.COMPLETE_PILOT_ENTERPRISE_PLUS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						profileData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPDATE_DEVICE_TOKEN]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ALL_NOTIFICATIONS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						allNotifications: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPDATE_NOTIFICATION_READ_STATUS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.PASSED_QUIZ]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ALL_LICENSE_TYPES]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						allLicenseTypes: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ALL_DRONE_INSURANCE_TYPES]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						allDroneInsuranceTypes: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.ADD_DRONE_INSURANCE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						addDroneInsuranceSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ALL_PURCHASED_INSURANCE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						allPurchasedInsurance: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.DELETE_DRONE_INSURANCE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						deleteDroneInsuranceSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPDATE_DRONE_INSURANCE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						updateInsuranceSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.REPORT_ERROR]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				return Object.assign({}, state, {})
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_WEB_CONFIG]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				return Object.assign({}, state, {
					webConfig: action.payload,
				})
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},

		[ACTION.GET_EMAIL_OTP]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('OTP has been sent to your email id')
					return Object.assign({}, state, {
						getOtpData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
					return Object.assign({}, state, {
						getOtpData: null,
					})
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},

		[ACTION.GET_EMAIL_AND_MOBILE_OTP]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('OTP has been sent to your phone number and email id')
					return Object.assign({}, state, {
						getOtpData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
					return Object.assign({}, state, {
						getOtpData: null,
					})
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},

		[ACTION.VERIFY_EMAIL_AND_MOBILE_OTP]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					setValueToStorage('TOKEN', action.payload.data.token)
					return Object.assign({}, state, {
						token: action.payload.data.token,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
					return Object.assign({}, state, {
						 
					})
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
	},
	initialState,
)

export default UserSessionReducer
