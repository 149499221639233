import { Button, Collapse, Space } from 'antd'
import React from 'react'
import { IM, IMAGES_SRC, IMAGES_SRCAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import MatImage from '../../../AeroComponents/Image'
const { Panel } = Collapse

const ProcessedDownloadModalContent = props => {
	const { models, curves, cloud, orthomosaic, others, reports } = props
	return (
		<Collapse expandIconPosition='end' bordered={false}>
			<Panel header='DIGITAL MODELS' key='1'>
				{models && models?.length
					? models.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<Button href={item.url} style={{ color: '#096DD9' }} type='link'>
									<Space>
										<MatImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</Button>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
			<Panel header='LEVEL CURVES' key='2'>
				{curves && curves?.length
					? curves.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<Button href={item.url} style={{ color: '#096DD9' }} type='link'>
									<Space>
										<MatImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</Button>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
			<Panel header='POINT CLOUD' key='3'>
				{cloud && cloud?.length
					? cloud.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<Button href={item.url} style={{ color: '#096DD9' }} type='link'>
									<Space>
										<MatImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</Button>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
			<Panel header='ORTHOMOSAIC' key='4'>
				{orthomosaic && orthomosaic?.length
					? orthomosaic.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<Button href={item.url} style={{ color: '#096DD9' }} type='link'>
									<Space>
										<MatImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</Button>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
			<Panel header='REPORTS' key='5'>
				{reports && reports?.length
					? reports.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<Button href={item.url} style={{ color: '#096DD9' }} type='link'>
									<Space>
										<MatImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</Button>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
			<Panel header='OTHERS' key='6'>
				{others && others?.length
					? others.map((item, index) => (
							<div key={index} style={{ marginBottom: '15px' }}>
								<Button href={item.url} style={{ color: '#096DD9' }} type='link'>
									<Space>
										<MatImage alt='processed-item' src={IMAGES_SRC.PHOTO_FILLED} /> <span>{item.name}</span>
									</Space>
								</Button>
							</div>
					  ))
					: 'No data available!'}
			</Panel>
		</Collapse>
	)
}

export default ProcessedDownloadModalContent
