export const IMAGES_SRC = {
	LOGO_BLACK: require('../../Assets/Images/aerologix_black_logo.svg'),
	LOGO_WHITE: require('../../Assets/Images/aerologix_white_logo.svg'),
	SITE_SCAN: require('../../Assets/Images/site_scan.png'),
	'3D_MODELING': require('../../Assets/Images/3d_modeling.png'),
	FREE_FLIGHT: require('../../Assets/Images/free_flight.png'),
	FLIGHT_ON_A_LINE: require('../../Assets/Images/flight_on_a_line.png'),
	SIGNED_UP_IMAGE: require('../../Assets/Images/signed_up.svg'),
	PILOT_ICON: require('../../Assets/Images/pilot_icon.svg'),
	CONSUMER_ICON: require('../../Assets/Images/consumer_icon.svg'),
	HOME_ICON: require('../../Assets/Images/home-icon.svg'),
	HOME_ICON_SELECTED: require('../../Assets/Images/home-icon-selected.svg'),
	MESSAGES_ICON: require('../../Assets/Images/message.svg'),
	AEROCLOUD_ICON: require('../../Assets/Images/aerocloud.svg'),
	PROFILE_ICON: require('../../Assets/Images/profile.svg'),
	PAYMENT_ICON: require('../../Assets/Images/payment.svg'),
	SETTINGS_ICON: require('../../Assets/Images/setting.svg'),
	FAQ_ICON: require('../../Assets/Images/FAQ.svg'),
	CONTACT_ICON: require('../../Assets/Images/contact.svg'),
	LOGOUT_ICON: require('../../Assets/Images/logout.svg'),
	RECOVERY_SENT_ICON: require('../../Assets/Images/recovery_sent.svg'),
	VISA_CARD_ICON: require('../../Assets/Images/visa_card.svg'),
	CHECK_ICON: require('../../Assets/Images/check_icon.svg'),
	PROJECT_ICON: require('../../Assets/Images/project_icon.svg'),
	DUMMY_DRONE: require('../../Assets/Images/drone.svg'),
	MARKER_ICON: require('../../Assets/Images/location.svg'),
	FULL_STAR_ICON: require('../../Assets/Images/full_star.svg'),
	HALF_STAR_ICON: require('../../Assets/Images/half_star.svg'),
	STOP_ICON: require('../../Assets/Images/stop.svg'),
	SUCCESS_ICON: require('../../Assets/Images/success_icon.svg'),
	IMAGES_PROJECT: require('../../Assets/Images/images.svg'),
	VIDEOS_PROJECT: require('../../Assets/Images/videos.svg'),
	IMAGES_AND_VIDEOS: require('../../Assets/Images/images_and_videos.svg'),
	'3D_PROJECT': require('../../Assets/Images/3D.svg'),
	ZIP_ICON: require('../../Assets/Images/zip.svg'),
	LINK_EXPIRED_ICON: require('../../Assets/Images/link_expired.svg'),
	DP_PLACEHOLDER: require('../../Assets/Images/dp_placeholder.svg'),
	STRIPE_LOGO: require('../../Assets/Images/stripe_logo.svg'),
	LOCK_ICON: require('../../Assets/Images/lock.svg'),
	CLOCK_ICON: require('../../Assets/Images/clock.svg'),
	BIN_ICON: require('../../Assets/Images/bin_icon.svg'),
	CLOSE_CIRCLE: require('../../Assets/Images/close_circle.svg'),
	WARNING_ICON: require('../../Assets/Images/warning_icon.svg'),
	UPGRADES_ICON: require('../../Assets/Images/upgrades_icon.svg'),
	INSURANCE_ICON: require('../../Assets/Images/insurance_icon.svg'),
	UPGRADE_REMOTE_ICON: require('../../Assets/Images/upgrade_remote_icon.svg'),
	UPGRADE_DRONE_ICON: require('../../Assets/Images/upgrade_drone_icon.svg'),
	REFER_ICON: require('../../Assets/Images/refer_icon.svg'),
	FACEBOOK_ICON: require('../../Assets/Images/facebook_icon.svg'),
	TWITTER_ICON: require('../../Assets/Images/twitter_icon.svg'),
	DOT_ICON: require('../../Assets/Images/dot.svg'),
	APPOVE_ICON: require('../../Assets/Images/approve_icon.svg'),
	DISAPPROVE_ICON: require('../../Assets/Images/disapprove_icon.svg'),
	UPGRADE_RISK_ICON: require('../../Assets/Images/upgrade_risk_icon.svg'),
	GIFT_ICON: require('../../Assets/Images/gift_icon.svg'),
	MULTISTREAM_ICON: require('../../Assets/Images/multistream_icon.svg'),
	SMALL_GIFT_ICON: require('../../Assets/Images/small_gift_icon.svg'),
	FACEBOOK_F: require('../../Assets/Images/fb_f.svg'),
	GOOGLE_G: require('../../Assets/Images/google_g.svg'),
	APPLE_LOGO: require('../../Assets/Images/apple_logo.svg'),
	EARNING_ICON: require('../../Assets/Images/earning_icon.svg'),
	DATA_SECURITY_ICON: require('../../Assets/Images/data_security_icon.svg'),
	INCOMPLETE_ICON: require('../../Assets/Images/incomplete_icon.svg'),
	NEW_FEATURE_ICON: require('../../Assets/Images/new_feature_icon.svg'),
	INTRODUCE_ICON: require('../../Assets/Images/introduce_icon.svg'),
	BUY_INSURANCE_BTN_ICON: require('../../Assets/Images/buy_insurance_btn_icon.svg'),
	VACATION_MODE_OFF_ICON: require('../../Assets/Images/vacation_mode_off_icon.svg'),
	VACATION_MODE_ON_ICON: require('../../Assets/Images/vacation_mode_on_icon.svg'),
	INFO_CIRCLE_ICON: require('../../Assets/Images/info_circle_icon.svg'),
	ENTERPRISE_FLAG_ICON: require('../../Assets/Images/enterprise_flag_icon.svg'),
	RED_EXCLAMATION_ICON: require('../../Assets/Images/red_exclamation_icon.svg'),
	BIG_WHITE_EXCLAMATION_ICON: require('../../Assets/Images/big_white_exclamation_icon.svg'),
	OVERVIEW_ICON: require('../../Assets/Images/overview.svg'),
	OVERVIEW_ICON_SELECTED: require('../../Assets/Images/overview-selected.svg'),
	ORDERS_ICON: require('../../Assets/Images/orders.svg'),
	ORDERS_ICON_SELECTED: require('../../Assets/Images/orders-selected.svg'),
	TRANSACTIONS_ICON_SELECTED: require('../../Assets/Images/transaction-selected.svg'),
	TRANSACTIONS_ICON: require('../../Assets/Images/transaction.svg'),
	COMPLETED_DOT: require('../../Assets/Images/completed_dot.svg'),
	ONGOING_DOT: require('../../Assets/Images/ongoing_dot.svg'),
	DRONE_MARKER: require('../../Assets/Images/drone_marker.svg'),
	AERO_CREDIT_ICON: require('../../Assets/Images/aero_credit_icon.svg'),
	RAISE_TICKET_ICON: require('../../Assets/Images/raise_ticket_icon.svg'),
	COPY_ICON: require('../../Assets/Images/copy_icon.svg'),
	EMAIL_SENT_ICON: require('../../Assets/Images/email_sent_icon.svg'),
	BIG_BELL_ICON: require('../../Assets/Images/big_bell_icon.svg'),
	GREY_MARKER_ICON: require('../../Assets/Images/grey_marker_icon.svg'),
	ARCHIVE_ICON: require('../../Assets/Images/archive.svg'),
	SMALL_AERO_CREDIT_ICON: require('../../Assets/Images/small_aero_credit_icon.svg'),
	TAX_SUMMARY_ICON: require('../../Assets/Images/tax_summary_icon.svg'),
	NOT_FOUND_ICON: require('../../Assets/Images/not_found_icon.svg'),
	SERVER_DOWN_ICON: require('../../Assets/Images/server_down_icon.svg'),
	GO_BACK_ICON: require('../../Assets/Images/goBack.svg'),
	GETTY_ICON: require('../../Assets/Images/getty_icon.svg'),
	VIDEO_ICON: require('../../Assets/Images/video.svg'),
	SUBMIT_MESSAGE: require('../../Assets/Images/submit_message.svg'),
	COMP_BANNER_DRONE: require('../../Assets/Images/comp-banner-drone.svg'),
	CELEBRATION_ICON: require('../../Assets/Images/celebration_icon.svg'),
	COMP_BANNER_BG: require('../../Assets/Images/comp_banner_bg.svg'),
	FOOTAGE_ICON: require('../../Assets/Images/footage_icon.svg'),
	COPY_ORANGE_ICON: require('../../Assets/Images/copy_orange_icon.svg'),
	MY_PLANS_ICON: require('../../Assets/Images/my-plans-icon.svg'),
	DIAMOND_ICON: require('../../Assets/Images/diamond-icon.svg'),
	MP_EMPTY_LIST_ICON: require('../../Assets/Images/mp-empty-list-icon.svg'),
	MP_MOBILE_SIGNUP_DRONE: require('../../Assets/Images/mp-mobile-signup-drone.svg'),
	PHOTOS_HOME_ICON: require('../../Assets/Images/photos-home-icon.svg'),
	PHOTOS_VIDEOS_HOME_ICON: require('../../Assets/Images/photos-videos-home-icon.svg'),
	VIDEOS_HOME_ICON: require('../../Assets/Images/videos-home-icon.svg'),
	SIDEBAR_LOGO: require('../../Assets/Images/logo.svg'),
	FAILURE_ICON: require('../../Assets/Images/failure-icon.svg'),
	AEROPATH_PROJECT: require('../../Assets/Images/aeropath.svg'),
	HAPPY_FACE: require('../../Assets/Images/happy-face.svg'),
	MORNING_SLOT_ICON: require('../../Assets/Images/morning-slot-icon.svg'),
	MIDDAY_SLOT_ICON: require('../../Assets/Images/midday-slot-icon.svg'),
	AFTERNOON_SLOT_ICON: require('../../Assets/Images/afternoon-slot-icon.svg'),
	EVENING_SLOT_ICON: require('../../Assets/Images/evening-slot-icon.svg'),
	SIGNUP_SELECT_IMG: require('../../Assets/Images/signup-select-img.svg'),
	BACK_BTN: require('../../Assets/Images/back-btn.svg'),
	MORE_VERTICAL: require('../../Assets/Images/more-vertical.svg'),
	MODIFY_ICON: require('../../Assets/Images/modify-icon.svg'),
	CANCEL_PROJECT: require('../../Assets/Images/cancel-project-icon.svg'),
	FACEBOOK_NEW_ICON: require('../../Assets/Images/facebook-icon.svg'),
	GOOGLE_NEW_ICON: require('../../Assets/Images/google-icon.svg'),
	CURRENT_STEP_ICON: require('../../Assets/Images/current-step-icon.svg'),
	NEXT_STEP_DOT_ICON: require('../../Assets/Images/next-step-dot-icon.svg'),
	COMPLETED_STEP_ICON: require('../../Assets/Images/completed-step-icon.svg'),
	SUBMIT_BID_ICON: require('../../Assets/Images/submit-bid-icon.svg'),
	SUBMIT_BID_HEAD_ICON: require('../../Assets/Images/submit-bid-head-icon.svg'),
	REJECT_BID_ICON: require('../../Assets/Images/reject-bid-icon.svg'),
	ACCEPT_BID_ICON: require('../../Assets/Images/accept-bid-icon.svg'),
	CROSS_ICON: require('../../Assets/Images/cross.svg'),
	MAXIMIZE_ICON: require('../../Assets/Images/maximize.svg'),
	MINIMIZE_ICON: require('../../Assets/Images/minimize.svg'),
	NEW_BLUE_LOGO: require('../../Assets/Images/new-blue-logo.svg'),
	RED_DOT_ICON: require('../../Assets/Images/red-dot-icon.svg'),
	EMAIL_SUCCESS_ICON: require('../../Assets/Images/email-success-icon.svg'),
	BIG_CHECK_ICON: require('../../Assets/Images/big-check-icon.svg'),
	COMMERCIAL_PROJECT_SELECTED: require('../../Assets/Images/commercial_project_selected.svg'),
	ENTERPRISE_PROJECT_SELECTED: require('../../Assets/Images/enterprise_project_selected.svg'),
	FOOTAGE_SELECTED: require('../../Assets/Images/footage_selected.svg'),
	HELP_SELECTED: require('../../Assets/Images/help_selected.svg'),
	MY_PLANS_SELECTED: require('../../Assets/Images/my_plans_selected.svg'),
	PAYMENT_SELECTED: require('../../Assets/Images/payment_selected.svg'),
	PROFILE_SELECTED: require('../../Assets/Images/profile_selected.svg'),
	REFER_AND_EARN_SELECTED: require('../../Assets/Images/refer_and_earn_selected.svg'),
	SETTINGS_SELECTED: require('../../Assets/Images/setting_selected.svg'),
	UPGRADES_SELECTED: require('../../Assets/Images/upgrades_selected.svg'),
	COMMERCIAL_PROJECT: require('../../Assets/Images/commercial_project.svg'),
	ENTERPRISE_PROJECT: require('../../Assets/Images/enterprise_project.svg'),
	FOOTAGE: require('../../Assets/Images/footage.svg'),
	HELP: require('../../Assets/Images/help.svg'),
	MY_PLANS: require('../../Assets/Images/my_plans.svg'),
	REFER_AND_EARN: require('../../Assets/Images/refer_and_earn.svg'),
	SETTINGS: require('../../Assets/Images/settings_new.svg'),
	UPGRADES: require('../../Assets/Images/upgrades.svg'),
	PROFILE: require('../../Assets/Images/profile_new.svg'),
	PAYMENT: require('../../Assets/Images/payment_new.svg'),
	REJECT_INVITATION: require('../../Assets/Images/reject-invitation.svg'),
	ACCEPT_INVITATION: require('../../Assets/Images/accept-invitation.svg'),
	INFO_DRONE_SUPPORT_ICON: require('../../Assets/Images/info_drone_support_icon.svg'),
	SUBMITTED_BID_ICON: require('../../Assets/Images/submitted-bid-icon.svg'),
	PROMOTION_ICON: require('../../Assets/Images/promotion-icon.svg'),
	WITHDRAW_SMALL_ICON: require('../../Assets/Images/withdraw-small-icon.svg'),
	WITHDRAW_BIG_ICON: require('../../Assets/Images/withdraw-big-icon.svg'),
	CONTRACT_DOWNLOAD: require('../../Assets/Images/contract_download.svg'),
	CONTRACT_PRINT: require('../../Assets/Images/contract_print.svg'),
	ENTERPRISE_PILOT_PLUS_SUCCESS: require('../../Assets/Images/enterprise_pilot_plus-registration_success.svg'),
	ENTERPRISE_PLUS_BANNER_LOGO: require('../../Assets/Images/enterprise_plus_banner_logo.svg'),
	ENTERPRISE_PLUS_PROFILE_ICON: require('../../Assets/Images/enterprise_plus_profile_icon.svg'),
	ENTERPRISE_PLUS_HANDSHAKE: require('../../Assets/Images/enterprise_pilot_plus_handshake.svg'),
	MY_PROJECTS_SELECTED: require('../../Assets/Images/MY_PROJECTS_SELECTED.svg'),
	MY_PROJECTS: require('../../Assets/Images/MY_PROJECTS.svg'),
	INVITED_BY_AERO: require('../../Assets/Images/invited-by-aero.svg'),
	ALERT_CIRCLE: require('../../Assets/Images/alert-circle.svg'),
	NZ_QUIZ_ICON: require('../../Assets/Images/nz-quiz-icon.svg'),
	WRONG_QUIZ_ICON: require('../../Assets/Images/wrong-quiz-icon.svg'),
	BIG_RED_EXCLAMATION_ICON: require('../../Assets/Images/big-red-exclamation-icon.svg'),
	CONGRATS_ICON: require('../../Assets/Images/congrats-icon.svg'),
	BIG_INFO_ICON: require('../../Assets/Images/big-info-icon.svg'),
	COMING_SOON_HOME: require('../../Assets/Images/coming-soon-home.svg'),
	COMPETITION_ALERT: require('../../Assets/Images/competition-alert.svg'),
	COMP_ALERT_BELL_ICON: require('../../Assets/Images/comp-alert-bell-icon.svg'),
	COMING_SOON_ICON: require('../../Assets/Images/coming-soon-icon.svg'),
	ADD_MOBILE_ICON: require('../../Assets/Images/add-mobile-icon.svg'),
	DOLLAR_ICON: require('../../Assets/Images/dollar-icon.svg'),
	EMPTY_ICON: require('../../Assets/Images/empty.svg'),
	NZ_DOLLAR_ICON: require('../../Assets/Images/nz-dollar.svg'),
	PROFILE_DETAILS: require('../../Assets/Images/profile-details-screenshot.png'),
	ADD_COLLABORATOR: require('../../Assets/Images/add-collaborator-screenshot.png'),
	EDIT_PERMISSION: require('../../Assets/Images/edit-permission-screenshot.png'),
	EDIT_PERMISSION_OVERVIEW: require('../../Assets/Images/edit-permission-overview.png'),
	SUPPORT_THUMB_1: require('../../Assets/Images/support-thumb-1.jpg'),
	SUPPORT_THUMB_2: require('../../Assets/Images/support-thumb-2.jpg'),
	SUPPORT_THUMB_3: require('../../Assets/Images/support-thumb-3.jpg'),
	SUPPORT_THUMB_4: require('../../Assets/Images/support-thumb-4.jpg'),
	EMAIL_ICON : require('../../Assets/Images/email.svg'),
	EMAIL_AND_PHONE_ICON : require('../../Assets/Images/email_phone.svg'),
	PHOTO_FILLED: require('../../Assets/Images/photo-filled.svg')
}
