import React, { Component } from 'react'
import { Button, Upload, message, Tooltip, Spin } from 'antd'
import { UploadOutlined, PaperClipOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { uploadProjectDescription } from '../../Stores/Projects/action'
import { textShortner } from '../../Services/UtilityService'

class ProjectDescriptionUpload extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
		}
	}

	uploadDoc = options => {
		this.setState({ loading: true })
		let body = new FormData()
		let fileObj = options.file
		body.append('file', fileObj)
		body.append('type', 'PROJECT')
		this.props
			.uploadProjectDescription(body)
			.then(
				res => {
					if (res.value.status_code === 200) {
						this.props.storeFileData(res.value.data)
						this.setState({ loading: false })
						options.onSuccess({
							message: 'File uploaded',
						})
					} else {
						this.setState({ loading: false })
						options.onError({
							message: 'Unable to upload file.',
						})
					}
				},

				res => {
					this.setState({ loading: false })
					console.log('err block', res)
				},
			)
			.catch(res => {
				this.setState({ loading: false })
				console.log('catch block', res)
			})
	}

	beforeUpload = file => {
		let fileName = file.name
		fileName = fileName.slice(fileName.length - 3)

		const isJpgOrPngOrPdf =
			file.type === 'image/jpeg' ||
			file.type === 'image/png' ||
			file.type === 'application/pdf' ||
			file.type === 'application/msword' ||
			file.type === 'application/vnd.ms-excel' ||
			fileName.toLowerCase() === 'kml' ||
			fileName.toLowerCase() === 'kmz'
		if (!isJpgOrPngOrPdf) {
			message.error('You can only upload JPG/PNG/PDF/DOC/XLS/KML/KMZ file!')
		}
		const isLt2M = file.size / 1024 / 1024 <= 10
		if (!isLt2M) {
			message.error('File must not be more than 10 MB!')
		}
		return isLt2M && isJpgOrPngOrPdf
	}

	removeDoc = () => {
		this.props.storeFileData(null)
	}

	renderUpload = type => {
		return (
			<Upload showUploadList={false} accepts='.jpg, .png, .doc, .pdf, .xls, .kml, .kmz' name='file' customRequest={this.uploadDoc} beforeUpload={this.beforeUpload}>
				{type !== 'edit' ? (
					<>
						<Button type='link'>
							<UploadOutlined style={{ position: 'relative', right: '12%', bottom: '10%' }} /> <span style={{ position: 'relative', right: '12%' }}>Upload file</span>
						</Button>
						<span style={{ color: '#595959' }}>(Optional)</span>
					</>
				) : (
					<Button type='link'>
						<EditOutlined style={{ color: '#096DD9', position: 'relative', bottom: '3px' }} />
					</Button>
				)}
			</Upload>
		)
	}

	render() {
		const { fileData } = this.props
		const { loading } = this.state
		return (
			<div className='upload-part'>
				<Spin spinning={loading}>
					{fileData ? (
						<>
							<PaperClipOutlined style={{ position: 'relative', bottom: '3px', color: 'grey' }} />{' '}
							{fileData.fileName && (
								<Tooltip placement='top' title={fileData.fileName}>
									<Button type='link' href={fileData.url} target='_blank' rel='noopener noreferrer'>
										{textShortner(fileData.fileName, 10)}
									</Button>
								</Tooltip>
							)}
							<div>
								{this.renderUpload('edit')}
								<Button onClick={this.removeDoc} type='link'>
									<CloseOutlined style={{ color: '#F5222D', position: 'relative', bottom: '3px' }} />
								</Button>
							</div>
						</>
					) : (
						<Tooltip placement='top' title='.jpg, .png, .doc, .pdf, .xls, .kml, .kmz (Upto 10 MB)'>
							{this.renderUpload('upload')}
						</Tooltip>
					)}
				</Spin>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		uploadProjectDescriptionSuccess: state.PaymentsReducer.uploadProjectDescriptionSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		uploadProjectDescription: bindActionCreators(uploadProjectDescription, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProjectDescriptionUpload)
