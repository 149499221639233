import React from 'react'
import styles from './Details.module.scss'
function Details(props) {
	// extract props
	const {
		setShowDetailsModal, // from parent
	} = props

	return (
		<>
			<div className={styles['details-card-body']}>
				<div>
					<div className={styles['details-card-content-bold']}>You can currently submit only Editorial Videos to the Getty Images platform</div>
					<div className={styles['details-card-content']}>
						We are working to bring the ability to submit stock images to the Getty Images platform. Once listed on platform, you will receive a Royalty on every sale
						made.{' '}
					</div>

					<br></br>
					<input
						type='button'
						value='What can I upload?'
						onClick={() => {
							setShowDetailsModal(true)
						}}
					></input>
				</div>
			</div>
		</>
	)
}

export default Details
