import React from 'react'
import { NavLink } from 'react-router-dom'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { pushEventToMixpanel } from '../../Services/MixpanelService'

const MobileBarItem = props => {
	const { menu, handleHamburger, profileIncomplete, paymentIncomplete, currentPathName, profileType } = props
	return (
		<NavLink
			key={menu.pathName}
			style={{ display: 'flex' }}
			to={menu.pathName}
			onClick={() => {
				pushEventToMixpanel('', `${menu.displayValue} Option Clicked`)
			}}
		>
			<div onClick={handleHamburger} className={menu.pathName === currentPathName ? 'mobile-sidebar-menu-selected' : 'mobile-sidebar-menu'}>
				<MatImage
					style={{ position: 'relative', bottom: '3px' }}
					src={IMAGES_SRC[menu.pathName === currentPathName ? `${menu.icon}_SELECTED` : menu.icon]}
					alt='menu-icon'
				/>
				<span style={{ marginLeft: '15px' }}>
					{menu.displayValue}
					{(menu.key === 'PROFILE' && profileIncomplete && profileType === 'PILOT') || (menu.key === 'PAYMENT' && paymentIncomplete) ? (
						<span style={{ position: 'relative', top: '-10px', right: '1px' }}>
							<MatImage src={IMAGES_SRC.INCOMPLETE_ICON} />
						</span>
					) : null}
				</span>
			</div>
		</NavLink>
	)
}

export default MobileBarItem
