import typeToReducer from 'type-to-reducer'
import { message } from 'antd'

const initialState = {
	signUpSuccess: false,
	checkReferralCodeSuccess: false,
}

const OnBoardingReducer = typeToReducer(
	{
		SIGN_UP: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						signUpSuccess: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Unable to signup!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		CHECK_REFERRAL_CODE: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Referral code applied successfully!')
					return Object.assign({}, state, {
						checkReferralCodeSuccess: true,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Please enter a valid code!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
	},
	initialState,
)

export default OnBoardingReducer
