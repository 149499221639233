import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './enterpriseProfile.sass'
import { PlusOutlined, EditOutlined, CheckCircleFilled } from '@ant-design/icons'
import { Card, Button, Table, Empty, Input, Divider, Radio, Select, message, Row } from 'antd'
import { getAllCollaborators } from '../../Stores/Projects/action'
import {
	getProfile,
	updateProfile,
	updateEmail,
	changePassword,
	addCollaborator,
	getOrdersNotOfCollaborator,
	deleteCollaborator,
	updateCollaboratorPermissions,
} from '../../Stores/UserSession/action'
import { collaboratorsCols, permissionCols } from './table-cols'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import AeroModal from '../../AeroComponents/Modal/index'
import { Form } from '@ant-design/compatible'
import MobileNumberVerification from '../Profile/Components/mobile-number-verification'
import ProfilePicture from '../Profile/Components/profile-picture'
import ChangePassword from '../Settings/Components/change-password'
import { getValueFromStorage } from '../../Services/LocalStorageService'

const { Option } = Select
class EnterpriseProfileForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			collaboratorsData: [],
			collaboratorsCols: collaboratorsCols(this),
			permissionCols: permissionCols(this),
			profileData: {},
			nameModal: false,
			emailModal: false,
			emailSuccessModal: false,
			mobileNumberModal: false,
			profilePictureModal: false,
			passwordModal: false,
			addCollaboratorModal: false,
			deleteCollaboratorModal: false,
			editPermissionModal: false,
			ordersNotOfCollaborator: [],
			selectedCollaborator: {},
			permissionTableData: [],
			selectedPermissionTab: 'orders',
			transactionsPermission: '',
			overviewPermission: '',
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.collaboratorsData !== nextProps.collaboratorsData) {
			this.setTableData(nextProps.collaboratorsData)
		}
		if (this.props.profileData !== nextProps.profileData) {
			this.setState({ profileData: nextProps.profileData })
		}
		if (this.props.updateProfileSuccess !== nextProps.updateProfileSuccess) {
			if (nextProps.updateProfileSuccess) {
				this.setState({
					profileData: nextProps.updateProfileSuccess,
					nameModal: false,
					mobileNumberModal: false,
					profilePictureModal: false,
				})
			}
		}
		if (this.props.updateEmailSuccess !== nextProps.updateEmailSuccess) {
			if (nextProps.updateEmailSuccess) {
				this.setState({
					emailModal: false,
					emailSuccessModal: true,
				})
			}
		}
		if (this.props.changePasswordSuccess !== nextProps.changePasswordSuccess) {
			if (nextProps.changePasswordSuccess) {
				this.setState({
					passwordModal: false,
				})
			}
		}
		if (this.props.addCollaboratorSuccess !== nextProps.addCollaboratorSuccess) {
			if (nextProps.addCollaboratorSuccess) {
				this.setState({
					addCollaboratorModal: false,
				})
				this.props.getAllCollaborators()
			}
		}
		if (this.props.ordersNotOfCollaborator !== nextProps.ordersNotOfCollaborator) {
			if (nextProps.ordersNotOfCollaborator) {
				this.setState({
					ordersNotOfCollaborator: nextProps.ordersNotOfCollaborator,
				})
			}
		}
		if (this.props.deleteCollaboratorSuccess !== nextProps.deleteCollaboratorSuccess) {
			if (nextProps.deleteCollaboratorSuccess) {
				this.setState({
					deleteCollaboratorModal: false,
				})
				this.props.getAllCollaborators()
			}
		}
	}

	componentDidMount() {
		if (getValueFromStorage('SUBROLE') !== 'CLIENT_COLLABORATOR') {
			this.props.getAllCollaborators()
		}

		this.props.getProfile()
	}

	setTableData = resp => {
		for (let index = 0; index < resp.length; index++) {
			let perms = []
			for (let j = 0; j < resp[index].permissions.length; j++) {
				if (resp[index].permissions[j].permissionValue !== '0' && !perms.includes(resp[index].permissions[j].permissionType)) {
					perms.push(resp[index].permissions[j].permissionType)
				}
			}

			resp[index]['permissions_string'] = perms.join(', ')
		}
		this.setState({
			collaboratorsData: resp,
			editPermissionModal: false,
		})
	}

	toggleNameModal = () => {
		const nameModal = this.state.nameModal
		this.setState({ nameModal: !nameModal })
	}

	handleNameSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err || 'verificationCode' in err) {
				this.props.form.resetFields()
				const data = {
					firstName: values.firstName,
					middleName: values.middleName ? values.middleName : '',
					lastName: values.lastName,
				}

				this.props.updateProfile(data)
			}
		})
	}

	toggleEmailModal = () => {
		const emailModal = this.state.emailModal
		this.setState({ emailModal: !emailModal })
	}

	handleEmailSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err || 'verificationCode' in err) {
				this.props.form.resetFields()
				const data = {
					newEmail: values.email,
				}

				this.props.updateEmail(data)
			}
		})
	}

	toggleEmailSuccessModal = () => {
		const emailSuccessModal = this.state.emailSuccessModal
		this.setState({ emailSuccessModal: !emailSuccessModal })
	}

	submitOtp = data => {
		this.props.updateProfile(data)
	}

	toggleMobileNumberModal = () => {
		const mobileNumberModal = this.state.mobileNumberModal
		this.setState({ mobileNumberModal: !mobileNumberModal })
	}

	toggleProfilePictureModal = () => {
		const profilePictureModal = this.state.profilePictureModal
		this.setState({ profilePictureModal: !profilePictureModal })
	}

	uploadProfilePicture = data => {
		this.props.updateProfile(data)
	}

	togglePasswordModal = () => {
		const passwordModal = this.state.passwordModal
		this.setState({ passwordModal: !passwordModal })
	}

	updatePassword = data => {
		this.props.changePassword(data)
	}

	toggleAddCollaboratorModal = () => {
		const addCollaboratorModal = this.state.addCollaboratorModal
		this.setState({ addCollaboratorModal: !addCollaboratorModal })
	}

	handleAddCollaboratorSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err || 'verificationCode' in err) {
				this.props.form.resetFields()
				const data = {
					firstName: values.collaboratorFirstName,
					lastName: values.collaboratorLastName,
					email: values.collaboratorEmail,
					password: values.collaboratorPassword,
					confirmPassword: values.collaboratorConfirmPassword,
				}

				this.props.addCollaborator(data)
			}
		})
	}

	compareToFirstPassword = (rule, value, callback) => {
		const { form } = this.props
		if (value && value !== form.getFieldValue('collaboratorPassword')) {
			callback('Two passwords that you enter is inconsistent!')
		} else {
			callback()
		}
	}

	validateToNextPassword = (rule, value, callback) => {
		const { form } = this.props

		if (value && this.state.confirmDirty) {
			form.validateFields(['collaboratorConfirmPassword'], { force: true })
		}

		const regForPass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/
		if (value && value.match(regForPass)) {
			callback()
		} else {
			callback('Password should be of minimum 6 characters, it must have at least one special character and at least one number!')
		}
	}

	openDeleteCollaboratorModal = data => {
		this.setState({ deleteCollaboratorModal: true, selectedCollaborator: data })
	}

	closeDeleteCollaboratorModal = () => {
		this.setState({ deleteCollaboratorModal: false })
	}

	openEditPermissionModal = data => {
		this.props.getOrdersNotOfCollaborator({ collaboratorId: data.consumerId })
		this.setPermissionTableData(data.permissions)
		this.setState({ editPermissionModal: true, selectedCollaborator: data })
	}

	closeEditPermissionModal = () => {
		this.setState({ editPermissionModal: false })
	}

	handleSelectChange = values => {
		let permissionTableData = this.state.permissionTableData
		let ordersNotOfCollaborator = this.state.ordersNotOfCollaborator

		for (let index = 0; index < ordersNotOfCollaborator.length; index++) {
			if (values[values.length - 1] === ordersNotOfCollaborator[index].id) {
				permissionTableData.push({
					name: ordersNotOfCollaborator[index].name,
					addedOn: ordersNotOfCollaborator[index].startDate,
					id: values[values.length - 1],
				})
				ordersNotOfCollaborator.splice(index, 1)
			}
		}
		let unique = permissionTableData.filter((item, i, ar) => ar.indexOf(item) === i)
		this.setState({ permissionTableData: unique, ordersNotOfCollaborator: ordersNotOfCollaborator })
	}

	setPermissionTableData = data => {
		let permissionTableData = []
		let overviewPermission = 'no'
		let transactionsPermission = 'no'
		for (let index = 0; index < data.length; index++) {
			if (data[index].permissionType === 'ORDER') {
				permissionTableData.push({
					name: data[index].order.name,
					addedOn: data[index].order.startDate,
					id: data[index].permissionValue,
				})
			} else if (data[index].permissionType === 'OVERVIEW') {
				if (data[index].permissionValue === '1') {
					overviewPermission = 'yes'
				}
			} else if (data[index].permissionType === 'TRANSACTIONS') {
				if (data[index].permissionValue === '1') {
					transactionsPermission = 'yes'
				}
			}
		}
		this.setState({ permissionTableData: permissionTableData, overviewPermission: overviewPermission, transactionsPermission: transactionsPermission })
	}

	onTabChange = e => {
		this.setState({ selectedPermissionTab: e.target.value })
	}

	onOveriviewTabChange = e => {
		this.setState({ overviewPermission: e.target.value })
	}

	onTransactionsTabChange = e => {
		this.setState({ transactionsPermission: e.target.value })
	}

	deleteCollaboratorCaller = () => {
		this.props.deleteCollaborator({ collaboratorId: this.state.selectedCollaborator.consumerId })
	}

	updateCollaboratorPermissionCaller = () => {
		let permissions = []
		const permissionTableData = this.state.permissionTableData
		permissions.push({
			permissionType: 'OVERVIEW',
			permissionValue: this.state.overviewPermission === 'yes' ? '1' : '0',
		})
		permissions.push({
			permissionType: 'TRANSACTIONS',
			permissionValue: this.state.transactionsPermission === 'yes' ? '1' : '0',
		})
		for (let index = 0; index < permissionTableData.length; index++) {
			permissions.push({
				permissionType: 'ORDER',
				permissionValue: permissionTableData[index].id,
			})
		}
		let body = {
			consumerId: this.state.selectedCollaborator.consumerId,
			permissions: permissions,
		}

		this.props.updateCollaboratorPermissions(body).then(res => {
			message.success('Please ask the collaborator to re-login to their account for the changes to take effect')
			this.props.getAllCollaborators()
		})
	}

	removePermission = id => {
		let permissionTableData = this.state.permissionTableData
		for (let index = 0; index < permissionTableData.length; index++) {
			if (id === permissionTableData[index].id) {
				permissionTableData.splice(index, 1)
			}
		}
		let unique = permissionTableData.filter((item, i, ar) => ar.indexOf(item) === i)
		this.setState({ permissionTableData: unique })
	}

	render() {
		const {
			collaboratorsData,
			collaboratorsCols,
			profileData,
			nameModal,
			emailModal,
			emailSuccessModal,
			mobileNumberModal,
			profilePictureModal,
			passwordModal,
			addCollaboratorModal,
			deleteCollaboratorModal,
			editPermissionModal,
			permissionCols,
			ordersNotOfCollaborator,
			selectedCollaborator,
			permissionTableData,
			selectedPermissionTab,
			transactionsPermission,
			overviewPermission,
		} = this.state
		const { getFieldDecorator } = this.props.form
		return (
			<div className='page-container'>
				<Row>
					<Card className='en-profile-top-card' title='Basic Details'>
						<div className='en-profile-picture-wrap'>
							{profileData && profileData['profilePicture'] ? (
								<div style={{ minWidth: '115px', minHeight: '115px' }} className='circular'>
									<MatImage style={{ borderRadius: '50%', maxWidth: '115px' }} src={profileData['profilePicture']} />
								</div>
							) : (
								<MatImage style={{ width: '115px', height: '115px' }} src={IMAGES_SRC.DP_PLACEHOLDER} />
							)}

							<div className='en-profile-pic-edit-wrap'>
								<Button onClick={this.toggleProfilePictureModal} type='link'>
									<EditOutlined style={{ position: 'relative', right: '10px', bottom: '5px', color: 'white' }} />
								</Button>
							</div>
						</div>
						<div className='en-profile-details-wrap'>
							<div className='en-profile-details-content'>
								<span>
									<strong>Name: </strong> {profileData.firstName + ' ' + profileData.middleName + ' ' + profileData.lastName}
								</span>
								<span>
									<Button onClick={this.toggleNameModal} style={{ float: 'right' }} type='link'>
										<EditOutlined className='simple-icon-placement' />
										Edit
									</Button>
								</span>
							</div>
							<div className='en-profile-details-content'>
								<span>
									<strong>Email: </strong> {profileData.email}
								</span>
								<span>
									{/* <Button onClick={this.toggleEmailModal} style={{ float: 'right' }} type='link'>
										<EditOutlined className='simple-icon-placement' />
										Edit
									</Button> */}
								</span>
							</div>
							<div className='en-profile-details-content'>
								<span>
									<strong>Phone Number: </strong> {profileData.mobileNumber}
								</span>
								<span>
									<Button onClick={this.toggleMobileNumberModal} style={{ float: 'right' }} type='link'>
										<EditOutlined className='simple-icon-placement' />
										Edit
									</Button>
								</span>
							</div>
						</div>
					</Card>
					<Card className='en-profile-top-card' title='Security Details'>
						<Button onClick={this.togglePasswordModal} type='primary' style={{ width: '237px', borderRadius: '8px' }}>
							Change Password
						</Button>
					</Card>
				</Row>
				{getValueFromStorage('SUBROLE') === 'CLIENT_COLLABORATOR' ? null : (
					<Row>
						<Card
							className='en-profile-bottom-card'
							title={
								<div>
									Invite Collaborators
									<div style={{ fontSize: '14px', color: '#595959', fontWeight: '400' }}>
										Invite colleagues from within your organization to collaborate on Orders and Projects
									</div>
								</div>
							}
							extra={
								<Button onClick={this.toggleAddCollaboratorModal} style={{ borderRadius: '8px' }} type='primary'>
									<PlusOutlined className='simple-icon-placement' /> Add Collaborator
								</Button>
							}
						>
							<Table
								locale={{
									emptyText: (
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											description={
												<div style={{ fontSize: '20px' }}>
													No collaborator has been added yet<div style={{ fontSize: '16px' }}>Click on 'Add Collaborator' to add one</div>
												</div>
											}
										></Empty>
									),
								}}
								dataSource={collaboratorsData}
								columns={collaboratorsCols}
							></Table>
						</Card>
					</Row>
				)}
				<AeroModal title='Edit Name' visible={nameModal} onCancel={this.toggleNameModal} footer={false} bodyStyle={{ background: '#F5F5F5' }}>
					<Form onSubmit={this.handleNameSubmit} layout='vertical'>
						<Form.Item label='First Name'>
							{getFieldDecorator('firstName', { rules: [{ required: nameModal, message: 'First name is required!' }], initialValue: profileData.firstName })(
								<Input style={{ borderRadius: '8px' }}></Input>,
							)}
						</Form.Item>
						<Form.Item label='Middle Name'>
							{getFieldDecorator('middleName', { initialValue: profileData.middleName ? profileData.middleName : '' })(
								<Input style={{ borderRadius: '8px' }}></Input>,
							)}
						</Form.Item>
						<Form.Item label='Last Name'>
							{getFieldDecorator('lastName', { rules: [{ required: nameModal, message: 'Last name is required!' }], initialValue: profileData.lastName })(
								<Input style={{ borderRadius: '8px' }}></Input>,
							)}
						</Form.Item>
						<Form.Item>
							<Button style={{ borderRadius: '8px', height: '50px' }} type='primary' block htmlType='submit'>
								Save Changes
							</Button>
						</Form.Item>
					</Form>
				</AeroModal>
				<AeroModal title='Edit Email ID' visible={emailModal} onCancel={this.toggleEmailModal} footer={false} bodyStyle={{ background: '#F5F5F5' }}>
					<Form onSubmit={this.handleEmailSubmit} layout='vertical'>
						<Form.Item label='Email'>
							{getFieldDecorator('email', {
								rules: [{ required: emailModal, message: 'A valid Email is required!', type: 'email' }],
								initialValue: profileData.email,
							})(<Input style={{ borderRadius: '8px' }}></Input>)}
						</Form.Item>
						<Form.Item>
							<Button style={{ borderRadius: '8px', height: '50px' }} type='primary' block htmlType='submit'>
								Send Verification Link
							</Button>
						</Form.Item>
					</Form>
				</AeroModal>
				<AeroModal visible={emailSuccessModal} onCancel={this.toggleEmailSuccessModal} footer={false}>
					<div style={{ textAlign: 'center' }}>
						<MatImage src={IMAGES_SRC.EMAIL_SENT_ICON} />
						<div style={{ fontSize: '24px', paddingTop: '20px' }}>
							<CheckCircleFilled style={{ color: '#36C626', position: 'relative', bottom: '5px' }} /> &nbsp;Email sent successfully!
						</div>
						<div style={{ width: '160px', marginLeft: '150px', color: '#595959' }}>
							<Divider></Divider>
						</div>

						<div style={{ fontSize: '16px', color: '#595959' }}>Please check your mailbox to proceed</div>
						<div style={{ color: '#595959' }}>
							<i>Verification link will expire in 48 hours</i>
						</div>
					</div>
				</AeroModal>
				<AeroModal title='Edit Phone Number' bodyStyle={{ background: '#F5F5F5' }} footer={false} visible={mobileNumberModal} onCancel={this.toggleMobileNumberModal}>
					<MobileNumberVerification
						filedRequired={mobileNumberModal}
						submitOtp={this.submitOtp}
						{...this.props}
						initialNumber={profileData.mobileNumber ? profileData.mobileNumber : null}
					/>
				</AeroModal>
				<AeroModal
					title='Edit Profile Picture'
					bodyStyle={{ background: '#F5F5F5' }}
					footer={false}
					visible={profilePictureModal}
					onCancel={this.toggleProfilePictureModal}
				>
					<ProfilePicture
						viewMode={profileData && profileData['profilePicture'] ? true : false}
						updateProfilePicture={this.uploadProfilePicture}
						data={profileData && profileData['profilePicture'] ? profileData : ''}
					/>
				</AeroModal>
				<AeroModal title='Change password' bodyStyle={{ background: '#F5F5F5' }} footer={false} visible={passwordModal} onCancel={this.togglePasswordModal}>
					<ChangePassword updatePassword={this.updatePassword} />
				</AeroModal>
				<AeroModal title='Add Collaborator' visible={addCollaboratorModal} onCancel={this.toggleAddCollaboratorModal} footer={false} bodyStyle={{ background: '#F5F5F5' }}>
					<Form onSubmit={this.handleAddCollaboratorSubmit} layout='vertical'>
						<Form.Item label='First Name'>
							{getFieldDecorator('collaboratorFirstName', { rules: [{ required: addCollaboratorModal, message: 'First name is required!' }] })(
								<Input style={{ borderRadius: '8px' }}></Input>,
							)}
						</Form.Item>
						<Form.Item label='Last Name'>
							{getFieldDecorator('collaboratorLastName', { rules: [{ required: addCollaboratorModal, message: 'Last name is required!' }] })(
								<Input style={{ borderRadius: '8px' }}></Input>,
							)}
						</Form.Item>
						<Form.Item label="Collaborator's Email Id">
							{getFieldDecorator('collaboratorEmail', { rules: [{ required: addCollaboratorModal, message: 'A valid Email name is required!' }], type: 'email' })(
								<Input style={{ borderRadius: '8px' }}></Input>,
							)}
						</Form.Item>
						<Form.Item label='Password'>
							{getFieldDecorator('collaboratorPassword', {
								rules: [
									{ required: addCollaboratorModal, message: 'Password is required!' },
									{
										validator: addCollaboratorModal ? this.validateToNextPassword : '',
									},
								],
							})(<Input.Password style={{ borderRadius: '8px' }}></Input.Password>)}
						</Form.Item>
						<Form.Item label='Confirm Password'>
							{getFieldDecorator('collaboratorConfirmPassword', {
								rules: [
									{ required: addCollaboratorModal, message: 'Please confirm your password!' },
									{
										validator: addCollaboratorModal ? this.compareToFirstPassword : '',
									},
								],
							})(<Input.Password style={{ borderRadius: '8px' }}></Input.Password>)}
						</Form.Item>
						<Form.Item>
							<Button style={{ borderRadius: '8px', height: '50px' }} type='primary' block htmlType='submit'>
								Add
							</Button>
						</Form.Item>
					</Form>
				</AeroModal>
				<AeroModal footer={false} visible={deleteCollaboratorModal} onCancel={this.closeDeleteCollaboratorModal}>
					<div style={{ textAlign: 'center', padding: '20px' }}>
						<MatImage src={IMAGES_SRC.BIN_ICON} />
						<div style={{ fontSize: '24px', paddingTop: '20px' }}>
							<strong>{selectedCollaborator.firstName + ' ' + selectedCollaborator.lastName}</strong> will be removed as a collaborator
						</div>

						<div style={{ fontSize: '16px', color: '#595959' }}>Deleting a collaborator will also delete all saved permissions</div>
						<div style={{ paddingTop: '20px' }}>
							<Button onClick={this.closeDeleteCollaboratorModal} style={{ width: '171px', borderRadius: '8px' }} type='primary'>
								Cancel
							</Button>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<Button onClick={this.deleteCollaboratorCaller} style={{ width: '171px', borderRadius: '8px' }}>
								Remove
							</Button>
						</div>
					</div>
				</AeroModal>
				<AeroModal
					title='Edit Permissions'
					bodyStyle={{ background: '#F5F5F5' }}
					footer={false}
					visible={editPermissionModal}
					onCancel={this.closeEditPermissionModal}
					width={700}
				>
					<Radio.Group defaultValue='orders' buttonStyle='solid' onChange={this.onTabChange} style={{ marginBottom: 16 }}>
						<Radio.Button style={{ borderRadius: '8px 0px 0px 8px' }} value='orders'>
							Orders
						</Radio.Button>
						<Radio.Button value='overview'>Overview</Radio.Button>
						<Radio.Button style={{ borderRadius: '0px 8px 8px 0px' }} value='transactions'>
							Transactions
						</Radio.Button>
					</Radio.Group>
					{selectedPermissionTab === 'orders' ? (
						<React.Fragment>
							<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
								<span style={{ color: '#595959' }}>
									Add <strong>{selectedCollaborator.firstName + ' ' + selectedCollaborator.lastName}</strong> to order:
								</span>
								<span style={{ float: 'right' }}>
									<Select
										mode='multiple'
										allowClear
										showArrow={true}
										placeholder='Select orders'
										value={[]}
										onChange={this.handleSelectChange}
										style={{ width: '256px', height: '32px', borderRadius: '4px' }}
									>
										{ordersNotOfCollaborator.map((data, index) => (
											<Option key={index} value={data.id}>
												{data.name}
											</Option>
										))}
									</Select>
								</span>
							</div>
							<Table columns={permissionCols} dataSource={permissionTableData} pagination={false}></Table>
						</React.Fragment>
					) : null}

					{selectedPermissionTab === 'overview' ? (
						<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
							<span>All Projects Overview?</span>
							<span style={{ float: 'right' }}>
								<Radio.Group defaultValue='yes' buttonStyle='solid' value={overviewPermission} onChange={this.onOveriviewTabChange}>
									<Radio.Button style={{ borderRadius: '8px 0px 0px 8px', width: '116px', textAlign: 'center' }} value='yes'>
										Yes
									</Radio.Button>
									<Radio.Button style={{ borderRadius: '0px 8px 8px 0px', width: '116px', textAlign: 'center' }} value='no'>
										No
									</Radio.Button>
								</Radio.Group>
							</span>
						</div>
					) : null}
					{selectedPermissionTab === 'transactions' ? (
						<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
							<span>All Order Transactions?</span>
							<span style={{ float: 'right' }}>
								<Radio.Group defaultValue='yes' buttonStyle='solid' value={transactionsPermission} onChange={this.onTransactionsTabChange}>
									<Radio.Button style={{ borderRadius: '8px 0px 0px 8px', width: '116px', textAlign: 'center' }} value='yes'>
										Yes
									</Radio.Button>
									<Radio.Button style={{ borderRadius: '0px 8px 8px 0px', width: '116px', textAlign: 'center' }} value='no'>
										No
									</Radio.Button>
								</Radio.Group>
							</span>
						</div>
					) : null}
					<div style={{ padding: '20px', textAlign: 'center' }}>
						<Button onClick={this.closeEditPermissionModal} style={{ width: '304px', height: '50px', borderRadius: '8px', position: 'relative', right: '20px' }}>
							Cancel
						</Button>

						<Button
							onClick={this.updateCollaboratorPermissionCaller}
							type='primary'
							style={{ width: '304px', height: '50px', borderRadius: '8px', position: 'relative', left: '20px' }}
						>
							Save Changes
						</Button>
					</div>
				</AeroModal>
			</div>
		)
	}
}

const EnterpriseProfile = Form.create({ name: 'ENTERPRISE_PROFILE_FORM' })(EnterpriseProfileForm)

const mapStateToProps = state => {
	return {
		collaboratorsData: state.ProjectsReducer.collaboratorsData,
		profileData: state.UserSessionReducer.profileData,
		updateProfileSuccess: state.UserSessionReducer.updateProfileSuccess,
		updateEmailSuccess: state.UserSessionReducer.updateEmailSuccess,
		changePasswordSuccess: state.UserSessionReducer.changePasswordSuccess,
		addCollaboratorSuccess: state.UserSessionReducer.addCollaboratorSuccess,
		ordersNotOfCollaborator: state.UserSessionReducer.ordersNotOfCollaborator,
		deleteCollaboratorSuccess: state.UserSessionReducer.deleteCollaboratorSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAllCollaborators: bindActionCreators(getAllCollaborators, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
		updateProfile: bindActionCreators(updateProfile, dispatch),
		updateEmail: bindActionCreators(updateEmail, dispatch),
		changePassword: bindActionCreators(changePassword, dispatch),
		addCollaborator: bindActionCreators(addCollaborator, dispatch),
		getOrdersNotOfCollaborator: bindActionCreators(getOrdersNotOfCollaborator, dispatch),
		deleteCollaborator: bindActionCreators(deleteCollaborator, dispatch),
		updateCollaboratorPermissions: bindActionCreators(updateCollaboratorPermissions, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(EnterpriseProfile)
