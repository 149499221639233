import { PoweroffOutlined } from '@ant-design/icons'
import { Button, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import { BTN_LOG_OUT, SIDE_MENU_LIST } from './side-bar-menu-constants'

const WebSidebar = props => {
	const { permissions, collaboratorMenuList, subrole, profileType, modules, profileIncomplete, paymentIncomplete, logOut } = props
	const generalModules = SIDE_MENU_LIST[profileType].filter(menu => modules.includes(menu.key))
	const itemMapper = permissions ? collaboratorMenuList : subrole && subrole in SIDE_MENU_LIST ? SIDE_MENU_LIST[subrole] : generalModules
	const keyFinder = itemMapper.filter(item => item.pathName === window.location.pathname)
	const [currentPathName, setCurrentPathName] = useState(keyFinder.length ? keyFinder[0].key : 'HOME')
	useEffect(() => {
		if (keyFinder.length && currentPathName !== keyFinder[0].key) {
			setCurrentPathName(keyFinder.length ? keyFinder[0].key : 'HOME')
		}
	}, [window.location.pathname])
	const changeCurrentPath = e => {
		setCurrentPathName(e.key)
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'column', height: 'inherit', alignItems: 'center' }}>
			<MatImage src={IMAGES_SRC.SIDEBAR_LOGO} height='53' width='170' />
			<Menu onClick={changeCurrentPath} selectedKeys={[currentPathName]} style={{ width: '201px' }} mode='inline' theme='light'>
				{itemMapper.map(menu => (
					<Menu.Item key={menu.key}>
						<NavLink
							style={{ display: 'flex' }}
							to={menu.pathName}
							onClick={() => {
								pushEventToMixpanel('', `${menu.displayValue} Option Clicked`)
							}}
						>
							<MatImage src={IMAGES_SRC[menu.key === currentPathName ? `${menu.icon}_SELECTED` : menu.icon]} />
							<span style={{ display: 'inline-block', marginLeft: menu.key === 'MY_PLANS' ? '13px' : '10px', fontSize: '16px' }}>
								{menu.displayValue}
								{(menu.key === 'PROFILE' && profileIncomplete && profileType === 'PILOT') || (menu.key === 'PAYMENT' && paymentIncomplete) ? (
									<span style={{ position: 'relative', top: '-10px', right: '1px' }}>
										<MatImage src={IMAGES_SRC.INCOMPLETE_ICON} />
									</span>
								) : null}
							</span>
						</NavLink>
					</Menu.Item>
				))}
			</Menu>
			<div style={{ position: 'fixed', bottom: '7%' }}>
				<Button
					type='link'
					target='_blank'
					rel='noopener noreferrer'
					href={`https://aerologix.com/${profileType === 'PILOT' ? 'terms' : 'client-terms'}`}
					style={{ color: '#84818A', fontSize: '10px' }}
				>
					Terms and Conditions
				</Button>
			</div>
			<div style={{ position: 'fixed', bottom: '1%' }}>
				<Button
					block
					onClick={() => logOut()}
					type='primary'
					style={{ background: '#FFFFFF', border: '2px solid #00B3FF', borderRadius: '12px', width: '150px', minHeight: '48px' }}
				>
					<PoweroffOutlined style={{ color: '#1890ff', fontSize: '16px', position: 'relative', bottom: '4px', right: '5px' }} />
					<span style={{ marginLeft: '5px', color: '#1890ff', fontSize: '16px' }}>{BTN_LOG_OUT}</span>
				</Button>
			</div>
		</div>
	)
}

export default WebSidebar
