/*eslint-disable*/
import React from 'react'
import { Button, Divider, Switch, Card, Modal } from 'antd'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'

class AccountCard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			currentId: '',
		}
	}

	showModal = id => {
		this.setState({
			visible: true,
			currentId: id,
		})
	}

	handleCancel = () => {
		this.setState({ visible: false })
	}

	handleOk = () => {
		this.setState({
			visible: false,
		})
		this.deleteAccount(this.state.currentId)
	}

	makeDefault = accountId => {
		this.props.makeDefault(accountId)
	}

	deleteAccount = accountId => {
		this.props.deleteAccount(accountId)
	}

	render() {
		const { visible } = this.state
		const data = this.props.accountDetails
		return (
			<React.Fragment>
				<Card size='small' className='account-card'>
					<p>
						<strong>{data.maskedAccountNumber}</strong>
						{data.verified ? (
							''
						) : (
							<span style={{ float: 'right', color: '#8C8C8C', fontSize: '12px' }}>
								<MatImage src={IMAGES_SRC.CLOCK_ICON} /> Verification Pending
							</span>
						)}
					</p>
					<p>
						{data.accountHolderName}
						{data.primary ? (
							''
						) : (
							<span style={{ float: 'right', marginBottom: '15px' }}>
								<Button
									onClick={() => {
										this.showModal(data.id)
									}}
									type='danger'
								>
									Delete
								</Button>
							</span>
						)}
					</p>
					<Divider></Divider>
					{data.primary ? (
						<strong>Default Account</strong>
					) : (
						<p>
							Make this default
							<span style={{ float: 'right' }}>
								<Switch
									disabled={!data.verified}
									onChange={() => {
										this.makeDefault(data.id)
									}}
								></Switch>
							</span>
						</p>
					)}
				</Card>

				<Modal
					visible={visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={
						<div style={{ textAlign: 'center' }}>
							<Button key='submit' type='primary' onClick={this.handleOk}>
								Delete
							</Button>
							&nbsp; &nbsp; &nbsp;
							<Button key='back' onClick={this.handleCancel}>
								Cancel
							</Button>
						</div>
					}
				>
					<div style={{ textAlign: 'center' }}>
						<MatImage src={IMAGES_SRC.BIN_ICON} />
						<div>
							<h2>Delete Bank Account?</h2>
						</div>
					</div>
				</Modal>
			</React.Fragment>
		)
	}
}

export default AccountCard
