import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Card, message, Button, Spin, Alert } from 'antd'
import { Form } from '@ant-design/compatible'
import './my-plans.sass'
import moment from 'moment'
import AeroModal from '../../AeroComponents/Modal'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import {
	getAllPlans,
	getAllActivePlans,
	getAllExpiredPlans,
	getAllAeroFlyDrones,
	requestNewDrone,
	customPlanEnquiry,
	createAeroFlyTransaction,
	aeroFlyPayment,
	getAeroFlyBannerDetails,
	postIssueFaced,
	generateOtp,
	initiateFreeTrial,
	activateFreeTrial,
} from '../../Stores/MyPlans/action'
import { getStripeCheckoutUrl } from '../../Stores/Payments/action'
import AllPlansModalContent from './components/all-plans-modal-content'
import ContactDrawerContent from './components/contact-drawer-content'
import DroneDrawerContent from './components/drone-drawer-content'
import SupportDrawerContent from './components/support-drawer-content'
import EmptyCardContent from './components/empty-card-content'
import PlansTabContent from './components/plans-tab-content'
import StripePayment from '../Payment/Components/stripe-payment'
import WriteToUsDrawerContent from './components/write-to-us-drawer-content'
import FreeTrialDrawerContent from './components/free-trial-drawer-content'
import { getConfigData, getValueFromStorage, removeValueFromStorage, setValueToStorage } from '../../Services/LocalStorageService'

const MyPlansForm = props => {
	const [plansModal, setPlansModal] = useState(false)
	const [contactDrawer, setContactDrawer] = useState(false)
	const [droneDrawer, setDroneDrawer] = useState(false)
	const [supportDrawer, setSupportDrawer] = useState(false)
	const [paymentSuccessModal, setPaymentSuccessModal] = useState(false)
	const [paymentFailureModal, setPaymentFailureModal] = useState(false)
	const [supportQuery, setSupportQuery] = useState(null)
	const [location, setLocation] = useState({ latitude: null, longitude: null })
	const [allActivePlans, setAllActivePlans] = useState(props.allActivePlans)
	const [allExpiredPlans, setAllExpiredPlans] = useState(props.allExpiredPlans)
	const [allPlans, setAllPlans] = useState(props.allPlans)
	const [selectedPlan, setSelectedPlan] = useState(null)
	const [allAeroFlyDrones, setAllAeroFlyDrones] = useState(props.allAeroFlyDrones)
	const [formLoading, setFormLoading] = useState(false)
	const [stripeModal, setStripeModal] = useState(false)
	const [paymentToken, setPaymentToken] = useState(null)
	const [currentTab, setCurrentTab] = useState('active')
	const [bannerDetails, setBannerDetails] = useState(null)
	const [writeToUsDrawer, setWriteToUsDrawer] = useState(false)
	const [useType, setUseType] = useState('Personal')
	const [issuePostedModal, setIssuePostedModal] = useState(false)
	const [freeTrialDrawer, setFreeTrialDrawer] = useState(false)
	const [verificiationBtn, setVerificationBtn] = useState(true)
	const [phoneNumber, setPhoneNumber] = useState(null)
	const [verificationCodeSent, setVerificationCodeSent] = useState(false)
	const [sendCodeBtn, setSendCodeBtn] = useState(false)
	const [countryCode, setCountryCode] = useState('+91')
	const [finalOtp, setFinalOtp] = useState(null)
	const [freeTrialSuccessModal, setFreeTrialSuccessModal] = useState(false)
	const [verificationId, setVerificationId] = useState(null)
	const [freeTrialEndDate, setFreeTrialEndDate] = useState(null)
	const [waitForApi, setWaitForApi] = useState(false)
	const [allCountryCode, setAllCountryCode] = useState([])
	const [payBtnLoading, setPayBtnLoading] = useState(false)

	const otpInput1 = useRef()
	const otpInput2 = useRef()
	const otpInput3 = useRef()
	const otpInput4 = useRef()
	const { paymentStatus } = props.match.params

	const togglePlansModal = () => setPlansModal(prevState => !prevState)
	const toggleContactDrawer = () => setContactDrawer(prevState => !prevState)
	const toggleSupportDrawer = () => setSupportDrawer(prevState => !prevState)
	const toggleWriteToUsDrawer = () => setWriteToUsDrawer(prevState => !prevState)
	const toggleFreeTrialDrawer = () => setFreeTrialDrawer(prevState => !prevState)
	const toggleIssuePostedModal = () => setIssuePostedModal(prevState => !prevState)
	const togglePaymentFailureModal = () => setPaymentFailureModal(prevState => !prevState)

	const toggleFreeTrialSuccessModal = () => {
		if (freeTrialSuccessModal) {
			props.getAllActivePlans()
			props.getAllExpiredPlans()
		}
		setFreeTrialSuccessModal(prevState => !prevState)
	}
	const togglePaymentSuccessModal = () => {
		if (paymentSuccessModal) {
			props.getAllActivePlans()
			props.getAllExpiredPlans()
		}
		setPaymentSuccessModal(prevState => !prevState)
	}
	const toggleStripeModal = () => setStripeModal(prevState => !prevState)
	const toggleDroneDrawer = () => {
		if (!droneDrawer) {
			props.getAllAeroFlyDrones()
		}
		setDroneDrawer(prevState => !prevState)
	}
	const enableVerificationBtn = val => {
		if (verificiationBtn) {
			setVerificationBtn(false)
		}
		setPhoneNumber(val)
	}

	useEffect(() => {
		if (paymentStatus && paymentStatus === 'success') {
			if (plansModal) togglePlansModal()
			if (getValueFromStorage('MY_PLANS_PAYMENT_FLAG')) {
				removeValueFromStorage('MY_PLANS_PAYMENT_FLAG')
				// window.location.replace(getConfigData().countryId === 1 ? process.env.REACT_APP_AU_CLIENT_BASE_URL : process.env.REACT_APP_NZ_CLIENT_BASE_URL + 'my-plans')
			} else {
				setValueToStorage('MY_PLANS_PAYMENT_FLAG', true)
				togglePaymentSuccessModal()
			}
		} else if (paymentStatus && paymentStatus === 'failure') {
			if (plansModal) togglePlansModal()
			if (getValueFromStorage('MY_PLANS_PAYMENT_FLAG')) {
				removeValueFromStorage('MY_PLANS_PAYMENT_FLAG')
				// window.location.replace(getConfigData().countryId === 1 ? process.env.REACT_APP_AU_CLIENT_BASE_URL : process.env.REACT_APP_NZ_CLIENT_BASE_URL + 'my-plans')
			} else {
				setValueToStorage('MY_PLANS_PAYMENT_FLAG', true)
				togglePaymentFailureModal()
			}
		}
		const showPosition = position => {
			const latLong = {
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			}
			setLocation(latLong)
			props.getAllPlans({ latitude: position.coords.latitude, longitude: position.coords.longitude })
		}
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition)
		} else {
			message.error('Geolocation is not supported by this browser.')
		}
		props.getAeroFlyBannerDetails()
		props.getAllActivePlans()
		props.getAllExpiredPlans()
		fetch('https://d98pi9tz3mk1u.cloudfront.net/CountryCodes.json')
			.then(res => res.json())
			.then(out => {
				setAllCountryCode(out)
			})
			.catch(err => console.error(err))
	}, [])

	useEffect(() => setAllActivePlans(props.allActivePlans), [props.allActivePlans])
	useEffect(() => setAllExpiredPlans(props.allExpiredPlans), [props.allExpiredPlans])
	useEffect(() => setAllPlans(props.allPlans), [props.allPlans])
	useEffect(() => setAllAeroFlyDrones(props.allAeroFlyDrones), [props.allAeroFlyDrones])
	useEffect(() => setBannerDetails(props.aeroFlyBannerData), [props.aeroFlyBannerData])

	useEffect(() => {
		if (props.verificationData && waitForApi) {
			setWaitForApi(false)
			setVerificationId(props.verificationData)
		}
	}, [props.verificationData])

	useEffect(() => {
		if (props.freeTrialActivated && waitForApi) {
			setWaitForApi(false)
			setFreeTrialEndDate(moment(props.freeTrialActivated.endDate).format('Do MMM YYYY'))
			toggleFreeTrialDrawer()
			togglePlansModal()
			toggleFreeTrialSuccessModal()
			props.getAllPlans({ latitude: location.latitude, longitude: location.longitude })
		}
	}, [props.freeTrialActivated])

	useEffect(() => {
		if (props.transactionData && waitForApi) {
			let body = {
				amount: selectedPlan.price,
				bank_token: paymentToken,
				payment_transaction_id: props.transactionData.payment_transaction_id,
			}
			props
				.aeroFlyPayment(body)
				.then(() => {
					setFormLoading(false)
					setWaitForApi(false)
				})
				.catch(() => {
					setFormLoading(false)
					setWaitForApi(false)
				})
		}
	}, [props.transactionData])

	useEffect(() => {
		if (props.aeroFlyPaymentSuccess && waitForApi) {
			toggleStripeModal()
			if (plansModal) togglePlansModal()
			togglePaymentSuccessModal()
		}
	}, [props.aeroFlyPaymentSuccess])

	useEffect(() => {
		if (props.newDroneRequestSuccessful && waitForApi) {
			toggleSupportDrawer()
			setSupportQuery('')
			setWaitForApi(false)
		}
	}, [props.newDroneRequestSuccessful])

	useEffect(() => {
		if (props.issuePostSuccessful && waitForApi) {
			toggleWriteToUsDrawer()
			toggleIssuePostedModal()
			setSupportQuery('')
			setUseType('Personal')
			setWaitForApi(false)
		}
	}, [props.issuePostSuccessful])

	useEffect(() => {
		if (props.enquiryRequestSuccessful && waitForApi) {
			props.form.resetFields()
			toggleContactDrawer()
			setWaitForApi(false)
		}
	}, [props.enquiryRequestSuccessful])

	useEffect(() => {
		if (props.stripeCheckoutUrl && waitForApi) {
			window.location.replace(props.stripeCheckoutUrl.url)
			setWaitForApi(false)
		}
	}, [props.stripeCheckoutUrl])

	const handleFormSubmit = e => {
		e.preventDefault()
		props.form.validateFields((err, values) => {
			if (!err) {
				let body = new FormData()
				const requestBody = {
					subject: 'AerologixFly Custom Plan Inquiry',
					description: 'AerologixFly Custom Plan Inquiry',
					noOfPlans: values.numberOfPlans,
					organization: values.organisation,
					additionalRequirement: values.additionalRequirement,
				}
				body.append('requestBody', JSON.stringify(requestBody))
				body.append('supportTypeId', 3)

				setFormLoading(true)
				setWaitForApi(true)
				props
					.customPlanEnquiry(body)
					.then(() => setFormLoading(false))
					.catch(() => setFormLoading(false))
			}
		})
	}

	const handleDroneSearch = e => {
		const val = e.target.value
		if (val) {
			const result = allAeroFlyDrones.filter(item => item.name.toLowerCase().includes(val.toLowerCase()))
			setAllAeroFlyDrones(result)
		} else {
			setAllAeroFlyDrones(props.allAeroFlyDrones)
		}
	}

	const fireInitiation = () => {
		if (phoneNumber) {
			setVerificationCodeSent(true)
			props.form.validateFields((err, values) => {
				if (!err) {
					const requestBody = {
						firstName: values.firstName,
						lastName: values.lastName,
						countryCode: countryCode,
						phoneNumber: phoneNumber,
						latitude: location.latitude,
						longitude: location.longitude,
					}
					setFormLoading(true)
					setWaitForApi(true)
					props
						.initiateFreeTrial(requestBody)
						.then(() => setFormLoading(false))
						.catch(() => setFormLoading(false))
				}
			})
		} else {
			message.error('Please enter the phone number!')
		}
	}

	const handleVerifyOtp = e => {
		e.preventDefault()
		fireInitiation()
	}

	const storeSupportQuery = e => setSupportQuery(e.target.value)
	const storeUseType = val => setUseType(val)

	const submitSupportQuery = () => {
		let body = new FormData()
		const requestBody = {
			subject: 'Drone Support Request',
			description: 'Drone Support Request',
			droneName: supportQuery.toUpperCase(),
		}
		body.append('requestBody', JSON.stringify(requestBody))
		body.append('supportTypeId', 2)
		setWaitForApi(true)
		props.requestNewDrone(body)
	}

	const submitIssueFaced = () => {
		let body = new FormData()
		const requestBody = {
			subject: 'Trial Plan Extension Request',
			description: 'Trial Plan Extension Request',
			issueFaced: supportQuery,
			useType: useType,
		}
		body.append('requestBody', JSON.stringify(requestBody))
		body.append('supportTypeId', 5)
		props.postIssueFaced(body)
	}

	const selectPlan = plan => setSelectedPlan(plan)

	const payNow = token => {
		setFormLoading(true)
		setPaymentToken(token)
		setWaitForApi(true)
		props.createAeroFlyTransaction({ subscription_id: selectedPlan.planId })
	}

	const handleTabChange = val => {
		setCurrentTab(val)
	}

	const checkAndFire = () => {
		if (location.latitude !== null && location.longitude !== null) {
			togglePlansModal()
		} else {
			message.error('Please allow location access in order to proceed with a Flight Plan purchase!')
		}
	}

	const getVerificationCode = () => {
		if (phoneNumber) {
			setVerificationCodeSent(true)
			setFormLoading(true)
			setWaitForApi(true)
			props
				.activateFreeTrial({ otp: finalOtp, verificationId: verificationId })
				.then(() => setFormLoading(false))
				.catch(() => setFormLoading(false))
		} else {
			message.error('Please enter the phone number!')
		}
	}

	const storeOtpInput = type => {
		const numArr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
		const otpValues = [otpInput1.current.input.value, otpInput2.current.input.value, otpInput3.current.input.value, otpInput4.current.input.value]

		if (type === 1 && otpValues[0]) {
			otpInput2.current.focus()
		} else if (type === 2 && otpValues[1]) {
			if (otpValues[0]) {
				otpInput3.current.focus()
			}
		} else if (type === 3 && otpValues[2]) {
			if (otpValues[0] && otpValues[1]) {
				otpInput4.current.focus()
			}
		}
		if (otpValues[0] && otpValues[1] && otpValues[2] && otpValues[3]) {
			const numCheck = otpValues.find(item => !numArr.includes(item))
			if (numCheck) {
				message.error('Only numbers are allowed!')
			} else {
				setSendCodeBtn(true)
				const finalOtpVal = otpValues[0] + otpValues[1] + otpValues[2] + otpValues[3]
				setFinalOtp(finalOtpVal)
			}
		}
	}

	const editPhoneNumber = () => setVerificationCodeSent(false)
	const handleCountryCodeChange = val => setCountryCode(val)

	const checkoutWithStripe = () => {
		setPayBtnLoading(true)
		const body = {
			subscription_id: selectedPlan.planId,
			latitude: location.latitude,
			longitude: location.longitude,
			// success_URL: `${getConfigData().countryId === 1 ? process.env.REACT_APP_AU_CLIENT_BASE_URL : process.env.REACT_APP_NZ_CLIENT_BASE_URL}my-plans/success`,
			// failure_URL: `${getConfigData().countryId === 1 ? process.env.REACT_APP_AU_CLIENT_BASE_URL : process.env.REACT_APP_NZ_CLIENT_BASE_URL}my-plans/failure`,
		}
		setWaitForApi(true)
		props.getStripeCheckoutUrl(body)
	}

	return (
		<div style={{ padding: '20px' }}>
			<div className='projects-heading'>
				<p style={{ fontSize: '32px', lineHeight: '42px', fontStyle: 'SemiBold' }}>My Plans</p>
			</div>
			{bannerDetails && bannerDetails.bannerDetails === 'TRIAL_PLAN_EXPIRED' && (
				<Alert
					style={{ padding: '20px', marginBottom: '20px' }}
					message={<span style={{ color: 'white', paddingLeft: '15px' }}>Your Trial has expired. Please upgrade your Plan to enjoy uninterrupted services</span>}
					description={
						<span>
							<span style={{ color: 'white', paddingLeft: '15px', fontSize: '13px' }}>
								Need more time with the Trial Plan?{' '}
								<Button onClick={toggleWriteToUsDrawer} style={{ color: 'white' }} type='link'>
									<u>Write to us</u>&nbsp; {' ->'}
								</Button>
							</span>
							<span className='remind-me-later'>
								<Button onClick={checkAndFire} style={{ background: 'white', color: '#FA541C', width: '168px', borderRadius: '8px', fontSize: '16px' }}>
									Upgrade Plan
								</Button>
							</span>
						</span>
					}
					type='info'
					showIcon
					icon={
						<span>
							<MatImage src={IMAGES_SRC.BIG_WHITE_EXCLAMATION_ICON} />
						</span>
					}
					className='enterprise-pilot-eligiblity-alert-failed'
				/>
			)}

			<Card className='my-plans-header-banner'>
				<p style={{ fontSize: '28px' }}>AeroPath</p>
				<p style={{ fontSize: '16px' }}>
					Autonomous drone flight solutions for telecommunications tower inspections
					<span style={{ float: 'right' }}>
						{allActivePlans?.length || allExpiredPlans?.length ? (
							<Button
								onClick={checkAndFire}
								style={{ position: 'relative', bottom: '25px', width: '200px', borderRadius: '8px', color: '#176DD9', background: 'white' }}
								type='primary'
							>
								View Plans
							</Button>
						) : null}
					</span>
				</p>
			</Card>
			{allActivePlans?.length || allExpiredPlans?.length ? (
				<PlansTabContent
					allActivePlans={allActivePlans}
					allExpiredPlans={allExpiredPlans}
					repurchase={togglePlansModal}
					handleTabChange={handleTabChange}
					currentTab={currentTab}
				/>
			) : (
				<EmptyCardContent checkAndFire={checkAndFire} allPlans={allPlans} />
			)}
			<AeroModal bodyStyle={{ background: '#F5F5F5', width: '880' }} width={880} visible={plansModal} footer={false} title='AerologixFly Plans' onCancel={togglePlansModal}>
				<AllPlansModalContent
					type='web'
					allPlans={allPlans}
					toggleContactDrawer={toggleContactDrawer}
					toggleDroneDrawer={toggleDroneDrawer}
					selectPlan={selectPlan}
					selectedPlan={selectedPlan}
					checkoutWithStripe={checkoutWithStripe}
					toggleFreeTrialDrawer={toggleFreeTrialDrawer}
					payBtnLoading={payBtnLoading}
				/>
			</AeroModal>
			<AeroModal
				bodyStyle={{ background: '#F5F5F5' }}
				footer={false}
				onCancel={toggleContactDrawer}
				visible={contactDrawer}
				onClose={toggleContactDrawer}
				placement='center'
				title='Custom Plan Enquiry'
			>
				<Form layout='vertical' onSubmit={handleFormSubmit}>
					<ContactDrawerContent type='web' {...props} formLoading={formLoading} />
				</Form>
			</AeroModal>
			<AeroModal
				onCancel={toggleFreeTrialDrawer}
				//closeIcon={<LeftCircleFilled style={{ fontSize: '20px' }} />}
				bodyStyle={{ background: '#FAFAFA' }}
				visible={freeTrialDrawer}
				onClose={toggleFreeTrialDrawer}
				placement='center'
				title='Start Trial for Free! No hidden charges'
			>
				<Form layout='vertical' onSubmit={handleVerifyOtp}>
					<FreeTrialDrawerContent
						{...props}
						formLoading={formLoading}
						verificiationBtn={verificiationBtn}
						enableVerificationBtn={enableVerificationBtn}
						verificationCodeSent={verificationCodeSent}
						getVerificationCode={getVerificationCode}
						phoneNumber={phoneNumber}
						otpInput1={otpInput1}
						otpInput2={otpInput2}
						otpInput3={otpInput3}
						otpInput4={otpInput4}
						storeOtpInput={storeOtpInput}
						sendCodeBtn={sendCodeBtn}
						editPhoneNumber={editPhoneNumber}
						handleCountryCodeChange={handleCountryCodeChange}
						fireInitiation={fireInitiation}
						type='web'
						allCountryCode={allCountryCode}
					/>
				</Form>
			</AeroModal>
			<AeroModal
				closeIcon={toggleWriteToUsDrawer}
				//closeIcon={<LeftCircleFilled style={{ fontSize: '20px' }} />}
				bodyStyle={{ background: '#FAFAFA' }}
				visible={writeToUsDrawer}
				onClose={toggleWriteToUsDrawer}
				placement='center'
				title='Custom Plan Enquiry'
			>
				<WriteToUsDrawerContent storeSupportQuery={storeSupportQuery} storeUseType={storeUseType} submitIssueFaced={submitIssueFaced} />
			</AeroModal>
			<AeroModal
				closeIcon={toggleDroneDrawer}
				//closeIcon={<LeftCircleFilled style={{ fontSize: '20px' }} />}
				bodyStyle={{ background: '#FAFAFA' }}
				visible={droneDrawer}
				onCancel={toggleDroneDrawer}
				placement='center'
				title='Supported Drones'
			>
				{allAeroFlyDrones && <DroneDrawerContent type='web' data={allAeroFlyDrones} handleDroneSearch={handleDroneSearch} toggleSupportDrawer={toggleSupportDrawer} />}
			</AeroModal>
			<AeroModal
				closeIcon={toggleSupportDrawer}
				//closeIcon={<LeftCircleFilled style={{ fontSize: '20px' }} />}
				bodyStyle={{ background: '#FAFAFA' }}
				visible={supportDrawer}
				onClose={toggleSupportDrawer}
				placement='center'
				title='Drone Support Request'
			>
				<SupportDrawerContent type='web' storeSupportQuery={storeSupportQuery} submitSupportQuery={submitSupportQuery} />
			</AeroModal>
			<AeroModal visible={paymentSuccessModal} footer={false} onCancel={togglePaymentSuccessModal}>
				<div className='modal-content'>
					<MatImage src={IMAGES_SRC.SUCCESS_ICON} />
					<h1>Payment Successful!</h1>
					<div className='line-2'>Congratulations! You have unlocked AeroPath access</div>
					<div className='line-3'>Download the AerologixFly app to use the flight module</div>
				</div>
			</AeroModal>
			<AeroModal visible={paymentFailureModal} footer={false} onCancel={togglePaymentFailureModal}>
				<div className='modal-content'>
					<MatImage src={IMAGES_SRC.FAILURE_ICON} />
					<h1>Payment Failed!</h1>
					<div className='line-2'>Please retry or try using another payment method.</div>
				</div>
			</AeroModal>
			<AeroModal visible={issuePostedModal} footer={false} onCancel={toggleIssuePostedModal}>
				<div className='modal-content'>
					<MatImage src={IMAGES_SRC.SUCCESS_ICON} />
					<h1>Request Submitted</h1>
					<div className='line-2'>Our team will contact you soon regarding your issue with trial plan</div>
				</div>
			</AeroModal>
			<AeroModal visible={freeTrialSuccessModal} footer={false} onCancel={toggleFreeTrialSuccessModal}>
				<div className='modal-content'>
					<MatImage src={IMAGES_SRC.SUCCESS_ICON} />
					<h1>Congratulations!</h1>
					<div className='line-2'>Your free trial has started and it will end on {freeTrialEndDate}</div>
				</div>
			</AeroModal>
			<AeroModal visible={stripeModal} footer={false} onCancel={toggleStripeModal}>
				<Spin spinning={formLoading}>
					<div style={{ margin: '13px' }}>
						{selectedPlan && <StripePayment id='PAY_NOW' displayAmount={selectedPlan.price} cancel={toggleStripeModal} updateCardDetails={payNow} />}
					</div>
				</Spin>
			</AeroModal>
		</div>
	)
}

const MyPlans = Form.create({ name: 'MY_PLANS_FORM' })(MyPlansForm)

const mapStateToProps = state => {
	return {
		allPlans: state.MyPlansReducer.allPlans,
		allActivePlans: state.MyPlansReducer.allActivePlans,
		allExpiredPlans: state.MyPlansReducer.allExpiredPlans,
		allAeroFlyDrones: state.MyPlansReducer.allAeroFlyDrones,
		newDroneRequestSuccessful: state.MyPlansReducer.newDroneRequestSuccessful,
		enquiryRequestSuccessful: state.MyPlansReducer.enquiryRequestSuccessful,
		transactionData: state.MyPlansReducer.transactionData,
		aeroFlyPaymentSuccess: state.MyPlansReducer.aeroFlyPaymentSuccess,
		aeroFlyBannerData: state.MyPlansReducer.aeroFlyBannerData,
		issuePostSuccessful: state.MyPlansReducer.issuePostSuccessful,
		verificationData: state.MyPlansReducer.verificationData,
		freeTrialActivated: state.MyPlansReducer.freeTrialActivated,
		stripeCheckoutUrl: state.PaymentsReducer.stripeCheckoutUrl,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAllPlans: bindActionCreators(getAllPlans, dispatch),
		getAllActivePlans: bindActionCreators(getAllActivePlans, dispatch),
		getAllExpiredPlans: bindActionCreators(getAllExpiredPlans, dispatch),
		getAllAeroFlyDrones: bindActionCreators(getAllAeroFlyDrones, dispatch),
		requestNewDrone: bindActionCreators(requestNewDrone, dispatch),
		customPlanEnquiry: bindActionCreators(customPlanEnquiry, dispatch),
		createAeroFlyTransaction: bindActionCreators(createAeroFlyTransaction, dispatch),
		aeroFlyPayment: bindActionCreators(aeroFlyPayment, dispatch),
		getAeroFlyBannerDetails: bindActionCreators(getAeroFlyBannerDetails, dispatch),
		postIssueFaced: bindActionCreators(postIssueFaced, dispatch),
		generateOtp: bindActionCreators(generateOtp, dispatch),
		initiateFreeTrial: bindActionCreators(initiateFreeTrial, dispatch),
		activateFreeTrial: bindActionCreators(activateFreeTrial, dispatch),
		getStripeCheckoutUrl: bindActionCreators(getStripeCheckoutUrl, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(MyPlans)
