import React, { useState } from 'react'
import styles from './SelectFileModal.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'
import { Button, Upload, Tooltip, message, notification } from 'antd'

import { InboxOutlined, PaperClipOutlined, CloseOutlined, CheckCircleTwoTone, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons'

import { getMultipartUrls, uploadMultipartFile, createContribution, addContribution, removeContribution, removeUpload, addToUpload } from '../../../../../Stores/Getty/action'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

function SelectFileModal(props) {
	const { Dragger } = Upload

	// extract props
	const {
		// from parent
		setShowSelectFileModal,
		showSelectFileModal,
		submissionId,

		//dispacth
		getMultipartUrls,
		uploadMultipartFile,
		createContribution,
		addContribution,
		removeContribution,
		removeUpload,
		addToUpload,
		// from state
		uploads,
		contributionList,
	} = props

	const [readyToUpload, setReadyToUpload] = useState(false)
	const [fileList, setfileList] = useState([])

	function uploadFiles() {
		let sucessMessage = 'Started uploading ' + fileList.length + ' file' + (fileList.length === 1 ? '' : 's')
		message.success({ content: sucessMessage, icon: <CheckCircleFilled style={{ position: 'relative', top: '-3px' }} /> })

		let uploadList
		let currentSubmissionUploadList
		let startUpload = true

		if (window.hasOwnProperty('gettyUploadList')) {
			uploadList = window.gettyUploadList
			startUpload = false
			if (uploadList.hasOwnProperty(submissionId)) {
				currentSubmissionUploadList = uploadList[submissionId]
			} else {
				currentSubmissionUploadList = {}
			}
		} else {
			uploadList = {}
			currentSubmissionUploadList = {}
		}

		for (let file of fileList) {
			currentSubmissionUploadList[file.name] = file.originFileObj
			addToUpload({ file: file.originFileObj, filename: file.name, submissionBatchId: submissionId })

			addContribution({ filename: file.name, submissionBatchId: submissionId })
		}

		uploadList = {
			...uploadList,
			[submissionId]: {
				...currentSubmissionUploadList,
			},
		}
		window.gettyUploadList = uploadList

		if (startUpload) {
			uploadFile(submissionId)
		}

		setShowSelectFileModal(false)
		setReadyToUpload(false)
		window.onbeforeunload = unloadFunction
	}

	function uploadFile(submissionBatchId) {
		let file = null

		let uploadList = window.gettyUploadList

		// return if no file present in list to upload
		if (window.hasOwnProperty('gettyUploadList')) {
			if (!uploadList.hasOwnProperty(submissionId)) {
				return
			}
		} else {
			return
		}

		let fileNames = Object.keys(uploadList[submissionBatchId])
		if (fileNames.length > 0) {
			file = uploadList[submissionBatchId][fileNames[0]]
		} else {
			return
		}

		if (file == null) {
			return
		}

		let fileSize = file.size
		let fileName = file.name
		let noOfParts = calculateNoOfParts(fileSize)

		getMultipartUrls({ submissionBatchId: submissionBatchId, parts: noOfParts, fileName: fileName })
			.then(urlResponse => {
				// urlResponse will have action and value
				let urls = urlResponse.value.data.urls
				let uploadId = urlResponse.value.data.uploadId

				uploadParts(file, urls, submissionBatchId)
					.then(uploadResponse => {
						let responseBody = {
							uploadId: uploadId,
							parts: uploadResponse,
							submissionId: submissionBatchId,
							fileName: fileName,
							mixpanel_type: 'file_upload',
						}

						createContribution({
							body: responseBody,
							submissionBatchId: submissionBatchId,
						})
							.then(completeResponse => {
								// Send in app notification
								notification.open({
									duration: 10,
									message: ' File uploaded successfully ' + fileName,
									className: styles['notification'],
									icon: <CheckCircleTwoTone twoToneColor='#52c41a' style={{ position: 'relative', top: '-4px' }} />,
								})

								deleteUploadList(submissionBatchId, fileName)
								if (!isUploadRemaining()) {
									window.onbeforeunload = ''
								}
								uploadFile(submissionId)
							})
							.catch(error => {
								console.log(error)
								removeContribution({ filename: fileName })
								removeUpload({ submissionBatchId: submissionBatchId, filename: fileName })
								message.error('Error while completing upload of ' + fileName + '. Please try again')
								deleteUploadList(submissionBatchId, fileName)
							})
					})
					.catch(error => {
						console.log(error)
						removeContribution({ filename: fileName })
						removeUpload({ submissionBatchId: submissionBatchId, filename: fileName })
						message.error('Error while uploading ' + fileName + '. Please try again')
						deleteUploadList(submissionBatchId, fileName)
					})
			})
			.catch(error => {
				console.log(error)
				removeContribution({ filename: fileName })
				removeUpload({ submissionBatchId: submissionBatchId, filename: fileName })
				message.error('Error while uploading ' + fileName + '. Please try again')
				deleteUploadList(submissionBatchId, fileName)
			})
	}

	const unloadFunction = function (event) {
		// function to confirm closing tab
		return ' '
	}

	function deleteUploadList(submissionBatchId, fileName) {
		let uploadList = window.gettyUploadList
		if (uploadList.hasOwnProperty(submissionBatchId) && uploadList[submissionBatchId].hasOwnProperty(fileName)) {
			delete uploadList[submissionBatchId][fileName]
		}
		if (Object.keys(uploadList[submissionId]).length === 0) {
			delete uploadList[submissionId]
		}
		if (Object.keys(uploadList).length === 0) {
			delete window.gettyUploadList
			return
		}

		window.gettyUploadList = uploadList
	}

	// check if any other upload is pending
	function isUploadRemaining() {
		let uploadRemaining = false
		if (window.hasOwnProperty('gettyUploadList')) {
			if (Object.keys(window.gettyUploadList).length !== 0) {
				uploadRemaining = true
			}
		}
		return uploadRemaining
	}

	function beforeUpload(fileObj) {
		// Validate upload file before upload

		if (fileList.length === 0) {
			return false
		}
		for (let file of fileList) {
			if (!(file.type === 'video/quicktime' || file.type === 'application/mxf' || file.type === 'video/mp4')) {
				message.error('You can only upload  .mov, mp4 & mxf formats ')
				setShowSelectFileModal(false)
				setShowSelectFileModal(true)
				return false
			}
			let fiveGB = 5 * 1024 * 1024 * 1024
			if (fiveGB <= file.size) {
				message.error('Maximim file size should be 5 GB.')
				setShowSelectFileModal(false)
				setShowSelectFileModal(true)
				return false
			}
		}

		let totalFileCount = 0

		if (contributionList) {
			totalFileCount = totalFileCount + contributionList.length + fileList.length
		}

		if (totalFileCount > 25) {
			message.error('Maximum number of videos allowed for submission is 25.')
			setShowSelectFileModal(false)
			setShowSelectFileModal(true)
			return false
		}

		setReadyToUpload(true)
	}

	async function uploadParts(file, urls, submissionId) {
		let noOfParts = calculateNoOfParts(file.size)
		let FILE_CHUNK_SIZE = 0
		FILE_CHUNK_SIZE = file.size / noOfParts
		if (FILE_CHUNK_SIZE / (1024 * 1024) < 6) {
			FILE_CHUNK_SIZE = 6 * 1024 * 1024
		}
		const keys = Object.keys(urls)
		const promises = []

		// create chuncks of the file and upload
		for (const indexStr of keys) {
			const index = parseInt(indexStr) - 1
			const start = index * FILE_CHUNK_SIZE
			const end = (index + 1) * FILE_CHUNK_SIZE
			const blob = index < keys.length ? file.slice(start, end) : file.slice(start)
			let body = {
				url: urls[index + 1],
				partNo: index + 1,
				body: blob,
			}

			let chunk_p = uploadMultipartFile({ body, submissionBatchId: submissionId, filename: file.name })

			promises.push(chunk_p)
		}

		const resParts = await Promise.all(promises) // eact part response will have etag and partnumber
		return resParts.map(item => ({
			etag: item.value.data.etag,
			partNumber: item.value.data.partNumber,
		}))
	}

	// calculate number of chuncks the file has to be splitted
	function calculateNoOfParts(fileSize) {
		const toMB = fileSize / (1024 * 1024)
		if (toMB > 600) {
			return 100
		}
		if (toMB < 6) {
			return 1
		} else {
			return Math.ceil(toMB / 6)
		}
	}
	//{ fileList: newFileList }
	const onChange = data => {
		let isSameFileCurrentlyUploading = false

		if (uploads && uploads[submissionId]) {
			for (let fileName in uploads[submissionId]) {
				if (uploads[submissionId][fileName] === data.file.name) {
					isSameFileCurrentlyUploading = true
				}
			}

			// setCount(prev => prev + 1)
		}

		let isSameFileAlreadyUploaded = false
		if (contributionList) {
			if (contributionList.findIndex(el => el.fileName === data.file.name) > -1) {
				isSameFileAlreadyUploaded = true
			}
		}

		if (!isSameFileAlreadyUploaded && !isSameFileCurrentlyUploading) {
			setfileList([...data.fileList])
		} else {
			message.error({
				content: 'File with name ' + data.file.name + ' is already uploaded or in queue for upload.',
				icon: <CloseCircleFilled style={{ color: 'red', position: 'relative', top: '-3px' }} />,
			})
			setShowSelectFileModal(false)
			setShowSelectFileModal(true)
		}
	}

	function getFiles() {
		let files = fileList.map(file => {
			return (
				<div className={styles['upload-ready']} key={file.name}>
					<div className={styles['upload-name']}>
						<Tooltip placement='top' title={file.name}>
							<span>
								<PaperClipOutlined />{' '}
							</span>{' '}
							{file.name}
						</Tooltip>
					</div>

					<div className={styles['progress-right']}>
						<CloseOutlined
							className={styles['progress-close']}
							onClick={() => {
								removeFileFromList(file.name)
							}}
						/>
					</div>
				</div>
			)
		})

		return files
	}

	function removeFileFromList(name) {
		let newFileList = [...fileList]
		newFileList.splice(newFileList.findIndex(el => el.name === name), 1)
		setfileList(newFileList)

		if (newFileList.length === 0) {
			setReadyToUpload(false)
		}
	}

	return (
		<AeroModal
			footer={false}
			onCancel={() => {
				setShowSelectFileModal(false)
				setReadyToUpload(false)
			}}
			visible={showSelectFileModal}
		>
			<div className={styles['modal-wrapper']}>
				{readyToUpload ? (
					// After selecting file
					<div>
						<div className={styles['upload-files-scroller']}>{getFiles()}</div>
						<br />
						<Button className={styles['upload-button']} type='primary' onClick={uploadFiles}>
							Upload
						</Button>
					</div>
				) : (
					// Before selecting file
					<Dragger multiple={true} onChange={onChange} className={styles['dragger']} showUploadList={false} customRequest={beforeUpload}>
						<p>
							<InboxOutlined className={styles['upload-icon']} />
						</p>
						<div>
							<div className={styles['header']}>Click or drag file to this area to upload</div>
							<div className={styles['header-info']}>
								<br />
								Only .mov, mp4 &amp; mxf formats are supported
								<br />
								<br />
								Maximum supported filesize is 5GB.
								<br />
								<br />
								Editorial Videos ranging between 30 seconds and 50 seconds are most likely to get Published.
							</div>
						</div>
					</Dragger>
				)}
			</div>
		</AeroModal>
	)
}

const mapStateToProps = (state, ownProps) => {
	return {
		// all upload data
		uploads: state.GettyReducer.uploads,
		contributionList: state.GettyReducer.contributionList,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getMultipartUrls: bindActionCreators(getMultipartUrls, dispatch),
		uploadMultipartFile: bindActionCreators(uploadMultipartFile, dispatch),
		createContribution: bindActionCreators(createContribution, dispatch),
		addContribution: bindActionCreators(addContribution, dispatch),
		removeContribution: bindActionCreators(removeContribution, dispatch),
		addToUpload: bindActionCreators(addToUpload, dispatch),
		removeUpload: bindActionCreators(removeUpload, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SelectFileModal)
