import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import CasaLicense from '../casa-license'

const CasaTab = props => {
	const { profileData, deleteCasaLicense, updateCasaLicense, casaLicenseTypesData, callListOfLicenses, deleteCasaModal, toggleDeleteCasaModal, selectedLicense } = props

	return (
		<>
			<div style={{ fontSize: '16px', color: '#47464A' }}>CASA License</div>
			<div style={{ color: '#84818A', marginTop: '10px' }}>
				Jobs will be allocated to you based on the qualifications of your CASA License. You can apply for or upgrade your CASA License{' '}
				<Button style={{ position: 'relative', right: '10px' }} type='link' href='https://www.casa.gov.au/drones' target='_blank' rel='noopener noreferrer'>
					here
				</Button>
			</div>
			<div style={{ marginTop: '20px' }}>
				{profileData ? (
					profileData['licenses'].length !== 0 ? (
						<>
							<Button
								style={{ height: '48px', marginBottom: '20px' }}
								type='primary'
								onClick={() => {
									callListOfLicenses()
								}}
							>
								<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} />
								Add License
							</Button>
							<CasaLicense
								viewMode
								data={profileData['licenses']}
								retry={callListOfLicenses}
								deleteLicense={(id, licenseType) => {
									deleteCasaLicense({ pilotLicenseId: id, licenseType })
								}}
								casaLicenseTypesData={casaLicenseTypesData}
								closeModal={newData => {
									updateCasaLicense(newData)
								}}
								deleteCasaModal={deleteCasaModal}
								toggleDeleteCasaModal={toggleDeleteCasaModal}
								selectedLicense={selectedLicense}
							/>
						</>
					) : (
						<CasaLicense
							data={profileData['licenses']}
							retry={callListOfLicenses}
							deleteLicense={id => {
								deleteCasaLicense({ pilotLicenseId: id })
							}}
							casaLicenseTypesData={casaLicenseTypesData}
							closeModal={newData => {
								updateCasaLicense(newData)
							}}
							deleteCasaModal={deleteCasaModal}
							toggleDeleteCasaModal={toggleDeleteCasaModal}
							selectedLicense={selectedLicense}
						/>
					)
				) : null}
			</div>
		</>
	)
}

export default CasaTab
