import React, { useEffect, useState } from 'react'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { Button, Form, Input, Statistic } from 'antd'
import AeroModal from '../../../AeroComponents/Modal'
import { validateMobileNumber } from '../../../Services/UtilityService'
import { getOtp, getWebConfig, updateProfile } from '../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { EditFilled } from '@ant-design/icons'

const { Countdown } = Statistic

const ProjectMobileChecker = props => {
	const {
		pnModal,
		togglePnModal,
		getOtp,
		getOtpData,
		updateProfile,
		checkAndCreateProject,
		projectBody,
		cancelPnModalStage1,
		cancelPnModalStage2,
		handlePnModalCancel,
		handlePnVerificationCancel,
		handlePublishCancel,
		webConfig,
		getWebConfig,
	} = props
	const [timer, setTimer] = useState(null)
	const [stage, setStage] = useState(1)
	const [mobileNumber, setMobileNumber] = useState(null)
	const [showTimer, setShowTimer] = useState(true)
	const [waitForApi, setWaitForApi] = useState(false)
	const [otpData, setOtpData] = useState(null)
	const [configData, setConfigData] = useState(webConfig)

	useEffect(() => {
		if (!configData) {
			setWaitForApi(true)
			getWebConfig()
		}
	}, [configData])

	useEffect(() => {
		if (webConfig && waitForApi) {
			setWaitForApi(false)
			setConfigData(webConfig)
		}
	}, [webConfig])

	useEffect(() => {
		if (getOtpData && waitForApi) {
			setWaitForApi(false)
			setOtpData(getOtpData)
		}
	}, [getOtpData])

	const capturePhoneNumber = values => {
		setWaitForApi(true)
		getOtp({ mobileNumber: configData.isdCode + values.phoneNumber }).then(() => {
			setMobileNumber(values.phoneNumber)
			setTimer(Date.now() + 1000 * 60)
			setStage(2)
		})
	}

	const changePhoneNumber = mode => {
		if (mode === 1) {
			setStage(1)
		} else {
			setWaitForApi(true)
			getOtp({ mobileNumber: configData.isdCode + mobileNumber }).then(() => {
				toggleShowTimer()
				setTimer(Date.now() + 1000 * 60)
			})
		}
	}

	const toggleShowTimer = () => setShowTimer(prevState => !prevState)

	const captureOtp = values => {
		const body = { mobileNumber: mobileNumber, otpRequest: { otp: values.otp, otpKey: otpData.otp_key }, mixpanel_type: 'phone_number' }
		updateProfile(body).then(res => {
			if (res.value.status_code === 200) {
				togglePnModal(false)
				checkAndCreateProject(projectBody)
			}
		})
	}

	return (
		<>
			{configData && (
				<>
					<AeroModal title={stage === 2 ? 'Add Phone Number' : false} visible={pnModal} footer={false} onCancel={handlePnModalCancel}>
						<div style={{ textAlign: 'center' }}>
							{stage === 1 ? (
								<>
									<div style={{ padding: '20px' }}>
										<MatImage alt='add-phone' src={IMAGES_SRC.ADD_MOBILE_ICON} />
									</div>
									<div style={{ fontSize: '20px', width: '60%', margin: 'auto', padding: '10px' }}>Just one more step before we publish your project</div>
									<div style={{ fontSize: '16px', color: '#84818A', padding: '10px' }}>Please verify your phone number to proceed</div>
									<div style={{ padding: '10px' }}>
										<Form onFinish={capturePhoneNumber} layout='vertical'>
											<Form.Item
												rules={[
													{
														required: true,
														message: '',
													},
													{
														validator: validateMobileNumber,
													},
												]}
												name='phoneNumber'
												label='Enter Phone Number'
											>
												<Input placeholder='Type here' type='number' prefix={configData.isdCode} style={{ height: '50px', borderRadius: '8px' }}></Input>
											</Form.Item>

											<Button htmlType='submit' block type='primary' style={{ height: '50px', marginTop: '15px' }}>
												Get Verification Code
											</Button>
										</Form>
									</div>
								</>
							) : (
								<>
									<div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
										<span>
											Sent code to{' '}
											<strong>
												{configData.isdCode}-{mobileNumber}
											</strong>
											{!showTimer ? (
												<EditFilled onClick={() => changePhoneNumber(1)} style={{ position: 'relative', bottom: '3px', left: '5px', cursor: 'pointer' }} />
											) : null}
										</span>
										<span onClick={() => changePhoneNumber(showTimer ? 1 : 2)} style={{ color: '#00B3FF', cursor: 'pointer' }}>
											{showTimer ? 'Change' : 'Resend Code'}
										</span>
									</div>
									<div style={{ padding: '10px' }}>
										<Form onFinish={captureOtp} layout='vertical'>
											<Form.Item
												name='otp'
												rules={[
													{
														required: true,
														message: 'Invalid OTP',
														whitespace: true,
													},
												]}
												label='Enter Verification Code'
											>
												<Input
													placeholder='Type here'
													type='number'
													suffix={
														showTimer ? (
															<Countdown
																prefix={<span className='resend-extra-text-left'>Resend(</span>}
																suffix={<span className='resend-extra-text-right'>s)</span>}
																valueStyle={{ fontSize: '14px', color: '#84818A' }}
																value={timer}
																format='ss'
																onFinish={toggleShowTimer}
															/>
														) : null
													}
													style={{ height: '50px', borderRadius: '8px' }}
												></Input>
											</Form.Item>

											<Button htmlType='submit' block type='primary' style={{ height: '50px', marginTop: '15px' }}>
												Verify
											</Button>
										</Form>
									</div>
								</>
							)}
						</div>
					</AeroModal>
					<AeroModal footer={false} visible={cancelPnModalStage1} onCancel={handlePnModalCancel}>
						<div style={{ textAlign: 'center' }}>
							<MatImage style={{ margin: '20px' }} alt='wrong-quiz' src={IMAGES_SRC.BIG_RED_EXCLAMATION_ICON} />
							<div style={{ fontSize: '20px', marginBottom: '15px' }}>Cancel Verification?</div>
							<div style={{ fontSize: '16px', color: '#84818A', width: '95%', margin: 'auto' }}>
								We won't be able to publish your project unless you provide your mobile number
							</div>
						</div>
						<div style={{ textAlign: 'center', marginTop: '25px' }}>
							<Button onClick={handlePnVerificationCancel} style={{ width: '40%', height: '50px', margin: '15px' }}>
								Cancel
							</Button>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<Button onClick={handlePnModalCancel} type='primary' style={{ width: '40%', height: '50px', margin: '15px' }}>
								Verify Number
							</Button>
						</div>
					</AeroModal>
					<AeroModal footer={false} visible={cancelPnModalStage2} onCancel={handlePublishCancel}>
						<div style={{ textAlign: 'center' }}>
							<MatImage style={{ margin: '20px' }} alt='wrong-quiz' src={IMAGES_SRC.BIG_RED_EXCLAMATION_ICON} />
							<div style={{ fontSize: '20px', marginBottom: '15px' }}>Unable to Publish Project</div>
							<div style={{ fontSize: '16px', color: '#84818A', width: '95%', margin: 'auto' }}>
								Your project could not be published because your phone number has not been verified
							</div>
						</div>
						<div style={{ textAlign: 'center', marginTop: '25px' }}>
							<Button onClick={handlePublishCancel} type='primary' block style={{ height: '50px' }}>
								Okay, Got it
							</Button>
						</div>
					</AeroModal>
				</>
			)}
		</>
	)
}

const mapStateToProps = state => {
	return {
		getOtpData: state.UserSessionReducer.getOtpData,
		webConfig: state.UserSessionReducer.webConfig,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getOtp: bindActionCreators(getOtp, dispatch),
		updateProfile: bindActionCreators(updateProfile, dispatch),
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProjectMobileChecker)
