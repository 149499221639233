import React from 'react'
import { Input } from 'antd'
import { TEXTS, LOG_IN_FORM_MODAL } from '../../../Modules/OnBoarding/on-boarding.constants'

export const LOG_IN_FIELDS = {
	CONSUMER: [
		{
			fieldName: 'USERNAME',
			fieldLabel: TEXTS.USERNAME,
			fieldKey: LOG_IN_FORM_MODAL.USERNAME,
			fileObject: {
				rules: [
					{
						type: 'email',
						message: 'The input is not valid E-mail!',
						whitespace: true,
					},
					{
						required: true,
						message: 'Please input your E-mail!',
					},
				],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
		},
		{
			fieldName: 'PASS',
			fieldLabel: TEXTS.PASS,
			fieldKey: LOG_IN_FORM_MODAL.PASS,
			fileObject: {
				rules: [
					{
						required: true,
						message: 'Please input your password!',
					},
				],
			},
			fieldToRender: <Input.Password style={{ height: '48px', borderRadius: '8px' }} />,
		},
		// {
		//     fieldName: 'REMEBER_ME',
		//     fieldLabel: null,
		//     fieldKey: LOG_IN_FORM_MODAL.REMEBER_ME,
		//     fileObject: {
		//         rules: [],
		//     },
		//     fieldToRender: (<React.Fragment><Checkbox /> {TEXTS.REMEBER_ME}</React.Fragment>)
		// },
	],
	PILOT: [
		{
			fieldName: 'USERNAME',
			fieldLabel: TEXTS.USERNAME,
			fieldKey: LOG_IN_FORM_MODAL.USERNAME,
			fileObject: {
				rules: [
					{
						type: 'email',
						message: 'The input is not valid E-mail!',
						whitespace: true,
					},
					{
						required: true,
						message: 'Please input your E-mail!',
					},
				],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
		},
		{
			fieldName: 'PASS',
			fieldLabel: TEXTS.PASS,
			fieldKey: LOG_IN_FORM_MODAL.PASS,
			fileObject: {
				rules: [
					{
						required: true,
						message: 'Please input your password!',
					},
				],
			},
			fieldToRender: <Input.Password style={{ height: '48px', borderRadius: '8px' }} />,
		},
		// {
		// 	fieldName: 'REMEBER_ME',
		// 	fieldLabel: null,
		// 	fieldKey: LOG_IN_FORM_MODAL.REMEBER_ME,
		// 	fileObject: {
		// 		rules: [],
		// 	},
		// 	fieldToRender: (
		// 		<React.Fragment>
		// 			<Checkbox />
		// 			&nbsp;&nbsp;{TEXTS.REMEBER_ME}
		// 		</React.Fragment>
		// 	),
		// },
	],
}
