import { DeleteOutlined, PaperClipOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Divider, Form, Input, Select, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import moment from 'moment'
import AeroModal from '../../../../AeroComponents/Modal'
import PurchasedInsurances from './purchased-insurances'
import MatImage from '../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../AeroComponents/Image/image.constants'
import { getWebConfig } from '../../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const DroneInsuranceTab = props => {
	const {
		droneInsuranceModal,
		toggleDroneInsuranceModal,
		openInsuranceModal,
		allDroneInsuranceTypes,
		handleInsuranceForm,
		caaUploadProps,
		allPurchasedInsurance,
		deleteInsuranceModal,
		selectedInsurance,
		callDeleteInsurance,
		toggleDeleteInsuranceModal,
		toggleRetryInsuranceModal,
		retryInsurance,
		deleteDoc,
		fileList,
		webConfig,
		getWebConfig,
		updateDocState,
	} = props
	const [form] = Form.useForm()
	const [visible, setVisible] = useState(false)
	const [configData, setConfigData] = useState(webConfig)
	const [waitForApi, setWaitForApi] = useState(false)

	useEffect(() => {
		if (!configData) {
			setWaitForApi(true)
			getWebConfig()
		}
	}, [configData])

	useEffect(() => {
		if (webConfig && waitForApi) {
			setWaitForApi(false)
			setConfigData(webConfig)
		}
	}, [webConfig])

	const disabledDate = current => {
		return current && current < dayjs().endOf('day')
	}

	const uploadProps = caaUploadProps()

	useEffect(() => {
		if (retryInsurance) {
			form.setFieldsValue({
				insuranceType: retryInsurance.type,
				insuranceAmount: retryInsurance.amount,
				expiryDate: moment.utc(retryInsurance.expiryDate).local(),
				moreInfo: retryInsurance.moreInfo,
			})
			updateDocState('retryInsurance')
		} else {
			form.setFieldsValue({
				insuranceType: null,
				insuranceAmount: null,
				moreInfo: null,
				expiryDate: null,
			})
			updateDocState('init')
		}
	}, [retryInsurance, droneInsuranceModal])

	const handleRedirection = () => {
		const countryId = configData.countryId
		if (countryId === 1) {
			window.open('https://login.precision-autonomy.com/landing/aon_au-aerologix', '_blank')
		} else {
			toggleVisible()
		}
	}

	const toggleVisible = () => setVisible(!visible)

	return (
		<>
			<Card style={{ marginTop: '15px' }}>
				<div className='submod-card-header'>
					<div>
						<div style={{ marginBottom: '10px', color: '#47464A' }}>Drone Insurance</div>
						<div style={{ color: '#84818A' }}>
							Insurance is vital for protection and client reassurance in flight capture. You can obtain your insurance from{' '}
							<Button onClick={handleRedirection} type='link' style={{ color: '#00B3FF', position: 'relative', right: '15px' }}>
								here.
							</Button>
						</div>
					</div>
					<Button onClick={openInsuranceModal} className='submod-card-header-btn' type='primary'>
						<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} /> Add Drone Insurance
					</Button>
				</div>
				<Divider></Divider>
				{allPurchasedInsurance.length ? (
					<PurchasedInsurances
						allPurchasedInsurance={allPurchasedInsurance}
						toggleDeleteInsuranceModal={toggleDeleteInsuranceModal}
						toggleRetryInsuranceModal={toggleRetryInsuranceModal}
					/>
				) : (
					<div style={{ textAlign: 'center', fontSize: '16px', color: '#84818A' }}>No insurance has been added yet</div>
				)}
			</Card>
			<AeroModal visible={droneInsuranceModal} title='Add Drone Insurance' footer={false} onCancel={toggleDroneInsuranceModal}>
				<div>
					<Form form={form} onFinish={handleInsuranceForm} layout='vertical' encType='multipart/form-data'>
						<Form.Item
							rules={[{ required: true, message: 'Please select the insurance type!' }]}
							label='Select Insurance Type'
							name='insuranceType'
							style={{ marginBottom: '20px' }}
						>
							<Select options={allDroneInsuranceTypes} size='large'></Select>
						</Form.Item>
						<Form.Item
							rules={[{ required: true, message: 'Please input the insurance amount!' }]}
							name='insuranceAmount'
							label='Insurance Amount'
							style={{ marginBottom: '20px' }}
						>
							<Input
								size='large'
								type='number'
								style={{ borderRadius: '8px' }}
								prefix={
									<>
										{configData?.currency} <Divider style={{ height: '100%' }} type='vertical'></Divider>
									</>
								}
							></Input>
						</Form.Item>
						<Form.Item
							rules={[{ required: true, message: 'Please input the insurance expiry date!' }]}
							name='expiryDate'
							label='Insurance Expiry Date'
							style={{ marginBottom: '20px' }}
						>
							<DatePicker size='large' disabledDate={disabledDate} style={{ borderRadius: '8px', width: '100%' }} />
						</Form.Item>
						<Form.Item label='Upload Certificate (jpeg, png or PDF)' style={{ marginBottom: '25px' }}>
							{retryInsurance && retryInsurance.url && retryInsurance.url !== 'changed' ? (
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button style={{ position: 'relative', right: '15px' }} type='link' href={retryInsurance.url} target='_blank'>
										<PaperClipOutlined style={{ position: 'relative', bottom: '3px' }} /> Click to download
									</Button>
									<Button onClick={() => deleteDoc('insurance')} type='text'>
										<DeleteOutlined style={{ position: 'relative', bottom: '3px' }} />
									</Button>
								</div>
							) : (
								<Upload {...uploadProps}>
									<Button style={{ height: '40px', width: '100%' }}>Select File</Button>
								</Upload>
							)}
						</Form.Item>
						<Form.Item name='moreInfo' label='More Info (optional)'>
							<Input.TextArea showCount rows={4} placeholder='Type here' maxLength={250} />
						</Form.Item>
						<Form.Item style={{ marginTop: '35px' }}>
							<Button
								disabled={retryInsurance ? (retryInsurance.url || fileList.length !== 0 ? false : true) : fileList.length === 0 ? true : false}
								htmlType='submit'
								block
								type='primary'
								style={{ height: '40px' }}
							>
								Submit for Verification
							</Button>
						</Form.Item>
					</Form>
				</div>
			</AeroModal>
			<AeroModal visible={deleteInsuranceModal} onCancel={toggleDeleteInsuranceModal} footer={false}>
				<div style={{ textAlign: 'center' }}>
					<MatImage src={IMAGES_SRC.BIN_ICON} />
					<div style={{ padding: '10px' }}>
						<h2>Delete Insurance Details?</h2>
					</div>
					<div>
						<strong>{selectedInsurance?.insuranceName}</strong> will be deleted
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
						<Button style={{ height: '48px', width: '45%' }} key='submit' type='primary' onClick={callDeleteInsurance}>
							Delete
						</Button>
						<Button style={{ height: '48px', width: '45%' }} key='back' onClick={toggleDeleteInsuranceModal}>
							Cancel
						</Button>
					</div>
				</div>
			</AeroModal>
			<AeroModal onCancel={toggleVisible} visible={visible} footer={false}>
				<div style={{ textAlign: 'center' }}>
					<MatImage src={IMAGES_SRC.BIG_INFO_ICON} />
					<div style={{ padding: '25px', fontSize: '20px' }}>
						Information collected by Precision Autonomy in New Zealand will be shared with AON New Zealand. AON New Zealand will organise a quote for your consideration
					</div>

					<div style={{ padding: '20px' }}>
						<Button
							style={{ height: '48px' }}
							block
							key='submit'
							type='primary'
							onClick={() => {
								toggleVisible()
								window.open('https://au.droneinsure.co/proposal/', '_blank')
							}}
						>
							Continue
						</Button>
					</div>
				</div>
			</AeroModal>
		</>
	)
}

const mapStateToProps = state => {
	return {
		webConfig: state.UserSessionReducer.webConfig,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DroneInsuranceTab)
