import { Button, Spin } from 'antd'
import React from 'react'
import AeroField from '../../../AeroComponents/FormFields'
import { CUSTOM_PLAN_ENQUIRY_FIELDS } from '../../../AeroComponents/FormFields/Metadata/custom-plan-enquiry.fields'

const ContactDrawerContent = props => {
	const { formLoading, type } = props
	return (
		<Spin spinning={formLoading}>
			<AeroField fieldsToRender={CUSTOM_PLAN_ENQUIRY_FIELDS} {...props} />
			<div style={{ textAlign: 'center' }}>
				<Button
					type='primary'
					style={{ borderRadius: '8px', width: '290px', height: '40px', background: type === 'mobile' ? '#00B3FF' : null, border: type === 'mobile' ? '0px' : null }}
					htmlType='submit'
				>
					Submit
				</Button>
			</div>
		</Spin>
	)
}

export default ContactDrawerContent
