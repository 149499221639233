import { MailOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import AppleLogin from 'react-apple-login'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { getValueFromStorage, setValueToStorage } from '../../Services/LocalStorageService'
import { goTo } from '../../Services/NavigationService'
import { urlConfig } from '../../Services/UtilityService'

const SignupSelect = props => {
	const [referralResponse, setReferralResponse] = useState({})
	const profileType = getValueFromStorage('PROFILE_TYPE')
	const countryConfig = urlConfig()

	useEffect(() => {
		const response = props.location.state
		if (response) {
			setReferralResponse(response)
		}
	}, [])

	const redirectSocial = social_type => {
		const redirection = countryConfig.redirection ? '?redirection=' + countryConfig.redirection : ''
		const redirect_endpoint = process.env.REACT_APP_BASE_URL + 'v1/login/' + social_type + '/' + getValueFromStorage('PROFILE_TYPE') + redirection
		window.location.replace(redirect_endpoint)
	}

	const goToLogin = () => {
		setValueToStorage('PROFILE_TYPE', profileType === 'CONSUMER' ? 'PILOT' : 'CONSUMER')
		goTo('login')
	}

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<MatImage style={{ marginTop: '15px' }} alt='logo' src={IMAGES_SRC.NEW_BLUE_LOGO} />

				{!getValueFromStorage('PROJECT_DATA') ? (
					<Button style={{ marginTop: '15px' }} onClick={goToLogin} className='login-as-other-web' type='link'>
						Log In as a {profileType === 'CONSUMER' ? 'Pilot' : 'Client'}
					</Button>
				) : null}
			</div>
			<Divider></Divider>
			<div className='ob-content-wrapper'>
				<div>
					<div style={{ fontSize: '24px', marginBottom: '20px' }}>Sign up as a {profileType === 'CONSUMER' ? 'Client' : 'Pilot'}</div>
					<div style={{ marginBottom: '20px', textAlign: 'center' }}>
						<Button style={{ width: '100%' }} onClick={() => goTo('signup')} className='continue-with-email-btn' type='primary'>
							<MailOutlined style={{ position: 'relative', bottom: '4px' }} /> Continue with Email
						</Button>
					</div>
					<Divider style={{ color: '#ABA8B0' }}>
						<span style={{ color: '#ABA8B0', fontWeight: '400' }}>Or continue with</span>
					</Divider>
					 
					<div  className='social-buttons'>
						<Button onClick={() => redirectSocial('GOOGLE')} style={{ margin: '10px', height: '50px', width: '250px' }}>
							<MatImage style={{ marginRight: '5px' }} alt='google' src={IMAGES_SRC.GOOGLE_NEW_ICON} /> Google
						</Button>
						<AppleLogin
							designProp={{ height: 50, width: 250 }}
							clientId='com.aerologix.client'
							redirectURI={process.env.REACT_APP_BASE_URL + countryConfig.appleUrl + getValueFromStorage('PROFILE_TYPE')}
							scope='email%20name'
							responseType='code%20id_token'
							responseMode='form_post'
						/>
					</div>
					<div style={{ textAlign: 'center', padding: '30px' }}>
						<span style={{ color: '#84818A' }}>Already registered?</span>{' '}
						<Button style={{ fontSize: '16px' }} onClick={() => goTo('login')} type='link'>
							Log in
						</Button>
					</div>
				</div>
				{!getValueFromStorage('PROJECT_DATA') ? (
					<div style={{ textAlign: 'center' }}>
						<Button className='login-as-other-mobile' type='link'>
							Log In as a Pilot
						</Button>
					</div>
				) : null}
			</div>
		</>
	)
}

export default SignupSelect
