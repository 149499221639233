import React from 'react'
import {  Progress } from 'antd'
 
 


function AeroProgress(props) {
    
    return (
        <Progress {...props} />
    )
}

export default AeroProgress
