import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { StripeProvider } from 'react-stripe-elements'
import store from './store'
import 'sanitize.css/sanitize.css'
import './index.sass'
import App from './App'
import { urlConfig } from './Services/UtilityService'
import ErrorBoundary from './AeroComponents/ErrorBoundary'

const target = document.querySelector('#root')

render(
	<Provider store={store}>
		<StripeProvider apiKey={urlConfig().countryId === 1 ? process.env.REACT_APP_AU_STRIPE_CLIENT_KEY : process.env.REACT_APP_NZ_STRIPE_CLIENT_KEY}>
			<div style={{ height: '100%', backgroundColor: '#FFF' }}>
				<ErrorBoundary>
					<App />
				</ErrorBoundary>
			</div>
		</StripeProvider>
	</Provider>,
	target,
)
