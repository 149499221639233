import React, { Component } from 'react'
import { Card, Tabs, Input, Select, Button, Alert, Modal, message } from 'antd'
import './getty.sass'
import GettyTabContent from './getty-tab-content'
import AeroModal from '../../AeroComponents/Modal'
import { Form } from '@ant-design/compatible'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getPreviousSubmissions, getSavedSubmissions, createGettySubmission } from '../../Stores/Getty/action'
import { pushEventToMixpanel } from '../../Services/MixpanelService'

const { TabPane } = Tabs
const { Option } = Select

class GettyForm extends Component {
	constructor(props) {
		super(props)
		const paramsHolder = props.match.params
		const tabState = paramsHolder.tabState.toLowerCase()
		const pageNumber = paramsHolder.pageNumber
		const searchToken = this.props.location.search.split('search=')[1] ? this.props.location.search.split('search=')[1] : ''
		this.state = {
			addSubmissionVisible: false,
			alertBox: false,
			previousSubmissions: [],
			savedSubmissions: [],
			previousSubmissionsCount: 0,
			savedSubmissionsCount: 0,
			currentPrevSubPage: tabState === 'previous' ? pageNumber : 1,
			currentSavSubPage: tabState === 'saved' ? pageNumber : 1,
			prevSearchToken: tabState === 'previous' ? searchToken : '',
			savSearchToken: tabState === 'saved' ? searchToken : '',
			tabState: tabState,
			participationBoxVisible: false,
		}
	}

	getSearchData() {
		let search = this.props.location.search.split('search=')[1] ? this.props.location.search.split('search=')[1] : ''
		if (this.state.tabState === 'previous') {
			this.props.getPreviousSubmissions({
				pageNumber: this.state.currentPrevSubPage,
				pageSize: 10,
				searchToken: search,
			})
			this.setState({ prevSearchToken: search })
		} else {
			this.props.getSavedSubmissions({
				pageNumber: this.state.currentSavSubPage,
				pageSize: 10,
				searchToken: search,
			})
			this.setState({ savSearchToken: search })
		}
	}

	componentDidMount() {
		this.getSearchData()
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.search !== nextProps.location.search) {
			let search = nextProps.location.search.split('search=')[1]
			if (this.state.tabState === 'previous') {
				this.props.getPreviousSubmissions({
					pageNumber: this.state.currentPrevSubPage,
					pageSize: 10,
					searchToken: search,
				})
			} else {
				let search = nextProps.location.search.split('search=')[1]
				this.props.getSavedSubmissions({
					pageNumber: this.state.currentSavSubPage,
					pageSize: 10,
					searchToken: search,
				})
			}
		}
		if (this.props.match.params.tabState !== nextProps.match.params.tabState) {
			this.setState({ tabState: nextProps.match.params.tabState }, () => {
				if (nextProps.match.params.tabState.toLowerCase() === 'previous') {
					this.props.getPreviousSubmissions({
						pageNumber: this.state.currentPrevSubPage,
						pageSize: 10,
						searchToken: '', // this.state.prevSearchToken
					})
				} else {
					this.props.getSavedSubmissions({
						pageNumber: this.state.currentSavSubPage,
						pageSize: 10,
						searchToken: '', // this.state.savSearchToken
					})
				}
			})
		}

		if (this.props.match.params.pageNumber !== nextProps.match.params.pageNumber) {
			if (this.state.tabState.toLowerCase() === 'previous') {
				this.setState({ currentPrevSubPage: nextProps.match.params.pageNumber }, () => {
					this.props.getPreviousSubmissions({
						pageNumber: nextProps.match.params.pageNumber,
						pageSize: 10,
						searchToken: this.state.prevSearchToken,
					})
				})
			} else {
				this.setState({ currentSavSubPage: nextProps.match.params.pageNumber }, () => {
					this.props.getSavedSubmissions({
						pageNumber: nextProps.match.params.pageNumber,
						pageSize: 10,
						searchToken: this.state.savSearchToken,
					})
				})
			}
		}

		if (this.props.match.params.searchToken !== nextProps.match.params.searchToken) {
			if (this.state.tabState.toLowerCase() === 'previous') {
				this.setState({ prevSearchToken: nextProps.match.params.searchToken }, () => {
					this.props.getPreviousSubmissions({
						pageNumber: this.state.currentPrevSubPage,
						pageSize: 10,
						searchToken: nextProps.match.params.searchToken,
					})
				})
			} else {
				this.setState({ savSearchToken: nextProps.match.params.searchToken }, () => {
					this.props.getSavedSubmissions({
						pageNumber: this.state.currentSavSubPage,
						pageSize: 10,
						searchToken: nextProps.match.params.searchToken,
					})
				})
			}
		}

		if (this.props.previousSubmissions !== nextProps.previousSubmissions) {
			this.setState({ previousSubmissions: nextProps.previousSubmissions.data, previousSubmissionsCount: nextProps.previousSubmissions.count })
		}
		if (this.props.savedSubmissions !== nextProps.savedSubmissions) {
			this.setState({ savedSubmissions: nextProps.savedSubmissions.data, savedSubmissionsCount: nextProps.savedSubmissions.count })
		}
		if (this.props.createSubmissionsSuccess !== nextProps.createSubmissionsSuccess) {
			this.setState({ addSubmissionVisible: false })
			message.success('Submission added')
		}
	}

	changeTab = key => {
		if (key === 'previous') {
			this.props.history.push(`/getty/submission/${key}/${this.state.currentPrevSubPage}/?search=`) //${this.state.prevSearchToken}
		} else {
			this.props.history.push(`/getty/submission/${key}/${this.state.currentSavSubPage}/?search=`) // ${this.state.savSearchToken}
		}
	}

	toggleNewSubmissionModal = () => {
		this.setState(prevState => ({
			addSubmissionVisible: !prevState.addSubmissionVisible,
		}))
	}

	toggleAlertBox = () => {
		this.setState(prevState => ({
			alertBox: !prevState.alertBox,
		}))
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.form.resetFields()
				const data = {
					submissionName: values.submissionName,
					eventType: values.eventType,
					lastName: values.lastName,
					submissionType: 'GETTY_EDITORIAL_VIDEO',
				}

				this.props.createGettySubmission(data).then(result => {
					let submissionId = result.value.data.submissionId
					this.props.history.push(`/getty/submission/${submissionId}`)
				})
			}
		})
	}

	changePrevSubPage = page => {
		let searchToken = this.props.location.search.split('search=')[1] ? this.props.location.search.split('search=')[1] : ''
		this.setState({ currentPrevSubPage: page })
		this.props.getPreviousSubmissions({ pageNumber: page, pageSize: 10, searchToken: searchToken })
	}

	changeSavSubPage = page => {
		let searchToken = this.props.location.search.split('search=')[1] ? this.props.location.search.split('search=')[1] : ''
		this.setState({ currentSavSubPage: page })
		this.props.getSavedSubmissions({ pageNumber: page, pageSize: 10, searchToken: searchToken })
	}

	changePage = page => {
		let searchToken = this.props.location.search.split('search=')[1] ? this.props.location.search.split('search=')[1] : ''
		this.props.history.push(`/getty/submission/${this.state.tabState}/${page}/?search=${searchToken}`)
	}

	searchPrevSub = value => {
		this.props.history.push({ pathname: `/getty/submission/previous/${this.state.currentPrevSubPage}/`, search: `?search=${value}` })
	}

	searchSavSub = value => {
		this.props.history.push({ pathname: `/getty/submission/saved/${this.state.currentSavSubPage}/`, search: `?search=${value}` })
	}

	toggleParticipationBox = () => this.setState(prevState => ({ participationBoxVisible: !prevState.participationBoxVisible }))

	render() {
		const {
			addSubmissionVisible,
			alertBox,
			previousSubmissions,
			savedSubmissions,
			previousSubmissionsCount,
			savedSubmissionsCount,
			tabState,
			currentPrevSubPage,
			currentSavSubPage,
		} = this.state
		const { getFieldDecorator } = this.props.form
		return (
			<div className='page-container'>
				<div className='projects-heading'>
					<p style={{ fontSize: '32px', lineHeight: '42px', fontStyle: 'SemiBold' }}>Footage</p>
				</div>
				{/* <Badge.Ribbon
                    style={{ height: '30px' }}
                    text={
                        <span style={{ position: 'relative', top: '5px', padding: '10px' }}>
                            <BellTwoTone twoToneColor='#FFC53D' style={{ position: 'relative', bottom: '3px', right: '5px' }} />
                            <span style={{ color: 'black' }}>REWARD ALERT!</span>
                        </span>
                    }
                    color='white'
                    placement='start'
                >
                    <Card className='competition-banner-getty'>
                        <div style={{ color: 'white', marginTop: '25px' }}>
                            <p style={{ fontSize: '20px' }}>
                              Submit your video footage and be rewarded!
                            </p>
                            <p style={{ fontSize: '16px' }}>
                            Aerologix’s exclusive relationship with Getty Images allows you to earn recurring royalties on uploaded footage (via a licensing agreement).
                            </p>
                            <p>
                                <MatImage src={IMAGES_SRC.CELEBRATION_ICON} /> You are eligible to upload your footage(videos) on our platform!
                            </p>
                        </div>
                    </Card>
                </Badge.Ribbon> */}

				<Alert
					style={{ background: '#096DD9', borderRadius: '10px', marginBottom: '20px', padding: '20px' }}
					showIcon
					icon={<ExclamationCircleOutlined style={{ fontSize: '45px', color: 'white', position: 'relative', top: '3px' }} />}
					message={<span style={{ color: 'white', paddingLeft: '15px' }}>How does the Getty feature on the Aerologix platform work?</span>}
					description={
						<span>
							<span style={{ color: 'white', paddingLeft: '15px', fontSize: '13px' }}>
								Upload your videos to Getty through Aerologix, sit back and earn royalties on your published videos
							</span>
						</span>
					}
					type='warning'
					action={
						<Button
							onClick={() => {
								this.toggleAlertBox()

								// Sending how does this work button clicked event
								pushEventToMixpanel('', 'How Does This Work Button Clicked')
							}}
							style={{ borderRadius: '8px', width: '200px', height: '40px', position: 'relative', top: '5px' }}
						>
							How does this work?
						</Button>
					}
				/>
				<Card style={{ borderRadius: '12px' }}>
					<Tabs activeKey={tabState} onChange={this.changeTab}>
						<TabPane tab='Previous Submissions' key='previous'>
							<GettyTabContent
								onPageChange={this.changePage}
								count={previousSubmissionsCount}
								data={previousSubmissions}
								toggleNewSubmissionModal={this.toggleNewSubmissionModal}
								onItemSearch={this.searchPrevSub}
								currentPage={currentPrevSubPage}
								history={this.props.history}
								location={this.props.location}
							/>
						</TabPane>
						<TabPane tab='Saved Submissions' key='saved'>
							<GettyTabContent
								onPageChange={this.changePage}
								count={savedSubmissionsCount}
								data={savedSubmissions}
								toggleNewSubmissionModal={this.toggleNewSubmissionModal}
								onItemSearch={this.searchSavSub}
								currentPage={currentSavSubPage}
								history={this.props.history}
								location={this.props.location}
							/>
						</TabPane>
					</Tabs>
				</Card>
				<AeroModal bodyStyle={{ background: '#F5F5F5' }} title='Create Submission' visible={addSubmissionVisible} onCancel={this.toggleNewSubmissionModal} footer={false}>
					<Form style={{ textAlign: 'center' }} onSubmit={this.handleSubmit} layout='vertical'>
						<Form.Item label='Submission Name'>
							{getFieldDecorator('submissionName', { rules: [{ required: addSubmissionVisible, message: 'Submission name is required!' }], initialValue: '' })(
								<Input style={{ borderRadius: '8px' }}></Input>,
							)}
						</Form.Item>
						<Form.Item label='Event Type'>
							{getFieldDecorator('eventType', {
								initialValue: 'LIVE',
								rules: [{ required: addSubmissionVisible, message: 'Event Type is required!' }],
							})(
								<Select style={{ borderRadius: '8px' }}>
									<Option value='LIVE'>Live (Video captured in the last 24 hours)</Option>
									<Option value='NON_LIVE'>Non-Live (Video older than 24 hours but shot after the year 2000)</Option>
									{/* <Option value='ARCHIVE'>Archive</Option> */}
								</Select>,
							)}
						</Form.Item>
						<Form.Item>
							<Button htmlType='submit' style={{ borderRadius: '8px', height: '50px', width: '380px' }} type='primary'>
								Create
							</Button>
						</Form.Item>
					</Form>
				</AeroModal>
				<Modal bodyStyle={{ background: '#F5F5F5' }} title='How does this work?' onCancel={this.toggleAlertBox} footer={false} visible={alertBox}>
					<ul>
						<li>
							In the case of a sale of your Uploaded Video on the Getty Platform, Royalty Payments from the Getty Platform to the bank account linked to your
							Aerologix Profile will take place within a span of 2 months from the date of purchase. Note that simply uploading a Video or Submission to the Getty
							Platform does not guarantee a sale or entitlement to Royalty Earnings.
						</li>
						<li>
							You will earn a total Royalty of 35% on the total sale value for every sale of your uploaded Video/Image on the Getty Platform. Royalty payments from
							the Getty Platform will not reflect to your linked bank account will not be displayed in the "Received Payments" tab of the Aerologix application and
							Invoices for Royalty payments cannot be generated by Aerologix
						</li>
						<li>
							We are currently unable to obtain and share real-time information on the total number of views, total sales, and interest levels on any specific
							uploaded media file that is listed on the Getty Platform through Aerologix.
						</li>
						<li>Please read our updated Terms & Conditions before making your first upload</li>
					</ul>
					<div style={{ textAlign: 'center', marginTop: '20px' }}>
						<Button onClick={this.toggleAlertBox} style={{ borderRadius: '8px', width: '386px', height: '50px' }} type='primary' block>
							Okay, Got It
						</Button>
					</div>
				</Modal>
			</div>
		)
	}
}

const Getty = Form.create({ name: 'GETTY_FORM' })(GettyForm)

const mapStateToProps = state => {
	return {
		previousSubmissions: state.GettyReducer.previousSubmissions,
		savedSubmissions: state.GettyReducer.savedSubmissions,
		createSubmissionsSuccess: state.GettyReducer.createSubmissionsSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getPreviousSubmissions: bindActionCreators(getPreviousSubmissions, dispatch),
		getSavedSubmissions: bindActionCreators(getSavedSubmissions, dispatch),
		createGettySubmission: bindActionCreators(createGettySubmission, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Getty)
