import { InfoCircleFilled } from '@ant-design/icons'
import { Button, Checkbox, Divider, Input, List } from 'antd'
import React from 'react'

const ProjectBudget = props => {
	const { handleProjectCostChange, configData, projectCost, handleNegotiableChange, isNegotiable, budgetLimit } = props
	return (
		<>
			{!isNegotiable ? (
				<Input
					type='number'
					onChange={handleProjectCostChange}
					prefix={
						<>
							<span>{configData.currency}</span>
							<Divider style={{ height: '100%', background: '#ABA8B0' }} type='vertical'></Divider>
						</>
					}
					placeholder='Type here'
					value={projectCost}
					className='cnp-budget-input'
				></Input>
			) : null}
			{budgetLimit ? (
				<div className='cnp-excess-budget-box'>
					<List.Item.Meta
						avatar={<InfoCircleFilled style={{ position: 'relative', left: '10px' }} />}
						description={
							<span style={{ fontSize: '12px', color: 'rgb(132, 129, 138)' }}>
								If you have a high-value project and need assistance from our support team to complete it, consider choosing the enterprise project option. Get in
								touch with our team{' '}
								<Button
									href='https://aerologix.com/enterprise/#enquireproject'
									target='_blank'
									rel='noopener noreferrer'
									style={{ fontSize: '12px', position: 'relative', right: '15px' }}
									type='link'
								>
									here.
								</Button>
							</span>
						}
					/>
				</div>
			) : null}
			<div style={{ marginTop: '25px' }}>
				<Checkbox defaultChecked={isNegotiable} onChange={handleNegotiableChange}>
					<span style={{ fontSize: '16px' }}>I'm not sure about my budget</span>
				</Checkbox>
				<div className='cnp-budget-checbox-box'>
					Not sure of the budget? No problem! Publish your project and our support team will email you a budget estimate based on your requirements.
				</div>
			</div>
		</>
	)
}

export default ProjectBudget
