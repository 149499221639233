import React, { useState, useEffect, useRef } from 'react'
import styles from './ChatBox.module.scss'
import MatImage from '../../../AeroComponents/Image'
import { DownOutlined } from '@ant-design/icons'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { Button, message, Input, Avatar } from 'antd'
import { pushEventToMixpanel } from '../../../Services/MixpanelService'
import { getProjectStatus } from '../../../Services/GetProjectStatus'
import { getWebConfig } from '../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const { TextArea } = Input
function ChatBox(props) {
	const {
		chatClientRef,
		chatId,
		userName,
		participantName,
		projectName,
		projectData,
		consumerData,
		profileType,
		bidAmount,
		budget,
		closeChat,
		userProfilePicture,
		participantProfilePicture,
		disableSendMessage,
		selectedQuote,
		webConfig,
		getWebConfig,
	} = props

	const [messages, setMessages] = useState([])
	const [messageList, setMessageList] = useState([])
	const [loading, setLoading] = useState(false)
	const [scrollToTheBottom, setScrollToTheBottom] = useState(false)
	const [maximize, setMaximize] = useState(false)
	const [newMessage, setNewMessage] = useState(false)
	const [isScrolledTobottom, setIsScrolledTobottom] = useState(true)
	const [configData, setConfigData] = useState(webConfig)
	const [waitForApi, setWaitForApi] = useState(false)

	useEffect(() => {
		if (!configData) {
			setWaitForApi(true)
			getWebConfig()
		}
	}, [configData])

	useEffect(() => {
		if (webConfig && waitForApi) {
			setWaitForApi(false)
			setConfigData(webConfig)
		}
	}, [webConfig])

	const messageListRef = useRef('')
	const messageBoxRef = useRef('')
	const messageScrollRef = useRef('')
	const messageScrollBottomRef = useRef('')
	const conversationRef = useRef('')

	const NETWORK_ERROR = 'Cannot send message. Network not connected'
	const MESSAGE_FETCH_ERROR = 'Error in fetching messages. Please refresh the page.'
	const CONVERSATION_FETCH_ERROR = 'Error in loading Conversation. Please refresh the page.'

	const BID_WITHDRAWN_MESSAGE_PILOT =
		'Your chat has been disabled because you have withdrawn your bid from this project. To send messages again for this project, you will need to re-submit a bid.'
	const BID_WITHDRAWN_MESSAGE_CONSUMER = 'We have ended your conversation with this pilot as they have withdrawn their bid.'

	const PROJECT_COMPLETED_MESSAGE = 'We have ended this conversation because the project has been successfully completed.'
	const BID_FAILED_MESSAGE = 'Your chat has been disabled because your bid for this project was not successful.'
	const PROJECT_REJECTED_MESSAGE = 'Your chat has been disabled as you have rejected the project invitation'

	useEffect(() => {
		return () => {
			// destructor
			removeMessageAddedEventListener()
		}
	}, [])

	useEffect(() => {
		if (scrollToTheBottom) {
			// newly added message
			scrollToBottom()
			setScrollToTheBottom(false)
		} else {
			// old messages
			messageScrollRef.current.scrollTop += 50
		}
		messageListRef.current = messages // event handler dont have the state data when new message arrives
	}, [messages])

	useEffect(() => {
		if (chatId) {
			setMessages([])
			removeMessageAddedEventListener()
			loadMessages()
		}
	}, [chatId]) // change conversation

	const removeMessageAddedEventListener = () => {
		if (conversationRef.current?._events?.messageAdded.length > 1) {
			for (let i = 1; i < conversationRef.current?._events?.messageAdded.length; i++) {
				conversationRef.current._events.messageAdded.pop() // manually remove the event listener as remove listerner is not working properly
			}
		}
	}

	const loadMessages = () => {
		setLoading(true)
		chatClientRef.current
			.getConversationBySid(chatId)
			.then(conversationObj => {
				conversationRef.current = conversationObj
				conversationRef.current.on('messageAdded', receiveMessage) // add listener for recieving message
				return conversationObj
					.getMessages()
					.then(messages => {
						setMessageList(messages)
						let msgs =
							messages &&
							messages.items &&
							messages.items.map(message => {
								return {
									name: message.author?.includes(profileType) ? userName : participantName,
									time: message.dateUpdated,
									message: message.body,
									id: message.sid,
									profilePicture: message.author?.includes(profileType) ? userProfilePicture : participantProfilePicture,
								}
							})
						setScrollToTheBottom(true)
						setMessages(msgs)
						setLoading(false)
						conversationObj.setAllMessagesRead()
					})
					.catch(error => {
						setLoading(false)
						message.error(MESSAGE_FETCH_ERROR)
						console.log(error)
					})
			})
			.catch(error => {
				setLoading(false)
				message.error(CONVERSATION_FETCH_ERROR)
				console.log(error)
			})
	}

	const renderMessages = () => {
		return messages && messages.length > 0 ? (
			messages.map(messageItem => {
				return (
					<div key={messageItem.id} className={styles['message']}>
						<div>
							{messageItem.profilePicture ? (
								<MatImage className={styles['avatar']} src={messageItem.profilePicture} alt='.'></MatImage>
							) : (
								<Avatar className={styles['avatar']}>{messageItem.name.substring(0, 1)}</Avatar>
							)}
						</div>
						<div className={styles['message-details']}>
							<div className={styles['top']}>
								{messageItem.name} <span className={styles['time']}> | {messageItem?.time?.toLocaleString()} </span>
							</div>
							<div className={styles['bottom']}>{messageItem.message}</div>
						</div>
					</div>
				)
			})
		) : (
			<div style={{ textAlign: 'center', height: '100%', display: 'grid', placeItems: 'center', color: '#84818A', fontSize: '12px' }}>
				{' '}
				<div>Say Hello to {participantName} </div>{' '}
			</div>
		)
	}

	const receiveMessage = message => {
		if (message.conversation.sid === conversationRef.current.sid) {
			// check recieved message is for opened chat

			if (!message.author?.includes(profileType)) {
				//  check if the reciever and sender are same
				setNewMessage(true)
				let oldMessages = [...messageListRef.current]
				oldMessages.push({
					name: participantName,
					time: message.dateUpdated,
					message: message.body,
					id: message.sid,
					profilePicture: participantProfilePicture,
				})
				messageListRef.current = oldMessages
				setMessages(oldMessages)

				conversationRef.current.setAllMessagesRead() // set all messages as read
			}
		}
	}

	const sendMessage = e => {
		if (chatClientRef.current.connectionState !== 'connected') {
			message.error(NETWORK_ERROR)
			return
		}
		e.preventDefault()
		let messageData = messageBoxRef.current.resizableTextArea.textArea.value.trim()
		if (messageData) {
			conversationRef.current.sendMessage(messageData)

			// Sending chat message event to Mixpanel
			if (profileType === 'PILOT') {
				pushEventToMixpanel('', 'Chat Message Sent', {
					'Project Type': projectData.order_id ? 'Enterprise' : 'Commercial',
					'Project ID': projectData.projectId,
					'Project Name': projectData.name,
					'Project Status': getProjectStatus(projectData.order_id ? 'ENTERPRISE' : 'COMMERCIAL', projectData.state, projectData.quote?.status ?? ''),
					'Was The Message Sent And Delivered Successfully': true,
				})
			} else {
				pushEventToMixpanel('', 'Chat Message Sent', {
					'Project ID': consumerData.projectId,
					'Project Name': consumerData.name,
					'Project Status': getProjectStatus(consumerData.order_id ? 'ENTERPRISE' : 'COMMERCIAL', consumerData.state, selectedQuote?.status ?? ''),
					'Was The Message Sent And Delivered Successfully': true,
					'Pilot ID': consumerData.pilot_id,
				})
			}

			let oldMessages = [...messages]
			oldMessages.push({
				name: userName,
				time: new Date(),
				message: messageData,
				id: Math.random(),
				profilePicture: userProfilePicture,
			})
			setMessages(oldMessages)
			setScrollToTheBottom(true)
			messageBoxRef.current.value = ''
		}
	}

	const scrollToBottom = () => {
		messageScrollBottomRef.current.scrollIntoView()
	}

	const viewNewMessages = () => {
		scrollToBottom()
		setNewMessage(false)
	}

	const scrollHandler = el => {
		// check if scroll reached bottom
		if (messageScrollRef.current.scrollHeight - (messageScrollRef.current.scrollTop + messageScrollRef.current.clientHeight) > 40) {
			setIsScrolledTobottom(false)
		} else {
			setIsScrolledTobottom(true)
			setNewMessage(false)
		}

		if (messageScrollRef.current.scrollTop === 0) {
			// check if scroll reached top
			if (messageList.hasPrevPage) {
				setLoading(true)

				messageList
					.prevPage()
					.then(messageItems => {
						// load next set of messages
						setMessageList(messageItems)
						let newMessages =
							messageItems &&
							messageItems.items &&
							messageItems.items.map(message => {
								return {
									name: message.author?.includes(profileType) ? userName : participantName,
									time: message.dateUpdated,
									message: message.body,
									id: message.sid,
									profilePicture: message.author?.includes(profileType) ? userProfilePicture : participantProfilePicture,
								}
							})
						let oldMessages = [...messages]

						setMessages(newMessages.concat(oldMessages))
						setLoading(false)
					})
					.catch(error => {
						message.error(MESSAGE_FETCH_ERROR)
						console.log(error)
					})
			}
		}
	}

	const renderHeader = () => {
		return (
			<div className={styles['header']}>
				<div className={styles['header-top']}>
					<div>
						{' '}
						<span onClick={closeChat}>
							<MatImage height='24px' width='24px' style={{ cursor: 'pointer' }} src={IMAGES_SRC.BACK_BTN} alt='back'></MatImage>
						</span>
					</div>
					<div className={styles['header-top-content']}>Messages</div>
				</div>
				<div className={styles['header-content']}>
					<div>
						{participantProfilePicture ? (
							<MatImage className={styles['avatar']} src={participantProfilePicture} alt='.'></MatImage>
						) : (
							<Avatar className={styles['avatar']}>{participantName.substring(0, 1)}</Avatar>
						)}
					</div>
					<div className={styles['details']}>
						<div className={styles['top']}>
							<div>{participantName}</div>
							<div className={styles['icons']}>
								<span onClick={closeChat}>
									<MatImage className={styles['icon']} style={{ cursor: 'pointer' }} src={IMAGES_SRC.CROSS_ICON}></MatImage>
								</span>
								<span className={styles['icon-max-min']}>
									{' '}
									{maximize ? (
										<span
											onClick={() => {
												setMaximize(false)
											}}
										>
											{' '}
											<MatImage className={styles['icon']} style={{ cursor: 'pointer' }} src={IMAGES_SRC.MINIMIZE_ICON}></MatImage>{' '}
										</span>
									) : (
										<span
											onClick={() => {
												setMaximize(true)
											}}
										>
											{' '}
											<MatImage className={styles['icon']} style={{ cursor: 'pointer' }} src={IMAGES_SRC.MAXIMIZE_ICON}></MatImage>
										</span>
									)}{' '}
								</span>
							</div>
						</div>
						<div className={styles['bottom']}>
							<div> {projectName} </div>
							<div>
								{' '}
								{projectData?.enterprise_project
									? `Your Earnings:  ${configData.currency} ${bidAmount}`
									: profileType === 'PILOT'
										? budget === 0
											? `Budget: Negotiable | Your bid: ${configData.currency} ${bidAmount}`
											: `Budget: ${configData.currency} ${budget} | Your bid: ${configData.currency} ${bidAmount}`
										: `${participantName.split(' ')[0]}'s bid: ${configData.currency} ${bidAmount}`}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const renderDisableMsg = () => {
		if ((profileType === 'PILOT' && projectData.quote?.status === 'WITHDRAWN') || (profileType === 'CONSUMER' && selectedQuote?.status === 'WITHDRAWN')) {
			return profileType === 'CONSUMER' ? BID_WITHDRAWN_MESSAGE_CONSUMER : BID_WITHDRAWN_MESSAGE_PILOT
		} else if ((profileType === 'PILOT' && projectData.quote?.status === 'INVALID') || (profileType === 'CONSUMER' && selectedQuote?.status === 'INVALID')) {
			return BID_FAILED_MESSAGE
		} else if ((profileType === 'PILOT' && projectData.state === 'RESOLVED') || (profileType === 'CONSUMER' && consumerData.state === 'RESOLVED')) {
			return PROJECT_COMPLETED_MESSAGE
		} else if ((profileType === 'PILOT' && projectData.quote?.status === 'REJECTED') || (profileType === 'PILOT' && selectedQuote?.status === 'REJECTED')) {
			return PROJECT_REJECTED_MESSAGE
		}
	}

	return (
		<div className={styles['chat-box']} style={maximize ? { width: '500px', height: '700px' } : {}}>
			{renderHeader()}
			<div className={styles['body']} ref={messageScrollRef} onScroll={scrollHandler}>
				{loading ? <div style={{ textAlign: 'center' }}>Loading</div> : ''}
				{renderMessages()}
				{newMessage && !isScrolledTobottom ? (
					<div
						className={styles['unread-messages-bar']}
						onClick={() => {
							viewNewMessages()
						}}
					>
						Latest messages <DownOutlined style={{ position: 'relative', bottom: '3px' }} />
					</div>
				) : null}
				{(profileType === 'PILOT' &&
					(projectData.quote?.status === 'WITHDRAWN' ||
						projectData.quote?.status === 'INVALID' ||
						projectData.state === 'RESOLVED')) ||
					(profileType === 'CONSUMER' && (selectedQuote?.status === 'WITHDRAWN' || selectedQuote?.status === 'INVALID' || consumerData.state === 'RESOLVED')) ? (
					<div className={styles['chat-disable-msg-box']}>{renderDisableMsg()}</div>
				) : null}
				<div ref={messageScrollBottomRef} />
			</div>
			{!disableSendMessage ? (
				<div className={styles['footer']}>
					<div className={styles['text-box-holder']}>
						<TextArea
							ref={messageBoxRef}
							className={styles['text-box']}
							placeholder='Write a message'
							bordered={false}
							onPressEnter={sendMessage}
							value={messageBoxRef.current.value}
							autoSize={{ minRows: 1, maxRows: 3 }}
						/>
					</div>
					<div className={styles['button-holder']}>
						<Button type='primary' shape='round' className={styles['button']} onClick={sendMessage}>
							{' '}
							Send{' '}
						</Button>
					</div>
				</div>
			) : null}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		webConfig: state.UserSessionReducer.webConfig,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ChatBox)
