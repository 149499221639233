import { Input } from 'antd'
import React from 'react'
import { FREE_TRIAL_FIELD_METADATA } from '../../../Modules/MyPlans/constants'

export const FREE_TRIAL_FIELDS = [
	{
		fieldName: 'FNAME',
		fieldLabel: FREE_TRIAL_FIELD_METADATA.FNAME.label,
		fieldKey: FREE_TRIAL_FIELD_METADATA.FNAME.key,
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter first name!',
				},
			],
		},
		fieldToRender: <Input style={{ borderRadius: '8px' }} />,
	},
	{
		fieldName: 'LNAME',
		fieldLabel: FREE_TRIAL_FIELD_METADATA.LNAME.label,
		fieldKey: FREE_TRIAL_FIELD_METADATA.LNAME.key,
		fieldToRender: <Input style={{ borderRadius: '8px' }} />,
	},
]
