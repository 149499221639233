import { post, get, put, deleteMethod } from '../../Api/http-interceptor'
import { getProjectStatus } from '../../Services/GetProjectStatus'
import { setValueToStorage, getValueFromStorage, getConfigData } from '../../Services/LocalStorageService'
import { pushSuperPropertiesToMixpanel, pushEventToMixpanel, setUserIdentityToMixpanel, pushProfileToMixpanel } from '../../Services/MixpanelService'
import * as ACTION from './actionTypes'

export const logInApi = body => {
	return post('LOG_IN', body)
}

export const socialMediaLoginApi = body => {
	return post('SOCIAL_MEDIA_LOGIN', body)
}

export const getProfileApi = async () => {
	const hasProfileFetchedBefore = getValueFromStorage('hasProfileFetchedBefore')
	const profileType = getValueFromStorage('PROFILE_TYPE')
	const profileIdentifier = profileType === 'PILOT' ? 'Pilot ID' : 'Client ID'

	// Setting to localstorage that profile has been fetched once so event do't get fired multiple times to Mixpanel
	setValueToStorage('hasProfileFetchedBefore', true)

	const res = await get('GET_PROFILE')
	if (!hasProfileFetchedBefore) {
		// Setting identity in Mixpanel
		setUserIdentityToMixpanel(profileType + res.data?.id)

		// Pushing supper properties in Mixpanel
		pushSuperPropertiesToMixpanel({
			'User Type': profileType === 'PILOT' ? 'Pilot' : 'Client',
			[profileIdentifier]: res.data.id,
			Email: res.data.email,
			distinct_id: profileType + res.data?.id,
		})

		let profileData = {
			$first_name: res?.data?.firstName || '',
			$last_name: res?.data?.lastName || '',
			$email: res?.data?.email,
			$phone: res?.data?.mobileNumber,
			'Is ARN Verified': res?.data?.arnLicense?.verified,
			'Is Enterprise Pilot': res?.data?.enterprisePilot,
			'Is ID Verified': res?.data?.verified,
			'Notification Radius': res?.data?.preferences?.projectRadius,
			'Base Location': res?.data?.address?.addressDetails,
		}
		pushProfileToMixpanel(profileData)

		// Pushing event to Mixpanel
		let payload = {
			'Login Date Time': new Date().getTime(),
			'Log In Method': res?.data?.socialMediaChannel?.charAt(0).toUpperCase() + res?.data?.socialMediaChannel?.slice(1).toLowerCase(),
		}
		if (profileType !== 'PILOT') {
			payload['Client Type'] = res?.data?.subrole === 'CLIENT_STANDARD' ? 'Commercial' : 'Enterprise'

			setValueToStorage('clientType', res?.data?.subrole)
		}
		pushEventToMixpanel(profileType, 'Log In', payload)
	}

	return res
}

export const updateProfileApi = async body => {
	const profileType = getValueFromStorage('PROFILE_TYPE')

	const res = await post('UPDATE_PROFILE', body)

	// Sending profile picture event to Mixpanel
	if (body.mixpanel_type === 'profile_picture') {
		pushEventToMixpanel(profileType, ' Profile Picture', {
			'Has Profile Picture': res.status_code === 200,
		})
	}
	// Sending drone add event to Mixpanel
	else if (body.mixpanel_type === 'drone' && res.status_code === 200) {
		body.droneDetails.map(drone => {
			pushEventToMixpanel('', 'Drone Details', {
				'Drone Name': drone.droneType.replaceAll('_', ' '),
				'Drone Registration Number Added': drone.droneRegNum ? true : false,
			})
		})
	}
	// Sending base location (address) details event to Mixpanel
	else if (body.mixpanel_type === 'base_location') {
		pushEventToMixpanel('', 'Base Location', {
			[`${profileType === 'PILOT' ? 'Pilot' : 'Client'} Base Location Added`]: res.status_code === 200,
		})
		if (res?.status_code === 200) {
			pushProfileToMixpanel({
				'Base Location': body?.address?.addressDetails,
			})
		}
	}
	// Sending phone number event to Mixpanel
	else if (body.mixpanel_type === 'phone_number') {
		pushEventToMixpanel('', 'Phone Number', {
			'Phone Number Added': res.status_code === 200,
		})
		if (res?.status_code === 200) {
			pushProfileToMixpanel({
				$phone: body?.mobileNumber,
			})
		}
	}
	// Sending about you event to Mixpanel
	else if (body.mixpanel_type === 'about_you') {
		pushEventToMixpanel('', 'About You', {
			'Is About You Added': res.status_code === 200,
		})
	}
	// Sending ABN (Payment) event to Mixpanel
	else if (body.mixpanel_type === 'abn') {
		pushEventToMixpanel('', 'ABN', {
			'Is ABN Number Added': res.status_code === 200,
		})
	}
	// Sending project preferences updated event to Mixpanel
	else if (body.mixpanel_type === 'project_preferences_updated') {
		const oldPreferences = body?.oldProjectTypes?.sort()
		const newPreferences = body?.preferences?.projectType?.sort()

		pushEventToMixpanel('', 'Project Preferences Updated', {
			'Is Project Preference Updated': !oldPreferences.every((val, index) => val === newPreferences[index]),
			'Is Radius Updated': !(body.oldProjectRadius === body.preferences.projectRadius),
		})
		if (res?.status_code === 200) {
			pushProfileToMixpanel({
				'Notification Radius': body?.preferences?.projectRadius,
			})
		}
	}
	// Sending company details event to Mixpanel
	else if (body.mixpanel_type === 'company_details' && res.status_code === 200) {
		pushEventToMixpanel('', 'Company Details', {
			'Is Company Name Added': !!`${body.corporateDetails.companyName ?? ''}`.trim(),
			'Is Company Address Added': !!`${body.corporateDetails.companyAddress ?? ''}`.trim(),
			'Is ACN Added': !!`${body.corporateDetails.acnNumber ?? ''}`.trim(),
			'Is ABN Added': !!`${body.corporateDetails.abnNumber ?? ''}`.trim(),
			'Is GST Number Added': !!`${body.corporateDetails.gstNumber ?? ''}`.trim(),
		})
	}

	return res
}

export const forgotPasswordApi = async body => {
	const profileType = getValueFromStorage('PROFILE_TYPE')

	const res = await post('FORGOT_PASS', body)

	// Mixpanel event for forgot password
	pushEventToMixpanel(profileType, ' - Forgot Password', {
		'User Type': profileType === 'PILOT' ? 'Pilot' : 'Client',
		Email: body.email,
		'Recovery Email Sent Status': res.status_code === 200,
	})
	return res
}

export const changePasswordApi = async body => {
	const res = await post('CHANGE_PASSWORD', body)

	// Sending password updated event to Mixpanel
	if (body.mixpanel_type === 'password_updated')
		pushEventToMixpanel('', 'Password Updated', {
			'Is Succesful Update': res.status_code === 200,
		})

	return res
}

export const getCountriesApi = () => {
	return get('GET_COUNTRIES')
}

export const getCitiesApi = queryParams => {
	return get('GET_CITIES', null, queryParams)
}

export const getStatesApi = () => {
	return get('GET_STATES')
}

export const getAllDroneTypesApi = () => {
	return get('GET_ALL_DRONES')
}

export const getAllCasaLicenseTypesApi = () => {
	return get('GET_ALL_CASA_LICENSE_TYPES')
}

export const verifyWithDvsApi = body => {
	return post('VERIFY_WITH_DVS', body)
}

export const updateBaseLocationApi = async body => {
	const profileType = getValueFromStorage('PROFILE_TYPE')

	const res = await put('UPDATE_BASE_LOCATION', body)

	// For sending profile picture event
	if (body.mixpanel_type === 'profile_picture') {
		pushEventToMixpanel(profileType, ' Profile Picture', {
			[`${profileType === 'PILOT' ? 'Pilot' : 'Client'} Profile Picture`]: res.status_code === 200,
		})
	}
	// For sending base location (address) details event
	else if (body.mixpanel_type === 'base_location') {
		pushEventToMixpanel('', 'Base Location', {
			[`${profileType === 'PILOT' ? 'Pilot' : 'Client'} Base Location Added`]: res.status_code === 200,
		})
		if (res?.status_code === 200) {
			pushProfileToMixpanel({
				'Base Location': body?.address?.addressDetails,
				'Notification Radius': body?.preferences?.projectRadius,
			})
		}
	}

	return res
}

export const deleteCasaLicenseApi = async queryParams => {
	const res = await deleteMethod('DELETE_CASA_LICENSE', null, queryParams)

	pushEventToMixpanel('', `CASA License Deleted - ${queryParams.licenseType}`, {
		[`CASA License - ${queryParams.licenseType} Deleted`]: res.status_code === 200,
	})

	return res
}

export const addCasaLicenseApi = async body => {
	const res = await post('ADD_CASA_LICENSE', body)
	// const licenseType = body.get('type').replaceAll('_', ' ') // todo: Ask Dharmik to add cases for internationalisation

	// pushEventToMixpanel('', `CASA License - ${licenseType}`, {
	// 	[`CASA License - ${licenseType} Added`]: res.status_code === 200,
	// })

	return res
}

export const addArnDetailsApi = async body => {
	const res = await post('ADD_ARN_DETAILS', body)

	// Sending ASN details event to Mixpanel
	pushEventToMixpanel('', 'ARN Details', {
		'Is ARN Details Added': res.status_code === 200,
	})

	return res
}

export const sendReferralInviteApi = body => {
	return post('SEND_REFERRAL_INVITE', body)
}

export const verifySharableLinkApi = queryParams => {
	return get('VERIFY_SHARABLE_LINK', null, queryParams)
}

export const getSocialAuthApi = async body => {
	return post('GET_SOCIAL_AUTH', body)
}

export const getOtpApi = body => {
	return post('GET_OTP', body)
}

export const getEmailOtpApi = body => {
	return post('GET_EMAIL_OTP', body)
}

export const getEmailAndMobileOtpApi = body => {
	return post('GET_EMAIL_AND_MOBILE_OTP', body)
}

export const verifyEmailAndMobileOtpApi = body => {
	return post('VERIFY_EMAIL_AND_MOBILE_OTP', body)
}

export const rejectEnterprisePilotPromptApi = () => {
	return put('REJECT_ENTERPRISE_PILOT_PROMPT')
}

export const createEnterprisePilotApi = async (body, mixpanelData) => {
	const res = await post('CREATE_ENTERPRISE_PILOT', body)

	// Sending Drone Insurance event to Mixpanel
	if (mixpanelData.mixpanel_type === 'drone_insurance' && res.status_code === 200) {
		pushEventToMixpanel('', 'Drone Insurance', {
			'Is Drone Insurance Added': true,
			'Are Enterprise Project Specialized Domains Added': mixpanelData.preferenceData.specialities.filter(el => el.active).length > 0,
		})
	}

	return res
}

export const getEnterprisePilotDataApi = () => {
	return get('GET_ENTERPRISE_PILOT_DATA')
}

export const updateEnterprisePilotApi = async body => {
	const res = await put('UPDATE_ENTERPRISE_PILOT', body.body)

	// Sending enterprise project preferences updated event to Mixpanel
	const oldSpecialities = body.enterprisePilotData?.specialities
		?.filter(el => el.active)
		?.map(el => el.name)
		?.sort()
	const newSpecialities = res.data.specialities
		?.filter(el => el.active)
		?.map(el => el.name)
		?.sort()

	if (body.mixpanel_type === 'enterprise_project_preferences_updated' && res.status_code === 200) {
		pushEventToMixpanel('', 'Enterprise Project Preferences Updated', {
			'Is Vacation Mode Updated': body.enterprisePilotData.vacationMode !== res.data.vacationMode,
			'Vacation Mode': res.data.vacationMode,
			'Specialized Domains Updated': !oldSpecialities.every((val, index) => val === newSpecialities[index]),
			'Is Weekday Preference Turned On': res.data.daywiseAvailabilities[0].active,
			'Is Weekend Preference Turned On': res.data.daywiseAvailabilities[1].active,
			'Is Weekday Prefered Time Updated':
				res.data.preferredContactTimings[0].startTime !== body.enterprisePilotData.preferredContactTimings[0].startTime ||
				res.data.preferredContactTimings[0].endTime !== body.enterprisePilotData.preferredContactTimings[0].endTime,
			'Is Weekend Prefered Time Updated':
				res.data.preferredContactTimings[1].startTime !== body.enterprisePilotData.preferredContactTimings[1].startTime ||
				res.data.preferredContactTimings[1].endTime !== body.enterprisePilotData.preferredContactTimings[1].endTime,
			'Is Weekday Preferred Contact Time Turned On': res.data.preferredContactTimings[0].active,
			'Is Weekend Preferred Contact Time Turned On': res.data.preferredContactTimings[1].active,
		})
	}

	return res
}

export const deleteDroneApi = pathParams => {
	return deleteMethod('DELETE_DRONE', pathParams)
}

export const updateEmailApi = body => {
	return post('UPDATE_EMAIL', body)
}

export const addCollaboratorApi = body => {
	return post('ADD_COLLABORATOR', body)
}

export const getOrdersNotOfCollaboratorApi = queryParams => {
	return get('GET_ORDERS_NOT_OF_COLLABORATOR', null, queryParams)
}

export const deleteCollaboratorApi = queryParams => {
	return deleteMethod('DELETE_COLLABORATOR', null, queryParams)
}

export const updateCollaboratorPermissionsApi = body => {
	return post('UPDATE_COLLABORATOR_PERMISSIONS', body)
}

export const contactSupportApi = body => {
	return post('CONTACT_SUPPORT', body)
}

export const getPilotPortfolioApi = async (pathParams, mixpanelData) => {
	const res = await get('GET_PILOT_PORTFOLIO', pathParams)

	// Sending Pilot Profile Viewed event to Mixpanel
	if (mixpanelData?.mixpanel_type === 'pilot_profile_viewed' && res.status_code === 200) {
		pushEventToMixpanel('', 'Pilot Profile Viewed', {
			'Project ID': mixpanelData.consumerProjectData.projectId,
			'Bid Amount': mixpanelData.bidAmount,
			'Project Status': getProjectStatus('COMMERCIAL', mixpanelData.consumerProjectData.state, mixpanelData.consumerProjectData.quote?.status ?? ''),
		})
	}

	return res
}

export const getPortfolioUploadUrlApi = async body => {
	const res = await post('GET_PORTFOLIO_UPLOAD_URL', body)

	// Sending portfolio event to Mixpanel
	if (body.mixpanel_type === 'portfolio' && res.status_code === 200) {
		pushEventToMixpanel('', 'Portfolio', {
			Images: body.type === 'PHOTO' ? body.imageLength + 1 : body.imageLength,
			Videos: body.type === 'VIDEO' ? body.videoLength + 1 : body.videoLength,
		})
	}

	return res
}

export const deletePortfolioMediaApi = async queryParams => {
	const res = await deleteMethod('DELETE_PORTFOLIO_MEDIA', null, queryParams)

	// Sending portfolio event to Mixpanel
	if (queryParams.mixpanel_type === 'portfolio' && res.status_code === 200) {
		pushEventToMixpanel('', 'Portfolio', {
			Images: queryParams.type === 'PHOTO' ? queryParams.imageLength - 1 : queryParams.imageLength,
			Videos: queryParams.type === 'VIDEO' ? queryParams.videoLength - 1 : queryParams.videoLength,
		})
	}

	return res
}

export const updateCasaLicenseApi = async body => {
	const res = await put('UPDATE_CASA_LICENSE', body)
	// const licenseType = body.get('type').replaceAll('_', ' ')

	// pushEventToMixpanel('', `CASA License - ${licenseType}`, {
	// 	[`CASA License - ${licenseType} Added`]: res.status_code === 200,
	// })

	return res
}

export const recoverPilotAccountApi = body => {
	return post('RECOVER_PILOT_ACCOUNT', body)
}

export const getChatAccessTokenApi = () => {
	return get('GET_CHAT_ACCESS_TOKEN')
}

// ENTERPRISE PLUS PILOT APIS
export const getEnterprisePlusContractApi = pathParams => {
	return get('GET_ENTERPRISE_PLUS_CONTRACT_API', pathParams)
}

export const cancelEnterprisePlusPromptApi = () => {
	return put('CANCEL_ENTERPRISE_PLUS_PROMPT')
}

export const completePilotEnterprisePlusApi = body => {
	return put('COMPLETE_PILOT_ENTERPRISE_PLUS', body)
}

export const updateDeviceTokenApi = body => {
	return put(ACTION.UPDATE_DEVICE_TOKEN, body)
}

export const getAllNotificationsApi = queryParams => {
	return get(ACTION.GET_ALL_NOTIFICATIONS, null, queryParams)
}

export const updateNotificationReadStatusApi = body => {
	return put(ACTION.UPDATE_NOTIFICATION_READ_STATUS, body)
}

export const passedQuizApi = body => {
	return post(ACTION.PASSED_QUIZ, body)
}

export const getAllLicenseTypesApi = () => {
	return get(ACTION.GET_ALL_LICENSE_TYPES)
}

export const getAllDroneInsuranceTypesApi = () => {
	return get(ACTION.GET_ALL_DRONE_INSURANCE_TYPES)
}

export const addDroneInsuranceApi = body => {
	return post(ACTION.ADD_DRONE_INSURANCE, body)
}

export const getAllPurchasedInsuranceApi = () => {
	return get(ACTION.GET_ALL_PURCHASED_INSURANCE)
}

export const deleteDroneInsuranceApi = queryParams => {
	return deleteMethod(ACTION.DELETE_DRONE_INSURANCE, null, queryParams)
}

export const updateDroneInsuranceApi = body => {
	return post(ACTION.UPDATE_DRONE_INSURANCE, body)
}

export const reportErrorApi = body => {
	return post(ACTION.REPORT_ERROR, body)
}

export const getWebConfigApi = async () => {
	return await getConfigData()
}
