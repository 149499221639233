import { CheckCircleFilled, CloseCircleFilled, DeleteOutlined, InfoCircleFilled, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Card, DatePicker, Divider, Form, Input, Select, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import MatImage from '../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../AeroComponents/Image/image.constants'
import AeroModal from '../../../../AeroComponents/Modal'
import { NZ_QUIZ_RULES } from '../../constants'
import NzCaaLicense from './nz-caa-license'
import NzQuiz from './nz-quiz'
import NzOtherLicenses from './nz-other-license'
import moment from 'moment'

const NzLicensesTab = props => {
	const {
		quizModal,
		toggleQuizModal,
		startQuiz,
		quizStarted,
		currentIndex,
		captureResponse,
		quizNextDissabled,
		goBack,
		quizCompleteModal,
		retakeModal,
		toggleQuizCompleteModal,
		toggleRetakeModal,
		wrongAnswers,
		retakeQuiz,
		quizConfirmationModal,
		toggleQuizConfirmationModal,
		leaveQuiz,
		addCaaModal,
		toggleAddCaaModal,
		caaUploadProps,
		fileList,
		p101TestTaken,
		submitCaaLicense,
		caaLicenseRef,
		caaLicenseData,
		toggleDeleteLicenseModal,
		deleteLicenseModal,
		callDeleteLicense,
		addOtherNzLicenseModal,
		toggleAddOtherNzLicenseModal,
		openOtherNzLicenseModal,
		allLicenseTypes,
		handleLicenseChange,
		selectedLicense,
		addLicense,
		otherLicenseData,
		toggleRetryModal,
		retryLicense,
		deleteDoc,
		clearCaaNumberRef,
		currentResponse,
		updateDocState,
	} = props
	const [form] = Form.useForm()
	const [retrySubTypes, setRetrySubTpes] = useState(null)
	const disabledDate = current => {
		// Can not select days before today and today
		return current && current < dayjs().endOf('day')
	}
	const uploadProps = caaUploadProps()

	useEffect(() => {
		if (retryLicense) {
			form.setFieldsValue({
				licenseType: retryLicense.licenseType,
				subtype: retryLicense.licenseSubType,
				number: retryLicense.licenseNumber,
				expiryDate: retryLicense.expiryDate ? moment.utc(retryLicense.expiryDate).local() : null,
			})
			if (addOtherNzLicenseModal) {
				const index = allLicenseTypes.findIndex(item => item.value === retryLicense.licenseType)
				const subtypes = allLicenseTypes[index].subtype.map(item => {
					return { value: item.name, label: item.label, present: item.present, subtype: item.subtype, type: item.type, disabled: item.present }
				})
				setRetrySubTpes(subtypes)
			}
			updateDocState('retryNzLicense')
		} else {
			form.setFieldsValue({
				licenseType: null,
				subtype: null,
				number: null,
				expiryDate: null,
			})
			updateDocState('init')
		}
	}, [retryLicense, addOtherNzLicenseModal])

	useEffect(() => {
		if (caaLicenseData) {
			updateDocState('retryNzCaa')
		} else {
			updateDocState('init')
		}
	}, [addCaaModal, caaLicenseData])

	return (
		<>
			<div style={{ fontSize: '16px', color: '#47464A' }}>Licenses</div>
			<Card style={{ marginTop: '20px' }}>
				{p101TestTaken ? (
					<div style={{ color: '#47464A' }}>
						Part 101 Rules{' '}
						<span style={{ color: '#6CBE44', border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: '8px', padding: '5px', fontSize: '12px', marginLeft: '10px' }}>
							<CheckCircleFilled style={{ position: 'relative', bottom: '3px', marginRight: '4px' }} /> Verified
						</span>
					</div>
				) : (
					<>
						<div className='submod-card-header'>
							<div>
								<div style={{ marginBottom: '10px', color: '#47464A' }}>Part 101 Rules</div>
								<div style={{ marginBottom: '10px', color: '#84818A' }}>In order to fly legally in New Zealand you need to know the Part 101 rules</div>
								<div className='nz-license-box-wrap'>
									<InfoCircleFilled style={{ position: 'relative', bottom: '3px' }} /> Kindly visit rules before taking the test
								</div>
							</div>
							<MatImage alt='nz-lice' src={IMAGES_SRC.NZ_QUIZ_ICON} />
						</div>
						<div className='submod-card-header-btn'>
							<Button onClick={toggleQuizModal} style={{ width: '160px', height: '48px', marginTop: '10px' }} type='primary'>
								Take Test
							</Button>
						</div>
					</>
				)}
			</Card>
			<Card style={{ marginTop: '15px' }}>
				<div className='submod-card-header'>
					<div>
						<div style={{ marginBottom: '10px', color: '#47464A' }}>Licenses/ Certificates</div>
						<div style={{ color: '#84818A' }}>
							Jobs will be allocated to you based on the qualifications of your License. You can apply for or upgrade your License here
						</div>
					</div>
					<Button onClick={openOtherNzLicenseModal} className='submod-card-header-btn' type='primary'>
						<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} /> Add License
					</Button>
				</div>
				<Divider></Divider>
				{otherLicenseData.length ? (
					<NzOtherLicenses otherLicenseData={otherLicenseData} toggleDeleteLicenseModal={toggleDeleteLicenseModal} toggleRetryModal={toggleRetryModal} />
				) : (
					<div style={{ textAlign: 'center', fontSize: '16px', color: '#84818A' }}>No license has been added yet</div>
				)}
			</Card>
			<Card style={{ marginTop: '15px' }}>
				<div className='submod-card-header'>
					<div>
						<div style={{ marginBottom: '10px', color: '#47464A' }}>CAA License Number</div>
						<div style={{ color: '#84818A' }}>
							Jobs will be allocated to you based on the qualifications of your License. You can apply for or upgrade your License here
						</div>
					</div>
					{caaLicenseData ? (
						<></>
					) : (
						<Button className='submod-card-header-btn' onClick={toggleAddCaaModal} type='primary'>
							<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} /> Add License
						</Button>
					)}
				</div>
				<Divider></Divider>
				{caaLicenseData ? (
					<NzCaaLicense caaLicenseData={caaLicenseData} toggleDeleteLicenseModal={toggleDeleteLicenseModal} toggleRetryModal={toggleRetryModal} />
				) : (
					<div style={{ textAlign: 'center', fontSize: '16px', color: '#84818A' }}>No license has been added yet</div>
				)}
			</Card>
			<AeroModal title={quizStarted ? 'Part 101 rules test' : 'Rules for test'} footer={false} visible={quizModal} onCancel={toggleQuizModal}>
				{quizStarted ? (
					<NzQuiz currentResponse={currentResponse} currentIndex={currentIndex} captureResponse={captureResponse} />
				) : (
					<>
						<ol style={{ textAlign: 'justify' }}>
							{NZ_QUIZ_RULES.map((item, key) => (
								<li key={key}>
									{key === 6 ? (
										<>
											If you get any answer wrong, you can visit
											<a
												target='_blank'
												rel='noopener noreferrer'
												style={{ color: '#00B3FF', marginLeft: '5px', marginRight: '5px' }}
												href='https://www.aviation.govt.nz/rules/rule-part/show/101'
											>
												here
											</a>
											to learn more about the latest rules and guidelines to become a drone pilot in New Zealand.
										</>
									) : (
										item
									)}
								</li>
							))}
						</ol>
						<p style={{ textAlign: 'center' }}>Good Luck!</p>
					</>
				)}
				<div style={{ textAlign: 'center' }}>
					{currentIndex === 0 ? (
						<Button disabled={quizNextDissabled} onClick={startQuiz} type='primary' style={{ width: '70%', height: '50px', margin: '15px' }}>
							{quizStarted ? 'Next' : 'Start Test'}
						</Button>
					) : (
						<div>
							<Button onClick={goBack} style={{ width: '40%', height: '40px', margin: '15px' }}>
								Go Back
							</Button>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<Button disabled={quizNextDissabled} onClick={startQuiz} type='primary' style={{ width: '40%', height: '40px', margin: '15px' }}>
								{currentIndex === 5 ? 'Submit' : 'Next'}
							</Button>
						</div>
					)}
				</div>
			</AeroModal>
			<AeroModal footer={false} visible={retakeModal} onCancel={toggleRetakeModal}>
				<div style={{ textAlign: 'center' }}>
					<MatImage style={{ margin: '20px' }} alt='wrong-quiz' src={IMAGES_SRC.WRONG_QUIZ_ICON} />
					<div style={{ fontSize: '20px', marginBottom: '15px' }}>Oops! You didn't clear the test</div>
					<div style={{ fontSize: '16px', color: '#84818A' }}>
						You did not answer all question correctly. <br />
						To pass this test you must answer all questions correctly
					</div>
					<div style={{ fontSize: '16px', color: '#84818A', marginTop: '15px' }}>
						Please visit this{' '}
						<a rel='noopener noreferrer' href='https://www.aviation.govt.nz/rules/rule-part/show/101' target='_blank' style={{ color: '#00B3FF' }}>
							link{' '}
						</a>{' '}
						to equip yourself with latest rules and guidelines
					</div>
				</div>
				<div style={{ marginTop: '30px', margin: '15px' }}>
					<div style={{ color: '#84818A', marginBottom: '15px' }}>The questions which you have answered wrong:</div>
					{wrongAnswers.map(item => (
						<div key={item.key} style={{ marginBottom: '10px' }}>
							{item.key}. {item.question}
						</div>
					))}
				</div>
				<div style={{ textAlign: 'center', marginTop: '25px' }}>
					<Button onClick={retakeQuiz} type='primary' style={{ width: '70%', height: '50px', margin: '15px' }}>
						Re-take Test
					</Button>
				</div>
			</AeroModal>
			<AeroModal footer={false} visible={quizCompleteModal} onCancel={toggleQuizCompleteModal}>
				<div style={{ textAlign: 'center' }}>
					<MatImage style={{ margin: '20px' }} alt='wrong-quiz' src={IMAGES_SRC.CONGRATS_ICON} />
					<div style={{ fontSize: '20px', marginBottom: '15px' }}>Congratulations!</div>
					<div style={{ fontSize: '16px', color: '#84818A', margin: '15px' }}>You have passed the test and you are now eligible to fly drones in NewZealand</div>
				</div>
				<div style={{ textAlign: 'center', marginTop: '25px' }}>
					<Button onClick={toggleQuizCompleteModal} type='primary' style={{ width: '70%', height: '50px', margin: '15px' }}>
						Okay, Got It
					</Button>
				</div>
			</AeroModal>
			<AeroModal footer={false} visible={quizConfirmationModal} onCancel={toggleQuizConfirmationModal}>
				<div style={{ textAlign: 'center' }}>
					<MatImage style={{ margin: '20px' }} alt='wrong-quiz' src={IMAGES_SRC.BIG_RED_EXCLAMATION_ICON} />
					<div style={{ fontSize: '20px', marginBottom: '15px' }}>Do you want to leave test?</div>
					<div style={{ fontSize: '16px' }}>All progress will be lost</div>
				</div>
				<div style={{ textAlign: 'center', marginTop: '25px' }}>
					<Button onClick={toggleQuizConfirmationModal} type='primary' style={{ width: '40%', height: '50px', margin: '15px' }}>
						Go Back
					</Button>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<Button onClick={leaveQuiz} style={{ width: '40%', height: '50px', margin: '15px' }}>
						Leave Test
					</Button>
				</div>
			</AeroModal>
			<AeroModal footer={false} visible={addCaaModal} title='Add CAA License Number' onCancel={toggleAddCaaModal}>
				<Form layout='vertical' encType='multipart/form-data'>
					<Form.Item label='License Number' style={{ marginBottom: '25px' }}>
						<Input
							ref={caaLicenseRef}
							allowClear={{ clearIcon: <CloseCircleFilled style={{ position: 'relative', bottom: '2px' }} ref={clearCaaNumberRef} /> }}
							defaultValue={caaLicenseData ? caaLicenseData.licenseNumber : null}
							style={{ borderRadius: '8px', height: '48px' }}
							placeholder='Type here'
						/>
					</Form.Item>
					<Form.Item label='Upload License (jpeg, png or PDF)' style={{ marginBottom: '25px' }}>
						{caaLicenseData && caaLicenseData.imageURL && caaLicenseData.imageURL !== 'changed' ? (
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Button type='link' href={caaLicenseData.imageURL} target='_blank'>
									Click to download
								</Button>
								<Button onClick={() => deleteDoc('caa')} type='text'>
									<DeleteOutlined style={{ position: 'relative', bottom: '3px' }} />
								</Button>
							</div>
						) : (
							<Upload {...uploadProps}>
								<Button style={{ height: '48px', width: '100%' }}>Select File</Button>
							</Upload>
						)}
					</Form.Item>
				</Form>
				<Button
					onClick={submitCaaLicense}
					disabled={caaLicenseData ? (caaLicenseData.imageURL || fileList.length !== 0 ? false : true) : fileList.length === 0 ? true : false}
					block
					type='primary'
					style={{ height: '48px' }}
				>
					Submit for Verification
				</Button>
			</AeroModal>
			<AeroModal visible={deleteLicenseModal} onCancel={toggleDeleteLicenseModal} footer={false}>
				<div style={{ textAlign: 'center' }}>
					<MatImage src={IMAGES_SRC.BIN_ICON} />
					<div style={{ padding: '10px' }}>
						<h2>Delete License?</h2>
					</div>
					<div>
						License <strong>{selectedLicense ? selectedLicense.licenseNumber : caaLicenseData?.licenseNumber}</strong> will be deleted
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
						<Button style={{ height: '48px', width: '45%' }} key='submit' type='primary' onClick={callDeleteLicense}>
							Delete
						</Button>
						<Button style={{ height: '48px', width: '45%' }} key='back' onClick={toggleDeleteLicenseModal}>
							Cancel
						</Button>
					</div>
				</div>
			</AeroModal>
			{allLicenseTypes && allLicenseTypes.length ? (
				<AeroModal footer={false} visible={addOtherNzLicenseModal} title='Add License' onCancel={toggleAddOtherNzLicenseModal}>
					<Form form={form} onFinish={addLicense} layout='vertical' encType='multipart/form-data'>
						<Form.Item
							name='licenseType'
							rules={[{ required: true, message: 'Please select the license/certificate type!' }]}
							label='Select License/ Certificate type'
							style={{ marginBottom: '25px' }}
						>
							<Select size='large' onChange={handleLicenseChange} options={allLicenseTypes} />
						</Form.Item>
						{(retryLicense && retryLicense.licenseSubType) || (selectedLicense && selectedLicense.subtype?.length) ? (
							<Form.Item
								name='subtype'
								rules={[{ required: selectedLicense && selectedLicense.subtype.length, message: 'Please select the type!' }]}
								label='Type'
								style={{ marginBottom: '25px' }}
							>
								<Select size='large' options={retryLicense ? retrySubTypes : selectedLicense?.subtype} />
							</Form.Item>
						) : null}
						<Form.Item
							name='number'
							rules={[{ required: true, whitespace: true, message: 'Please enter the license/certificate number!' }]}
							label={selectedLicense?.type === 'CERTIFICATE' ? 'Certificate Number' : 'License Number'}
							style={{ marginBottom: '25px' }}
						>
							<Input size='large' style={{ borderRadius: '8px' }} placeholder='Type here' />
						</Form.Item>
						{selectedLicense?.value === 'P61' || (retryLicense && retryLicense.licenseType === 'P61') ? null : (
							<Form.Item
								name='expiryDate'
								rules={[
									{
										required: selectedLicense?.value === 'P141' || (retryLicense && retryLicense.licenseType === 'P141') ? false : true,
										message: 'Please select the expiry date!',
									},
								]}
								label={selectedLicense?.type === 'CERTIFICATE' ? 'Certificate Expiry Date' : 'License Expiry Date'}
								style={{ marginBottom: '25px' }}
							>
								<DatePicker size='large' disabledDate={disabledDate} style={{ borderRadius: '8px', width: '100%' }} />
							</Form.Item>
						)}
						<Form.Item
							label={selectedLicense?.type === 'CERTIFICATE' ? 'Upload Certificate (jpeg, png or PDF)' : 'Upload License (jpeg, png or PDF)'}
							style={{ marginBottom: '25px' }}
						>
							{retryLicense && retryLicense.imageURL && retryLicense.imageURL !== 'changed' ? (
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<Button type='link' href={retryLicense.imageURL} target='_blank'>
										Click to download
									</Button>
									<Button onClick={() => deleteDoc('other')} type='text'>
										<DeleteOutlined style={{ position: 'relative', bottom: '3px' }} />
									</Button>
								</div>
							) : (
								<Upload style={{ width: '100%' }} {...uploadProps}>
									<Button size='large' style={{ width: '100%' }}>
										Select File
									</Button>
								</Upload>
							)}
						</Form.Item>
						<Button
							htmlType='submit'
							disabled={retryLicense ? (retryLicense.imageURL || fileList.length !== 0 ? false : true) : fileList.length === 0 ? true : false}
							block
							type='primary'
							style={{ height: '48px' }}
						>
							Submit for Verification
						</Button>
					</Form>
				</AeroModal>
			) : null}
		</>
	)
}

export default NzLicensesTab
