import React from 'react'
import { Document, Page, Text, StyleSheet, Font, Image, View } from '@react-pdf/renderer'
import moment from 'moment'

// Create styles
Font.register({
	family: 'Red Hat Display',
	src: 'https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&display=swap',
})

const styles = StyleSheet.create({
	body: {
		paddingTop: 70,
		paddingBottom: 65,
		paddingHorizontal: 50,
	},
	mainTitle: {
		fontSize: 14,
		fontWeight: '700',
		width: '100%',
		fontFamily: 'Helvetica-Bold',
	},
	mainTitleDescription: {
		fontSize: 10,
		width: '100%',
		marginTop: '10px',
		lineHeight: '1.5px',
	},
	subTitle: {
		fontSize: 10,
		margin: '12 0 0 0',
		fontFamily: 'Helvetica-Bold',
	},
	subTitleDescription: {
		fontSize: 10,
		width: '100%',
		marginTop: '10px',
		lineHeight: '1.5px',
	},
	children: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '10px',
		fontFamily: 'Helvetica-Bold',
	},
	integerIndex: {
		fontSize: 10,
		marginRight: '30px',
	},
	integerTitle: {
		fontSize: 10,
		width: '100%',
	},
	childrenIndexData: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '18px',
	},
	childrenIntegerIndex: {
		fontSize: 10,
		marginRight: '30px',
	},
	childrenIntegerDescription: {
		fontSize: 10,
		width: '100%',
	},
	subChildrenIndexData: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '10px',
	},
	subChildrenIntegerIndex: {
		fontSize: 9.5,
		marginLeft: '40px',
		marginRight: '30px',
	},
	subChildrenIntegerDescription: {
		fontSize: 9.5,
		width: '100%',
	},
	image: {
		width: '200px',
		height: '70px',
		objectFit: 'contain',
	},
	imageView: {
		width: '150px',
		height: '70px',
		border: '1px solid #eceaee',
		borderRadius: '12px',
		marginBottom: '16px',
	},
	signAndDate: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	dateAndTime: {
		fontSize: 12,
		display: 'flex',
		width: '150px',
		height: '70px',
		textAlign: 'center',
		border: '1px solid #eceaee',
		borderRadius: '12px',
		marginBottom: '16px',
		paddingTop: '27px',
	},
	nameOfAuthorizedSignature: {
		fontSize: 12,
		display: 'flex',
		width: '150px',
		height: '70px',
		textAlign: 'center',
		border: '1px solid #eceaee',
		borderRadius: '12px',
		marginBottom: '16px',
		paddingTop: '27px',
	},
	textForSignAndDate: {
		fontSize: 10,
		textAlign: 'center',
		width: '150px',
		marginTop: 10,
		fontFamily: 'Helvetica-Bold',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 10,
		bottom: 30,
		left: 50,
		right: 0,
		textAlign: 'center',
		color: 'grey',
		borderTop: '1px solid #000',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		paddingTop: '10px',
	},
})

// ***************REACT PDF-RENDERER****************
const MyDocument = ({ contractObj, signData, profileData }) => {
	const obj = contractObj
	const revisedDate = moment().format('YYYY-MM-DD')

	// CREATING A CONTRACT
	const getContractData = obj => {
		return (
			obj &&
			Object.keys(obj).map(el => (
				<>
					{el === 'mainTitle' && (
						<>
							<Text style={styles.mainTitle}>{obj[el].title}</Text>
							{obj[el].description.length > 0 && obj[el].description.map(el => <Text style={styles.mainTitleDescription}>{el}</Text>)}
						</>
					)}
					{el === 'subTitle' && (
						<>
							<Text style={styles.subTitle}>{obj[el].title}</Text>
							{obj[el].description.length > 0 && obj[el].description.map(el => <Text style={styles.subTitleDescription}>{el}</Text>)}
						</>
					)}
					{el === 'subData' && (
						<>
							<Text style={styles.subTitle}>{obj[el].title}</Text>
							{obj[el].children.length > 0 &&
								obj[el].children.map((el, i) => (
									<>
										<View style={styles.children}>
											<Text style={styles.integerIndex}>{i + 1}.</Text>
											<Text style={styles.integerTitle}>{el.title}</Text>
										</View>
										{el.children?.length > 0 &&
											el.children.map((child, j) => (
												<>
													<View style={styles.childrenIndexData}>
														<Text style={styles.childrenIntegerIndex}>
															{i + 1}.{j + 1}
														</Text>
														<Text style={styles.childrenIntegerDescription}>{child.description}</Text>
													</View>
													{child.children?.length > 0 &&
														child.children.map((children, k) => (
															<>
																<View style={styles.subChildrenIndexData}>
																	<Text style={styles.subChildrenIntegerIndex}>({getNextChar(97 + k)})</Text>
																	<Text style={styles.subChildrenIntegerDescription}>{children.description}</Text>
																</View>
															</>
														))}
												</>
											))}
									</>
								))}
						</>
					)}
				</>
			))
		)
	}

	// FOR GETTING NECT CHARACTER
	const getNextChar = (char = 97) => {
		return String.fromCharCode(char)
	}

	return (
		<Document>
			<Page size='A4' style={styles.body}>
				{getContractData(obj)}
				<View style={styles.signAndDate} break>
					<View
						style={{
							display: 'flex',
							flexDirection: 'column',
							// width: '50%',
							marginLeft: 10,
						}}
					>
						<Text style={styles.nameOfAuthorizedSignature}>
							{(profileData?.firstName && profileData?.lastName && `${profileData.firstName} ${profileData.lastName}`.toUpperCase()) ?? ''}
						</Text>
						<Text style={styles.textForSignAndDate}>Name of Authorized Signatory</Text>
					</View>
					<View
						style={{
							display: 'flex',
							flexDirection: 'column',
							// width: '50%',
							marginLeft: 10,
						}}
					>
						<View style={styles.imageView}>{signData?.eSignPreview && <Image src={signData?.eSignPreview ?? ''} />} </View>
						<Text style={styles.textForSignAndDate}>Signature</Text>
					</View>
					<View
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end',
							marginRight: 10,
						}}
					>
						<Text style={styles.dateAndTime}>{signData?.eSignDateAndTime ?? ''}</Text>
						<Text style={styles.textForSignAndDate}>Date & Time</Text>
					</View>
				</View>
				<Text style={styles.pageNumber}>Revised({revisedDate})</Text>
			</Page>
		</Document>
	)
}
// *************************************************

export default MyDocument
