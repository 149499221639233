import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { EditOutlined } from '@ant-design/icons'

const OtpComponent = props => {
	const { phoneNumber, otpInput1, otpInput2, otpInput3, otpInput4, storeOtpInput, sendCodeBtn, editPhoneNumber, getVerificationCode, type, fireInitiation } = props

	const [timerValue, setTimerValue] = useState('03:00')
	const [resendBtnEnabled, setResendBtnEnabled] = useState(true)

	const changeFormate = name => {
		if (name < 10) {
			name = '0' + name
		}
		return name
	}

	const timer = () => {
		const countDownDate = new Date().getTime() + 1 * 60000
		const x = setInterval(function() {
			const now = new Date().getTime()
			const distance = countDownDate - now
			let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
			let seconds = Math.floor((distance % (1000 * 60)) / 1000)
			const timerValue = changeFormate(minutes) + ':' + changeFormate(seconds)
			setTimerValue(timerValue)
			if (distance < 0) {
				clearInterval(x)
				setTimerValue('00:00')
				setResendBtnEnabled(false)
			}
		}, 1000)
	}

	useEffect(() => timer(), [])

	const resendCheckAndFire = () => {
		timer()
		setResendBtnEnabled(true)
		fireInitiation()
	}

	return (
		<>
			<div style={{ textAlign: 'left', fonttSize: '13px' }}>
				Enter verification code sent to <strong>{phoneNumber}</strong>{' '}
				<Button onClick={editPhoneNumber} type='link'>
					<EditOutlined style={{ position: 'relative', bottom: '3px', right: '2px' }} />
				</Button>
			</div>
			<div>
				<Input.Group size='large'>
					<Input
						controls={false}
						maxLength='1'
						max={9}
						min={0}
						onChange={() => storeOtpInput(1)}
						ref={otpInput1}
						style={{ margin: '5px', borderRadius: '8px', width: '10%' }}
					/>
					<Input
						controls={false}
						maxLength='1'
						max={9}
						min={0}
						onChange={() => storeOtpInput(2)}
						ref={otpInput2}
						style={{ margin: '5px', borderRadius: '8px', width: '10%' }}
					/>
					<Input
						controls={false}
						maxLength='1'
						max={9}
						min={0}
						onChange={() => storeOtpInput(3)}
						ref={otpInput3}
						style={{ margin: '5px', borderRadius: '8px', width: '10%' }}
					/>
					<Input
						controls={false}
						maxLength='1'
						max={9}
						min={0}
						onChange={() => storeOtpInput(4)}
						ref={otpInput4}
						style={{ margin: '5px', borderRadius: '8px', width: '10%' }}
					/>
					<span style={{ textAlign: 'right', fontSize: '12px', position: 'relative', top: '10px' }}>
						{timerValue}
						<Button onClick={resendCheckAndFire} disabled={resendBtnEnabled} style={{ fontSize: '12px' }} type='link'>
							Resend Code
						</Button>
					</span>
				</Input.Group>
				<div style={{ marginTop: '20px' }}>
					{sendCodeBtn && (
						<Button
							onClick={getVerificationCode}
							type='primary'
							style={{
								borderRadius: '8px',
								width: '290px',
								height: '40px',
								background: type === 'mobile' ? '#00B3FF' : null,
								border: type === 'mobile' ? '0px' : null,
							}}
						>
							Verify Code
						</Button>
					)}
				</div>
			</div>
		</>
	)
}

export default OtpComponent
