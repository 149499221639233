export const AEROLOGIX_ENDPOINTS = {
	CONSUMER: {
		REGISTER: 'v1/consumer/register',
		FORGOT_PASS: 'v1/consumer/forgot/password',
		UPDATE_PROFILE: 'v1/consumer/update-profile',
		UPDATE_BASE_LOCATION: 'v1/consumer/edit-profile',
		CHANGE_PASSWORD: 'v1/consumer/change/password',
		GET_PROFILE: 'v1/consumer/profile',
		CONFIRM_ACCOUNT: 'v1/consumer/confirm-account',
		IS_ALIVE: 'v1/consumer/isAlive',
		GET_PILOTS: 'v1/consumer/pilots-around-the-project',
		GET_QUOTES: 'v1/consumer/quote',
		GET_PROJECTS: 'v1/consumer/getAllProjects',
		GET_DOWNLOAD_URL: 'v1/consumer',
		GET_PREVIEW_URL: 'v1/consumer',
		UPDATE_PROJECT: 'v1/consumer/project',
		GET_PAYMENT_HISTORY: 'v1/consumer/project-payment-history',
		GET_PROJECT_TIMELINE: 'v1/consumer/project/timeLine',
		GET_ALL_ACTIVE_LIVE_STREAMS: 'v1/consumer/liveStream/projects/active',
		GET_STREAM_DETAILS: 'v1/consumer/liveStream',
		GET_OTP: 'v1/consumer/otp',
		GET_PROJECTS_OVERVIEW: 'v1/consumer/projects-overview-page',
		GET_TRANSACTIONS_PAGE_DATA: 'v1/consumer/transactions-page',
		GET_ACCOUNT_MANAGER: 'v1/consumer/enterprise/account-manager',
		GET_ALL_COLLABORATORS: 'v1/consumer/enterprise/collaborators',
		UPDATE_EMAIL: 'v1/consumer/update-email',
		ADD_COLLABORATOR: 'v1/consumer/enterprise/add-collaborator',
		GET_ORDERS_NOT_OF_COLLABORATOR: 'v1/consumer/collaborator/unassigned-orders',
		DELETE_COLLABORATOR: 'v1/consumer/collaborator/delete',
		UPDATE_COLLABORATOR_PERMISSIONS: 'v1/consumer/enterprise/collaborator/update-permissions',
		GET_ALL_PROJECTS_BY_STATUS: 'v1/consumer/projects',
		GET_PILOT_PORTFOLIO: 'v1/pilot',
		UPDATE_QUOTE: 'v1/consumer/project/quote',
		CHAT_WINDOW_CONSUMER_STATUS: 'v1/consumer/chatWindow',
		GET_EMAIL_OTP: 'v1/consumer/email-otp',
		GET_EMAIL_AND_MOBILE_OTP: 'v1/consumer/email-mobile-otp',
		VERIFY_EMAIL_AND_MOBILE_OTP: 'v1/consumer/verify-otp',
	},
	PILOT: {
		REGISTER: 'v1/pilot/register',
		FORGOT_PASS: 'v1/pilot/forgot/password',
		UPDATE_PROFILE: 'v1/pilot/update-profile',
		UPDATE_BASE_LOCATION: 'v1/pilot/edit-profile',
		CHANGE_PASSWORD: 'v1/pilot/change/password',
		GET_PROFILE: 'v1/pilot/profile',
		CONFIRM_ACCOUNT: 'v1/pilot/confirm-account',
		IS_ALIVE: 'v1/pilot/isAlive',
		GET_ALL_DRONES: 'v2/pilot/drone/types',
		GET_ALL_CASA_LICENSE_TYPES: 'v1/pilot/case-license/types',
		ADD_PAYMENT_DETAILS: 'v1/pilot/complete-profile',
		GET_ALL_QUOTES: 'v1/pilot/quotes',
		GET_QUOTES: 'v1/pilot/quote',
		GET_PROJECTS: 'v1/pilot/getAllProjects',
		CHECK_UPLOADED_PREVIEW: 'v1/pilot',
		CHECK_UPLOADED_DATA: 'v1/pilot',
		UPDATE_ACCOUNT: 'v1/pilot/add-bank-account',
		VERIFY_WITH_DVS: 'v1/pilot/verifyAccountWithDVS',
		UPLOAD_STRIPE_DOCUMENT: 'v1/pilot/upload-individual-document-to-Stripe',
		GET_ALL_BANK_ACCOUNTS: 'v1/pilot/getAllBankAccounts',
		MAKE_DEFAULT_ACCOUNT: 'v1/pilot/changePrimaryAccount',
		DELETE_BANK_ACCOUNT: 'v1/pilot/delete-bank-account',
		DELETE_CASA_LICENSE: 'v1/pilot/remove-license',
		ADD_CASA_LICENSE: 'v1/pilot/add-pilot-casa-license',
		UPDATE_CASA_LICENSE: 'v1/pilot/update-pilot-casa-license',
		ADD_ARN_DETAILS: 'v1/pilot/add-pilot-ARN',
		SEND_REFERRAL_INVITE: 'v1/pilot/invite/refer/pilots',
		GET_PROJECT_TIMELINE: 'v1/pilot/project/timeLine',
		GET_OTP: 'v1/pilot/otp',
		GET_RECEIVED_PAYMENTS_LIST: 'v2/pilot/received-payments',
		GET_PAYMENT_PAGE_DETAILS: 'v1/pilot/payment-page',
		GET_PILOT_EARNINGS: 'v1/pilot/earnings',
		REJECT_ENTERPRISE_PILOT_PROMPT: 'v1/pilot/cancel-enterprise-prompt',
		CREATE_ENTERPRISE_PILOT: 'v1/pilot/enterprise/preferences',
		GET_ENTERPRISE_PILOT_DATA: 'v1/pilot/enterprise/data',
		UPDATE_ENTERPRISE_PILOT: 'v1/pilot/enterprise/preferences',
		DELETE_DRONE: 'v1/pilot',
		GET_ALL_PROJECTS_BY_STATUS: 'v1/pilot/projects',
		GET_ALL_INVITATIONS: 'v1/pilot/invitations',
		GET_ALL_PROJECTS_HISTORY: 'v1/pilot/project/history',
		GET_INVITATIONS_COUNT: 'v1/pilot/quotes/count',
		GET_MULTIPART_URLS: 'v1/pilot/getMultipartUrls',
		COMPLETE_MULTIPART_UPLOAD: 'v1/pilot/completeMultipartUpload',
		ABORT_MULTIPART_UPLOAD: 'v1/pilot/abortMultiPartUpload',
		GET_PILOT_PORTFOLIO: 'v1/pilot',
		GET_PORTFOLIO_UPLOAD_URL: 'v1/pilot/portfolio/upload',
		DELETE_PORTFOLIO_MEDIA: 'v1/pilot/portfolio/delete',
		GET_ALL_CONTRIBUTIONS: 'v1/getty/get-all-contributions',
		UPDATE_CONTRIBUTION: 'v1/getty/update-contribution',
		DELETE_CONTRIBUTION: 'v1/getty/deleteContribution',
		SUBMIT_SUBMISSION: 'v1/getty/submit-submission',
		UPLOAD_CONTRIBUTION: 'v1/getty/upload-contribution',
		CREATE_CONTRIBUTION: 'v1/getty/complete-upload-and-create-contribution',
		BULK_UPDATE: 'v1/getty/bulk-update',
		RECOVER_PILOT_ACCOUNT: 'v1/pilot/recover-account',
		FETCH_STRIPE_URL: 'v1/pilot/add-bank-account-url',
		GET_BIDDABLE_PROJECTS: 'v1/pilot/biddable/projects',
		MODIFY_BID: 'v1/pilot/project/quote',
		UPDATE_QUOTE: 'v1/pilot/project/quote',
		GET_ENTERPRISE_PLUS_CONTRACT_API: 'v1/pilot',
		CANCEL_ENTERPRISE_PLUS_PROMPT: 'v1/pilot/cancel-enterprisePlus-prompt',
		COMPLETE_PILOT_ENTERPRISE_PLUS: 'v1/pilot/enterprisePlus/create',
		GET_PILOT_CREDIT_CARDS: 'v1/pilot/getAllCreditCards',
		PASSED_QUIZ: 'v1/pilot/p101-test-taken',
		GET_ALL_LICENSE_TYPES: 'v1/pilot/license/types',
		GET_ALL_DRONE_INSURANCE_TYPES: 'v1/pilot/types/drone-insurance',
		ADD_DRONE_INSURANCE: 'v1/pilot/drone-insurance',
		GET_ALL_PURCHASED_INSURANCE: 'v1/pilot/drone-insurance',
		DELETE_DRONE_INSURANCE: 'v1/pilot/drone-insurance',
		UPDATE_DRONE_INSURANCE: 'v1/pilot/update-drone-insurance',
		CHAT_WINDOW_PILOT_STATUS: 'v1/pilot/chatWindow',
		GET_EMAIL_OTP: 'v1/pilot/email-otp',
		VERIFY_EMAIL_AND_MOBILE_OTP: 'v1/pilot/verify-otp',
	},
	OTHERS: {
		LOG_IN: 'v1/login',
		SOCIAL_MEDIA_LOGIN: 'v1/socialMediaLogin',
		CREATE_PROJECT: 'v1/project',
		GET_COUNTRIES: 'v1/country',
		GET_CITIES: 'v1/cities',
		GET_STATES: 'v1/states',
		INVITE_PILOTS: 'v1/project/quote',
		SEND_MESSAGE: 'v1/message/',
		GET_MESSAGES: 'v1/message',
		CREATE_TRANSACTION: 'v1/payment/create-transaction',
		PAYMENT: 'v1/payment',
		MARK_IN_FLIGHT: 'v1/project/in-flight',
		MARK_READY_TO_PROCESS: 'v1/project/process',
		MARK_COMPLETE: 'v1/project/complete',
		MARK_RESOLVED: 'v1/project/resolve',
		CONFLICT_RESOLUTION_PREVIEW: 'v1/project/conflict-resolution-preview-data',
		CONFLICT_RESOLUTION_FINAL: 'v1/project/conflict-resolution-final-data',
		GET_PROJECT_FOR_CONSUMER: 'v1/project',
		GET_PROJECT_FOR_PILOT: 'v1/project',
		CHECK_REFERRAL_CODE: 'v1/refer/verify/pilot',
		VERIFY_SHARABLE_LINK: 'v1/refer/verify/pilot/redirect',
		DISAPPROVE_PREVIEW_DATA: 'v1/project/disapprove-preview-data',
		DISAPPROVE_FINAL_DATA: 'v1/project/disapprove-final-data',
		GET_SOCIAL_AUTH: 'v1/socialMediaLogin',
		CREATE_SUPPORT_TICKET: 'v1/support/create-support-ticket',
		GET_TRANSACTIONS_HISTORY: 'v1/aero-credits/enterprise/client/all/transactions',
		GET_ALL_ORDERS: 'v1/order/consumer/all',
		GET_ALL_PROJECTS_FOR_ORDER: 'v1/order/projects/data',
		GET_PROJECTS_FOR_CARDS: 'v1/order/projects/details',
		CONTACT_SUPPORT: 'v1/support/create-ticket',
		GET_ORDER_DETAILS: 'v1/order',
		UPLOAD_PROJECT_DESCRIPTION: 'v1/file/upload',
		SEND_INVOICE_FOR_PROJECT: 'v1/invoice/send-invoice/project',
		SEND_TAX_SUMMARY: 'v1/invoice/tax-summary',
		DOWNLOAD_ADDITIONAL_DATA: 'v1/additionalData/download/presignedUrl',
		GET_PREVIOUS_SUBMISSIONS: 'v1/getty/get-previous-submissions',
		GET_SAVED_SUBMISSIONS: 'v1/getty/get-saved-submissions',
		CREATE_GETTY_SUBMISSION: 'v1/getty/create-submission',
		GET_BOX_CREDS: 'v1/project',
		DOWNLOAD_BOX_FOLDER: 'v1/project/box/downloadzip',
		GET_ALL_PLANS: 'v1/aerofly/plans',
		GET_ALL_ACTIVE_PLANS: 'v1/aerofly/active-licenses',
		GET_ALL_EXPIRED_PLANS: 'v1/aerofly/expired-licenses',
		GET_ALL_AEROFLY_DRONES: 'v1/aerofly/drone/types',
		CUSTOM_PLAN_ENQUIRY: 'v1/support/support-ticket',
		REQUEST_NEW_DRONE: 'v1/support/support-ticket',
		POST_ISSUE_FACED: 'v1/support/support-ticket',
		CREATE_AEROFLY_TRANSACTION: 'v1/aerofly/create-transaction',
		AEROFLY_PAYMENT: 'v1/aerofly/payment',
		GET_AEROFLY_BANNER_DETAILS: 'v1/aerofly/banner-dashboard',
		GENERATE_OTP: 'v1/aerofly/change-phone-number/otp',
		INITIATE_FREE_TRIAL: 'v1/aerofly/free-trial/initiate',
		ACTIVATE_FREE_TRIAL: 'v1/aerofly/free-trial/validate',
		GET_STRIPE_CHECKOUT_URL: 'v1/aerofly/create-transaction-session',
		GET_COMMERCIAL_COMMISION: 'v1/commercial/commission',
		GET_BID_HISTORY: 'v1/project/quote/history',
		GET_CHAT_ACCESS_TOKEN: 'v1/project/chat/token',
		CANCEL_PROJECT: 'v1/project/cancel',
		UPDATE_DEVICE_TOKEN: 'v1/message/update/deviceToken',
		GET_ALL_NOTIFICATIONS: 'v1/notification/getAllNotifications',
		UPDATE_NOTIFICATION_READ_STATUS: 'v1/notification/read',
		CHECK_FOR_SUBMITTED_DETAILS: 'v1/payment/details-submitted',
		ENQUIRE_FOR_ORDER: 'v1/order/enquiry',
		REPORT_ERROR: 'v1/admin/forwardException',
		UPLOAD_FLIGHT_LOG_FILE: 'v1/file/uploadFlightLog',
		DELETE_FLIGHT_LOG_FILE: 'v1/mappa/delete-flightLog',
	},
}
