import React from 'react'
import { Redirect } from 'react-router-dom'
import LoggedInRedirect from '../Modules/LoggedInRedirect'
import Home from '../Modules/Home'
import Getty from '../Modules/Getty'
import GettyCountDown from '../Modules/GettyCountDown'
import Contribution from '../Modules/Getty/Contribution'
import Payment from '../Modules/Payment'
import Profile from '../Modules/Profile'
import Refer from '../Modules/Refer'
import Upgrades from '../Modules/Upgrades'
import Settings from '../Modules/Settings'
import ContactUs from '../Modules/ContactUs'
import Projects from '../Modules/Projects'
import Overview from '../Modules/Overview'
import Orders from '../Modules/Orders'
import Transactions from '../Modules/Transactions'
import EnterpriseProfile from '../Modules/EnterpriseProfile'
import Support from '../Modules/Support'
import OrderDetails from '../Modules/Orders/orderDetails'
import PilotPortfolio from '../Modules/Profile/Components/pilot-portfolio'
import MyPlans from '../Modules/MyPlans'
import EnterpriseProjects from '../Modules/EnterpriseProjects'
import CreateNewProject from '../Modules/Projects/create-new-project'
import NotAvailable from './NotAvailable'
import ServerDown from './ServerDown'
import PageNotFound from './PageNotFound'
import OnBoarding from '../Modules/OnBoarding'
import Login from '../Modules/OnBoarding/login'
import Signup from '../Modules/OnBoarding/signup'
import SignupSelect from '../Modules/OnBoarding/signup-select'
import ForgotPassword from '../Modules/OnBoarding/forgot-password'
import ResetPassword from '../Modules/OnBoarding/reset-password'
import SignUpSuccess from '../Modules/OnBoarding/signup-success'
import EmailVerificationSuccess from '../Modules/OnBoarding/email-verification-success'
import EmailVerificationFail from '../Modules/OnBoarding/email-verification-fail'
import ShareReferral from '../Modules/ShareReferral'
import ReceiveAuth from '../Modules/ReceiveAuth'
import UserDeletionModal from '../Modules/UserDeletionModal'
import SentPasswordRecoveryLink from '../Modules/OnBoarding/sent-password-recovery'
import AddEmailMobile from '../Modules/AddEmailMobile'
import ProjectData from '../Modules/Orders/Components/OrderDetail/project-data'

export const PROFILE_ROUTES = (profileData, scope) => {
	return {
		CONSUMER: [
			{
				key: 0,
				path: '/',
				component: props => <LoggedInRedirect {...props} />,
			},
			{
				key: 1,
				path: '/home',
				component: props => <Home {...props} />,
			},
			{
				key: 2,
				path: '/profile',
				component: props => <>{profileData ? <Profile {...props} profileData={profileData} /> : null}</>,
			},
			{
				key: 3,
				path: '/settings',
				component: props => <Settings {...props} />,
			},
			{
				key: 4,
				path: '/contact-us',
				component: props => <ContactUs {...props} />,
			},
			{
				key: 5,
				path: '/home/projects/:projectId',
				component: props => (
					<Projects
						userName={profileData ? profileData.firstName : ''}
						{...props}
						profilePicture={profileData && profileData.profilePicture ? profileData.profilePicture : ''}
						chatClientRef={scope.chatClientRef}
						createChatClient={scope.createChatClient}
					/>
				),
			},
			{
				key: 6,
				path: '/projects/:projectId',
				component: props => (
					<Projects
						userName={profileData ? profileData.firstName : ''}
						{...props}
						profilePicture={profileData && profileData.profilePicture ? profileData.profilePicture : ''}
						chatClientRef={scope.chatClientRef}
						createChatClient={scope.createChatClient}
					/>
				),
			},
			{
				key: 7,
				path: '/enterprise/overview',
				component: props => <Overview {...props} />,
			},
			{
				key: 8,
				path: '/enterprise/orders',
				component: props => <Orders {...props} profileData={profileData} />,
			},
			{
				key: 9,
				path: '/enterprise/transactions',
				component: props => <Transactions {...props} />,
			},
			{
				key: 10,
				path: '/enterprise/profile',
				component: props => <EnterpriseProfile {...props} />,
			},
			{
				key: 11,
				path: '/enterprise/support',
				component: props => <Support {...props} />,
			},
			{
				key: 12,
				path: '/enterprise/orders/order-details/:orderId',
				component: props => <OrderDetails {...props} />,
			},
			{
				key: 13,
				path: '/pilot-portfolio/:pilotId',
				component: props => <PilotPortfolio {...props} />,
			},
			{
				key: 14,
				path: '/enterprise-projects',
				component: props => <EnterpriseProjects {...props} />,
			},

			{
				key: 15,
				path: '/create-new-project/:projectId',
				component: props => <CreateNewProject {...props} />,
			},
			{
				key: 16,
				path: '/create-new-project',
				component: props => <CreateNewProject {...props} />,
			},
			{
				key: 17,
				path: '/project-data/:projectId',
				component: props => <ProjectData {...props} />,
			},
			{
				key: 18,
				path: '/not-available',
				component: props => <NotAvailable {...props} />,
			},
			{
				key: 19,
				path: '/loggedInRedirect',
				component: props => <LoggedInRedirect {...props} />,
			},
			{
				key: 20,
				path: '/server-down',
				component: props => <ServerDown {...props} />,
			},
			{
				key: 21,
				path: '/add-email',
				component: props => <AddEmailMobile {...props} />,
			},

			{
				key: 22,
				path: '*',
				component: props => <PageNotFound {...props} />,
			},
		],
		PILOT: [
			{
				key: 0,
				path: '/',
				component: props => <LoggedInRedirect {...props} />,
			},
			{
				key: 1,
				path: '/home',
				component: props => <Home {...props} />,
			},
			{
				key: 2,
				path: '/getty/submission/:tabState/:pageNumber',
				component: props => <Getty {...props} />,
			},
			{
				key: 3,
				path: '/getty/submission/:tabState/:pageNumber/?search=',
				component: props => <Getty {...props} />,
			},
			{
				key: 4,
				path: '/getty-countdown',
				component: props => <GettyCountDown {...props} />,
			},
			{
				key: 5,
				path: '/getty/submission/:submissionId',
				component: props => <Contribution {...props} />,
			},
			{
				key: 6,
				path: '/profile',
				component: props => <>{profileData ? <Profile {...props} profileData={profileData} /> : null}</>,
			},
			{
				key: 7,
				path: '/payment/:isRedirected',
				component: props => <>{profileData ? <Payment {...props} profileData={profileData} userEmail={profileData?.email} /> : null}</>,
			},
			{
				key: 8,
				path: '/payment',
				component: props => <>{profileData ? <Payment {...props} profileData={profileData} userEmail={profileData?.email} /> : null}</>,
			},
			{
				key: 9,
				path: '/refer',
				component: props => <Refer {...props} />,
			},
			{
				key: 10,
				path: '/upgrades',
				component: props => <Upgrades {...props} />,
			},
			{
				key: 11,
				path: '/settings',
				component: props => <Settings {...props} />,
			},
			{
				key: 12,
				path: '/contact-us',
				component: props => <ContactUs {...props} />,
			},
			{
				key: 13,
				path: '/home/projects/:projectId',
				component: props => (
					<Projects
						userName={profileData ? profileData.firstName : ''}
						{...props}
						profilePicture={profileData && profileData.profilePicture ? profileData.profilePicture : ''}
						chatClientRef={scope.chatClientRef}
						createChatClient={scope.createChatClient}
					/>
				),
			},
			{
				key: 14,
				path: '/projects/:projectId',
				component: props => (
					<Projects
						userName={profileData ? profileData.firstName : ''}
						{...props}
						profilePicture={profileData && profileData.profilePicture ? profileData.profilePicture : ''}
						chatClientRef={scope.chatClientRef}
						createChatClient={scope.createChatClient}
					/>
				),
			},
			{
				key: 15,
				path: '/pilot-portfolio/:pilotId',
				component: props => <PilotPortfolio {...props} />,
			},
			{
				key: 16,
				path: '/my-plans/:paymentStatus',
				component: props => <MyPlans {...props} />,
			},
			{
				key: 17,
				path: '/my-plans',
				component: props => <MyPlans {...props} />,
			},
			{
				key: 18,
				path: '/enterprise-projects',
				component: props => <EnterpriseProjects {...props} />,
			},
			{
				key: 19,
				path: '/not-available',
				component: props => <NotAvailable {...props} />,
			},
			{
				key: 20,
				path: '/loggedInRedirect',
				component: props => <LoggedInRedirect {...props} />,
			},
			{
				key: 21,
				path: '/server-down',
				component: props => <ServerDown {...props} />,
			},

			{
				key: 22,
				path: '*',
				component: props => <PageNotFound {...props} />,
			},
		],
	}
}

export const NON_AUTH_ROUTES = scope => {
	return [
		{
			key: 0,
			path: '/',
			component: props => <OnBoarding {...props} />,
		},
		{
			key: 1,
			path: '/login',
			component: props => <Login setTokenFound={scope.setTokenFound} updateDeviceToken={scope.updateDeviceToken} {...props} />,
		},
		{
			key: 2,
			path: '/signup',
			component: props => <Signup {...props} />,
		},
		{
			key: 3,
			path: '/signup-select',
			component: props => <SignupSelect {...props} />,
		},
		{
			key: 4,
			path: '/forgot-password',
			component: props => <ForgotPassword {...props} />,
		},
		{
			key: 5,
			path: '/sent-password-recovery',
			component: props => <SentPasswordRecoveryLink {...props} />,
		},
		{
			key: 6,
			path: '/reset-password',
			component: props => <ResetPassword {...props} />,
		},
		{
			key: 7,
			path: '/signup-success',
			component: props => <SignUpSuccess {...props} />,
		},
		{
			key: 8,
			path: '/email-verification-success',
			component: props => <EmailVerificationSuccess {...props} />,
		},
		{
			key: 9,
			path: '/email-verification-fail',
			component: props => <EmailVerificationFail {...props} />,
		},
		{
			key: 10,
			path: '/share-referral/referralCode/:referralCode',
			component: props => <ShareReferral {...props} />,
		},
		{
			key: 11,
			path: '/receive-auth/:authKey/:role/:channel',
			component: props => <ReceiveAuth setTokenFound={scope.setTokenFound} updateDeviceToken={scope.updateDeviceToken} {...props} />,
		},
		{
			key: 12,
			path: '/create-new-project',
			component: props => <CreateNewProject {...props} />,
		},
		{
			key: 13,
			path: '/user-deletion',
			component: props => <UserDeletionModal {...props} />,
		},
		{
			key: 14,
			path: '/add-email',
			component: props => <AddEmailMobile {...props} />,
		},
		{
			key: 15,
			path: '*',
			component: props => (
				<Redirect
					to={{
						pathname: '/',
					}}
				/>
			),
		},
	]
}
