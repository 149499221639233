import React from 'react'
import styles from './SubmitModal.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'
import { Button } from 'antd'
import MatImage from '../../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../../AeroComponents/Image/image.constants'

import { bulkUpdate } from '../../../../../Stores/Getty/action'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

function SubmitModal(props) {
	// extract props
	const {
		setShowSubmitModal, // from parent
		showSubmitModal,
		buildDataForChangeDescription,
		bulkUpdate, // dispatch
	} = props

	// functions
	function submitSubmissionToAerologix() {
		let data = buildDataForChangeDescription()
		bulkUpdate({ ...data, mixpanel_type: 'file_submission' })
		setShowSubmitModal(false)
	}

	return (
		<AeroModal
			bodyStyle={{ backgroundColor: '#FFFFFF', borderRadius: '12px' }}
			footer={false}
			onCancel={() => {
				setShowSubmitModal(false)
			}}
			visible={showSubmitModal}
		>
			<div className={styles['submit-modal-content']}>
				<br />
				<div>
					<br />
					<div>
						<MatImage style={{ fontSize: '40px' }} src={IMAGES_SRC.SUBMIT_MESSAGE} /> <br />
						<span className={styles['bold-text']}>Send Submission for review to Aerologix?</span>
						<br />
						<span> All Videos in your Submission will be reviewed by Aerologix before uploading to the Getty platform </span>
					</div>

					<br></br>
					<Button
						block
						className={styles['button-white']}
						type='primary'
						onClick={() => {
							setShowSubmitModal(false)
						}}
					>
						Cancel
					</Button>
					<Button
						block
						className={styles['button']}
						type='primary'
						onClick={() => {
							submitSubmissionToAerologix()
						}}
					>
						Send
					</Button>
				</div>
			</div>
		</AeroModal>
	)
}

const mapDispatchToProps = dispatch => {
	return {
		bulkUpdate: bindActionCreators(bulkUpdate, dispatch),
	}
}

export default connect(
	null,
	mapDispatchToProps,
)(SubmitModal)
