import React from 'react'
import { Button } from 'antd'

export const collaboratorsCols = scope => {
	return [
		{
			title: 'Name',
			dataIndex: 'firstName',
			key: 'firstName',
			render: (firstName, { ...data }) => firstName + ' ' + data.lastName,
		},
		{
			title: 'Permissions',
			dataIndex: 'permissions_string',
			key: 'permissions_string',
		},
		{
			title: 'Actions',
			dataIndex: 'consumerId',
			key: 'consumerId',
			render: (consumerId, { ...data }) => (
				<span>
					<Button onClick={() => scope.openEditPermissionModal(data)} type='link'>
						Edit Permissions
					</Button>{' '}
					|{' '}
					<Button onClick={() => scope.openDeleteCollaboratorModal(data)} type='link'>
						Delete Collaborator
					</Button>
				</span>
			),
		},
	]
}

export const permissionCols = scope => {
	return [
		{
			title: 'Order Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Added On',
			dataIndex: 'addedOn',
			key: 'addedOn',
			render: addedOn => (addedOn ? addedOn : '--'),
		},
		{
			title: 'Actions',
			dataIndex: 'id',
			key: 'id',
			render: id => (
				<Button style={{ color: 'red' }} onClick={() => scope.removePermission(id)} type='link'>
					Remove
				</Button>
			),
		},
	]
}
