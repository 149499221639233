import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import AboutYou from '../about-you'

const AboutYouTab = props => {
	const { openMadalToAddDetails, profileData } = props
	return (
		<>
			<div style={{ fontSize: '16px', color: '#47464A' }}>About You</div>
			<Button
				style={{ height: '48px', marginBottom: '20px', marginTop: '20px' }}
				type='primary'
				onClick={() => {
					openMadalToAddDetails('ABOUT_YOU')
				}}
			>
				<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} />
				Add Details
			</Button>
			{profileData && profileData['aboutYou'] ? <AboutYou viewMode data={profileData} /> : <div>No details has been added yet</div>}
		</>
	)
}

export default AboutYouTab
