
import React from 'react'
import styles from './DeleteModal.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'
import { Button, notification } from "antd";
import MatImage from '../../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../../AeroComponents/Image/image.constants'

import { deleteContribution } from '../../../../../Stores/Getty/action'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { CheckCircleTwoTone } from '@ant-design/icons';

function DeleteModal(props) {

  // extract props
  const {
    setShowDeleteModal, // from parent  
    showDeleteModal,
    contributionId,
    fileName,
    totalContributions, setSelectedIndex,
    deleteContribution // dispatch
  } = props

  // functions
  function deleteContributionFromSubmission() {
    setShowDeleteModal(false)
    deleteContribution({ "contributionId": contributionId }).then(result => {
      // Send in app notification
      notification.open({
        duration: 10,
        message: ' File deleted ',
        className: styles['notification'],
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" style={{ position: "relative", bottom: "5px" }} />
      });

      setSelectedIndex(totalContributions - 2)

    })

  }


  return (
    <AeroModal
      bodyStyle={{ backgroundColor: '#FFFFFF', borderRadius: '12px' }}
      footer={false} onCancel={() => { setShowDeleteModal(false) }} visible={showDeleteModal}>
      <div className={styles['details-modal']}  >



        <br />
        <div>

          <br />
          <div className={styles['delete-modal-content']} >
            <MatImage style={{ fontSize: '40px' }} src={IMAGES_SRC.BIN_ICON} /> <br />
            <span className={styles['bold-text']} >Delete File?</span>
            <span>File <span className={styles['bold-small-text']} > {fileName} </span> will be deleted </span>

            <br></br>
            <span>
              <Button block className={styles['button-white']} type='primary' onClick={() => { setShowDeleteModal(false) }}> Cancel</Button>
              <Button block className={styles['button']} type='primary' onClick={() => { deleteContributionFromSubmission() }}>Delete</Button>
            </span>
          </div>


        </div>

      </div>
    </AeroModal>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    deleteContribution: bindActionCreators(deleteContribution, dispatch),

  }
}

export default connect(
  null,
  mapDispatchToProps
)(DeleteModal)

