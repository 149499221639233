import { signUpApi, checkReferralCodeApi } from './api'

export const signUp = body => {
	return {
		type: 'SIGN_UP',
		payload: signUpApi(body),
	}
}

export const checkReferralCode = queryParams => {
	return {
		type: 'CHECK_REFERRAL_CODE',
		payload: checkReferralCodeApi(queryParams),
	}
}
