import moment from 'moment'

import { post, get, put, deleteMethod } from '../../Api/http-interceptor'
import * as ACTION from './actionTypes'
import { pushEventToMixpanel } from '../../Services/MixpanelService'

export const getAllContributionsApi = queryParams => {
	return get(ACTION.GET_ALL_CONTRIBUTIONS, null, queryParams)
}

export const getPreviousSubmissionsApi = queryParams => {
	return get('GET_PREVIOUS_SUBMISSIONS', null, queryParams)
}

export const getSavedSubmissionsApi = queryParams => {
	return get('GET_SAVED_SUBMISSIONS', null, queryParams)
}

export const createGettySubmissionApi = async body => {
	const res = await post('CREATE_GETTY_SUBMISSION', body)

	// Sending new submission event to Mixpanel
	if (res?.status_code === 200) {
		pushEventToMixpanel('', 'New Submission', {
			'Event Type': res.data.eventType
				.replaceAll('_', ' ')
				.split(' ')
				.map(el => `${el.charAt(0).toUpperCase()}${el.slice(1).toLowerCase()}`)
				.join(' '),
		})
	}

	return res
}

export const updateContributionApi = async body => {
	const res = await put(ACTION.UPDATE_CONTRIBUTION, body)

	// Sending file upload event to Mixpanel
	if (body.mixpanel_type === 'file_saved') {
		pushEventToMixpanel('', 'File Saved', {
			'Country of Shoot': res.data.countryOfShoot,
			City: res.data.city,
			'Province/State': res.data.provinceState,
			'Video Creation Date': moment(res.data.recordedDate, 'YYYY-MM-DD').format('MM-DD-YYYY'),
			'Clip Type': `${res.data.clipType.charAt(0).toUpperCase()}${res.data.clipType.slice(1).toLowerCase()}`,
			Category: `${res.data.iptcCategory.charAt(0).toUpperCase()}${res.data.iptcCategory.slice(1).toLowerCase()}`,
			'Supplemental Categories': res.data.iptcSubjects.join(', '),
			'Shot Speed': res.data.shotSpeed
				.replaceAll('_', ' ')
				.split(' ')
				.map(string => `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`)
				.join(' '),
			Keywords: res.data.keywords.map(keyword => keyword.term).join(', '),
			'Saved Successfully': res.status_code === 200,
		})
	}

	return res
}

export const deleteContributionApi = queryParams => {
	return deleteMethod(ACTION.DELETE_CONTRIBUTION, null, queryParams)
}

export const submitSubmissionApi = body => {
	return post(ACTION.SUBMIT_SUBMISSION, body)
}

export const getMultipartUrlsApi = body => {
	return post(ACTION.UPLOAD_CONTRIBUTION, body)
}

export const uploadMultipartFileApi = body => {
	return put(ACTION.UPLOAD_MULTIPART_FILE, body, null, null, true)
}

export const createContributionApi = async body => {
	const res = await post(ACTION.CREATE_CONTRIBUTION, body)

	console.log(body.fileName.split('.')[body.fileName.split('.').length - 1])
	// Sending file upload to Mixpanel
	if (body.mixpanel_type === 'file_upload') {
		pushEventToMixpanel('', 'File Upload', {
			'Video File Type': body.fileName.split('.')[body.fileName.split('.').length - 1],
			'Successful Upload': res.status_code === 200,
		})
	}

	return res
}

export const bulkUpdateContributionApi = async body => {
	const res = await put(ACTION.BULK_UPDATE, body)

	// Sending copy details to all videos event to mixpanel
	if (res.status_code === 200 && body.mixpanel_type === 'copy_details_to_all_videos') {
		pushEventToMixpanel('', 'Copy Details To All Videos', {
			'Was This Option Used': true,
		})
	}
	// Sending file submission to all videos event to mixpanel
	if (body.mixpanel_type === 'file_submission') {
		pushEventToMixpanel('', 'File Submission', {
			'Number of Videos': body.contributions.length,
			'Successfully Submitted': res.status_code === 200,
		})
	}

	return res
}
