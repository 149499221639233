import * as ACTION from './actionTypes'
import {
	getAllPlansApi,
	getAllActivePlansApi,
	getAllExpiredPlansApi,
	getAllAeroFlyDronesApi,
	requestNewDroneApi,
	customPlanEnquiryApi,
	createAeroFlyTransactionApi,
	aeroFlyPaymentApi,
	getAeroFlyBannerDetailsApi,
	postIssueFacedApi,
	generateOtpApi,
	initiateFreeTrialApi,
	activateFreeTrialApi,
} from './api'

export const getAllPlans = queryParams => {
	return {
		type: ACTION.GET_ALL_PLANS,
		payload: getAllPlansApi(queryParams),
	}
}

export const getAllActivePlans = queryParams => {
	return {
		type: ACTION.GET_ALL_ACTIVE_PLANS,
		payload: getAllActivePlansApi(queryParams),
	}
}

export const getAllExpiredPlans = queryParams => {
	return {
		type: ACTION.GET_ALL_EXPIRED_PLANS,
		payload: getAllExpiredPlansApi(queryParams),
	}
}

export const getAllAeroFlyDrones = queryParams => {
	return {
		type: ACTION.GET_ALL_AEROFLY_DRONES,
		payload: getAllAeroFlyDronesApi(queryParams),
	}
}

export const requestNewDrone = body => {
	return {
		type: ACTION.REQUEST_NEW_DRONE,
		payload: requestNewDroneApi(body),
	}
}

export const customPlanEnquiry = body => {
	return {
		type: ACTION.CUSTOM_PLAN_ENQUIRY,
		payload: customPlanEnquiryApi(body),
	}
}

export const createAeroFlyTransaction = body => {
	return {
		type: ACTION.CREATE_AEROFLY_TRANSACTION,
		payload: createAeroFlyTransactionApi(body),
	}
}

export const aeroFlyPayment = body => {
	return {
		type: ACTION.AEROFLY_PAYMENT,
		payload: aeroFlyPaymentApi(body),
	}
}

export const getAeroFlyBannerDetails = queryParams => {
	return {
		type: ACTION.GET_AEROFLY_BANNER_DETAILS,
		payload: getAeroFlyBannerDetailsApi(queryParams),
	}
}

export const postIssueFaced = body => {
	return {
		type: ACTION.POST_ISSUE_FACED,
		payload: postIssueFacedApi(body),
	}
}

export const generateOtp = body => {
	return {
		type: ACTION.GENERATE_OTP,
		payload: generateOtpApi(body),
	}
}

export const initiateFreeTrial = body => {
	return {
		type: ACTION.INITIATE_FREE_TRIAL,
		payload: initiateFreeTrialApi(body),
	}
}

export const activateFreeTrial = body => {
	return {
		type: ACTION.ACTIVATE_FREE_TRIAL,
		payload: activateFreeTrialApi(body),
	}
}
