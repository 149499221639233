import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Descriptions, Input, Button } from 'antd'
import { getWebConfig } from '../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class AbnDetailsForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			configData: props.webConfig,
		}
	}
	componentDidMount() {
		if (!this.state.configData) {
			this.props.getWebConfig()
		}
		this.props.form.setFieldsValue(this.props.data)
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const data = {
					businessNumber: values.businessNumber,
					mixpanel_type: this.state.configData?.countryId === 1 ? 'abn' : 'nzbn',
				}
				this.props.updateAbnDetails(data)
			}
		})
	}

	render() {
		const { viewMode, data } = this.props
		const { getFieldDecorator } = this.props.form
		return (
			<Form layout='vertical' onSubmit={this.handleSubmit}>
				{viewMode ? (
					<Descriptions>
						<Descriptions.Item label={this.state.configData.countryId === 1 ? 'ABN' : 'NZBN'}>{data.businessNumber}</Descriptions.Item>
					</Descriptions>
				) : (
					<>
						<div style={{ marginTop: '20px', marginBottom: '20px' }}>
							{this.state.configData.countryId === 1
								? 'An ABN is a unique 11 digit number that identifies your business to government & community Aerologix requires your ABN in order to generate GST invoices'
								: 'New Zealand Business Number (NZBN) is a 13-digit globally unique identifier available to every Kiwi business. Aerologix requires your NZBN to generate GST invoices.'}
						</div>
						<div>
							<Form.Item label={`${this.state.configData.countryId === 1 ? 'Australian' : 'New Zealand'} Business Number`}>
								{getFieldDecorator('businessNumber', {
									rules: [
										{
											required: true,
											whitespace: true,
											message: `Please input ${this.state.configData.countryId === 1 ? 'ABN' : 'NZBN'}`,
										},
									],
								})(
									<Input
										type='number'
										style={{ height: '48px', borderRadius: '8px' }}
										placeholder={`Enter your ${this.state.configData.countryId === 1 ? '11 digits ABN' : '13 digits NZBN'}`}
									/>,
								)}
								<div style={{ color: '#84818A' }}>Note: Once added, it cannot be changed</div>
							</Form.Item>
							<Form.Item>
								<Button style={{ height: '48px' }} block type='primary' htmlType='submit'>
									Add
								</Button>
							</Form.Item>
						</div>
					</>
				)}
			</Form>
		)
	}
}

const mapStateToProps = state => {
	return {
		webConfig: state.UserSessionReducer.webConfig,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
const AbnDetails = Form.create({ name: 'ABN_DETAILS_FORM' })(AbnDetailsForm)
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AbnDetails)
