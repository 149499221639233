import { Input } from 'antd'
import React from 'react'
import { CUSTOM_PLAN_FIELD_METADATA } from '../../../Modules/MyPlans/constants'

export const CUSTOM_PLAN_ENQUIRY_FIELDS = [
	{
		fieldName: 'NUMPL',
		fieldLabel: CUSTOM_PLAN_FIELD_METADATA.NUMPL.label,
		fieldKey: CUSTOM_PLAN_FIELD_METADATA.NUMPL.key,
		fileObject: {
			rules: [
				{
					required: true,
					message: 'Enter number of plans!',
				},
			],
		},
		fieldToRender: <Input type='number' style={{ borderRadius: '8px' }} />,
	},
	{
		fieldName: 'ORG',
		fieldLabel: CUSTOM_PLAN_FIELD_METADATA.ORG.label,
		fieldKey: CUSTOM_PLAN_FIELD_METADATA.ORG.key,
		fieldToRender: <Input style={{ borderRadius: '8px' }} />,
	},
	{
		fieldName: 'ADDNREQ',
		fieldLabel: CUSTOM_PLAN_FIELD_METADATA.ADDNREQ.label,
		fieldKey: CUSTOM_PLAN_FIELD_METADATA.ADDNREQ.key,
		fieldToRender: <Input style={{ borderRadius: '8px' }} />,
	},
]