import React, { useEffect, useState } from 'react'
import {Card } from 'antd'
import { InstagramFilled, TwitterSquareFilled, LinkedinFilled, FacebookFilled } from '@ant-design/icons'


const GettyCountDown = () => {
    const [timer, setTimer] = useState('')

    const changeFormate =(name) =>{
        if (name < 10) {
            name = "0" + name
        }
        return name
    }

    const   redirectForInstagram =() =>{
        window.open('https://www.instagram.com/aerologix/' ,'_blank')
    }
    const   redirectForLinkdin =() =>{
        window.open('https://www.linkedin.com/company/aerologix/' ,'_blank')
    }
    const   redirectForTwitter =() =>{
        window.open('https://twitter.com/aerologix' ,'_blank')
    }
    const   redirectForFacebook =() =>{
        window.open('https://www.facebook.com/aerologix/' ,'_blank')
    }
    useEffect(() => {
        // Set the date we're counting down to
        const countDownDate = new Date('Mar 28, 2022 00:00:00').getTime()

        // Update the count down every 1 second
        const x = setInterval(function() {
            // Get today's date and time
            const now = new Date().getTime()

            // Find the distance between now and the count down date
            const distance = countDownDate - now

            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24))
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            let seconds = Math.floor((distance % (1000 * 60)) / 1000)
            

            
            // Output the result in an element with id="demo"
             let timerValue = changeFormate(days) + ':'  + changeFormate(hours) + ':' + changeFormate(minutes) + ':' + changeFormate(seconds)
             setTimer(timerValue)
            // If the count down is over, write some text
            if (distance < 0) {
                clearInterval(x)
                setTimer('Competition will launch soon!')
            }
        }, 1000)
    }, [])

   return (
        <Card style={{ margin: '20px', borderRadius: '12px', textAlign: 'center' }}>
            <p>You are eligible for the competition!</p>
            <p>Stay tuned! Competition launches in</p>
            <p>
                <div style={{ fontSize: '60px' }}>
                    <strong>{timer}</strong>
                </div>
                <div>
                    <span style={{ position: 'relative', right: '60px' }}>Days</span>
                    <span style={{ position: 'relative' }}>Hours</span>
                    <span style={{ position: 'relative', left: '38px' }}>Minutes</span>
                    <span style={{ position: 'relative', left: '80px' }}>Seconds</span>
                </div>
            </p>
            <p>Follow us on social media for updates</p>
            <p style={{ fontSize: '20px' }}>
                <InstagramFilled  onClick={redirectForInstagram} style={{ margin: '10px' }} />
                <TwitterSquareFilled onClick={redirectForTwitter} style={{ margin: '10px' }} />
                <LinkedinFilled onClick={redirectForLinkdin} style={{ margin: '10px' }} />
                <FacebookFilled  onClick= {redirectForFacebook} style={{ margin: '10px' }} />
            </p>
        </Card>
    )
}

export default GettyCountDown