/*eslint-disable*/
import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Modal, Divider, Slider } from 'antd'
import { ADDRESS_DETAILS_FIELDS } from '../../../AeroComponents/FormFields/Metadata/address-details.fields'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import AeroField from '../../../AeroComponents/FormFields'
import MapWithASearchBox from '../../../AeroComponents/GoogleMap/MapView'

const marks = {
	0: '0 km',
	300: '300 kms',
}

class AddressDetailsForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fieldsToRender: ADDRESS_DETAILS_FIELDS(this, props.viewMode),
			layout: 'vertical',
			visible: false,
			googleLocationObject: { lat: props.data.address?.latitude, lng: props.data.address?.longitude },
			sliderValue: 10,
		}
	}

	componentDidMount() {
		if (this.props.viewMode) {
			const { form, data } = this.props
			form.setFieldsValue(
				{
					address: data.address.addressDetails,
				},
				() => {
					this.setState({
						fieldsToRender: ADDRESS_DETAILS_FIELDS(this, this.props.viewMode),
					})
				},
			)
		}
	}

	handleAddressChange = address => {
		// this.setState({ activateUpdate: false })
		this.props.form.setFieldsValue(
			{
				address: address,
			},
			() => {
				this.setState({
					fieldsToRender: ADDRESS_DETAILS_FIELDS(this, this.props.viewMode),
				})
			},
		)
	}

	handleAddressSelect = (address, placeID) => {
		this.props.form.setFieldsValue(
			{
				address: address,
			},
			() => {
				this.setState({
					fieldsToRender: ADDRESS_DETAILS_FIELDS(this, this.props.viewMode),
				})
			},
		)
		geocodeByAddress(address)
			.then(async results => {
				return getLatLng(results[0])
			})
			.then(latLng => {
				// Do something with latLng : window.google.maps.LatLngLiteral
				this.setState({ googleLocationObject: latLng })
			})
			.catch(error => {
				console.error('Error', error)
			})
	}

	setLocationByMap = loc => {
		this.handleAddressSelect(loc.address)
		this.setState({
			visible: false,
		})
	}

	openMadalToAddDetails = modalKey => {
		switch (modalKey) {
			case 'GOOGLE_MAP':
				const { form } = this.props
				this.setState({
					visible: true,
					modalTitle: 'Select Location on Map',
					modalComponent: (
						<MapWithASearchBox address={form.getFieldValue('address')} googleLocationObject={this.state.googleLocationObject} setLocation={this.setLocationByMap} />
					),
				})
				break
			default:
				break
		}
	}

	handleCancel = () => {
		this.setState({
			visible: false,
		})
	}

	clearAddress = () => {
		this.props.form.setFieldsValue({
			location: null,
		})
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const addressDetails = values.address
				let data = {
					address: {
						addressDetails,
						latitude: this.state.googleLocationObject ? this.state.googleLocationObject.lat : this.props.data.address.latitude,
						longitude: this.state.googleLocationObject ? this.state.googleLocationObject.lng : this.props.data.address.longitude,
					},
					mixpanel_type: 'base_location',
				}
				if (this.props.pilot) {
					const prefer = {
						projectRadius: this.state.sliderValue,
					}
					data['preferences'] = prefer
				}
				if (this.props.viewMode) {
					this.props.updateBaseLocation(data)
				} else {
					this.props.updateAddressDetails(data)
				}
			}
		})
	}

	formatter = value => {
		return `${value} kms`
	}

	setSliderValue = value => {
		this.setState({ sliderValue: value })
	}

	render() {
		const { fieldsToRender, visible, modalTitle, modalComponent } = this.state
		return (
			<React.Fragment>
				<Form layout={this.state.layout} onSubmit={this.handleSubmit}>
					<AeroField fieldsToRender={fieldsToRender} {...this.props} />

					{this.props.pilot ? (
						<div>
							<Divider></Divider>
							<div>
								<div>
									<strong>Radius of Project Notification:</strong>
									<br />
									Set the radius within which you are open to accepting jobs
								</div>
							</div>
							<div style={{ padding: '15px' }}>
								<Slider
									defaultValue={this.props.data.preferences.projectRadius}
									marks={marks}
									onChange={this.setSliderValue}
									tipFormatter={this.formatter}
									max={300}
								/>
							</div>
						</div>
					) : (
						''
					)}

					{this.props.viewMode ? (
						<Button style={{ height: '48px', marginTop: '20px' }} block type='primary' htmlType='submit'>
							Update
						</Button>
					) : (
						<Button style={{ height: '48px', marginTop: '20px' }} block type='primary' htmlType='submit'>
							Add
						</Button>
					)}
				</Form>
				{visible && (
					<Modal open={visible} title={modalTitle} width={500} onCancel={this.handleCancel} footer={null}>
						{modalComponent}
					</Modal>
				)}
			</React.Fragment>
		)
	}
}

const AddressDetails = Form.create({ name: 'ADDRESS_DETAILS_FORM' })(AddressDetailsForm)

export default AddressDetails
