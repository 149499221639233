import React from 'react'
import moment from 'moment'
import { Button, Tooltip } from 'antd'

export const invitedPilotsTableColumnsAction = scope => {
	return [
		{
			title: 'Pilot Name',
			dataIndex: 'pilot',
			key: 'pilot',
			render: (pilot, { projectId, ...data }) => {
				return <span to={'home/projects/' + projectId}>{pilot.firstName + ' ' + pilot.lastName}</span>
			},
		},
		{
			title: 'Project Cost (A$)',
			dataIndex: 'amount',
			key: 'amount',
			render: amount => amount,
		},
		{
			title: 'Invitation Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: createdAt => moment(createdAt).format('DD/MM/YYYY'),
		},
		{
			title: 'Messages',
			dataIndex: 'quote_id',
			key: 'quote_id',
			render: (quote_id, { pilot }) => {
				return (
					<Button style={{ marginLeft: '-12px' }} type='link' onClick={() => scope.toggleDrawer(quote_id, pilot)}>
						Chat
					</Button>
				)
			},
		},
		{
			title: (
				<Tooltip
					title={
						<div>
							<strong>Note:</strong>
							<br />
							Allocate button will appear only when Pilot accepts the invite
						</div>
					}
				>
					<span>Action</span>
				</Tooltip>
			),
			dataIndex: 'status',
			key: 'status',
			render: (status, { pilot, quote_id }) => {
				return (
					<div>
						{status === 'ACCEPTED' ? (
							<Button style={{ marginLeft: '-12px' }} type='link' onClick={() => scope.showModal(pilot, quote_id)}>
								Allocate
							</Button>
						) : status === 'PENDING' ? (
							"Awaiting pilot's response"
						) : null}
					</div>
				)
			},
		},
	]
}

export const invitedPilotsTableColumns = scope => {
	return [
		{
			title: 'Pilot Name',
			dataIndex: 'pilot',
			key: 'pilot',
			render: (pilot, { projectId, ...data }) => {
				return <span>{pilot.firstName + ' ' + pilot.lastName}</span>
			},
		},
		{
			title: 'Project Cost (A$)',
			dataIndex: 'amount',
			key: 'amount',
			render: amount => amount,
		},
		{
			title: 'Invitation Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: createdAt => moment(createdAt).format('DD/MM/YYYY'),
		},
		{
			title: 'Messages',
			dataIndex: 'quote_id',
			key: 'quote_id',
			render: (quote_id, { pilot }) => {
				return (
					<Button style={{ marginLeft: '-12px' }} type='link' onClick={() => scope.toggleDrawer(quote_id, pilot)}>
						Chat
					</Button>
				)
			},
		},
	]
}

export const consumerTableColumns = scope => {
	return [
		{
			title: 'Customer Name',
			dataIndex: 'consumer',
			key: 'consumer',
			render: consumer => consumer?.name,
		},
		{
			title: 'Messages',
			dataIndex: 'quote_id',
			key: 'quote_id',
			render: (quote_id, { consumer }) => {
				return (
					<Button style={{ marginLeft: '-12px' }} type='link' onClick={() => scope.toggleDrawer(quote_id, consumer)}>
						Chat
					</Button>
				)
			},
		},
	]
}

export const projectStatusDetailsColumns = [
	{
		title: 'Project Status',
		dataIndex: 'statusName',
		key: 'statusName',
	},
	{
		title: 'Description',
		dataIndex: 'description',
		key: 'description',
	},
	{
		title: 'Action',
		dataIndex: 'action',
		key: 'action',
	},
]

export const projectStatusDetails = [
	{
		key: '1',
		statusName: 'Open for bidding',
		description: 'Awaiting bids from the Pilots',
		action: 'Taken by Client',
	},
	{
		key: '2',
		statusName: 'Bid Accepted',
		description: 'Bid accepted by the client',
		action: 'Taken by Client',
	},
	{
		key: '3',
		statusName: 'In-flight',
		description: 'Waiting for the pilot to complete the flight and submit the drone data',
		action: 'Taken by Pilot',
	},
	{
		key: '4',
		statusName: 'Data Delivered',
		description: 'Data submitted by the pilot. To be reviewed by client within 7 days of the submission.',
		action: 'Taken by Pilot',
	},
	{
		key: '5',
		statusName: 'Data Accepted',
		description: 'Data deemed satisfactory by the client',
		action: 'Taken by Client',
	},
	{
		key: '6',
		statusName: 'Data Rejected',
		description: 'Try having a chat with the pilot to explain them your requirements and expectation',
		action: 'Taken by Client',
	},
	{
		key: '7',
		statusName: 'Completed',
		description: 'Project Completed and the payment has been made to the pilot',
		action: 'Taken by Client',
	},
]

export const enterpriseProjectStatusDetails = [
	{
		key: '1',
		statusName: 'Project Allocated',
		description: 'Project allocated by Client',
		action: 'Taken by Client',
	},
	{
		key: '3',
		statusName: 'In-flight',
		description: 'Waiting for pilot to complete Drone Flight',
		action: 'Taken by Pilot',
	},
	{
		key: '4',
		statusName: 'Data Delivered',
		description: 'Data submitted by the pilot. To be reviewed by client within 7 days of the submission',
		action: 'Taken by Pilot',
	},
	{
		key: '5',
		statusName: 'Data Accepted',
		description: 'Data deemed satisfactory by the client',
		action: 'Taken by Client',
	},
	{
		key: '6',
		statusName: 'Data Rejected',
		description: "Data deemed as unsatisfactory. You'll soon hear back from the pilot or the Aerologix support team",
		action: 'Taken by Client',
	},
	{
		key: '7',
		statusName: 'Completed',
		description: 'Payment done to the pilot',
		action: 'Taken by Aerologix',
	},
]
