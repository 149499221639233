import React from 'react'
import { Input } from 'antd'

export const COMPANY_DETAILS_FIELDS_AU = viewMode => {
	return [
		{
			fieldName: 'COMPANY_NAME',
			fieldLabel: 'Company Name',
			fieldKey: 'companyName',
			fileObject: {
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} disabled={viewMode} />,
		},
		{
			fieldName: 'COMPANY_ADDRESS',
			fieldLabel: 'Company Address',
			fieldKey: 'companyAddress',
			fileObject: {
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} disabled={viewMode} />,
		},
		{
			fieldName: 'ACN_NUMBER',
			fieldLabel: 'Australian Company Number (ACN)',
			fieldKey: 'acnNumber',
			fileObject: {
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} disabled={viewMode} />,
		},
		{
			fieldName: 'ABN_NUMBER',
			fieldLabel: 'Australian Business Number (ABN)',
			fieldKey: 'abnNumber',
			fileObject: {
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} disabled={viewMode} />,
		},
		{
			fieldName: 'GST_NUMBER',
			fieldLabel: 'GST Number',
			fieldKey: 'gstNumber',
			fileObject: {
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} disabled={viewMode} />,
		},
	]
}

export const COMPANY_DETAILS_FIELDS_NZ = viewMode => {
	return [
		{
			fieldName: 'COMPANY_NAME',
			fieldLabel: 'Company Name',
			fieldKey: 'companyName',
			fileObject: {
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} disabled={viewMode} />,
		},
		{
			fieldName: 'COMPANY_ADDRESS',
			fieldLabel: 'Company Address',
			fieldKey: 'companyAddress',
			fileObject: {
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} disabled={viewMode} />,
		},
		{
			fieldName: 'ABN_NUMBER',
			fieldLabel: 'New Zealand Business Number (NZBN)',
			fieldKey: 'abnNumber',
			fileObject: {
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} disabled={viewMode} />,
		},
		{
			fieldName: 'GST_NUMBER',
			fieldLabel: 'GST Number',
			fieldKey: 'gstNumber',
			fileObject: {
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} disabled={viewMode} />,
		},
	]
}
