export const CUSTOM_PLAN_FIELD_METADATA = {
	NUMPL: {
		label: 'Number of Plans',
		key: 'numberOfPlans',
	},
	ORG: {
		label: 'Organisation',
		key: 'organisation',
	},
	ADDNREQ: {
		label: 'Additional Requirement',
		key: 'additionalRequirement',
	},
}

export const FREE_TRIAL_FIELD_METADATA = {
	FNAME: {
		label: 'First Name',
		key: 'firstName',
	},
	LNAME: {
		label: 'Last Name',
		key: 'lastName',
	},
}
