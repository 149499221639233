import React, { useEffect, useState } from 'react'
import AbnDetails from '../../../Profile/Components/abn-details'
import { getWebConfig } from '../../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const AbnTab = props => {
	const { abn, updateAbnDetails, webConfig, getWebConfig } = props
	const [configData, setConfigData] = useState(webConfig)
	const [waitForApi, setWaitForApi] = useState(false)

	useEffect(() => {
		if (!configData) {
			setWaitForApi(true)
			getWebConfig()
		}
	}, [configData])

	useEffect(() => {
		if (webConfig && waitForApi) {
			setWaitForApi(false)
			setConfigData(webConfig)
		}
	}, [webConfig])
	return (
		<>
			{abn ? (
				<>
					<div className='icon-box'>
						<strong>{configData.countryId === 1 ? 'ABN' : 'NZBN'}</strong>
					</div>
					<div className='label-box'>{configData.countryId === 1 ? 'Australian' : 'New Zealand'} Business Number</div>
					<div className='value-box'>
						<strong>{abn}</strong>
					</div>
				</>
			) : (
				<AbnDetails updateAbnDetails={updateAbnDetails} />
			)}
		</>
	)
}

const mapStateToProps = state => {
	return {
		webConfig: state.UserSessionReducer.webConfig,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AbnTab)
