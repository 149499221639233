/*eslint-disable*/
import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Slider, Checkbox } from 'antd'

const marks = {
	0: '0 km',
	300: '300 kms',
}

export default class ProjectArea extends React.Component {
	formatter = value => {
		return `${value} kms`
	}
	render() {
		const { getFieldDecorator } = this.props.form
		return (
			<React.Fragment>
				<Form.Item label={'Radius Of Notification (Km)'}>
					{getFieldDecorator('projectRadius', {
						initialValue: this.props.data ? this.props.data.projectRadius : undefined,
					})(<Slider style={{ margin: '30px' }} marks={marks} tipFormatter={this.formatter} max={300} />)}
				</Form.Item>
				{/* <Form.Item>
					{getFieldDecorator('pushNotifications', {
						valuePropName: 'checked',
						initialValue: this.props.data ? this.props.data.pushNotifications : undefined,
						rules: [],
					})(<Checkbox>Allow Push Notifications</Checkbox>)}
				</Form.Item> */}
			</React.Fragment>
		)
	}
}
