// react & redux imports
import React, { Component } from 'react'
import '../projects.sass'
// const DEFAULT_TEMPLATE = 'PROJECT_TYPE_CARD';

class ProjectTypeSelect extends Component {
	render() {
		return (
			<div>
				<div style={{ padding: '10px', paddingLeft: '35px' }}>
					<img src={this.props.icon} alt={this.props.icon} />
					<div className='project-type-text'>{this.props.title}</div>
				</div>
			</div>
		)
	}
}

export default ProjectTypeSelect
