import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './support.sass'
import { UploadOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { Card, Button, Collapse, message, Input, Upload, Result, Row, Image } from 'antd'
import { getAccountManager, createSupportTicket } from '../../Stores/Projects/action'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import AeroModal from '../../AeroComponents/Modal/index'

const { Panel } = Collapse

class SupportForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {},
			acManagerModal: false,
			createTicketModal: false,
			createTicketModalSuccess: false,
			fileList: [],
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.accountManagerData !== nextProps.accountManagerData) {
			this.setState({
				data: nextProps.accountManagerData,
			})
		}
		if (this.props.createSupportTicketSuccess !== nextProps.createSupportTicketSuccess) {
			if (nextProps.createSupportTicketSuccess) {
				this.setState({ createTicketModal: false, createTicketModalSuccess: true })
			}
		}
	}

	componentDidMount() {
		this.props.getAccountManager()
	}

	toggleAcManagerModal = () => {
		const acManagerModal = this.state.acManagerModal
		this.setState({
			acManagerModal: !acManagerModal,
		})
	}

	toggleCreateTicketModal = () => {
		const createTicketModal = this.state.createTicketModal
		this.setState({
			createTicketModal: !createTicketModal,
		})
	}

	closeResultModal = () => {
		this.setState({ createTicketModalSuccess: false })
	}

	copyItem = id => {
		const phoneNumber = this.state.data.mobileNumber
		const email = this.state.data.email
		if (id === 1) {
			navigator.clipboard.writeText(phoneNumber)
			message.success('Phone number copied to clipboard!')
		} else {
			navigator.clipboard.writeText(email)
			message.success('Email ID copied to clipboard!')
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.form.resetFields()
				const { fileList } = this.state
				let body = new FormData()
				if (fileList.length !== 0) {
					let fileData
					fileList.forEach(file => {
						fileData = file
					})
					body.append('attachment', fileData)
				}

				body.append('subject', values.subject)
				body.append('description', values.description)
				this.props.createSupportTicket(body)
			}
		})
	}

	render() {
		const { data, acManagerModal, createTicketModal, fileList, createTicketModalSuccess } = this.state
		const { getFieldDecorator } = this.props.form
		const props = {
			onRemove: file => {
				this.setState(state => {
					const index = state.fileList.indexOf(file)
					const newFileList = state.fileList.slice()
					newFileList.splice(index, 1)
					return {
						fileList: newFileList,
					}
				})
			},
			beforeUpload: file => {
				const isJpgOrPngOrPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
				if (!isJpgOrPngOrPdf) {
					message.error('You can only upload JPEG/PNG/PDF file!')
				}
				const isLt2M = file.size / 1024 / 1024 < 10
				if (!isLt2M) {
					message.error('File must be smaller than 10 MB!')
				}
				if (isJpgOrPngOrPdf && isLt2M) {
					this.setState(state => ({
						fileList: [...state.fileList, file],
					}))

					return false
				}
			},
			fileList,
		}
		return (
			<div className='page-container'>
				<Card className='en-support-top-card-1'>
					<Row>
						<span className='en-raise-ticket-elem-1'>
							<MatImage src={IMAGES_SRC.RAISE_TICKET_ICON} />
						</span>
						<span className='en-raise-ticket-elem-2'>Raise a Ticket</span>
					</Row>
					<Row>
						<div className='en-raise-ticket-btn'>
							<Button onClick={this.toggleCreateTicketModal} style={{ width: '184px', height: '32px', borderRadius: '8px' }} type='primary'>
								Create Ticket
							</Button>
						</div>
					</Row>
				</Card>
				<Card className='en-support-top-card-2'>
					<Row>
						<span className='en-raise-ticket-elem-1'>
							{data.profilePicture ? (
								<div className='circular'>
									<MatImage style={{ borderRadius: '50%', maxWidth: '100px' }} src={data.profilePicture} />
								</div>
							) : (
								<MatImage style={{ height: '45px', width: '45px' }} src={IMAGES_SRC.DP_PLACEHOLDER} />
							)}
						</span>
						<span className='en-ac-manager-elem-2'>
							Account Manager :{' '}
							<Button onClick={this.toggleAcManagerModal} type='link'>
								{data.firstName}
							</Button>
						</span>
					</Row>
					<Row>
						<div className='en-ac-manager-elem-3'>
							<strong>{data.mobileNumber}</strong>
						</div>
						<div className='en-ac-manager-elem-4'>
							<strong>{data.email}</strong>
						</div>
					</Row>
				</Card>
				<div className='projects-heading' style={{ marginLeft: '30px', marginBottom: '20px', marginTop: '20px' }}>
					Frequently Asked Questions
				</div>
				<div style={{ marginTop: '20px' }}>
					<Collapse className='en-faq-item-wrapper'>
						<Panel header='What are Aerocredits, and how can I use them?'>
							<p>Aerocredits are your key to unlocking a world of possibilities, allowing you to make bulk purchases tailored to your future project requirements.</p>
						</Panel>
					</Collapse>
					<Collapse className='en-faq-item-wrapper'>
						<Panel header='How do I add a team member to my account to review data?'>
							<ol>
								<li>Navigate to Profile</li>
								<li>'Add Collaborator'</li>
								<li>Enter team member details. See the snapshots below:</li>
							</ol>
							<Image width={200} src={IMAGES_SRC.SUPPORT_THUMB_1} preview={{ src: IMAGES_SRC.PROFILE_DETAILS }} />
							<Image width={200} style={{ marginLeft: '15px' }} src={IMAGES_SRC.SUPPORT_THUMB_2} preview={{ src: IMAGES_SRC.ADD_COLLABORATOR }} />
						</Panel>
					</Collapse>
					<Collapse className='en-faq-item-wrapper'>
						<Panel header="Why can't the collaborator I added see any projects?">
							<p>
								Ensure that the permissions have also been enabled per collaborator. See below for an example of how to change permission for Orders and Projects:
							</p>
							<p>Orders are the clusters of sites by State or month. Best to provide access to all orders if your team shares the workload.</p>
							<Image width={200} src={IMAGES_SRC.SUPPORT_THUMB_3} preview={{ src: IMAGES_SRC.EDIT_PERMISSION }} />
							<Image width={200} style={{ marginLeft: '15px' }} src={IMAGES_SRC.SUPPORT_THUMB_4} preview={{ src: IMAGES_SRC.EDIT_PERMISSION_OVERVIEW }} />
						</Panel>
					</Collapse>
					<Collapse className='en-faq-item-wrapper'>
						<Panel header='How do I view the requested project data?'>
							<ol>
								<li>Access the Enterprise Client Portal by logging in with your credentials.</li>
								<li>Navigate to the 'Orders' section found in the left-side menu and click on it.</li>
								<li>Look for your specific order which includes your project and select it.</li>
								<li>Once there, switch to the 'Details' tab. Here, you'll find the status of all projects within the selected order.</li>
								<li>For any project that is marked as completed, you have the option to preview or download its respective data.</li>
								<li>Alternatively, you can view your data by heading to the 'Data' tab.</li>
							</ol>
							<p>Please take a look at the tutorial video below that provides a detailed walkthrough on viewing your project data.
							</p>
							<iframe width="770" height="415"
								src="https://www.youtube.com/embed/CBlIkUTRmIo">
							</iframe>
						</Panel>
					</Collapse>
					<Collapse className='en-faq-item-wrapper'>
						<Panel header='How do I place an order?'>
							<ol>
								<li>Navigate to the 'Orders' section found in the left-side menu and click on it.</li>
								<li>On the top right corner, find the “Enquire for a new order” option.</li>
								<li>Fill in required fields and submit.</li>
								<li>A member of the Aerologix team will connect with you shortly.</li>
							</ol>
							<p>Please take a look at the tutorial video below that provides a detailed walkthrough on placing an enquiry for a new order.
							</p>
							<iframe width="770" height="415"
								src="https://www.youtube.com/embed/2pwyldw7AOU">
							</iframe>
						</Panel>
					</Collapse>
					<Collapse className='en-faq-item-wrapper'>
						<Panel header='What quality and safety assurance do I have when contracting pilots through Aerologix?'>
							<p>
								All Aerologix pilots have access to their respective country’s airspace information, allowing them to remain compliant with the governing aviation
								authority body regulations;{' '}
								<a style={{ color: '#00B3FF' }} href={'https://www.casa.gov.au/'} target='_blank'>
									Civil Aviation Safety Authority
								</a>
								in Australia or{' '}
								<a style={{ color: '#00B3FF' }} href={'https://www.aviation.govt.nz/'} target='_blank'>
									Civil Aviation Authority
								</a>
								in New Zealand. For Enterprise clients such as yourselves, our Aerologix Operations team hand-pick the pilot/s best suited to your project/s based
								on experience, qualifications, professionalism and communication skills.
							</p>
							<p>
								Rest assured, Aerologix also ensures our Chief Remote Pilot reviews all flight plans for enterprise projects to confirm they comply with{' '}
								<a style={{ color: '#00B3FF' }} href={'https://www.casa.gov.au/'} target='_blank'>
									CASA
								</a>{' '}
								and{' '}
								<a style={{ color: '#00B3FF' }} href={'https://www.aviation.govt.nz/'} target='_blank'>
									CAA
								</a>{' '}
								regulations and that pilots assigned to such projects are appropriately qualified and experienced, with relevant licences and training, if
								necessary.
							</p>
						</Panel>
					</Collapse>
				</div>
				<AeroModal
					style={{ width: '450px' }}
					bodyStyle={{ background: '#F5F5F5' }}
					title='Account manager Details'
					footer={false}
					visible={acManagerModal}
					onCancel={this.toggleAcManagerModal}
				>
					<div style={{ textAlign: 'center' }}>
						{data.profilePicture ? (
							<div className='circular'>
								<MatImage style={{ borderRadius: '50%', maxWidth: '100px' }} src={data.profilePicture} />
							</div>
						) : (
							<MatImage style={{ width: '154px', height: '154px' }} src={IMAGES_SRC.DP_PLACEHOLDER} />
						)}
						<div style={{ padding: '15px', fontSize: '18px' }}>
							<strong>{data.firstName}</strong>
						</div>
					</div>
					<div style={{ padding: '15px' }}>
						<div style={{ marginBottom: '20px' }}>
							<strong>Phone Number</strong>
							<span style={{ marginLeft: '205px' }}>
								{data.mobileNumber}
								<Button
									type='link'
									onClick={() => {
										this.copyItem(1)
									}}
								>
									<MatImage src={IMAGES_SRC.COPY_ICON} />
								</Button>
							</span>
						</div>
						<div>
							<strong>Email ID:</strong>
							<span style={{ marginLeft: '185px' }}>
								{data.email}
								<Button
									type='link'
									onClick={() => {
										this.copyItem(2)
									}}
								>
									<MatImage src={IMAGES_SRC.COPY_ICON} />
								</Button>
							</span>
						</div>
					</div>
				</AeroModal>
				<AeroModal bodyStyle={{ background: '#F5F5F5' }} title='Create Ticket' footer={false} visible={createTicketModal} onCancel={this.toggleCreateTicketModal}>
					<Form onSubmit={this.handleSubmit} layout='vertical' encType='multipart/form-data'>
						<Form.Item label='Subject'>
							{getFieldDecorator('subject', { rules: [{ required: true }] })(<Input style={{ borderRadius: '4px' }} placeholder='Type here'></Input>)}
						</Form.Item>
						<Form.Item label='Description'>
							{getFieldDecorator('description', { rules: [{ required: true }] })(
								<Input.TextArea style={{ borderRadius: '4px' }} placeholder='Please describe your issue' rows={10} />,
							)}
						</Form.Item>
						<Form.Item label='Upload Attachment (jpeg, png or pdf)'>
							<Upload {...props}>
								<Button style={{ borderRadius: '4px', width: '470px' }}>
									<UploadOutlined style={{ position: 'relative', top: '-4px' }} type='upload-o' /> Click to Upload
								</Button>
							</Upload>
						</Form.Item>
						<Form.Item>
							<Button htmlType='submit' style={{ borderRadius: '8px' }} type='primary' block>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</AeroModal>
				<AeroModal visible={createTicketModalSuccess} footer={false} onCancel={this.closeResultModal}>
					<Result status='success' title='Ticket submitted successfully!' subTitle='Our team will shortly contact you on given contact details' />
				</AeroModal>
			</div>
		)
	}
}

const Support = Form.create({ name: 'SUPPORT_FORM' })(SupportForm)

const mapStateToProps = state => {
	return {
		accountManagerData: state.ProjectsReducer.accountManagerData,
		createSupportTicketSuccess: state.ProjectsReducer.createSupportTicketSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAccountManager: bindActionCreators(getAccountManager, dispatch),
		createSupportTicket: bindActionCreators(createSupportTicket, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Support)
