import React from 'react'
import { Input } from 'antd'

export const FORGOT_PASSWORD_FILEDS = [
	{
		fieldName: 'EMAIL',
		fieldLabel: 'Email ID (Enter the email you used to create your account. You will receive a newly generated password)',
		fieldKey: 'email',
		fileObject: {
			rules: [
				{
					type: 'email',
					message: 'The input is not valid E-mail!',
					whitespace: true,
				},
				{
					required: true,
					message: 'Please input your E-mail!',
				},
			],
		},
		fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
	},
]
