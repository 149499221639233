import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import '../Home/home.sass'
import { BellTwoTone, CloseOutlined } from '@ant-design/icons'
import { Tabs, Button, Card, Alert, Input, message, Badge } from 'antd'
import { getValueFromStorage } from '../../Services/LocalStorageService'
import { bindActionCreators } from 'redux'
import {
	getProjects,
	updateQuote,
	getDownloadUrl,
	getAllProjectsByStatus,
	getAllInvitations,
	getAllProjectsHistory,
	getInvitationsCount,
	getBiddableProjects,
} from '../../Stores/Projects/action'
import { sendInvoiceForProject } from '../../Stores/Payments/action'
import { getProfile } from '../../Stores/UserSession/action'
import { connect } from 'react-redux'
import AeroModal from '../../AeroComponents/Modal'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { goTo } from '../../Services/NavigationService'
import ScrollableContent from '../Home/Components/scrollable-content'
import { capitalize } from '../../Services/UtilityService'
const { TabPane } = Tabs

class EnterpriseProjectsForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			profileType: getValueFromStorage('PROFILE_TYPE'),
			ongoing: [],
			pending: [],
			completed: [],
			biddable: [],
			history: [],
			updateProjectState: false,
			projectState: '',
			selectedProjectAmount: 0,
			selectedProjectQuoteId: '',
			enterprisePilotBanner: false,
			totalOngoing: 1,
			currentOngoingPage: 1,
			totalPending: 1,
			currentPendingPage: 1,
			totalCompleted: 1,
			totalBiddable: 1,
			totalHistory: 1,
			currentCompletedPage: 1,
			currentBiddablePage: 1,
			currentHistoryPage: 1,
			invitationsCount: 0,
			currentFilter: [],
			searchToken: '',
			filterBoxVisible: false,
			filterBtnType: '',
			currentTab: 'ongoing',
			toggleMailBox: false,
			selectedProject: '',
			userEmail: '',
			profileData: {},
			participationBoxVisible: false,
			compBannerVisible: false,
			loading: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.updateQuoteSuccess !== nextProps.updateQuoteSuccess) {
			if (nextProps.updateQuoteSuccess) {
				this.setState({ updateProjectState: false })
				this.props.getAllInvitations({ pageNumber: this.state.currentPendingPage, pageSize: 10, status: 'PENDING', isEnterprise: 0 })
				this.props.getInvitationsCount({ status: 'PENDING' })
			}
		}
		if (this.props.downloadUrlData !== nextProps.downloadUrlData) {
			if (nextProps.downloadUrlData) {
				window.location.replace(nextProps.downloadUrlData)
			}
		}
		if (this.props.profileData !== nextProps.profileData) {
			if (nextProps.profileData) {
				this.setState({
					userEmail: nextProps.profileData.email,
					profileData: nextProps.profileData,
					enterprisePilotBanner:
						nextProps.profileData.enterpriseBannerDetails?.visibility && nextProps.profileData.enterpriseBannerDetails.state === 'VERIFICATION_FAILED' ? true : false,
				})
			}
		}
		if (this.props.projectsByStatusData !== nextProps.projectsByStatusData) {
			if (nextProps.projectsByStatusData) {
				const { currentTab } = this.state
				const total = 'total' + capitalize(currentTab)
				const prevValue = this.state[currentTab]
				if (prevValue.length && this.state[`current${capitalize(currentTab)}Page`] === 1) {
					this.setState({ [currentTab]: nextProps.projectsByStatusData.data, [total]: nextProps.projectsByStatusData.count, loading: false })
				} else {
					this.setState({ [currentTab]: [...prevValue, ...nextProps.projectsByStatusData.data], [total]: nextProps.projectsByStatusData.count, loading: false })
				}
			}
		}
		if (this.props.invitationsData !== nextProps.invitationsData) {
			if (nextProps.invitationsData) {
				const { currentTab } = this.state
				const total = 'total' + capitalize(currentTab)
				const prevValue = this.state[currentTab]
				if (prevValue.length && this.state[`current${capitalize(currentTab)}Page`] === 1) {
					this.setState({ [currentTab]: nextProps.invitationsData.data, [total]: nextProps.invitationsData.count, loading: false })
				} else {
					this.setState({ [currentTab]: [...prevValue, ...nextProps.invitationsData.data], [total]: nextProps.invitationsData.count, loading: false })
				}
			}
		}
		if (this.props.biddableProjectsData !== nextProps.biddableProjectsData) {
			if (nextProps.biddableProjectsData) {
				if (this.state.biddable.length && this.state.currentBiddablePage === 1) {
					this.setState({ biddable: nextProps.biddableProjectsData.data, totalBiddable: nextProps.biddableProjectsData.count, loading: false })
				} else {
					this.setState({
						biddable: [...this.state.biddable, ...nextProps.biddableProjectsData.data],
						totalBiddable: nextProps.biddableProjectsData.count,
						loading: false,
					})
				}
			}
		}
		if (this.props.projectsHistoryData !== nextProps.projectsHistoryData) {
			if (nextProps.projectsHistoryData) {
				const { currentTab } = this.state
				const total = 'total' + capitalize(currentTab)
				const prevValue = this.state[currentTab]
				if (prevValue.length && this.state[`current${capitalize(currentTab)}Page`] === 1) {
					this.setState({ [currentTab]: nextProps.projectsHistoryData.data, [total]: nextProps.projectsHistoryData.count, loading: false })
				} else {
					this.setState({ [currentTab]: [...prevValue, ...nextProps.projectsHistoryData.data], [total]: nextProps.projectsHistoryData.count, loading: false })
				}
			}
		}
		if (this.props.invitationsCount !== nextProps.invitationsCount) {
			if (nextProps.invitationsCount) {
				this.setState({ invitationsCount: nextProps.invitationsCount.count })
			}
		}
		if (this.props.sendInvoiceForProjectSuccess !== nextProps.sendInvoiceForProjectSuccess) {
			if (nextProps.sendInvoiceForProjectSuccess) {
				this.toggleMailBox()
				message.success(
					<span>
						Invoice sent to <strong>{nextProps.sendInvoiceForProjectSuccess.message}</strong>{' '}
					</span>,
				)
			}
		}
	}

	componentDidMount() {
		this.props.getProfile()
		this.props.getAllProjectsByStatus({ pageNumber: 1, pageSize: 10, status: 'ONGOING' })
		let compBannerVisible = false
		if (window.sessionStorage.getItem('compBannerVisible') === null) {
			window.sessionStorage.setItem('compBannerVisible', true)
			compBannerVisible = true
		} else {
			if (window.sessionStorage.getItem('compBannerVisible') === 'false') {
				compBannerVisible = false
			} else {
				compBannerVisible = true
			}
		}
		this.setState({ compBannerVisible: compBannerVisible })
		this.props.getInvitationsCount({ status: 'PENDING' })
	}

	updateProjectState = status => {
		this.setState({ updateProjectState: true, projectState: status })
	}

	handleCancel = modalType => {
		this.setState({
			[modalType]: false,
		})
	}

	showModal = (quoteId, amount, status) => {
		this.setState({ updateProjectState: true, projectState: status, selectedProjectAmount: amount, selectedProjectQuoteId: quoteId })
	}

	updateQuoteStatus = () => {
		let body = {
			amount: Number(this.state.selectedProjectAmount),
			quoteId: this.state.selectedProjectQuoteId,
			status: this.state.projectState,
		}
		this.props.updateQuote(body)
	}

	goToProject = () => {
		this.props.history.push({ pathname: '/create-new-project' })
	}

	downloadData = projectId => {
		this.props.getDownloadUrl({ type: 'upload', project: projectId })
	}

	changeOngoingPage = page => {
		this.setState({ currentOngoingPage: page })
		this.props.getAllProjectsByStatus({ pageNumber: page, pageSize: 10, status: 'ONGOING', isEnterprise: 1 })
	}

	changeInvitationsPage = page => {
		this.setState({ currentPendingPage: page })
		this.props.getAllInvitations({ pageNumber: page, pageSize: 10, status: 'PENDING', isEnterprise: 1 })
	}

	changeHistoryPage = page => {
		this.setState({ currentHistoryPage: page })
		this.props.getAllProjectsHistory({ pageNumber: page, pageSize: 10, isEnterprise: 1 })
	}

	onTabChange = tab => {
		if (tab === 'ongoing') {
			this.setState({ currentTab: 'ongoing', currentOngoingPage: 1 })
			this.props.getAllProjectsByStatus({ pageNumber: 1, pageSize: 10, status: 'ONGOING', isEnterprise: 1 })
		} else if (tab === 'pending') {
			this.setState({ currentTab: 'pending', currentPendingPage: 1 })
			this.props.getAllInvitations({ pageNumber: this.state.currentPendingPage, pageSize: 10, status: 'PENDING', isEnterprise: 1 })
		} else if (tab === 'history') {
			this.setState({ currentTab: 'history', currentHistoryPage: 1 })
			this.props.getAllProjectsHistory({ pageNumber: this.state.currentHistoryPage, pageSize: 10, isEnterprise: 1 })
		}
	}

	onSearch = value => {
		if (value) {
			if (value.length < 3) {
				message.error('Please enter at least three letters!')
			} else {
				this.props.getAllProjectsHistory({ pageNumber: 1, pageSize: 10, filters: this.state.currentFilter.toString(), searchToken: value })
				this.setState({ searchToken: value })
			}
		} else {
			this.props.getAllProjectsHistory({ pageNumber: 1, pageSize: 10, filters: this.state.currentFilter.toString() })
			this.setState({ searchToken: '' })
		}
	}

	onFilterChange = value => {
		this.setState({ currentFilter: value })
	}

	toggleFilterBox = () => {
		let filterBtnType = this.state.filterBtnType
		filterBtnType = filterBtnType === 'primary' ? '' : 'primary'
		this.setState(prevState => ({
			filterBoxVisible: !prevState.filterBoxVisible,
			filterBtnType: filterBtnType,
		}))
	}

	applyFilter = () => {
		this.toggleFilterBox()
		this.props.getAllProjectsHistory({ pageNumber: 1, pageSize: 10, filters: this.state.currentFilter.toString(), searchToken: this.state.searchToken })
	}

	resetFilter = () => {
		this.toggleFilterBox()
		this.setState({ currentFilter: [] })
		this.props.getAllProjectsHistory({ pageNumber: 1, pageSize: 10, searchToken: this.state.searchToken })
	}

	toggleMailBox = () => {
		this.setState(prevState => ({ mailBoxVisible: !prevState.mailBoxVisible }))
	}

	mailSingleInvoice = project => {
		this.setState({ selectedProject: project, mailBoxVisible: true })
	}

	sendSingleInvoice = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const body = {
					email: values.email,
					projectId: this.state.selectedProject.id,
				}
				this.props.sendInvoiceForProject(body)
				this.props.form.resetFields()
			} else {
				message.error('Please enter email!')
			}
		})
	}

	toggleParticipationBox = () => this.setState(prevState => ({ participationBoxVisible: !prevState.participationBoxVisible }))
	closeCompBanner = () => {
		this.setState({
			compBannerVisible: false,
		})
		window.sessionStorage.setItem('compBannerVisible', 'false')
	}

	loadMoreData = type => {
		if (this.state.loading) return
		this.setState({ loading: true })
		if (type === 'ongoing') {
			this.changeOngoingPage(this.state.currentOngoingPage + 1)
		} else if (type === 'pending') {
			this.changeInvitationsPage(this.state.currentPendingPage + 1)
		} else if (type === 'history') {
			this.changeHistoryPage(this.state.currentHistoryPage + 1)
		}
	}

	openProjectDetails = projectId => goTo(`projects/${projectId}`)

	render() {
		const {
			ongoing,
			pending,
			profileType,
			enterprisePilotBanner,
			totalOngoing,
			totalPending,
			invitationsCount,
			history,
			totalHistory,
			mailBoxVisible,
			selectedProject,
			userEmail,
			compBannerVisible,
		} = this.state
		const { getFieldDecorator } = this.props.form
		return (
			<React.Fragment>
				{enterprisePilotBanner ? (
					<div style={{ padding: '20px' }}>
						<Alert
							message={<span style={{ color: 'white', paddingLeft: '15px' }}>Your application for Enterprise program has been rejected</span>}
							description={
								<span>
									<span style={{ color: 'white', paddingLeft: '15px', fontSize: '13px' }}>The uploaded insurance document has been rejected</span>
									<span className='remind-me-later'>
										<Button
											onClick={() => goTo('settings')}
											style={{ background: 'white', color: '#FA541C', width: '168px', borderRadius: '8px', fontSize: '16px' }}
										>
											Retry
										</Button>
									</span>
								</span>
							}
							type='info'
							closable
							closeText={<span style={{ color: 'white' }}>X</span>}
							showIcon
							icon={
								<span>
									<MatImage src={IMAGES_SRC.BIG_WHITE_EXCLAMATION_ICON} />
								</span>
							}
							className='enterprise-pilot-eligiblity-alert-failed'
						/>
					</div>
				) : null}

				{this.state.profileType === 'PILOT' && compBannerVisible ? (
					<Badge.Ribbon
						style={{ marginLeft: '21px', height: '30px' }}
						text={
							<span style={{ position: 'relative', top: '5px', padding: '10px' }}>
								<BellTwoTone twoToneColor='#FFC53D' style={{ position: 'relative', bottom: '3px', right: '5px' }} />
								<span style={{ color: 'black' }}>REWARD ALERT!</span>
							</span>
						}
						color='white'
						placement='start'
					>
						<Card className='competition-banner'>
							{this.state.profileData?.completed ? (
								<strong>
									<CloseOutlined
										onClick={this.closeCompBanner}
										style={{ float: 'right', color: 'white', fontSize: '20px', position: 'relative', bottom: '9px', left: '12px' }}
									/>
								</strong>
							) : null}
							<div style={{ color: 'white', marginTop: '25px' }}>
								<p style={{ fontSize: '20px' }}>Submit your video footage and be rewarded!</p>
								<p style={{ fontSize: '16px' }}>
									Aerologix’s exclusive relationship with Getty Images allows you to earn recurring royalties on uploaded footage (via a licensing agreement).
								</p>
								<p>
									{this.state.profileData?.arnLicense?.verificationStatus === 'VERIFICATION_SUCCEEDED' &&
									this.state.profileData?.firstName &&
									this.state.profileData?.lastName &&
									this.state.profileData?.email &&
									this.state.profileType === 'PILOT' ? (
										<span>
											<MatImage src={IMAGES_SRC.CELEBRATION_ICON} /> You are eligible to upload your footage(videos) on our platform!
										</span>
									) : (
										'Go to the Profile tab and add your ARN. Once your ARN is verified, the "Footage" tab will be visible on the left menu.'
									)}
								</p>
							</div>
						</Card>
					</Badge.Ribbon>
				) : null}
				<div style={{ padding: '25px' }}>
					<div className='projects-heading'>Enterprise Projects</div>

					<Tabs style={{ marginTop: '20px' }} onChange={this.onTabChange} defaultActiveKey='ongoing'>
						<TabPane tab='Ongoing' key='ongoing'>
							<div id='ongoingDiv' style={{ overflow: 'auto', height: '75vh' }}>
								<ScrollableContent
									projectType='enterprise'
									profileType={profileType}
									openProjectDetails={this.openProjectDetails}
									data={ongoing}
									loadMoreData={this.loadMoreData}
									totalData={totalOngoing}
									type='ongoing'
									divId='ongoingDiv'
								/>
							</div>
						</TabPane>
						<TabPane tab={<span>Invitations {invitationsCount !== 0 ? <MatImage src={IMAGES_SRC.INCOMPLETE_ICON} /> : ''}</span>} key='pending'>
							<div id='pendingDiv' style={{ overflow: 'auto', height: '75vh' }}>
								<ScrollableContent
									projectType='enterprise'
									profileType={profileType}
									openProjectDetails={this.openProjectDetails}
									data={pending}
									loadMoreData={this.loadMoreData}
									totalData={totalPending}
									type='pending'
									divId='pendingDiv'
								/>
							</div>
						</TabPane>
						<TabPane tab='History' key='history'>
							<div id='historyDiv' style={{ overflow: 'auto', height: '75vh' }}>
								<ScrollableContent
									projectType='enterprise'
									profileType={profileType}
									openProjectDetails={this.openProjectDetails}
									data={history}
									loadMoreData={this.loadMoreData}
									totalData={totalHistory}
									type='history'
									divId='historyDiv'
								/>
							</div>
						</TabPane>
					</Tabs>
				</div>
				<AeroModal
					visible={mailBoxVisible}
					title={
						<span>
							<strong>Send Invoice: </strong> {selectedProject.name}
						</span>
					}
					onCancel={this.toggleMailBox}
					footer={false}
				>
					<Form layout='vertical' onSubmit={this.sendSingleInvoice}>
						<Form.Item label='Email Address'>
							{getFieldDecorator('email', {
								rules: [
									{
										required: true,
										message: 'Please enter email!',
										whitespace: true,
									},
								],
								initialValue: userEmail,
							})(<Input />)}
						</Form.Item>
						<Form.Item>
							<Button type='primary' htmlType='submit' style={{ borderRadius: '8px' }} block>
								Send
							</Button>
						</Form.Item>
					</Form>
				</AeroModal>
			</React.Fragment>
		)
	}
}
const Home = Form.create({ name: 'HOME_FORM' })(EnterpriseProjectsForm)
const mapStateToProps = state => {
	return {
		projectData: state.ProjectsReducer.projectData,
		updateQuoteSuccess: state.ProjectsReducer.updateQuoteSuccess,
		downloadUrlData: state.ProjectsReducer.downloadUrlData,
		profileData: state.UserSessionReducer.profileData,
		projectsByStatusData: state.ProjectsReducer.projectsByStatusData,
		invitationsData: state.ProjectsReducer.invitationsData,
		projectsHistoryData: state.ProjectsReducer.projectsHistoryData,
		invitationsCount: state.ProjectsReducer.invitationsCount,
		sendInvoiceForProjectSuccess: state.PaymentsReducer.sendInvoiceForProjectSuccess,
		biddableProjectsData: state.ProjectsReducer.biddableProjectsData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getProjects: bindActionCreators(getProjects, dispatch),
		updateQuote: bindActionCreators(updateQuote, dispatch),
		getDownloadUrl: bindActionCreators(getDownloadUrl, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
		getAllProjectsByStatus: bindActionCreators(getAllProjectsByStatus, dispatch),
		getAllInvitations: bindActionCreators(getAllInvitations, dispatch),
		getAllProjectsHistory: bindActionCreators(getAllProjectsHistory, dispatch),
		getInvitationsCount: bindActionCreators(getInvitationsCount, dispatch),
		sendInvoiceForProject: bindActionCreators(sendInvoiceForProject, dispatch),
		getBiddableProjects: bindActionCreators(getBiddableProjects, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Home)
