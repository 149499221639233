import React, { Component } from 'react'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Input, Select } from 'antd'

let id = 1

class DynamicFields extends Component {
	componentDidMount() {
		id = 1
	}
	remove = k => {
		const { form } = this.props
		// can use data-binding to get
		const keys = form.getFieldValue(this.props.fieldKey)
		// We need at least one passenger
		if (keys.length === 1) {
			return
		}
		// can use data-binding to set
		form.setFieldsValue({
			[this.props.fieldKey]: keys.filter(key => key !== k),
		})
	}

	add = () => {
		const { form } = this.props
		// can use data-binding to get
		const keys = form.getFieldValue(this.props.fieldKey)
		const newKeyIndex = id++
		const nextKeys = keys.concat(newKeyIndex)
		// can use data-binding to set
		// important! notify form to detect changes
		form.setFieldsValue({ [this.props.fieldKey]: nextKeys })
	}

	render() {
		const { getFieldDecorator, getFieldValue } = this.props.form
		const variableValueKeys = getFieldValue(this.props.fieldKey)
		return (
			<React.Fragment>
				{!this.props.viewMode && (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ display: 'grid', gridTemplateColumns: '48% 48% auto' }}>
							<div>Drone Name</div>
							<div>Drone Registration Number</div>
						</div>
						{variableValueKeys.map((k, index) => (
							<div style={{ display: 'grid', gridTemplateColumns: '48% 48% auto' }} key={index}>
								<Form.Item style={{ marginRight: '10px' }}>
									{getFieldDecorator(`droneType[${k}]`, {
										rules: [
											{
												required: getFieldValue(`droneRegNum[${k}]`),
												message: 'Select Drone Type.',
											},
										],
									})(
										<Select showSearch size='large' placeholder='Select Drone Type' style={{ marginRight: '15px' }}>
											{this.props.droneTypesData.map(data => (
												<Select.Option key={data.id} value={data.type}>
													{data.name}
												</Select.Option>
											))}
										</Select>,
									)}
								</Form.Item>
								<Form.Item style={{ marginRight: '10px' }}>
									{getFieldDecorator(`droneRegNum[${k}]`, {
										// rules: [
										// 	{
										// 		required: getFieldValue(`droneType[${k}]`),
										// 		message: 'Enter drone registration number.',
										// 	},
										// ],
									})(<Input style={{ height: '38px', borderRadius: '8px' }} />)}
								</Form.Item>
								<Form.Item>
									{variableValueKeys.length > 1 ? <DeleteOutlined style={{ position: 'relative', bottom: '3px' }} onClick={() => this.remove(k)} /> : null}
								</Form.Item>
							</div>
						))}
						<div>
							<Button
								type='dashed'
								onClick={() => {
									this.add()
								}}
							>
								<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} />
								Add Drone
							</Button>
						</div>
					</div>
				)}
			</React.Fragment>
		)
	}
}

export default DynamicFields
