import { FilterOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import React from 'react'
import MatImage from '../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../AeroComponents/Image/image.constants'
import { FILTER_VALUES } from '../../payment.constants'
import { getConfigData } from '../../../../Services/LocalStorageService'

const YourEarningsTab = props => {
	const { currentFilter, changeFilter, totalEarning } = props
	return (
		<div className='your-earning-box'>
			<div className='icon-box-2'>
				<MatImage src={getConfigData().countryId === 1 ? IMAGES_SRC.EARNING_ICON : IMAGES_SRC.NZ_DOLLAR_ICON} />
			</div>
			<div className='label-box'>
				Your Earnings <span className='filter-value'>({FILTER_VALUES[currentFilter].name})</span>
				<span className='filter-icon'>
					<Popover
						title='Show earnings for:'
						trigger='hover'
						content={
							<>
								{FILTER_VALUES.map(item => (
									<div className='button-wrapper' key={item.key}>
										<Button
											onClick={() => {
												changeFilter(item.key)
											}}
											type={currentFilter === item.key ? 'primary' : ''}
											block
										>
											{item.name}
										</Button>
									</div>
								))}
							</>
						}
					>
						<FilterOutlined />
					</Popover>
				</span>
			</div>
			<div className='value-box-2'>
				<strong>{totalEarning}</strong>
			</div>
		</div>
	)
}

export default YourEarningsTab
