import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { EditFilled, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Divider, Image, message, Tabs } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
	updateProfile,
	getAllDroneTypes,
	verifyWithDvs,
	updateBaseLocation,
	deleteCasaLicense,
	rejectEnterprisePilotPrompt,
	deleteDroneById,
	getPilotPortfolio,
	passedQuiz,
	getProfile,
	updateCasaLicense,
	addCasaLicense,
	getAllLicenseTypes,
	getAllDroneInsuranceTypes,
	addDroneInsurance,
	getAllPurchasedInsurance,
	deleteDroneInsurance,
	updateDroneInsurance,
	getWebConfig,
} from '../../Stores/UserSession/action'
import { getConfigData, getValueFromStorage } from '../../Services/LocalStorageService'
import './profile.sass'
import ProfilePicture from './Components/profile-picture'
import AddressDetails from './Components/address-details'
import IdDetails from './Components/id-details'
import ArnDetails from './Components/arn-details'
import CasaLicense from './Components/casa-license'
import CompanyDetails from './Components/company-details'
import DroneDetails from './Components/drone-details'
import MobileNumberVerification from './Components/mobile-number-verification'
import AboutYou from './Components/about-you'
import AeroModal from '../../AeroComponents/Modal'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import PilotAlerts from './Components/pilot-alerts'
import { ClientTabs, NZ_QUIZ_QUESTIONS, PilotTabs } from './constants'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import moment from 'moment'

class Profile extends Component {
	constructor(props) {
		const fetchedProfileType = getValueFromStorage('PROFILE_TYPE')
		super(props)
		if (fetchedProfileType === 'PILOT') {
			this.props.getAllDroneTypes()
		}
		const webConfig = fetchedProfileType === 'PILOT' ? getConfigData().pilotModules[0].webConfig : getConfigData().consumerModules[0].webConfig
		const webConfigIndex = webConfig.findIndex(item => item.modKey === 'PROFILE')
		this.state = {
			profileType: fetchedProfileType,
			profileData: props.profileData,
			visible: false,
			showPortfolio: false,
			droneTypesData: [],
			status: 'start',
			nameCheck: false,
			newFeaturePopup: false,
			enterprisePilotBanner: false,
			portfolioImages: [],
			portfolioVideos: [],
			portfolioBanner: '',
			currentStep: 1,
			subMods: webConfig[webConfigIndex].subMods.map(item => item.modKey),
			quizModal: false,
			currentIndex: 0,
			quizStarted: false,
			wrongAnswers: [],
			quizNextDissabled: false,
			retakeModal: false,
			quizCompleteModal: false,
			quizConfirmationModal: false,
			tempStoreResponse: null,
			addCaaModal: false,
			fileList: [],
			noMoreUploads: false,
			p101TestTaken: false,
			caaLicenseData: null,
			deleteLicenseModal: false,
			addOtherNzLicenseModal: false,
			allLicenseTypes: [],
			selectedLicense: null,
			otherLicenseData: [],
			retryLicense: null,
			deleteCasaModal: false,
			retryMode: null,
			userAnswers: [],
			currentResponse: null,
			droneInsuranceModal: false,
			allDroneInsuranceTypes: [],
			allPurchasedInsurance: [],
			deleteInsuranceModal: false,
			selectedInsurance: null,
			retryInsurance: null,
			dvsTempStore: false,
			deleteFlag: false,
			configData: props.webConfig,
		}
		this.caaLicenseRef = React.createRef(null)
		this.clearCaaNumberRef = React.createRef(null)
	}

	componentDidMount() {
		if (!this.state.configData) {
			this.props.getWebConfig()
		}
		if (this.state.profileData) {
			if (this.state.profileType === 'PILOT') {
				if (this.state.configData.countryId === 160) {
					const testTaken = this.state.profileData.licenses.find(item => item.licenseType === 'P_101_Test_Taken')
					const caaLicenseObj = this.state.profileData.licenses.filter(item => item.licenseType === 'CAA_NZ')
					const otherLicenseData = this.state.profileData.licenses.filter(item => item.licenseType !== 'CAA_NZ' && item.licenseType !== 'P_101_Test_Taken')
					this.setState({
						p101TestTaken: testTaken,
						caaLicenseData: caaLicenseObj && caaLicenseObj.length ? { ...caaLicenseObj[0] } : null,
						otherLicenseData: otherLicenseData,
					})
				}

				this.props.getPilotPortfolio({ [this.state.profileData.id]: 'portfolio' })
			}
			if (this.state.profileType === 'PILOT' && !this.state.profileData.verified) {
				this.setState({ nameCheck: true })
			}
			this.setState({
				profileData: this.state.profileData,
				newFeaturePopup: this.state.profileData.enterpriseEligibilityPopUp ? true : false,
				enterprisePilotBanner:
					this.state.profileData.enterpriseBannerDetails?.visibility && this.state.profileData.enterpriseBannerDetails.state === 'NOT_INITIATED' ? true : false,
			})
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.updateProfileSuccess !== nextProps.updateProfileSuccess) {
			if (nextProps.updateProfileSuccess) {
				this.setState({
					profileData: nextProps.updateProfileSuccess,
					visible: false,
				})
			}
		}
		if (this.props.allLicenseTypes !== nextProps.allLicenseTypes) {
			if (nextProps.allLicenseTypes) {
				const allLicenseTypes = nextProps.allLicenseTypes.map(item => {
					return { value: item.name, label: item.label, present: item.present, subtype: item.subtype, type: item.type, disabled: item.present }
				})
				this.setState({
					allLicenseTypes: allLicenseTypes,
					addOtherNzLicenseModal: this.state.configData.countryId === 160 && !this.state.deleteFlag ? true : false,
					deleteFlag: false,
				})
				if (this.state.configData.countryId === 1 && this.state.profileData.licenses?.length) {
					const { retryMode } = this.state
					if (retryMode) {
						this.openMadalToAddDetails('CASA_LICENSE', true, retryMode, allLicenseTypes)
					} else {
						this.openMadalToAddDetails('CASA_LICENSE', false, null, allLicenseTypes)
					}
				}
			}
		}
		if (this.props.profileData !== nextProps.profileData) {
			if (nextProps.profileData) {
				if (this.state.configData.countryId === 160 && this.state.profileType === 'PILOT') {
					const testTaken = nextProps.profileData.licenses.find(item => item.licenseType === 'P_101_Test_Taken')
					const caaLicenseObj = nextProps.profileData.licenses.filter(item => item.licenseType === 'CAA_NZ')
					const otherLicenseData = nextProps.profileData.licenses.filter(item => item.licenseType !== 'CAA_NZ' && item.licenseType !== 'P_101_Test_Taken')

					this.setState({
						p101TestTaken: testTaken,
						caaLicenseData: caaLicenseObj && caaLicenseObj.length ? { ...caaLicenseObj[0] } : null,
						profileData: nextProps.profileData,
						otherLicenseData: otherLicenseData,
					})
				} else {
					this.setState({ profileData: nextProps.profileData, visible: false })
				}
			}
		}
		if (this.props.updateBaseLocationSuccess !== nextProps.updateBaseLocationSuccess) {
			if (nextProps.updateBaseLocationSuccess) {
				this.setState({
					profileData: nextProps.updateBaseLocationSuccess,
					visible: false,
				})
			}
		}
		if (this.props.deleteCasaLicenseSuccess !== nextProps.deleteCasaLicenseSuccess) {
			if (nextProps.deleteCasaLicenseSuccess) {
				if (this.state.configData.countryId === 160) {
					this.toggleDeleteLicenseModal(null)
				}
				this.setState({ selectedLicense: this.state.allLicenseTypes[0], visible: false, deleteCasaModal: false, deleteFlag: true })
				if (this.state.profileData.licenses.length === 1) {
					this.props.getAllLicenseTypes()
				}

				this.props.getProfile()
			}
		}
		if (this.props.droneTypesData !== nextProps.droneTypesData) {
			this.setState({
				droneTypesData: nextProps.droneTypesData,
			})
		}
		if (this.props.verifyWithDvsSuccess !== nextProps.verifyWithDvsSuccess) {
			if (nextProps.verifyWithDvsSuccess) {
				window.location.reload()
			}
		}
		if (this.props.rejectEnterprisePilotPromptSuccess !== nextProps.rejectEnterprisePilotPromptSuccess) {
			if (nextProps.rejectEnterprisePilotPromptSuccess) {
				if (nextProps.rejectEnterprisePilotPromptSuccess) {
					this.setState({
						newFeaturePopup: false,
						enterprisePilotBanner: true,
					})
				}
			}
		}
		if (this.props.deleteDroneByIdData !== nextProps.deleteDroneByIdData) {
			this.setState({
				profileData: nextProps.deleteDroneByIdData,
			})
		}
		if (this.props.pilotPortfolioData !== nextProps.pilotPortfolioData) {
			if (nextProps.pilotPortfolioData) {
				this.setState({
					portfolioImages: nextProps.pilotPortfolioData.photos,
					portfolioVideos: nextProps.pilotPortfolioData.videos,
					portfolioBanner: nextProps.pilotPortfolioData.bannerText,
				})
			}
		}
		if (this.props.addCasaLicenseSuccess !== nextProps.addCasaLicenseSuccess) {
			if (nextProps.addCasaLicenseSuccess) {
				this.setState({ addCaaModal: false, addOtherNzLicenseModal: false, fileList: [] })
				this.props.getProfile()
				message.success('License submitted for verification')
			}
		}
		if (this.props.updateCasaLicenseSuccess !== nextProps.updateCasaLicenseSuccess) {
			if (nextProps.updateCasaLicenseSuccess) {
				this.setState({
					addCaaModal: false,
					addOtherNzLicenseModal: false,
					retryLicense: null,
					selectedLicense: this.state.allLicenseTypes[0],
					retryMode: null,
					fileList: [],
				})
				this.props.getProfile()
				message.success('License submitted for verification')
			}
		}
		if (this.props.allDroneInsuranceTypes !== nextProps.allDroneInsuranceTypes) {
			if (nextProps.allDroneInsuranceTypes) {
				const data = nextProps.allDroneInsuranceTypes.map(item => {
					return { value: item.key, label: item.value }
				})
				this.setState({ droneInsuranceModal: true, allDroneInsuranceTypes: data })
			}
		}
		if (this.props.addDroneInsuranceSuccess !== nextProps.addDroneInsuranceSuccess) {
			if (nextProps.addDroneInsuranceSuccess) {
				this.setState({ droneInsuranceModal: false, fileList: [] })
				this.props.getAllPurchasedInsurance()
				message.success('Insurance submitted for verification')
			}
		}
		if (this.props.allPurchasedInsurance !== nextProps.allPurchasedInsurance) {
			if (nextProps.allPurchasedInsurance) {
				this.setState({ allPurchasedInsurance: nextProps.allPurchasedInsurance })
			}
		}
		if (this.props.deleteDroneInsuranceSuccess !== nextProps.deleteDroneInsuranceSuccess) {
			if (nextProps.deleteDroneInsuranceSuccess) {
				pushEventToMixpanel('', 'Insurance Deleted', { verificationStatus: this.state.selectedInsurance?.verificationStatus })
				this.toggleDeleteInsuranceModal(null)
				this.props.getAllPurchasedInsurance()
				message.success('Insurance has been deleted')
			}
		}
		if (this.props.updateInsuranceSuccess !== nextProps.updateInsuranceSuccess) {
			if (nextProps.updateInsuranceSuccess) {
				this.setState({
					droneInsuranceModal: false,
					retryInsurance: null,
					selectedInsurance: null,
					fileList: [],
				})
				this.props.getAllPurchasedInsurance()
				message.success('Insurance submitted for verification')
			}
		}
	}

	verficationHandler = response => {
		if (response.error) {
			if (response.error === 'verification_cancelled') {
			}
			console.err(`error: ${response.error}`)
			return
		}
		let body = { grantCode: response.code }
		this.props.verifyWithDvs(body)
	}

	handleOk = () => {
		this.setState({ loading: true })
		setTimeout(() => {
			this.setState({ loading: false, visible: false })
		}, 3000)
	}

	handleCancel = () => {
		this.setState({ visible: false })
	}

	hidePortfolio = () => {
		this.setState({
			showPortfolio: false,
		})
	}

	updateCasaLicense = () => {
		this.props.getProfile()
	}

	updateArnDetails = newData => {
		this.setState({
			profileData: newData,
			visible: false,
		})
	}

	openMadalToAddDetails = (modalKey, retryMode = false, selectedId = null, allLicenseTypes = []) => {
		const profileData = this.state.profileData ? this.state.profileData : ''
		switch (modalKey) {
			case 'ARN_DETAILS':
				this.setState({
					visible: true,
					modalTitle: 'Aviation Reference Number',
					modalComponent: (
						<ArnDetails
							viewMode={false}
							data={profileData && profileData['arnLicense'] ? profileData['arnLicense'] : ''}
							closeModal={newData => {
								this.updateArnDetails(newData)
							}}
						/>
					),
				})
				break
			case 'CASA_LICENSE':
				this.setState({
					visible: true,
					modalTitle: 'CASA License Type',
					modalComponent: (
						<CasaLicense
							viewMode={false}
							data={profileData && profileData['casaLicenses'] ? profileData['casaLicenses'] : ''}
							deleteLicense={id => {
								this.props.deleteCasaLicense({ pilotLicenseId: id })
							}}
							retryMode={retryMode}
							selectedId={selectedId}
							casaLicenseTypesData={allLicenseTypes}
							closeModal={newData => {
								this.updateCasaLicense(newData)
							}}
							deleteCasaModal={this.state.deleteCasaModal}
							toggleDeleteCasaModal={this.toggleDeleteCasaModal}
							selectedLicense={this.state.selectedLicense}
						/>
					),
				})
				break
			case 'ID_DETAILS':
				this.setState({
					visible: true,
					modalTitle: 'ID Details',
					modalComponent: <IdDetails updateIdDetails={this.completeProfile} />,
				})
				break
			case 'PROFILE_PICTURE':
				this.setState({
					visible: true,
					modalTitle: 'Profile Picture',
					modalComponent: (
						<ProfilePicture
							viewMode={profileData && profileData['profilePicture'] ? true : false}
							updateProfilePicture={profileData && profileData['profilePicture'] ? this.updateBaseLocation : this.completeProfile}
							data={profileData && profileData['profilePicture'] ? profileData : ''}
						/>
					),
				})
				break
			case 'ADDRESS_DETAILS':
				this.setState({
					visible: true,
					modalTitle: 'Address Details',
					modalComponent: <AddressDetails updateAddressDetails={this.completeProfile} />,
				})
				break
			case 'COMPANY_DETAILS':
				this.setState({
					visible: true,
					modalTitle: 'Company Details',
					modalComponent: <CompanyDetails updateCompanyDetails={this.completeProfile} />,
				})
				break
			case 'DRONE_DETAILS':
				this.setState({
					visible: true,
					modalTitle: 'Add Drone Details',
					modalComponent: (
						<DroneDetails {...this.props} updateDroneDetails={this.completeProfile} deleteDrone={this.deleteDrone} droneTypesData={this.state.droneTypesData} />
					),
				})
				break
			case 'PORTFOLIO':
				this.setState({
					showPortfolio: true,
				})
				break
			case 'PN_VERIFICATION':
				this.setState({
					visible: true,
					modalTitle: 'Update Mobile Number',
					modalComponent: (
						<MobileNumberVerification
							fieldRequired={true}
							submitOtp={this.submitOtp}
							{...this.props}
							initialNumber={profileData && profileData['mobileNumber'] ? profileData['mobileNumber'] : null}
						/>
					),
				})
				break
			case 'ABOUT_YOU':
				this.setState({
					visible: true,
					modalTitle: 'Add Something About You',
					modalComponent: <AboutYou updateAboutYou={this.completeProfile} />,
				})
				break
			default:
				break
		}
	}

	completeProfile = data => {
		this.props.updateProfile(data)
	}

	updateBaseLocation = data => {
		this.props.updateBaseLocation(data)
	}

	submitOtp = data => {
		this.props.updateProfile(data)
	}

	closeNewFeaturePopup = () => {
		this.props.rejectEnterprisePilotPrompt()
	}

	handlePositivePrompt = () => {
		this.props.history.push({ pathname: '/settings', state: { enterprisePilotOnboardingPopup: true } })
	}

	deleteDrone = droneId => {
		this.props.deleteDroneById({ drone: droneId })
	}

	onClickFullscreen = () => {
		screenfull.request(findDOMNode(this.refs.player))
	}

	renderPortfolio = caseKey => {
		if ((this.state.portfolioImages && this.state.portfolioImages.length !== 0) || (this.state.portfolioVideos && this.state.portfolioVideos.length !== 0)) {
			if (caseKey === 'BTN') {
				return (
					<Button
						style={{ height: '48px', marginBottom: '20px', marginTop: '20px' }}
						type='primary'
						onClick={() => {
							this.props.history.push(`/pilot-portfolio/${this.state.profileData.id}`)
						}}
					>
						<EditFilled style={{ position: 'relative', bottom: '3px' }} />
						Edit
					</Button>
				)
			} else {
				return (
					<>
						<div>Images</div>
						<Divider></Divider>
						<Image.PreviewGroup>
							{this.state.portfolioImages.map((item, index) => (
								<div className='thumbnail-wrapper'>
									<Image
										style={{ float: 'left', width: '120px', height: '120px', objectFit: 'cover', borderRadius: '8px' }}
										key={index}
										src={item.thumbnails.smallThumbnail}
										placeholder={true}
										preview={{
											src: item.file,
										}}
									/>
								</div>
							))}
						</Image.PreviewGroup>
						{this.state.portfolioVideos.length > 0 ? (
							<>
								<Divider />
								<div>Video</div>
								<Divider />
								<div style={{ background: 'black', height: '130px', width: '130px', borderRadius: '8px' }}>
									<ReactPlayer
										playing={true}
										controls={true}
										light={this.state.portfolioVideos[0].thumbnails?.smallThumbnail}
										width={130}
										height={130}
										ref='player'
										// onPlay={this.onClickFullscreen}
										url={this.state.portfolioVideos[0].file}
									/>
								</div>
							</>
						) : null}
					</>
				)
			}
		} else {
			if (caseKey === 'BTN') {
				return (
					<Button
						style={{ height: '48px', marginBottom: '20px', marginTop: '20px' }}
						type='primary'
						onClick={() => {
							this.props.history.push(`/pilot-portfolio/${this.state.profileData.id}`)
						}}
					>
						<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} />
						Add
					</Button>
				)
			} else {
				return <div>You haven’t added photos and video to your portfolio. Click on ‘Add’ to add them</div>
			}
		}
	}

	handleTabChange = val => {
		// Sending tab click event to mixpanel
		if (this.state.profileType === 'PILOT') {
			if (val === '3' && !this.state.profileData.licenses?.length) {
				this.props.getAllLicenseTypes()
			}
			if (val === '11' && !this.state.allPurchasedInsurance.length) {
				this.props.getAllPurchasedInsurance()
			}
			const tabClicked = PilotTabs(this).filter(el => el.key === val)
			pushEventToMixpanel('', `${tabClicked[0].name} Tab Clicked`, {
				'Option Type': 'Profile',
			})
		} else {
			const tabClicked = ClientTabs(this).filter(el => el.key === val)
			pushEventToMixpanel('', `${tabClicked[0].name} Tab Clicked`, {
				'Option Type': 'Profile',
			})
		}

		if (val === '7') {
			const { dvsTempStore } = this.state
			if (!dvsTempStore) {
				const script = document.createElement('script')
				script.src = process.env.REACT_APP_DVS_SCRIPT_URL
				script.async = true

				document.body.appendChild(script)

				script.onload = () => {
					/* Verify with Digital iD */
					window.digitalId.init({
						clientId: process.env.REACT_APP_DVS_CLIENT_ID,
						uxMode: 'popup',
						onLoadComplete: function() {},
						onComplete: this.verficationHandler,
						onClick: function(opts) {},
						onKeepAlive: function() {},
					})
				}
				this.setState({ dvsTempStore: true })
			}
		}
		this.setState({ currentStep: Number(val) })
	}

	toggleQuizConfirmationModal = () => this.setState(prevState => ({ quizConfirmationModal: !prevState.quizConfirmationModal }))

	toggleQuizModal = () => {
		if (!this.state.quizStarted) {
			this.setState(prevState => ({ quizModal: !prevState.quizModal }))
		} else {
			this.toggleQuizConfirmationModal()
		}
	}

	toggleQuizCompleteModal = () => this.setState(prevState => ({ quizCompleteModal: !prevState.quizCompleteModal }))

	toggleRetakeModal = () => this.setState(prevState => ({ retakeModal: !prevState.retakeModal }))

	storeWrongAnswer = () => {
		const resArr = this.state.wrongAnswers
		if (!resArr.includes(NZ_QUIZ_QUESTIONS[this.state.currentIndex]) && !NZ_QUIZ_QUESTIONS[this.state.currentIndex].options[this.state.tempStoreResponse].isCorrect) {
			resArr.push(NZ_QUIZ_QUESTIONS[this.state.currentIndex])
			this.setState({ wrongAnswers: resArr })
		}
		return resArr
	}

	startQuiz = () => {
		const currentAnswers = this.state.userAnswers
		if (this.state.quizStarted && this.state.currentIndex !== 5) {
			this.storeWrongAnswer()

			if (currentAnswers.find(t => t.hasOwnProperty(this.state.currentIndex))) {
				currentAnswers[this.state.currentIndex][this.state.currentIndex] = NZ_QUIZ_QUESTIONS[this.state.currentIndex].options[this.state.tempStoreResponse].id
			} else {
				currentAnswers.push({
					[this.state.currentIndex]: NZ_QUIZ_QUESTIONS[this.state.currentIndex].options[this.state.tempStoreResponse].id,
				})
			}
			this.setState(prevState => ({
				currentIndex: prevState.currentIndex + 1,
				quizNextDissabled: true,
				userAnswers: currentAnswers,
				currentResponse: currentAnswers.length
					? currentAnswers[prevState.currentIndex + 1]
						? currentAnswers[prevState.currentIndex + 1][prevState.currentIndex + 1]
						: currentAnswers[prevState.currentIndex]
					: null,
			}))
		} else if (this.state.currentIndex === 5) {
			const resArr = this.storeWrongAnswer()
			this.setState({ currentResponse: null })
			if (this.state.wrongAnswers.length || resArr.length) {
				this.toggleRetakeModal()
			} else {
				this.props.passedQuiz().then(() => {
					this.props.getProfile()
					this.setState({ currentIndex: 0, quizModal: false })
					this.toggleQuizCompleteModal()
				})
			}
		} else {
			this.setState({ quizStarted: true, quizNextDissabled: true, userAnswers: [] })
		}
	}

	retakeQuiz = () => {
		this.toggleRetakeModal()
		this.setState({ wrongAnswers: [], quizStarted: false, quizModal: true, currentIndex: 0 })
	}

	captureResponse = e => {
		const response = e.target.value
		const optIndex = NZ_QUIZ_QUESTIONS[this.state.currentIndex].options.findIndex(item => item.id === response)
		if (this.state.currentIndex === 5) {
			const currentAnswers = this.state.userAnswers
			if (currentAnswers.find(t => t.hasOwnProperty(5))) {
				currentAnswers[5][5] = NZ_QUIZ_QUESTIONS[5].options[optIndex].id
			} else {
				currentAnswers.push({
					[5]: NZ_QUIZ_QUESTIONS[5].options[optIndex].id,
				})
			}
			this.setState({ userAnswers: currentAnswers })
		}

		this.setState({ quizNextDissabled: false, tempStoreResponse: optIndex, currentResponse: response })
	}

	goBack = () =>
		this.setState(prevState => ({
			currentIndex: prevState.currentIndex - 1,
			currentResponse: prevState.userAnswers[prevState.currentIndex - 1][prevState.currentIndex - 1],
		}))

	leaveQuiz = () => {
		this.toggleQuizConfirmationModal()
		this.setState({ currentIndex: 0, quizStarted: false, quizModal: false, quizNextDissabled: false, wrongAnswers: [] })
	}

	toggleAddCaaModal = () => {
		this.setState(prevState => ({ addCaaModal: !prevState.addCaaModal, fileList: [] }))
		if (this.clearCaaNumberRef.current && !this.state.caaLicenseData) {
			this.clearCaaNumberRef.current.click()
		}
	}

	caaUploadProps = () => {
		const { fileList } = this.state
		const retry = this.state.retryLicense
		const retryCaa = this.state.caaLicenseData
		const retryInsurance = this.state.retryInsurance
		const props = {
			onRemove: file => {
				if (retry) {
					retry.imageURL = null
					this.setState({ retryLicense: retry })
				} else if (retryCaa) {
					retryCaa.imageURL = null
					this.setState({ caaLicenseData: retryCaa })
				} else if (retryInsurance) {
					retryInsurance.url = null
					this.setState({ retryInsurance: retryInsurance })
				}
				this.setState({ fileList: [] })
			},
			beforeUpload: file => {
				const isJpgOrPngOrPdf = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'application/pdf'
				if (!isJpgOrPngOrPdf) {
					message.error('You can only upload jpeg, png or PDF file!')
					return false
				}
				const isLt2M = file.size / 1024 / 1024 < 10
				if (!isLt2M) {
					message.error('File must be smaller than 10 MB!')
					return false
				}

				if (retry) {
					retry.imageURL = 'changed'
					this.setState({ retryLicense: retry })
				} else if (retryCaa) {
					retryCaa.imageURL = 'changed'
					this.setState({ caaLicenseData: retryCaa })
				} else if (retryInsurance) {
					retryInsurance.url = 'changed'
					this.setState({ retryInsurance: retryInsurance })
				}
				this.setState({ fileList: [file] })
				return false
			},
			fileList,
		}

		return props
	}

	submitCaaLicense = () => {
		const { fileList, caaLicenseData } = this.state
		const licenseNumber = this.caaLicenseRef.current.input.value
		if ((!licenseNumber && (!fileList.length || !caaLicenseData?.imageURL)) || !licenseNumber.replace(/\s/g, '').length) {
			return message.warning('Please fill in the details!')
		}

		let body = new FormData()

		let fileData
		fileList.forEach(file => {
			fileData = file
		})
		if (this.state.caaLicenseData) {
			if (this.state.caaLicenseData.imageURL === 'changed') {
				body.append('licenceFile', fileData)
			}
		} else {
			body.append('licenceFile', fileData)
		}
		body.append('licenseNumber', licenseNumber)
		body.append('caaNz', true)
		if (this.state.caaLicenseData) {
			body.append('licenseId', this.state.caaLicenseData.id)
			this.props.updateCasaLicense(body)
		} else {
			this.props.addCasaLicense(body)
		}
	}

	toggleDeleteLicenseModal = item => this.setState(prevState => ({ deleteLicenseModal: !prevState.deleteLicenseModal, selectedLicense: item }))
	toggleRetryModal = item => {
		if (item) {
			this.props.getAllLicenseTypes()
			this.setState({ retryLicense: { ...item } })
		} else {
			this.setState({ addCaaModal: true })
		}
	}
	callDeleteLicense = () => {
		this.props.deleteCasaLicense({ pilotLicenseId: this.state.selectedLicense ? this.state.selectedLicense.id : this.state.caaLicenseData.id })
	}

	toggleAddOtherNzLicenseModal = () =>
		this.setState(prevState => ({
			addOtherNzLicenseModal: !prevState.addOtherNzLicenseModal,
			retryLicense: null,
			selectedLicense: this.state.allLicenseTypes[0],
			fileList: [],
		}))

	openOtherNzLicenseModal = () => {
		this.props.getAllLicenseTypes()
	}

	handleLicenseChange = val => {
		const obj = this.state.allLicenseTypes.filter(item => item.value === val)
		if (obj[0].subtype.length) {
			obj[0].subtype = obj[0].subtype.map(item => {
				return { value: item.name ? item.name : item.value, label: item.label, present: item.present, subtype: item.subtype, type: item.type, disabled: item.present }
			})
		}
		if (this.state.retryLicense && obj[0].value !== 'P61') {
			let retry = this.state.retryLicense
			retry.licenseSubType = null
			this.setState({ retryLicense: retry })
		}
		this.setState({ selectedLicense: obj[0] })
	}

	addLicense = values => {
		const { fileList } = this.state

		let body = new FormData()

		let fileData
		fileList.forEach(file => {
			fileData = file
		})
		if (this.state.retryLicense) {
			if (this.state.retryLicense.imageURL === 'changed') {
				body.append('licenceFile', fileData)
			}
		} else {
			body.append('licenceFile', fileData)
		}

		body.append('licenseNumber', values.number)
		body.append('nzDocumentType', values.licenseType)
		body.append('expiryDate', values.expiryDate ? moment(values.expiryDate).unix() : '')
		if (values.subtype) {
			body.append('subTypeP61', values.subtype)
		}
		if (this.state.retryLicense) {
			body.append('licenseId', this.state.retryLicense.id)
			this.props.updateCasaLicense(body)
		} else {
			this.props.addCasaLicense(body)
		}
	}

	deleteDoc = type => {
		if (type === 'caa') {
			const item = this.state.caaLicenseData
			item.imageURL = null
			this.setState({ caaLicenseData: item, fileList: [] })
		} else if (type === 'insurance') {
			const item = this.state.retryInsurance
			item.url = null
			this.setState({ retryInsurance: item, fileList: [] })
		} else {
			const item = this.state.retryLicense
			item.imageURL = null
			this.setState({ retryLicense: item, fileList: [] })
		}
	}

	callListOfLicenses = (retryId = null) => {
		if (retryId) {
			this.setState({ retryMode: retryId })
		}
		this.props.getAllLicenseTypes()
	}

	toggleDeleteCasaModal = item => this.setState(prevState => ({ deleteCasaModal: !prevState.deleteCasaModal, selectedLicense: item }))

	toggleDroneInsuranceModal = () => this.setState(prevState => ({ droneInsuranceModal: !prevState.droneInsuranceModal, retryInsurance: null }))

	toggleDeleteInsuranceModal = item => this.setState(prevState => ({ deleteInsuranceModal: !prevState.deleteInsuranceModal, selectedInsurance: item }))

	callDeleteInsurance = () => {
		this.props.deleteDroneInsurance({ insuranceId: this.state.selectedInsurance.id })
	}

	openInsuranceModal = () => {
		pushEventToMixpanel('', 'Intent to add insurance')
		this.props.getAllDroneInsuranceTypes()
	}

	toggleRetryInsuranceModal = item => {
		this.props.getAllDroneInsuranceTypes()
		this.setState({ retryInsurance: { ...item } })
	}

	handleInsuranceForm = values => {
		const { insuranceAmount, insuranceType, moreInfo, expiryDate } = values
		if (String(insuranceAmount).includes('.') || String(insuranceAmount).includes('-')) {
			return message.warning('Please input only positive whole numbers!')
		}
		const { fileList } = this.state

		let insuranceBody = new FormData()

		let fileData
		fileList.forEach(file => {
			fileData = file
		})
		if (this.state.retryInsurance) {
			if (this.state.retryInsurance.url === 'changed') {
				insuranceBody.append('insuranceFile', fileData)
			}
		} else {
			insuranceBody.append('insuranceFile', fileData)
		}

		insuranceBody.append('amount', insuranceAmount)
		insuranceBody.append('type', insuranceType)
		insuranceBody.append('moreInfo', moreInfo ? moreInfo : '')
		insuranceBody.append('expiryDate', moment(expiryDate).unix())
		if (this.state.retryInsurance) {
			const { retryInsurance } = this.state
			insuranceBody.append('insuranceId', retryInsurance.id)
			let fieldsChanged = false
			if (
				insuranceAmount !== retryInsurance.amount ||
				insuranceType !== retryInsurance.type ||
				moreInfo !== retryInsurance.moreInfo ||
				!expiryDate.isSame(moment(retryInsurance.expiryDate)) ||
				!retryInsurance.url ||
				fileList.length !== 0
			) {
				fieldsChanged = true
			}

			if (fieldsChanged) {
				pushEventToMixpanel('', 'Insurance re-submitted for verification', {
					insuranceType: insuranceType,
					verificationStatus: retryInsurance.verificationStatus,
					fieldsChanged: fieldsChanged,
				})
				this.props.updateDroneInsurance(insuranceBody)
			} else {
				message.warning('Change atleast one field to update the insurance.')
			}
		} else {
			pushEventToMixpanel('', 'Insurance added', { insuranceType: insuranceType, moreInfoAdded: !!moreInfo })
			this.props.addDroneInsurance(insuranceBody)
		}
	}

	updateDocState = type => {
		if (type === 'init' && this.state.fileList.length) {
			this.setState({ fileList: [] })
		} else if (type === 'retryInsurance') {
			const retry = this.state.retryInsurance
			const all = this.state.allPurchasedInsurance
			const picked = all.filter(item => item.id === retry.id)
			retry.url = picked[0].url
			this.setState({ retryInsurance: retry })
		} else if (type === 'retryNzLicense') {
			const retry = this.state.retryLicense
			const all = this.state.otherLicenseData
			const picked = all.filter(item => item.id === retry.id)
			retry.url = picked[0].url
			this.setState({ retryLicense: retry })
		} else if (type === 'retryNzCaa') {
			const caa = this.state.caaLicenseData
			const caaLicenseObj = this.state.profileData.licenses.filter(item => item.licenseType === 'CAA_NZ')
			if (caaLicenseObj && caaLicenseObj.length) {
				caa.imageURL = caaLicenseObj[0].imageURL
				this.setState({ caaLicenseData: caa })
			}
		}
	}

	render() {
		const { profileData, visible, modalTitle, modalComponent, profileType, enterprisePilotBanner, newFeaturePopup, currentStep, subMods } = this.state
		return (
			<>
				<div>
					<div className='projects-heading' style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
						Profile{' '}
						{!profileData.enterprisePlusPilot && profileData.enterprisePilot ? (
							<span className='enterprise-flag-box'>
								<MatImage src={IMAGES_SRC.ENTERPRISE_FLAG_ICON} />
								&nbsp; Enterprise Pilot
							</span>
						) : null}
						{profileData.enterprisePlusPilot ? <MatImage style={{ width: '120px' }} src={IMAGES_SRC.ENTERPRISE_PLUS_PROFILE_ICON} /> : null}
					</div>
					<Button className='data-security-btn' target='_blank' href='https://www.aerologix.com/policy'>
						<MatImage style={{ paddingRight: '5px' }} src={IMAGES_SRC.DATA_SECURITY_ICON} /> <u>Data Security</u>
					</Button>
				</div>
				{profileType === 'PILOT' ? (
					<>
						<Tabs
							className='create-new-project-form'
							size='small'
							activeKey={String(currentStep)}
							onChange={this.handleTabChange}
							style={{ padding: '10px' }}
							tabPosition='left'
							tabBarStyle={{ height: '90vh', color: '#84818A' }}
						>
							{PilotTabs(this).map(item => (
								<>
									{subMods.includes(item.modKey) ? (
										<Tabs.TabPane tab={item.tab} key={item.key} style={{ overflowY: 'auto', height: '80vh', overflowX: 'hidden' }}>
											<PilotAlerts profileData={profileData} enterprisePilotBanner={enterprisePilotBanner} />
											{item.component}
										</Tabs.TabPane>
									) : null}
								</>
							))}
						</Tabs>
						<Tabs className='create-new-project-form-mobile' activeKey={String(currentStep)} onChange={this.handleTabChange} style={{ padding: '20px' }}>
							{PilotTabs(this).map(item => (
								<>
									{subMods.includes(item.modKey) ? (
										<Tabs.TabPane tab={item.tab} key={item.key}>
											<PilotAlerts profileData={profileData} enterprisePilotBanner={enterprisePilotBanner} />
											{item.component}
										</Tabs.TabPane>
									) : null}
								</>
							))}
						</Tabs>
					</>
				) : (
					<>
						<Tabs
							className='create-new-project-form'
							size='small'
							activeKey={String(currentStep)}
							onChange={this.handleTabChange}
							style={{ padding: '10px' }}
							tabPosition='left'
							tabBarStyle={{ height: '90vh', color: '#84818A' }}
						>
							{ClientTabs(this).map(item => (
								<>
									{subMods.includes(item.modKey) ? (
										<Tabs.TabPane tab={item.tab} key={item.key}>
											{item.component}
										</Tabs.TabPane>
									) : null}
								</>
							))}
						</Tabs>
						<Tabs className='create-new-project-form-mobile' activeKey={String(currentStep)} onChange={this.handleTabChange} style={{ padding: '20px' }}>
							{ClientTabs(this).map(item => (
								<>
									{subMods.includes(item.modKey) ? (
										<Tabs.TabPane tab={item.tab} key={item.key}>
											{item.component}
										</Tabs.TabPane>
									) : null}
								</>
							))}
						</Tabs>
					</>
				)}

				{visible && (
					<AeroModal visible={visible} title={modalTitle} onOk={this.handleOk} onCancel={this.handleCancel} footer={null}>
						{modalComponent}
					</AeroModal>
				)}
				<AeroModal visible={newFeaturePopup} footer={false} onCancel={this.closeNewFeaturePopup}>
					<div style={{ textAlign: 'center' }}>
						<MatImage src={IMAGES_SRC.NEW_FEATURE_ICON} />
						<div style={{ padding: '20px' }}>
							<h2 style={{ fontSize: '20px' }}>You just unlocked a new feature</h2>
							<div style={{ fontSize: '16px', padding: '30px' }}>
								You are eligible to be an enterprise pilot and increase your average earnings!
								<br />
								<br />
								We require a few more details in order to confirm your profile, would you like to continue?
							</div>
							<div>
								<Button onClick={this.closeNewFeaturePopup} style={{ width: '169px' }}>
									No
								</Button>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<Button onClick={this.handlePositivePrompt} style={{ width: '169px' }} type='primary'>
									Yes, I am in
								</Button>
							</div>
						</div>
					</div>
				</AeroModal>
			</>
		)
	}
}

const mapStateToProps = state => {
	return {
		droneTypesData: state.UserSessionReducer.droneTypesData,
		updateProfileSuccess: state.UserSessionReducer.updateProfileSuccess,
		updateBaseLocationSuccess: state.UserSessionReducer.updateBaseLocationSuccess,
		deleteCasaLicenseSuccess: state.UserSessionReducer.deleteCasaLicenseSuccess,
		verifyWithDvsSuccess: state.UserSessionReducer.verifyWithDvsSuccess,
		rejectEnterprisePilotPromptSuccess: state.UserSessionReducer.rejectEnterprisePilotPromptSuccess,
		deleteDroneByIdData: state.UserSessionReducer.deleteDroneByIdData,
		pilotPortfolioData: state.UserSessionReducer.pilotPortfolioData,
		addCasaLicenseSuccess: state.UserSessionReducer.addCasaLicenseSuccess,
		allLicenseTypes: state.UserSessionReducer.allLicenseTypes,
		updateCasaLicenseSuccess: state.UserSessionReducer.updateCasaLicenseSuccess,
		allDroneInsuranceTypes: state.UserSessionReducer.allDroneInsuranceTypes,
		addDroneInsuranceSuccess: state.UserSessionReducer.addDroneInsuranceSuccess,
		allPurchasedInsurance: state.UserSessionReducer.allPurchasedInsurance,
		deleteDroneInsuranceSuccess: state.UserSessionReducer.deleteDroneInsuranceSuccess,
		updateInsuranceSuccess: state.UserSessionReducer.updateInsuranceSuccess,
		webConfig: state.UserSessionReducer.webConfig,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		updateProfile: bindActionCreators(updateProfile, dispatch),
		getAllDroneTypes: bindActionCreators(getAllDroneTypes, dispatch),
		verifyWithDvs: bindActionCreators(verifyWithDvs, dispatch),
		updateBaseLocation: bindActionCreators(updateBaseLocation, dispatch),
		deleteCasaLicense: bindActionCreators(deleteCasaLicense, dispatch),
		rejectEnterprisePilotPrompt: bindActionCreators(rejectEnterprisePilotPrompt, dispatch),
		deleteDroneById: bindActionCreators(deleteDroneById, dispatch),
		getPilotPortfolio: bindActionCreators(getPilotPortfolio, dispatch),
		passedQuiz: bindActionCreators(passedQuiz, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
		updateCasaLicense: bindActionCreators(updateCasaLicense, dispatch),
		addCasaLicense: bindActionCreators(addCasaLicense, dispatch),
		getAllLicenseTypes: bindActionCreators(getAllLicenseTypes, dispatch),
		getAllDroneInsuranceTypes: bindActionCreators(getAllDroneInsuranceTypes, dispatch),
		addDroneInsurance: bindActionCreators(addDroneInsurance, dispatch),
		getAllPurchasedInsurance: bindActionCreators(getAllPurchasedInsurance, dispatch),
		deleteDroneInsurance: bindActionCreators(deleteDroneInsurance, dispatch),
		updateDroneInsurance: bindActionCreators(updateDroneInsurance, dispatch),
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Profile)
