import { CheckCircleFilled, ClockCircleOutlined } from '@ant-design/icons'
import React from 'react'

const VerificationTag = props => {
	const { verificationState } = props

	return (
		<span
			style={{
				marginLeft: '10px',
				borderRadius: '8px',
				background: verificationState === 'PENDING_VERIFICATION' ? '#3487EB' : verificationState === 'VERIFICATION_FAILED' ? '#F75151' : 'white',
				fontSize: '12px',
				color: verificationState === 'VERIFICATION_FAILED' || verificationState === 'PENDING_VERIFICATION' ? 'white' : '#6CBE44',
				padding: '5px',
				border: verificationState === 'VERIFICATION_SUCCEEDED' ? '1px solid rgba(0, 0, 0, 0.15)' : null,
			}}
		>
			{verificationState === 'VERIFICATION_FAILED' || verificationState === 'PENDING_VERIFICATION' ? (
				<ClockCircleOutlined style={{ position: 'relative', bottom: '3px', marginRight: '6px' }} />
			) : (
				<CheckCircleFilled style={{ position: 'relative', bottom: '3px', marginRight: '4px' }} />
			)}
			{verificationState === 'PENDING_VERIFICATION' ? 'Verification Pending' : verificationState === 'VERIFICATION_FAILED' ? 'Verification Failed' : 'Verified'}
		</span>
	)
}

export default VerificationTag
