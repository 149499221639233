import { EditFilled } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import MatImage from '../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../AeroComponents/Image/image.constants'
import BasicDetails from '../basic-details'

const BasicDetailsTab = props => {
	const { profileData, openMadalToAddDetails, updateBaseLocation, nameCheck } = props
	return (
		<div style={{ padding: '10px' }}>
			<div>
				{profileData && profileData['profilePicture'] ? (
					<div className='circular'>
						<MatImage style={{ borderRadius: '50%', maxWidth: '100px' }} src={profileData['profilePicture']} />
					</div>
				) : (
					<MatImage src={IMAGES_SRC.DP_PLACEHOLDER} />
				)}
				<div className='profile-pic-edit-wrap' style={{ left: profileData && profileData['profilePicture'] ? '75px' : '50px' }}>
					<Button onClick={() => openMadalToAddDetails('PROFILE_PICTURE')} type='link'>
						<EditFilled style={{ position: 'relative', right: '10px', bottom: '5px', color: 'white' }} />
					</Button>
				</div>
			</div>
			{nameCheck ? (
				<div
					style={{
						paddingBottom: '15px',
						color: '#d4380d',
						background: '#fff2e8',
						border: '1px solid #ffbb96',
						padding: '10px',
						marginBottom: '20px',
						borderRadius: '8px',
					}}
				>
					Please fill in your legal First & Last Name before proceeding to complete your ID Verification!
				</div>
			) : null}
			{profileData && <BasicDetails viewMode updateBasicDetails={updateBaseLocation} data={profileData} />}
		</div>
	)
}

export default BasicDetailsTab
