/*eslint-disable*/
import React from 'react'
import { Button, Divider } from 'antd'
import './on-boarding.sass'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { goTo } from '../../Services/NavigationService'

export default class SignUpSuccess extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			timeVal: 10,
		}
	}

	startTimer = type => {
		const interval = setInterval(() => {
			if (this.state.timeVal > 0 && type !== 'btn') {
				this.setState(prevState => ({
					timeVal: prevState.timeVal - 1,
				}))
			} else {
				clearInterval(interval)
				goTo('login')
			}
		}, 1000)
	}

	componentDidMount() {
		this.startTimer('app')
	}

	render() {
		return (
			<>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<MatImage style={{ marginTop: '15px' }} alt='logo' src={IMAGES_SRC.NEW_BLUE_LOGO} />
				</div>
				<Divider></Divider>
				<div className='ob-content-wrapper'>
					<div>
						<div style={{ marginBottom: '20px' }}>
							<MatImage alt='email-icn' src={IMAGES_SRC.BIG_CHECK_ICON} />
						</div>
						<div style={{ fontSize: '24px', marginBottom: '20px' }}>Signed up successfully!</div>
						<div style={{ fontSize: '18px', color: '#84818A', marginBottom: '20px', textAlign: 'center' }}>
							Please verify your email to proceed. Your verification link will expire in 48 hours
						</div>
						<div style={{ fontSize: '18px', color: '#84818A', textAlign: 'center' }}>
							You will be redirected to login screen in {this.state.timeVal} sec
							<br />
							If not,{' '}
							<Button onClick={() => this.startTimer('btn')} style={{ fontSize: '18px', position: 'relative', right: '15px' }} type='link'>
								Click here
							</Button>
						</div>
					</div>
				</div>
			</>
		)
	}
}
