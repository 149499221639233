import { Descriptions, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { textShortner, timeDiffString } from '../../../Services/UtilityService'
import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { getWebConfig } from '../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const STATUS_MAP = {
	ALLOTTED: 'Bid Accepted',
	IN_FLIGHT: 'In-Flight',
	COMPLETED: 'Data Delivered',
	DATA_REJECTED_FINAL: 'Data Rejected',
	RESOLVEDL: 'Project Completed',
}

const PILOT_BUDGET_LABEL = {
	biddable: 'Budget',
	pending: 'Bid Amount',
	ongoing: 'Project Amount',
	history: 'Bid Amount',
	completed: 'Project Amount',
}

const CONSUMER_BUDGET_LABEL = {
	ongoing: 'Budget',
	pending: 'Project Amount',
	completed: 'Project Amount',
}

const ClientProjectsCard = props => {
	const { data, profileType, tabType, webConfig, getWebConfig } = props
	const [configData, setConfigData] = useState(webConfig)
	const [waitForApi, setWaitForApi] = useState(false)

	useEffect(() => {
		if (!configData) {
			setWaitForApi(true)
			getWebConfig()
		}
	}, [configData])

	useEffect(() => {
		if (webConfig && waitForApi) {
			setWaitForApi(false)
			setConfigData(webConfig)
		}
	}, [webConfig])

	const descContent = type => {
		return (
			<Descriptions layout={type === 'web' ? 'horizontal' : 'vertical'} labelStyle={{ color: '#84818A' }} size='small' column={type === 'web' ? 4 : 2}>
				<Descriptions.Item label={profileType === 'PILOT' ? PILOT_BUDGET_LABEL[tabType] : CONSUMER_BUDGET_LABEL[tabType]}>
					{profileType === 'CONSUMER' || tabType === 'biddable' || tabType === 'ongoing' || tabType === 'completed'
						? data.is_negotiable && data.total_fee === 0
							? 'Negotiable'
							: configData.currency + ' ' + data.total_fee
						: data.is_negotiable && data.quote?.amount === 0
						? 'Negotiable'
						: configData.currency + ' ' + data.quote?.amount}{' '}
					<Divider type='vertical' className='card-divider' />
				</Descriptions.Item>
				<Descriptions.Item
					label={
						profileType === 'CONSUMER'
							? !data.deadlineType || data.deadlineType !== 'ON'
								? 'Deadline By'
								: 'Project Date'
							: !data.deadline_type || data.deadline_type !== 'ON'
							? 'Deadline By'
							: 'Project Date'
					}
				>
					{profileType === 'CONSUMER'
						? data.deadlineType !== 'FLEXIBLE'
							? moment
									.utc(data.deadline)
									.local()
									.format('DD/MM/YYYY')
							: 'Flexible'
						: data.deadline_type !== 'FLEXIBLE'
						? moment
								.utc(data.deadline)
								.local()
								.format('DD/MM/YYYY')
						: 'Flexible'}
					{type === 'web' ? <Divider type='vertical' className='card-divider' /> : null}
				</Descriptions.Item>
				<Descriptions.Item label='Location'>{profileType === 'CONSUMER' ? data.address : data.address.addressDetails}</Descriptions.Item>
				{tabType === 'completed' ? (
					<Descriptions.Item label='Completed On'>
						{moment
							.utc(data.updated_at)
							.local()
							.format('DD/MM/YYYY')}
					</Descriptions.Item>
				) : null}
			</Descriptions>
		)
	}

	return (
		<div>
			<div style={{ fontSize: '18px', padding: '10px' }}>
				{data.name}
				{(profileType === 'CONSUMER' && tabType === 'pending') || (profileType === 'PILOT' && tabType === 'ongoing') ? (
					<span style={{ marginLeft: '10px' }} className='bid-tag project-status'>
						<ClockCircleFilled style={{ position: 'relative', bottom: '3px' }} />
						&nbsp;&nbsp;{STATUS_MAP[data.state]}
					</span>
				) : null}
				{tabType === 'history' ? (
					<span
						style={{ marginLeft: '10px' }}
						className={
							data.quote.status === 'ACCEPTED' || data.quote.status === 'COMPLETED' || data.quote.status === 'RESOLVED' ? 'bid-tag accepted' : 'bid-tag rejected'
						}
					>
						{data.quote.status === 'ACCEPTED' || data.quote.status === 'COMPLETED' || data.quote.status === 'RESOLVED' ? (
							<CheckCircleFilled style={{ position: 'relative', bottom: '3px' }} />
						) : (
							<CloseCircleFilled style={{ position: 'relative', bottom: '3px' }} />
						)}
						&nbsp;&nbsp;
						{data.quote.status === 'ACCEPTED' || (data.quote.status === 'COMPLETED' && data.state !== 'RESOLVED')
							? 'Bid Accepted'
							: data.quote.status === 'REJECTED'
							? 'Bid Rejected'
							: data.quote.status === 'WITHDRAWN'
							? 'Bid Withdrawn'
							: data.quote.status === 'RESOLVED' || (data.quote.status === 'COMPLETED' && data.state === 'RESOLVED')
							? 'Project Completed'
							: 'Bid Failed'}
					</span>
				) : null}

				<span className='web-posted-at-text'>Posted {timeDiffString(profileType === 'CONSUMER' ? data.createdAt : data.created_at)} ago</span>
			</div>
			<div className='mobile-posted-at-text'>Posted {timeDiffString(profileType === 'CONSUMER' ? data.createdAt : data.created_at)} ago</div>
			<div className='web-cards-for-projects'>{descContent('web')}</div>
			<div className='mobile-cards-for-projects'>{descContent('mobile')}</div>
			{profileType === 'CONSUMER' ? (
				<p style={{ padding: '10px' }}>{data.additionalInfo ? textShortner(data.additionalInfo, 200) : null}</p>
			) : (
				<p style={{ padding: '10px' }}>{data.additional_Info ? textShortner(data.additional_Info, 200) : null}</p>
			)}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		webConfig: state.UserSessionReducer.webConfig,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ClientProjectsCard)
