import React from 'react'
import { Button } from 'antd'
import { Elements } from 'react-stripe-elements'

import styles from '../enterprisePilotPlus.module.scss'
import MatImage from '../../../../../AeroComponents/Image/index'
import { IMAGES_SRC } from '../../../../../AeroComponents/Image/image.constants'
import CardSetupForm from '../components/CardSetupForm'

const StripeCreditCard = ({ closeEnterprisePlusPilotModal, cancelEnterprisePlusPrompt, history, termsAndConditionsModalData, handleNext, completePilotEnterprisePlus }) => {
	const goToHomePage = () => {
		closeEnterprisePlusPilotModal()
		cancelEnterprisePlusPrompt()
		history.goBack()
	}

	return (
		<div className={styles['terms_and_conditions']}>
			<div className={styles['back_button']}>
				<Button type='link' onClick={goToHomePage}>
					<MatImage src={IMAGES_SRC.BACK_BTN} />
				</Button>
				Enterprise
			</div>

			<div className={`${styles['heading']} ${styles['stripe_heading']}`}>
				<span>Credit Card Details</span>
			</div>

			<div className={styles['card_details']}>
				<Elements>
					<CardSetupForm termsAndConditionsModalData={termsAndConditionsModalData} completePilotEnterprisePlus={completePilotEnterprisePlus} handleNext={handleNext} />
				</Elements>
			</div>
		</div>
	)
}

export default StripeCreditCard
