/*eslint-disable*/
import React from 'react'
import './on-boarding.sass'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Divider } from 'antd'
import SelectProjectType from './ProfileSetupComponents/select-project-type'
import ProjectArea from './ProfileSetupComponents/project-area'

const PROJECT_TYPE = { PHOTOS: 'Images', VIDEOS: 'Videos', PHOTOS_AND_VIDEOS: 'Images and Videos', AEROPATH: 'Tower Inspection' }

class ProjectPrefrencesForm extends React.Component {
	submitProfile = e => {
		e.preventDefault()
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				// hit api for complete profile.
				const { projectRadius, pushNotifications, projectTypes } = values
				let projectType = projectTypes
				const data = {
					preferences: {
						projectRadius,
						projectType,
						pushNotifications: Number(pushNotifications),
					},
					mixpanel_type: 'project_preferences_updated',
					oldProjectTypes: this.props.data.projectType,
					oldProjectRadius: this.props.data.projectRadius,
				}
				this.props.updateProfile(data)
			}
		})
	}

	renderProjects = () => {
		const res = this.props.data.projectType.map(item => PROJECT_TYPE[item])
		return <span>{res.join(', ')}</span>
	}

	render() {
		return (
			<>
				<div style={{ fontSize: '16px', color: '#47464A' }}>Pilot Preferences</div>
				<div style={{ color: '#84818A', marginTop: '10px', marginBottom: '20px' }}>This will help Aerologix to provide you with projects of your interest</div>

				<Form layout='vertical' onSubmit={this.submitProfile}>
					<div style={{ fontSize: '16px', color: '#47464A', marginBottom: '20px' }}>Type of projects you would like to do </div>
					<SelectProjectType {...this.props} />
					<Divider />
					<div style={{ fontSize: '16px', color: '#47464A', marginBottom: '20px' }}>
						Radius of Project Notifications{' '}
						<span style={{ color: '#828282', fontSize: '14px' }}>(As a Pilot, you can mention what is the radius in which you are open to accepting projects)</span>{' '}
					</div>
					<ProjectArea {...this.props} />
					<Button style={{ height: '48px' }} block type='primary' htmlType='submit'>
						Update
					</Button>
				</Form>
			</>
		)
	}
}

const ProjectPrefrences = Form.create({ name: 'PROJECT_PREFERENCES_FORM' })(ProjectPrefrencesForm)

export default ProjectPrefrences
