/*eslint-disable*/
import React from 'react'
import { Button } from 'antd'
import './on-boarding.sass'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import MatText from '../../AeroComponents/Text'

const CARD_FIELDS = {
	RECOVERY_LINK_SENT: {
		header: 'A temporary password has been sent to your email address',
		resendText: 'Resend Recovery Link',
		image: 'RECOVERY_SENT_ICON',
	},
	SIGN_UP_SUCCESS: {
		icon: 'CHECK_ICON',
		header: 'Signed Up Successfully!',
		subHeading: 'Please verify your email to proceed',
		subHeading1: 'Verification link will expire in 48 hours',
		image: 'SIGNED_UP_IMAGE',
	},
}

export default class StatusCard extends React.Component {
	render() {
		return (
			<div className='status-card'>
				<MatImage src={IMAGES_SRC[CARD_FIELDS[this.props.id].image]} />
				{CARD_FIELDS[this.props.id].icon ? (
					<div style={{ display: 'flex', width: '75%', alignItems: 'center', justifyContent: 'space-around' }}>
						<MatImage src={IMAGES_SRC[CARD_FIELDS[this.props.id].icon]} width='22' height='22' />
						<MatText size='22' text={CARD_FIELDS[this.props.id].header} />
					</div>
				) : (
					<MatText size='22' text={CARD_FIELDS[this.props.id].header} />
				)}
				{this.props.data ? <MatText text={this.props.data} /> : <MatText text={CARD_FIELDS[this.props.id].subHeading} />}
				{CARD_FIELDS[this.props.id].subHeading1 && <MatText text={CARD_FIELDS[this.props.id].subHeading1} />}
				<Button type='link' onClick={() => this.props.cardLinkAction()}>
					{CARD_FIELDS[this.props.id].resendText}
				</Button>
			</div>
		)
	}
}
