import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import './home.sass'
import { BellTwoTone, CloseOutlined, PlusCircleOutlined, InfoCircleOutlined, CloseCircleFilled } from '@ant-design/icons'
import { Tabs, Button, Card, Alert, Input, message, Badge, Tooltip, Checkbox } from 'antd'
import { getValueFromStorage } from '../../Services/LocalStorageService'
import { bindActionCreators } from 'redux'
import {
	getProjects,
	updateQuote,
	getDownloadUrl,
	getAllProjectsByStatus,
	getAllInvitations,
	getAllProjectsHistory,
	getInvitationsCount,
	getBiddableProjects,
	createProject,
} from '../../Stores/Projects/action'
import { sendInvoiceForProject } from '../../Stores/Payments/action'
import { getProfile, updateProfile, getEnterprisePlusContract, cancelEnterprisePlusPrompt, completePilotEnterprisePlus, getWebConfig } from '../../Stores/UserSession/action'
import { connect } from 'react-redux'
import AeroModal from '../../AeroComponents/Modal'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { goTo } from '../../Services/NavigationService'
import ScrollableContent from './Components/scrollable-content'
import { capitalize, debounce } from '../../Services/UtilityService'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import EnterprisePilotPlus from './Components/EnterprisePilotPlus'
import EnterprisePlusPilotBanner from './Components/EnterprisePlusPilotBanner'
import ProjectMobileChecker from '../Projects/Components/project-mobile-checker'
const { TabPane } = Tabs
const projectTypeOptions = [
	{
		label: 'Commercial',
		value: 0,
	},
	{
		label: 'Invited by Aerologix',
		value: 1,
	},
]

class HomeForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			profileType: getValueFromStorage('PROFILE_TYPE'),
			ongoing: [],
			pending: [],
			completed: [],
			biddable: [],
			history: [],
			invitations: [],
			updateProjectState: false,
			projectState: '',
			selectedProjectAmount: 0,
			selectedProjectQuoteId: '',
			enterprisePilotBanner: false,
			totalOngoing: 1,
			currentOngoingPage: 1,
			totalPending: 1,
			currentPendingPage: 1,
			totalCompleted: 1,
			totalBiddable: 1,
			totalHistory: 1,
			totalInvitations: 1,
			currentInvitationsPage: 1,
			currentCompletedPage: 1,
			currentBiddablePage: 1,
			currentHistoryPage: 1,
			invitationsCount: 0,
			currentFilter: [],
			searchToken: '',
			filterBoxVisible: false,
			filterBtnType: '',
			currentTab: 'ongoing',
			toggleMailBox: false,
			selectedProject: '',
			userEmail: '',
			profileData: null,
			participationBoxVisible: false,
			compBannerVisible: false,
			loading: false,
			enterprisePlusBannerVisible: null,
			enterprisePlusPopup: false,
			configData: props.webConfig,
			pnModal: false,
			cancelPnModalStage1: false,
			cancelPnModalStage2: false,
			projectPublishedModal: false,
		}
		this.biddableRef = React.createRef(null)
		this.biddableClose = React.createRef(null)
		this.invitationsRef = React.createRef(null)
		this.invitationsClose = React.createRef(null)
		this.pendingRef = React.createRef(null)
		this.pendingClose = React.createRef(null)
		this.ongoingRef = React.createRef(null)
		this.ongoingClose = React.createRef(null)
		this.historyRef = React.createRef(null)
		this.historyClose = React.createRef(null)
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.updateQuoteSuccess !== nextProps.updateQuoteSuccess) {
			if (nextProps.updateQuoteSuccess) {
				this.setState({ updateProjectState: false })
				this.props.getAllInvitations({ pageNumber: this.state.currentPendingPage, pageSize: 10, status: 'PENDING', isEnterprise: 0 })
				this.props.getInvitationsCount({ status: 'PENDING' })
			}
		}
		if (this.props.downloadUrlData !== nextProps.downloadUrlData) {
			if (nextProps.downloadUrlData) {
				window.location.replace(nextProps.downloadUrlData)
			}
		}
		if (this.props.profileData !== nextProps.profileData) {
			if (nextProps.profileData) {
				if (nextProps.profileData.enterpriseStatus === 'INVITED' && !nextProps.profileData.enterpriseInvitePopup) {
					if (!window.sessionStorage.getItem('enterprisePlusBannerClosed')) {
						window.sessionStorage.setItem('enterprisePlusBannerVisible', true)
						this.setState({
							enterprisePlusBannerVisible: true,
						})
					}
				} else {
					window.sessionStorage.setItem('enterprisePlusBannerVisible', false)
					this.setState({
						enterprisePlusBannerVisible: false,
					})
				}
				this.setState({
					userEmail: nextProps.profileData.email,
					profileData: nextProps.profileData,
					enterprisePilotBanner:
						nextProps.profileData.enterpriseBannerDetails?.visibility && nextProps.profileData.enterpriseBannerDetails.state === 'VERIFICATION_FAILED' ? true : false,
				})
			}
		}
		if (this.props.projectsByStatusData !== nextProps.projectsByStatusData) {
			if (nextProps.projectsByStatusData) {
				const { currentTab } = this.state
				const total = 'total' + capitalize(currentTab)
				const prevValue = this.state[currentTab]
				if (prevValue.length && this.state[`current${capitalize(currentTab)}Page`] === 1) {
					this.setState({ [currentTab]: nextProps.projectsByStatusData.data, [total]: nextProps.projectsByStatusData.count, loading: false })
				} else {
					this.setState({ [currentTab]: [...prevValue, ...nextProps.projectsByStatusData.data], [total]: nextProps.projectsByStatusData.count, loading: false })
				}
			}
		}
		if (this.props.invitationsData !== nextProps.invitationsData) {
			if (nextProps.invitationsData) {
				const { currentTab } = this.state
				const total = 'total' + capitalize(currentTab)
				const prevValue = this.state[currentTab]
				if (prevValue.length && this.state[`current${capitalize(currentTab)}Page`] === 1) {
					this.setState({ [currentTab]: nextProps.invitationsData.data, [total]: nextProps.invitationsData.count, loading: false })
				} else {
					this.setState({ [currentTab]: [...prevValue, ...nextProps.invitationsData.data], [total]: nextProps.invitationsData.count, loading: false })
				}
			}
		}
		if (this.props.biddableProjectsData !== nextProps.biddableProjectsData) {
			if (nextProps.biddableProjectsData) {
				if (this.state.biddable.length && this.state.currentBiddablePage === 1) {
					this.setState({ biddable: nextProps.biddableProjectsData.data, totalBiddable: nextProps.biddableProjectsData.count, loading: false })
				} else {
					this.setState({
						biddable: [...this.state.biddable, ...nextProps.biddableProjectsData.data],
						totalBiddable: nextProps.biddableProjectsData.count,
						loading: false,
					})
				}
			}
		}
		if (this.props.projectsHistoryData !== nextProps.projectsHistoryData) {
			if (nextProps.projectsHistoryData) {
				this.setState({ completed: nextProps.projectsHistoryData.data, totalCompleted: nextProps.projectsHistoryData.count })
			}
		}
		if (this.props.invitationsCount !== nextProps.invitationsCount) {
			if (nextProps.invitationsCount) {
				this.setState({ invitationsCount: nextProps.invitationsCount.count })
			}
		}
		if (this.props.sendInvoiceForProjectSuccess !== nextProps.sendInvoiceForProjectSuccess) {
			if (nextProps.sendInvoiceForProjectSuccess) {
				this.toggleMailBox()
				message.success(
					<span>
						Invoice sent to <strong>{nextProps.sendInvoiceForProjectSuccess.message}</strong>{' '}
					</span>,
				)
			}
		}
		if (this.props.updateProfileSuccess !== nextProps.updateProfileSuccess) {
			if (nextProps.updateProfileSuccess) {
				this.setState({
					pnModal: false,
				})
			}
		}
		if (this.props.webConfig !== nextProps.webConfig) {
			if (nextProps.webConfig) {
				this.setState({
					configData: nextProps.webConfig,
				})
			}
		}
	}

	componentDidMount() {
		this.props.getProfile().then(res => {
			if (this.state.profileType === 'CONSUMER') {
				const projectData = getValueFromStorage('PROJECT_DATA')
				if (projectData) {
					if (res.value.data.mobileNumber) {
						this.checkAndCreateProject(projectData)
					} else {
						this.setState({ pnModal: true })
					}
				} else {
					this.props.getAllProjectsByStatus({ pageNumber: this.state.currentPendingPage, pageSize: 10, status: 'INVITATIONS', isEnterprise: 0 })
				}
			}
		})
		if (!this.state.configData) {
			this.props.getWebConfig()
		}
		if (this.state.profileType === 'PILOT') {
			let compBannerVisible = false
			if (window.sessionStorage.getItem('compBannerVisible') === null) {
				window.sessionStorage.setItem('compBannerVisible', true)
				compBannerVisible = true
			} else {
				if (window.sessionStorage.getItem('compBannerVisible') === 'false') {
					compBannerVisible = false
				} else {
					compBannerVisible = true
				}
			}
			this.setState({ compBannerVisible: compBannerVisible })
			this.props.getBiddableProjects({ pageNumber: this.state.currentBiddablePage, pageSize: 10 })
			this.props.getInvitationsCount({ status: 'PENDING' })
		}
	}

	updateProjectState = status => {
		this.setState({ updateProjectState: true, projectState: status })
	}

	handleCancel = modalType => {
		this.setState({
			[modalType]: false,
		})
	}

	showModal = (quoteId, amount, status) => {
		this.setState({ updateProjectState: true, projectState: status, selectedProjectAmount: amount, selectedProjectQuoteId: quoteId })
	}

	updateQuoteStatus = () => {
		let body = {
			amount: Number(this.state.selectedProjectAmount),
			quoteId: this.state.selectedProjectQuoteId,
			status: this.state.projectState,
		}
		this.props.updateQuote(body)
	}

	goToProject = () => {
		pushEventToMixpanel('', 'New Project Option Clicked')
		this.props.history.push({ pathname: '/create-new-project' })
	}

	downloadData = projectId => {
		this.props.getDownloadUrl({ type: 'upload', project: projectId })
	}

	changeOngoingPage = page => {
		this.setState({ currentOngoingPage: page })
		if (this.state.profileType === 'CONSUMER') {
			this.props.getAllProjectsByStatus({ pageNumber: page, pageSize: 10, status: 'INVITATIONS', isEnterprise: 0 })
		} else {
			if (!this.state.currentFilter?.length || this.state.currentFilter?.length === 2) {
				this.props.getAllProjectsByStatus({ pageNumber: page, pageSize: 10, status: 'ONGOING', searchToken: this.state.searchToken })
			} else {
				this.props.getAllProjectsByStatus({
					pageNumber: page,
					pageSize: 10,
					status: 'ONGOING',
					isEnterprise: this.state.currentFilter[0],
					searchToken: this.state.searchToken,
				})
			}
		}
	}

	changeBiddablePage = page => {
		this.setState({ currentBiddablePage: page })
		this.props.getBiddableProjects({ pageNumber: page, pageSize: 10, searchToken: this.state.searchToken })
	}

	changePendingPage = page => {
		this.setState({ currentPendingPage: page })
		if (this.state.profileType === 'CONSUMER') {
			this.props.getAllProjectsByStatus({ pageNumber: page, pageSize: 10, status: 'ONGOING', isEnterprise: 0 })
		} else {
			if (!this.state.currentFilter?.length || this.state.currentFilter?.length === 2) {
				this.props.getAllInvitations({ pageNumber: page, pageSize: 10, status: 'ONGOING', searchToken: this.state.searchToken })
			} else {
				this.props.getAllInvitations({ pageNumber: page, pageSize: 10, status: 'ONGOING', isEnterprise: this.state.currentFilter[0], searchToken: this.state.searchToken })
			}
		}
	}

	changeInvitationsPage = page => {
		this.setState({ currentInvitationsPage: page })
		this.props.getAllInvitations({ pageNumber: page, pageSize: 10, status: 'PENDING', isEnterprise: 1, searchToken: this.state.searchToken })
	}

	changeCompletedPage = page => {
		this.setState({ currentCompletedPage: page })
		if (!this.state.currentFilter?.length || this.state.currentFilter?.length === 2) {
			this.props.getAllProjectsByStatus({ pageNumber: page, pageSize: 10, searchToken: this.state.searchToken })
		} else {
			this.props.getAllProjectsByStatus({ pageNumber: page, pageSize: 10, isEnterprise: this.state.currentFilter[0], searchToken: this.state.searchToken })
		}
	}

	changeHistoryPage = page => {
		this.setState({ currentHistoryPage: page })
		if (!this.state.currentFilter?.length || this.state.currentFilter?.length === 2) {
			this.props.getAllInvitations({ pageNumber: page, pageSize: 10, status: 'HISTORY', searchToken: this.state.searchToken })
		} else {
			this.props.getAllInvitations({ pageNumber: page, pageSize: 10, status: 'HISTORY', searchToken: this.state.searchToken, isEnterprise: this.state.currentFilter[0] })
		}
	}

	clearSearchData = type => {
		this.setState({ currentFilter: null, searchToken: '' })
		if (type !== 'completed' && this[type + 'Ref'].current) {
			this[type + 'Close'].current.click()
		}
	}

	onTabChange = tab => {
		this.clearSearchData(tab)
		if (tab === 'invitations') {
			if (this.state.profileType === 'CONSUMER') {
				this.setState({ currentTab: 'pending', currentPendingPage: 1 })
				this.props.getAllProjectsByStatus({ pageNumber: 1, pageSize: 10, status: 'ONGOING', isEnterprise: 0 })
			} else {
				this.setState({ currentTab: 'invitations', currentInvitationsPage: 1 })
				this.props.getAllInvitations({ pageNumber: this.state.currentInvitationsPage, pageSize: 10, status: 'PENDING', isEnterprise: 1 })
			}
		} else if (tab === 'ongoing') {
			this.setState({ currentTab: 'ongoing', currentOngoingPage: 1 })
			if (this.state.profileType === 'CONSUMER') {
				this.props.getAllProjectsByStatus({ pageNumber: 1, pageSize: 10, status: 'INVITATIONS', isEnterprise: 0 })
			} else {
				this.props.getAllProjectsByStatus({ pageNumber: 1, pageSize: 10, status: 'ONGOING' })
			}
		} else if (tab === 'biddable') {
			this.setState({ currentTab: 'biddable', currentBiddablePage: 1 })
			this.props.getBiddableProjects({ pageNumber: 1, pageSize: 10 })
		} else if (tab === 'pending') {
			this.setState({ currentTab: 'pending', currentPendingPage: 1 })
			this.props.getAllInvitations({ pageNumber: this.state.currentPendingPage, pageSize: 10, status: 'ONGOING' })
		} else if (tab === 'history') {
			this.setState({ currentTab: 'history', currentHistoryPage: 1 })
			this.props.getAllInvitations({ pageNumber: this.state.currentHistoryPage, pageSize: 10, status: 'HISTORY' })
		} else {
			this.setState({ currentTab: 'completed', currentCompletedPage: 1 })
			this.props.getAllProjectsByStatus({ pageNumber: 1, pageSize: 10 })
		}
	}

	onFilterChange = (value, type) => {
		this.setState({ currentFilter: value, [type]: [], ['total' + capitalize(type)]: 1 })
	}

	toggleMailBox = () => {
		this.setState(prevState => ({ mailBoxVisible: !prevState.mailBoxVisible }))
	}

	mailSingleInvoice = project => {
		this.setState({ selectedProject: project, mailBoxVisible: true })
	}

	sendSingleInvoice = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const body = {
					email: values.email,
					projectId: this.state.selectedProject.id,
				}
				this.props.sendInvoiceForProject(body)
				this.props.form.resetFields()
			} else {
				message.error('Please enter email!')
			}
		})
	}

	toggleParticipationBox = () => this.setState(prevState => ({ participationBoxVisible: !prevState.participationBoxVisible }))
	closeCompBanner = () => {
		this.setState({
			compBannerVisible: false,
		})
		window.sessionStorage.setItem('compBannerVisible', 'false')
	}

	loadMoreData = type => {
		if (this.state.loading) return
		this.setState({ loading: true })
		if (type === 'ongoing') {
			this.changeOngoingPage(this.state.currentOngoingPage + 1)
		} else if (type === 'pending') {
			this.changePendingPage(this.state.currentPendingPage + 1)
		} else if (type === 'completed') {
			this.changeCompletedPage(this.state.currentCompletedPage + 1)
		} else if (type === 'biddable') {
			this.changeBiddablePage(this.state.currentBiddablePage + 1)
		} else if (type === 'history') {
			this.changeHistoryPage(this.state.currentHistoryPage + 1)
		} else if (type === 'invitations') {
			this.changeInvitationsPage(this.state.currentInvitationsPage + 1)
		}
	}

	openProjectDetails = projectId => goTo(`projects/${projectId}`)

	submitOtp = data => {
		this.props.updateProfile(data)
	}

	closeEnterprisePlusPilotModal = () => {
		this.setState({ enterprisePlusPopup: false })
	}

	hideEnterprisePlusBanner = () => {
		this.setState({ enterprisePlusBannerVisible: false })
		window.sessionStorage.setItem('enterprisePlusBannerVisible', 'false')
		window.sessionStorage.setItem('enterprisePlusBannerClosed', 'true')
	}

	handleSearch = type => {
		if (type === 'biddable') {
			const val = this.biddableRef.current.input.value
			this.setState({ searchToken: val })
			if (val === '') {
				this.props.getBiddableProjects()
			} else if (val.length >= 3) {
				this.onFilterChange(this.state.currentFilter, type)
				this.props.getBiddableProjects({ searchToken: val })
			}
		} else if (type === 'invitations') {
			const val = this.invitationsRef.current.input.value
			this.setState({ searchToken: val })
			if (val === '') {
				if (this.state.profileType === 'CONSUMER') {
					this.props.getAllProjectsByStatus({ status: 'ONGOING', isEnterprise: 0 })
				} else {
					this.props.getAllInvitations({ status: 'PENDING', isEnterprise: 1 })
				}
			} else if (val.length >= 3) {
				this.onFilterChange(this.state.currentFilter, type)
				if (this.state.profileType === 'CONSUMER') {
					this.props.getAllProjectsByStatus({ status: 'ONGOING', isEnterprise: 0, searchToken: val })
				} else {
					this.props.getAllInvitations({ status: 'PENDING', isEnterprise: 1, searchToken: val })
				}
			}
		} else if (type === 'pending') {
			const val = this.pendingRef.current.input.value
			this.setState({ searchToken: val })
			if (val === '') {
				if (!this.state.currentFilter?.length || this.state.currentFilter?.length === 2) {
					this.props.getAllInvitations({ status: 'ONGOING' })
				} else {
					this.props.getAllInvitations({ status: 'ONGOING', isEnterprise: this.state.currentFilter[0] })
				}
			} else if (val.length >= 3) {
				this.onFilterChange(this.state.currentFilter, type)
				if (!this.state.currentFilter?.length || this.state.currentFilter?.length === 2) {
					this.props.getAllInvitations({ status: 'ONGOING', searchToken: val })
				} else {
					this.props.getAllInvitations({ status: 'ONGOING', searchToken: val, isEnterprise: this.state.currentFilter[0] })
				}
			}
		} else if (type === 'ongoing') {
			const val = this.ongoingRef.current.input.value
			this.setState({ searchToken: val })
			if (val === '') {
				if (this.state.profileType === 'CONSUMER') {
					this.props.getAllProjectsByStatus({ status: 'INVITATIONS', isEnterprise: 0 })
				} else {
					if (!this.state.currentFilter?.length || this.state.currentFilter?.length === 2) {
						this.props.getAllProjectsByStatus({ status: 'ONGOING' })
					} else {
						this.props.getAllProjectsByStatus({ status: 'ONGOING', isEnterprise: this.state.currentFilter[0] })
					}
				}
			} else if (val.length >= 3) {
				this.onFilterChange(this.state.currentFilter, type)
				if (this.state.profileType === 'CONSUMER') {
					this.props.getAllProjectsByStatus({ status: 'ONGOING', isEnterprise: 0, searchToken: val })
				} else {
					if (!this.state.currentFilter?.length || this.state.currentFilter?.length === 2) {
						this.props.getAllProjectsByStatus({ status: 'ONGOING', searchToken: val })
					} else {
						this.props.getAllProjectsByStatus({ status: 'ONGOING', searchToken: val, isEnterprise: this.state.currentFilter[0] })
					}
				}
			}
		} else if (type === 'history') {
			const val = this.historyRef.current.input.value
			this.setState({ searchToken: val })
			if (val === '') {
				if (!this.state.currentFilter?.length || this.state.currentFilter?.length === 2) {
					this.props.getAllInvitations({ status: 'HISTORY' })
				} else {
					this.props.getAllInvitations({ status: 'HISTORY', isEnterprise: this.state.currentFilter[0] })
				}
			} else if (val.length >= 3) {
				this.onFilterChange(this.state.currentFilter, type)
				if (!this.state.currentFilter?.length || this.state.currentFilter?.length === 2) {
					this.props.getAllInvitations({ status: 'HISTORY', searchToken: val })
				} else {
					this.props.getAllInvitations({ status: 'HISTORY', searchToken: val, isEnterprise: this.state.currentFilter[0] })
				}
			}
		}
	}

	handleFilter = (checkedValues, type) => {
		this.onFilterChange(checkedValues, type)
		if (type === 'pending') {
			if (!checkedValues?.length || checkedValues?.length === 2) {
				this.props.getAllInvitations({ pageNumber: 1, pageSize: 10, status: 'ONGOING', searchToken: this.state.searchToken })
			} else {
				this.props.getAllInvitations({ pageNumber: 1, pageSize: 10, status: 'ONGOING', isEnterprise: checkedValues[0], searchToken: this.state.searchToken })
			}
		} else if (type === 'ongoing') {
			if (!checkedValues?.length || checkedValues?.length === 2) {
				this.props.getAllProjectsByStatus({ pageNumber: 1, pageSize: 10, status: 'ONGOING', searchToken: this.state.searchToken })
			} else {
				this.props.getAllProjectsByStatus({ pageNumber: 1, pageSize: 10, status: 'ONGOING', isEnterprise: checkedValues[0], searchToken: this.state.searchToken })
			}
		} else if (type === 'history') {
			if (!checkedValues?.length || checkedValues?.length === 2) {
				this.props.getAllInvitations({ status: 'HISTORY', searchToken: this.state.searchToken })
			} else {
				this.props.getAllInvitations({ status: 'HISTORY', searchToken: this.state.searchToken, isEnterprise: checkedValues[0] })
			}
		}
	}

	checkAndCreateProject = projectData => {
		this.props.createProject(JSON.parse(projectData)).then(() => {
			this.props.getAllProjectsByStatus({ pageNumber: this.state.currentPendingPage, pageSize: 10, status: 'INVITATIONS', isEnterprise: 0 })
			window.localStorage.removeItem('PROJECT_DATA')
			this.setState({ projectPublishedModal: true })
		})
	}

	togglePnModal = data => this.setState({ pnModal: data })
	handlePnModalCancel = () => this.setState(prevState => ({ cancelPnModalStage1: !prevState.cancelPnModalStage1 }))

	handlePnVerificationCancel = () => {
		this.togglePnModal(false)
		this.setState(prevState => ({ cancelPnModalStage1: !prevState.cancelPnModalStage1 }))
		this.setState(prevState => ({ cancelPnModalStage2: !prevState.cancelPnModalStage2 }))
		window.localStorage.removeItem('PROJECT_DATA')
		this.props.getAllProjectsByStatus({ pageNumber: this.state.currentPendingPage, pageSize: 10, status: 'INVITATIONS', isEnterprise: 0 })
	}

	handlePublishCancel = () => {
		this.setState(prevState => ({ cancelPnModalStage2: !prevState.cancelPnModalStage2 }))
	}

	toggleProjectPublishModal = () => {
		this.setState(prevState => ({ projectPublishedModal: !prevState.projectPublishedModal }))
		window.location.reload()
	}

	render() {
		const {
			ongoing,
			pending,
			completed,
			profileType,
			enterprisePilotBanner,
			totalOngoing,
			totalCompleted,
			totalPending,
			biddable,
			totalBiddable,
			history,
			totalHistory,
			mailBoxVisible,
			selectedProject,
			userEmail,
			compBannerVisible,
			profileData,
			pnModal,
			enterprisePlusBannerVisible,
			invitationsCount,
			totalInvitations,
			invitations,
			currentFilter,
			configData,
		} = this.state
		const { getFieldDecorator } = this.props.form
		return (
			<React.Fragment>
				{enterprisePilotBanner ? (
					<div style={{ padding: '20px' }}>
						<Alert
							message={<span style={{ color: 'white', paddingLeft: '15px' }}>Your application for Enterprise program has been rejected</span>}
							description={
								<span>
									<span style={{ color: 'white', paddingLeft: '15px', fontSize: '13px' }}>The uploaded insurance document has been rejected</span>
									<span className='remind-me-later'>
										<Button
											onClick={() => goTo('settings')}
											style={{ background: 'white', color: '#FA541C', width: '168px', borderRadius: '8px', fontSize: '16px' }}
										>
											Retry
										</Button>
									</span>
								</span>
							}
							type='info'
							closable
							closeText={<span style={{ color: 'white' }}>X</span>}
							showIcon
							icon={
								<span>
									<MatImage src={IMAGES_SRC.BIG_WHITE_EXCLAMATION_ICON} />
								</span>
							}
							className='enterprise-pilot-eligiblity-alert-failed'
						/>
					</div>
				) : null}
				{profileType === 'PILOT' &&
				profileData?.enterpriseStatus === 'INVITED' &&
				!profileData?.enterpriseInvitePopup &&
				!profileData?.enterprisePilot &&
				enterprisePlusBannerVisible ? (
					<EnterprisePlusPilotBanner
						profileData={profileData}
						hideEnterprisePlusBanner={this.hideEnterprisePlusBanner}
						openPopup={() => this.setState({ enterprisePlusPopup: true })}
					/>
				) : null}
				{profileType === 'PILOT' && `compBannerVisible` && !enterprisePlusBannerVisible && configData.countryId === 1 ? (
					<Badge.Ribbon
						style={{ marginLeft: '21px', height: '30px' }}
						text={
							<span style={{ position: 'relative', top: '5px', padding: '10px' }}>
								<BellTwoTone twoToneColor='#FFC53D' style={{ position: 'relative', bottom: '3px', right: '5px' }} />
								<span style={{ color: 'black' }}>REWARD ALERT!</span>
							</span>
						}
						color='white'
						placement='start'
					>
						<Card className='competition-banner'>
							{profileData?.completed ? (
								<strong>
									<CloseOutlined
										onClick={this.closeCompBanner}
										style={{ float: 'right', color: 'white', fontSize: '20px', position: 'relative', bottom: '9px', left: '12px' }}
									/>
								</strong>
							) : null}
							<div style={{ color: 'white', marginTop: '25px' }}>
								<p style={{ fontSize: '20px' }}>Submit your video footage and be rewarded!</p>
								<p style={{ fontSize: '16px' }}>
									Aerologix’s exclusive relationship with Getty Images allows you to earn recurring royalties on uploaded footage (via a licensing agreement).
								</p>
								<p>
									{profileData?.arnLicense?.verificationStatus === 'VERIFICATION_SUCCEEDED' &&
									profileData?.firstName &&
									profileData?.lastName &&
									profileData?.email &&
									profileType === 'PILOT' ? (
										<span>
											<MatImage src={IMAGES_SRC.CELEBRATION_ICON} /> You are eligible to upload your footage(videos) on our platform!
										</span>
									) : (
										'Go to the Profile tab and add your ARN. Once your ARN is verified, the "Footage" tab will be visible on the left menu.'
									)}
								</p>
							</div>
						</Card>
					</Badge.Ribbon>
				) : null}
				{profileType === 'CONSUMER' && profileData && (!profileData.discountAvailed && !profileData.mobileNumber) ? (
					<div className='discount-alert-box'>
						<div style={{ fontSize: '24px' }}>
							<MatImage alt='prmn-icn' style={{ marginRight: '10px', position: 'relative', bottom: '3px' }} src={IMAGES_SRC.DOLLAR_ICON} />
							Get {configData.currency} 50 off on your first Aerologix Project!
							{/* <Button onClick={this.togglePnModal} style={{ width: '150px', position: 'relative', left: '90px' }}>
								Add Now
							</Button> */}
						</div>
					</div>
				) : null}

				<div style={{ padding: '25px' }}>
					{profileType === 'CONSUMER' ? (
						<Button className='new-project-btn' onClick={this.goToProject} type='primary'>
							<PlusCircleOutlined style={{ display: 'inline-flex', marginRight: '4px' }} /> New Project
						</Button>
					) : (
						''
					)}
					<div className='projects-heading'>{profileType === 'PILOT' ? 'My Projects' : 'Projects'}</div>
					{profileType === 'PILOT' ? (
						<Tabs style={{ marginTop: '20px' }} onChange={this.onTabChange} defaultActiveKey='biddable'>
							<TabPane
								tab={
									<span>
										Open for bidding{' '}
										<Tooltip
											title='This tab features projects posted by 
							consumers, and to express your interest in completing a project, you must submit a bid'
										>
											<InfoCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
										</Tooltip>
									</span>
								}
								key='biddable'
							>
								<Input
									style={{ width: '30%', borderRadius: '8px' }}
									ref={this.biddableRef}
									allowClear={{ clearIcon: <CloseCircleFilled style={{ position: 'relative', bottom: '2px' }} ref={this.biddableClose} /> }}
									onChange={debounce(() => this.handleSearch('biddable'), 500)}
									placeholder='Search by Project Name'
								/>
								<div id='biddableDiv' style={{ overflow: 'auto', height: '75vh' }}>
									<ScrollableContent
										profileType={profileType}
										openProjectDetails={this.openProjectDetails}
										data={biddable}
										loadMoreData={this.loadMoreData}
										totalData={totalBiddable}
										type='biddable'
										divId='biddableDiv'
									/>
								</div>
							</TabPane>
							{invitationsCount && (
								<TabPane
									tab={
										<span>
											Invitations{' '}
											<Tooltip
												title="This tab features project invitations sent by Aerologix.
								You're required to accept the invitation to show your interest in completing the project."
											>
												<InfoCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
											</Tooltip>
											<MatImage src={IMAGES_SRC.INCOMPLETE_ICON} />
										</span>
									}
									key='invitations'
								>
									<Input
										style={{ width: '30%', borderRadius: '8px' }}
										ref={this.invitationsRef}
										allowClear={{ clearIcon: <CloseCircleFilled style={{ position: 'relative', bottom: '2px' }} ref={this.invitationsClose} /> }}
										onChange={debounce(() => this.handleSearch('invitations'), 500)}
										placeholder='Search by Project Name'
									/>
									<div id='invitationsDiv' style={{ overflow: 'auto', height: '75vh' }}>
										<ScrollableContent
											projectType='enterprise'
											profileType={profileType}
											openProjectDetails={this.openProjectDetails}
											data={invitations}
											loadMoreData={this.loadMoreData}
											totalData={totalInvitations}
											type='invitations'
											divId='invitationsDiv'
										/>
									</div>
								</TabPane>
							)}
							<TabPane tab='Awaiting Response' key='pending'>
								<Input
									style={{ width: '30%', borderRadius: '8px' }}
									ref={this.pendingRef}
									allowClear={{ clearIcon: <CloseCircleFilled style={{ position: 'relative', bottom: '2px' }} ref={this.pendingClose} /> }}
									onChange={debounce(() => this.handleSearch('pending'), 500)}
									placeholder='Search by Project Name'
								/>
								<span style={{ marginLeft: '20px' }}>
									<Checkbox.Group value={currentFilter} options={projectTypeOptions} onChange={val => this.handleFilter(val, 'pending')} />
								</span>
								<div id='pendingDiv' style={{ overflow: 'auto', height: '75vh' }}>
									<ScrollableContent
										profileType={profileType}
										openProjectDetails={this.openProjectDetails}
										data={pending}
										loadMoreData={this.loadMoreData}
										totalData={totalPending}
										type='pending'
										divId='pendingDiv'
									/>
								</div>
							</TabPane>
							<TabPane tab='Ongoing' key='ongoing'>
								<Input
									style={{ width: '30%', borderRadius: '8px' }}
									ref={this.ongoingRef}
									allowClear={{ clearIcon: <CloseCircleFilled style={{ position: 'relative', bottom: '2px' }} ref={this.ongoingClose} /> }}
									onChange={debounce(() => this.handleSearch('ongoing'), 500)}
									placeholder='Search by Project Name'
								/>
								<span style={{ marginLeft: '20px' }}>
									<Checkbox.Group value={currentFilter} options={projectTypeOptions} onChange={val => this.handleFilter(val, 'ongoing')} />
								</span>
								<div id='ongoingDiv' style={{ overflow: 'auto', height: '75vh' }}>
									<ScrollableContent
										profileType={profileType}
										openProjectDetails={this.openProjectDetails}
										data={ongoing}
										loadMoreData={this.loadMoreData}
										totalData={totalOngoing}
										type='ongoing'
										divId='ongoingDiv'
									/>
								</div>
							</TabPane>
							<TabPane tab='History' key='history'>
								<Input
									style={{ width: '30%', borderRadius: '8px' }}
									ref={this.historyRef}
									allowClear={{ clearIcon: <CloseCircleFilled style={{ position: 'relative', bottom: '2px' }} ref={this.historyClose} /> }}
									onChange={debounce(() => this.handleSearch('history'), 500)}
									placeholder='Search by Project Name'
								/>
								<span style={{ marginLeft: '20px' }}>
									<Checkbox.Group value={currentFilter} options={projectTypeOptions} onChange={val => this.handleFilter(val, 'history')} />
								</span>
								<div id='historyDiv' style={{ overflow: 'auto', height: '75vh' }}>
									<ScrollableContent
										profileType={profileType}
										openProjectDetails={this.openProjectDetails}
										data={history}
										loadMoreData={this.loadMoreData}
										totalData={totalHistory}
										type='history'
										divId='historyDiv'
									/>
								</div>
							</TabPane>
							{/* <TabPane tab='Completed' key='completed'>
								<Input
									style={{ width: '30%', borderRadius: '8px' }}
									ref={this.historyRef}
									allowClear={{ clearIcon: <CloseCircleFilled style={{position:'relative', bottom:'2px'}} ref={this.historyClose} /> }}
									onChange={debounce(() => this.handleSearch('completed'), 500)}
									placeholder='Search by Project Name'
								/>
								<span style={{ marginLeft: '20px' }}>
									<Checkbox.Group value={currentFilter} options={projectTypeOptions} onChange={val => this.handleFilter(val, 'completed')} />
								</span>
								<div id='completedDiv' style={{ overflow: 'auto', height: '75vh' }}>
									<ScrollableContent
										profileType={profileType}
										openProjectDetails={this.openProjectDetails}
										data={completed}
										loadMoreData={this.loadMoreData}
										totalData={totalCompleted}
										type='completed'
										divId='completedDiv'
									/>
								</div>
							</TabPane> */}
						</Tabs>
					) : (
						<Tabs style={{ marginTop: '20px' }} onChange={this.onTabChange} defaultActiveKey='ongoing'>
							<TabPane tab='Open for Bidding' key='ongoing'>
								<div id='ongoingDiv' style={{ overflow: 'auto', height: '75vh' }}>
									<ScrollableContent
										profileType={profileType}
										openProjectDetails={this.openProjectDetails}
										data={ongoing}
										loadMoreData={this.loadMoreData}
										totalData={totalOngoing}
										type='ongoing'
										divId='ongoingDiv'
									/>
								</div>
							</TabPane>
							<TabPane tab='Ongoing' key='invitations'>
								<div id='pendingDiv' style={{ overflow: 'auto', height: '75vh' }}>
									<ScrollableContent
										profileType={profileType}
										openProjectDetails={this.openProjectDetails}
										data={pending}
										loadMoreData={this.loadMoreData}
										totalData={totalPending}
										type='pending'
										divId='pendingDiv'
									/>
								</div>
							</TabPane>
							<TabPane tab='Completed' key='completed'>
								<div id='completedDiv' style={{ overflow: 'auto', height: '75vh' }}>
									<ScrollableContent
										profileType={profileType}
										openProjectDetails={this.openProjectDetails}
										data={completed}
										loadMoreData={this.loadMoreData}
										totalData={totalCompleted}
										type='completed'
										divId='completedDiv'
									/>
								</div>
							</TabPane>
						</Tabs>
					)}
				</div>

				<AeroModal
					visible={mailBoxVisible}
					title={
						<span>
							<strong>Send Invoice: </strong> {selectedProject.name}
						</span>
					}
					onCancel={this.toggleMailBox}
					footer={false}
				>
					<Form layout='vertical' onSubmit={this.sendSingleInvoice}>
						<Form.Item label='Email Address'>
							{getFieldDecorator('email', {
								rules: [
									{
										required: true,
										message: 'Please enter email!',
										whitespace: true,
									},
								],
								initialValue: userEmail,
							})(<Input />)}
						</Form.Item>
						<Form.Item>
							<Button type='primary' htmlType='submit' style={{ borderRadius: '8px' }} block>
								Send
							</Button>
						</Form.Item>
					</Form>
				</AeroModal>
				{/* <AeroModal visible={pnModal} title='Add Phone Number' footer={false} onCancel={this.togglePnModal}>
					<MobileNumberVerification fieldRequired={true} submitOtp={this.submitOtp} {...this.props} initialNumber={null} />
				</AeroModal> */}
				{((profileData?.enterprisePlusStatus === 'INVITED' && profileData?.enterprisePlusPopup) || this.state.enterprisePlusPopup) && (
					<EnterprisePilotPlus
						{...this.props}
						enterprisePlusPopup={this.state.enterprisePlusPopup}
						closeEnterprisePlusPilotModal={this.closeEnterprisePlusPilotModal}
						hideEnterprisePlusBanner={this.hideEnterprisePlusBanner}
					/>
				)}
				<ProjectMobileChecker
					projectBody={getValueFromStorage('PROJECT_DATA')}
					pnModal={pnModal}
					togglePnModal={this.togglePnModal}
					checkAndCreateProject={this.checkAndCreateProject}
					handlePnModalCancel={this.handlePnModalCancel}
					cancelPnModalStage1={this.state.cancelPnModalStage1}
					cancelPnModalStage2={this.state.cancelPnModalStage2}
					handlePnVerificationCancel={this.handlePnVerificationCancel}
					handlePublishCancel={this.handlePublishCancel}
				/>
			</React.Fragment>
		)
	}
}
const Home = Form.create({ name: 'HOME_FORM' })(HomeForm)
const mapStateToProps = state => {
	return {
		projectData: state.ProjectsReducer.projectData,
		updateQuoteSuccess: state.ProjectsReducer.updateQuoteSuccess,
		downloadUrlData: state.ProjectsReducer.downloadUrlData,
		profileData: state.UserSessionReducer.profileData,
		projectsByStatusData: state.ProjectsReducer.projectsByStatusData,
		invitationsData: state.ProjectsReducer.invitationsData,
		projectsHistoryData: state.ProjectsReducer.projectsHistoryData,
		invitationsCount: state.ProjectsReducer.invitationsCount,
		sendInvoiceForProjectSuccess: state.PaymentsReducer.sendInvoiceForProjectSuccess,
		biddableProjectsData: state.ProjectsReducer.biddableProjectsData,
		updateProfileSuccess: state.UserSessionReducer.updateProfileSuccess,
		webConfig: state.UserSessionReducer.webConfig,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getProjects: bindActionCreators(getProjects, dispatch),
		updateQuote: bindActionCreators(updateQuote, dispatch),
		getDownloadUrl: bindActionCreators(getDownloadUrl, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
		getAllProjectsByStatus: bindActionCreators(getAllProjectsByStatus, dispatch),
		getAllInvitations: bindActionCreators(getAllInvitations, dispatch),
		getAllProjectsHistory: bindActionCreators(getAllProjectsHistory, dispatch),
		getInvitationsCount: bindActionCreators(getInvitationsCount, dispatch),
		sendInvoiceForProject: bindActionCreators(sendInvoiceForProject, dispatch),
		getBiddableProjects: bindActionCreators(getBiddableProjects, dispatch),
		updateProfile: bindActionCreators(updateProfile, dispatch),
		getEnterprisePlusContract: bindActionCreators(getEnterprisePlusContract, dispatch),
		cancelEnterprisePlusPrompt: bindActionCreators(cancelEnterprisePlusPrompt, dispatch),
		completePilotEnterprisePlus: bindActionCreators(completePilotEnterprisePlus, dispatch),
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
		createProject: bindActionCreators(createProject, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Home)
