import { Tabs, Card } from 'antd'
import React from 'react'
import ActivePlanCard from './active-plan-card'

const { TabPane } = Tabs

const PlansTabContent = props => {
	const { allActivePlans, allExpiredPlans, repurchase, handleTabChange, currentTab } = props
	return (
		<Card className='my-plans-content-card'>
			<Tabs onChange={handleTabChange} defaultActiveKey={currentTab}>
				<TabPane tab='Active Plans' key='active'>
					{allActivePlans && allActivePlans.map(item => <ActivePlanCard type='active' key={item.id} data={item} />)}
				</TabPane>
				<TabPane tab='Expired Plans' key='expired'>
					{allExpiredPlans && allExpiredPlans.map(item => <ActivePlanCard type='expired' key={item.id} data={item} repurchase={repurchase} />)}
				</TabPane>
			</Tabs>
		</Card>
	)
}

export default PlansTabContent
