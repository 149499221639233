import { InfoCircleOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import React from 'react'
import { getConfigData } from '../../../Services/LocalStorageService'

const PaymentAlerts = props => {
	const { bannerFlags, paymentPageData } = props
	return (
		<>
			{bannerFlags ? (
				<Alert
					message={
						<div style={{ color: 'white', paddingLeft: '10px' }}>
							Add {!paymentPageData?.bannerFlags?.payment ? ' payment details ' : ''}{' '}
							
							to start receiving payments for projects
						</div>
					}
					description={<div style={{ color: 'white', paddingLeft: '10px', fontSize: '13px' }}>Your payments will be on hold until then</div>}
					type='warning'
					closable={paymentPageData?.bannerFlags?.abn ? true : false}
					showIcon
					icon={<InfoCircleOutlined style={{ color: 'white', fontSize: '35px', paddingTop: '5px' }}></InfoCircleOutlined>}
					className='payment-page-alert'
				/>
			) : null}
		</>
	)
}

export default PaymentAlerts
