import React from 'react'
import styles from './CopyMetadataModal.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'
import { Button } from 'antd'
import MatImage from '../../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../../AeroComponents/Image/image.constants'

import { bulkUpdate } from '../../../../../Stores/Getty/action'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

function CopyMetadataModal(props) {
	// extract props
	const {
		setShowCopyMetadataModal, // from parent
		showCopyMetadataModal,
		buildDataForCopyMetadata,
		bulkUpdate, // dispatch
	} = props

	// functions
	function copyMetadata() {
		let data = buildDataForCopyMetadata()
		setShowCopyMetadataModal(false)
		bulkUpdate({ ...data, mixpanel_type: 'copy_details_to_all_videos' })
	}

	return (
		<AeroModal
			bodyStyle={{ backgroundColor: '#FFFFFF', borderRadius: '12px' }}
			footer={false}
			onCancel={() => {
				setShowCopyMetadataModal(false)
			}}
			visible={showCopyMetadataModal}
		>
			<div className={styles['copy-modal']}>
				<br />
				<div>
					<br />
					<div className={styles['copy-modal-content']}>
						<MatImage style={{ fontSize: '40px' }} src={IMAGES_SRC.COPY_ORANGE_ICON} /> <br />
						<span className={styles['bold-text']}>Copy Details to all other videos?</span>
						<span className={styles['small-text']}> Copying details will erase any existing details you have saved on all other videos </span>
						<br></br>
						<span>
							<Button
								block
								className={styles['button-white']}
								type='primary'
								onClick={() => {
									setShowCopyMetadataModal(false)
								}}
							>
								{' '}
								Cancel
							</Button>
							<Button
								block
								className={styles['button']}
								type='primary'
								onClick={() => {
									copyMetadata()
								}}
							>
								Copy Details
							</Button>
						</span>
					</div>
				</div>
			</div>
		</AeroModal>
	)
}

const mapDispatchToProps = dispatch => {
	return {
		bulkUpdate: bindActionCreators(bulkUpdate, dispatch),
	}
}

export default connect(
	null,
	mapDispatchToProps,
)(CopyMetadataModal)
