import React, { Component } from 'react'
import { Avatar, Badge, Popover } from 'antd'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { goTo } from '../../Services/NavigationService'
import './header.sass'
import { MenuOutlined } from '@ant-design/icons'
import NotificationList from './notification-list'

class Header extends Component {
	handleVacationClick = () => {
		goTo('settings', { enterprisePilotUpdatePopup: true })
	}
	render() {
		const { userName, profilePicture, vacationMode, enterprisePlusPilot, notifications, reduceUnreadCount } = this.props
		return (
			<div className='header-container'>
				<div className='hamburger'>
					<MenuOutlined onClick={this.props.handleHamburger} />
				</div>
				{window.location.pathname === '/home' && <div className='home-header'>Projects</div>}
				{window.location.pathname === '/profile' && <div className='home-header'>Profile</div>}
				{window.location.pathname === '/payment' && <div className='home-header'>Payments</div>}
				{window.location.pathname === '/settings' && <div className='home-header'>Settings</div>}
				{window.location.pathname === '/upgrades' && <div className='home-header'>Upgrades</div>}
				{window.location.pathname === '/contact-us' && <div className='home-header'>Frequently Asked Questions</div>}

				<div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{/* {notifications && (
							<>
								<NotificationList notifications={notifications} reduceUnreadCount={reduceUnreadCount} />
								<Badge style={{ position: 'relative', right: '35px', bottom: '10px' }} count={notifications.unreadCount} overflowCount={50} />
							</>
						)} */}
						{profilePicture ? (
							<div className='circular-header-dp'>
								<MatImage style={{ borderRadius: '50%', maxWidth: '32px' }} src={profilePicture} />
							</div>
						) : (
							<Avatar>{userName.substring(0, 1)}</Avatar>
						)}

						<span className='header-username' style={profilePicture ? { paddingLeft: '15px' } : { paddingLeft: '15px' }}>
							<span style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
								{userName}
								{enterprisePlusPilot && <MatImage src={IMAGES_SRC.ENTERPRISE_PLUS_PROFILE_ICON} />}
							</span>
						</span>
					</div>
					{vacationMode && window.location.pathname === '/home' ? (
						<Popover content='Turn off vacation mode to start receiving projects' trigger='hover'>
							<div
								onClick={this.handleVacationClick}
								style={{ marginBottom: '-10px', marginRight: '25px', position: 'relative', bottom: '4px', cursor: 'pointer' }}
								className='vacation-mode-on-tag'
							>
								<MatImage src={IMAGES_SRC.VACATION_MODE_ON_ICON} /> &nbsp;Vacation Mode is on {' >'}
							</div>
						</Popover>
					) : null}
				</div>
			</div>
		)
	}
}

export default Header
