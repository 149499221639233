import React from 'react'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import AbnTab from './Components/TabContents/abn-tab'
import BankDetailsTab from './Components/TabContents/bank-details-tab'
import ReceivedPaymentsTab from './Components/TabContents/received-payments-tab'
import TaxTab from './Components/TabContents/tax-tab'
import YourEarningsTab from './Components/TabContents/your-earnings-tab'

export const FILTER_VALUES = [
	{ name: 'This month', value: 'THIS_MONTH', key: 0 },
	{ name: 'Last month', value: 'LAST_1_MONTH', key: 1 },
	{ name: 'Last 3 months', value: 'LAST_3_MONTHS', key: 2 },
	{ name: 'Last 6 months', value: 'LAST_6_MONTHS', key: 3 },
	{ name: 'Last 12 months', value: 'LAST_12_MONTHS', key: 4 },
]

export const PaymentTabs = scope => {
	const {
		abn,
		creditCardData,
		allBankAccounts,
		receivedPaymentsList,
		receivedPaymentsColumns,
		totalReveivedPayments,
		currentFilter,
		showFilter,
		totalEarning,
		popVisible,
	} = scope.state
	return [
		{
			key: '1',
			name: 'ABN',
			modKey: 'ABN',
			tab: (
				<>
					<span>Australian Business Number (ABN)</span>
					{!abn ? (
						<div style={{ textAlign: 'left', fontSize: '12px', color: '#F97855' }}>
							<MatImage alt='dot-icn' src={IMAGES_SRC.RED_DOT_ICON} /> Action Required
						</div>
					) : null}
				</>
			),
			component: <AbnTab abn={abn} updateAbnDetails={scope.updateAbnDetails} />,
		},
		{
			key: '6',
			name: 'NZBN',
			modKey: 'NZBN',
			tab: (
				<>
					<span>New Zealand Business Number (NZBN)</span>
					{!abn ? (
						<div style={{ textAlign: 'left', fontSize: '12px', color: '#F97855' }}>
							<MatImage alt='dot-icn' src={IMAGES_SRC.RED_DOT_ICON} /> Action Required
						</div>
					) : null}
				</>
			),
			component: <AbnTab abn={abn} updateAbnDetails={scope.updateAbnDetails} />,
		},
		{
			key: '2',
			name: 'Payment Details',
			modKey: 'PAYMENT_DETAILS',
			tab: (
				<>
					<span>Payment Details</span>
					{!allBankAccounts?.length ? (
						<div style={{ textAlign: 'left', fontSize: '12px', color: '#F97855' }}>
							<MatImage alt='dot-icn' src={IMAGES_SRC.RED_DOT_ICON} /> Action Required
						</div>
					) : null}
				</>
			),
			component: (
				<BankDetailsTab
					creditCardData={creditCardData}
					allBankAccounts={allBankAccounts}
					getStripeUrl={scope.getStripeUrl}
					makeDefault={scope.makeDefault}
					deleteAccount={scope.deleteAccount}
				/>
			),
		},
		{
			key: '3',
			name: 'Received Payments',
			modKey: 'RECEIVED_PAYMENTS',
			tab: 'Received Payments',
			component: (
				<ReceivedPaymentsTab
					receivedPaymentsList={receivedPaymentsList}
					onSearch={scope.onSearch}
					changePage={scope.changePage}
					receivedPaymentsColumns={receivedPaymentsColumns}
					totalReveivedPayments={totalReveivedPayments}
				/>
			),
		},
		{
			key: '4',
			name: 'Your Earnings',
			modKey: 'YOUR_EARNINGS',
			tab: 'Your Earnings',
			component: <YourEarningsTab currentFilter={currentFilter} changeFilter={scope.changeFilter} totalEarning={totalEarning} />,
		},
		{
			key: '5',
			name: 'Tax Statement',
			modKey: 'TAX_STATEMENT',
			tab: 'Tax Statement',
			component: <TaxTab userEmail={scope.props.userEmail} sendSingleInvoice={scope.sendSingleInvoice} />,
		},
	]
}
