// react & redux imports
import React, { Component } from 'react'
// library imports
// style import
//  component imports

// store imports

class MatImage extends Component {
	render() {
		return (
			<React.Fragment>
				<img
					className={this.props.className}
					src={this.props.src}
					width={this.props.width}
					height={this.props.height}
					alt={!!this.props.alt ? this.props.alt : ''}
					{...this.props}
				/>
			</React.Fragment>
		)
	}
}

export default MatImage
