/*eslint-disable*/
import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button } from 'antd'
import { MOBILE_NIUMBER_VERIFICATION_FIELDS } from '../../../AeroComponents/FormFields/Metadata/mobile-number-verification.fields'
import AeroField from '../../../AeroComponents/FormFields'
import { getConfigData } from '../../../Services/LocalStorageService'
class MobileNumberVerificationForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fieldsToRender: MOBILE_NIUMBER_VERIFICATION_FIELDS(this, props.initialNumber, props.fieldRequired),
			layout: 'vertical',
			otpData: '',
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const { otpData } = this.state
				const body = {
					mobileNumber: values.mobileNumber,
					otpRequest: { otp: values.verificationCode, otpKey: otpData.otp_key, countryCode: getConfigData().isdCode },
					mixpanel_type: 'phone_number',
				}
				this.props.submitOtp(body)
			}
		})
	}

	getPhoneNumber = () => {
		return this.props.form.getFieldValue('mobileNumber')
	}

	setOtpKey = data => {
		this.setState({ otpData: data })
	}

	render() {
		const { fieldsToRender } = this.state
		return (
			<Form layout={this.state.layout} onSubmit={this.handleSubmit}>
				<AeroField fieldsToRender={fieldsToRender} {...this.props} />
				<Button style={{ height: '48px' }} block type='primary' htmlType='submit'>
					Verify & Add
				</Button>
			</Form>
		)
	}
}

const MobileNumberVerification = Form.create({ name: 'PN_VERIFICATION_FORM' })(MobileNumberVerificationForm)

export default MobileNumberVerification
