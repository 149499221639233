import React, { useEffect, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Drawer, Tabs, Form, message, Spin } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import NewProjectPerStep from './Components/new-project-per-step'
import { getConfigData, initializeAuth, setValueToStorage } from '../../Services/LocalStorageService'
import { goTo } from '../../Services/NavigationService'
import { createProject, getProjectForConsumer, updateProject } from '../../Stores/Projects/action'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import { urlConfig } from '../../Services/UtilityService'
import ProjectMobileChecker from './Components/project-mobile-checker'

const TAB_MAP = [
	{
		tab: 'Summary & Location',
		key: '1',
	},
	{
		tab: 'Time',
		key: '2',
	},
	{
		tab: 'Project Details',
		key: '3',
	},
	{
		tab: 'Budget',
		key: '4',
	},
]
const MIN_PROJECT_BUDGET = 101

const CreateNewProject = props => {
	const configData = getConfigData() ? getConfigData() : urlConfig()
	const { createProject, getProjectForConsumer, consumerProjectData, updateProject, profileData } = props
	const [form] = Form.useForm()
	const { getFieldValue, setFieldsValue } = form
	const [currentStep, setCurrentStep] = useState(1)
	const [googleLocationObject, setGoogleLocationObject] = useState(null)
	const [openModalToAddDetails, setOpenModalToAddDetails] = useState(false)
	const [selectedDateType, setSelectedDateType] = useState(null)
	const [onDateValue, setOnDateValue] = useState(null)
	const [beforeDateValue, setBeforeDateValue] = useState(null)
	const [showSlots, setShowSlots] = useState(false)
	const [selectedSlot, setSelectedSlot] = useState(null)
	const [descValue, setDescValue] = useState(null)
	const [loading, setLoading] = useState(false)
	const [waitForApi, setWaitForApi] = useState(false)
	const [projectName, setProjectName] = useState(null)
	const [projectCost, setProjectCost] = useState(null)
	const [holdOn, setHoldOn] = useState(false)
	const [fileData, setFileData] = useState(null)
	const [pnModal, setPnModal] = useState(false)
	const [cancelPnModalStage1, setCancelPnModalStage1] = useState(false)
	const [cancelPnModalStage2, setCancelPnModalStage2] = useState(false)
	const [projectBody, setProjectBody] = useState(null)
	const [isNegotiable, setIsNegotiable] = useState(false)
	const [budgetLimit, setBudgetLimit] = useState(false)
	const loggedIn = !!initializeAuth()
	const { projectId } = props.match.params
	const editMode = projectId ? true : false

	useEffect(() => {
		if (editMode) {
			setWaitForApi(true)
			getProjectForConsumer({ consumer: projectId })
				.then(() => {
					setLoading(false)
				})
				.catch(err => {
					setLoading(false)
					console.log('fetch project err ', err)
				})
		}
	}, [editMode])

	useEffect(() => {
		if (consumerProjectData && waitForApi) {
			setWaitForApi(false)
			setFieldsValue({
				projectName: consumerProjectData.name,
				location: consumerProjectData.address,
			})
			setProjectName(consumerProjectData.name)
			setHoldOn(true)
			setIsNegotiable(consumerProjectData.is_negotiable)
			setGoogleLocationObject({ lat: consumerProjectData.latitude, lng: consumerProjectData.longitude })

			if (consumerProjectData.deadline_type === 'ON') {
				setOnDateValue(moment.utc(consumerProjectData.deadline).local())
				setSelectedDateType('ondate')
			} else if (consumerProjectData.deadline_type === 'BEFORE') {
				setBeforeDateValue(moment.utc(consumerProjectData.deadline).local())
				setSelectedDateType('beforedate')
			} else if (consumerProjectData.deadline_type === 'FLEXIBLE') {
				setSelectedDateType('flexible')
			}

			if (consumerProjectData.deadline_slot) {
				setShowSlots(true)
				setSelectedSlot(consumerProjectData.deadline_slot)
			}

			setDescValue(consumerProjectData.additional_info)
			setFileData(consumerProjectData.descriptionFile)
			setProjectCost(consumerProjectData.total_fee)
		}
	}, [consumerProjectData])

	const handleClose = () => {
		if (loggedIn) {
			goTo('home')
		} else {
			if (configData.countryId === 1) {
				setValueToStorage('PROFILE_TYPE', 'CONSUMER')
				goTo('signup-select')
			} else {
				goTo('')
			}
		}
	}

	const handleNextBtn = () => {
		if (currentStep === 1) {
			if (!projectName || !getFieldValue('location') || !googleLocationObject || !projectName.replace(/\s/g, '').length) {
				return message.error('Please fill in the details to move ahead!')
			}
			// Sending Summary And Location Entered event to Mixpanel
			pushEventToMixpanel('', 'Summary And Location Entered')
			setCurrentStep(2)
		} else if (currentStep === 2) {
			if (!selectedDateType || (showSlots && !selectedSlot)) {
				return message.error('Please fill in the details to move ahead!')
			}
			// Sending Date And Time Entered event to Mixpanel
			pushEventToMixpanel('', 'Date And Time Entered')
			setCurrentStep(3)
		} else if (currentStep === 3) {
			if (!descValue) {
				return message.error('Please fill in the details to move ahead!')
			}
			// Sening Description Entered event to Mixpanel
			pushEventToMixpanel('', 'Description Entered')
			setCurrentStep(4)
		}
	}

	const handleTabChange = val => {
		if (val === '2') {
			if (!projectName || !getFieldValue('location') || !googleLocationObject || !projectName.replace(/\s/g, '').length) {
				return message.error('Please fill in the details to move ahead!')
			}
			setCurrentStep(Number(val))
		} else if (val === '3') {
			if (
				!projectName ||
				!getFieldValue('location') ||
				!googleLocationObject ||
				!projectName.replace(/\s/g, '').length ||
				(!selectedDateType || (showSlots && !selectedSlot))
			) {
				return message.error('Please fill in the details to move ahead!')
			}
			setCurrentStep(Number(val))
		} else if (val === '4') {
			if (
				!projectName ||
				!getFieldValue('location') ||
				!googleLocationObject ||
				!projectName.replace(/\s/g, '').length ||
				(!selectedDateType || (showSlots && !selectedSlot)) ||
				!descValue
			) {
				return message.error('Please fill in the details to move ahead!')
			}
			setCurrentStep(Number(val))
		} else {
			setCurrentStep(Number(val))
		}
	}
	const handleBackBtn = () => setCurrentStep(prevState => (prevState !== 1 ? prevState - 1 : prevState))

	const clearAddress = () => {
		setFieldsValue({
			location: null,
		})
	}

	const handleAddressChange = address => {
		setFieldsValue({
			location: address,
		})
	}

	const handleAddressSelect = (address, placeID) => {
		setFieldsValue({
			location: address,
		})
		geocodeByAddress(address)
			.then(async results => {
				return getLatLng(results[0])
			})
			.then(latLng => {
				// Do something with latLng : window.google.maps.LatLngLiteral
				setGoogleLocationObject(latLng)
			})
			.catch(error => {
				console.error('Error', error)
			})
	}

	const setLocationByMap = loc => {
		handleAddressSelect(loc.address)
		toggleMapBox()
	}

	const toggleMapBox = () => {
		setOpenModalToAddDetails(prev => !prev)
	}

	const handleOnDate = date => {
		setOnDateValue(date)
		setBeforeDateValue(null)
		setSelectedDateType(date ? 'ondate' : null)
	}
	const handleBeforeDate = date => {
		setOnDateValue(null)
		setBeforeDateValue(date)
		setSelectedDateType(date ? 'beforedate' : null)
	}
	const handleFlexible = () => {
		setOnDateValue(null)
		setBeforeDateValue(null)
		setSelectedDateType('flexible')
	}

	const handleShowSlots = e => {
		if (e.target.checked) {
			setShowSlots(true)
		} else {
			setShowSlots(false)
		}
	}

	const handleSlotChange = e => {
		setSelectedSlot(e.target.value)
	}

	const handleDescChange = e => {
		setDescValue(e.target.value)
	}

	const handleProjectNameChange = e => {
		if (e.target.value && e.target.value.length > 150) {
			return message.warning('Only 150 charaters are allowed!')
		}
		const value = e.target.value
		const regex = /^[0-9a-zA-Z ]+$/
		if (value.match(regex) || value === '') {
			setProjectName(e.target.value)
		}
		else {
			return message.warning('Please use only Alpha-Numeric characters')
		}
	}

	const handleProjectCostChange = e => {
		const val = e.target.value
		if (val > 99999) {
			return message.warning('Budget cannot be more than 99999!')
		}
		if (val >= 2000) {
			setBudgetLimit(true)
		} else {
			setBudgetLimit(false)
		}
		setProjectCost(val)
	}

	const disabledDate = current => {
		const today = moment()
		const customDate = moment(today, 'DD-MM-YYYY').subtract(1, 'days')
		return current < moment(customDate, 'YYYY-MM-DD')
	}

	const updatedDeadline = val => {
		const TIME_MAP = {
			MORNING: { h: 10, m: 0 },
			MIDDAY: { h: 14, m: 0 },
			AFTERNOON: { h: 18, m: 0 },
			EVENING: { h: 23, m: 59 },
		}
		if (showSlots) {
			val.set(TIME_MAP[selectedSlot])
		} else {
			val.set({ h: 23, m: 59 })
		}
		return String(moment(val).unix())
	}

	const togglePnModal = () => setPnModal(prevState => !prevState)

	const handlePnModalCancel = () => setCancelPnModalStage1(prevState => !prevState)

	const handlePnVerificationCancel = () => {
		togglePnModal()
		setCancelPnModalStage1(prevState => !prevState)
		setCancelPnModalStage2(prevState => !prevState)
	}

	const handlePublishCancel = () => {
		setLoading(false)
		setCancelPnModalStage2(prevState => !prevState)
	}

	const checkAndCreateProject = body => {
		if (!editMode) {
			createProject(body)
				.then(() => {
					setLoading(false)
					goTo('home')
				})
				.catch(err => {
					setLoading(false)
					console.log('create project err ', err)
				})
		} else {
			body.project_id = projectId
			updateProject(body)
				.then(() => {
					setLoading(false)
					goTo('home')
				})
				.catch(err => {
					setLoading(false)
					console.log('create project err ', err)
				})
		}
	}

	const submitForm = () => {
		if (!isNegotiable && !projectCost) return message.error('Please fill in the details to move ahead!')
		if (!isNegotiable && projectCost < MIN_PROJECT_BUDGET) return message.error(`Your budget should be greater than ${configData.currency} 100`)

		setLoading(true)
		let body = {
			additional_info: descValue,
			deadline: selectedDateType === 'ondate' ? updatedDeadline(onDateValue) : selectedDateType === 'beforedate' ? updatedDeadline(beforeDateValue) : null,
			latitude: googleLocationObject ? googleLocationObject.lat : editMode ? googleLocationObject.lat : null,
			longitude: googleLocationObject ? googleLocationObject.lng : editMode ? googleLocationObject.lng : null,
			name: projectName.trim(),
			project_type: 'PHOTOS_AND_VIDEOS',
			totalFee: isNegotiable ? 0 : Number(projectCost),
			address: getFieldValue('location'),
			deadline_slot: showSlots ? selectedSlot : null,
			deadline_type: selectedDateType === 'ondate' ? 'ON' : selectedDateType === 'beforedate' ? 'BEFORE' : 'FLEXIBLE',
			is_negotiable: isNegotiable,
		}
		if (fileData) {
			body.descriptionFile = fileData.fileId
		}

		// For sending data to Mixpanel for Project Created and Project Scope Modified events
		if (!editMode) {
			body.mixpanel_data = {
				'Project Location': getFieldValue('location'),
				'Deadline Option Selection': selectedDateType === 'ondate' ? 'On Date' : selectedDateType === 'beforedate' ? 'Before Date' : 'Flexible',
				'Time Constraint Option': showSlots ? `${selectedSlot.charAt(0).toUpperCase()}${selectedSlot.slice(1).toLowerCase()}` : 'None',
				'Description Length': descValue.length,
				'Is Attachment Added': !!fileData,
				'Budget Value': Number(projectCost),
				'Is Budget Negotiable': isNegotiable,
				'Deadline Value':
					selectedDateType === 'ondate'
						? moment(onDateValue, 'DD/MM/YYYY')
							.utc()
							.local()
							.format('MM/DD/YYYY')
						: selectedDateType === 'beforedate'
							? moment(beforeDateValue, 'DD/MM/YYYY')
								.utc()
								.local()
								.format('MM/DD/YYYY')
							: null,
			}
			body.mixpanel_type = 'project_created'
		} else {
			body.mixpanel_data = {
				'Is Project Title Modified': projectName.trim() !== consumerProjectData.name,
				'Is The Location Modified': getFieldValue('location') !== consumerProjectData.address,
				'Is The Deadline Modified':
					body.deadline_type !== consumerProjectData.deadline_type ||
					moment.unix(body.deadline).format('DD-MM-YYYY') !== moment(consumerProjectData.deadline).format('DD-MM-YYYY'),
				'Is The Time Constraint Modified': body.deadline_slot !== consumerProjectData.deadline_slot,
				'Is The Description Modified': body.additional_info !== consumerProjectData.additional_info,
				'Is The Budget Modified': body.totalFee !== consumerProjectData.total_fee,
				'Is The Attachment Added/Modified': body.descriptionFile !== consumerProjectData.descriptionFile?.fileId,
				'Project Creation Date': new Date(`${consumerProjectData.created_at}`).getTime(),
				'Is Budget Negotiable': isNegotiable,
				'Deadline Value':
					body.deadline_type !== 'FLEXIBLE'
						? moment
							.unix(body.deadline)
							.utc()
							.local()
							.format('MM/DD/YYYY')
						: null,
			}
			body.mixpanel_type = 'project_scope_modified'
		}

		if (loggedIn) {
			const mobileNumber = profileData?.mobileNumber
			setProjectBody(body)
			if (!mobileNumber) {
				togglePnModal()
			} else {
				checkAndCreateProject(body)
			}
		} else {
			setValueToStorage('PROFILE_TYPE', 'CONSUMER')
			setValueToStorage('PROJECT_DATA', JSON.stringify(body))
			goTo('signup-select')
		}
	}

	const storeFileData = val => {
		setFileData(val)
	}

	const handleNegotiableChange = e => {
		setIsNegotiable(e.target.checked)
	}

	const configs = {
		step: currentStep,
		handleNextBtn: handleNextBtn,
		googleLocationObject: googleLocationObject,
		openModalToAddDetails: openModalToAddDetails,
		selectedDateType: selectedDateType,
		onDateValue: onDateValue,
		beforeDateValue: beforeDateValue,
		showSlots: showSlots,
		clearAddress: clearAddress,
		handleAddressChange: handleAddressChange,
		setLocationByMap: setLocationByMap,
		handleOnDate: handleOnDate,
		handleBeforeDate: handleBeforeDate,
		handleFlexible: handleFlexible,
		handleShowSlots: handleShowSlots,
		toggleMapBox: toggleMapBox,
		handleAddressSelect: handleAddressSelect,
		form: form,
		handleBackBtn: handleBackBtn,
		handleSlotChange: handleSlotChange,
		selectedSlot: selectedSlot,
		handleDescChange: handleDescChange,
		descValue: descValue,
		submitForm: submitForm,
		disabledDate: disabledDate,
		handleProjectNameChange: handleProjectNameChange,
		handleProjectCostChange: handleProjectCostChange,
		projectCost: projectCost,
		projectName: projectName,
		editMode: editMode,
		holdOn: holdOn,
		fileData: fileData,
		storeFileData: storeFileData,
		handleNegotiableChange: handleNegotiableChange,
		isNegotiable: isNegotiable,
		budgetLimit: budgetLimit,
		address: getFieldValue('location'),
	}

	return (
		<>
			<Drawer
				closeIcon={<CloseOutlined style={{ position: 'relative', left: '80vw' }} />}
				open={true}
				placement='left'
				title={<MatImage src={IMAGES_SRC.SIDEBAR_LOGO} height='53' width='170' style={{ marginLeft: '3vw' }} />}
				width='100VW'
				getContainer={false}
				forceRender
				onClose={handleClose}
			>
				<Spin spinning={loading}>
					<Form className='create-new-project-form' layout='vertical'>
						<Tabs
							activeKey={String(currentStep)}
							onChange={handleTabChange}
							style={{ padding: '50px' }}
							tabPosition='left'
							tabBarStyle={{ height: '80vh', color: '#84818A' }}
						>
							{TAB_MAP.map(item => (
								<Tabs.TabPane tab={item.tab} key={item.key}>
									<NewProjectPerStep {...props} {...configs} />
								</Tabs.TabPane>
							))}
						</Tabs>
					</Form>
					<Form className='create-new-project-form-mobile' layout='vertical'>
						<NewProjectPerStep {...props} {...configs} />
					</Form>
				</Spin>
			</Drawer>

			<ProjectMobileChecker
				pnModal={pnModal}
				togglePnModal={togglePnModal}
				checkAndCreateProject={checkAndCreateProject}
				projectBody={projectBody}
				handlePnModalCancel={handlePnModalCancel}
				cancelPnModalStage1={cancelPnModalStage1}
				cancelPnModalStage2={cancelPnModalStage2}
				handlePnVerificationCancel={handlePnVerificationCancel}
				handlePublishCancel={handlePublishCancel}
			/>
		</>
	)
}

const mapStateToProps = state => {
	return {
		consumerProjectData: state.ProjectsReducer.consumerProjectData,
		profileData: state.UserSessionReducer.profileData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		createProject: bindActionCreators(createProject, dispatch),
		updateProject: bindActionCreators(updateProject, dispatch),
		getProjectForConsumer: bindActionCreators(getProjectForConsumer, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CreateNewProject)
