import React from 'react'
import styles from './Step.module.scss'

import { CheckCircleTwoTone, WarningTwoTone, ClockCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

import MatImage from '../../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../../AeroComponents/Image/image.constants'


function Step(props) {

    // extract props
    const {
        title, titleColor, subtitle,
        contentTitle, content, icon, iconColor,
        lineColor // from parent  
    } = props

    function stepContent() {
        if (contentTitle) {
            return (
                <div className={styles['step-content']}>
                    <span className={styles['step-content-title']} > {contentTitle} : </span>
                    {content}

                </div>
            )
        }
        else {

            return (
                <div className={styles['step-content']}>

                    {content}

                </div>

            )
        }
    }

    function stepIcon() {


        switch (icon) {
            case "clock": {
                return <ClockCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={iconColor} />

            }

            case "warning": {
                return <WarningTwoTone style={{ fontSize: '20px' }} twoToneColor={iconColor} />

            }

            case "check": {
                return <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={iconColor} />

            }

            case "close": {
                return <CloseCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={iconColor} />

            }

            case "dot": {
                return <MatImage style={{ fontSize: '20px' }} src={IMAGES_SRC.DOT_ICON} />

            }

            default: {
                return <CloseCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={iconColor} />

            }
        }

    }


    return (
        <div className={styles['step']}>
            <div>
                {stepIcon()}
            </div>
            <div className={styles['step-line']}>
                <hr style={{ border: '1px solid' + lineColor }}></hr>
            </div>
            <div className={styles['step-card-content']}>
                <div style={{ color: titleColor }} className={styles['step-title']}>  {title}  </div>
                <div className={styles['step-sub-title']}>  {subtitle} </div>

                {stepContent()}

            </div>
        </div>

    )

}
Step.defaultProps = {
    titleColor: "grey",
    lineColor: "grey",
    icon: "dot"
}
export default Step;