/*eslint-disable*/
import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Input, Upload, Select, DatePicker, message, Descriptions } from 'antd'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { addCasaLicense, updateCasaLicense } from '../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import AeroModal from '../../../AeroComponents/Modal'
import dayjs from 'dayjs'
import VerificationTag from '../Components/verification-tag'


const MIXPANEL_LICENSE_TYPES = { REOC: 'REOC', REPL: 'REPL', SUB_2_KG: 'Sub 2 KG' }

class CasaLicenseForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedType: '',
			fileList: [],
			noMoreUploads: false,
			selectedLicense: null,
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const { fileList } = this.state
				let body = new FormData()

				let fileData
				fileList.forEach(file => {
					fileData = file
				})
				body.append('licenceFile', fileData)
				body.append('licenseNumber', values.licenseNumber)
				if (this.state.selectedLicense && this.state.selectedLicense.subtype.length && values.subtype) {
					body.append('subTypeRepl', values.subtype)
				}

				const expiryDate = moment(values.expiryDate).unix()
				body.append('expiryDate', expiryDate)

				body.append('typeAu', values.licenseType)

				if (this.props.retryMode) {
					body.append('licenseId', this.props.selectedId)

					this.props.updateCasaLicense(body)
				} else {
					this.props.addCasaLicense(body)
				}
			}
		})
	}

	showModal = data => {
		this.props.toggleDeleteCasaModal(data)
	}

	handleOk = () => {
		this.props.deleteLicense(this.props.selectedLicense.id, MIXPANEL_LICENSE_TYPES[this.props.selectedLicense.licenseType])
	}

	toggleFields = val => {
		const obj = this.props.casaLicenseTypesData.filter(item => item.value === val)
		if (obj[0].subtype.length) {
			obj[0].subtype = obj[0].subtype.map(item => {
				return { value: item.name, label: item.label, present: item.present, subtype: item.subtype, type: item.type }
			})
		}
		this.setState({ selectedType: obj[0].value, selectedLicense: obj[0] })
	}

	retry = id => {
		this.props.retry(id)
	}

	disabledDate = current => {
		// Can not select days before today and today
		return current && current < dayjs().endOf('day')
	}

	render() {
		const { selectedType, fileList, noMoreUploads } = this.state
		const { viewMode, casaLicenseTypesData, data, deleteCasaModal, selectedLicense } = this.props
		const { getFieldDecorator } = this.props.form
		const props = {
			onRemove: file => {
				this.setState(state => {
					const index = state.fileList.indexOf(file)
					const newFileList = state.fileList.slice()
					newFileList.splice(index, 1)
					return {
						fileList: newFileList,
						noMoreUploads: false,
					}
				})
			},
			beforeUpload: file => {
				const isJpgOrPngOrPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
				if (!isJpgOrPngOrPdf) {
					message.error('You can only upload JPG/PNG/PDF file!')
				}
				const isLt2M = file.size / 1024 / 1024 < 10
				if (!isLt2M) {
					message.error('File must be smaller than 10 MB!')
				}

				if (isLt2M && isJpgOrPngOrPdf) {
					this.setState(state => ({
						fileList: [...state.fileList, file],
						noMoreUploads: true,
					}))
					return false
				}
			},
			fileList,
		}

		return (
			<React.Fragment>
				<Form layout='vertical' onSubmit={this.handleSubmit} encType='multipart/form-data'>
					{viewMode ? (
						data.map(data => (
							<div style={{ border: '1px solid #ECEAEE', borderRadius: '12px', padding: '20px', marginBottom: '20px' }}>
								<Descriptions>
									<Descriptions.Item>
										<strong>{data.licenseTypeLabel}</strong>
									</Descriptions.Item>

									<Descriptions.Item>
										<span
											onClick={() => {
												this.showModal(data)
											}}
											style={{ color: 'red', float: 'right', cursor: 'pointer', paddingRight: '120px' }}
										>
											Delete
										</span>
									</Descriptions.Item>

									<Descriptions.Item>
										<VerificationTag verificationState={data.verificationState} />

									</Descriptions.Item>
								</Descriptions>
								<Descriptions>
									<Descriptions.Item label='License Number'>{data.licenseNumber}</Descriptions.Item>
									<Descriptions.Item label='License'>
										<Button type='link' href={data.imageURL} target='_blank' style={{ position: 'relative', right: '16px', bottom: '4px' }}>
											Click to view
										</Button>
									</Descriptions.Item>

									<Descriptions.Item>
										{data.licenseType === 'REOC' ? (
											<>
												Expiry Date: &nbsp;
												<strong>
													{data.expiryDate ? (
														<>
															{moment
																.utc(data.expiryDate)
																.local()
																.format('DD-MM-YYYY')}
														</>
													) : (
														'--'
													)}
												</strong>
											</>
										) : data.licenseType === 'REPL' && data.licenseSubType ? (
											<>
												Subtype: &nbsp;
												{data.licenseSubTypeLabel}
											</>
										) : null}
									</Descriptions.Item>
								</Descriptions>
								{data.verificationState === 'VERIFICATION_FAILED' ? (
									<Descriptions>
										<Descriptions.Item label='Reason of Failure'>{data.failedVerificationReason}</Descriptions.Item>
										<Descriptions.Item>
											<Button
												onClick={() => {
													this.retry(data.id)
												}}
												style={{ width: '100px', paddingLeft: '20px' }}
												type='primary'
											>
												Retry
											</Button>
										</Descriptions.Item>
									</Descriptions>
								) : null}
							</div>
						))
					) : (
						<>
							<Form.Item label='Select License Type'>
								{getFieldDecorator('licenseType', {
									rules: [
										{
											required: true,
											message: 'Please select a license type!',
										},
									],
								})(
									<Select size='large' onChange={this.toggleFields} placeholder='Click to Select License Type'>
										{casaLicenseTypesData.map(data => (
											<Select.Option disabled={data.present} key={data.name} value={data.value}>
												{data.label}
											</Select.Option>
										))}
									</Select>,
								)}
							</Form.Item>
							{this.state.selectedLicense && this.state.selectedLicense.subtype.length ? (
								<Form.Item label='Type'>
									{getFieldDecorator('subtype', {
										rules: [
											{
												required: this.state.selectedLicense && this.state.selectedLicense.subtype.length,
												message: 'Please select a license type!',
											},
										],
									})(
										<Select size='large' placeholder='Click to Select License Type'>
											{this.state.selectedLicense.subtype.map(data => (
												<Select.Option disabled={data.present} key={data.name} value={data.value}>
													{data.label}
												</Select.Option>
											))}
										</Select>,
									)}
								</Form.Item>
							) : null}

							<div>
								<Form.Item label='Enter License Number'>
									{getFieldDecorator('licenseNumber', {
										rules: [
											{
												required: true,
												message: 'Please input license number!',
											},
										],
									})(<Input size='large' style={{ borderRadius: '8px' }} />)}
								</Form.Item>
								{selectedType === 'REOC' ? (
									<Form.Item label='License Expiry Date'>
										{getFieldDecorator('expiryDate', {
											rules: [
												{
													required: selectedType === 'REOC',
													message: 'Please input expiry date!',
												},
											],
										})(<DatePicker disabledDate={this.disabledDate} style={{ width: '100%' }} size='large' format='DD/MM/YYYY' />)}
									</Form.Item>
								) : null}

								<Form.Item label='Upload License (jpeg, png or pdf)'>
									<Upload style={{ width: '100%' }} {...props}>
										<Button style={{ height: '38px', width: '100%' }} disabled={noMoreUploads}>
											Select File
										</Button>
									</Upload>
								</Form.Item>
							</div>

							<div style={{ paddingTop: '20px' }}>
								<Button style={{ height: '38px' }} disabled={fileList.length === 0} block type='primary' htmlType='submit'>
									Add
								</Button>
							</div>
						</>
					)}
				</Form>
				{selectedLicense ? (
					<AeroModal visible={deleteCasaModal} onOk={this.handleOk} onCancel={() => this.props.toggleDeleteCasaModal()} footer={false}>
						<div style={{ textAlign: 'center' }}>
							<MatImage src={IMAGES_SRC.BIN_ICON} />
							<div style={{ padding: '10px' }}>
								<h2>Delete License?</h2>
							</div>
							<div>
								License{' '}
								<strong>
									{selectedLicense.licenseTypeLabel} {selectedLicense.licenseNumber ? '- ' + selectedLicense.licenseNumber : ''}
								</strong>{' '}
								will be deleted
							</div>
							<div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
								<Button style={{ height: '48px', width: '45%' }} key='submit' type='primary' onClick={this.handleOk}>
									Delete
								</Button>
								<Button style={{ height: '48px', width: '45%' }} key='back' onClick={() => this.props.toggleDeleteCasaModal()}>
									Cancel
								</Button>
							</div>
						</div>
					</AeroModal>
				) : null}
			</React.Fragment>
		)
	}
}

const CasaLicense = Form.create({ name: 'CASA_LICENSE_FORM' })(CasaLicenseForm)

const mapStateToProps = state => {
	return {
		addCasaLicenseSuccess: state.PaymentsReducer.addCasaLicenseSuccess,
		updateCasaLicenseSuccess: state.PaymentsReducer.updateCasaLicenseSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		addCasaLicense: bindActionCreators(addCasaLicense, dispatch),
		updateCasaLicense: bindActionCreators(updateCasaLicense, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CasaLicense)
