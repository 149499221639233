
import React from 'react'
import styles from './VideoPlayer.module.scss'


function VideoPlayer(props) {

  // extract props
  const {
    src, setOpenPlayer // from parent  

  } = props


  return (
    <div className={styles['video-player']} >
      <div className={styles['close-button']} title="close" onClick={() => { setOpenPlayer(false) }} >x  </div>
      <video id="clip" controls preload="auto" width="100%" height="100%" src={src} />


    </div>
  )
}

export default VideoPlayer;