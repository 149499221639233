import React from 'react'
import { TEXTS } from '../../../Modules/OnBoarding/on-boarding.constants'
import { Input } from 'antd'

export const SIGN_UP_FORM_MODAL = {
	FN: 'firstName',
	LN: 'lastName',
	EMAIL: 'email',
	PN: 'mobileNumber',
	PASS: 'password',
	CONFIRM_PASS: 'confirmPassword',
	ABOUT_YOU: 'aboutYou',
}

export const SIGN_UP_FIELDS = form => {
	function compareToFirstPassword(rule, value, callback) {
		if (value && value !== form.getFieldValue('password')) {
			callback('Two passwords that you enter is inconsistent!')
		} else {
			callback()
		}
	}

	function validateToNextPassword(rule, value, callback) {
		if (value) {
			form.validateFields(['confirmPassword'], { force: true })
		}

		const regForPass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/
		if (value && value.match(regForPass)) {
			callback()
		} else {
			callback('Password should be of minimum 6 characters, it must have at least one special character and at least one number!')
		}
	}

	return {
		CONSUMER: [
			{
				fieldName: 'FN',
				fieldLabel: TEXTS.FN,
				fieldKey: SIGN_UP_FORM_MODAL.FN,
				fileObject: {
					rules: [
						{
							required: true,
							message: 'Please input your first name!',
							whitespace: true,
						},
					],
				},
				fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
			},
			{
				fieldName: 'LN',
				fieldLabel: TEXTS.LN,
				fieldKey: SIGN_UP_FORM_MODAL.LN,
				fileObject: {
					rules: [
						{
							required: true,
							message: 'Please input your last name!',
							whitespace: true,
						},
					],
				},
				fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
			},
			{
				fieldName: 'EMAIL',
				fieldLabel: TEXTS.EMAIL,
				fieldKey: SIGN_UP_FORM_MODAL.EMAIL,
				fileObject: {
					rules: [
						{
							type: 'email',
							message: 'The input is not valid E-mail!',
							whitespace: true,
						},
						{
							required: true,
							message: 'Please input your E-mail!',
						},
					],
				},
				fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
			},
			{
				fieldName: 'PASS',
				fieldLabel: TEXTS.PASS,
				fieldKey: SIGN_UP_FORM_MODAL.PASS,
				fileObject: {
					rules: [
						{
							required: true,
							message: 'Please input your password!',
						},
						{
							validator: validateToNextPassword,
						},
					],
				},
				fieldToRender: <Input.Password style={{ height: '48px', borderRadius: '8px' }} />,
			},
			{
				fieldName: 'CONFIRM_PASS',
				fieldLabel: TEXTS.CONFIRM_PASS,
				fieldKey: SIGN_UP_FORM_MODAL.CONFIRM_PASS,
				fileObject: {
					rules: [
						{
							required: true,
							message: 'Please confirm your password!',
						},
						{
							validator: compareToFirstPassword,
						},
					],
				},
				fieldToRender: <Input.Password style={{ height: '48px', borderRadius: '8px' }} />,
			},
		],
		PILOT: [
			{
				fieldName: 'FN',
				fieldLabel: TEXTS.FN,
				fieldKey: SIGN_UP_FORM_MODAL.FN,
				fileObject: {
					rules: [
						{
							required: true,
							message: 'Please input your first name!',
							whitespace: true,
						},
					],
				},
				fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
			},
			{
				fieldName: 'LN',
				fieldLabel: TEXTS.LN,
				fieldKey: SIGN_UP_FORM_MODAL.LN,
				fileObject: {
					rules: [
						{
							required: true,
							message: 'Please input your last name!',
							whitespace: true,
						},
					],
				},
				fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
			},
			{
				fieldName: 'EMAIL',
				fieldLabel: TEXTS.EMAIL,
				fieldKey: SIGN_UP_FORM_MODAL.EMAIL,
				fileObject: {
					rules: [
						{
							type: 'email',
							message: 'The input is not valid E-mail!',
							whitespace: true,
						},
						{
							required: true,
							message: 'Please input your E-mail!',
						},
					],
				},
				fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
			},
			{
				fieldName: 'PASS',
				fieldLabel: TEXTS.PASS,
				fieldKey: SIGN_UP_FORM_MODAL.PASS,
				fileObject: {
					rules: [
						{
							required: true,
							message: 'Please input your password!',
						},
						{
							validator: validateToNextPassword,
						},
					],
				},
				fieldToRender: <Input.Password style={{ height: '48px', borderRadius: '8px' }} />,
			},
			{
				fieldName: 'CONFIRM_PASS',
				fieldLabel: TEXTS.CONFIRM_PASS,
				fieldKey: SIGN_UP_FORM_MODAL.CONFIRM_PASS,
				fileObject: {
					rules: [
						{
							required: true,
							message: 'Please confirm your password!',
						},
						{
							validator: compareToFirstPassword,
						},
					],
				},
				fieldToRender: <Input.Password style={{ height: '48px', borderRadius: '8px' }} />,
			},
		],
	}
}
