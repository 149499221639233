import React, { Component } from 'react'
import '../projects.sass'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Row, Input, Upload, Spin, message } from 'antd'
import AeroModal from '../../../AeroComponents/Modal'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { disapprovePreviewData, disapproveFinalData } from '../../../Stores/Projects/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const { TextArea } = Input

class ProjectStatusStepDescriptionForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			disapproveVisible: false,
			fileList: [],
			modalCase: '',
			loading: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.disapprovePreviewDataResponse !== nextProps.disapprovePreviewDataResponse) {
			if (nextProps.disapprovePreviewDataResponse) {
				this.setState({ loading: false })
				this.closeDisapprove()
				this.props.disapproveData(nextProps.disapprovePreviewDataResponse)
			}
		}
		if (this.props.disapproveFinalDataResponse !== nextProps.disapproveFinalDataResponse) {
			if (nextProps.disapproveFinalDataResponse) {
				this.setState({ loading: false })
				this.closeDisapprove()
				this.props.disapproveData(nextProps.disapproveFinalDataResponse)
			}
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({
					loading: true,
				})
				const { fileList } = this.state
				let body = new FormData()

				let fileData
				fileList.forEach(file => {
					fileData = file
				})
				body.append('proofFile', fileData)
				body.append('disapprovalReason', values.disapprovalReason)
				body.append('projectId', this.props.data.projectId)
				let mixpanelData = {
					mixpanel_type: 'unsatisfactory_data',
					projectId: this.props.data.projectId,
					pilotId: this.props.data.pilot_id,
				}
				if (this.state.modalCase === 'approve') {
					this.props.disapprovePreviewData(body, mixpanelData)
				} else {
					this.props.disapproveFinalData(body, mixpanelData)
				}
			}
		})
	}

	closeModal = () => {
		this.setState({ visible: false })
	}

	openModal = modalCase => {
		this.setState({ visible: true, modalCase: modalCase })
	}

	closeDisapprove = () => {
		this.setState({ disapproveVisible: false })
	}

	openDisapprove = modalCase => {
		this.setState({ disapproveVisible: true, modalCase })
	}

	currentStateDescription = statusIndex => {
		const { profileType } = this.props
		if (profileType === 'CONSUMER') {
			if (statusIndex === 0) {
				return 'Awaiting bids from Pilots'
			} else if (statusIndex === 1) {
				return 'Waiting for the Pilot to commence the drone flight'
			} else if (statusIndex === 2) {
				return 'Waiting for the pilot to complete the flight and upload the drone data'
			} else if (statusIndex === 3) {
				return (
					<div>
						You've 7 days to let us know about the quality of the submitted data..{' '}
						<strong>
							Are you satisfied with the quality of Project Data?{' '}
							<div className='des-btn'>
								<Button
									style={{ width: '100%' }}
									onClick={() => {
										this.openDisapprove('satisfy')
									}}
								>
									Unsatisfactory
								</Button>
								&nbsp;
								<Button
									style={{ width: '100%' }}
									onClick={() => {
										this.openModal('satisfy')
									}}
									type='primary'
								>
									Satisfactory
								</Button>
							</div>
						</strong>
					</div>
				)
			} else if (statusIndex === 4) {
				return 'Try having a chat with the pilot to explain them your requirements and expectation'
			} else if (statusIndex === 5) {
				return 'Payment to the pilot has been processed. '
			}
		} else {
			if (statusIndex === 0) {
				return (
					<div>
						Waiting for you to commence the drone flight
						<div className='des-btn'>
							<Button
								onClick={() => {
									this.props.projectStateAction('markInFlight')
								}}
								type='primary'
							>
								Mark In-Flight
							</Button>
						</div>
					</div>
				)
			} else if (statusIndex === 1) {
				return (
					<div>
						Waiting for you to complete the flight and upload the drone data.{' '}
						<div className='des-btn'>
							<Button
								onClick={() => {
									this.props.projectStateAction('markReadyToProcess')
								}}
								type='primary'
							>
								Mark as done
							</Button>
						</div>
					</div>
				)
			} else if (statusIndex === 2) {
				return "Your data has been submitted and is being reviewed by the client. You'll be updated within 14 working days."
			} else if (statusIndex === 3) {
				return (
					<div>
						Try having a chat with the client to understand their requirements and expectations.
						<div className='des-btn'>
							<Button
								onClick={() => {
									this.props.projectStateAction('conflictResolutionFinal')
								}}
								type='primary'
							>
								Mark as Done
							</Button>
						</div>
					</div>
				)
			} else if (statusIndex === 4) {
				return 'Your payment has been processed and should be credited within 2 business days'
			}
		}
	}

	render() {
		const { statusTime, statusName, currentState, statusIndex } = this.props
		const { visible, disapproveVisible, fileList, modalCase, loading } = this.state
		const { getFieldDecorator } = this.props.form
		const props = {
			onRemove: file => {
				this.setState(state => {
					const index = state.fileList.indexOf(file)
					const newFileList = state.fileList.slice()
					newFileList.splice(index, 1)
					return {
						fileList: newFileList,
					}
				})
			},
			beforeUpload: file => {
				const isJpgOrPngOrPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
				if (!isJpgOrPngOrPdf) {
					message.error('You can only upload JPG/PNG/PDF file!')
					return false
				}
				const isLt2M = file.size / 1024 / 1024 < 10
				if (!isLt2M) {
					message.error('File must be smaller than 10 MB!')
					return false
				}
				this.setState(state => ({
					fileList: [file],
				}))
				return false
			},
			fileList,
		}
		return (
			<Row>
				<div className='step-description'>{currentState === statusName ? this.currentStateDescription(statusIndex) : statusTime[statusIndex].des}</div>
				<AeroModal footer={false} onCancel={this.closeModal} visible={visible}>
					<div style={{ textAlign: 'center' }}>
						<MatImage src={IMAGES_SRC.APPOVE_ICON} />
						<div>
							<h2>{modalCase === 'approve' ? "Approve Project's Preview Data?" : 'Mark Project Data Satisfactory?'}</h2>
							<div style={{ padding: '5px' }}>
								{modalCase === 'approve'
									? 'You will be required to complete the balance payment to access the complete set of data'
									: "Pilot's payment will be processed"}
							</div>
							<div style={{ padding: '10px' }}>
								<Button style={{ height: '50px', width: '45%' }} onClick={this.closeModal}>
									{modalCase === 'approve' ? 'Cancel' : 'No'}
								</Button>
								&nbsp;&nbsp;
								<Button
									style={{ height: '50px', width: '45%' }}
									onClick={() => {
										this.closeModal()
										this.props.projectStateAction(modalCase === 'approve' ? 'markComplete' : 'markResolved')
									}}
									type='primary'
								>
									{modalCase === 'approve' ? 'Approve & Pay' : 'Yes'}
								</Button>
							</div>
						</div>
					</div>
				</AeroModal>
				<AeroModal footer={false} onCancel={this.closeDisapprove} visible={disapproveVisible}>
					<div style={{ textAlign: 'center' }}>
						<MatImage src={IMAGES_SRC.DISAPPROVE_ICON} />
						<div>
							<h2>{modalCase === 'approve' ? 'Disapprove Project Data?' : 'Mark Project Data Unsatisfactory?'}</h2>
							<div style={{ padding: '5px' }}>
								We’re sorry to know that the data you’ve received is unsatisfactory. Please have a chat with the pilot
								to explain your expectation more clearly.  Meanwhile, our Operations team will be notified and will contact
								you soon to help resolve your issue.
							</div>
							<Form style={{ padding: '10px' }} layout='vertical' onSubmit={this.handleSubmit} encType='multipart/form-data'>
								<Spin spinning={loading}>
									<Form.Item label='Please describe your issue in detail'>
										{getFieldDecorator('disapprovalReason', { rules: [{ required: true, whitespace: true, message: 'Please mention the issue!' }] })(
											<TextArea rows={4} placeholder={'Start Typing...'} />,
										)}
									</Form.Item>
									<Form.Item label='Upload Attachment (jpeg, png or pdf)'>
										<Upload {...props}>
											<Button style={{ height: '50px' }}>Click to Upload</Button>
										</Upload>
									</Form.Item>
									<div style={{ padding: '10px' }}>
										<Button style={{ height: '50px', width: '45%' }} onClick={this.closeDisapprove}>
											Cancel
										</Button>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<Button style={{ height: '50px', width: '45%' }} type='primary' htmlType='submit'>
											Submit
										</Button>
									</div>
								</Spin>
							</Form>
						</div>
					</div>
				</AeroModal>
			</Row>
		)
	}
}
const ProjectStatusStepDescription = Form.create({ name: 'PROJECT_STATUS_STEP_DESCRIPTION_FORM' })(ProjectStatusStepDescriptionForm)

const mapStateToProps = state => {
	return {
		disapprovePreviewDataResponse: state.ProjectsReducer.disapprovePreviewDataResponse,
		disapproveFinalDataResponse: state.ProjectsReducer.disapproveFinalDataResponse,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		disapprovePreviewData: bindActionCreators(disapprovePreviewData, dispatch),
		disapproveFinalData: bindActionCreators(disapproveFinalData, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProjectStatusStepDescription)
