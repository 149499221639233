import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './overview.sass'
import { Card, Progress, Row } from 'antd'
import Chart from 'react-apexcharts'
import GoogleMapReact from 'google-map-react'
import MarkerClusterer from '@google/markerclusterer'
import { getProjectsOverview } from '../../Stores/Projects/action'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'

class Overview extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {},
			dronePositions: [],
			chartSeries: [
				{
					name: 'Projects',
					data: [],
				},
			],
			chartOptions: {
				chart: {
					type: 'area',
					height: 300,
					zoom: {
						enabled: false,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: 'straight',
				},
				xaxis: {
					categories: [],
				},
				yaxis: {
					opposite: false,
				},
				legend: {
					horizontalAlign: 'left',
				},
			},
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.projectsOverviewData !== nextProps.projectsOverviewData) {
			this.setState({
				data: nextProps.projectsOverviewData,
			})
			this.setDronePositionList(nextProps.projectsOverviewData.activeProjects)
			this.setGraphData(nextProps.projectsOverviewData.projectHistory)
		}
	}

	componentDidMount() {
		this.props.getProjectsOverview()
		const script = document.createElement('script')
		script.src = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js'
		script.async = true
		document.body.appendChild(script)
	}

	setDronePositionList = activeProjects => {
		let positions = []
		for (let index = 0; index < activeProjects.length; index++) {
			let pos_obj = {
				lat: parseFloat(activeProjects[index].latitude),
				lng: parseFloat(activeProjects[index].longitude),
				info: activeProjects[index].name,
			}
			positions.push(pos_obj)
		}
		this.setState({ dronePositions: positions })
	}

	setGraphData = projectHistory => {
		let yAxisData = []
		let xAxisData = []
		let chartSeries = this.state.chartSeries
		let chartOptions = this.state.chartOptions
		for (let index = 0; index < projectHistory.length; index++) {
			yAxisData.push(projectHistory[index].count)
			xAxisData.push(projectHistory[index].month)
		}
		chartSeries[0].data = yAxisData
		chartOptions.xaxis.categories = xAxisData

		this.setState({
			chartSeries: chartSeries,
			chartOptions: chartOptions,
		})
	}

	setGoogleMapRef = (map, maps) => {
		this.googleMapRef = map
		this.googleRef = maps
		let locations = this.state.dronePositions
		let infoWin = new this.googleRef.InfoWindow()
		let markers =
			locations &&
			locations.map(location => {
				let marker = new this.googleRef.Marker({ position: location, icon: IMAGES_SRC.DRONE_MARKER })
				this.googleRef.event.addListener(marker, 'click', function(evt) {
					infoWin.setContent(location.info)
					infoWin.open(map, marker)
				})
				return marker
			})
		new MarkerClusterer(map, markers, {
			imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
			gridSize: 10,
			minimumClusterSize: 2,
		})
	}

	render() {
		const { data, dronePositions, chartOptions, chartSeries } = this.state
		return (
			<div className='page-container'>
				<Row>
					<Card className='en-project-big-card'>
						PROJECT HISTORY
						<div style={{ marginTop: '20px' }}>
							{chartOptions.xaxis.categories.length !== 0 && chartSeries[0].data.length !== 0 ? (
								<Chart options={chartOptions} series={chartSeries} type='area' height={300} />
							) : (
								<div style={{ textAlign: 'center' }}>
									<MatImage src={IMAGES_SRC.ARCHIVE_ICON} />
									<div style={{ color: '#485465', fontSize: '18px' }}>No data available</div>
								</div>
							)}
						</div>
					</Card>
					<Card className='en-project-small-card'>
						<React.Fragment>
							PROJECTS
							<div style={{ paddingTop: '10px', paddingBottom: '20px' }}>
								<MatImage src={IMAGES_SRC.COMPLETED_DOT} />
								<span style={{ fontSize: '11px', marginLeft: '10px', marginRight: '15px' }}>Completed</span>
								<MatImage src={IMAGES_SRC.ONGOING_DOT} />
								<span style={{ fontSize: '11px', marginLeft: '10px' }}>Ongoing</span>
							</div>
							<div style={{ textAlign: 'center' }}>
								<Progress width={230} type='circle' percent={data.completedGraphChart} />
							</div>
						</React.Fragment>
					</Card>
				</Row>
				<Row style={{ paddingTop: '20px' }}>
					<Card className='en-project-big-card'>
						ACTIVE PROJECTS
						<div style={{ height: '270px', width: '100%', marginTop: '30px' }}>
							{dronePositions.length !== 0 ? (
								<GoogleMapReact
									bootstrapURLKeys={{ key: 'AIzaSyAxj3E73qJL4BQAPyKN9J8TYBWUsv32dvI' }}
									yesIWantToUseGoogleMapApiInternals
									onGoogleApiLoaded={({ map, maps }) => this.setGoogleMapRef(map, maps)}
									defaultCenter={{ lat: -25.2744, lng: 133.7751 }}
									defaultZoom={3.5}
									options={{ styles: [{ stylers: [{ saturation: -100 }] }], mapTypeControl: true }}
								></GoogleMapReact>
							) : (
								<div style={{ textAlign: 'center' }}>
									<MatImage src={IMAGES_SRC.ARCHIVE_ICON} />
									<div style={{ color: '#485465', fontSize: '18px' }}>No data available</div>
								</div>
							)}
						</div>
					</Card>
					<Card className='en-project-small-card'>
						<React.Fragment>
							NOTIFICATIONS
							<div style={{ textAlign: 'center', marginTop: '60px' }}>
								<MatImage src={IMAGES_SRC.BIG_BELL_ICON} />
								<div style={{ fontSize: '18px', color: '#485465' }}>This feature is coming soon</div>
							</div>
							{/* <div style={{ paddingTop: '15px', overflowY: 'auto' }}>
								<div style={{ paddingBottom: '10px' }}>
									<MatImage src={IMAGES_SRC.COMPLETED_DOT} /> &nbsp; <span>Your order Nokia has been created</span>
								</div>
								<div style={{ paddingBottom: '10px' }}>
									<MatImage src={IMAGES_SRC.COMPLETED_DOT} /> &nbsp; <span>Your order Nokia has been created</span>
								</div>
							</div> */}
						</React.Fragment>
					</Card>
				</Row>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		projectsOverviewData: state.ProjectsReducer.projectsOverviewData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getProjectsOverview: bindActionCreators(getProjectsOverview, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Overview)
