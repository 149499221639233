/*eslint-disable*/
import typeToReducer from 'type-to-reducer'
import * as ACTION from './actionTypes'
import { message } from 'antd'

const initialState = {
	updateAccountData: null,
	uploadStripeDocumentSuccess: false,
	updatePaymentSuccess: false,
	bankAccountsData: null,
	makeDefaultAccountData: null,
	deleteBankAccountData: null,
	receivedPaymentsList: null,
	paymentPageData: null,
	pilotEarningsData: null,
	sendInvoiceForProjectSuccess: null,
	sendTaxSummarySuccess: null,
	stripeCheckoutUrl: null,
	stripeUrlSuccess: null,
	creditCardData: null,
	submittedDetails: null,
}

const PaymentsReducer = typeToReducer(
	{
		[ACTION.UPDATE_ACCOUNT]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Account information added successfully!')
					return Object.assign({}, state, {
						updateAccountData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.UPLOAD_STRIPE_DOCUMENT]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						uploadStripeDocumentSuccess: true,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.ADD_PAYMENT_DETAILS]: {
			PENDING: state => {
				return Object.assign({}, state, {
					updatePaymentSuccess: false,
				})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Card Details Added Successfully')
					return Object.assign({}, state, {
						updatePaymentSuccess: true,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Please complete your profile before adding payment details.')
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ALL_BANK_ACCOUNTS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				return Object.assign({}, state, {
					bankAccountsData: action.payload && action.payload.data ? action.payload.data : null,
				})
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.MAKE_DEFAULT_ACCOUNT]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Account changed to default!')
					return Object.assign({}, state, {
						makeDefaultAccountData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.DELETE_BANK_ACCOUNT]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Bank account deleted successfully')
					return Object.assign({}, state, {
						deleteBankAccountData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_RECEIVED_PAYMENTS_LIST]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				return Object.assign({}, state, {
					receivedPaymentsList: action.payload && action.payload.data ? action.payload.data : null,
				})
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_PAYMENT_PAGE_DETAILS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				return Object.assign({}, state, {
					paymentPageData: action.payload && action.payload.data ? action.payload.data : null,
				})
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_PILOT_EARNINGS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						pilotEarningsData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.SEND_INVOICE_FOR_PROJECT]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						sendInvoiceForProjectSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.SEND_TAX_SUMMARY]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						sendTaxSummarySuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_STRIPE_CHECKOUT_URL]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						stripeCheckoutUrl: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.FETCH_STRIPE_URL]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						stripeUrlSuccess: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_PILOT_CREDIT_CARDS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						creditCardData: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.CHECK_FOR_SUBMITTED_DETAILS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						submittedDetails: action.payload.data,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
	},
	initialState,
)

export default PaymentsReducer
