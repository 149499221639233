import React, { useState, useEffect } from 'react'
import styles from './ContributionThumbnail.module.scss'
import { IMAGES_SRC } from '../../../../../AeroComponents/Image/image.constants'

import { EyeOutlined, DeleteOutlined, CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, DownloadOutlined } from '@ant-design/icons'
import { Progress, Button } from 'antd'

function ContributionThumbnail(props) {
	// extract props
	const {
		thumbnailUrl,
		showDelete,
		status,
		showProgress,
		progress,
		selectContribution,
		setOpenPlayer,
		setShowDeleteModal,
		isSelected,
		isUploading,
		isDownloadable,
		name,
		url, // from parent
	} = props

	const [uploadProgress, setUploadProgress] = useState(progress)

	// effects
	useEffect(() => {
		if (progress) {
			setUploadProgress(progress)
		}
	}, [progress])

	function getImage() {
		if (thumbnailUrl === 'Set a default thumbnail url')
			return (
				<div className={styles['thumbnail-default-holder']}>
					{' '}
					<img alt='thumbnail' className={styles['thumbnail-default']} src={IMAGES_SRC.VIDEO_ICON}></img>{' '}
				</div>
			)
		else return <img className={styles['thumbnail-img']} alt='thumbnail' src={thumbnailUrl}></img>
	}

	function getOverlayIcon() {
		switch (status) {
			case 'PENDING_FILE_SUBMISSION':
			case 'UNDER_AEROLOGIX_REVIEW':
			case 'UNDER_GETTY_REVIEW':
				return ''

			case 'REJECTED_AEROLOGIX':
			case 'REJECTED_GETTY':
				return <CloseCircleFilled style={{ color: '#FF4D4F', fontSize: '20px' }} className={styles['thumbnail-overlay']}></CloseCircleFilled>

			case 'REVISE_AND_RETRY_GETTY':
				return <ExclamationCircleFilled style={{ color: '#FA541C', fontSize: '20px' }} className={styles['thumbnail-overlay']}></ExclamationCircleFilled>

			case 'PUBLISHED':
				return <CheckCircleFilled style={{ color: '#52c41a', fontSize: '20px' }} className={styles['thumbnail-overlay']}></CheckCircleFilled>

			default:
				return ''
		}
	}

	function getDownloadOrViewIcon() {
		if (isDownloadable) {
			return (
				<span title='Download video'>
					<Button type='link' href={url}>
						{' '}
						<DownloadOutlined className={styles['thumbnail-icon']} twoToneColor='white'></DownloadOutlined>{' '}
					</Button>
				</span>
			)
		} else {
			return (
				<span title='Open video' onClick={event => openVideo(event)}>
					{' '}
					<EyeOutlined className={styles['thumbnail-icon']} twoToneColor='white'></EyeOutlined>{' '}
				</span>
			)
		}
	}

	function getHoverIcons() {
		if (showDelete) {
			return (
				<div className={styles['thumbnail-holder']}>
					{' '}
					{getDownloadOrViewIcon()} &nbsp;{' '}
					<span onClick={event => deleteVideo(event)}>
						{' '}
						<DeleteOutlined title='Delete video' className={styles['thumbnail-icon']} twoToneColor='white'></DeleteOutlined>{' '}
					</span>{' '}
				</div>
			)
		} else {
			return <div className={styles['thumbnail-holder']}> {getDownloadOrViewIcon()} </div>
		}
	}

	function openVideo(event) {
		setOpenPlayer(true)
	}

	function deleteVideo(event) {
		setShowDeleteModal(true)
	}

	function getSelectedClass() {
		if (isSelected) {
			return styles['thumbnail-card-selected']
		} else {
			return styles['thumbnail-card']
		}
	}

	return (
		<>
			<div className={getSelectedClass()} onClick={() => selectContribution()}>
				{getImage()}
				{!isUploading ? (
					<>
						<div className={styles['thumbnail-overlay-greyed']}>{getHoverIcons()}</div>
						<div>
							<div className={styles['thumbnail-name-holder']}>
								<div className={styles['thumbnail-name']}> {name} </div></div>
							{getOverlayIcon()}

						</div>{' '}
					</>
				) : (
					''
				)}
				{showProgress ? <Progress style={{ position: 'relative', left: '10px' }} strokeColor='#52C41A' percent={uploadProgress} /> : ''}
			</div>
		</>
	)
}

ContributionThumbnail.defaultProps = {
	progress: 0,
}

export default ContributionThumbnail
