/*eslint-disable*/
import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Divider, message, Select, Input, Descriptions } from 'antd'
import AeroField from '../../../AeroComponents/FormFields'
import { DRONE_DETAILS_FIELDS } from '../../../AeroComponents/FormFields/Metadata/drone-details.fields'
import AeroModal from '../../../AeroComponents/Modal'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { DeleteFilled, EditFilled } from '@ant-design/icons'

class DroneDetailsForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fieldsToRender: DRONE_DETAILS_FIELDS(this),
			visible: false,
			currentEditDrone: {},
			visibleDelete: false,
			currentDeleteDrone: null,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.droneTypesData !== nextProps.droneTypesData) {
			this.setState({
				fieldsToRender: DRONE_DETAILS_FIELDS(this),
			})
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let droneDetails = []
				for (let index = 0; index < values.droneType.length; index++) {
					if (values.droneType[index] && values.droneRegNum[index]) {
						droneDetails.push({
							droneType: values.droneType[index],
							droneRegNum: values.droneRegNum[index],
						})
					} else if (values.droneType[index] && !values.droneRegNum[index]) {
						droneDetails.push({
							droneType: values.droneType[index],
						})
					}
				}
				if (droneDetails.length === 0) {
					return message.error('Please select at least one drone type!')
				}
				this.props.updateDroneDetails({ droneDetails, mixpanel_type: 'drone' })
			}
		})
	}

	openEditModal = id => {
		const droneDetailsArr = this.props.data.droneDetails
		const found = droneDetailsArr.filter(function(item) {
			return item.id === id
		})
		this.setState({
			visible: true,
			currentEditDrone: found[0],
		})
	}

	openDeleteModal = id => {
		this.setState({
			visibleDelete: true,
			currentDeleteDrone: id,
		})
	}

	handleDeleteOk = () => {
		this.props.deleteDrone(this.state.currentDeleteDrone)
		this.setState({ visibleDelete: false })
	}

	handleDeleteCancel = () => {
		this.setState({
			visibleDelete: false,
			currentDeleteDrone: '',
		})
	}

	handleCancel = () => {
		this.setState({
			visible: false,
		})
	}

	updateDrone = () => {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let currentDrone = this.state.currentEditDrone
				const newDroneType = values.currentDroneType
				let data = {}
				if (values.currentDroneRegNum) {
					const newRegNo = values.currentDroneRegNum

					data = {
						droneDetails: [{ id: currentDrone.id, droneType: newDroneType, droneRegNum: newRegNo }],
					}
				} else {
					data = {
						droneDetails: [{ id: currentDrone.id, droneType: newDroneType }],
					}
				}
				this.props.updateDroneDetails(data)
				this.props.form.resetFields()
				this.setState({ visible: false })
			} else {
				message.error('Please resolve the errors shown below!')
			}
		})
	}

	render() {
		const { fieldsToRender, visibleDelete } = this.state
		const { getFieldDecorator } = this.props.form
		return (
			<Form onSubmit={this.handleSubmit}>
				<AeroField fieldsToRender={fieldsToRender} {...this.props} />

				{this.props.viewMode ? (
					this.props.data.droneDetails && this.props.data.droneDetails.length ? (
						this.props.data.droneDetails.map(item => (
							<div key={item.id} style={{ border: '1px solid #ECEAEE', borderRadius: '12px', padding: '20px', marginBottom: '20px', paddingTop: '50px' }}>
								<Descriptions labelStyle={{ color: '#84818A' }}>
									<Descriptions.Item>
										<strong>{item.name}</strong>
									</Descriptions.Item>
									<Descriptions.Item label='Registration No'>{item.droneRegNum}</Descriptions.Item>
									<Descriptions.Item>
										<Button
											onClick={() => {
												this.openEditModal(item.id)
											}}
											type='link'
										>
											<EditFilled style={{ color: '#358FFF', position: 'relative', bottom: '8px' }} />
										</Button>
										{item.deletable ? (
											<Button
												onClick={() => {
													this.openDeleteModal(item.id)
												}}
												type='link'
											>
												<DeleteFilled style={{ color: 'red', position: 'relative', bottom: '8px' }} />
											</Button>
										) : null}
									</Descriptions.Item>
								</Descriptions>
							</div>
						))
					) : null
				) : (
					<Button block type='primary' htmlType='submit'>
						Add
					</Button>
				)}
				<AeroModal title='Edit Drone Details' visible={this.state.visible} onCancel={this.handleCancel} footer={false}>
					<p>
						<Form.Item label='Drone Type'>
							{getFieldDecorator('currentDroneType', {
								initialValue: this.state.currentEditDrone.droneType,
							})(
								<Select placeholder='Select Drone Type' style={{ width: '100%' }}>
									{this.props.droneTypesData.map(data => (
										<Select.Option key={data.id} value={data.type}>
											{data.name}
										</Select.Option>
									))}
								</Select>,
							)}
						</Form.Item>
						<Form.Item label='Drone Registration Number'>
							{getFieldDecorator(`currentDroneRegNum`, {
								initialValue: this.state.currentEditDrone.droneRegNum,
							})(<Input />)}
						</Form.Item>
						<Divider></Divider>
						<Form.Item>
							<Button onClick={this.updateDrone} block type='primary'>
								Update
							</Button>
						</Form.Item>
					</p>
				</AeroModal>
				<AeroModal
					visible={visibleDelete}
					onOk={this.handleDeleteOk}
					onCancel={this.handleDeleteCancel}
					footer={
						<div style={{ textAlign: 'center' }}>
							<Button key='submit' type='primary' onClick={this.handleDeleteOk}>
								Delete
							</Button>
							&nbsp; &nbsp; &nbsp;
							<Button key='back' onClick={this.handleDeleteCancel}>
								Cancel
							</Button>
						</div>
					}
				>
					<div style={{ textAlign: 'center' }}>
						<MatImage src={IMAGES_SRC.BIN_ICON} />
						<div>
							<h2>Delete Drone?</h2>
						</div>
					</div>
				</AeroModal>
			</Form>
		)
	}
}

const DroneDetails = Form.create({ name: 'DRONE_DETAILS_FORM' })(DroneDetailsForm)

export default DroneDetails
