import { Button, Input, Modal, Form, DatePicker, Checkbox, Tooltip, Icon } from 'antd'
import React from 'react'
import ProjectDescriptionUpload from '../../../AeroComponents/FormFields/project-description-upload'
import MapWithASearchBox from '../../../AeroComponents/GoogleMap/MapView'
import LocationSearchInput from '../../../AeroComponents/GoogleMap/SearchPlaceAndCurrentLocation'
import { getConfigData, getValueFromStorage } from '../../../Services/LocalStorageService'
import SlotCards from './slot-cards'
import { urlConfig } from '../../../Services/UtilityService'
import ProjectBudget from './project-budget'

import { InfoCircleOutlined, QuestionCircleOutlined, PhoneOutlined } from '@ant-design/icons'

const HEAD_MAP = {
	1: 'Tell us about your project',
	2: 'When do you need this done?',
	3: 'Please tell us more about the project',
	4: 'Tell us your budget',
}
const FAQ_LINK = "https://aerologix.com/faq/";
const configData = getConfigData() ? getConfigData() : urlConfig()
const countryId = configData.countryId
const getLocationLabelWithToolTip = () => {
	return (
		<span> Location  <Tooltip
			title="Once the location is set, it cannot be modified afterwards. After assigning a pilot, you can share the precise address through the chat."
		>
			<InfoCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px' }} />
		</Tooltip> </span>
	)
}
const getHelpLineNumber = ()=>{
	return (countryId==160 )? "+61 1800 570 518" : "1800 570 518"
}

const NewProjectPerStep = props => {
	const {
		step,
		handleNextBtn,
		handleBackBtn,
		form,
		handleAddressChange,
		handleAddressSelect,
		handleBeforeDate,
		handleFlexible,
		handleOnDate,
		handleShowSlots,
		clearAddress,
		toggleMapBox,
		onDateValue,
		selectedDateType,
		beforeDateValue,
		showSlots,
		openModalToAddDetails,
		googleLocationObject,
		setLocationByMap,
		handleSlotChange,
		selectedSlot,
		handleDescChange,
		descValue,
		submitForm,
		disabledDate,
		handleProjectNameChange,
		handleProjectCostChange,
		projectCost,
		projectName,
		editMode,
		holdOn,
		fileData,
		storeFileData,
		handleNegotiableChange,
		isNegotiable,
		budgetLimit,
		address,
	} = props
	const { getFieldValue } = form
	const FIELDS_MAP = {
		1: [
			{
				label: 'Project Title',
				key: 'projectName',
				component: (
					<>
						{editMode ? (
							projectName || holdOn ? (
								<Input
									onChange={handleProjectNameChange}
									value={projectName}
									placeholder='e.g. Wedding photography'
									style={{ borderRadius: '8px', height: '48px', width: '100%' }}
								></Input>
							) : null
						) : (
							<Input
								onChange={handleProjectNameChange}
								value={projectName}
								placeholder='e.g. Wedding photography'
								style={{ borderRadius: '8px', height: '48px', width: '100%' }}
							></Input>
						)}
					</>
				),
				rules: [
					{
						required: true,
						message: 'Project Title is required!',
					},
				],
				initialValue: projectName,
			},
			{
				label: getLocationLabelWithToolTip(),
				key: 'location',
				component: (
					<LocationSearchInput
						source='p2p'
						showSelectOnMap
						address={getFieldValue('location')}
						clearAddress={clearAddress}
						onChange={handleAddressChange}
						onAddressSelect={handleAddressSelect}
						openModal={toggleMapBox}
					/>
				),
				rules: [
					{
						required: true,
						message: 'Project Location is required!',
					},
				],
			},
		],
		2: [
			{
				label: '',
				key: 'onDate',
				component: (
					<>
						<DatePicker
							className={selectedDateType === 'ondate' ? 'cnp-date-picker selected' : 'cnp-date-picker'}
							onChange={handleOnDate}
							placeholder='On Date'
							format='On MMM D'
							value={onDateValue}
							disabledDate={disabledDate}
						/>
					</>
				),
				rules: [],
			},
			{
				label: '',
				key: 'beforeDate',
				component: (
					<>
						<DatePicker
							className={selectedDateType === 'beforedate' ? 'cnp-date-picker selected' : 'cnp-date-picker'}
							onChange={handleBeforeDate}
							placeholder='Prior to Date'
							format='Prior to MMM D'
							value={beforeDateValue}
							disabledDate={disabledDate}
						/>
					</>
				),
				rules: [],
			},
			{
				label: '',
				key: 'flexible',
				component: (
					<Button onClick={handleFlexible} className='cnp-date-picker' type={selectedDateType === 'flexible' ? 'primary' : 'default'}>
						I am flexible
					</Button>
				),
				rules: [],
			},
			{
				label: '',
				key: 'slotCheckbox',
				component: (
					<Checkbox checked={showSlots} onChange={handleShowSlots}>
						I need a certain time of day
					</Checkbox>
				),
				rules: [],
			},
			{
				label: '',
				key: 'deadline_slot',
				component: <SlotCards showSlots={showSlots} handleSlotChange={handleSlotChange} selectedSlot={selectedSlot}></SlotCards>,
				rules: [],
			},
		],
		3: [
			{
				label: 'Project Description',
				key: 'additional_info',
				component: (
					<>
						<Input.TextArea
							onChange={handleDescChange}
							value={descValue}
							rows={6}
							placeholder='Type here'
							style={{ borderRadius: '8px', width: '100%' }}
						></Input.TextArea>
						{getValueFromStorage('TOKEN') ? (
							<div>
								<ProjectDescriptionUpload fileData={fileData} storeFileData={storeFileData} />
							</div>
						) : null}
					</>
				),
				rules: [
					{
						required: true,
						message: 'Project Description is required!',
					},
				],
			},
		],
		4: [
			{
				label: !isNegotiable ? 'Project Budget' : '',
				key: 'budget',
				component: (
					<>
						{editMode ? (
							projectCost || holdOn ? (
								<ProjectBudget
									handleNegotiableChange={handleNegotiableChange}
									handleProjectCostChange={handleProjectCostChange}
									configData={configData}
									projectCost={projectCost}
									isNegotiable={isNegotiable}
									budgetLimit={budgetLimit}
								/>
							) : null
						) : (
							<ProjectBudget
								handleNegotiableChange={handleNegotiableChange}
								handleProjectCostChange={handleProjectCostChange}
								configData={configData}
								projectCost={projectCost}
								isNegotiable={isNegotiable}
								budgetLimit={budgetLimit}
							/>
						)}
					</>
				),
				rules: [
					{
						required: !isNegotiable,
						message: 'Project Budget is required!',
					},
				],
			},
		],
	}
	return (
		<>
			<div style={{ color: '#aba8b0', marginBottom: '10px' }}>Step {step}/4</div>
			<div style={{ fontSize: '24px', marginBottom: step !== 4 ? '20px' : null }}>{HEAD_MAP[step]}</div>
			{step === 4 ? (
				<div className='cnp-subhead-box'>
					The budget set is a guide for pilots and may not be the final price agreed upon. You can modify your budget anytime before accepting a pilot's offer.
				</div>
			) : null}

			{FIELDS_MAP[step].map((item, index) => (
				<div
					style={
						item.key === 'onDate' || item.key === 'beforeDate' || item.key === 'flexible'
							? { display: 'inline-block', margin: '0px 10px 10px 10px' }
							: { padding: '10px' }
					}
					key={index}
				>
					<Form.Item label={item.label} name={item.key} rules={item.rules}>
						{item.component}
					</Form.Item>
				</div>
			))}
			<div>
				{step !== 1 ? (
					<span className='create-new-project-form'>
						<Button style={{ background: '#F2F2F3', color: '#00B3FF', marginRight: '5vw' }} className='create-new-project-nxt-btn' onClick={handleBackBtn}>
							Go Back
						</Button>
					</span>
				) : null}
				{step === 4 ? (
					<Button className='create-new-project-nxt-btn' onClick={submitForm} type='primary'>
						{editMode ? 'Modify Project' : 'Get Bids'}
					</Button>
				) : (
					<Button className='create-new-project-nxt-btn' type='primary' onClick={handleNextBtn}>
						Next
					</Button>
				)}
			</div>

			{step !== 1 ? (
				<div style={{ marginTop: '20px' }} className='create-new-project-form-mobile'>
					<Button className='create-new-project-back-btn' onClick={handleBackBtn}>
						Go Back
					</Button>
				</div>
			) : null}

			 
				<div className='create-new-project-form'>
					<div style={{
						marginTop: '20px',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '10px',
					}} >

						<span style={{ color: '#00B3FF', cursor: 'pointer' }} onClick={() => window.open(FAQ_LINK, '_blank')} > <QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px', marginRight: '5px' }} /> FAQs</span>
						<span style={{ marginLeft: 'auto', backgroundColor: '#6583FE', color: 'white', width: '214px', height: '84px', borderRadius: '12px', textAlign: 'center', padding: '10px' }}>
							<span style={{ marginBottom: '10px' }} ><PhoneOutlined style={{ fontSize: '24px', transform: 'scaleX(-1)' }} />
								Need Help? Call Us
							</span>
							<br />
							<a style={{ backgroundColor: 'white', color: 'black', padding: '4px 30px', borderRadius: '6px', verticalAlign: 'sub' }} href={"tel:"+getHelpLineNumber()}>{getHelpLineNumber()}</a>

						</span>

					</div>
				</div>
			 
				<div className='create-new-project-form-mobile'>
					<div style={{
						marginTop: '20px',
						display:'flex',
						flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
					}} >
						<div style={{ color: '#00B3FF', cursor: 'pointer',marginBottom: '20px' }} onClick={() => window.open(FAQ_LINK, '_blank')} > <QuestionCircleOutlined style={{ position: 'relative', left: '3px', bottom: '3px', marginRight: '5px' }} /> FAQs</div>


						<div style={{  backgroundColor: '#6583FE', color: 'white', width: '100%', height: '84px', borderRadius: '12px', textAlign: 'center', padding: '10px' }}>
							<span style={{ marginBottom: '10px' }} ><PhoneOutlined style={{ fontSize: '20px', transform: 'scaleX(-1)' }} />
								Need Help? Call Us
							</span>
							<br />
							<a style={{ backgroundColor: 'white', color: 'black',  borderRadius: '6px', verticalAlign: 'sub' }} href={"tel:"+getHelpLineNumber()}>
								<button  type="button" style={{ width:'200px', border:'none', borderRadius: '6px', }}> {getHelpLineNumber()} </button>
								</a>

						</div>
					</div>
				</div>

			 
			<Modal open={openModalToAddDetails} footer={false} title='Select Location on Map' onCancel={toggleMapBox}>
				<MapWithASearchBox address={address} googleLocationObject={googleLocationObject} setLocation={setLocationByMap} />
			</Modal>
		</>
	)
}

export default NewProjectPerStep
