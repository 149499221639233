/*eslint-disable*/
import React from 'react'
import './on-boarding.sass'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import StatusCard from './status-card'
import ContactSupport from './ContactSupport'
import { forgotPassword } from '../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button } from 'antd'
import { goTo } from '../../Services/NavigationService'

const GOTO_LOGIN = 'Return to Log In'
const CONTACT_SUPPORT = 'Contact Support'

class SentPasswordRecoveryLink extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			emailToRecovery: props.location.state.email,
		}
	}

	toggleContactSupport = () => {
		this.setState(prevState => ({
			visible: !prevState.visible,
		}))
	}

	resendLink = () => {
		this.props.forgotPassword({ email: this.state.emailToRecovery })
	}

	render() {
		return (
			<div className='flex-container'>
				<MatImage src={IMAGES_SRC.LOGO_BLACK} width='260' height='70' />
				<StatusCard id='RECOVERY_LINK_SENT' data={this.state.emailToRecovery} cardLinkAction={this.resendLink} />
				<div>
					<Button type='link' onClick={() => goTo('login')}>
						{GOTO_LOGIN}
					</Button>
					{/* <Button type='link' onClick={this.toggleContactSupport}>
						{CONTACT_SUPPORT}
					</Button> */}
				</div>
				{this.state.visible ? <ContactSupport onClose={this.toggleContactSupport} /> : ''}
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		forgotPassword: bindActionCreators(forgotPassword, dispatch),
	}
}

export default connect(
	null,
	mapDispatchToProps,
)(SentPasswordRecoveryLink)
