import React from 'react'
import { compose, withProps, lifecycle } from 'recompose'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import LocationSearchInput from './SearchPlaceAndCurrentLocation'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Geocode from 'react-geocode'
import { Button } from 'antd'
import { getConfigData } from '../../Services/LocalStorageService'
import { urlConfig } from '../../Services/UtilityService'

const configData = getConfigData() ? getConfigData() : urlConfig()
const countryId = configData.countryId

const MapWithASearchBox = compose(
	withProps({
		containerElement: <div style={{ height: `500px`, margin: '-20px' }} />,
		mapElement: <div style={{ height: `470px` }} />,
	}),
	lifecycle({
		componentWillMount() {
			const refs = {}
			const location = countryId === 1 ? { lat: -33.865143, lng: 151.2099 } : { lat: -41.294713, lng: 174.7558667 }
			this.setState({
				bounds: null,
				center: this.props.googleLocationObject?.lat && this.props.googleLocationObject?.lng ? this.props.googleLocationObject : location,
				markerPos: this.props.googleLocationObject?.lat && this.props.googleLocationObject?.lng ? this.props.googleLocationObject : null,
				address: this.props.address ? this.props.address : '',
				onMapMounted: ref => {
					refs.map = ref
				},
				onSearchBoxMounted: ref => {
					refs.searchBox = ref
				},
				onMapClick: e => {
					const latLongStr = e.latLng.toJSON()
					Geocode.fromLatLng(latLongStr.lat, latLongStr.lng, 'AIzaSyAxj3E73qJL4BQAPyKN9J8TYBWUsv32dvI').then(
						response => {
							this.setState({
								markerPos: e.latLng,
								center: e.latLng,
								address: response.results[0].formatted_address,
							})
						},
						error => {
							console.error(error)
						},
					)
				},
				handleAddressSelect: address => {
					geocodeByAddress(address)
						.then(async results => {
							// Do something with results[0]: window.google.maps.GeocoderResult[]
							this.setState({
								address: address,
							})
							return getLatLng(results[0])
						})
						.then(latLng => {
							this.setState({
								markerPos: latLng,
								center: latLng,
							})
						})
						.catch(error => {
							console.error('Error', error)
						})
				},
				confirmLocation: () => {
					this.props.setLocation({
						latLng: this.state.markerPos,
						address: this.state.address,
					})
				},
			})
		},
	}),
	withGoogleMap,
)(props => {
	return (
		<div>
			<GoogleMap ref={props.onMapMounted} defaultZoom={countryId === 1 ? 15 : 5} center={props.center} onClick={props.onMapClick}>
				<div style={{ position: 'absolute', top: '120px', width: '301px', left: '12px' }}>
					<LocationSearchInput
						ref={props.onSearchBoxMounted}
						address={props.address}
						bounds={props.bounds}
						// clearAddress={scope.clearAddress}
						// onChange={props.onPlacesChanged}
						onAddressSelect={props.handleAddressSelect}
					/>
				</div>
				<Marker position={props.markerPos} />
			</GoogleMap>
			<Button block type='primary' onClick={props.confirmLocation}>
				Confirm Location
			</Button>
		</div>
	)
})

export default MapWithASearchBox
