import { Alert } from 'antd'
import React from 'react'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'

const SettingsAlert = props => {
	const { enterprisePilotBanner } = props

	return (
		<>
			{enterprisePilotBanner ? (
				<div style={{ padding: '20px' }}>
					<Alert
						message={<span style={{ color: 'white', paddingLeft: '15px' }}>You are eligible to be an enterprise pilot and increase your average earnings!</span>}
						description={
							<span>
								<span style={{ color: 'white', paddingLeft: '15px', fontSize: '13px' }}>
									Head over to the Settings tab and fill in the relevant information to apply
								</span>
								{/* <span className='remind-me-later'>Remind me later</span> */}
							</span>
						}
						type='info'
						closable
						closeText={<span style={{ color: 'white' }}>X</span>}
						showIcon
						icon={
							<span>
								<MatImage src={IMAGES_SRC.INTRODUCE_ICON} />
							</span>
						}
						className='enterprise-pilot-eligiblity-alert'
					/>
				</div>
			) : null}
		</>
	)
}

export default SettingsAlert
