import React from 'react'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import AddressDetails from './Components/address-details'
import CompanyDetails from './Components/company-details'
import ContactDetails from './Components/contact-details'
import AboutYouTab from './Components/TabContents/about-you-tab'
import ArnTab from './Components/TabContents/arn-tab'
import BaseLocationTab from './Components/TabContents/base-location-tab'
import BasicDetailsTab from './Components/TabContents/basic-details-tab'
import CasaTab from './Components/TabContents/casa-tab'
import DroneLicenseTab from './Components/TabContents/drone-licence-tab'
import IdTab from './Components/TabContents/id-tab'
import NzLicensesTab from './Components/TabContents/nz-licenses-tab'
import DroneInsuranceTab from './Components/TabContents/drone-insurance-tab'

export const PilotTabs = scope => {
	const {
		profileData,
		nameCheck,
		droneTypesData,
		quizModal,
		currentIndex,
		quizStarted,
		quizNextDissabled,
		quizCompleteModal,
		retakeModal,
		wrongAnswers,
		quizConfirmationModal,
		addCaaModal,
		fileList,
		p101TestTaken,
		caaLicenseData,
		deleteLicenseModal,
		addOtherNzLicenseModal,
		allLicenseTypes,
		selectedLicense,
		otherLicenseData,
		retryLicense,
		deleteCasaModal,
		currentResponse,
		droneInsuranceModal,
		allDroneInsuranceTypes,
		allPurchasedInsurance,
		deleteInsuranceModal,
		selectedInsurance,
		retryInsurance,
	} = scope.state
	return [
		{
			modKey: 'BASIC_DETAILS',
			key: '1',
			tab: 'Basic Details',
			name: 'Basic Details',
			component: (
				<BasicDetailsTab
					profileData={profileData}
					openMadalToAddDetails={scope.openMadalToAddDetails}
					updateBaseLocation={scope.updateBaseLocation}
					nameCheck={nameCheck}
				/>
			),
		},
		{
			modKey: 'BASE_LOCATION',
			key: '5',
			name: 'Base Location',
			tab: (
				<>
					<span>Base Location</span>
					{!profileData.address ? (
						<div style={{ textAlign: 'left', fontSize: '12px', color: '#F97855' }}>
							<MatImage alt='dot-icn' src={IMAGES_SRC.RED_DOT_ICON} /> Action Required
						</div>
					) : null}
				</>
			),
			component: <BaseLocationTab profileData={profileData} updateBaseLocation={scope.updateBaseLocation} completeProfile={scope.completeProfile} />,
		},
		{
			modKey: 'LICENSES',
			key: '10',
			name: 'Licenses',
			tab: (
				<>
					<span>Licenses</span>
					{!profileData.licenses.length ? (
						<div style={{ textAlign: 'left', fontSize: '12px', color: '#F97855' }}>
							<MatImage alt='dot-icn' src={IMAGES_SRC.RED_DOT_ICON} /> Action Required
						</div>
					) : null}
				</>
			),
			component: (
				<NzLicensesTab
					quizModal={quizModal}
					toggleQuizModal={scope.toggleQuizModal}
					currentIndex={currentIndex}
					captureResponse={scope.captureResponse}
					startQuiz={scope.startQuiz}
					quizStarted={quizStarted}
					quizNextDissabled={quizNextDissabled}
					goBack={scope.goBack}
					quizCompleteModal={quizCompleteModal}
					retakeModal={retakeModal}
					toggleQuizCompleteModal={scope.toggleQuizCompleteModal}
					toggleRetakeModal={scope.toggleRetakeModal}
					wrongAnswers={wrongAnswers}
					retakeQuiz={scope.retakeQuiz}
					quizConfirmationModal={quizConfirmationModal}
					toggleQuizConfirmationModal={scope.toggleQuizConfirmationModal}
					leaveQuiz={scope.leaveQuiz}
					addCaaModal={addCaaModal}
					toggleAddCaaModal={scope.toggleAddCaaModal}
					caaUploadProps={scope.caaUploadProps}
					fileList={fileList}
					p101TestTaken={p101TestTaken}
					submitCaaLicense={scope.submitCaaLicense}
					caaLicenseRef={scope.caaLicenseRef}
					caaLicenseData={caaLicenseData}
					toggleDeleteLicenseModal={scope.toggleDeleteLicenseModal}
					deleteLicenseModal={deleteLicenseModal}
					callDeleteLicense={scope.callDeleteLicense}
					addOtherNzLicenseModal={addOtherNzLicenseModal}
					toggleAddOtherNzLicenseModal={scope.toggleAddOtherNzLicenseModal}
					openOtherNzLicenseModal={scope.openOtherNzLicenseModal}
					allLicenseTypes={allLicenseTypes}
					handleLicenseChange={scope.handleLicenseChange}
					selectedLicense={selectedLicense}
					addLicense={scope.addLicense}
					otherLicenseData={otherLicenseData}
					toggleRetryModal={scope.toggleRetryModal}
					retryLicense={retryLicense}
					deleteDoc={scope.deleteDoc}
					clearCaaNumberRef={scope.clearCaaNumberRef}
					currentResponse={currentResponse}
					updateDocState={scope.updateDocState}
				/>
			),
		},
		{
			modKey: 'ARN',
			key: '2',
			name: 'ARN',
			tab: (
				<>
					<span>Aviation Reference Number (ARN)</span>
					{!profileData.arnLicense ? (
						<div style={{ textAlign: 'left', fontSize: '12px', color: '#F97855' }}>
							<MatImage alt='dot-icn' src={IMAGES_SRC.RED_DOT_ICON} /> Action Required
						</div>
					) : null}
				</>
			),
			component: <ArnTab profileData={profileData} openMadalToAddDetails={scope.openMadalToAddDetails} updateArnDetails={scope.updateArnDetails} />,
		},
		{
			modKey: 'CASA',
			key: '3',
			name: 'CASA License',
			tab: (
				<>
					<span>CASA License</span>
					{!profileData.licenses.length ? (
						<div style={{ textAlign: 'left', fontSize: '12px', color: '#F97855' }}>
							<MatImage alt='dot-icn' src={IMAGES_SRC.RED_DOT_ICON} /> Action Required
						</div>
					) : null}
				</>
			),
			component: (
				<CasaTab
					profileData={profileData}
					deleteCasaLicense={scope.props.deleteCasaLicense}
					updateCasaLicense={scope.updateCasaLicense}
					casaLicenseTypesData={allLicenseTypes}
					callListOfLicenses={scope.callListOfLicenses}
					deleteCasaModal={deleteCasaModal}
					toggleDeleteCasaModal={scope.toggleDeleteCasaModal}
					selectedLicense={selectedLicense}
				/>
			),
		},
		{
			modKey: 'DRONE_DETAILS',
			key: '4',
			name: 'Drones',
			tab: (
				<>
					<span>Drone Details</span>
					{!profileData.droneDetails ? (
						<div style={{ textAlign: 'left', fontSize: '12px', color: '#F97855' }}>
							<MatImage alt='dot-icn' src={IMAGES_SRC.RED_DOT_ICON} /> Action Required
						</div>
					) : null}
				</>
			),
			component: (
				<DroneLicenseTab
					profileData={profileData}
					openMadalToAddDetails={scope.openMadalToAddDetails}
					deleteDrone={scope.deleteDrone}
					droneTypesData={droneTypesData}
					completeProfile={scope.completeProfile}
				/>
			),
		},
		{
			modKey: 'DRONE_INSURANCE',
			key: '11',
			name: 'Drone Insurance',
			tab: 'Drone Insurance',
			component: (
				<DroneInsuranceTab
					droneInsuranceModal={droneInsuranceModal}
					toggleDroneInsuranceModal={scope.toggleDroneInsuranceModal}
					openInsuranceModal={scope.openInsuranceModal}
					allDroneInsuranceTypes={allDroneInsuranceTypes}
					handleInsuranceForm={scope.handleInsuranceForm}
					caaUploadProps={scope.caaUploadProps}
					allPurchasedInsurance={allPurchasedInsurance}
					callDeleteInsurance={scope.callDeleteInsurance}
					deleteInsuranceModal={deleteInsuranceModal}
					selectedInsurance={selectedInsurance}
					toggleDeleteInsuranceModal={scope.toggleDeleteInsuranceModal}
					toggleRetryInsuranceModal={scope.toggleRetryInsuranceModal}
					retryInsurance={retryInsurance}
					deleteDoc={scope.deleteDoc}
					fileList={fileList}
					updateDocState={scope.updateDocState}
				/>
			),
		},

		{
			modKey: 'CONTACT_DETAILS',
			key: '6',
			tab: 'Contact Details',
			name: 'Contact Details',
			component: (
				<>
					<div style={{ fontSize: '16px', color: '#47464A' }}>Contact Details</div>
					{profileData && <ContactDetails data={profileData} updateInfo={scope.openMadalToAddDetails} />}
				</>
			),
		},
		{
			modKey: 'ID_DETAILS',
			key: '7',
			tab: 'ID Details',
			name: 'ID Details',
			component: <IdTab profileData={profileData} />,
		},
		{
			modKey: 'PORTFOLIO',
			key: '8',
			tab: 'Portfolio',
			name: 'Portfolio',
			component: (
				<>
					<div style={{ fontSize: '16px', color: '#47464A' }}>Portfolio</div>
					<div style={{ color: '#84818A', marginTop: '10px' }}>Your portfolio will help you land projects so go ahead and upload your best work!</div>
					{scope.renderPortfolio('BTN')}
					{scope.renderPortfolio('CARD')}
				</>
			),
		},
		{
			modKey: 'ABOUT_YOU',
			key: '9',
			tab: 'About You',
			name: 'About You',
			component: <AboutYouTab profileData={profileData} openMadalToAddDetails={scope.openMadalToAddDetails} />,
		},
	]
}

export const ClientTabs = scope => {
	const { profileData } = scope.state
	return [
		{
			modKey: 'BASIC_DETAILS',
			key: '1',
			tab: 'Basic Details',
			name: 'Basic Details',
			component: <BasicDetailsTab profileData={profileData} openMadalToAddDetails={scope.openMadalToAddDetails} updateBaseLocation={scope.updateBaseLocation} />,
		},
		{
			modKey: 'CONTACT_DETAILS',
			key: '2',
			tab: 'Contact Details',
			name: 'Contact Details',
			component: (
				<>
					<div style={{ fontSize: '16px', color: '#47464A' }}>Contact Details</div>
					{profileData && <ContactDetails data={profileData} updateInfo={scope.openMadalToAddDetails} />}
				</>
			),
		},
		{
			modKey: 'ADDRESS_DETAILS',
			key: '3',
			tab: 'Address Details',
			name: 'Address Details',
			component: (
				<>
					<div style={{ fontSize: '16px', color: '#47464A' }}>Address Details</div>
					<div style={{ marginTop: '20px' }}>
						{profileData ? (
							profileData['address'] ? (
								<AddressDetails viewMode updateBaseLocation={scope.updateBaseLocation} data={profileData} />
							) : (
								<AddressDetails updateAddressDetails={scope.completeProfile} data={profileData} />
							)
						) : null}
					</div>
				</>
			),
		},
		{
			modKey: 'COMPANY_DETAILS',
			key: '4',
			tab: 'Company Details',
			name: 'Company Details',
			component: (
				<>
					<div style={{ fontSize: '16px', color: '#47464A' }}>Company Details</div>
					<div style={{ marginTop: '20px' }}>
						{profileData && profileData['corporateDetails'] ? (
							<CompanyDetails viewMode data={profileData} />
						) : (
							<CompanyDetails updateCompanyDetails={scope.completeProfile} />
						)}
					</div>
				</>
			),
		},
		{
			modKey: 'ABOUT_YOU',
			key: '5',
			tab: 'About You',
			name: 'About You',
			component: <AboutYouTab profileData={profileData} openMadalToAddDetails={scope.openMadalToAddDetails} />,
		},
	]
}

export const NZ_QUIZ_RULES = [
	'The test consists of 6 questions, and you must answer all of them correctly.',
	'Each question has only one correct answer.',
	'You can attempt this quiz as many times as you want.',
	'There is no time limit for this quiz.',
	'If you close the quiz before answering all 6 questions, you will be required to start the quiz from the beginning.',
	'Once you have answered all 6 questions, you will be able to know whether you got all the answers right or wrong.',
	'',
	'If you answer all the questions correctly, you’ll become eligible to receive project invitations and send bids for commercial projects.',
]

export const NZ_QUIZ_QUESTIONS = [
	{
		key: 1,
		question: 'Nominally (without approval) what is the maximum operating height of a remotely piloted aircraft?',
		options: [
			{
				id: 1,
				text: '100ft / 30m',
				isCorrect: false,
			},
			{
				id: 2,
				text: '200ft / 60m',
				isCorrect: false,
			},
			{
				id: 3,
				text: '400ft / 120m',
				isCorrect: true,
			},
			{
				id: 4,
				text: '1000ft / 305m',
				isCorrect: false,
			},
		],
	},
	{
		key: 2,
		question: 'Nominally (without approval/supervision/shielded) what is the closest you can operate a remotely piloted aircraft to an aerodrome?',
		options: [
			{
				id: 5,
				text: '2km',
				isCorrect: false,
			},
			{
				id: 6,
				text: '3km',
				isCorrect: false,
			},
			{
				id: 7,
				text: '4km',
				isCorrect: true,
			},
			{
				id: 8,
				text: '5km',
				isCorrect: false,
			},
		],
	},
	{
		key: 3,
		question: 'You must not fly a remotely piloted aircraft at night unless:',
		options: [
			{
				id: 9,
				text: 'The aircraft has appropriate lighting',
				isCorrect: false,
			},
			{
				id: 10,
				text: 'The flight is indoors or shielded',
				isCorrect: true,
			},
			{
				id: 11,
				text: 'The flight is shielded or has appropriate lighting',
				isCorrect: false,
			},
			{
				id: 12,
				text: 'The flight is indoors or tethered',
				isCorrect: false,
			},
		],
	},
	{
		key: 4,
		question: 'In Part 101, are you allowed to operate outside of Visual Line of Sight (VLoS)?',
		options: [
			{
				id: 13,
				text: 'Only when you have appropriate lighting and Return To Home (RTH) set',
				isCorrect: false,
			},
			{
				id: 14,
				text: 'Only when you can still see the aircraft with binoculars',
				isCorrect: false,
			},
			{
				id: 15,
				text: 'Only when weather has deteriorated since taking off and now obstructing VLoS',
				isCorrect: false,
			},
			{
				id: 16,
				text: 'Never',
				isCorrect: true,
			},
		],
	},
	{
		key: 5,
		question: 'Who is the Aeronautical Information Service (AIS) provider for New Zealand?',
		options: [
			{
				id: 17,
				text: 'Airways',
				isCorrect: true,
			},
			{
				id: 18,
				text: 'Airservices New Zealand',
				isCorrect: false,
			},
			{
				id: 19,
				text: 'Federal Aviation Administration (FAA)',
				isCorrect: false,
			},
			{
				id: 20,
				text: 'Civil Aviation Authority (CAA)',
				isCorrect: false,
			},
		],
	},
	{
		key: 6,
		question: 'Who is the aviation safety authority in New Zealand?',
		options: [
			{
				id: 21,
				text: 'Airways',
				isCorrect: false,
			},
			{
				id: 22,
				text: 'Civil Aviation Safety Authority (CASA)',
				isCorrect: false,
			},
			{
				id: 23,
				text: 'Civil Aviation Authority (CAA)',
				isCorrect: true,
			},
			{
				id: 24,
				text: 'Federal Aviation Administration (FAA)',
				isCorrect: false,
			},
		],
	},
]
