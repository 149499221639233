import { Button, Input } from 'antd'
import React from 'react'

const SupportDrawerContent = props => {
	const { storeSupportQuery, submitSupportQuery, type } = props
	return (
		<>
			<div style={{ textAlign: 'center', padding: '10px' }}>
				<p>
					<div style={{ textAlign: 'left' }}>Drone Model</div>
					<Input style={{ borderRadius: '8px' }} onBlur={storeSupportQuery} />
				</p>
				<p>
					<Button
						onClick={submitSupportQuery}
						style={{ borderRadius: '8px', width: '100%', height: '50px', background: type === 'mobile' ? '#00B3FF' : null, border: type === 'mobile' ? '0px' : null }}
						type='primary'
					>
						Submit
					</Button>
				</p>
			</div>
		</>
	)
}

export default SupportDrawerContent
