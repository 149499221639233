import React from 'react'
import AddressDetails from '../address-details'

const BaseLocationTab = props => {
	const { profileData, updateBaseLocation, completeProfile } = props
	return (
		<>
			<div style={{ fontSize: '16px', color: '#47464A' }}>
				Base Location <span style={{ color: '#84818A' }}>(Your Current Location)</span>
			</div>
			<div style={{ color: '#84818A', marginTop: '10px' }}>Jobs in the vicinity will be allocated to you according to your Base Location</div>
			<div style={{ marginTop: '20px' }}>
				{profileData ? (
					profileData['address'] ? (
						<AddressDetails pilot={true} viewMode updateBaseLocation={updateBaseLocation} data={profileData} />
					) : (
						<AddressDetails pilot={true} updateAddressDetails={completeProfile} data={profileData} />
					)
				) : null}
			</div>
		</>
	)
}

export default BaseLocationTab
