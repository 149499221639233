import { Descriptions, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { timeDiffString } from '../../../Services/UtilityService'
import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { getWebConfig } from '../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const STATUS_MAP = {
	ALLOTTED: 'Project Allotted',
	IN_FLIGHT: 'In-Flight',
	COMPLETED: 'Data Delivered',
	DATA_REJECTED_FINAL: 'Data Rejected',
	RESOLVED: 'Project Completed',
}

const EnterpriseProjectsCard = props => {
	const { data, tabType, webConfig, getWebConfig } = props
	const [configData, setConfigData] = useState(webConfig)
	const [waitForApi, setWaitForApi] = useState(false)

	useEffect(() => {
		if (!configData) {
			setWaitForApi(true)
			getWebConfig()
		}
	}, [configData])

	useEffect(() => {
		if (webConfig && waitForApi) {
			setWaitForApi(false)
			setConfigData(webConfig)
		}
	}, [webConfig])

	const descContent = type => {
		return (
			<Descriptions layout={type === 'web' ? 'horizontal' : 'vertical'} labelStyle={{ color: '#84818A' }} size='small' column={type === 'web' ? 4 : 2}>
				<Descriptions.Item label='Your Earnings'>
					{data.quote.amount === 0 && data.is_negotiable ? 'NEGOTIABLE' : configData.currency + ' ' + data.quote.amount}{' '}
					<Divider type='vertical' className='card-divider' />
				</Descriptions.Item>
				<Descriptions.Item label='Deadline'>
					{data.deadline
						? moment
								.utc(data.deadline)
								.local()
								.format('DD/MM/YYYY')
						: ''}
					{type === 'web' ? <Divider type='vertical' className='card-divider' /> : null}
				</Descriptions.Item>
				<Descriptions.Item label='Location'>{data.address.addressDetails}</Descriptions.Item>
			</Descriptions>
		)
	}

	return (
		<div>
			<div style={{ fontSize: '18px', padding: '10px' }}>
				{data.name}
				{tabType === 'ongoing' ? (
					<span style={{ marginLeft: '10px' }} className='bid-tag project-status'>
						<ClockCircleFilled style={{ position: 'relative', bottom: '3px' }} />
						&nbsp;&nbsp;{STATUS_MAP[data.state]}
					</span>
				) : null}
				{tabType === 'history' ? (
					<span
						style={{ marginLeft: '10px' }}
						className={
							data.quote.status === 'ACCEPTED' || data.quote.status === 'COMPLETED' || data.quote.status === 'RESOLVED' ? 'bid-tag accepted' : 'bid-tag rejected'
						}
					>
						{data.quote.status === 'ACCEPTED' || data.quote.status === 'COMPLETED' || data.quote.status === 'RESOLVED' ? (
							<CheckCircleFilled style={{ position: 'relative', bottom: '3px' }} />
						) : (
							<CloseCircleFilled style={{ position: 'relative', bottom: '3px' }} />
						)}
						&nbsp;&nbsp;
						{data.quote.status === 'ACCEPTED' || (data.quote.status === 'COMPLETED' && data.state !== 'RESOLVED')
							? 'Project Accepted'
							: data.quote.status === 'REJECTED'
							? 'Project Rejected'
							: data.quote.status === 'RESOLVED' || (data.quote.status === 'COMPLETED' && data.state === 'RESOLVED')
							? 'Project Completed'
							: 'Project Failed'}
					</span>
				) : null}
				<span className='web-posted-at-text'>Posted {timeDiffString(data.created_at)} ago</span>
				<span className='invited-by-aero-tag'>
					<MatImage style={{ marginRight: '5px', marginBottom: '3px' }} alt='tag-img' src={IMAGES_SRC.INVITED_BY_AERO} />
					Invited by Aerologix
				</span>
			</div>
			<div className='mobile-posted-at-text'>Posted {timeDiffString(data.created_at)} ago</div>
			<div className='web-cards-for-projects'>{descContent('web')}</div>
			<div className='mobile-cards-for-projects'>{descContent('mobile')}</div>
			<p style={{ padding: '10px' }}>{data.additional_Info}</p>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		webConfig: state.UserSessionReducer.webConfig,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(EnterpriseProjectsCard)
