/*eslint-disable*/
import React from 'react'
import './on-boarding.sass'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Divider, Spin } from 'antd'
import { TEXTS } from './on-boarding.constants'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { getValueFromStorage, clearLocalStore, setValueToStorage } from '../../Services/LocalStorageService'
import { logIn, socialMediaLogin } from '../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { LOG_IN_FIELDS } from '../../AeroComponents/FormFields/Metadata/log-in.fields'
import AeroField from '../../AeroComponents/FormFields'
import { goTo } from '../../Services/NavigationService'
import AppleLogin from 'react-apple-login'
import { urlConfig } from '../../Services/UtilityService'
import { fetchToken } from '../../Services/firebase-service' 
import FacebookModal from './FacebookModal'

const countryConfig = urlConfig()
class LoginForm extends React.Component {
	constructor(props) {
		super(props)
		const profileType = getValueFromStorage('PROFILE_TYPE')
		if (!(profileType === 'CONSUMER' || profileType === 'PILOT')) {
			props.history.push('/')
			clearLocalStore()
		}
		this.state = {
			profileType,
			fieldsToRender: LOG_IN_FIELDS,
			loading: false,
			facebookLoginModal: false
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, { username, password }) => {
			if (!err) {
				const deviceToken = getValueFromStorage('DEVICE_TOKEN')
				// if (deviceToken) {
				this.props.logIn({ username, password, webUrl: window.location.origin, device_token: deviceToken })
				// } else {
				// 	fetchToken(this.props.setTokenFound, this.props.updateDeviceToken).then(() => {
				// 		this.props.logIn({ username, password, webUrl: window.location.origin, device_token: getValueFromStorage('DEVICE_TOKEN') })
				// 	})
				// }
			}
		})
	}

	redirectSocial = social_type => {
		this.setState({ loading: true })
		const redirection = countryConfig.redirection ? '?redirection=' + countryConfig.redirection : ''
		const redirect_endpoint = process.env.REACT_APP_BASE_URL + 'v1/login/' + social_type + '/' + getValueFromStorage('PROFILE_TYPE') + redirection
		window.location.replace(redirect_endpoint)
	}

	goToForgotPass = () => {
		goTo('forgot-password')
	}

	goToLogin = () => {
		setValueToStorage('PROFILE_TYPE', this.state.profileType === 'CONSUMER' ? 'PILOT' : 'CONSUMER')
		window.location.reload()
	}

	toggleFacebookLoginModal = () =>{
		this.setState(prevState => ({
			facebookLoginModal: !prevState.facebookLoginModal  
		  }));
	}

 

	render() {
		const { profileType, fieldsToRender, loading } = this.state
		return (
			<>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<MatImage style={{ marginTop: '15px' }} alt='logo' src={IMAGES_SRC.NEW_BLUE_LOGO} />
					{!getValueFromStorage('PROJECT_DATA') ? (
						<Button style={{ marginTop: '15px' }} onClick={this.goToLogin} className='login-as-other-web' type='link'>
							Log In as a {profileType === 'CONSUMER' ? 'Pilot' : 'Client'}
						</Button>
					) : null}
				</div>
				<div className='ob-content-wrapper-form'>
					<div>
						<div style={{ fontSize: '24px', marginBottom: '20px' }}>
							<Button style={{ float: 'left' }} type='link' onClick={() => this.props.history.goBack()}>
								<MatImage src={IMAGES_SRC.BACK_BTN} style={{ position: 'relative', bottom: '9px' }} />
							</Button>
							<span style={{ position: 'relative', right: '30px' }}>Log in as a {profileType === 'CONSUMER' ? 'Client' : 'Pilot'}</span>
						</div>
						<div style={{ marginBottom: '20px' }}>
							<Spin spinning={loading}>
								<Form style={{ width: '60%', margin: 'auto' }} layout='vertical' onSubmit={this.handleSubmit}>
									<AeroField profileType={profileType} fieldsToRender={fieldsToRender} {...this.props} />
									<Form.Item>
										<Button style={{ padding: '0px' }} type='link' onClick={this.goToForgotPass}>
											{TEXTS.FORGOT_PASS}
										</Button>
									</Form.Item>
									<Form.Item>
										<Button style={{ height: '48px' }} block type='primary' htmlType='submit'>
											{TEXTS.LOG_IN}
										</Button>
									</Form.Item>
								</Form>
							</Spin>
						</div>
						<Divider style={{ color: '#ABA8B0' }}>
							<span style={{ color: '#ABA8B0', fontWeight: '400' }}>Or continue with</span>
						</Divider>
						<div style={{ textAlign: 'center', marginBottom: '20px' }}>
							<Button onClick={() => this.redirectSocial('GOOGLE')} style={{ margin: '10px', height: '48px', width: '120px' }}>
								<MatImage style={{ marginRight: '5px' }} alt='google' src={IMAGES_SRC.GOOGLE_NEW_ICON} /> Google
							</Button>
							<Button onClick={ this.toggleFacebookLoginModal} style={{ margin: '10px', height: '48px', width: '120px' }}>
							<MatImage style={{ marginRight: '5px' }} alt='fb' src={IMAGES_SRC.FACEBOOK_NEW_ICON} /> facebook
						</Button>
						 
							<AppleLogin
								style={{ cursor: 'pointer' }}
								designProp={{ height: 50, width: 250, margin: 10 }}
								clientId='com.aerologix.client'
								redirectURI={process.env.REACT_APP_BASE_URL + countryConfig.appleUrl + profileType}
								scope='email%20name'
								responseType='code%20id_token'
								responseMode='form_post'
							/>
						</div>
						<div style={{ textAlign: 'center', padding: '30px' }}>
							<span style={{ color: '#84818A' }}>New User?</span>{' '}
							<Button style={{ fontSize: '16px' }} onClick={() => goTo('signup-select')} type='link'>
								Create an Account
							</Button>
						</div>
					</div>
					{!getValueFromStorage('PROJECT_DATA') ? (
						<div style={{ textAlign: 'center' }}>
							<Button onClick={this.goToLogin} className='login-as-other-mobile' type='link'>
								Log In as a {profileType === 'CONSUMER' ? 'Pilot' : 'Client'}
							</Button>
						</div>
					) : null}
				</div>
				<FacebookModal facebookLoginModal={this.state.facebookLoginModal} 		toggleFacebookLoginModal={this.toggleFacebookLoginModal}	goToForgotPass={this.goToForgotPass} ></FacebookModal>
			</>
		)
	}
}

const LogIn = Form.create({ name: 'LOG_IN_FORM' })(LoginForm)

const mapDispatchToProps = dispatch => {
	return {
		socialMediaLogin: bindActionCreators(socialMediaLogin, dispatch),
		logIn: bindActionCreators(logIn, dispatch),
	}
}

export default connect(
	null,
	mapDispatchToProps,
)(LogIn)
