import React, { useState } from 'react'
import { Button, Checkbox } from 'antd'
import { PDFDownloadLink, BlobProvider, pdf } from '@react-pdf/renderer'

import styles from '../enterprisePilotPlus.module.scss'
import { IMAGES_SRC } from '../../../../../AeroComponents/Image/image.constants'
import MatImage from '../../../../../AeroComponents/Image/index'
import Document from '../components/Document'
import SignatureModal from './SignatureModal'

const TermsAndConditons = ({
	termsAndConditionsModalData,
	currentStep,
	signatureData,
	setTermsAndConditionsModalData,
	setSignatureData,
	handleNext,
	closeEnterprisePlusPilotModal,
	cancelEnterprisePlusPrompt,
	history,
	profileData,
}) => {
	const [signModal, setSignModal] = useState(false)
	const obj = termsAndConditionsModalData.contractObj

	const getNextChar = (char = 97) => {
		return String.fromCharCode(char)
	}
	const goToNextStep = async () => {
		if (currentStep === 1 && !signatureData.eSignComplete) {
			return setSignModal(true)
		}
		const blob = await pdf(<Document profileData={profileData} contractObj={termsAndConditionsModalData.contractObj} signData={signatureData} />).toBlob()
		setTermsAndConditionsModalData(prev => ({
			...prev,
			pdfFile: new File([blob], 'Aerologix_contract.pdf', {
				type: 'application/pdf',
			}),
		}))
		handleNext()
	}

	const goToHomePage = () => {
		closeEnterprisePlusPilotModal()
		cancelEnterprisePlusPrompt()
		history.goBack()
	}

	return (
		<div className={styles['terms_and_conditions']}>
			<div className={styles['goback']}>
				<Button className={styles['goback_button']} type='link' onClick={goToHomePage}>
					<MatImage src={IMAGES_SRC.BACK_BTN} />
				</Button>
				Enterprise
			</div>

			<div className={`${styles['heading']} ${styles['heading_main']}`}>
				<span className={styles['heading_main_name']}>Contract</span>
				<div className={styles['heading_main_buttons']}>
					{!signatureData.eSignComplete && (
						<>
							<BlobProvider document={<Document profileData={profileData} contractObj={termsAndConditionsModalData.contractObj} signData={signatureData} />}>
								{({ blob, url, loading, error }) => (
									<Button
										type='link'
										className={styles['sub_button']}
										onClick={() => {
											window.open(url)
										}}
									>
										<MatImage src={IMAGES_SRC.CONTRACT_PRINT} />
									</Button>
								)}
							</BlobProvider>

							<PDFDownloadLink
								document={<Document profileData={profileData} contractObj={termsAndConditionsModalData.contractObj} signData={signatureData} />}
								fileName='Aerologix_enterprise_plus_pilot.pdf'
							>
								{({ blob, url, loading, error }) => (
									<Button type='link' className={styles['sub_button']}>
										<MatImage src={IMAGES_SRC.CONTRACT_DOWNLOAD} />
									</Button>
								)}
							</PDFDownloadLink>
						</>
					)}
				</div>
			</div>

			{/* PDF CONTENT */}
			<div className={styles['terms_and_conditions-text']}>
				<div>
					{obj &&
						Object.keys(obj).map(el => (
							<>
								{el === 'mainTitle' && (
									<>
										<div className={styles['mainTitle']}>{obj[el].title}</div>
										{obj[el].description.length > 0 && obj[el].description.map(el => <div className={styles['mainTitleDescription']}>{el}</div>)}
									</>
								)}
								{el === 'subTitle' && (
									<>
										<div className={styles['subTitle']}>{obj[el].title}</div>
										{obj[el].description.length > 0 && obj[el].description.map(el => <div className={styles['subTitleDescription']}>{el}</div>)}
									</>
								)}
								{el === 'subData' && (
									<>
										<div className={styles['subTitle']}>{obj[el].title}</div>
										{obj[el].children.length > 0 &&
											obj[el].children.map((el, i) => (
												<>
													<div className={styles['children']}>
														<div className={styles['integerIndex']}>{i + 1}.</div>
														<div className={styles['integerTitle']}>{el.title}</div>
													</div>
													{el.children?.length > 0 &&
														el.children.map((child, j) => (
															<>
																<div className={styles['childrenIndexData']}>
																	<div className={styles['childrenIntegerIndex']}>
																		{i + 1}.{j + 1}
																	</div>
																	<div className={styles['childrenIntegerDescription']}>{child.description}</div>
																</div>
																{child.children?.length > 0 &&
																	child.children.map((children, k) => (
																		<>
																			<div className={styles['subChildrenIndexData']}>
																				<div className={styles['subChildrenIntegerIndex']}>({getNextChar(97 + k)})</div>
																				<div className={styles['subChildrenIntegerDescription']}>{children.description}</div>
																			</div>
																		</>
																	))}
															</>
														))}
												</>
											))}
									</>
								)}
							</>
						))}
				</div>
				<div className={styles['document_dynamic_content']}>
					<div className={styles['document_images']}>
						<span className={styles['image']}>{`${profileData.firstName} ${profileData.lastName}`.toUpperCase()}</span>
						<span>Name of Authorized Signatory</span>
					</div>
					<div className={styles['document_images']}>
						<div className={styles['imageContainer']}>
							{signatureData.eSignPreview && (
								<MatImage className={styles['signImagePreview']} style={{ width: 'inherit', height: 'inherit' }} src={signatureData.eSignPreview} />
							)}{' '}
						</div>
						<span>Signature</span>
					</div>
					<div className={styles['document_images']}>
						<span className={styles['image']}>{signatureData.eSignDateAndTime}</span>
						<span>Date & Time</span>
					</div>
				</div>
			</div>

			<div className={styles['submit_button']}>
				{signatureData.eSignComplete ? (
					<PDFDownloadLink
						document={<Document profileData={profileData} contractObj={termsAndConditionsModalData.contractObj} signData={signatureData} />}
						fileName='Aerologix_enterprise_plus_pilot.pdf'
					>
						{({ blob, url, loading, error }) => (
							<Button className={styles['sub_button']} type='link'>
								<MatImage src={IMAGES_SRC.CONTRACT_DOWNLOAD} />
								<span style={{ marginLeft: '0.5rem' }}>Download signed copy</span>
							</Button>
						)}
					</PDFDownloadLink>
				) : (
					<Checkbox
						className={styles['checkbox']}
						checked={termsAndConditionsModalData.checkbox}
						onClick={() =>
							setTermsAndConditionsModalData(prev => ({
								...prev,
								checkbox: !prev.checkbox,
							}))
						}
					>
						I agree to the terms and conditions
					</Checkbox>
				)}

				<Button
					className={styles['next_eSign_button']}
					type='primary'
					disabled={!signatureData.eSignComplete && !termsAndConditionsModalData.checkbox}
					onClick={goToNextStep}
				>
					{signatureData.eSignComplete ? 'Next' : 'E-sign'}
				</Button>
			</div>

			<SignatureModal visible={signModal} setSignModal={setSignModal} setSignatureData={setSignatureData} />
		</div>
	)
}

export default TermsAndConditons
