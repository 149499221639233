import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import moment from 'moment'
import './payment.sass'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { message, Tabs, Input, Button } from 'antd'
import StripePayment from './Components/stripe-payment'
import AbnDetails from '../Profile/Components/abn-details'
import AeroModal from '../../AeroComponents/Modal'
import {
	addPaymentDetails,
	getAllBankAccounts,
	makeDefaultAccount,
	deleteBankAccount,
	getReceivedPaymentsList,
	getPaymentPageDetails,
	getPilotEarnings,
	sendTaxSummary,
	fetchStripeUrl,
	getPilotCreditCards,
	checkForSubmittedDetails,
	sendInvoiceForProject,
} from '../../Stores/Payments/action'
import { updateProfile, logOut } from '../../Stores/UserSession/action'
import { receivedPaymentsTableColumns } from './tableColumns'
import { getConfigData, getValueFromStorage, setValueToStorage } from '../../Services/LocalStorageService'
import { FILTER_VALUES, PaymentTabs } from './payment.constants'
import PaymentAlerts from './Components/payment-alerts'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import { validateEmailAddress } from '../../Services/UtilityService'

class PaymentForm extends Component {
	constructor(props) {
		super(props)
		const fetchedProfileType = getValueFromStorage('PROFILE_TYPE')
		const webConfig = fetchedProfileType === 'PILOT' ? getConfigData().pilotModules[0].webConfig : getConfigData().consumerModules[0].webConfig
		const webConfigIndex = webConfig.findIndex(item => item.modKey === 'PAYMENT')
		if (!webConfigIndex || !webConfig[webConfigIndex].subMods?.length) {
			this.props.logOut()
		}
		this.state = {
			visible: false,
			modalTitle: '',
			allBankAccounts: [],
			receivedPaymentsList: [],
			paymentPageData: {},
			totalEarning: 0,
			abn: 'Loading...',
			bannerFlags: false,
			currentFilter: 4,
			abnVisible: false,
			totalReveivedPayments: 1,
			searchToken: '',
			currentPage: 1,
			receivedPaymentsColumns: receivedPaymentsTableColumns(this),
			selectedProject: '',
			singleMailModalVisible: false,
			currentStep: getConfigData()?.countryId === 1 ? 1 : 6,
			creditCardData: [],
			subMods: webConfig[webConfigIndex].subMods.map(item => item.modKey),
		}
	}

	componentDidMount() {
		const { isRedirected } = this.props.match.params
		const accId = getValueFromStorage('STRIPE_ACC_ID')
		if (isRedirected && isRedirected === 'success' && accId) {
			this.props.checkForSubmittedDetails({ accountId: accId })
			window.localStorage.removeItem('STRIPE_ACC_ID')
		}
		if (isRedirected && isRedirected === 'failure') {
			message.error('Something went wrong, please try once again!')
			window.localStorage.removeItem('STRIPE_ACC_ID')
		}
		this.props.getAllBankAccounts()
		this.props.getReceivedPaymentsList({ pageNumber: 1, pageSize: 10 })
		this.props.getPaymentPageDetails()
		this.props.getPilotCreditCards()
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.updatePaymentSuccess !== nextProps.updatePaymentSuccess) {
			if (nextProps.updatePaymentSuccess) {
				this.handleCancel()
				this.props.getPaymentPageDetails()
			}
		}
		if (this.props.bankAccountsData !== nextProps.bankAccountsData) {
			if (nextProps.bankAccountsData) {
				let defaultText = ''
				if (nextProps.bankAccountsData.length === 0) {
					defaultText = 'Add bank details to start receiving projects'
				}
				this.setState({ allBankAccounts: nextProps.bankAccountsData, defaultText: defaultText })
			}
		}
		if (this.props.creditCardData !== nextProps.creditCardData) {
			if (nextProps.creditCardData) {
				this.setState({ creditCardData: nextProps.creditCardData })
			}
		}
		if (this.props.makeDefaultAccountData !== nextProps.makeDefaultAccountData) {
			if (nextProps.makeDefaultAccountData) {
				let defaultText = ''
				if (nextProps.bankAccountsData.length === 0) {
					defaultText = 'Add bank details to start receiving projects'
				}
				this.setState({ allBankAccounts: nextProps.makeDefaultAccountData, defaultText: defaultText })
			}
		}
		if (this.props.deleteBankAccountData !== nextProps.deleteBankAccountData) {
			if (nextProps.deleteBankAccountData) {
				let defaultText = ''
				if (nextProps.bankAccountsData.length === 0) {
					defaultText = 'Add bank details to start receiving projects'
				}
				this.setState({ allBankAccounts: nextProps.deleteBankAccountData, defaultText: defaultText })
			}
		}
		if (this.props.receivedPaymentsList !== nextProps.receivedPaymentsList) {
			if (nextProps.receivedPaymentsList) {
				this.setState({ receivedPaymentsList: nextProps.receivedPaymentsList.data, totalReveivedPayments: nextProps.receivedPaymentsList.count })
			}
		}
		if (this.props.paymentPageData !== nextProps.paymentPageData) {
			if (nextProps.paymentPageData) {
				this.setState({
					paymentPageData: nextProps.paymentPageData,
					totalEarning: nextProps.paymentPageData.earnings,
					abn: nextProps.paymentPageData.abn,
					bannerFlags: !nextProps.paymentPageData.bannerFlags.payment ,  
				})
			}
		}
		if (this.props.pilotEarningsData !== nextProps.pilotEarningsData) {
			if (nextProps.pilotEarningsData) {
				this.setState({ totalEarning: nextProps.pilotEarningsData.amount, showFilter: false })
			}
		}
		if (this.props.updateProfileSuccess !== nextProps.updateProfileSuccess) {
			if (nextProps.updateProfileSuccess) {
				this.setState({
					abn: nextProps.updateProfileSuccess.abn,
					abnVisible: false,
				})
				this.props.getPaymentPageDetails()
			}
		}
		if (this.props.sendInvoiceForProjectSuccess !== nextProps.sendInvoiceForProjectSuccess) {
			if (nextProps.sendInvoiceForProjectSuccess) {
				this.handleSingleInvoiceCancel()
				message.success(
					<span>
						Invoice sent to <strong>{nextProps.sendInvoiceForProjectSuccess.message}</strong>{' '}
					</span>,
				)
			}
		}
		if (this.props.sendTaxSummarySuccess !== nextProps.sendTaxSummarySuccess) {
			if (nextProps.sendTaxSummarySuccess) {
				message.success(
					<span>
						Tax summary sent to <strong>{nextProps.sendTaxSummarySuccess.message}</strong>{' '}
					</span>,
				)
			}
		}
		if (this.props.stripeUrlSuccess !== nextProps.stripeUrlSuccess) {
			if (nextProps.stripeUrlSuccess) {
				setValueToStorage('MOVED_OUT_FROM', '/payment')
				setValueToStorage('STRIPE_ACC_ID', nextProps.stripeUrlSuccess.accountId)
				window.location.replace(nextProps.stripeUrlSuccess.url)
			}
		}
		if (this.props.submittedDetails !== nextProps.submittedDetails) {
			if (nextProps.submittedDetails) {
				message.success('Details sent for verification, please come back after some time to check for the account addition.')
			} else {
				message.warning('Bank Account Addition Cancelled')
			}
		}
	}

	handleOk = () => {
		this.setState({ loading: true })
		setTimeout(() => {
			this.setState({ loading: false, visible: false })
		}, 3000)
	}

	handleCancel = () => {
		this.setState({ visible: false })
	}

	closeAddAccountBox = () => {
		this.setState({ visible: false })
		this.props.getAllBankAccounts()
	}

	openMadal = modalKey => {
		switch (modalKey) {
			case 'ADD_CARD':
				this.setState({
					visible: true,
					modalTitle: 'Add Bank Details',
					modalComponent: <StripePayment id='ADD_CARD_DETAILS' updateCardDetails={this.props.updateCardDetails} closeAddAccount={this.closeAddAccountBox} />,
				})
				break
			default:
				break
		}
	}

	completeProfile = data => {
		this.props.changePassword(data)
	}

	makeDefault = async accountId => {
		this.props.makeDefaultAccount({ pilotBankDetailsId: accountId })
	}

	deleteAccount = async accountId => {
		this.props.deleteBankAccount({ pilotBankDetailsId: accountId })
	}

	changeFilter = filterValue => {
		this.props.getPilotEarnings({ timeFrame: FILTER_VALUES[filterValue].value })
		this.setState({ currentFilter: filterValue })
	}

	updateAbnDetails = data => {
		const countryId = getConfigData()?.countryId
		if (countryId === 1) {
			if (data.businessNumber.length !== 11) {
				return message.warning('The ABN should be exactly 11 digits!')
			}
		} else if (countryId === 160) {
			if (data.businessNumber.length !== 13) {
				return message.warning('The NZBN should be exactly 13 digits!')
			}
		}
		this.props.updateProfile(data)
	}

	openMadalToAddDetails = () => {
		this.setState({ abnVisible: true })
	}

	handleAbnCancel = () => {
		this.setState({ abnVisible: false })
	}

	onSearch = value => {
		if (value) {
			if (value.length < 3) {
				message.error('Please enter at least three letters!')
			} else {
				this.props.getReceivedPaymentsList({ pageNumber: 1, pageSize: 10, searchToken: value })
				this.setState({ searchToken: value })
			}
		} else {
			this.props.getReceivedPaymentsList({ pageNumber: 1, pageSize: 10 })
			this.setState({ searchToken: '' })
		}
	}

	changePage = page => {
		this.setState({ currentPage: page })
		this.props.getReceivedPaymentsList({ pageNumber: page, pageSize: 10, searchToken: this.state.searchToken })
	}

	sendSingleInvoice = values => {
		const body = {
			email: values.email,
			startDate: moment(values.range[0])
				.format('DD/MM/YYYY')
				.toString(),
			endDate: moment(values.range[1])
				.format('DD/MM/YYYY')
				.toString(),
			mixpanel_type: 'pilot_mail_tax_summary',
		}
		this.props.sendTaxSummary(body)
	}

	mailInvoice = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let body = {
					email: values.email,
					projectId: this.state.selectedProject.id,
				}
				this.props.sendInvoiceForProject(body)

				this.props.form.resetFields()
			} else {
				message.error('Please enter email!')
			}
		})
	}

	getStripeUrl = () => {
		if (this.state.allBankAccounts.length === 5) {
			return message.warning('You have already added 5 bank accounts, please delete one to add a new one!')
		}
		if (!this.props.profileData.mobileNumber) {
			return message.warning('Please add your phone number before adding the bank account!')
		}
		const baseUrl = window.location.origin
		this.props.fetchStripeUrl({
			url: `${baseUrl}/payment/success`,
			refreshUrl: `${baseUrl}/payment/failure`,
		})
	}

	mailSingleInvoice = project => {
		this.setState({ selectedProject: project, singleMailModalVisible: true })
	}

	handleSingleInvoiceCancel = () => {
		this.setState({ singleMailModalVisible: false })
	}

	handleTabChange = val => {
		this.setState({ currentStep: Number(val) })

		// Sending payment details event to Mixpanel
		const tab = PaymentTabs(this)[val - 1]
		if (tab.name === 'Payment Details') {
			pushEventToMixpanel('', 'Payment Details', {
				'Is Accounts Added': this.props.bankAccountsData?.length > 0,
				'Account Verified By Stripe': this.props.bankAccountsData?.filter(account => account.verified).length,
			})
		}
	}

	render() {
		const { visible, modalTitle, modalComponent, paymentPageData, bannerFlags, abnVisible, currentStep, subMods, singleMailModalVisible, selectedProject } = this.state
		const { getFieldDecorator } = this.props.form
		return (
			<React.Fragment>
				<div className='projects-heading' style={{ marginLeft: '30px', marginBottom: '20px' }}>
					Payments
					
				</div>
				<PaymentAlerts bannerFlags={bannerFlags} paymentPageData={paymentPageData} />
				<Tabs
					className='create-new-project-form'
					size='small'
					activeKey={String(currentStep)}
					onChange={this.handleTabChange}
					style={{ padding: '10px' }}
					tabPosition='left'
					tabBarStyle={{ height: '90vh', color: '#84818A' }}
				>
					{PaymentTabs(this).map(item => (
						<>
							{subMods.includes(item.modKey) ? (
								<Tabs.TabPane tab={item.tab} key={item.key}>
									
									{item.component}
								</Tabs.TabPane>
							) : null}
						</>
					))}
				</Tabs>
				<Tabs className='create-new-project-form-mobile' activeKey={String(currentStep)} onChange={this.handleTabChange} style={{ padding: '20px' }}>
					{PaymentTabs(this).map(item => (
						<>
							{subMods.includes(item.modKey) ? (
								<Tabs.TabPane tab={item.tab} key={item.key}>
									
									{item.component}
								</Tabs.TabPane>
							) : null}
						</>
					))}
				</Tabs>

				<AeroModal visible={visible} title={modalTitle} onOk={this.handleOk} onCancel={this.handleCancel} footer={null}>
					{modalComponent}
				</AeroModal>
				<AeroModal visible={abnVisible} title='Add Australian Business Number' onOk={this.handleOk} onCancel={this.handleAbnCancel} footer={null}>
					<AbnDetails updateAbnDetails={this.updateAbnDetails} />
				</AeroModal>
				<AeroModal
					visible={singleMailModalVisible}
					title={
						<span>
							<strong>Send Invoice: </strong> {selectedProject.name}
						</span>
					}
					onCancel={this.handleSingleInvoiceCancel}
					footer={null}
				>
					<Form layout='vertical' onSubmit={this.mailInvoice}>
						<Form.Item label='Email Address'>
							{getFieldDecorator('email', {
								rules: [
									{
										required: true,
										message: 'Please enter email!',
										whitespace: true,
									},
									{
										validator: validateEmailAddress,
									},
								],
								initialValue: this.props.userEmail,
							})(<Input type='email' style={{ height: '48px', borderRadius: '8px' }} />)}
						</Form.Item>
						<Form.Item>
							<Button type='primary' htmlType='submit' style={{ height: '48px' }} block>
								Send
							</Button>
						</Form.Item>
					</Form>
				</AeroModal>
			</React.Fragment>
		)
	}
}

const Payment = Form.create({ name: 'PAYMENT_FORM' })(PaymentForm)

const mapStateToProps = state => {
	return {
		updatePaymentSuccess: state.PaymentsReducer.updatePaymentSuccess,
		bankAccountsData: state.PaymentsReducer.bankAccountsData,
		makeDefaultAccountData: state.PaymentsReducer.makeDefaultAccountData,
		deleteBankAccountData: state.PaymentsReducer.deleteBankAccountData,
		receivedPaymentsList: state.PaymentsReducer.receivedPaymentsList,
		paymentPageData: state.PaymentsReducer.paymentPageData,
		pilotEarningsData: state.PaymentsReducer.pilotEarningsData,
		updateProfileSuccess: state.UserSessionReducer.updateProfileSuccess,
		sendTaxSummarySuccess: state.PaymentsReducer.sendTaxSummarySuccess,
		stripeUrlSuccess: state.PaymentsReducer.stripeUrlSuccess,
		creditCardData: state.PaymentsReducer.creditCardData,
		submittedDetails: state.PaymentsReducer.submittedDetails,
		sendInvoiceForProjectSuccess: state.PaymentsReducer.sendInvoiceForProjectSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		updateCardDetails: bindActionCreators(addPaymentDetails, dispatch),
		getAllBankAccounts: bindActionCreators(getAllBankAccounts, dispatch),
		makeDefaultAccount: bindActionCreators(makeDefaultAccount, dispatch),
		deleteBankAccount: bindActionCreators(deleteBankAccount, dispatch),
		getReceivedPaymentsList: bindActionCreators(getReceivedPaymentsList, dispatch),
		getPaymentPageDetails: bindActionCreators(getPaymentPageDetails, dispatch),
		getPilotEarnings: bindActionCreators(getPilotEarnings, dispatch),
		updateProfile: bindActionCreators(updateProfile, dispatch),
		sendTaxSummary: bindActionCreators(sendTaxSummary, dispatch),
		fetchStripeUrl: bindActionCreators(fetchStripeUrl, dispatch),
		getPilotCreditCards: bindActionCreators(getPilotCreditCards, dispatch),
		checkForSubmittedDetails: bindActionCreators(checkForSubmittedDetails, dispatch),
		logOut: bindActionCreators(logOut, dispatch),
		sendInvoiceForProject: bindActionCreators(sendInvoiceForProject, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Payment)
