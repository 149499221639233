import { Button, Card } from 'antd'
import React from 'react'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'

const EmptyCardContent = props => {
	const { checkAndFire } = props

	return (
		<Card className='my-plans-empty-content-card'>
			<p>
				<MatImage src={IMAGES_SRC.DIAMOND_ICON} />
			</p>
			<p style={{ fontSize: '24px' }}>
				<b>
					Access all features of the app with
					<br /> AerologixFly's premium membership
				</b>
			</p>
			<p style={{ fontSize: '20px' }}>Check our available Plans for AeroPath and other upcoming Flight Modules</p>
			<p>
				<Button onClick={checkAndFire} style={{ borderRadius: '8px', minHeight: '50px', minWidth: '380px' }} type='primary'>
					Explore Plans
				</Button>
			</p>
		</Card>
	)
}

export default EmptyCardContent
