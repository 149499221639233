import React from 'react'
import MatImage from '../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../AeroComponents/Image/image.constants'

const IdTab = props => {
	const { profileData } = props
	return (
		<>
			<div style={{ fontSize: '16px', color: '#47464A' }}>ID Details</div>
			<div style={{ color: '#84818A', marginTop: '10px' }}>Australia Post's Digital ID is used to verify your Photo ID</div>
			{profileData && profileData['verified'] ? (
				<div>
					<MatImage src={IMAGES_SRC.CHECK_ICON} /> Verified
				</div>
			) : (
				<div>
					<div style={{ marginTop: '20px', marginBottom: '10px' }}>Verify Identification Documents with Australia Post's Digital ID</div>
					<div id='digitalid-verify' className='primary'></div>
				</div>
			)}
		</>
	)
}

export default IdTab
