// react & redux imports
import React from 'react'
import './form.sass'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Col } from 'antd'

class AeroField extends React.Component {
	render() {
		const { profileType, fieldsToRender, projectDetails } = this.props
		const { getFieldDecorator } = this.props.form
		return profileType
			? fieldsToRender[profileType].map(field => (
					<React.Fragment>
						{projectDetails ? (
							<Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
								<Form.Item key={field.fieldName} label={field.fieldLabel}>
									{getFieldDecorator(field.fieldKey, field.fileObject)(field.fieldToRender)}
								</Form.Item>
							</Col>
						) : (
							<Form.Item key={field.fieldName} label={field.fieldLabel}>
								{getFieldDecorator(field.fieldKey, field.fileObject)(field.fieldToRender)}
							</Form.Item>
						)}
					</React.Fragment>
			  ))
			: fieldsToRender.map(field => (
					<Form.Item key={field.fieldName} label={field.fieldLabel}>
						{getFieldDecorator(field.fieldKey, field.fileObject)(field.fieldToRender)}
					</Form.Item>
			  ))
	}
}

export default AeroField
