import React from 'react'
import { Descriptions } from 'antd'
import '../projects.sass'

const ProjectRequirements = props => {
	const { data } = props
	const descComp = type => {
		return (
			<Descriptions
				labelStyle={{ color: '#84818A' }}
				style={{ marginBottom: '20px' }}
				size='small'
				column={type === 'web' ? 2 : 1}
				layout={type === 'web' ? 'horizontal' : 'vertical'}
			>
				{data.type !== 'VIDEOS' ? (
					<>
						<Descriptions.Item label='Number of Images'>{data.photos}</Descriptions.Item>
						<Descriptions.Item label='Resolution of Images'>{data.photo_resolution}</Descriptions.Item>
					</>
				) : null}
				{data.type !== 'PHOTOS' ? (
					<>
						<Descriptions.Item label='Number of Videos'>{data.videos}</Descriptions.Item>
						<Descriptions.Item label='Resolution of Videos'>{data.video_resolution}</Descriptions.Item>
					</>
				) : null}
			</Descriptions>
		)
	}
	return (
		<div style={{ marginTop: '30px' }}>
			<div style={{ marginLeft: '10px' }}>
				<div style={{ fontSize: '18px', color: '#84818A', marginBottom: '20px' }}>Project Requirements</div>
				<div className='web-desc-holder'>{descComp('web')}</div>
				<div className='mobile-desc-holder'>{descComp('mobile')}</div>
			</div>
		</div>
	)
}

export default ProjectRequirements
