import * as ACTION from './actionTypes'
import {
	logInApi,
	getProfileApi,
	forgotPasswordApi,
	updateProfileApi,
	getCitiesApi,
	getCountriesApi,
	getStatesApi,
	changePasswordApi,
	getAllDroneTypesApi,
	verifyWithDvsApi,
	socialMediaLoginApi,
	updateBaseLocationApi,
	getAllCasaLicenseTypesApi,
	deleteCasaLicenseApi,
	addCasaLicenseApi,
	addArnDetailsApi,
	sendReferralInviteApi,
	verifySharableLinkApi,
	getSocialAuthApi,
	getOtpApi,
	rejectEnterprisePilotPromptApi,
	createEnterprisePilotApi,
	getEnterprisePilotDataApi,
	updateEnterprisePilotApi,
	deleteDroneApi,
	updateEmailApi,
	addCollaboratorApi,
	getOrdersNotOfCollaboratorApi,
	deleteCollaboratorApi,
	updateCollaboratorPermissionsApi,
	contactSupportApi,
	getPilotPortfolioApi,
	getPortfolioUploadUrlApi,
	deletePortfolioMediaApi,
	updateCasaLicenseApi,
	recoverPilotAccountApi,
	getChatAccessTokenApi,
	getEnterprisePlusContractApi,
	cancelEnterprisePlusPromptApi,
	completePilotEnterprisePlusApi,
	updateDeviceTokenApi,
	getAllNotificationsApi,
	updateNotificationReadStatusApi,
	passedQuizApi,
	getAllLicenseTypesApi,
	getAllDroneInsuranceTypesApi,
	addDroneInsuranceApi,
	getAllPurchasedInsuranceApi,
	deleteDroneInsuranceApi,
	updateDroneInsuranceApi,
	reportErrorApi,
	getWebConfigApi,
	getEmailAndMobileOtpApi,
	getEmailOtpApi,
	verifyEmailAndMobileOtpApi
} from './api'
import { pushEventToMixpanel, resetMixpanel } from '../../Services/MixpanelService'
import { getValueFromStorage, removeValueFromStorage } from '../../Services/LocalStorageService'

export const logIn = body => {
	return {
		type: 'LOG_IN',
		payload: logInApi(body),
	}
}

export const socialMediaLogin = body => {
	return {
		type: ACTION.SOCIAL_MEDIA_LOGIN,
		payload: socialMediaLoginApi(body),
	}
}

export const getProfile = () => {
	return {
		type: 'GET_PROFILE',
		payload: getProfileApi(),
	}
}

export const logOut = data => {
	const profileType = getValueFromStorage('PROFILE_TYPE')
	const clientType = getValueFromStorage('clientType')

	removeValueFromStorage('hasProfileFetchedBefore')
	removeValueFromStorage('HAS_EMAIL')
	removeValueFromStorage('HAS_MOBILE')
	// For sending logout event to mixpanel
	let payload = {
		'Log Out Type': data?.auto ? 'Auto' : 'Manual',
	}
	if (profileType !== 'PILOT') {
		payload['Client Type'] = clientType === 'CLIENT_STANDARD' ? 'Commercial' : 'Enterprise'
	}
	pushEventToMixpanel(profileType, 'Log Out', payload)

	// For clearing super properties in mixpanel
	resetMixpanel()

	removeValueFromStorage('clientType')
	removeValueFromStorage('WEB_CONFIG_DATA')
	sessionStorage.clear()

	return {
		type: 'LOG_OUT',
	}
}

export const toggleAppLoading = loaderType => {
	return {
		type: 'APP_LOADER',
		payload: loaderType,
	}
}

export const updateProfile = body => {
	return {
		type: 'UPDATE_PROFILE',
		payload: updateProfileApi(body),
	}
}

export const forgotPassword = body => {
	return {
		type: 'FORGOT_PASS',
		payload: forgotPasswordApi(body),
	}
}

export const changePassword = body => {
	return {
		type: ACTION.CHANGE_PASSWORD,
		payload: changePasswordApi(body),
	}
}

export const getCountries = () => {
	return {
		type: ACTION.GET_COUNTRIES,
		payload: getCountriesApi(),
	}
}

export const getStates = () => {
	return {
		type: ACTION.GET_STATES,
		payload: getStatesApi(),
	}
}

export const getCities = params => {
	return {
		type: ACTION.GET_CITIES,
		payload: getCitiesApi(params),
	}
}

export const getAllDroneTypes = () => {
	return {
		type: ACTION.GET_ALL_DRONES,
		payload: getAllDroneTypesApi(),
	}
}

export const getAllCasaLicenseTypes = () => {
	return {
		type: ACTION.GET_ALL_CASA_LICENSE_TYPES,
		payload: getAllCasaLicenseTypesApi(),
	}
}

export const verifyWithDvs = body => {
	return {
		type: ACTION.VERIFY_WITH_DVS,
		payload: verifyWithDvsApi(body),
	}
}

export const updateBaseLocation = body => {
	return {
		type: ACTION.UPDATE_BASE_LOCATION,
		payload: updateBaseLocationApi(body),
	}
}

export const deleteCasaLicense = queryParams => {
	return {
		type: ACTION.DELETE_CASA_LICENSE,
		payload: deleteCasaLicenseApi(queryParams),
	}
}

export const addCasaLicense = body => {
	return {
		type: ACTION.ADD_CASA_LICENSE,
		payload: addCasaLicenseApi(body),
	}
}

export const addArnDetails = body => {
	return {
		type: ACTION.ADD_ARN_DETAILS,
		payload: addArnDetailsApi(body),
	}
}

export const sendReferralInvite = body => {
	return {
		type: ACTION.SEND_REFERRAL_INVITE,
		payload: sendReferralInviteApi(body),
	}
}

export const verifySharableLink = params => {
	return {
		type: ACTION.VERIFY_SHARABLE_LINK,
		payload: verifySharableLinkApi(params),
	}
}

export const getSocialAuth = body => {
	return {
		type: ACTION.GET_SOCIAL_AUTH,
		payload: getSocialAuthApi(body),
	}
}

export const getOtp = body => {
	return {
		type: ACTION.GET_OTP,
		payload: getOtpApi(body),
	}
}

export const rejectEnterprisePilotPrompt = () => {
	return {
		type: ACTION.REJECT_ENTERPRISE_PILOT_PROMPT,
		payload: rejectEnterprisePilotPromptApi(),
	}
}

export const createEnterprisePilot = (body, mixpanelData) => {
	return {
		type: ACTION.CREATE_ENTERPRISE_PILOT,
		payload: createEnterprisePilotApi(body, mixpanelData),
	}
}

export const getEnterprisePilotData = () => {
	return {
		type: ACTION.GET_ENTERPRISE_PILOT_DATA,
		payload: getEnterprisePilotDataApi(),
	}
}

export const updateEnterprisePilot = body => {
	return {
		type: ACTION.UPDATE_ENTERPRISE_PILOT,
		payload: updateEnterprisePilotApi(body),
	}
}

export const deleteDroneById = pathParam => {
	return {
		type: ACTION.DELETE_DRONE,
		payload: deleteDroneApi(pathParam),
	}
}

export const updateEmail = body => {
	return {
		type: ACTION.UPDATE_EMAIL,
		payload: updateEmailApi(body),
	}
}

export const addCollaborator = body => {
	return {
		type: ACTION.ADD_COLLABORATOR,
		payload: addCollaboratorApi(body),
	}
}

export const getOrdersNotOfCollaborator = queryParams => {
	return {
		type: ACTION.GET_ORDERS_NOT_OF_COLLABORATOR,
		payload: getOrdersNotOfCollaboratorApi(queryParams),
	}
}

export const deleteCollaborator = queryParams => {
	return {
		type: ACTION.DELETE_COLLABORATOR,
		payload: deleteCollaboratorApi(queryParams),
	}
}

export const updateCollaboratorPermissions = body => {
	return {
		type: ACTION.UPDATE_COLLABORATOR_PERMISSIONS,
		payload: updateCollaboratorPermissionsApi(body),
	}
}

export const contactSupport = body => {
	return {
		type: ACTION.CONTACT_SUPPORT,
		payload: contactSupportApi(body),
	}
}

export const getPilotPortfolio = (params, mixpanelData) => {
	return {
		type: ACTION.GET_PILOT_PORTFOLIO,
		payload: getPilotPortfolioApi(params, mixpanelData),
	}
}

export const getPortfolioUploadUrl = body => {
	return {
		type: ACTION.GET_PORTFOLIO_UPLOAD_URL,
		payload: getPortfolioUploadUrlApi(body),
	}
}

export const deletePortfolioMedia = queryParams => {
	return {
		type: ACTION.DELETE_PORTFOLIO_MEDIA,
		payload: deletePortfolioMediaApi(queryParams),
	}
}
export const updateCasaLicense = body => {
	return {
		type: ACTION.UPDATE_CASA_LICENSE,
		payload: updateCasaLicenseApi(body),
	}
}

export const recoverPilotAccount = body => {
	return {
		type: ACTION.RECOVER_PILOT_ACCOUNT,
		payload: recoverPilotAccountApi(body),
	}
}

export const getChatAccessToken = () => {
	return {
		type: ACTION.GET_CHAT_ACCESS_TOKEN,
		payload: getChatAccessTokenApi(),
	}
}

// ENTERPRISE PLUS PILOT APIS
export const getEnterprisePlusContract = pathParams => {
	return {
		type: ACTION.GET_ENTERPRISE_PLUS_CONTRACT_API,
		payload: getEnterprisePlusContractApi(pathParams),
	}
}

export const cancelEnterprisePlusPrompt = () => {
	return {
		type: ACTION.CANCEL_ENTERPRISE_PLUS_PROMPT,
		payload: cancelEnterprisePlusPromptApi(),
	}
}

export const completePilotEnterprisePlus = body => {
	return {
		type: ACTION.COMPLETE_PILOT_ENTERPRISE_PLUS,
		payload: completePilotEnterprisePlusApi(body),
	}
}

export const updateDeviceToken = body => {
	return {
		type: ACTION.UPDATE_DEVICE_TOKEN,
		payload: updateDeviceTokenApi(body),
	}
}

export const getAllNotifications = queryParams => {
	return {
		type: ACTION.GET_ALL_NOTIFICATIONS,
		payload: getAllNotificationsApi(queryParams),
	}
}

export const updateNotificationReadStatus = body => {
	return {
		type: ACTION.UPDATE_NOTIFICATION_READ_STATUS,
		payload: updateNotificationReadStatusApi(body),
	}
}

export const passedQuiz = () => {
	return {
		type: ACTION.UPDATE_NOTIFICATION_READ_STATUS,
		payload: passedQuizApi(null),
	}
}

export const getAllLicenseTypes = () => {
	return {
		type: ACTION.GET_ALL_LICENSE_TYPES,
		payload: getAllLicenseTypesApi(),
	}
}

export const getAllDroneInsuranceTypes = () => {
	return {
		type: ACTION.GET_ALL_DRONE_INSURANCE_TYPES,
		payload: getAllDroneInsuranceTypesApi(),
	}
}

export const addDroneInsurance = body => {
	return {
		type: ACTION.ADD_DRONE_INSURANCE,
		payload: addDroneInsuranceApi(body),
	}
}

export const getAllPurchasedInsurance = () => {
	return {
		type: ACTION.GET_ALL_PURCHASED_INSURANCE,
		payload: getAllPurchasedInsuranceApi(),
	}
}

export const deleteDroneInsurance = queryParams => {
	return {
		type: ACTION.DELETE_DRONE_INSURANCE,
		payload: deleteDroneInsuranceApi(queryParams),
	}
}

export const updateDroneInsurance = body => {
	return {
		type: ACTION.UPDATE_DRONE_INSURANCE,
		payload: updateDroneInsuranceApi(body),
	}
}

export const reportError = body => {
	return {
		type: ACTION.REPORT_ERROR,
		payload: reportErrorApi(body),
	}
}

export const getWebConfig = () => {
	return {
		type: ACTION.GET_WEB_CONFIG,
		payload: getWebConfigApi(),
	}
}

export const sendEmailOtp = params => {
	return {
		type: ACTION.GET_EMAIL_OTP,
		payload: getEmailOtpApi(params),
	}
}
 

export const sendOtpToEmailAndMobile = params => {
	return {
		type: ACTION.GET_EMAIL_AND_MOBILE_OTP,
		payload: getEmailAndMobileOtpApi(params),
	}
}

export const verifyEmailAndMobileOtp = params => {
	return {
		type: ACTION.VERIFY_EMAIL_AND_MOBILE_OTP,
		payload: verifyEmailAndMobileOtpApi(params),
	}
}
