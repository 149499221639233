export const BTN_LOG_OUT = 'Log Out'

export const SIDE_MENU_LIST = {
	CONSUMER: [
		{
			key: 'HOME',
			icon: 'HOME_ICON',
			pathName: '/home',
			displayValue: 'My Projects',
		},
		// {
		//  id: 'MULTISTREAM',
		//  icon: 'MULTISTREAM_ICON',
		//  pathName: '/multistream',
		//  displayValue: 'Multi-Stream',
		// },
		// {
		//  id: 'AEROCLOUD',
		//  icon: 'AEROCLOUD_ICON',
		//  pathName: '/aerocloud',
		//  displayValue: 'Aerocloud'
		// },
		{
			key: 'PROFILE',
			icon: 'PROFILE',
			pathName: '/profile',
			displayValue: 'Profile',
		},
		// {
		//  id: 'PAYMENT',
		//  icon: 'PAYMENT_ICON',
		//  pathName: '/payment',
		//  displayValue: 'Payment',
		// },
		{
			key: 'SETTINGS',
			icon: 'SETTINGS',
			pathName: '/settings',
			displayValue: 'Settings',
		},

		{
			key: 'CONTACT_US',
			icon: 'HELP',
			pathName: '/contact-us',
			displayValue: 'Contact Us',
		},
	],
	PILOT: [
		{
			key: 'HOME',
			icon: 'MY_PROJECTS',
			pathName: '/home',
			displayValue: 'My Projects',
		},
		{
			key: 'GETTY',
			icon: 'FOOTAGE',

			//pathName: '/getty-countdown',
			pathName: '/getty/submission/previous/1/',
			displayValue: 'Footage',
		},
		{
			key: 'PROFILE',
			icon: 'PROFILE',
			pathName: '/profile',
			displayValue: 'Profile',
		},
		{
			key: 'PAYMENT',
			icon: 'PAYMENT',
			pathName: '/payment',
			displayValue: 'Payments',
		},
		{
			key: 'REFER',
			icon: 'REFER_AND_EARN',
			pathName: '/refer',
			displayValue: 'Refer and Earn',
		},
		{
			key: 'UPGRADES',
			icon: 'UPGRADES',
			pathName: '/upgrades',
			displayValue: 'Upgrades',
		},
		{
			key: 'SETTINGS',
			icon: 'SETTINGS',
			pathName: '/settings',
			displayValue: 'Settings',
		},
		{
			key: 'MY_PLANS',
			icon: 'MY_PLANS',
			pathName: '/my-plans',
			displayValue: 'My Plans',
		},
		{
			key: 'CONTACT_US',
			icon: 'HELP',
			pathName: '/contact-us',
			displayValue: 'Help',
		},
	],
	CLIENT_ENTERPRISE: [
		{
			key: 'OVERVIEW',
			icon: 'OVERVIEW_ICON',
			pathName: '/enterprise/overview',
			displayValue: 'Overview',
		},
		{
			key: 'ORDERS',
			icon: 'ORDERS_ICON',
			pathName: '/enterprise/orders',
			displayValue: 'Orders',
		},
		{
			key: 'TRANSACTIONS',
			icon: 'TRANSACTIONS_ICON',
			pathName: '/enterprise/transactions',
			displayValue: 'Transactions',
		},
		{
			key: 'ENTERPRISE_PROFILE',
			icon: 'PROFILE',
			pathName: '/enterprise/profile',
			displayValue: 'Profile',
		},

		{
			key: 'SUPPORT',
			icon: 'HELP',
			pathName: '/enterprise/support',
			displayValue: 'Support',
		},
	],
	CLIENT_COLLABORATOR: [
		{
			key: 'OVERVIEW',
			icon: 'OVERVIEW_ICON',
			pathName: '/enterprise/overview',
			displayValue: 'Overview',
		},
		{
			key: 'ORDER',
			icon: 'ORDERS_ICON',
			pathName: '/enterprise/orders',
			displayValue: 'Orders',
		},
		{
			key: 'TRANSACTIONS',
			icon: 'TRANSACTIONS_ICON',
			pathName: '/enterprise/transactions',
			displayValue: 'Transactions',
		},
		{
			key: 'ENTERPRISE_PROFILE',
			icon: 'PROFILE',
			pathName: '/enterprise/profile',
			displayValue: 'Profile',
		},

		{
			key: 'SUPPORT',
			icon: 'HELP',
			pathName: '/enterprise/support',
			displayValue: 'Support',
		},
	],
}
