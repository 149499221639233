import { Button, Descriptions, Divider } from 'antd'
import React from 'react'
import VerificationTag from '../verification-tag'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const NzCaaLicense = props => {
	const { caaLicenseData, toggleDeleteLicenseModal, toggleRetryModal } = props

	return (
		<>
			<div style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between' }}>
				<div>
					<div style={{ marginBottom: '25px' }}>
						<strong>License</strong>
						<VerificationTag verificationState={caaLicenseData.verificationState} />
					</div>
					<Descriptions labelStyle={{ color: '#84818A' }}>
						<Descriptions.Item label='License Number'>{caaLicenseData.licenseNumber}</Descriptions.Item>
						<Descriptions.Item label='License'>
							<a style={{ color: '#00B3FF' }} href={caaLicenseData.imageURL} target='_blank'>
								Click to download
							</a>
						</Descriptions.Item>
					</Descriptions>
				</div>
				<>
					{caaLicenseData.verificationState === 'VERIFICATION_FAILED' ? (
						<Button style={{ marginRight: '10px' }} type='primary' onClick={() => toggleRetryModal(null)}>
							<EditOutlined style={{ position: 'relative', bottom: '3px' }} />
						</Button>
					) : null}
					<Button onClick={() => toggleDeleteLicenseModal(null)}>
						<DeleteOutlined style={{ position: 'relative', bottom: '3px' }} />
					</Button>
				</>
			</div>
			{caaLicenseData.verificationState === 'VERIFICATION_FAILED' ? (
				<>
					<Divider></Divider>
					<p>{caaLicenseData.failedVerificationReason}</p>
				</>
			) : null}
		</>
	)
}

export default NzCaaLicense
