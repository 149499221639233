import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { combineReducers } from 'redux'

// import reducers.
import UserSessionReducer from '../src/Stores/UserSession/reducer'
import OnBoardingReducer from '../src/Stores/OnBoarding/reducer'
import ProjectsReducer from '../src/Stores/Projects/reducer'
import PaymentsReducer from '../src/Stores/Payments/reducer'
import GettyReducer from './Stores/Getty/reducer'
import MyPlansReducer from './Stores/MyPlans/reducer'

const enhancers = []
const middleware = [thunk, promiseMiddleware]

if (process.env.NODE_ENV === 'development') {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension())
	}
}

const reducers = combineReducers({
	UserSessionReducer,
	OnBoardingReducer,
	ProjectsReducer,
	PaymentsReducer,
	GettyReducer,
	MyPlansReducer,
})

const composedEnhancers = compose(
	applyMiddleware(...middleware),
	...enhancers,
)

const AppStore = createStore(reducers, composedEnhancers)

export default AppStore