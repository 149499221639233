import React from 'react'
import { Input } from 'antd'

export const CHANGE_PASSWORD_FIELDS = scope => {
	function compareToFirstPassword(rule, value, callback) {
		const { form } = scope.props
		// if (form.getFieldValue('newPassword')) {
		if (value && value !== form.getFieldValue('newPassword') && form.getFieldValue('newPassword')) {
			callback('Two passwords that you enter is inconsistent!')
		} else {
			callback()
		}
	}

	function validateToNextPassword(rule, value, callback) {
		const { form } = scope.props

		if (value && scope.state.confirmDirty) {
			form.validateFields(['confirmPassword'], { force: true })
		}

		const regForPass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/
		if (value && value.match(regForPass)) {
			callback()
		} else {
			callback('Password should be of minimum 6 characters, it must have at least one special character and at least one number!')
		}
	}

	return [
		{
			fieldName: 'CURRENT_PASS',
			fieldLabel: 'Current Password',
			fieldKey: 'oldPassword',
			fileObject: {
				rules: [
					{
						required: true,
						message: 'Please input your password!',
					},
				],
			},
			fieldToRender: <Input.Password style={{ height: '48px', borderRadius: '8px' }} />,
		},
		{
			fieldName: 'NEW_PASS',
			fieldLabel: 'New Password',
			fieldKey: 'newPassword',
			fileObject: {
				rules: [
					{
						required: true,
						message: 'Please input your password!',
					},
					{
						validator: validateToNextPassword,
					},
				],
			},
			fieldToRender: <Input.Password style={{ height: '48px', borderRadius: '8px' }} />,
		},
		{
			fieldName: 'CONFIRM_NEW_PASS',
			fieldLabel: 'Confirm New Password',
			fieldKey: 'confirmPassword',
			fileObject: {
				rules: [
					{
						required: true,
						message: 'Please confirm your password!',
					},
					{
						validator: compareToFirstPassword,
					},
				],
			},
			fieldToRender: <Input.Password style={{ height: '48px', borderRadius: '8px' }} />,
		},
	]
}
