import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './transactions.sass'
import { Card, Row, Table } from 'antd'
import Chart from 'react-apexcharts'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { getTransactionsPageData, getTransactionsHistory } from '../../Stores/Projects/action'
import { transactionHistoryCols } from './table-cols'

class Transactions extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {},
			totalPage: 1,
			transactionHistoryCols: transactionHistoryCols(this),
			tableData: [],
			chartSeries: [],
			chartOptions: {
				chart: {
					type: 'donut',
				},
				theme: {
					monochrome: {
						enabled: true,
					},
				},
				plotOptions: {
					pie: {
						donut: {
							size: '55%',
						},
					},
				},
				labels: [],
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: 'bottom',
							},
						},
					},
				],
				tooltip: {
					custom: function({ series, seriesIndex, dataPointIndex, w }) {
						return (
							'<div class="arrow-box">' +
							'<span>' +
							w.globals.labels[seriesIndex] +
							': ' +
							'<img src="' +
							IMAGES_SRC.SMALL_AERO_CREDIT_ICON +
							'"/> ' +
							series[seriesIndex] +
							'</span>' +
							'</div>'
						)
					},
				},
			},
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.transactionsPageData !== nextProps.transactionsPageData) {
			this.setDataForPage(nextProps.transactionsPageData)
		}
		if (this.props.transactionsHistoryData !== nextProps.transactionsHistoryData) {
			this.setState({ tableData: nextProps.transactionsHistoryData.data, totalPage: nextProps.transactionsHistoryData.count })
		}
	}

	componentDidMount() {
		this.props.getTransactionsPageData()
		this.props.getTransactionsHistory()
	}

	setDataForPage = resp => {
		let chartOptions = this.state.chartOptions
		let chartSeries = []
		let labels = []
		for (let index = 0; index < resp.aeroCreditProjectTypeStats.length; index++) {
			chartSeries.push(resp.aeroCreditProjectTypeStats[index].aeroCreditsSpent)
			labels.push(resp.aeroCreditProjectTypeStats[index].name)
		}
		chartOptions.labels = labels
		this.setState({
			data: resp,
			chartOptions: chartOptions,
			chartSeries: chartSeries,
		})
	}

	changePage = page => {
		this.props.getTransactionsHistory({ pageNumber: page })
	}

	render() {
		const { data, chartOptions, chartSeries, transactionHistoryCols, tableData, totalPage } = this.state
		return (
			<div className='page-container'>
				<Row>
					<Card className='en-aero-credit-card'>
						<MatImage src={IMAGES_SRC.AERO_CREDIT_ICON} />

						<div className='en-aero-credit-text'>
							Aero Credits <span style={{ color: '#8C8C8C' }}>(ACr)</span>
						</div>
						<div style={{ fontSize: '36px' }}>
							<strong>{data.aeroCredits}</strong>
						</div>
					</Card>
					<Card className='en-payment-history-card'>
						PAYMENT HISTORY
						{chartSeries && chartOptions.labels.length !== 0 ? (
							<div style={{ width: '385px', top: '10px', left: '200px' }}>
								<Chart options={chartOptions} series={chartSeries} type='donut' />
							</div>
						) : (
							<div style={{ textAlign: 'center', fontSize: '18px', color: '#485465' }}>
								<MatImage src={IMAGES_SRC.ARCHIVE_ICON} />
								<div>No data available</div>
							</div>
						)}
					</Card>
				</Row>

				<Row style={{ paddingTop: '20px' }}>
					<Card className='en-transation-history-card'>
						<div style={{ padding: '10px' }}>TRANSACTION HISTORY</div>

						<Table
							pagination={{
								showQuickJumper: totalPage > 25,
								total: totalPage,
								defaultCurrent: 1,
								onChange: this.changePage,
							}}
							dataSource={tableData}
							columns={transactionHistoryCols}
						/>
					</Card>
				</Row>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		transactionsPageData: state.ProjectsReducer.transactionsPageData,
		transactionsHistoryData: state.ProjectsReducer.transactionsHistoryData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getTransactionsPageData: bindActionCreators(getTransactionsPageData, dispatch),
		getTransactionsHistory: bindActionCreators(getTransactionsHistory, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Transactions)
