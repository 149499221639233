// Import the functions you need from the SDKs you need
import { notification } from 'antd'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { getValueFromStorage, setValueToStorage } from './LocalStorageService'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyDxSyaFs-iZo4jQZn9_H0END44yvFg1ZG4',
	authDomain: 'aerologix-pilot.firebaseapp.com',
	databaseURL: 'https://aerologix-pilot.firebaseio.com',
	projectId: 'aerologix-pilot',
	storageBucket: 'aerologix-pilot.appspot.com',
	messagingSenderId: '398343230422',
	appId: '1:398343230422:web:4eb31b259aafd0f11dca77',
	measurementId: 'G-JG25HJSB1D',
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const fetchToken = async (setTokenFound, updateDeviceToken) => {
	try {
		if (!navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
			const currentToken = await getToken(messaging, { vapidKey: 'BJxXQgG-BS0T1-b3s_INH-oyXznUWEUX8BgzdiJewuA_WDidqtGtoDBmwWxIkbu7D5FGwmlZrDrAC7YRJYKFncE' })
			if (currentToken) {
				const authToken = getValueFromStorage('TOKEN')
				const deviceToken = getValueFromStorage('DEVICE_TOKEN')
				if (!authToken && !deviceToken) {
					setValueToStorage('DEVICE_TOKEN', currentToken)
				} else if (authToken && !deviceToken) {
					updateDeviceToken(currentToken)
				} else if (authToken && deviceToken) {
					if (currentToken !== deviceToken) {
						updateDeviceToken(currentToken)
					}
				}
				setTokenFound(true)
			} else {
				console.log('No registration token available. Request permission to generate one.')
				setTokenFound(false)
			}
		}
	} catch (err) {
		console.log('An error occurred while retrieving token. ', err)
	}
}

export const onMessageListener = (updateNotifications, callReadApi) =>
	new Promise(() => {
		onMessage(messaging, payload => {
			updateNotifications(payload)
			const audio = new Audio('./notification.mp3')
			audio.play()
			notification.open({
				key: payload.data?.id,
				message: payload.data?.title,
				description: payload.data?.body,
				onClick: () => {
					notification.destroy(payload.data?.id)
					callReadApi(payload.data)
				},
			})
		})
	})
