import { message } from 'antd'
import uuid from 'uuid-random'
import { getConfigData, getValueFromStorage } from '../Services/LocalStorageService'
import { AEROLOGIX_ENDPOINTS } from './api-endpoints'
import { logOut, toggleAppLoading } from '../Stores/UserSession/action'
import AppStore from '../store'
import { bindActionCreators } from 'redux'
import { goTo } from '../Services/NavigationService'
import { urlConfig } from '../Services/UtilityService'

const store = AppStore
let isUploadUrl = false
// const PAGE_LOADER_API = [];
const MODAL_LOADER_API = [
	'UPDATE_PROFILE',
	'CREATE_PROJECT',
	'PAYMENT',
	'UPDATE_QUOTE',
	'GET_OTP',
	'CHANGE_PASSWORD',
	'UPDATE_ACCOUNT',
	'UPLOAD_STRIPE_DOCUMENT',
	'REJECT_ENTERPRISE_PILOT_PROMPT',
	'CREATE_ENTERPRISE_PILOT',
	'UPDATE_ENTERPRISE_PILOT',
	'DELETE_DRONE',
	'CREATE_SUPPORT_TICKET',
	'UPDATE_EMAIL',
	'ADD_COLLABORATOR',
	'DELETE_COLLABORATOR',
	'UPDATE_COLLABORATOR_PERMISSIONS',
	'MARK_RESOLVED',
	'DISAPPROVE_FINAL_DATA',
	'SEND_INVOICE_FOR_PROJECT',
	'SEND_TAX_SUMMARY',
	'DOWNLOAD_ADDITIONAL_DATA',
	'CREATE_GETTY_SUBMISSION',
	'INVITE_PILOTS',
	'GET_COMMERCIAL_COMMISION',
	'MODIFY_BID',
	'CANCEL_PROJECT',
	'ADD_CASA_LICENSE',
	'DELETE_CASA_LICENSE',
	'UPDATE_CASA_LICENSE',
	'ADD_DRONE_INSURANCE',
	'DELETE_DRONE_INSURANCE',
	'UPDATE_DRONE_INSURANCE',
	'ENQUIRE_FOR_ORDER',
	'DELETE_FLIGHT_LOG_FILE',
]
const NO_LOADER_API = ['GET_BOX_CREDS', 'GET_ALL_NOTIFICATIONS', 'UPDATE_NOTIFICATION_READ_STATUS']
const OPEN_API = ['LOG_IN', 'REGISTER', 'FORGOT_PASS', 'SOCIAL_MEDIA_LOGIN', 'CHECK_REFERRAL_CODE', 'VERIFY_SHARABLE_LINK', 'GET_SOCIAL_AUTH', 'CONTACT_SUPPORT', 'REPORT_ERROR'] // No Token Attached.
const OTHER_API = [
	'LOG_IN',
	'SOCIAL_MEDIA_LOGIN',
	'CHECK_REFERRAL_CODE',
	'VERIFY_SHARABLE_LINK',
	'CREATE_PROJECT',
	'GET_COUNTRIES',
	'GET_CITIES',
	'GET_STATES',
	'INVITE_PILOTS',
	'SEND_MESSAGE',
	'CREATE_TRANSACTION',
	'PAYMENT',
	'GET_MESSAGES',
	'MARK_IN_FLIGHT',
	'MARK_READY_TO_PROCESS',
	'MARK_COMPLETE',
	'MARK_RESOLVED',
	'CONFLICT_RESOLUTION_PREVIEW',
	'CONFLICT_RESOLUTION_FINAL',
	'GET_PROJECT_FOR_CONSUMER',
	'GET_PROJECT_FOR_PILOT',
	'DISAPPROVE_PREVIEW_DATA',
	'DISAPPROVE_FINAL_DATA',
	'GET_SOCIAL_AUTH',
	'CREATE_SUPPORT_TICKET',
	'GET_TRANSACTIONS_HISTORY',
	'GET_ALL_ORDERS',
	'GET_ALL_PROJECTS_FOR_ORDER',
	'GET_ORDER_DETAILS',
	'CONTACT_SUPPORT',
	'GET_PROJECTS_FOR_CARDS',
	'UPLOAD_PROJECT_DESCRIPTION',
	'SEND_INVOICE_FOR_PROJECT',
	'SEND_TAX_SUMMARY',
	'DOWNLOAD_ADDITIONAL_DATA',
	'GET_PREVIOUS_SUBMISSIONS',
	'GET_SAVED_SUBMISSIONS',
	'CREATE_GETTY_SUBMISSION',
	'GET_BOX_CREDS',
	'DOWNLOAD_BOX_FOLDER',
	'GET_ALL_PLANS',
	'GET_ALL_ACTIVE_PLANS',
	'GET_ALL_EXPIRED_PLANS',
	'GET_ALL_AEROFLY_DRONES',
	'CUSTOM_PLAN_ENQUIRY',
	'REQUEST_NEW_DRONE',
	'CREATE_AEROFLY_TRANSACTION',
	'AEROFLY_PAYMENT',
	'GET_AEROFLY_BANNER_DETAILS',
	'POST_ISSUE_FACED',
	'GENERATE_OTP',
	'INITIATE_FREE_TRIAL',
	'ACTIVATE_FREE_TRIAL',
	'GET_STRIPE_CHECKOUT_URL',
	'GET_COMMERCIAL_COMMISION',
	'GET_BID_HISTORY',
	'GET_CHAT_ACCESS_TOKEN',
	'CANCEL_PROJECT',
	'UPDATE_DEVICE_TOKEN',
	'GET_ALL_NOTIFICATIONS',
	'UPDATE_NOTIFICATION_READ_STATUS',
	'CHECK_FOR_SUBMITTED_DETAILS',
	'ENQUIRE_FOR_ORDER',
	'REPORT_ERROR',
	'UPLOAD_FLIGHT_LOG_FILE',
	'DELETE_FLIGHT_LOG_FILE',
] // others api ENUM
const ROLE_SPECIFIC_API = ['LOG_IN'] // append profile type value from local store in body.
function wait(delay) {
	return new Promise(resolve => setTimeout(resolve, delay))
}
function apiCallWithRetry(url, delay, tries, fetchOptions = {}, otherOptions) {
	function onError(error) {
		let triesLeft = tries - 1
		if (!triesLeft) {
			throw error
		}
		return wait(delay).then(() => apiCallWithRetry(url, delay, triesLeft, fetchOptions, otherOptions))
	}
	return fetch(url, fetchOptions)
		.then(response => {
			if (otherOptions.URL_KEY === 'UPLOAD_MULTIPART_FILE') {
				let responseObject = {
					status_code: response.status,
					data: {
						etag: JSON.parse(response.headers.get('ETag')),
						partNumber: otherOptions.partNo,
					},
				}
				return responseObject
			}
			return response.json()
		})
		.catch(error => {
			if (error.message.includes('The user aborted a request.')) {
				throw error
			}
			console.log('error on partno ' + otherOptions.partNo, ' ', error.message)
			return onError(error)
		})
}
function successHandler(response) {
	if (response.status_code === 500) {
		return Promise.reject(response.status_message)
	} else if (response.status === 500) {
		return Promise.reject(response.message)
	} else if (response.status === 401) {
		bindActionCreators(logOut, store.dispatch)({ auto: true })
		message.error('Session Expired.')
		return Promise.reject(response)
	} else if (response.status_code === 404) {
		if (response.status_message === 'account id not created') {
			return Promise.reject('Data not uploaded yet!')
		} else if (response.status_message === 'Data Preview or Final Data is not uploaded.') {
			// return Promise.resolve('Please upload the data!')
		} else {
			return Promise.resolve(response)
		}
	} else {
		return Promise.resolve(response)
	}
}
function errorHandler(response) {
	return Promise.reject(response)
}
function catchHandler(response) {
	if (typeof response !== 'object') {
		message.error(response)
	} else {
		if (response.status_code === 401 || response.status === 401) {
			bindActionCreators(logOut, store.dispatch)()
			message.error('Session Expired.')
		} else {
			goTo('server-down')
		}
	}
}
function appendPathParams(URL, pathParams) {
	let URL_WITH_PARAMS = URL
	const pathParamsArray = Object.keys(pathParams)
	let pathParamsKey, pathParamsValue
	for (let index = 0; index < pathParamsArray.length; index++) {
		pathParamsKey = pathParamsArray[index]
		pathParamsValue = pathParams[pathParamsKey]
		URL_WITH_PARAMS += index === 0 ? '/' : '/'
		URL_WITH_PARAMS += pathParamsKey + '/' + pathParamsValue
	}
	return URL_WITH_PARAMS
}
function appendQueryParams(URL, queryParams) {
	let URL_WITH_PARAMS = URL
	const queryParamsArray = Object.keys(queryParams)
	let queryParamKey, queryParamValue
	for (let index = 0; index < queryParamsArray.length; index++) {
		queryParamKey = queryParamsArray[index]
		queryParamValue = queryParams[queryParamKey]
		URL_WITH_PARAMS += index === 0 ? '?' : '&'
		URL_WITH_PARAMS += queryParamKey + '=' + queryParamValue
	}
	return URL_WITH_PARAMS
}
function toggleLoader(URL_KEY) {
	if (MODAL_LOADER_API.indexOf(URL_KEY) > -1) {
		bindActionCreators(toggleAppLoading, store.dispatch)('MODAL')
	} else if (NO_LOADER_API.indexOf(URL_KEY) > -1) {
		bindActionCreators(toggleAppLoading, store.dispatch)('NO_LOADER')
	} else {
		bindActionCreators(toggleAppLoading, store.dispatch)('PAGE')
	}
}
function apiCall(METHOD, URL_KEY, body, queryParams, abortSignal, retry) {
	// StartLoader
	if (
		URL_KEY !== 'UPLOAD_MULTIPART_FILE' &&
		URL_KEY !== 'GET_MULTIPART_URLS' &&
		URL_KEY !== 'COMPLETE_MULTIPART_UPLOAD' &&
		URL_KEY !== 'ABORT_MULTIPART_UPLOAD' &&
		URL_KEY !== 'UPLOAD_CONTRIBUTION' &&
		URL_KEY !== 'CREATE_CONTRIBUTION'
	) {
		toggleLoader(URL_KEY)
	}
	const PROFILE_TYPE = getValueFromStorage('PROFILE_TYPE')
	const WEB_CONFIG_DATA = getValueFromStorage('WEB_CONFIG_DATA')
	if (!(PROFILE_TYPE === 'CONSUMER' || PROFILE_TYPE === 'PILOT' || WEB_CONFIG_DATA)) {
		if (PROFILE_TYPE !== null) {
			message.error('Session Expired. Please Log In again.')
		}
		bindActionCreators(logOut, store.dispatch)()
	}
	let endPoint
	// if (URL_KEY === 'UPLOAD_TO_URL') {
	//  endPoint = queryParams === 'PREVIEW' ? body.uploadUrl.previewUrl : body.uploadUrl.uploadUrl
	// }
	if (URL_KEY === 'UPLOAD_MULTIPART_FILE') {
		endPoint = body.url
	} else {
		if (OTHER_API.indexOf(URL_KEY) === -1) {
			endPoint = AEROLOGIX_ENDPOINTS[PROFILE_TYPE][URL_KEY]
		} else {
			endPoint = AEROLOGIX_ENDPOINTS.OTHERS[URL_KEY]
		}
	}
	let partNo = null
	if (URL_KEY === 'UPLOAD_MULTIPART_FILE') {
		partNo = body.partNo
	}
	let URL
	// if (URL_KEY === 'UPLOAD_TO_URL') {
	//  URL = endPoint
	//  isUploadUrl = true
	// }
	if (URL_KEY === 'UPLOAD_MULTIPART_FILE') {
		URL = endPoint
		isUploadUrl = true
	} else {
		URL = process.env.REACT_APP_BASE_URL + endPoint
		isUploadUrl = false
	}
	let clonnedBody
	// if (URL_KEY === 'UPLOAD_TO_URL') {
	//  clonnedBody = body.body
	// }
	if (URL_KEY === 'UPLOAD_MULTIPART_FILE') {
		clonnedBody = body.body
	} else if (
		URL_KEY === 'UPLOAD_STRIPE_DOCUMENT' ||
		URL_KEY === 'ADD_CASA_LICENSE' ||
		URL_KEY === 'ADD_ARN_DETAILS' ||
		URL_KEY === 'DISAPPROVE_PREVIEW_DATA' ||
		URL_KEY === 'DISAPPROVE_FINAL_DATA' ||
		URL_KEY === 'CREATE_ENTERPRISE_PILOT' ||
		URL_KEY === 'UPDATE_ENTERPRISE_PILOT' ||
		URL_KEY === 'CREATE_SUPPORT_TICKET' ||
		URL_KEY === 'UPLOAD_PROJECT_DESCRIPTION' ||
		URL_KEY === 'COMPLETE_MULTIPART_UPLOAD' ||
		URL_KEY === 'UPDATE_CASA_LICENSE' ||
		URL_KEY === 'ABORT_MULTIPART_UPLOAD' ||
		URL_KEY === 'UPLOAD_CONTRIBUTION' ||
		URL_KEY === 'CREATE_CONTRIBUTION' ||
		URL_KEY === 'REQUEST_NEW_DRONE' ||
		URL_KEY === 'CUSTOM_PLAN_ENQUIRY' ||
		URL_KEY === 'POST_ISSUE_FACED' ||
		URL_KEY === 'ADD_DRONE_INSURANCE' ||
		URL_KEY === 'UPDATE_DRONE_INSURANCE' ||
		URL_KEY === 'ENQUIRE_FOR_ORDER' ||
		URL_KEY === 'UPLOAD_FLIGHT_LOG_FILE'
	) {
		clonnedBody = body
	} else {
		if (Array.isArray(body)) {
			clonnedBody = Object.assign([], body)
		} else {
			clonnedBody = Object.assign({}, body)
		}
	}
	let requestObj = {
		method: METHOD,
		redirect: 'follow',
		headers:
			// URL_KEY === 'UPLOAD_TO_URL'
			//  ? {
			//          'Content-Type': 'application/zip',
			//    }
			//  :
			{
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
	}
	if (OPEN_API.indexOf(URL_KEY) === -1) {
		// if (URL_KEY !== 'UPLOAD_TO_URL') {
		if (URL_KEY !== 'UPLOAD_MULTIPART_FILE') {
			//  && URL_KEY !== 'GET_MULTIPART_URLS' && URL_KEY !== 'COMPLETE_MULTIPART_UPLOAD' && URL_KEY !== 'ABORT_MULTIPART_UPLOAD'  ) {
			if (URL_KEY === 'RECOVER_PILOT_ACCOUNT' || URL_KEY === 'VERIFY_EMAIL_AND_MOBILE_OTP' || URL_KEY === 'GET_EMAIL_AND_MOBILE_OTP' || URL_KEY === 'GET_EMAIL_OTP') {
				requestObj.headers['Authorization'] = 'Bearer ' + getValueFromStorage('TEMP_TOKEN')
			} else {
				requestObj.headers['Authorization'] = 'Bearer ' + getValueFromStorage('TOKEN')
			}
		}
	}

	if (URL_KEY === 'GET_OTP') {
		// this api is used for both fully authenticated and partially authenticated (get email and phonenumber page)
		requestObj.headers['Authorization'] = 'Bearer ' + (getValueFromStorage('TEMP_TOKEN') ? getValueFromStorage('TEMP_TOKEN') : getValueFromStorage('TOKEN'))
	}
	requestObj.headers['correlationId'] = uuid()
	requestObj.headers['platform'] = 'DASHBOARD'
	requestObj.headers['appName'] = 'AEROLOGIX'
	const confgiData = getConfigData() ? getConfigData() : urlConfig()
	if (confgiData) requestObj.headers['countryId'] = confgiData.countryId
	if (
		URL_KEY === 'UPLOAD_STRIPE_DOCUMENT' ||
		URL_KEY === 'ADD_CASA_LICENSE' ||
		URL_KEY === 'ADD_ARN_DETAILS' ||
		URL_KEY === 'DISAPPROVE_PREVIEW_DATA' ||
		URL_KEY === 'DISAPPROVE_FINAL_DATA' ||
		URL_KEY === 'CREATE_ENTERPRISE_PILOT' ||
		URL_KEY === 'UPDATE_ENTERPRISE_PILOT' ||
		URL_KEY === 'CREATE_SUPPORT_TICKET' ||
		URL_KEY === 'UPDATE_CASA_LICENSE' ||
		URL_KEY === 'UPLOAD_PROJECT_DESCRIPTION' ||
		URL_KEY === 'REQUEST_NEW_DRONE' ||
		URL_KEY === 'CUSTOM_PLAN_ENQUIRY' ||
		URL_KEY === 'POST_ISSUE_FACED' ||
		URL_KEY === 'ADD_DRONE_INSURANCE' ||
		URL_KEY === 'UPDATE_DRONE_INSURANCE' ||
		URL_KEY === 'ENQUIRE_FOR_ORDER' ||
		URL_KEY === 'UPLOAD_FLIGHT_LOG_FILE'
	) {
		requestObj.headers = new Headers()
		requestObj.headers.append('Authorization', 'Bearer ' + getValueFromStorage('TOKEN'))
		requestObj.headers.append('correlationId', uuid())
		requestObj.headers.append('platform', 'DASHBOARD')
		if (confgiData) requestObj.headers.append('countryId', confgiData.countryId)
	}
	if (ROLE_SPECIFIC_API.indexOf(URL_KEY) > -1) {
		clonnedBody = {
			...clonnedBody,
			role: PROFILE_TYPE,
		}
	}
	if (body || queryParams) {
		if (METHOD === 'GET' || METHOD === 'DELETE') {
			if (body) {
				// pathparams in this case
				URL = appendPathParams(URL, body)
				// if query params is also there
				if (queryParams) {
					URL = appendQueryParams(URL, queryParams)
				}
			} else if (queryParams) {
				URL = appendQueryParams(URL, queryParams)
			}
		} else if (METHOD === 'POST' || METHOD === 'PUT') {
			if (
				//URL_KEY === 'UPLOAD_TO_URL' ||
				URL_KEY === 'UPLOAD_MULTIPART_FILE' ||
				URL_KEY === 'COMPLETE_MULTIPART_UPLOAD' ||
				URL_KEY === 'UPLOAD_STRIPE_DOCUMENT' ||
				URL_KEY === 'ADD_CASA_LICENSE' ||
				URL_KEY === 'ADD_ARN_DETAILS' ||
				URL_KEY === 'DISAPPROVE_PREVIEW_DATA' ||
				URL_KEY === 'DISAPPROVE_FINAL_DATA' ||
				URL_KEY === 'CREATE_ENTERPRISE_PILOT' ||
				URL_KEY === 'UPDATE_ENTERPRISE_PILOT' ||
				URL_KEY === 'CREATE_SUPPORT_TICKET' ||
				URL_KEY === 'UPDATE_CASA_LICENSE' ||
				URL_KEY === 'UPLOAD_PROJECT_DESCRIPTION' ||
				URL_KEY === 'REQUEST_NEW_DRONE' ||
				URL_KEY === 'CUSTOM_PLAN_ENQUIRY' ||
				URL_KEY === 'POST_ISSUE_FACED' ||
				URL_KEY === 'ADD_DRONE_INSURANCE' ||
				URL_KEY === 'UPDATE_DRONE_INSURANCE' ||
				URL_KEY === 'ENQUIRE_FOR_ORDER' ||
				URL_KEY === 'UPLOAD_FLIGHT_LOG_FILE'
			) {
				requestObj.body = clonnedBody
			} else {
				requestObj.body = JSON.stringify(clonnedBody)
			}
		}
	}
	if (abortSignal) {
		requestObj['signal'] = abortSignal
	}

	return (
		fetch(URL, requestObj)
			.then(res => {
				if (
					URL_KEY !== 'UPLOAD_MULTIPART_FILE' &&
					URL_KEY !== 'GET_MULTIPART_URLS' &&
					URL_KEY !== 'COMPLETE_MULTIPART_UPLOAD' &&
					URL_KEY !== 'ABORT_MULTIPART_UPLOAD' &&
					URL_KEY !== 'UPLOAD_CONTRIBUTION' &&
					URL_KEY !== 'CREATE_CONTRIBUTION'
				) {
					// Stop Loader.
					toggleLoader(URL_KEY)
				}
				if (isUploadUrl) {
					let response = {
						status_code: res.status,
						//data: true,
						data: {
							// ETag: JSON.parse(res.headers.get('ETag')),
							// PartNumber: partNo
							etag: JSON.parse(res.headers.get('ETag')),
							partNumber: partNo,
						},
					}
					return response
				} else {
					return res.json()
				}
			})
			.then(successHandler, errorHandler)
			//.catch(catchHandler)
			.catch(error => {
				console.log(error)
				if (error.message && error.message === 'The user aborted a request.') {
					throw error
				}
				if (retry) {
					return apiCallWithRetry(URL, 10000, 15, requestObj, { partNo, URL_KEY })
				}
				// if not aborted or retried
				if (
					URL_KEY === 'UPLOAD_MULTIPART_FILE' ||
					URL_KEY === 'GET_MULTIPART_URLS' ||
					URL_KEY === 'COMPLETE_MULTIPART_UPLOAD' ||
					URL_KEY === 'ABORT_MULTIPART_UPLOAD' ||
					URL_KEY !== 'UPLOAD_CONTRIBUTION' ||
					URL_KEY !== 'CREATE_CONTRIBUTION'
				) {
					// Stop Loader.
					throw error
				}
				catchHandler(error)
			})
	)
}
export function get(URL_KEY, pathParams, queryParams) {
	return apiCall('GET', URL_KEY, pathParams, queryParams)
}
export function deleteMethod(URL_KEY, pathParams, queryParams, abortSignal = null, retry = null) {
	return apiCall('DELETE', URL_KEY, pathParams, queryParams, abortSignal, retry)
}
export function post(URL_KEY, body, abortSignal = null, retry = null) {
	return apiCall('POST', URL_KEY, body, null, abortSignal, retry)
}
export function put(URL_KEY, body, type, abortSignal = null, retry = null) {
	return apiCall('PUT', URL_KEY, body, type, abortSignal, retry)
}
