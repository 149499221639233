import React from 'react'

import { FolderOutlined, CalendarOutlined, ClockCircleOutlined, EyeOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { AeroCard, AeroProgress } from '../../../../AeroComponents'
import MatImage from '../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../AeroComponents/Image/image.constants'
import styles from './projectCards.module.scss'

export default function ProjectStatusCard(props) {
	// extract props
	const {
		name,
		mapLink,
		address,
		status,
		projectType, // common for all project status
		scheduledDate, // for scheduled projects
		completedDate,
		downloadProjectData, // for completed projects
		projectId, // for both completed and inprogress projects
		projectState,
		completionPercentage,
		is_livestream_enabled,
		openLiveStreamModal, // for inprogress projects
		projectData,
		openAdditionalDataModal,
	} = props

	// To hold data (card bottom) apart from common data (card top)
	let cardData = ''

	// fill data according to project status
	switch (status) {
		case 'scheduled':
			cardData = (
				<>
					<div className={styles['scheduled-date-wrapper']}>
						<CalendarOutlined className={styles['icon-placement']} />{' '}
						<span className={styles['scheduled-date']}>
							<strong>Scheduled For: </strong>
							{scheduledDate}
						</span>
					</div>
					{projectData?.project_additional_information?.length ? (
						<div className={styles['download-wrapper']}>
							<InfoCircleOutlined className={styles['icon-placement']} />
							<Button onClick={() => openAdditionalDataModal(projectData)} className={styles['link']} type='link'>
								Data Delivery
							</Button>
						</div>
					) : null}
				</>
			)
			break

		case 'completed':
			cardData = (
				<>
					<div className={styles['completed-date-wrapper']}>
						<CalendarOutlined className={styles['icon-placement']} />{' '}
						<span className={styles['completed-date']}>
							<strong>Completed On: </strong>
							{completedDate}
						</span>
					</div>
					<div className={styles['download-wrapper']}>
						<DownloadOutlined className={styles['icon-placement']} />
						<Button onClick={() => downloadProjectData(projectData)} className={styles['link']} type='link'>
							Raw Data
						</Button>
					</div>
					{projectData?.project_additional_information?.length ? (
						<div className={styles['download-wrapper']}>
							<InfoCircleOutlined className={styles['icon-placement']} />
							<Button onClick={() => openAdditionalDataModal(projectData)} className={styles['link']} type='link'>
								Data Delivery
							</Button>
						</div>
					) : null}
				</>
			)
			break

		case 'inprogress':
			cardData = (
				<>
					<div className={styles['project-state-wrapper']}>
						<ClockCircleOutlined className={styles['icon-placement']} /> <span className={styles['project-state']}>{projectState}</span>
					</div>
					{projectState === 'Data Delivered' ? (
						<div className={styles['download-wrapper']}>
							<DownloadOutlined className={styles['icon-placement']} />
							<Button onClick={() => downloadProjectData(projectData)} className={styles['link']} type='link'>
								Raw Data
							</Button>
						</div>
					) : null}
					{projectData?.project_additional_information?.length ? (
						<div className={styles['download-wrapper']}>
							<InfoCircleOutlined className={styles['icon-placement']} />
							<Button onClick={() => openAdditionalDataModal(projectData)} className={styles['link']} type='link'>
								Data Delivery
							</Button>
						</div>
					) : null}
					<div className={styles['progress-bar']}>
						<AeroProgress showInfo={false} percent={completionPercentage} size='small' strokeColor='#52C41A' />
					</div>

					<div className={styles['progress']}>
						<strong>{completionPercentage + '% '} COMPLETED</strong>
					</div>
					{is_livestream_enabled ? (
						<div className={styles['live-stream']}>
							<Button onClick={() => openLiveStreamModal(projectId)} type='primary' className={styles['button']}>
								<EyeOutlined className='simple-icon-placement' /> View Live Stream
							</Button>
						</div>
					) : null}
				</>
			)
			break

		default:
			break
	}
	return (
		// Common Layout for all types
		<AeroCard className={styles['project-small-card-wrapper']}>
			<div className={styles['name']}>
				<strong>{name}</strong>
			</div>
			<div className={styles['address-wrapper']}>
				<MatImage src={IMAGES_SRC.GREY_MARKER_ICON} />
				<Button target='_blank' rel='noopener noreferrer' href={mapLink} className={styles['address']} type='link'>
					{address}
				</Button>
			</div>
			<div className={styles['project-type-wrapper']}>
				<FolderOutlined className={styles['icon-placement']} /> <span className={styles['project-type']}>{projectType}</span>
			</div>

			{/* data according to project status */}
			{cardData}
		</AeroCard>
	)
}
