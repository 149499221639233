import React, { Component } from 'react'

class MatText extends Component {
	render() {
		return (<React.Fragment>
				{
					(this.props.inline) ? 
					<span style={{fontSize: (this.props.size + 'px'), }}>{this.props.text}</span> :
					<div style={{fontSize: (this.props.size + 'px'), width: '100%', marginBottom: '12px', textAlign: 'center' }}>{this.props.text}</div>
				}
			</React.Fragment>)
	}
}

export default MatText
