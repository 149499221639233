export const TEXTS = {
	SIGNUP_LOGIN_PILOT: 'Sign up/Log In as a Pilot',
	SIGNUP_LOGIN_CONSUMER: 'Sign up/Log In as a Client',
	LOG_IN: 'Log In',
	EMAIL_PHONE: 'Email/Phone Number',
	PASS: 'Password',
	CONFIRM_PASS: 'Confirm Password',
	FORGOT_PASS: 'Forgot password',
	NEW_USER: 'New User? ',
	SIGN_UP: 'Sign Up',
	CREATE_NEW_PASS: 'Create a New Password',
	RETURN_LOG_IN: 'Return to Log In',
	CONTINUE: 'continue',
	CONTACT_SUPPORT: 'Contact Support',
	RESET_PASS: 'Password Reset',
	RECOVERY_LINK_MAIL: "Email (We'll send a recovery link to your email)",
	SEND_LINK: 'Send Recovery Link',
	FN: 'First Name',
	MN: 'Middle Name',
	LN: 'Last Name',
	EMAIL: 'Email ID',
	PN: 'Phone Number',
	ADD_PN: '( to allow Pilots to contact you )',
	CREATE_BP: 'Create Business Profile ( You will not be able to edit this later.)',
	COMPANY_NAME: 'Company Name',
	COMPANY_ADDRESS: 'Company Address',
	ACN_ABN: 'ACN/ ABN',
	GST: 'GST Number',
	B_MAIL: 'Business Email',
	ALREADY_THERE: 'Already Registered? ',
	SIGNUP_TC: `By clicking "Sign Up", you agree that you are 18 years of age or older and You agree to Aerologix's  Terms of Use and acknowledge that you've read the Privacy Policy.`,
	PROFILE_SETUP: 'Profile Setup',
	ADD_LINE_1: 'Address Line 1',
	ADD_LINE_2: 'Address Line 2',
	PC: 'Post Code',
	CITY: 'City',
	STATE: 'State',
	NEXT: 'Next',
	V_C: 'Verify & Continue',
	SKIP: 'Skip',
	SELECT_ID: 'Select ID Type',
	SETUP_PAYPAL: 'Setup Paypal Account',
	SUBMIT: 'Submit',
	PROJ_PREFER: 'Project Preferences',
	KNOW_MORE: 'Know More',
	ABOUT_YOU: 'About You',
	REMEBER_ME: 'Remember Me',
	USERNAME: 'Email ID',
	ARN: 'Enter Aviation Reference Number (ARN)',
	LICT: 'Select License Type',
}

export const PROFILE_SETUP_STPES = ['Address Details', 'ID Verification', 'Payment Profile', 'Profile Picture']

export const PROFILE_SETUP_STEPS = {
	CONSUMER: ['Address Details', 'ID Verification', 'Payment Profile'],
	PILOT: ['Address Details', 'Mobile Number Verification', 'ID Verification', 'Payment Profile', 'Drone Details', 'Profile Picture'],
}

export const PROFILE_SETUP_ID = {
	CONSUMER: ['ACN', 'ABN'],
	PILOT: ['Passport', 'Driving License', 'Medicare'],
}

export const PROJECT_PREFERENCES_STEPS = ['Types Of Projects', 'Proect Area']

export const PROFILE_SETUP_IDS = ['ACN', 'ABN']

export const LOG_IN_FORM_MODAL = {
	USERNAME: 'username',
	REMEBER_ME: 'rememberMe',
	PASS: 'password',
}
