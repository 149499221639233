
import React, { useState, useEffect } from 'react'
import styles from './Status.module.scss'
import moment from 'moment'

import Step from './Step'

function Status(props) {

  // extract props
  const {
    timeline, name, contributionStatus, setShowLearnMoreModal, setShowHowRoyalyWorksModal // from parent  
  } = props

  const blueColor = "#096DD9"
  const redColor = "#F5222D"
  const orangeColor = "#FA541C"
  const greenColor = "#52C41A"
  const greyColor = "grey"
  //state
  const [pendingTitle, setPendingTitle] = useState("PENDING FILE SUBMISSION")
  const [pendingTitleColor, setPendingTitleColor] = useState(greyColor)
  const [pendingIcon, setPendingIcon] = useState("dot")
  const [pendingIconColor, setPendingIconColor] = useState(blueColor)
  const [pendingLineColor, setPendingLineColor] = useState(greyColor)
  const [pendingSubTitle, setPendingSubTitle] = useState(null)
  const [pendingContentTitle, setPendingContentTitle] = useState(null)
  const [pendingContent, setPendingContent] = useState(null)

  const [underAeroTitle, setUnderAeroTitle] = useState("UNDER AEROLOGIX REVIEW")
  const [underAeroTitleColor, setUnderAeroTitleColor] = useState(greyColor)
  const [underAeroIcon, setUnderAeroIcon] = useState("dot")
  const [underAeroIconColor, setUnderAeroIconColor] = useState(blueColor)
  const [underAeroLineColor, setUnderAeroLineColor] = useState(greyColor)
  const [underAeroSubTitle, setUnderAeroSubTitle] = useState(null)
  const [underAeroContentTitle, setUnderAeroContentTitle] = useState(null)
  const [underAeroContent, setUnderAeroContent] = useState(null)

  const [underGettyTitle, setUnderGettyTitle] = useState("UNDER GETTY REVIEW")
  const [underGettyTitleColor, setUnderGettyTitleColor] = useState(greyColor)
  const [underGettyIcon, setUnderGettyIcon] = useState("dot")
  const [underGettyIconColor, setUnderGettyIconColor] = useState(blueColor)
  const [underGettyLineColor, setUnderGettyLineColor] = useState(greyColor)
  const [underGettySubTitle, setUnderGettySubTitle] = useState(null)
  const [underGettyContentTitle, setUnderGettyContentTitle] = useState(null)
  const [underGettyContent, setUnderGettyContent] = useState(null)

  const [publishedTitle, setPublishedTitle] = useState("PUBLISHED")
  const [publishedTitleColor, setPublishedTitleColor] = useState(greyColor,)
  const [publishedIcon, setPublishedIcon] = useState("dot")
  const [publishedIconColor, setPublishedIconColor] = useState(blueColor)
  const [publishedLineColor, setPublishedLineColor] = useState(greyColor,)
  const [publishedSubTitle, setPublishedSubTitle] = useState(null)
  const [publishedContentTitle, setPublishedContentTitle] = useState(null)
  const [publishedContent, setPublishedContent] = useState(null)


  /* Effects */
  useEffect(() => {
    if (timeline) {
      resetData()
      updateTimeline()

    }
    else {
      resetData()
    }
  }, [timeline]);

  function resetData() {
    setPendingTitle("PENDING FILE SUBMISSION")
    setPendingTitleColor(greyColor)
    setPendingIcon("dot")
    setPendingIconColor(blueColor)
    setPendingLineColor(greyColor)
    setPendingContentTitle("")
    setPendingContent("")
    setPendingSubTitle("")

    setUnderAeroTitle("UNDER AEROLOGIX REVIEW")
    setUnderAeroTitleColor(greyColor)
    setUnderAeroIcon("dot")
    setUnderAeroIconColor(blueColor)
    setUnderAeroLineColor(greyColor)
    setUnderAeroContentTitle("")
    setUnderAeroContent("")
    setUnderAeroSubTitle("")

    setUnderGettyTitle("UNDER GETTY REVIEW")
    setUnderGettyTitleColor(greyColor)
    setUnderGettyIcon("dot")
    setUnderGettyIconColor(blueColor)
    setUnderGettyLineColor(greyColor)
    setUnderGettyContentTitle("")
    setUnderGettyContent("")
    setUnderGettySubTitle("")

    setPublishedTitle("PUBLISHED")
    setPublishedTitleColor(greyColor)
    setPublishedIcon("dot")
    setPublishedIconColor(blueColor)
    setPublishedLineColor(greyColor)
    setPublishedContentTitle("")
    setPublishedContent("")
    setPublishedSubTitle("")
  }

  function updateTimeline() {

    for (let item in timeline) {

      switch (item) {
        case "PENDING_FILE_SUBMISSION": {
          if (contributionStatus === "PENDING_FILE_SUBMISSION") {
            setPendingIcon("clock")
            setPendingTitleColor("black");
            setPendingLineColor(blueColor)
          }
          else {
            setPendingIcon("check")
            setPendingIconColor(greenColor)
            setPendingTitleColor("black");
            setPendingLineColor(blueColor)
          }
          setPendingSubTitle(timeline[item].time)
          break;
        }
        case "UNDER_AEROLOGIX_REVIEW": {
          if (timeline[item].status === "UNDER_AEROLOGIX_REVIEW") {
            setUnderAeroTitleColor("black")
            setUnderAeroIcon("clock")
            setUnderAeroLineColor(blueColor)
          }
          else if (timeline[item].status === "REJECTED_AEROLOGIX") {
            setUnderAeroTitleColor(redColor)
            setUnderAeroIcon("close")
            setUnderAeroIconColor(redColor)
            setUnderAeroLineColor(redColor)
            setUnderAeroContentTitle("Rejected")
            setUnderAeroContent("Your submission has not been accepted by Aerologix and will not be uploaded to Getty. Please do not re-submit rejected media files")
          }
          else if (timeline[item].status === "REVISE_AND_RETRY_GETTY") {

            setUnderAeroTitleColor(orangeColor)
            setUnderAeroIcon("warning")
            setUnderAeroIconColor(orangeColor)
            setUnderAeroLineColor(orangeColor)
            setUnderAeroContentTitle("Revise and Retry")
            setUnderAeroContent("Changes requested by Getty under Review")
          }

          if (contributionStatus === "UNDER_GETTY_REVIEW" || contributionStatus === "REJECTED_GETTY" || contributionStatus === "PUBLISHED") {
            setUnderAeroTitleColor("black")
            setUnderAeroIcon("check")
            setUnderAeroIconColor(greenColor)
            setUnderAeroLineColor(blueColor)
          }

          setUnderAeroSubTitle(timeline[item].time)
          break;
        }
        case "UNDER_GETTY_REVIEW": {
          if (timeline[item].status === "UNDER_GETTY_REVIEW") {
            setUnderGettyTitleColor("black")
            setUnderGettyIcon("clock")
            setUnderGettyLineColor(blueColor)
          }
          else if (contributionStatus === "REJECTED_AEROLOGIX") {
            setUnderAeroIcon("clock")
          } else if (contributionStatus === "REVISE_AND_RETRY_GETTY") {

            setUnderGettyTitleColor(orangeColor)
            setUnderGettyIcon("warning")
            setUnderGettyIconColor(orangeColor)
            setUnderGettyLineColor(orangeColor)
            setUnderGettyContentTitle("Revise and Retry")
            setUnderGettyContent("Changes requested by Getty under Review")
          }

          else if (timeline[item].status === "REJECTED_GETTY") {

            setUnderGettyTitleColor(redColor)
            setUnderGettyIcon("close")
            setUnderGettyIconColor(redColor)
            setUnderGettyLineColor(redColor)
            setUnderGettyContentTitle("Rejected")
            setUnderGettyContent("Your submission has been rejected by Getty and cannot be re-uploaded")
          }

          if (contributionStatus === "PUBLISHED") {
            setUnderGettyTitleColor("black")
            setUnderGettyIcon("check")
            setUnderGettyIconColor(greenColor)
            setUnderGettyLineColor(blueColor)
          }

          setUnderGettySubTitle(timeline[item].time)
          break;
        }
        case "PUBLISHED": {
          setPublishedTitleColor("black")
          setPublishedIcon("check")
          setPublishedIconColor(greenColor)
          setPublishedLineColor(blueColor)
          setPublishedSubTitle(timeline[item].time)
          setPublishedContent(<span>Congrats! Your submission has been approved and is successfully listed on the Getty platform. If your media gets purchased on the Getty platform, <span onClick={() => { setShowHowRoyalyWorksModal(true) }} className={styles['link-text']}> here’s how royalty payouts work</span></span>)
          break;
        }

        default: {
          break;
        }
      }
    }


  }


  return (
    <div   >
      <div className={styles['status-card-header']}>
        <div> VIDEO STATUS: <span className={styles['status-card-title']}>  {name} </span>(You can view the status of a particular video by clicking on its thumbnail)</div>
        <div className={styles['learn-more']} onClick={() => { setShowLearnMoreModal(true) }}>Learn More</div>
      </div>

      <div className={styles['status-card-body']}>




        <Step title={<span className={styles['status-title']}>{pendingTitle}</span>} titleColor={pendingTitleColor}
          icon={pendingIcon} iconColor={pendingIconColor} lineColor={pendingLineColor}

          subtitle={pendingSubTitle && moment(pendingSubTitle).format('DD/MM/YYYY hh:mm A')} contentTitle={pendingContentTitle} content={pendingContent}
        ></Step>

        <Step title={<span className={styles['status-title']}>{underAeroTitle}</span>} titleColor={underAeroTitleColor}
          icon={underAeroIcon} iconColor={underAeroIconColor} lineColor={underAeroLineColor}

          subtitle={underAeroSubTitle && moment(underAeroSubTitle).format('DD/MM/YYYY hh:mm A')} contentTitle={underAeroContentTitle} content={underAeroContent}
        ></Step>

        <Step title={<span className={styles['status-title']}>{underGettyTitle}</span>} titleColor={underGettyTitleColor}
          icon={underGettyIcon} iconColor={underGettyIconColor} lineColor={underGettyLineColor}

          subtitle={underGettySubTitle && moment(underGettySubTitle).format('DD/MM/YYYY hh:mm A')} contentTitle={underGettyContentTitle} content={underGettyContent}
        ></Step>

        <Step title={<span className={styles['status-title']}>{publishedTitle}</span>} titleColor={publishedTitleColor}
          icon={publishedIcon} iconColor={publishedIconColor} lineColor={publishedLineColor}
          subtitle={publishedSubTitle && moment(publishedSubTitle).format('DD/MM/YYYY hh:mm A')} contentTitle={publishedContentTitle} content={publishedContent}

        ></Step>

      </div>

    </div>
  )
}

export default Status;