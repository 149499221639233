import { Empty, Input, Table } from 'antd'
import React from 'react'

const ReceivedPaymentsTab = props => {
	const { receivedPaymentsList, onSearch, changePage, receivedPaymentsColumns, totalReveivedPayments } = props

	return (
		<>
			<>
				<div style={{ fontSize: '16px', color: '#47464A' }}>Received Payments</div>
				<div style={{ marginTop: '20px', marginBottom: '20px' }}>
					<Input.Search placeholder='Search by Project name' onSearch={onSearch} size='large' />
				</div>
				{receivedPaymentsList && receivedPaymentsList.length ? (
					<Table
						columns={receivedPaymentsColumns}
						dataSource={receivedPaymentsList}
						pagination={{
							total: totalReveivedPayments,
							onChange: changePage,
						}}
						scroll={{
							x: 1300,
						}}
					></Table>
				) : (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No payments received yet' />
				)}
			</>
		</>
	)
}

export default ReceivedPaymentsTab
