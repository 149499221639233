/*eslint-disable*/
import React from 'react'
import { Button, DatePicker } from 'antd'
import moment from 'moment'

const { RangePicker } = DatePicker

class VacationDatePicker extends React.Component {
	disabledDate = current => {
		return current < moment().endOf('day')
	}
	render() {
		const { getFieldDecorator } = this.props.form
		const { data } = this.props
		return (
			<div style={{ marginLeft: '190px', marginBottom: '20px', marginTop: '20px' }}>
				{getFieldDecorator('vacationRangeValue', {
					initialValue: [
						moment.utc(data.vacationModeDetails ? data.vacationModeDetails.from : '2020-10-05T23:42:17.000+0000').local(),
						moment.utc(data.vacationModeDetails ? data.vacationModeDetails.to : '2020-10-06T10:48:57.000+0000').local(),
					],
				})(
					<RangePicker
						disabledDate={this.disabledDate}
						renderExtraFooter={() => (
							<Button type='primary' onClick={this.props.storeVacationDate}>
								Ok
							</Button>
						)}
						disabled={this.props.vacationDateView}
						open={this.props.vacationDatePicker}
						bordered={false}
						format='DD/MM/YYYY'
						allowClear={false}
					/>,
				)}
			</div>
		)
	}
}

export default VacationDatePicker
