import mixpanel from 'mixpanel-browser'

// Mixpanel initialization
mixpanel.init(process.env.REACT_APP_MIXPANEL_APP_ID, { debug: process.env.REACT_APP_MIXPANEL_DEBUG_VALUE })

export const pushEventToMixpanel = (profileType, eventName, payload) => {
	if (profileType === 'CONSUMER') {
		profileType = 'Client '
	} else if (profileType === 'PILOT') {
		profileType = 'Pilot '
	}
	const event = profileType + ' ' + eventName
	mixpanel.track(event, payload)
}

export const setUserIdentityToMixpanel = userId => {
	mixpanel.identify(`${userId}`)
}

export const pushSuperPropertiesToMixpanel = payload => {
	mixpanel.register(payload)
}

export const pushProfileToMixpanel = payload => {
	mixpanel.people.set(payload)
}

export const removeProfilePropertiesFromMixpanel = payload => {
	mixpanel.people.unset(payload)
}

export const resetMixpanel = () => {
	mixpanel.reset()
}
