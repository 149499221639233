import { Button, Descriptions, Divider } from 'antd'
import React from 'react'
import moment from 'moment'
import VerificationTag from '../verification-tag'
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons'

const PurchasedInsurances = props => {
	const { allPurchasedInsurance, toggleDeleteInsuranceModal, toggleRetryInsuranceModal } = props
	const compareDate = value => {
		if (
			moment
				.utc(value)
				.local()
				.diff(moment(), 'days') <= 30 &&
			moment
				.utc(value)
				.local()
				.diff(moment(), 'days') >= 0
		) {
			return 'Expiring'
		} else if (
			moment
				.utc(value)
				.local()
				.diff(moment(), 'days') < 0
		) {
			return 'Expired'
		}
		return null
	}
	return (
		<>
			{allPurchasedInsurance.map(item => (
				<React.Fragment key={item.id}>
					{compareDate(item.expiryDate) === 'Expiring' ? (
						<div className='insurance-banner' style={{ background: '#F97855' }}>
							<ExclamationCircleFilled style={{ position: 'relative', bottom: '3px', marginRight: '5px', marginLeft: '5px' }} /> Attention! Your drone insurance is
							about to expire. Please renew and submit your updated drone insurance.
						</div>
					) : compareDate(item.expiryDate) === 'Expired' ? (
						<div className='insurance-banner' style={{ background: '#F75151' }}>
							<ExclamationCircleFilled style={{ position: 'relative', bottom: '3px', marginRight: '5px', marginLeft: '5px' }} /> We see that your insurance has
							expired. Please renew and submit your updated drone insurance.
						</div>
					) : null}

					<div className='submod-card-header'>
						<div style={{ marginBottom: '25px' }}>
							<strong>{item.insuranceName}</strong>
							<VerificationTag verificationState={item.verificationState} />
						</div>

						<div>
							{item.verificationState === 'VERIFICATION_FAILED' ? (
								<Button style={{ marginRight: '10px' }} type='primary' onClick={() => toggleRetryInsuranceModal(item)}>
									<EditOutlined style={{ position: 'relative', bottom: '3px' }} />
								</Button>
							) : null}
							<Button className=' submod-card-header-btn' onClick={() => toggleDeleteInsuranceModal(item)}>
								<DeleteOutlined style={{ position: 'relative', bottom: '3px' }} />
							</Button>
						</div>
					</div>
					<Descriptions labelStyle={{ color: '#84818A' }}>
						<Descriptions.Item label='Insured Amount'>{item.amount}</Descriptions.Item>
						<Descriptions.Item label={compareDate(item.expiryDate) === 'Expired' ? 'Expired on' : 'Expiring on'}>
							{moment
								.utc(item.expiryDate)
								.local()
								.format('DD MMM YYYY')}
						</Descriptions.Item>

						<Descriptions.Item label='License'>
							<a style={{ color: '#00B3FF' }} href={item.url} target='_blank'>
								Click to download
							</a>
						</Descriptions.Item>
					</Descriptions>
					{item.moreInfo ? (
						<>
							<p style={{ color: '#84818A' }}>More Info: {item.moreInfo}</p>
						</>
					) : null}
					{item.verificationState === 'VERIFICATION_FAILED' ? (
						<>
							<Divider></Divider>
							<p>Reason for Insurance Rejection: {item.failedVerificationReason}</p>
						</>
					) : null}
					<Divider></Divider>
				</React.Fragment>
			))}
		</>
	)
}

export default PurchasedInsurances
