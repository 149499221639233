import React, { Component } from 'react'
import { connect } from 'react-redux'
import './contactUs.sass'
import { Collapse, Button, Divider } from 'antd'
import { CONTACTUS_PILOT, CONTACTUS_CONSUMER } from './contactUs.constants'
import { getValueFromStorage } from '../../Services/LocalStorageService'

const { Panel } = Collapse

class ContactUs extends Component {
	render() {
		const CONTACTUS = getValueFromStorage('PROFILE_TYPE') === 'PILOT' ? CONTACTUS_PILOT : CONTACTUS_CONSUMER
		return (
			<div className='flex-container'>
				<div className='projects-heading' style={{ marginLeft: '30px', marginBottom: '20px' }}>
					Frequently Asked Questions
				</div>

				<Divider></Divider>
				<Collapse style={{ width: '100%', borderRadius: '8px' }} accordion>
					{CONTACTUS.map(section => (
						<Collapse style={{ width: '100%' }} accordion key={section.sectionName}>
							<Panel header={section.sectionName}>
								{section.sectionQuestions.map(question => (
									<Collapse style={{ width: '100%' }} accordion key={question.id}>
										<Panel header={question.panelHeader}>
											{question.panelBody.map((bodyText, i) => {
												if (typeof bodyText === 'string') {
													return <p key={i}>{bodyText}</p>
												} else {
													if (bodyText[0] === 'ordered') {
														return bodyText.slice(1).map((_, i) => <p key={i}>{`${i + 1}. ${_}`}</p>)
													} else {
														return bodyText.slice(1).map((_, i) => <p key={i}>{`\u2022 ${_}`}</p>)
													}
												}
											})}
										</Panel>
									</Collapse>
								))}
							</Panel>
						</Collapse>
					))}
				</Collapse>
				<div style={{ padding: '40px' }}>
					If your query still persists, do not hesitate to reach out to us on{' '}
					<Button style={{ position: 'relative', right: '12px', fontSize: '16px' }} type='link' href='mailto:support@aerologix.com'>
						support@aerologix.com
					</Button>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {}
}

const mapDispatchToProps = dispatch => {
	// ... normally is an object full of action creators
	return {}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContactUs)
