import React, { Component } from 'react'
import '../projects.sass'
import { Card, Descriptions, Divider } from 'antd'
import moment from 'moment'
import { getConfigData } from '../../../Services/LocalStorageService'

export default class PaymentHistoryCard extends Component {
	render() {
		const { data, profileType } = this.props
		return (
			<Card style={{ marginTop: '20px' }} type='inner' title='Payment History'>
				{data.map((data, index) => (
					<React.Fragment key={index}>
						<Descriptions labelStyle={{ color: '#84818A' }} style={{ marginTop: '20px' }}>
							<Descriptions.Item label='Transaction Date'>
								{moment
									.utc(data.transactionDate)
									.local()
									.format('DD/MM/YYYY')}
							</Descriptions.Item>
							{profileType === 'CONSUMER' ? <Descriptions.Item label='Project Cost'>{getConfigData().currency + ' ' + data.projectCost}</Descriptions.Item> : null}
							<Descriptions.Item label='Amount Paid'>
								{getConfigData().currency} {profileType === 'PILOT' ? data.amount : data.amountPaid}
							</Descriptions.Item>
						</Descriptions>
						<Divider></Divider>
					</React.Fragment>
				))}
			</Card>
		)
	}
}
