export const GET_ALL_PLANS = 'GET_ALL_PLANS'
export const GET_ALL_ACTIVE_PLANS = 'GET_ALL_ACTIVE_PLANS'
export const GET_ALL_EXPIRED_PLANS = 'GET_ALL_EXPIRED_PLANS'
export const GET_ALL_AEROFLY_DRONES = 'GET_ALL_AEROFLY_DRONES'
export const CUSTOM_PLAN_ENQUIRY = 'CUSTOM_PLAN_ENQUIRY'
export const REQUEST_NEW_DRONE = 'REQUEST_NEW_DRONE'
export const CREATE_AEROFLY_TRANSACTION = 'CREATE_AEROFLY_TRANSACTION'
export const AEROFLY_PAYMENT = 'AEROFLY_PAYMENT'
export const GET_AEROFLY_BANNER_DETAILS = 'GET_AEROFLY_BANNER_DETAILS'
export const POST_ISSUE_FACED = 'POST_ISSUE_FACED'
export const GENERATE_OTP = 'GENERATE_OTP'
export const INITIATE_FREE_TRIAL = 'INITIATE_FREE_TRIAL'
export const ACTIVATE_FREE_TRIAL = 'ACTIVATE_FREE_TRIAL'
