import { Radio } from 'antd'
import React from 'react'
import { NZ_QUIZ_QUESTIONS } from '../../constants'

const NzQuiz = props => {
	const { currentIndex, captureResponse, currentResponse } = props
	const data = NZ_QUIZ_QUESTIONS[currentIndex]
	return (
		<>
			<div style={{ fontSize: '16px', marginBottom: '15px' }}>
				<strong>
					{data.key}. {data.question}
				</strong>
			</div>
			<Radio.Group value={currentResponse} onChange={captureResponse} style={{ width: '100%' }}>
				{data.options.map(item => (
					<div key={item.id} className='nz-quiz-option-wrap'>
						<Radio value={item.id}>{item.text}</Radio>
					</div>
				))}
			</Radio.Group>
		</>
	)
}

export default NzQuiz
