import typeToReducer from 'type-to-reducer'
import * as ACTION from './actionTypes'
import { message } from 'antd'

const initialState = {
	allPlans: null,
	allActivePlans: null,
	allExpiredPlans: null,
	allAeroFlyDrones: null,
	newDroneRequestSuccessful: null,
	enquiryRequestSuccessful: null,
	transactionData: null,
	aeroFlyPaymentSuccess: null,
	aeroFLyBannerData: null,
	issuePostSuccessful: null,
	verificationData: null,
	freeTrialActivated: null,
}

const MyPlansReducer = typeToReducer(
	{
		[ACTION.GET_ALL_PLANS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						allPlans: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ALL_ACTIVE_PLANS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						allActivePlans: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ALL_EXPIRED_PLANS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						allExpiredPlans: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_ALL_AEROFLY_DRONES]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						allAeroFlyDrones: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.REQUEST_NEW_DRONE]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Request for a new drone has been sent!')
					return Object.assign({}, state, {
						newDroneRequestSuccessful: action.payload && action.payload.data ? Math.random() : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.CUSTOM_PLAN_ENQUIRY]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					message.success('Custom plan enquiry has been sent!')
					return Object.assign({}, state, {
						enquiryRequestSuccessful: action.payload && action.payload.data ? Math.random() : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.CREATE_AEROFLY_TRANSACTION]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						transactionData: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.AEROFLY_PAYMENT]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						aeroFlyPaymentSuccess: action.payload && action.payload.data ? Math.random() : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GET_AEROFLY_BANNER_DETAILS]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						aeroFLyBannerData: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.POST_ISSUE_FACED]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						issuePostSuccessful: action.payload && action.payload.data ? Math.random() : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.GENERATE_OTP]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.INITIATE_FREE_TRIAL]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						verificationData: action.payload && action.payload.data ? action.payload.data.verificationId : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
		[ACTION.ACTIVATE_FREE_TRIAL]: {
			PENDING: state => {
				return Object.assign({}, state, {})
			},
			FULFILLED: (state, action) => {
				if (action.payload && action.payload.status_code === 200) {
					return Object.assign({}, state, {
						freeTrialActivated: action.payload && action.payload.data ? action.payload.data : null,
					})
				} else if (action.payload && action.payload.status_code !== 200) {
					message.error(action.payload.status_message)
				} else {
					message.error('Something went wrong!')
					return Object.assign({}, state, {})
				}
			},
			REJECTED: state => {
				return Object.assign({}, state, {})
			},
		},
	},
	initialState,
)

export default MyPlansReducer
