
import React from 'react'
import styles from './GettyReasonModal.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'
import { Button } from "antd";

function GettyReasonModal(props) {

  // extract props
  const {
    setshowGettyReasonModal, // from parent  
    showGettyReasonModal,
    title,
    content

  } = props


  return (
    <AeroModal
      bodyStyle={{ backgroundColor: '#F5F5F5', borderRadius: '0px 0px 12px 12px' }}
      title={title} footer={false} onCancel={() => { setshowGettyReasonModal(false) }} visible={showGettyReasonModal}>
      <div   >

        <div>
          {content}

          <br />
          <br />
          <Button block className={styles['button']} type='primary' onClick={() => { setshowGettyReasonModal(false) }}>Okay, Got it</Button>

        </div>

      </div>
    </AeroModal>
  )
}

export default GettyReasonModal;