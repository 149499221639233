/*eslint-disable*/
import React from 'react'
import { Button, Divider } from 'antd'
import moment from 'moment'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { PlusCircleOutlined } from '@ant-design/icons'

class EnterprisePilotPreferences extends React.Component {
	render() {
		const { data } = this.props
		return (
			<React.Fragment>
				<div style={{ fontSize: '16px', color: '#47464A' }}>Enterprise Pilot Preferences</div>
				<div style={{ color: '#84818A', marginTop: '10px', marginBottom: '20px' }}>This will help Aerologix to provide you with Enterprise projects of your interest</div>
				<Button
					style={{ height: '48px', marginBottom: '20px', marginTop: '20px' }}
					type='primary'
					onClick={
						data?.verification?.state === 'NOT_INITIATED'
							? this.props.openCreateEnterprisePilotPopup
							: data?.verification?.state === 'VERIFICATION_SUCCEEDED'
								? this.props.handleUpdatePopupOpen
								: null
					}
				>
					<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} />
					Update Preferences
				</Button>
				{data.verification.state === 'NOT_INITIATED' ? (
					<div style={{ textAlign: 'center', color: '#8C8C8C', fontSize: '16px' }}>No details has been added yet</div>
				) : data.verification.state === 'PENDING_VERIFICATION' ? (
					<div style={{ fontSize: '12px', color: '#8C8C8C' }}>
						<MatImage src={IMAGES_SRC.CLOCK_ICON} /> &nbsp;
						<i>Verification Pending</i>
					</div>
				) : data.verification.state === 'VERIFICATION_FAILED' ? (
					<div>
						<span style={{ fontSize: '12px', color: 'red' }}>
							<MatImage src={IMAGES_SRC.CLOSE_CIRCLE} />
							&nbsp;<i>Verification failed</i>
						</span>
						<span style={{ marginLeft: '15px' }}>
							<Button onClick={this.props.openCreateEnterprisePilotPopup} style={{ width: '120px', borderRadius: '8px' }} type='primary'>
								Retry
							</Button>
						</span>
						<Divider></Divider>
						<div>
							<span style={{ color: '#8C8C8C' }}>Reason For Rejection: </span>
							<span style={{ color: '#262626' }}>{data.verification.message}</span>
						</div>
					</div>
				) : data.verification.state === 'VERIFICATION_SUCCEEDED' ? (
					data.vacationMode ? (
						<div style={{ fontSize: '16px' }}>
							{moment
								.utc(data.vacationModeDetails.from)
								.local()
								.format('DD/MM/YYYY') +
								'  -  ' +
								moment
									.utc(data.vacationModeDetails.to)
									.local()
									.format('DD/MM/YYYY')}
							<div className='vacation-mode-on-tag'>
								<MatImage src={IMAGES_SRC.VACATION_MODE_ON_ICON} /> &nbsp;Vacation Mode is on
							</div>
						</div>
					) : (
						<div style={{ color: '#8C8C8C' }}>
							{' '}
							<MatImage src={IMAGES_SRC.VACATION_MODE_OFF_ICON} /> &nbsp;Vacation mode is off
						</div>
					)
				) : null}
			</React.Fragment>
		)
	}
}

export default EnterprisePilotPreferences
