import React, { useEffect, useState } from 'react'
import './side-bar-menu.sass'
import { getConfigData, getValueFromStorage } from '../../Services/LocalStorageService'
import { SIDE_MENU_LIST } from './side-bar-menu-constants'
import WebSidebar from './web-sidebar'
import MobileSidebar from './mobile-sidebar'
import { logOut } from '../../Stores/UserSession/action'

const SideBarNavigation = props => {
	const { profileData } = props
	const profileType = getValueFromStorage('PROFILE_TYPE')
	const subrole = getValueFromStorage('SUBROLE')
	const permissions = getValueFromStorage('PERMISSIONS')
	const webConfig = getConfigData()
	const modules = profileType === 'PILOT' ? webConfig.pilotModules[0].webConfig.map(item => item.modKey) : webConfig.consumerModules[0].webConfig.map(item => item.modKey)
	const profileIncomplete = profileData && !profileData['completed']
	const paymentIncomplete = profileData && (!profileData['businessNumber'] || !profileData['paymentDetailsAdded'])
	const [collaboratorMenuList, setCollaboratorMenuList] = useState([])

	const renderCollaboratorsMenu = () => {
		if (permissions) {
			const permissionsList = permissions.split(',')
			const menuList = SIDE_MENU_LIST.CLIENT_COLLABORATOR.filter(item =>
				permissionsList.includes(item.key) ? { key: item.key, icon: item.icon, pathName: item.pathName, displayValue: item.displayValue } : null,
			)

			setCollaboratorMenuList(menuList)
		}
	}

	useEffect(() => renderCollaboratorsMenu(), [])

	return (
		<>
			<WebSidebar
				permissions={permissions}
				collaboratorMenuList={collaboratorMenuList}
				subrole={subrole}
				profileType={profileType}
				profileIncomplete={profileIncomplete}
				paymentIncomplete={paymentIncomplete}
				logOut={logOut}
				modules={modules}
				{...props}
			/>
			<MobileSidebar
				permissions={permissions}
				collaboratorMenuList={collaboratorMenuList}
				subrole={subrole}
				profileType={profileType}
				profileIncomplete={profileIncomplete}
				paymentIncomplete={paymentIncomplete}
				logOut={logOut}
				modules={modules}
				{...props}
			/>
		</>
	)
}

export default SideBarNavigation
