let AppNavigator

export function setNavigation(routerHistoryObj) {
	AppNavigator = routerHistoryObj
}

export function goTo(key, data) {
	AppNavigator.push({ pathname: `/${key}`, state: data })
}

export function goToWithQueryParam(key,params, data) {
	AppNavigator.push({ pathname: `/${key}`, search: params, state: data })
}

export function gotToLogIn() {
	AppNavigator.push(`/`)
}
