import React from 'react'
import { Button, Card } from 'antd'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'

const EnterprisePlusPilotBanner = ({ profileData, hideEnterprisePlusBanner, openPopup }) => {
	return (
		<Card className='enterprise_plus-banner'>
			<div className='enterprise_plus_main'>
				<div>
					<MatImage className='enterprise_plus-banner-img' src={IMAGES_SRC.ENTERPRISE_PILOT_PLUS_SUCCESS} style={{ width: '40px', height: '40px' }} />
				</div>
				<div className='enterprise_plus_data'>
					<div className='enterprise_plus_data-name' style={{ fontSize: '24px' }}>
						Congratulations {profileData?.firstName}!
					</div>
					<span style={{ fontSize: '18px' }}>
						You have been selected for{' '}
						<span className='enterprise_plus_data-gradiant'>
							<b style={{ fontWeight: 900 }}>Enterprise</b>
						</span>{' '}
						<div className='enterprise_plus_data-gradiantPlus'>
							<div className='enterprise_plus_data-gradiantPlus_sub'>+</div>
						</div>{' '}
						program
					</span>
					<div className='enterprise_plus_banner_buttons'>
						<Button className='enterprise_plus_banner_buttons-first' ghost onClick={hideEnterprisePlusBanner}>
							Remind me later
						</Button>
						<Button className='enterprise_plus_banner_buttons-second' onClick={openPopup}>
							Join Now
						</Button>
					</div>
				</div>
				<div className='enterprise_plus-banner-logo'>
					<MatImage src={IMAGES_SRC.ENTERPRISE_PLUS_BANNER_LOGO} />
				</div>
			</div>
		</Card>
	)
}

export default EnterprisePlusPilotBanner
