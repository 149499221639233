import { Divider, List, Skeleton } from 'antd'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import EnterpriseProjectsCard from '../../EnterpriseProjects/components/enterprise-projects-card'
import ClientProjectsCard from './client-projects-card'

const ScrollableContent = props => {
	const { loadMoreData, data, totalData, divId, type, openProjectDetails, profileType } = props
	return (
		<InfiniteScroll
			dataLength={data.length}
			next={() => loadMoreData(type)}
			hasMore={data.length < totalData}
			loader={
				<Skeleton
					paragraph={{
						rows: 3,
					}}
					active
				/>
			}
			endMessage={<Divider></Divider>}
			scrollableTarget={divId}
		>
			{console.log(data.length, totalData)}
			<List
				dataSource={data}
				renderItem={item => (
					<List.Item
						className='project-card-wrapper'
						onClick={() => openProjectDetails(profileType === 'CONSUMER' ? item.projectId : item.project.id)}
						key={profileType === 'CONSUMER' ? item.projectId : item.project.id}
					>
						{(profileType === 'PILOT' && item.project.enterprise_project) || (profileType === 'CONSUMER' && item.enterprise_project) ? (
							<EnterpriseProjectsCard data={item.project} tabType={type} />
						) : (
							<ClientProjectsCard data={profileType === 'CONSUMER' ? item : item.project} tabType={type} profileType={profileType} />
						)}
					</List.Item>
				)}
			/>
		</InfiniteScroll>
	)
}

export default ScrollableContent
