/*eslint-disable*/
import React from 'react'
import './on-boarding.sass'
import MatImage from '../../AeroComponents/Image'
import MatText from '../../AeroComponents/Text'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { TEXTS } from './on-boarding.constants'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Row, Col, Modal, Button, Divider, Spin } from 'antd'
import { setValueToStorage, getValueFromStorage } from '../../Services/LocalStorageService'
import { goTo } from '../../Services/NavigationService'
import { socialMediaLogin } from '../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEmpty } from 'lodash'
import AppleLogin from 'react-apple-login'
import { urlConfig } from '../../Services/UtilityService'

const BACK_BTN_TEXT = 'Log In'
class OnBoarding extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			profileType: '',
			referralResponse: {},
			loading: false,
			countryId: urlConfig().countryId,
		}
	}

	componentDidMount() {
		const respones = this.props.location.state
		if (!isEmpty(respones)) {
			this.setState({ referralResponse: respones })
			this.openSignup('PILOT')
		}
	}

	goTo = () => {
		this.props.history.push({ pathname: '/signup', state: { referralResponse: this.state.referralResponse } })
	}

	openSignup = profileType => {
		setValueToStorage('PROFILE_TYPE', profileType)
		const viewableType = profileType === 'CONSUMER' ? 'Client' : profileType[0] + profileType.slice(1).toLowerCase()
		this.setState({ profileType: viewableType })
		goTo('signup-select')
	}

	handleCancel = e => {
		this.setState({
			visible: false,
		})
	}

	redirectSocial = social_type => {
		this.setState({ loading: true })
		const redirection = urlConfig().redirection ? '?redirection=' + urlConfig().redirection : ''
		const redirect_endpoint = process.env.REACT_APP_BASE_URL + 'v1/login/' + social_type + '/' + getValueFromStorage('PROFILE_TYPE') + redirection
		window.location.replace(redirect_endpoint)
	}

	render() {
		const { referralResponse, loading, countryId, profileType } = this.state
		return (
			<div>
				<Row className='flex-container'>
					<Col span={12}>
						<div className='logo-box'>
							<MatImage src={IMAGES_SRC.LOGO_BLACK} />
						</div>
					</Col>
				</Row>

				<div className='flex-container-row'>
					<div style={{ cursor: 'pointer' }} className='onboarding-card'>
						<div
							className='onboarding-card-content'
							onClick={() => {
								this.openSignup('PILOT')
							}}
						>
							<MatImage src={IMAGES_SRC.PILOT_ICON} />
							<Row className='onboarding-text'>
								<Col span={16}>
									<span>{TEXTS.SIGNUP_LOGIN_PILOT}</span>
								</Col>
								<Col span={4} offset={4}>
									<ArrowRightOutlined />
								</Col>
							</Row>
						</div>
					</div>
					<div className='onboarding-card' style={{ cursor: 'pointer' }}>
						<div className='onboarding-card-content' onClick={() => this.openSignup('CONSUMER')}>
							<MatImage src={IMAGES_SRC.CONSUMER_ICON} />
							<Row className='onboarding-text'>
								<Col span={16}>
									<span>{TEXTS.SIGNUP_LOGIN_CONSUMER}</span>
								</Col>
								<Col span={4} offset={4}>
									<ArrowRightOutlined />
								</Col>
							</Row>
						</div>
					</div>
				</div>
				<Modal width={700} title={profileType + ' Signup'} footer={null} open={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
					{!isEmpty(referralResponse) ? (
						<div className='referral-block'>
							<h2>Get access to drone jobs in the vicinity and earn money at your convenience!</h2>
							<div>Don't just own a drone, monetize it</div>
							<div className='box-wrapper'>
								<div className='invitee-block'>
									<MatImage src={IMAGES_SRC.SMALL_GIFT_ICON} /> Invited by {referralResponse.firstName}
								</div>
							</div>
						</div>
					) : (
						''
					)}
					<p>
						<Button onClick={this.goTo} type='primary' block>
							Sign Up with Email
						</Button>
					</p>
					<p>
						<Divider>Or Sign Up with</Divider>
					</p>
					<Spin spinning={loading}>
						<p>
							<span className='social-btn-box'>
								<Button
									onClick={() => {
										this.redirectSocial('FACEBOOK')
									}}
									className='fb-btn-up'
								>
									<MatImage src={IMAGES_SRC.FACEBOOK_F} />
									&nbsp; Continue with facebook
								</Button>
							</span>
							<span className='social-btn-box'>
								<Button
									onClick={() => {
										this.redirectSocial('GOOGLE')
									}}
									className='google-btn-up'
								>
									<MatImage src={IMAGES_SRC.GOOGLE_G} />
									&nbsp; Continue with Google
								</Button>
							</span>
						</p>
						<p>
							<span style={{ marginLeft: '165px' }} className='social-btn-box'>
								<div className='apple-btn'>
									<AppleLogin
										designProp={{ height: 50, width: 250, type: 'continue' }}
										clientId='com.aerologix.client'
										redirectURI={process.env.REACT_APP_BASE_URL + urlConfig().appleUrl + getValueFromStorage('PROFILE_TYPE')}
										scope='email%20name'
										responseType='code%20id_token'
										responseMode='form_post'
									/>
								</div>
							</span>
						</p>
					</Spin>

					<p>
						<div style={{ paddingLeft: '230px', paddingTop: '20px' }}>
							<MatText inline text={'Already Registered ? '}></MatText>
							<Button
								type='link'
								onClick={() => {
									goTo('login')
								}}
							>
								{BACK_BTN_TEXT}
							</Button>
						</div>
					</p>
				</Modal>
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		socialMediaLogin: bindActionCreators(socialMediaLogin, dispatch),
	}
}

export default connect(
	null,
	mapDispatchToProps,
)(OnBoarding)
