import React from 'react'
import {  Input } from 'antd'
 
const { Search } = Input


function AeroSearch(props) {
    
    return (
        <Search {...props} />
    )
}

export default AeroSearch
