import React from 'react'
import { Divider } from 'antd'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
const S3Downloader = props => {
	const { dataType, urlData, currentState } = props
	return (
		<div className='view-project-details'>
			<div className='active-card-header'>
				<div className='card-header-content'>
					<div className='heading-active'>PROJECT DATA ({dataType})</div>
				</div>
			</div>
			<div className='card-content'>
				<a href={urlData}>
					<MatImage src={IMAGES_SRC.ZIP_ICON} />
				</a>
				{currentState === 'READY_TO_REVIEW' ? (
					<>
						<Divider></Divider>
						<div>
							This is only a Preview file. If you're satisfied with the Preview data, please click the "Approve" button and pay the remaining project cost in order to
							access the Complete data.
						</div>
					</>
				) : null}
			</div>
		</div>
	)
}

export default S3Downloader
