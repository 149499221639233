import React from 'react'

import moment from 'moment'
import { PROJECT_TYPE } from '../../constants'
import ProjectStatusCard from './ProjectStatusCard'

export default function ScheduledProjectsCards(props) {
	// extract props
	const { index, scheduledProjects, openAdditionalDataModal } = props

	// if current index is lesser than scheduled projects length
	if (!(index > scheduledProjects.length - 1)) {
		const mapLink = 'https://www.google.com/maps/place/' + scheduledProjects[index].latitude + ',' + scheduledProjects[index].longitude

		return (
			<ProjectStatusCard
				name={scheduledProjects[index].name}
				mapLink={mapLink}
				openAdditionalDataModal={openAdditionalDataModal}
				address={scheduledProjects[index].address.substring(0, 15) + '...'}
				status='scheduled'
				projectType={PROJECT_TYPE[scheduledProjects[index].type]}
				scheduledDate={moment(scheduledProjects[index].deadline).format('DD/MM/YYYY')}
				projectData={scheduledProjects[index]}
			></ProjectStatusCard>
		)
	}
	// else return empty
	else return ''
}
