import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from '../../orders.module.scss'
import { Breadcrumb, Spin, message } from 'antd'
import { getBoxCreds, downloadBoxFolder, getProjectForConsumer } from '../../../../Stores/Projects/action'
import { getValueFromStorage, setValueToStorage } from '../../../../Services/LocalStorageService'
import moment from 'moment'
import BoxDownloader from '../../../Projects/Components/box-downloader'
import { goTo } from '../../../../Services/NavigationService'

class ProjectData extends Component {
	constructor(props) {
		super(props)
		this.state = {
			projectId: props.match.params.projectId,
			projectData: {},
			boxCreds: null,
			startTime: moment(),
			checkUploadDataFolderName: 'Data',
			checkUploadData: '',
		}

		if (getValueFromStorage('localStore/0/bce.defaultViewMode')) {
			setValueToStorage('localStore/0/bce.defaultViewMode', '"grid"')
		}
	}
	componentDidMount() {
		this.props.getBoxCreds({ data: this.state.projectId })
		this.props.getProjectForConsumer({ consumer: this.state.projectId })
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.consumerProjectData !== nextProps.consumerProjectData) {
			this.setState({
				projectData: nextProps.consumerProjectData,
			})
		}
		if (this.props.boxCreds !== nextProps.boxCreds) {
			if (nextProps.boxCreds) {
				this.setState({ boxCreds: nextProps.boxCreds })
			}
		}
		if (this.props.downloadBoxFolderUrl !== nextProps.downloadBoxFolderUrl) {
			if (nextProps.downloadBoxFolderUrl) {
				message.success('Your download will begin now!')
				window.location.replace(nextProps.downloadBoxFolderUrl)
			}
		}
	}

	downloadFolderFromBox = () => {
		this.props.downloadBoxFolder({
			folder_id: this.props.boxCreds.dataFolderId,
			project_id: this.state.projectId,
			accessToken: this.props.boxCreds.accessToken,
		})
	}

	boxTokenGenerator = () => {
		const duration = moment.duration(moment().diff(this.state.startTime))
		if (duration.asHours() >= 1) {
			return this.props
				.getBoxCreds({ data: this.state.projectId })
				.then(res => {
					this.setState({ startTime: moment() })
					return res?.value?.data?.accessToken
				})
				.catch(() => {
					this.setState({ startTime: moment() })
					return this.state.boxCreds.accessToken
				})
		} else return this.state.boxCreds.accessToken
	}

	currentFolderFlag = (event, folderType) => {
		this.setState({ [folderType]: event.id, [folderType + 'FolderName']: event.name })
	}

	render() {
		const { projectData, boxCreds } = this.state

		return (
			<div className={styles['page-container']}>
				<div className={styles['header']}>My Orders</div>
				<Breadcrumb>
					<Breadcrumb.Item>
						<span style={{ cursor: 'pointer' }} onClick={() => goTo('enterprise/orders')}>
							Home
						</span>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<span style={{ cursor: 'pointer' }} onClick={() => goTo(`enterprise/orders/order-details/${projectData.order_id}`, { selectedTab: 'data' })}>
							{projectData.orderName}
						</span>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<span>{projectData.name}</span>
					</Breadcrumb.Item>
				</Breadcrumb>

				{boxCreds ? (
					<BoxDownloader
						boxCreds={boxCreds}
						dataType={'Complete'}
						currentFolderFlag={this.currentFolderFlag}
						downloadFolder={this.downloadFolderFromBox}
						folderName={'Data'}
						returnToken={this.boxTokenGenerator}
					/>
				) : (
					<Spin spinning={true}></Spin>
				)}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		consumerProjectData: state.ProjectsReducer.consumerProjectData,
		boxCreds: state.ProjectsReducer.boxCreds,
		downloadBoxFolderUrl: state.ProjectsReducer.downloadBoxFolderUrl,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getBoxCreds: bindActionCreators(getBoxCreds, dispatch),
		downloadBoxFolder: bindActionCreators(downloadBoxFolder, dispatch),
		getProjectForConsumer: bindActionCreators(getProjectForConsumer, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProjectData)
