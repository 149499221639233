import React from 'react'
import { Button, Drawer } from 'antd'
import { CloseOutlined, PoweroffOutlined } from '@ant-design/icons'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { BTN_LOG_OUT, SIDE_MENU_LIST } from './side-bar-menu-constants'
import MobileBarItem from './mobile-bar-item'

const MobileSidebar = props => {
	const { handleHamburger, mobileSidebarVisible, permissions, collaboratorMenuList, subrole, profileType, modules, profileIncomplete, paymentIncomplete, logOut } = props
	const currentPathName = window.location.pathname
	const generalModules = SIDE_MENU_LIST[profileType].filter(menu => modules.includes(menu.key))
	const itemMapper = permissions ? collaboratorMenuList : subrole && subrole in SIDE_MENU_LIST ? SIDE_MENU_LIST[subrole] : generalModules
	return (
		<Drawer
			bodyStyle={{ background: '#F1F3F4' }}
			onClose={handleHamburger}
			closeIcon={<CloseOutlined style={{ position: 'relative', left: '300px' }} />}
			placement='left'
			title={<MatImage src={IMAGES_SRC.SIDEBAR_LOGO} height='53' width='170' />}
			open={mobileSidebarVisible}
			key='left'
		>
			{itemMapper.map(menu => (
				<MobileBarItem
					menu={menu}
					currentPathName={currentPathName}
					handleHamburger={handleHamburger}
					paymentIncomplete={paymentIncomplete}
					profileIncomplete={profileIncomplete}
					profileType={profileType}
				/>
			))}
			<div style={{ position: 'fixed', bottom: '7%', textAlign: 'center' }}>
				<Button
					type='link'
					target='_blank'
					rel='noopener noreferrer'
					href={`https://aerologix.com/${profileType === 'PILOT' ? 'terms' : 'client-terms'}`}
					style={{ color: '#84818A', fontSize: '10px' }}
				>
					Terms and Conditions
				</Button>
			</div>

			<div style={{ position: 'fixed', bottom: '1%', textAlign: 'center' }}>
				<Button onClick={() => logOut()} type='primary' style={{ background: '#FFFFFF', border: '2px solid #00B3FF', borderRadius: '12px', height: '48px', width: '290%' }}>
					<PoweroffOutlined style={{ color: '#1890ff', fontSize: '16px', position: 'relative', bottom: '4px', right: '5px' }} />
					<span style={{ marginLeft: '5px', color: '#1890ff', fontSize: '16px' }}>{BTN_LOG_OUT}</span>
				</Button>
			</div>
		</Drawer>
	)
}

export default MobileSidebar
