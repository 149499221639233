import React from 'react'
import {  Empty } from 'antd'
 
 


function AeroEmpty(props) {
    
    return (
        <Empty {...props} >
            {props.children}
            </Empty>
    )
}

export default AeroEmpty





