import React from 'react'
import { Table } from 'antd'
const columns = [
	{
		title: 'Metadata Field',
		dataIndex: 'metadataField',
		key: 'metadataField',
	},
	{
		title: 'Notes',
		dataIndex: 'notes',
		key: 'notes',
	},
]

const dataSource = [
	{
		key: '1',
		metadataField: 'Editorial Video Headline',
		notes: (
			<span>
				Titles should be capitalized.
				<br /> Eg. "Aerial drone views of Sydney Harbour" is not correct. "Aerial Drone Views Of Sydney Harbour" is correct
			</span>
		),
	},
	{
		key: '2',
		metadataField: 'Editorial Video Description',
		notes: <span>All Editorial Video Descriptions should follow this format 
      <br />CITY, COUNTRY - DATE: Insert two lines about the shot explaining the subject of the shot  the more descriptive the
       better your chances are of a sale. Make sure to add the date and location to the description.
       <br/>Eg. SYDNEY, AUSTRALIA - 2 FEB 2022: Aerial views of the hills overlooking Manly beach and the surrounding vicinity 
       on Feb 2nd 2022 in Sydney, Australia. The iconic Sydney skyline and the instantly recognizable Sydney Opera House can be
        seen in the background peeking out of the clouds in this wide-angle shot.
    </span>
	},
  {
		key: '3',
		metadataField: 'Country of Shoot',
		notes: <span>Select from dropdown list - must match Editorial Video Description
      <br/>Eg. Australia
    </span>
	},
  {
		key: '4',
		metadataField: 'City',
		notes: <span>Eg. Sydney
    </span>
	},
  {
		key: '5',
		metadataField: 'Province/ State',
		notes: <span>Eg. NSW
    </span>
	},
  {
		key: '6',
		metadataField: 'Clip Type',
		notes: <span>Produced - A video containing two or more different shots and angles that are edited into the single video
      <br/>Raw - A video containing a single continuous shot or single drone angle
    </span>
	},
  {
		key: '7',
		metadataField: 'Category',
		notes: <span>Entertainment - General
      <br/>Non-US Domestic News - News-worthy footage of current events not related to the United States of America
      <br/>Sports - Footage relating to sporting activities
      <br/>US Domestic News - News-worthy footage of current events related to the United States of America
    </span>
	},
  {
		key: '8',
		metadataField: 'Supplemental Category',
		notes: <span>Select a category that is most relevant
      <br/>Eg. If you upload a video of a beach - “Nature”, “Watersports” and “Surfing” can be selected as supplemental categories. 
      <br/>The more accurately your categories match your video, the higher the chances of it getting discovered
    </span>
	},
  {
		key: '9',
		metadataField: 'Shot Speed',
		notes: <span>Real-Time - Shot in with normal video settings
      <br/>Slow Motion - Video is captured in slow motion using a higher frame rate
      <br/>Time Lapse - Video is captured as a time-lapse using a very low frame rate
    </span>
	},
  {
		key: '10',
		metadataField: 'Keywords',
		notes: <span>Like the “Supplemental Category”, the accuracy of what is entered into the “Keywords” field will determine how easily 
      discoverable the uploaded video will be. A minimum of 5 accurate Keywords is recommended to increase the “searchability” of the video.
      <br/>Eg. If you upload a video of a beach - “Beach”, “Drone Australia” & “Drone Beach” can be added as keywords. 
      <br/>The objective here is to add words that are most likely to be searched if someone would want to find your video. 
    </span>
	},
]

function SampleDataModal() {
	return <Table columns={columns} pagination={false} dataSource={dataSource} />
}

export default SampleDataModal