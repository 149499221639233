import { Button, Descriptions, Divider } from 'antd'
import React from 'react'
import moment from 'moment'
import VerificationTag from '../verification-tag'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const NzOtherLicenses = props => {
	const { otherLicenseData, toggleDeleteLicenseModal, toggleRetryModal } = props
	return (
		<>
			{otherLicenseData.map(item => (
				<React.Fragment key={item.id}>
					<div className='submod-card-header'>
						<div>
							<div style={{ marginBottom: '25px' }}>
								<strong>{item.licenseTypeLabel}</strong>
								<VerificationTag verificationState={item.verificationState} />
							</div>
							<Descriptions labelStyle={{ color: '#84818A' }}>
								{item.expiryDate ? (
									<Descriptions.Item label='Expiry Date'>
										{moment
											.utc(item.expiryDate)
											.local()
											.format('DD MMM YYYY')}
									</Descriptions.Item>
								) : null}
								{item.licenseSubTypeLabel ? <Descriptions.Item label='Subtype'>{item.licenseSubTypeLabel}</Descriptions.Item> : null}
								<Descriptions.Item label='License Number'>{item.licenseNumber}</Descriptions.Item>
								<Descriptions.Item label='License'>
									<a style={{ color: '#00B3FF' }} href={item.imageURL} target='_blank'>
										Click to download
									</a>
								</Descriptions.Item>
							</Descriptions>
						</div>
						<>
							{item.verificationState === 'VERIFICATION_FAILED' ? (
								<Button style={{ marginRight: '10px' }} type='primary' onClick={() => toggleRetryModal(item)}>
									<EditOutlined style={{ position: 'relative', bottom: '3px' }} />
								</Button>
							) : null}
							<Button className='submod-card-header-btn' onClick={() => toggleDeleteLicenseModal(item)}>
								<DeleteOutlined style={{ position: 'relative', bottom: '3px' }} />
							</Button>
						</>
					</div>
					{item.verificationState === 'VERIFICATION_FAILED' ? (
						<>
							<Divider></Divider>
							<p>{item.failedVerificationReason}</p>
						</>
					) : null}
					<Divider></Divider>
				</React.Fragment>
			))}
		</>
	)
}

export default NzOtherLicenses
