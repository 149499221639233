export const CREATE_PROJECT = 'CREATE_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const GET_PROJECTS = 'GET_PROJECTS'
export const INVITE_PILOTS = 'INVITE_PILOTS'
export const GET_PILOTS = 'GET_PILOTS'
export const GET_QUOTES = 'GET_QUOTES'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION'
export const PAYMENT = 'PAYMENT'
export const GET_ALL_QUOTES = 'GET_ALL_QUOTES'
export const UPDATE_QUOTE = 'UPDATE_QUOTE'
export const GET_MESSAGES = 'GET_MESSAGES'
export const GET_DOWNLOAD_URL = 'GET_DOWNLOAD_URL'
export const GET_PREVIEW_URL = 'GET_PREVIEW_URL'
export const MARK_IN_FLIGHT = 'MARK_IN_FLIGHT'
export const MARK_READY_TO_PROCESS = 'MARK_READY_TO_PROCESS'
export const MARK_COMPLETE = 'MARK_COMPLETE'
export const MARK_RESOLVED = 'MARK_RESOLVED'
export const GET_PROJECT_FOR_CONSUMER = 'GET_PROJECT_FOR_CONSUMER'
export const GET_PROJECT_FOR_PILOT = 'GET_PROJECT_FOR_PILOT'
export const CHECK_UPLOADED_PREVIEW = 'CHECK_UPLOADED_PREVIEW'
export const CHECK_UPLOADED_DATA = 'CHECK_UPLOADED_DATA'
export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY'
export const GET_PROJECT_TIMELINE = 'GET_PROJECT_TIMELINE'
export const DISAPPROVE_PREVIEW_DATA = 'DISAPPROVE_PREVIEW_DATA'
export const DISAPPROVE_FINAL_DATA = 'DISAPPROVE_FINAL_DATA'
export const CONFLICT_RESOLUTION_PREVIEW = 'CONFLICT_RESOLUTION_PREVIEW'
export const CONFLICT_RESOLUTION_FINAL = 'CONFLICT_RESOLUTION_FINAL'
export const GET_ALL_ACTIVE_LIVE_STREAMS = 'GET_ALL_ACTIVE_LIVE_STREAMS'
export const GET_STREAM_DETAILS = 'GET_STREAM_DETAILS'
export const GET_PROJECTS_OVERVIEW = 'GET_PROJECTS_OVERVIEW'
export const GET_TRANSACTIONS_PAGE_DATA = 'GET_TRANSACTIONS_PAGE_DATA'
export const GET_ACCOUNT_MANAGER = 'GET_ACCOUNT_MANAGER'
export const CREATE_SUPPORT_TICKET = 'CREATE_SUPPORT_TICKET'
export const GET_TRANSACTIONS_HISTORY = 'GET_TRANSACTIONS_HISTORY'
export const GET_ALL_COLLABORATORS = 'GET_ALL_COLLABORATORS'
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS'
export const GET_ALL_PROJECTS_FOR_ORDER = 'GET_ALL_PROJECTS_FOR_ORDER'
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS'
export const GET_PROJECTS_FOR_CARDS = 'GET_PROJECTS_FOR_CARDS'
export const UPLOAD_PROJECT_DESCRIPTION = 'UPLOAD_PROJECT_DESCRIPTION'
export const GET_ALL_PROJECTS_BY_STATUS = 'GET_ALL_PROJECTS_BY_STATUS'
export const GET_ALL_INVITATIONS = 'GET_ALL_INVITATIONS'
export const GET_ALL_PROJECTS_HISTORY = 'GET_ALL_PROJECTS_HISTORY'
export const GET_INVITATIONS_COUNT = 'GET_INVITATIONS_COUNT'
export const GET_MULTIPART_URLS = 'GET_MULTIPART_URLS'
export const UPLOAD_MULTIPART_FILE = 'UPLOAD_MULTIPART_FILE'
export const COMPLETE_MULTIPART_UPLOAD = 'COMPLETE_MULTIPART_UPLOAD'
export const ABORT_MULTIPART_UPLOAD = 'ABORT_MULTIPART_UPLOAD'
export const DOWNLOAD_ADDITIONAL_DATA = 'DOWNLOAD_ADDITIONAL_DATA'
export const GET_BOX_CREDS = 'GET_BOX_CREDS'
export const DOWNLOAD_BOX_FOLDER = 'DOWNLOAD_BOX_FOLDER'
export const GET_BIDDABLE_PROJECTS = 'GET_BIDDABLE_PROJECTS'
export const GET_COMMERCIAL_COMMISION = 'GET_COMMERCIAL_COMMISION'
export const GET_BID_HISTORY = 'GET_BID_HISTORY'
export const MODIFY_BID = 'MODIFY_BID'
export const CANCEL_PROJECT = 'CANCEL_PROJECT'
export const ENQUIRE_FOR_ORDER = 'ENQUIRE_FOR_ORDER'
export const CHAT_WINDOW_CONSUMER_STATUS = 'CHAT_WINDOW_CONSUMER_STATUS'
export const CHAT_WINDOW_PILOT_STATUS = 'CHAT_WINDOW_PILOT_STATUS'
export const UPLOAD_FLIGHT_LOG_FILE = 'UPLOAD_FLIGHT_LOG_FILE'
export const DELETE_FLIGHT_LOG_FILE = 'DELETE_FLIGHT_LOG_FILE'
