// react & redux imports
import React, { Component } from 'react'
import { Select } from 'antd'
import { getConfigData } from '../../Services/LocalStorageService'

class AeroSelect extends Component {
	constructor(props) {
		super(props)
		const isdCode = getConfigData().isdCode
		this.state = {
			optionsData: this.props.data ? this.props.data : [{ value: isdCode, displayValue: isdCode }],
		}
	}
	render() {
		return (
			<div>
				<Select onChange={this.props.onChange} defaultValue={this.props.defaultValue ? this.props.defaultValue : this.state.optionsData[0].value}>
					{this.state.optionsData.map((datum, index) => (
						<Select.Option key={index} value={datum.value}>
							{datum.displayValue}
						</Select.Option>
					))}
				</Select>
			</div>
		)
	}
}

export default AeroSelect
