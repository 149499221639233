import React from 'react'
import { TEXTS } from '../../../Modules/OnBoarding/on-boarding.constants'
import { Input } from 'antd'

const ARN_DETAILS_MODAL = {
	ARN: 'arnLicenseNumber',
}

export const ARN_DETAILS_FIELDS = scope => {
	return [
		{
			fieldName: 'ARN',
			fieldLabel: TEXTS.ARN,
			fieldKey: ARN_DETAILS_MODAL.ARN,
			fileObject: {
				rules: [
					{
						message: 'The input a valid ARN Number!',
						whitespace: true,
					},
					{
						required: scope.props.viewMode ? false : true,
						message: 'Please input your ARN Number!',
					},
				],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
		},
	]
}
