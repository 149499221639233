import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from './orders.module.scss'
import { Breadcrumb, Button, message, Tooltip } from 'antd'
import { AeroModal } from '../../AeroComponents'
import { getDownloadUrl, markResolved, getStreamDetails, downloadAdditionalData, getBoxCreds, downloadBoxFolder } from '../../Stores/Projects/action'
import { dataCols } from './table-cols'
import { getValueFromStorage, setValueToStorage } from '../../Services/LocalStorageService'
import ProjectCards from './Components/ProjectCards/ProjectCards'
import OrderDetailCard from './Components/OrderDetail/OrderDetailCard'
import LiveStreamModal from './Components/Modals/LiveStreamModal'
import ApproveModal from './Components/Modals/ApproveModal'
import DisapproveModal from './Components/Modals/DisapproveModal'
// import AdditionalDataModal from './Components/Modals/AdditionalDataModal'
import ProcessedDownloadModalContent from './Components/processed-download-modal-content'
import moment from 'moment'
import { goTo } from '../../Services/NavigationService'
import { textShortner } from '../../Services/UtilityService'

class OrderDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dataCols: dataCols(this),
			approveModal: false,
			selectedProjectData: {},
			disapproveModal: false,
			liveStreamModal: false,
			orderId: props.match.params.orderId,
			orderData: {},
			additionalData: {},
			selectedProjectId: '',
			updateDataTab: false,
			showDownloadCard: false,
			boxCreds: null,
			startTime: moment(),
			downloadProcessedDataModal: false,
			archivedProducts: null,
			models: null,
			cloud: null,
			orthomosaic: null,
			reports: null,
			others: null,
			expandedRowKeys: [],
			additionalDataList: [],
			additionalDataModal: false,
		}

		if (getValueFromStorage('localStore/0/bce.defaultViewMode')) {
			setValueToStorage('localStore/0/bce.defaultViewMode', '"grid"')
		}
	}

	componentDidMount() {
		if (this.props.history.location.state?.selectedTab === 'data') {
			this.props.history.replace({ ...this.props.history.location, state: null })
			this.setState({ updateDataTab: true })
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.orderData !== nextProps.orderData) {
			this.setState({
				orderData: nextProps.orderData,
			})
		}
		if (this.props.downloadUrlData !== nextProps.downloadUrlData) {
			if (nextProps.downloadUrlData) {
				message.success('Your download will begin now!')
				window.location.replace(nextProps.downloadUrlData)
			}
		}

		if (this.props.markResolvedSuccess !== nextProps.markResolvedSuccess) {
			if (nextProps.markResolvedSuccess) {
				this.closeApproveModal()
				this.setState({ updateDataTab: true })
			}
		}
		if (this.props.disapproveFinalDataResponse !== nextProps.disapproveFinalDataResponse) {
			if (nextProps.disapproveFinalDataResponse) {
				this.closeDisapproveModal()
				this.props.getAllProjectsForOrder({ orderId: this.state.orderId })
			}
		}
		if (this.props.downloadAdditionalDataSuccess !== nextProps.downloadAdditionalDataSuccess) {
			if (nextProps.downloadAdditionalDataSuccess) {
				message.success('Your download will begin now!')
				window.location.replace(nextProps.downloadAdditionalDataSuccess.url)
			}
		}
		if (this.props.boxCreds !== nextProps.boxCreds) {
			if (nextProps.boxCreds) {
				this.setState({ boxCreds: nextProps.boxCreds })
			}
		}
		if (this.props.downloadBoxFolderUrl !== nextProps.downloadBoxFolderUrl) {
			if (nextProps.downloadBoxFolderUrl) {
				message.success('Your download will begin now!')
				window.location.replace(nextProps.downloadBoxFolderUrl)
				this.setState({ selectedProjectId: '' })
			}
		}
	}

	downloadProjectData = projectData => {
		if (projectData.isBoxSupported) {
			this.props.getBoxCreds({ data: projectData.projectId }).then(res => {
				this.props.downloadBoxFolder({
					folder_id: res.value.data.dataFolderId,
					project_id: projectData.projectId,
					accessToken: res.value.data.accessToken,
				})
			})
		} else {
			this.props.getDownloadUrl({ type: 'upload', project: projectData.projectId })
		}
	}

	previewProjectData = projectData => {
		goTo(`project-data/${projectData.projectId}`)
	}

	/* Approve modal */
	openApproveModal = data => {
		this.setState({ approveModal: true, selectedProjectData: data })
	}

	closeApproveModal = () => {
		this.setState({ approveModal: false })
	}

	approveData = () => {
		const data = this.state.selectedProjectData
		const body = {
			consumer_id: Number(data.consumer_id),
			pilot_id: Number(data.pilot_id),
			project_id: data.projectId,
			quote_id: data.quotation_id,
		}
		this.props.markResolved(body)
	}

	/* Disapprove modal */
	openDisapproveModal = data => {
		this.setState({ disapproveModal: true, selectedProjectData: data })
	}

	closeDisapproveModal = () => {
		this.setState({ disapproveModal: false })
	}

	disapproveData = () => {
		this.setState({ updateDataTab: true })
	}

	updateDataTabDone = () => {
		this.setState({ updateDataTab: false })
	}

	/* Live stream modal */
	openLiveStreamModal = projectId => {
		this.callSource(projectId)
		this.setState({ liveStreamModal: true })
	}

	closeLiveStreamModal = () => {
		this.setState({ liveStreamModal: false })
	}

	callSource = projectId => {
		this.props.getStreamDetails({ play: projectId })
	}

	viewAdditionalData = (additionalData, projectId) => {
		this.setState({ additionalDataModal: true, additionalData: additionalData, selectedProjectId: projectId })
	}

	closeAdditionalDataModal = () => {
		this.setState({ additionalDataModal: false, additionalData: {} })
	}

	downloadAdditionalData = fileId => {
		this.props.downloadAdditionalData({ projectId: this.state.selectedProjectId, fileId: fileId })
	}

	openProcessedDataDownload = processingId => {
		var myHeaders = new Headers()

		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow',
		}

		fetch(`${process.env.REACT_APP_MAPPA_BE_BASE_URL}projects/processings/${processingId}/list-reports-shared/`, requestOptions)
			.then(response => response.text())
			.then(result => {
				this.setState({ reports: JSON.parse(result).reports })
			})
			.catch(error => console.log('error', error))
		fetch(`${process.env.REACT_APP_MAPPA_BE_BASE_URL}projects/processings/${processingId}/list-products-shared/`, requestOptions)
			.then(response => response.text())
			.then(result => {
				let products = JSON.parse(result).data.products
				products = products.sort((a, b) => a.name.localeCompare(b.name))
				const archivedProducts = JSON.parse(result).archived_products
				const models = products.filter(products => products.name.includes('Model'))
				const curves = products.filter(products => products.name.includes('Curves'))
				const cloud = products.filter(products => products.name.includes('Cloud'))
				const orthomosaic = products.filter(products => products.name.includes('Orthomosaic'))
				const others = products.filter(
					products => !products.name.includes('Model') && !products.name.includes('Curves') && !products.name.includes('Cloud') && !products.name.includes('Orthomosaic'),
				)
				this.setState({
					archivedProducts: archivedProducts,
					models: models,
					curves: curves,
					cloud: cloud,
					orthomosaic: orthomosaic,
					others: others,
					downloadProcessedDataModal: true,
				})
			})
			.catch(error => console.log('error', error))
	}

	openAdditionalDataModal = project => this.setState({ additionalDataList: project.project_additional_information, additionalDataModal: true })

	render() {
		const {
			dataCols,
			approveModal,
			disapproveModal,
			liveStreamModal,
			orderData,
			additionalDataModal,
			// additionalData,
			updateDataTab,
			downloadProcessedDataModal,
			models,
			curves,
			cloud,
			orthomosaic,
			others,
			reports,
			additionalDataList,
		} = this.state

		return (
			<div className={styles['page-container']}>
				<div className={styles['header']}>My Orders</div>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Button type='link' href='/enterprise/orders'>
							Home
						</Button>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<span style={{ position: 'relative', top: '5px' }}>{orderData.name}</span>
					</Breadcrumb.Item>
				</Breadcrumb>
				<OrderDetailCard
					orderId={this.state.orderId}
					dataCols={dataCols}
					updateDataTab={updateDataTab}
					updateDataTabDone={this.updateDataTabDone}
					openApproveModal={this.openApproveModal}
					openDisapproveModal={this.openDisapproveModal}
					viewAdditionalData={this.viewAdditionalData}
					defaultTab={this.props.history.location.state?.selectedTab}
				></OrderDetailCard>

				<ProjectCards
					openAdditionalDataModal={this.openAdditionalDataModal}
					orderId={this.state.orderId}
					downloadProjectData={this.downloadProjectData}
					openLiveStreamModal={this.openLiveStreamModal}
				></ProjectCards>
				<ApproveModal approveModal={approveModal} closeApproveModal={this.closeApproveModal} approveData={this.approveData}></ApproveModal>

				<DisapproveModal
					closeDisapproveModal={this.closeDisapproveModal}
					disapproveModal={disapproveModal}
					selectedProjectData={this.state.selectedProjectData}
					disapproveData={this.disapproveData}
				></DisapproveModal>

				<LiveStreamModal liveStreamModal={liveStreamModal} closeLiveStreamModal={this.closeLiveStreamModal}></LiveStreamModal>

				{/* {additionalData.description || additionalData.files ? (
					<AdditionalDataModal
						downloadAdditionalData={this.downloadAdditionalData}
						additionalDataModal={additionalDataModal}
						closeAdditionalDataModal={this.closeAdditionalDataModal}
						additionalData={additionalData}
					></AdditionalDataModal>
				) : null} */}
				<AeroModal
					bodyStyle={{ background: '#F5F5F5', borderRadius: '12px' }}
					title='Download Data'
					footer={false}
					onCancel={() => this.setState({ downloadProcessedDataModal: false })}
					visible={downloadProcessedDataModal}
				>
					<ProcessedDownloadModalContent models={models} curves={curves} cloud={cloud} orthomosaic={orthomosaic} others={others} reports={reports} />
				</AeroModal>
				<AeroModal footer={false} visible={additionalDataModal} onCancel={() => this.setState({ additionalDataModal: false })} title='Data Delivery'>
					{additionalDataList.map(item => (
						<div key={item.id} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
							<div>
								<Tooltip title={item.label}>{textShortner(item.label, 20)}</Tooltip>
							</div>
							<div>
								{item.type === 'LINK' ? (
									<Tooltip title={item.value}>
										<Button type='link' href={item.value} target='_blank' rel='noopener noreferrer'>
											{textShortner(item.value, 30)}
										</Button>
									</Tooltip>
								) : (
									<Tooltip title={item.value}>{textShortner(item.value, 30)}</Tooltip>
								)}
							</div>
						</div>
					))}
				</AeroModal>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		downloadUrlData: state.ProjectsReducer.downloadUrlData,
		markResolvedSuccess: state.ProjectsReducer.markResolvedSuccess,
		disapproveFinalDataResponse: state.ProjectsReducer.disapproveFinalDataResponse,
		orderData: state.ProjectsReducer.orderData,
		downloadAdditionalDataSuccess: state.ProjectsReducer.downloadAdditionalDataSuccess,
		boxCreds: state.ProjectsReducer.boxCreds,
		downloadBoxFolderUrl: state.ProjectsReducer.downloadBoxFolderUrl,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getDownloadUrl: bindActionCreators(getDownloadUrl, dispatch),
		markResolved: bindActionCreators(markResolved, dispatch),
		getStreamDetails: bindActionCreators(getStreamDetails, dispatch),
		downloadAdditionalData: bindActionCreators(downloadAdditionalData, dispatch),
		getBoxCreds: bindActionCreators(getBoxCreds, dispatch),
		downloadBoxFolder: bindActionCreators(downloadBoxFolder, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(OrderDetails)
