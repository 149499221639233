import React from 'react'
import { Button } from 'antd'

import styles from '../enterprisePilotPlus.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'
import { IMAGES_SRC } from '../../../../../AeroComponents/Image/image.constants'

const RegistrationSuccessModal = ({ closeEnterprisePlusPilotModal, hideEnterprisePlusBanner, setRegistrationSucessModal, registrationSuccessModal }) => {
	const handleClose = () => {
		closeEnterprisePlusPilotModal()
		hideEnterprisePlusBanner()
		setRegistrationSucessModal(false)
	}

	return (
		<AeroModal visible={registrationSuccessModal} onCancel={handleClose} maskClosable={false} title='' footer={false}>
			<div className={styles['registration_sucess']}>
				<img alt='reg-suc' className={styles['registration_sucess_img']} src={IMAGES_SRC.ENTERPRISE_PILOT_PLUS_SUCCESS} />

				<div className={styles['registration_sucess_header']}>
					<h2>Congratulations!</h2>
					<p>You are now a member of Enterprise program</p>
				</div>

				<div style={{ textAlign: 'center', padding: '20px' }}>
					<Button
						type='primary'
						onClick={handleClose}
						block
						style={{ height: '50px', borderRadius: '8px', width: '90%', fontFamily: 'Red Hat Display', fontWeight: 500 }}
					>
						Okay, Got it
					</Button>
				</div>
			</div>
		</AeroModal>
	)
}

export default RegistrationSuccessModal
