import * as ACTION from './actionTypes'
import {
	createProjectApi,
	updateProjectApi,
	getProjectsApi,
	invitePilotsApi,
	getPilotsApi,
	getQuotesApi,
	sendMessageApi,
	createTransactionApi,
	paymentApi,
	getAllQuotesApi,
	updateQuoteApi,
	getMessagesApi,
	getDownloadUrlApi,
	getPreviewUrlApi,
	markInFlightApi,
	markReadyToProcessApi,
	markCompleteApi,
	markResolvedApi,
	getProjectForConsumerApi,
	getProjectForPilotApi,
	checkUploadedPreviewApi,
	checkUploadedDataApi,
	getPaymentHistoryApi,
	getProjectTimelineApi,
	disapprovePreviewDataApi,
	disapproveFinalDataApi,
	conflictResolutionPreviewApi,
	conflictResolutionFinalApi,
	getAllActiveLiveStreamsApi,
	getStreamDetailsApi,
	getProjectsOverviewApi,
	getTransactionsPageDataApi,
	getAccountManagerApi,
	createSupportTicketApi,
	getTransactionsHistoryApi,
	getAllCollaboratorsApi,
	getAllOrdersApi,
	getAllProjectsForOrderApi,
	getOrderDetailsApi,
	getProjectsForCardsApi,
	uploadProjectDescriptionApi,
	getAllProjectsByStatusApi,
	getAllInvitationsApi,
	getAllProjectsHistoryApi,
	getInvitationsCountApi,
	uploadMultipartFileApi,
	getMultipartUrlsApi,
	completeMultipartUploadApi,
	abortMultipartUploadApi,
	downloadAdditionalDataApi,
	getBoxCredsApi,
	downloadBoxFolderApi,
	getBiddableProjectsApi,
	getCommercialCommisionApi,
	getBidHistoryApi,
	modifyBidApi,
	cancelProjectApi,
	enquireForOrderApi,
	chatWindowConsumerStatusApi,
	chatWindowPilotStatusApi,
	uploadFlightLogFileApi,
	deleteFlightLogFileApi,
} from './api'

export const createProject = body => {
	return {
		type: ACTION.CREATE_PROJECT,
		payload: createProjectApi(body),
	}
}

export const updateProject = body => {
	return {
		type: ACTION.UPDATE_PROJECT,
		payload: updateProjectApi(body),
	}
}

export const sendMessage = body => {
	return {
		type: ACTION.SEND_MESSAGE,
		payload: sendMessageApi(body),
	}
}

export const createTransaction = body => {
	return {
		type: ACTION.CREATE_TRANSACTION,
		payload: createTransactionApi(body),
	}
}

export const payment = body => {
	return {
		type: ACTION.PAYMENT,
		payload: paymentApi(body),
	}
}

export const getPilots = pathParams => {
	return {
		type: ACTION.GET_PILOTS,
		payload: getPilotsApi(pathParams),
	}
}

export const getQuotes = queryParams => {
	return {
		type: ACTION.GET_QUOTES,
		payload: getQuotesApi(queryParams),
	}
}

export const getMessages = pathParams => {
	return {
		type: ACTION.GET_MESSAGES,
		payload: getMessagesApi(pathParams),
	}
}

export const invitePilots = body => {
	return {
		type: ACTION.INVITE_PILOTS,
		payload: invitePilotsApi(body),
	}
}

export const getProjects = () => {
	return {
		type: [ACTION.GET_PROJECTS],
		payload: getProjectsApi(),
	}
}

export const getAllQuotes = () => {
	return {
		type: [ACTION.GET_ALL_QUOTES],
		payload: getAllQuotesApi(),
	}
}

export const updateQuote = body => {
	return {
		type: ACTION.UPDATE_QUOTE,
		payload: updateQuoteApi(body),
	}
}

export const getDownloadUrl = pathParams => {
	return {
		type: ACTION.GET_DOWNLOAD_URL,
		payload: getDownloadUrlApi(pathParams),
	}
}

export const getPreviewUrl = pathParams => {
	return {
		type: ACTION.GET_PREVIEW_URL,
		payload: getPreviewUrlApi(pathParams),
	}
}

export const markInFlight = body => {
	return {
		type: ACTION.MARK_IN_FLIGHT,
		payload: markInFlightApi(body),
	}
}

export const markReadyToProcess = body => {
	return {
		type: ACTION.MARK_READY_TO_PROCESS,
		payload: markReadyToProcessApi(body),
	}
}

export const markComplete = body => {
	return {
		type: ACTION.MARK_COMPLETE,
		payload: markCompleteApi(body),
	}
}

export const markResolved = body => {
	return {
		type: ACTION.MARK_RESOLVED,
		payload: markResolvedApi(body),
	}
}
export const getProjectForConsumer = (pathParams, queryParams, mixpanelData) => {
	return {
		type: ACTION.GET_PROJECT_FOR_CONSUMER,
		payload: getProjectForConsumerApi(pathParams, queryParams, mixpanelData),
	}
}

export const getProjectForPilot = (pathParams, queryParams, mixpanelData) => {
	return {
		type: ACTION.GET_PROJECT_FOR_PILOT,
		payload: getProjectForPilotApi(pathParams, queryParams, mixpanelData),
	}
}

export const checkUploadedPreview = pathParams => {
	return {
		type: ACTION.CHECK_UPLOADED_PREVIEW,
		payload: checkUploadedPreviewApi(pathParams),
	}
}

export const checkUploadedData = pathParams => {
	return {
		type: ACTION.CHECK_UPLOADED_DATA,
		payload: checkUploadedDataApi(pathParams),
	}
}

export const getPaymentHistory = queryParams => {
	return {
		type: ACTION.GET_PAYMENT_HISTORY,
		payload: getPaymentHistoryApi(queryParams),
	}
}

export const getProjectTimeline = queryParams => {
	return {
		type: ACTION.GET_PROJECT_TIMELINE,
		payload: getProjectTimelineApi(queryParams),
	}
}

export const disapprovePreviewData = (body, mixpanelData) => {
	return {
		type: ACTION.DISAPPROVE_PREVIEW_DATA,
		payload: disapprovePreviewDataApi(body, mixpanelData),
	}
}

export const disapproveFinalData = (body, mixpanelData) => {
	return {
		type: ACTION.DISAPPROVE_FINAL_DATA,
		payload: disapproveFinalDataApi(body, mixpanelData),
	}
}

export const conflictResolutionPreview = body => {
	return {
		type: ACTION.CONFLICT_RESOLUTION_PREVIEW,
		payload: conflictResolutionPreviewApi(body),
	}
}

export const conflictResolutionFinal = body => {
	return {
		type: ACTION.CONFLICT_RESOLUTION_FINAL,
		payload: conflictResolutionFinalApi(body),
	}
}

export const getAllActiveLiveStreams = () => {
	return {
		type: [ACTION.GET_ALL_ACTIVE_LIVE_STREAMS],
		payload: getAllActiveLiveStreamsApi(),
	}
}

export const getStreamDetails = pathParams => {
	return {
		type: ACTION.GET_STREAM_DETAILS,
		payload: getStreamDetailsApi(pathParams),
	}
}

export const getProjectsOverview = () => {
	return {
		type: [ACTION.GET_PROJECTS_OVERVIEW],
		payload: getProjectsOverviewApi(),
	}
}

export const getTransactionsPageData = () => {
	return {
		type: [ACTION.GET_TRANSACTIONS_PAGE_DATA],
		payload: getTransactionsPageDataApi(),
	}
}

export const getAccountManager = () => {
	return {
		type: [ACTION.GET_ACCOUNT_MANAGER],
		payload: getAccountManagerApi(),
	}
}

export const createSupportTicket = body => {
	return {
		type: ACTION.CREATE_SUPPORT_TICKET,
		payload: createSupportTicketApi(body),
	}
}

export const getTransactionsHistory = queryParams => {
	return {
		type: [ACTION.GET_TRANSACTIONS_HISTORY],
		payload: getTransactionsHistoryApi(queryParams),
	}
}

export const getAllCollaborators = () => {
	return {
		type: [ACTION.GET_ALL_COLLABORATORS],
		payload: getAllCollaboratorsApi(),
	}
}

export const getAllOrders = queryParams => {
	return {
		type: [ACTION.GET_ALL_ORDERS],
		payload: getAllOrdersApi(queryParams),
	}
}

export const getAllProjectsForOrder = queryParams => {
	return {
		type: ACTION.GET_ALL_PROJECTS_FOR_ORDER,
		payload: getAllProjectsForOrderApi(queryParams),
	}
}

export const getOrderDetails = queryParams => {
	return {
		type: ACTION.GET_ORDER_DETAILS,
		payload: getOrderDetailsApi(queryParams),
	}
}

export const getProjectsForCards = queryParams => {
	return {
		type: ACTION.GET_PROJECTS_FOR_CARDS,
		payload: getProjectsForCardsApi(queryParams),
	}
}

export const uploadProjectDescription = body => {
	return {
		type: ACTION.UPLOAD_PROJECT_DESCRIPTION,
		payload: uploadProjectDescriptionApi(body),
	}
}

export const getAllProjectsByStatus = queryParams => {
	return {
		type: ACTION.GET_ALL_PROJECTS_BY_STATUS,
		payload: getAllProjectsByStatusApi(queryParams),
	}
}

export const getAllInvitations = queryParams => {
	return {
		type: ACTION.GET_ALL_INVITATIONS,
		payload: getAllInvitationsApi(queryParams),
	}
}

export const getAllProjectsHistory = queryParams => {
	return {
		type: ACTION.GET_ALL_PROJECTS_HISTORY,
		payload: getAllProjectsHistoryApi(queryParams),
	}
}

export const getInvitationsCount = queryParams => {
	return {
		type: ACTION.GET_INVITATIONS_COUNT,
		payload: getInvitationsCountApi(queryParams),
	}
}

export const getMultipartUrls = ({ projectId, abortController }, queryParams) => {
	return {
		type: ACTION.GET_MULTIPART_URLS,
		payload: getMultipartUrlsApi(queryParams).then(res => {
			return { ...res, id: projectId, ...queryParams, abortController }
		}),
	}
}

export const uploadMultipartFile = (body, abortSignal) => {
	return {
		type: ACTION.UPLOAD_MULTIPART_FILE,
		payload: uploadMultipartFileApi(body, abortSignal).then(res => {
			return { ...res, projectId: body.projectId }
		}),
	}
}

export const completeMultipartUpload = ({ body, projectId, abortSignal }) => {
	return {
		type: ACTION.COMPLETE_MULTIPART_UPLOAD,
		payload: completeMultipartUploadApi(body, abortSignal).then(res => {
			return { ...res, projectId: projectId }
		}),
	}
}

export const abortMultipartUpload = (projectId, body) => {
	return {
		type: ACTION.ABORT_MULTIPART_UPLOAD,
		payload: abortMultipartUploadApi(body.body).then(res => {
			return { ...res, projectId: projectId }
		}),
	}
}

export const downloadAdditionalData = queryParams => {
	return {
		type: ACTION.DOWNLOAD_ADDITIONAL_DATA,
		payload: downloadAdditionalDataApi(queryParams),
	}
}

export const getBoxCreds = pathParams => {
	return {
		type: ACTION.GET_BOX_CREDS,
		payload: getBoxCredsApi(pathParams),
	}
}

export const downloadBoxFolder = (queryParams, mixpanelData) => {
	return {
		type: ACTION.DOWNLOAD_BOX_FOLDER,
		payload: downloadBoxFolderApi(queryParams, mixpanelData),
	}
}

export const getBiddableProjects = queryParams => {
	return {
		type: ACTION.GET_BIDDABLE_PROJECTS,
		payload: getBiddableProjectsApi(queryParams),
	}
}

export const getCommercialCommision = queryParams => {
	return {
		type: ACTION.GET_COMMERCIAL_COMMISION,
		payload: getCommercialCommisionApi(queryParams),
	}
}

export const getBidHistory = queryParams => {
	return {
		type: ACTION.GET_BID_HISTORY,
		payload: getBidHistoryApi(queryParams),
	}
}

export const modifyBid = body => {
	return {
		type: ACTION.MODIFY_BID,
		payload: modifyBidApi(body),
	}
}

export const cancelProject = body => {
	return {
		type: ACTION.CANCEL_PROJECT,
		payload: cancelProjectApi(body),
	}
}

export const enquireForOrder = body => {
	return {
		type: ACTION.ENQUIRE_FOR_ORDER,
		payload: enquireForOrderApi(body),
	}
}

export const chatWindowConsumerStatus = body => {
	return {
		type: ACTION.CHAT_WINDOW_CONSUMER_STATUS,
		payload: chatWindowConsumerStatusApi(body),
	}
}

export const chatWindowPilotStatus = body => {
	return {
		type: ACTION.CHAT_WINDOW_PILOT_STATUS,
		payload: chatWindowPilotStatusApi(body),
	}
}

export const uploadFlightLogFile = body => {
	return {
		type: ACTION.UPLOAD_FLIGHT_LOG_FILE,
		payload: uploadFlightLogFileApi(body),
	}
}

export const deleteFlightLogFile = body => {
	return {
		type: ACTION.DELETE_FLIGHT_LOG_FILE,
		payload: deleteFlightLogFileApi(body),
	}
}
