/*eslint-disable*/
import React from 'react'
import { Button, Divider } from 'antd'
import './on-boarding.sass'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import MatText from '../../AeroComponents/Text'
import { gotToLogIn } from '../../Services/NavigationService'

export default class EmailVerificationFail extends React.Component {
	render() {
		return (
			<div className='flex-container'>
				<MatImage src={IMAGES_SRC.LOGO_BLACK} width='250' height='70' />
				<div className='status-card'>
					<MatImage src={IMAGES_SRC.LINK_EXPIRED_ICON} />

					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
						<MatText size='22' text={'Verification link has expired!'} />
					</div>
					<Divider></Divider>
					<MatText text={'For going back to login page'} />

					<Button type='link' onClick={() => gotToLogIn()}>
						Click here
					</Button>
				</div>
			</div>
		)
	}
}
