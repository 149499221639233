import React from 'react'
import moment from 'moment'
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons'

export const transactionHistoryCols = scope => {
	return [
		{
			title: 'Transaction ID',
			dataIndex: 'transactionId',
			key: 'transactionId',
		},
		// {
		// 	title: 'Particulars',
		// 	dataIndex: 'particular',
		// 	key: 'particular',
		// },
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (amount, { ...data }) => (
				<span>
					ACr {amount}{' '}
					{data.transactionType === 'DEBIT' ? (
						<CaretDownFilled style={{ position: 'relative', bottom: '3px', color: '#F5222D' }} />
					) : (
						<CaretUpFilled style={{ position: 'relative', bottom: '3px', color: '#52C41A' }} />
					)}
				</span>
			),
		},
		{
			title: 'Date & Time',
			dataIndex: 'timeStamp',
			key: 'timeStamp',
			render: timeStamp => moment(timeStamp).format('DD/MM/YYYY hh:mm A'),
		},
		{
			title: 'Closing Balance',
			dataIndex: 'closingBalance',
			key: 'closingBalance',
			render: closingBalance => <span>ACr {closingBalance}</span>,
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
		},
	]
}
