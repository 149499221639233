export const getProjectStatus = (projectType, projectStatusValue, projectQuoteValue) => {
	if (projectType === 'COMMERCIAL') {
		switch (`${projectStatusValue} ${projectQuoteValue}`) {
			case 'ALLOCATE_PILOT ':
				return 'Open For Bidding'
			case 'ALLOCATE_PILOT PENDING':
				return 'Bid Submitted'
			case 'ALLOTTED COMPLETED':
				return 'Bid Accepted'
			case 'ALLOCATE_PILOT REJECTED':
				return 'Bid Rejected'
			case 'ALLOCATE_PILOT WITHDRAWN':
				return 'Bid Withdrawn'
			case 'IN_FLIGHT COMPLETED':
				return 'In-Flight'
			case 'COMPLETED COMPLETED':
				return 'Data Delivered'
			case 'DATA_REJECTED_FINAL COMPLETED':
				return 'Data Rejected'
			case 'RESOLVED COMPLETED':
				return 'Completed'
			default:
				return 'Bid Failed'
		}
	} else {
		switch (`${projectStatusValue} ${projectQuoteValue}`) {
			case 'IN_PROGRESS ':
				return 'Pilot Not Invited'
			case 'IN_PROGRESS PENDING':
				return 'Invite Pilot'
			case 'ALLOCATE_PILOT REJECTED':
				return 'Project Rejected'
			case 'ALLOCATE_PILOT PENDING':
				return 'Invitation Pending'
			case 'ALLOCATE_PILOT ACCEPTED':
				return 'Project Accepted (Allocation Pending)'
			case 'ALLOTTED COMPLETED':
				return 'Pilot Allocated'
			case 'IN_FLIGHT COMPLETED':
				return 'In-Flight'
			case 'COMPLETED COMPLETED':
				return 'Data Delivered'
			case 'DATA_REJECTED_FINAL COMPLETED':
				return 'Data Rejected'
			case 'RESOLVED COMPLETED':
				return 'Completed'
			default:
				return 'Project Failed'
		}
	}
}
