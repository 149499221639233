import React, { useEffect, useState } from 'react'

import IntroModal from './Steps/IntroModal'
import TermsAndConditons from './Steps/TermsAndConditons'
import StripeCreditCard from './Steps/StripeCreditCard'
import RegistrationSuccessModal from './Steps/RegistrationSuccessModal'
import { message } from 'antd'

const EnterprisePilotPlus = props => {
	const [currentStep, setCurrentStep] = useState(0)
	const [introModal, setIntroModal] = useState(true || props.enterprisePlusPopup)
	const [termsAndConditionsModalData, setTermsAndConditionsModalData] = useState({
		checkbox: false,
		pdfFile: null,
		contractDetails: null,
		contractObj: null,
		blob: null,
	})
	const [signatureData, setSignatureData] = useState({
		eSignPreview: null,
		eSignDateAndTime: null,
		eSignComplete: false,
	})
	const [registrationSuccessModal, setRegistrationSucessModal] = useState(true)

	const handleNext = () => {
		setCurrentStep(prev => prev + 1)
	}

	const handlePrev = () => {
		setCurrentStep(prev => prev - 1)
	}

	const commonProps = {
		handleNext,
		handlePrev,
		currentStep,
		setCurrentStep,
		...props,
	}

	const steps = [
		<IntroModal visible={introModal} setIntroModal={setIntroModal} {...commonProps} />,
		<TermsAndConditons
			termsAndConditionsModalData={termsAndConditionsModalData}
			setTermsAndConditionsModalData={setTermsAndConditionsModalData}
			signatureData={signatureData}
			setSignatureData={setSignatureData}
			{...commonProps}
		/>,
		<StripeCreditCard termsAndConditionsModalData={termsAndConditionsModalData} {...commonProps} />,
		<RegistrationSuccessModal setRegistrationSucessModal={setRegistrationSucessModal} registrationSuccessModal={registrationSuccessModal} {...commonProps} />,
	]

	const getContract = async () => {
		try {
			const {
				value: { data },
			} = await props.getEnterprisePlusContract({ contract: 'ENTERPRISE' })
			const contractApiData = await fetch(data.contract)
			const contractObj = await contractApiData.json()
			setTermsAndConditionsModalData(prev => ({
				...prev,
				contractDetails: data,
				contractObj,
			}))
		} catch (error) {
			message.error(error)
		}
	}
	useEffect(() => {
		getContract()
	}, [])

	return steps[currentStep]
}

export default EnterprisePilotPlus
