import React from 'react'

import MatImage from '../../AeroComponents/Image'

import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'

import AeroModal from '../../AeroComponents/Modal/index'

import { Button } from 'antd'

import styles from './FacebookModal.module.scss'

function FacebookModal  (props)  {
	// extract props
	const {
		facebookLoginModal,
		toggleFacebookLoginModal,
		goToForgotPass,  // from parent
	} = props
    
    return (
        <AeroModal  footer={false} visible={facebookLoginModal} onCancel={toggleFacebookLoginModal}>
            <div style={{ textAlign: 'center' }}>
                <MatImage style={{ margin: '20px' }} alt='published' src={IMAGES_SRC.BIG_INFO_ICON} />
                <div style={{ fontSize: '20px', marginBottom: '15px' }}>Important Update Regarding Facebook Login</div>
                <div style={{ fontSize: '14px', color: 'black', width: '95%', margin: 'auto' }}>
                We’re moving away from Facebook login to enhance your user experience and ensure more reliable access. Hence, your account will be transitioned to Email login. Please click on “Create Password” to initiate the process.
After clicking, you’ll be prompted to enter the same email ID used for your Facebook account. A system-generated password will then be sent to this email. For security and convenience, we recommend updating this password once you’ve logged in to your account.
If you’re facing any issues with the login, please feel free to write to us at  <span style={{ color: '#00B3FF' , textDecoration: 'underline'}}> support@aerologix.com </span>. A team member will be happy to assist and get things in order for you.
                </div>
            </div>
            <div  className={styles['buttons']} >
                <Button onClick={toggleFacebookLoginModal} type='primary'  block style={{ height: '50px' }} className={styles['back-button']}>
                Cancel
                </Button>

                <Button onClick={goToForgotPass} type='primary' block style={{ height: '50px' }} >
                Create Password
                </Button>
            </div>

        </AeroModal>
    )
}

export default FacebookModal