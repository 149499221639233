
export const GET_CONTRIBUTIONS = 'GET_CONTRIBUTIONS'
export const GET_PREVIOUS_SUBMISSIONS = 'GET_PREVIOUS_SUBMISSIONS'
export const GET_SAVED_SUBMISSIONS = 'GET_SAVED_SUBMISSIONS'
export const CREATE_GETTY_SUBMISSION = 'CREATE_GETTY_SUBMISSION'

export const GET_ALL_CONTRIBUTIONS = 'GET_ALL_CONTRIBUTIONS'
export const UPDATE_CONTRIBUTION = 'UPDATE_CONTRIBUTION'
export const DELETE_CONTRIBUTION = 'DELETE_CONTRIBUTION'
export const SUBMIT_SUBMISSION = 'SUBMIT_SUBMISSION'
export const UPLOAD_CONTRIBUTION = 'UPLOAD_CONTRIBUTION'
export const UPLOAD_MULTIPART_FILE_GETTY = 'UPLOAD_MULTIPART_FILE_GETTY'
export const UPLOAD_MULTIPART_FILE = 'UPLOAD_MULTIPART_FILE'
export const REMOVE_UPLOAD = 'REMOVE_UPLOAD'
export const CREATE_CONTRIBUTION = 'CREATE_CONTRIBUTION'
export const ADD_CONTRIBUTION = 'ADD_CONTRIBUTION'
export const REMOVE_CONTRIBUTION = 'REMOVE_CONTRIBUTION'
export const ADD_FILE = 'ADD_FILE'
export const REMOVE_FILE = 'REMOVE_FILE'
export const ADD_TO_UPLOAD = 'ADD_TO_UPLOAD'
export const BULK_UPDATE= 'BULK_UPDATE'

export const TESTING = 'TESTING'
 

