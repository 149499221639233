import * as ACTION from './actionTypes'
import {
	updateAccountApi,
	uploadStripeDocumentApi,
	addPaymentDetailsApi,
	getAllBankAccountsApi,
	makeDefaultAccountApi,
	deleteBankAccountApi,
	getReceivedPaymentsListApi,
	getPaymentPageDetailsApi,
	getPilotEarningsApi,
	sendInvoiceForProjectApi,
	sendTaxSummaryApi,
	getStripeCheckoutUrlApi,
	fetchStripeUrlApi,
	getPilotCreditCardsApi,
	checkForSubmittedDetailsApi,
} from './api'

export const updateAccount = body => {
	return {
		type: ACTION.UPDATE_ACCOUNT,
		payload: updateAccountApi(body),
	}
}

export const uploadStripeDocument = body => {
	return {
		type: ACTION.UPLOAD_STRIPE_DOCUMENT,
		payload: uploadStripeDocumentApi(body),
	}
}

export const addPaymentDetails = stripeToken => {
	return {
		type: ACTION.ADD_PAYMENT_DETAILS,
		payload: addPaymentDetailsApi(stripeToken),
	}
}

export const getAllBankAccounts = () => {
	return {
		type: ACTION.GET_ALL_BANK_ACCOUNTS,
		payload: getAllBankAccountsApi(),
	}
}

export const makeDefaultAccount = body => {
	return {
		type: ACTION.MAKE_DEFAULT_ACCOUNT,
		payload: makeDefaultAccountApi(body),
	}
}

export const deleteBankAccount = queryParams => {
	return {
		type: ACTION.DELETE_BANK_ACCOUNT,
		payload: deleteBankAccountApi(queryParams),
	}
}

export const getReceivedPaymentsList = queryParams => {
	return {
		type: ACTION.GET_RECEIVED_PAYMENTS_LIST,
		payload: getReceivedPaymentsListApi(queryParams),
	}
}

export const getPaymentPageDetails = () => {
	return {
		type: ACTION.GET_PAYMENT_PAGE_DETAILS,
		payload: getPaymentPageDetailsApi(),
	}
}

export const getPilotEarnings = queryParams => {
	return {
		type: ACTION.GET_PILOT_EARNINGS,
		payload: getPilotEarningsApi(queryParams),
	}
}

export const sendInvoiceForProject = body => {
	return {
		type: ACTION.SEND_INVOICE_FOR_PROJECT,
		payload: sendInvoiceForProjectApi(body),
	}
}

export const sendTaxSummary = body => {
	return {
		type: ACTION.SEND_TAX_SUMMARY,
		payload: sendTaxSummaryApi(body),
	}
}

export const getStripeCheckoutUrl = body => {
	return {
		type: ACTION.GET_STRIPE_CHECKOUT_URL,
		payload: getStripeCheckoutUrlApi(body),
	}
}

export const fetchStripeUrl = body => {
	return {
		type: ACTION.FETCH_STRIPE_URL,
		payload: fetchStripeUrlApi(body),
	}
}

export const getPilotCreditCards = () => {
	return {
		type: ACTION.GET_PILOT_CREDIT_CARDS,
		payload: getPilotCreditCardsApi(),
	}
}

export const checkForSubmittedDetails = queryParams => {
	return {
		type: ACTION.CHECK_FOR_SUBMITTED_DETAILS,
		payload: checkForSubmittedDetailsApi(queryParams),
	}
}
