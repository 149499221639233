import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from './orders.module.scss'
import { AeroCard, AeroEmpty, AeroSearch, AeroTable } from '../../AeroComponents'
import { getAllOrders } from '../../Stores/Projects/action'
import { ordersCols } from './table-cols'

import { Button } from 'antd'
import OrderEnquiryModal from './Components/Modals/OrderEnquiryModal'

import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'

class Orders extends Component {
	constructor(props) {
		super(props)
		this.state = {
			ordersCols: ordersCols(this),
			ordersData: [],
			totalPage: 1,
			enquiryModal: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.ordersData !== nextProps.ordersData) {
			this.setState({
				ordersData: nextProps.ordersData.data,
				totalPage: nextProps.ordersData.count,
			})
		}
	}

	componentDidMount() {
		this.props.getAllOrders()
	}

	goToOrderDetails = orderId => {
		this.props.history.push('/enterprise/orders/order-details/' + orderId)
	}

	searchOrder = value => {
		if (value) {
			this.props.getAllOrders({ searchToken: value })
		} else {
			this.props.getAllOrders()
		}
	}

	changePage = page => {
		this.props.getAllOrders({ pageNumber: page })
	}

	getContactInfo = () => {
		let email = this.props.profileData.email
		if (email === null) {
			email = ''
		}
		let mobileNumber = this.props.profileData.mobileNumber
		if (mobileNumber === null) {
			mobileNumber = ''
		}
		return { email: email, mobileNumber: mobileNumber }
	}

	openEnquiryModel = () => {
		this.setState({ enquiryModal: true })
	}

	closeEnquiryModel = () => {
		this.setState({ enquiryModal: false })
	}

	render() {
		const { ordersCols, ordersData, totalPage, enquiryModal } = this.state
		return (
			<div className={styles['page-container']}>
				<div className={styles['header']}>
					My Orders
					<Button className={styles['enquire-button']} type='primary' onClick={this.openEnquiryModel}>
						Enquire for a New Order
					</Button>
				</div>
				{ordersData && ordersData.length > 0 ? (
					<>
						{' '}
						<AeroSearch className={styles['en-search-box']} placeholder='Search by Order name / Manager Name' onSearch={this.searchOrder} />
						<AeroCard className={styles['en-orders-table-card']}>
							<AeroTable
								locale={{
									emptyText: <AeroEmpty image={AeroEmpty.PRESENTED_IMAGE_SIMPLE} description='No orders yet'></AeroEmpty>,
								}}
								pagination={{
									showQuickJumper: totalPage > 25,
									total: totalPage,
									defaultCurrent: 1,
									onChange: this.changePage,
								}}
								dataSource={ordersData}
								columns={ordersCols}
							></AeroTable>
						</AeroCard>
					</>
				) : (
					<div className={styles['empty-holder']}>
						{' '}
						<div>
							<MatImage src={IMAGES_SRC.EMPTY_ICON} /> <br />
							No orders to display.
							<br /> To create new order click on “Enquire for a new order”{' '}
						</div>
					</div>
				)}
				<OrderEnquiryModal
					enquiryModal={enquiryModal}
					closeEnquiryModel={this.closeEnquiryModel}
					mobileNumber={this.getContactInfo().mobileNumber}
					email={this.getContactInfo().email}
				></OrderEnquiryModal>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		ordersData: state.ProjectsReducer.ordersData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAllOrders: bindActionCreators(getAllOrders, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Orders)
