import React from 'react'
import ProjectPrefrences from '../OnBoarding/project-preferences'
import ChangePassword from './Components/change-password'
import EnterprisePilotPreferences from './Components/enterprise-pilot-preferences'

export const SettingsTabs = scope => {
	const { profileData, enterprisePilotData } = scope.state
	return [
		{
			key: '1',
			name: 'Password Sub Option',
			tab: 'Password',
			component: <ChangePassword updatePassword={scope.completeProfile} />,
		},
		{
			key: '2',
			name: 'Project Preference',
			tab: 'Project Preferences',
			component: <ProjectPrefrences updateProfile={scope.props.updateProfile} data={profileData['preferences']} />,
		},
		// {
		// 	key: '3',
		// 	name: 'Enterprise Project Preferences',
		// 	tab: 'Enterprise Project Preferences',
		// 	component: (
		// 		<EnterprisePilotPreferences
		// 			handleUpdatePopupOpen={scope.handleUpdatePopupOpen}
		// 			openCreateEnterprisePilotPopup={scope.openCreateEnterprisePilotPopup}
		// 			data={enterprisePilotData}
		// 		/>
		// 	),
		// },
	]
}
