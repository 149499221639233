import { Form } from '@ant-design/compatible'
import { Button, Spin, Input, Select, InputNumber } from 'antd'
import React from 'react'
import AeroField from '../../../AeroComponents/FormFields'
import { FREE_TRIAL_FIELDS } from '../../../AeroComponents/FormFields/Metadata/free-trial.fields'
import OtpComponent from './otp-component'
import { getConfigData } from '../../../Services/LocalStorageService'

const { Option } = Select

const FreeTrialDrawerContent = props => {
	const {
		formLoading,
		form,
		verificiationBtn,
		enableVerificationBtn,
		getVerificationCode,
		verificationCodeSent,
		phoneNumber,
		otpInput1,
		otpInput2,
		otpInput3,
		otpInput4,
		storeOtpInput,
		sendCodeBtn,
		editPhoneNumber,
		handleCountryCodeChange,
		type,
		fireInitiation,
	} = props

	const isdCode = getConfigData().isdCode

	return (
		<Spin spinning={formLoading}>
			<div style={{ fontSize: '20px' }}>Start your free trial!</div>
			<div style={{ fontSize: '16px' }}>Complete your details below to start your free trial</div>
			<div style={{ padding: '5px', marginTop: '15px' }}>
				<AeroField fieldsToRender={FREE_TRIAL_FIELDS} {...props} />
				{!verificationCodeSent && (
					<Form.Item label='Phone Number'>
						{form.getFieldDecorator('phoneNumber')(
							<Input.Group compact>
								<Select onChange={handleCountryCodeChange} style={{ borderRadius: '8px' }} defaultValue={isdCode}>
									<Option value={isdCode}>{isdCode}</Option>
								</Select>
								<InputNumber controls={false} onChange={enableVerificationBtn} style={{ borderRadius: '0px 8px 8px 0px', width: '78%' }} />
							</Input.Group>,
						)}
					</Form.Item>
				)}
			</div>
			<div style={{ textAlign: 'center' }}>
				{verificationCodeSent ? (
					<OtpComponent
						type={type}
						phoneNumber={phoneNumber}
						otpInput1={otpInput1}
						otpInput2={otpInput2}
						otpInput3={otpInput3}
						otpInput4={otpInput4}
						storeOtpInput={storeOtpInput}
						sendCodeBtn={sendCodeBtn}
						editPhoneNumber={editPhoneNumber}
						getVerificationCode={getVerificationCode}
						fireInitiation={fireInitiation}
					/>
				) : (
					<Button
						htmlType='submit'
						disabled={verificiationBtn}
						type='primary'
						style={{ borderRadius: '8px', width: '290px', height: '40px', background: type === 'mobile' ? '#00B3FF' : null, border: type === 'mobile' ? '0px' : null }}
					>
						Get Verification Code
					</Button>
				)}
			</div>
		</Spin>
	)
}

export default FreeTrialDrawerContent
