import * as ACTION from './actionTypes'
import { get, post } from '../../Api/http-interceptor'

export const getAllPlansApi = queryParams => {
	return get(ACTION.GET_ALL_PLANS, null, queryParams)
}

export const getAllActivePlansApi = queryParams => {
	return get(ACTION.GET_ALL_ACTIVE_PLANS, null, queryParams)
}

export const getAllExpiredPlansApi = queryParams => {
	return get(ACTION.GET_ALL_EXPIRED_PLANS, null, queryParams)
}

export const getAllAeroFlyDronesApi = queryParams => {
	return get(ACTION.GET_ALL_AEROFLY_DRONES, null, queryParams)
}

export const requestNewDroneApi = body => {
	return post(ACTION.REQUEST_NEW_DRONE, body)
}

export const customPlanEnquiryApi = body => {
	return post(ACTION.CUSTOM_PLAN_ENQUIRY, body)
}

export const createAeroFlyTransactionApi = body => {
	return post(ACTION.CREATE_AEROFLY_TRANSACTION, body)
}

export const aeroFlyPaymentApi = body => {
	return post(ACTION.AEROFLY_PAYMENT, body)
}

export const getAeroFlyBannerDetailsApi = queryParams => {
	return get(ACTION.GET_AEROFLY_BANNER_DETAILS, null, queryParams)
}

export const postIssueFacedApi = body => {
	return post(ACTION.POST_ISSUE_FACED, body)
}

export const generateOtpApi = body => {
	return post(ACTION.GENERATE_OTP, body)
}

export const initiateFreeTrialApi = body => {
	return post(ACTION.INITIATE_FREE_TRIAL, body)
}

export const activateFreeTrialApi = body => {
	return post(ACTION.ACTIVATE_FREE_TRIAL, body)
}
