import { Button } from 'antd'
import React from 'react'
import PlanCard from './plan-card'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import MatImage from '../../../AeroComponents/Image'

const AllPlansModalContent = props => {
	const { toggleContactDrawer, toggleDroneDrawer, allPlans, selectPlan, selectedPlan, checkoutWithStripe, toggleFreeTrialDrawer, type, payBtnLoading } = props
	return (
		<>
			<p style={{ fontSize: type === 'mobile' ? '20px' : '24px' }}>Plans</p>
			<div style={{ fontSize: '16px', color: '#595959' }}>
				All plans give you access to <u>Aeropath</u>
			</div>
			<div style={{ textAlign: 'center', marginTop: '20px' }}>
				{allPlans.map(item => (
					<PlanCard type={type} data={item} key={item.planId} selectPlan={selectPlan} selectedPlan={selectedPlan} />
				))}
			</div>
			<div style={{ color: '#8D9097', textAlign: 'justify', marginTop: '15px' }}>
				<MatImage src={IMAGES_SRC.INFO_DRONE_SUPPORT_ICON} />
				Before making a purchase, be sure to check if your{' '}
				<Button style={{ position: 'relative', right: '11px' }} type='link' onClick={toggleDroneDrawer}>
					drone
				</Button>{' '}
				<span style={{ marginLeft: '-25px' }}>is supported by Aerologix</span>
			</div>
			<div style={{ textAlign: 'center', marginTop: '15px' }}>
				<Button onClick={toggleContactDrawer} style={{ position: 'relative', left: '10px' }} type='link'>
					Contact
				</Button>{' '}
				our team for Custom Plan
			</div>
			<div style={{ textAlign: 'center', padding: '15px' }}>
				{selectedPlan && type === 'web' ? (
					selectedPlan.planType === 'FREE_TRIAL' ? (
						<Button onClick={toggleFreeTrialDrawer} type='primary' style={{ borderRadius: '8px', width: '386px', height: '40px' }}>
							Continue
						</Button>
					) : (
						<Button loading={payBtnLoading} onClick={checkoutWithStripe} type='primary' style={{ borderRadius: '8px', width: '386px', height: '40px' }}>
							Pay {selectedPlan.currencySymbol}
							{selectedPlan.price}
						</Button>
					)
				) : null}
			</div>
		</>
	)
}

export default AllPlansModalContent