/*eslint-disable*/
import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Descriptions, Upload, Button, Spin, Divider, Input, message } from 'antd'
import { ARN_DETAILS_FIELDS } from '../../../AeroComponents/FormFields/Metadata/arn-details.fields'
import AeroField from '../../../AeroComponents/FormFields'
import { addArnDetails } from '../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import '../profile.sass'
import { CheckCircleFilled, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

const { Search } = Input

class ArnDetailsForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fieldsToRender: ARN_DETAILS_FIELDS(this),
			fileList: [],
			loading: false,
			noMoreUploads: false,
		}
	}

	componentDidMount() {
		this.props.form.setFieldsValue(this.props.data)
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({
					loading: true,
				})
				const { fileList } = this.state
				let body = new FormData()

				let fileData
				fileList.forEach(file => {
					fileData = file
				})
				body.append('arnFile', fileData)
				body.append('arnLicenseNumber', values.arnLicenseNumber)
				this.props
					.addArnDetails(body)
					.then(
						res => {
							this.setState({ loading: false })

							if (res.value.status_code === 200) {
								this.props.closeModal(res.value.data)
							}
						},

						res => {
							console.log('err block', res)
						},
					)
					.catch(res => {
						console.log('catch block', res)
					})
			}
		})
	}

	retry = () => {
		this.props.retry('ARN_DETAILS')
	}

	render() {
		const { fieldsToRender, fileList, loading } = this.state
		const { viewMode, data } = this.props

		const props = {
			onRemove: file => {
				this.setState(state => {
					const index = state.fileList.indexOf(file)
					const newFileList = state.fileList.slice()
					newFileList.splice(index, 1)
					return {
						fileList: newFileList,
						noMoreUploads: false,
					}
				})
			},
			beforeUpload: file => {
				const isJpgOrPngOrPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
				if (!isJpgOrPngOrPdf) {
					message.error('You can only upload JPG/PNG/PDF file!')
				}
				const isLt2M = file.size / 1024 / 1024 < 10
				if (!isLt2M) {
					message.error('File must be smaller than 10 MB!')
				}
				if (isLt2M && isJpgOrPngOrPdf) {
					this.setState(state => ({
						fileList: [...state.fileList, file],
						noMoreUploads: true,
					}))
					return false
				}
			},
			fileList,
		}
		return (
			<Form layout='vertical' onSubmit={this.handleSubmit}>
				<Spin spinning={loading}>
					{viewMode ? (
						<div>
							<Descriptions size='small'>
								<Descriptions.Item label={<span style={{ color: '#84818A' }}>ARN</span>}>{data.licenseNumber}</Descriptions.Item>
								<Descriptions.Item label={<span style={{ color: '#84818A' }}>License</span>}>
									<Button type='link' href={data.documentUrl} target='_blank' style={{ position: 'relative', bottom: '5px', right: '20px' }}>
										Click to view
									</Button>
								</Descriptions.Item>

								{data.verificationStatus === 'PENDING_VERIFICATION' ? (
									<Descriptions.Item>
										<span className='verification-state' style={{ background: '#3487EB' }}>
											<ClockCircleOutlined style={{ position: 'relative', bottom: '3px' }} /> Verification Pending
										</span>
									</Descriptions.Item>
								) : data.verificationStatus === 'VERIFICATION_FAILED' ? (
									<Descriptions.Item>
										<span className='verification-state' style={{ background: '#F75151' }}>
											<CloseCircleOutlined style={{ position: 'relative', bottom: '3px' }} /> Verification Failed
										</span>
									</Descriptions.Item>
								) : (
									<Descriptions.Item>
										<span
											style={{
												border: '1px solid rgba(0, 0, 0, 0.15',
												color: '#6CBE44',
												fontSize: '12px',
												borderRadius: '8px',
												padding: '5px 20px 5px 20px',
												position: 'relative',
												bottom: '5px'
											}}
										>
											<CheckCircleFilled style={{ position: 'relative', bottom: '3px' }} /> Verified
										</span>
									</Descriptions.Item>
								)}
							</Descriptions>
							{data.verificationStatus === 'VERIFICATION_FAILED' ? (
								<div>
									<Divider></Divider>
									<Descriptions>
										<Descriptions.Item label={<span style={{ color: '#84818A' }}>Reason for ARN Rejection</span>}>{data.reason}</Descriptions.Item>
									</Descriptions>
								</div>
							) : (
								''
							)}
							{data.verificationStatus === 'VERIFICATION_FAILED' ? (
								<Button style={{ float: 'right', width: '240px', fontSize: '16px', height: '48px' }} onClick={this.retry} type='primary'>
									Retry For Verification
								</Button>
							) : (
								''
							)}
						</div>
					) : (
						<>
							<AeroField fieldsToRender={fieldsToRender} {...this.props} />
							<Form.Item label='ARN Certificate (jpeg, png or pdf)'>
								<Upload style={{ width: '100%' }} {...props}>
									<Button style={{ height: '48px' }} disabled={this.state.noMoreUploads}>
										Select File
									</Button>
								</Upload>
							</Form.Item>
							<Form.Item>
								<Button
									style={{ float: viewMode ? 'right' : null, width: viewMode ? '240px' : null, fontSize: '16px', height: '48px' }}
									disabled={fileList.length === 0}
									type='primary'
									block
									htmlType='submit'
								>
									Submit For Verification
								</Button>
							</Form.Item>
						</>
					)}
				</Spin>
			</Form>
		)
	}
}

const ArnDetails = Form.create({ name: 'ARN_DETAILS_FORM' })(ArnDetailsForm)

const mapStateToProps = state => {
	return {
		addArnDetailsSuccess: state.PaymentsReducer.addArnDetailsSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		addArnDetails: bindActionCreators(addArnDetails, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ArnDetails)
