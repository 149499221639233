export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPLOAD_STRIPE_DOCUMENT = 'UPLOAD_STRIPE_DOCUMENT'
export const ADD_PAYMENT_DETAILS = 'ADD_PAYMENT_DETAILS'
export const GET_ALL_BANK_ACCOUNTS = 'GET_ALL_BANK_ACCOUNTS'
export const MAKE_DEFAULT_ACCOUNT = 'MAKE_DEFAULT_ACCOUNT'
export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT'
export const GET_RECEIVED_PAYMENTS_LIST = 'GET_RECEIVED_PAYMENTS_LIST'
export const GET_PAYMENT_PAGE_DETAILS = 'GET_PAYMENT_PAGE_DETAILS'
export const GET_PILOT_EARNINGS = 'GET_PILOT_EARNINGS'
export const SEND_INVOICE_FOR_PROJECT = 'SEND_INVOICE_FOR_PROJECT'
export const SEND_TAX_SUMMARY = 'SEND_TAX_SUMMARY'
export const GET_STRIPE_CHECKOUT_URL = 'GET_STRIPE_CHECKOUT_URL'
export const FETCH_STRIPE_URL = 'FETCH_STRIPE_URL'
export const GET_PILOT_CREDIT_CARDS = 'GET PILOT CREDIT CARDS'
export const CHECK_FOR_SUBMITTED_DETAILS = 'CHECK_FOR_SUBMITTED_DETAILS'
