import React, { useState } from 'react'
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements'
import { Button, message, Spin } from 'antd'

import styles from '../enterprisePilotPlus.module.scss'

const CardSetupForm = ({ stripe, elements, termsAndConditionsModalData, completePilotEnterprisePlus, handleNext }) => {
	const [loader, setLoader] = useState(false)
	const [name, setName] = useState('')
	const createOptions = (fontSize, padding) => {
		return {
			style: {
				base: {
					fontSize,
					color: '#424770',
					letterSpacing: '0.025em',
					fontFamily: 'Source Code Pro, monospace',
					'::placeholder': {
						color: '#aab7c4',
					},
					padding,
				},
				invalid: {
					color: '#9e2146',
				},
			},
		}
	}

	const handleSubmit = async () => {
		try {
			if (stripe && elements) {
				const card = elements.getElement('cardNumber')
				const paymentMethod = await stripe.createPaymentMethod({
					type: 'card',
					card: card,
					billing_details: {
						name,
					},
				})
				if (paymentMethod.error || !name.trim()) {
					return message.error('Please fill all the details')
				}
				const stripeToken = await stripe.createToken({ type: 'card', name })

				setLoader(true)
				// S3 upload
				let requestObj = {
					method: 'put',
					redirect: 'follow',
					headers: new Headers(),
					body: termsAndConditionsModalData.pdfFile,
				}
				const res = await fetch(termsAndConditionsModalData.contractDetails.uploadUrl, requestObj)

				let payload = {
					signedContract: res.url,
					creditCardId: paymentMethod.paymentMethod.card.last4,
					paymentMethodId: paymentMethod.paymentMethod.id,
					cardToken: stripeToken.token.id,
					nameOnCard: name,
				}
				setLoader(false)
				const { value } = await completePilotEnterprisePlus(payload)
				if (value.status_code === 200) {
					handleNext()
				}
			} else {
				message.error("Stripe.js hasn't loaded yet. Please retry")
			}
		} catch (error) {
			message.error(error)
			setLoader(false)
		}
	}

	return (
		<>
			{loader ? <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '45%', width: '100%', right: '0%' }} /> : ''}
			<div className={styles['form']}>
				<label className={styles['label']}>
					Card number
					<CardNumberElement {...createOptions(20)} />
				</label>
				<label className={styles['label']}>
					Expiration date
					<CardExpiryElement {...createOptions(20)} />
				</label>
				<label className={styles['label']}>
					CVC
					<CardCVCElement {...createOptions(20)} />
				</label>
				<label className={styles['label']}>
					Card holder Name
					<input className={styles['name']} placeholder='Card holder Name' type='text' value={name} onChange={e => setName(e.target.value)} />
				</label>
				<div className={styles['submit_button']}>
					<Button type='primary' onClick={handleSubmit} style={{ width: '200px', height: '50px', fontWeight: 500, fontSize: '16px' }}>
						Complete Registration
					</Button>
				</div>
			</div>
		</>
	)
}

export default injectStripe(CardSetupForm)
