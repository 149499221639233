/*eslint-disable*/
export const dev = {
	AEROLOGIX: 'http://13.54.244.56:1400/api/v1/',
}

// const stage = {
// };

// const preprod = {
// };

export const prod = {
	AEROLOGIX: 'https://api.aerologix.com/api/v1/',
}

// export default { dev }[APP_ENV]
