import React from 'react'
import {  Table } from 'antd'
 
 


function AeroTable(props) {
    
    return (
        <Table {...props} />
    )
}

export default AeroTable





