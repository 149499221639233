import React, { Component } from 'react'
import { Input, Button, Pagination } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import GettySubmissionList from './getty-submission-list'

class GettyTabContent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			searchToken: '',
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.location.pathname !== nextProps.location.pathname) {
			let search = nextProps.location.search.split('search=')[1]
			this.setState({ searchToken: search })
		}
	}

	textChange = event => {
		this.setState({ searchToken: event.target.value })
	}

	render() {
		return (
			<>
				<Input.Search
					onChange={this.textChange}
					value={this.state.searchToken}
					onSearch={this.props.onItemSearch}
					style={{ width: '340px', borderRadius: '8px' }}
					placeholder='Search by Submission Title'
				/>
				<span style={{ float: 'right' }}>
					<Button onClick={this.props.toggleNewSubmissionModal} style={{ borderRadius: '8px' }} type='primary'>
						<PlusOutlined style={{ position: 'relative', bottom: '3px' }} />
						New Submission
					</Button>
				</span>
				<div style={{ margin: '10px' }}>
					<GettySubmissionList {...this.props} />
					<Pagination onChange={this.props.onPageChange} style={{ float: 'right' }} current={Number(this.props.currentPage)} total={this.props.count} />
				</div>
			</>
		)
	}
}

export default GettyTabContent
