import React from 'react'
import DynamicFields from '../dynamic-fields'

export const DRONE_DETAILS_FIELDS = scope => {
	return [
		{
			fieldName: 'DRONE_DETAILS_SET',
			fieldLabel: null,
			fieldKey: 'droneDetails',
			fileObject: {
				initialValue: [0],
				rules: [],
			},
			fieldToRender: <DynamicFields droneTypesData={scope.props.droneTypesData} fieldKey={'droneDetails'} {...scope.props} />,
		},
	]
}
