import React from 'react'
import moment from 'moment'
import { Form, DatePicker, Input, Row, Col, Button } from 'antd'
import { validateEmailAddress } from '../../../../Services/UtilityService'
const { RangePicker } = DatePicker

const TaxTab = props => {
	const { userEmail, sendSingleInvoice } = props
	const disabledDate = current => {
		return current > moment().endOf('day')
	}

	return (
		<div>
			<div style={{ fontSize: '16px', color: '#47464A', marginBottom: '15px' }}>Get your Tax statement</div>
			<Form
				layout='vertical'
				onFinish={sendSingleInvoice}
				initialValues={{
					email: userEmail,
				}}
			>
				<Row>
					<Col span={8}>
						<Form.Item rules={[{ required: true, message: 'Please select from and to date!' }]} name='range' label='Statement From- To'>
							<RangePicker placeholder={['From', 'To']} size='large' disabledDate={disabledDate} />
						</Form.Item>
					</Col>
					<Col span={8} offset={2}>
						<Form.Item
							rules={[{ required: true, message: 'Please enter email!', whitespace: true }, { validator: validateEmailAddress }]}
							name='email'
							label='Send Statement to'
						>
							<Input type='email' size='large' style={{ borderRadius: '8px', width: '65%' }} />
						</Form.Item>
					</Col>
				</Row>
				<Button htmlType='submit' type='primary' style={{ width: '240px', height: '48px', marginTop: '20px' }}>
					Mail tax Summary
				</Button>
			</Form>
		</div>
	)
}

export default TaxTab
