export const TEXTS = {
	LOC: 'Location',
	PN: 'Project Name',
	NI: 'Number of Images',
	RI: 'Resolution of Images',
	NV: 'Number of Videos',
	RV: 'Resolution of Videos',
	DES: 'Description',
	DL: 'Deadline',
	PB: 'Project Budget',
	CID: 'Select City',
	SID: 'Select State',
	HT: 'Height of the Object (in m)',
	LT: 'Length of the Object (in m)',
	BT: 'Breadth of the Object (in m)',
}

export const CONSUMER_ALERT_TEXT = {
	ALLOCATE_PILOT: "Your project has been published. You'll soon start receiving the bids from interested pilots",
	ALLOTTED: 'You have accepted a bid from a pilot. The pilot will soon commence the flight, capture the data and share it with you.',
	IN_FLIGHT: "The pilot has commenced the flight. They'll soon submit the captured data",
	COMPLETED:
		"Good News! The pilot has submitted the data. You've 7 days to download and review the data. The payment will be automatically made to pilot if you fail to review the quality of data",
	RESOLVED: 'The project was completed on ',
	DATA_REJECTED_FINAL: "Uh oh! We're sorry that you found the data unsatisfactory. Please have a chat with them to explain your requirements and expectations",
}
export const PILOT_ALERT_TEXT = {
	ALLOTTED:
		"Congratulations! You're allocated this project. Please make sure you're completely updated with the project requirements. Mark the project In-Flight when you commence the flight",
	IN_FLIGHT: 'Have a safe flight! Once completed, you would have to upload the captured data and submit it to the client',
	COMPLETED: "Your data has been submitted and is being reviewed by the client. You'll be updated within 14 working days.",
	RESOLVED: 'Congratulations on completing the project! Your earnings have been credited to your specified bank account ',
	DATA_REJECTED_FINAL: 'Uh oh! The client is not satisfied with the data. Please have a chat with them to understand their requirements and expectations',
}
export const INITIALIZE_CHAT_ERROR = 'Could not initialize chat. Try refreshing the page.'
