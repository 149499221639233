import React, { Component } from 'react'
import { Divider } from 'antd'
import { CalendarOutlined, VideoCameraOutlined } from '@ant-design/icons'
import moment from 'moment'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'

class GettySubmissionList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
		}
	}

	openContribution = id => {
		this.props.history.push(`/getty/submission/${id}`)
	}

	render() {
		return (
			<>
				{this.props.data
					? this.props.data.map((item, index) => (
							<div key={index} style={{ marginTop: '30px', cursor: 'pointer' }} onClick={() => this.openContribution(item.submissionId)}>
								<div className='getty-thumbnail'>
									{item.thumbnailUrl !== null && item.thumbnailUrl !== '' ? (
										<img style={{ borderRadius: '20%', maxWidth: '70px' }} alt='getty-thumb' src={item.thumbnailUrl} />
									) : (
										<MatImage src={IMAGES_SRC.VIDEOS_PROJECT} />
									)}
									<div style={{ opacity: '80%' }} className='getty-thumb-tag'>
										{item.noOfContributions}
									</div>
								</div>
								<div className='getty-thumb-meta-box'>
									<div style={{ fontSize: '16px' }}>
										<strong>{item.submissionName}</strong>
									</div>
									<div style={{ fontSize: '12px', color: '#595959', position: 'relative', top: '10px' }}>
										<CalendarOutlined style={{ position: 'relative', bottom: '3px' }} /> <span style={{ fontWeight: '500' }}>Submission Created On:</span>{' '}
										{moment(item.createdAt).format('DD/MM/YYYY')}{' '}
										<span style={{ marginLeft: '50px' }}>
											<VideoCameraOutlined style={{ position: 'relative', bottom: '3px' }} /> <span style={{ fontWeight: '500' }}>Event Type:</span>{' '}
											{item.eventType}
										</span>
									</div>
								</div>
								<Divider />
							</div>
					  ))
					: ''}
			</>
		)
	}
}

export default GettySubmissionList
