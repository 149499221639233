/*eslint-disable*/
import React from 'react'
import './on-boarding.sass'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Button, Spin, Drawer, Row, Col, Select } from 'antd'
import { contactSupport } from '../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const { Option } = Select

class ContactSupport extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			visible: true,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.contactSupportSuccess !== nextProps.contactSupportSuccess) {
			this.setState({ loading: false })
			if (nextProps.contactSupportSuccess) {
				this.onClose()
			}
		}
	}

	onClose = () => {
		this.props.onClose()
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({ loading: true })
				this.props.contactSupport({ description: values.description, subject: values.subject })
			}
		})
	}

	render() {
		const { getFieldDecorator } = this.props.form
		const { loading, visible } = this.state
		return (
			<Drawer title='Contact Support' width={500} onClose={this.onClose} visible={visible}>
				<Form onSubmit={this.handleSubmit}>
					<Spin spinning={loading}>
						<Row>
							<Col span={12}>
								<Form.Item>
									{getFieldDecorator('subject', {
										rules: [{ required: true, message: 'Please select a subject' }],
									})(
										<Select placeholder='Please select a subject'>
											<Option value='Recovery link is not working'>Recovery link is not working</Option>
											<Option value='I have forgotten my emai ID'>I have forgotten my emai ID</Option>
											<Option value='Others'>Others</Option>
										</Select>,
									)}
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item>
									{getFieldDecorator('description', {
										rules: [
											{
												required: true,
												message: 'Please enter a description',
											},
										],
									})(<Input.TextArea rows={4} placeholder='Please enter a description' />)}
								</Form.Item>
							</Col>
						</Row>

						<div className='drawer-footer'>
							<Button onClick={this.onClose} style={{ marginRight: 8 }}>
								Cancel
							</Button>
							<Button onClick={this.handleSubmit} type='primary' htmlType='submit'>
								Submit
							</Button>
						</div>
					</Spin>
				</Form>
			</Drawer>
		)
	}
}

const WrapperContactSupport = Form.create()(ContactSupport)

const mapStateToProps = state => {
	return {
		contactSupportSuccess: state.UserSessionReducer.contactSupportSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		contactSupport: bindActionCreators(contactSupport, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(WrapperContactSupport)
