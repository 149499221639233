import React from 'react'
import moment from 'moment'
import { Button } from 'antd'
import { goTo } from '../../Services/NavigationService'
import { getConfigData } from '../../Services/LocalStorageService'

export const receivedPaymentsTableColumns = scope => {
	return [
		{
			title: 'Project Name',
			dataIndex: 'projectName',
			key: 'projectName',
			render: (projectName, { projectId }) => (
				<Button style={{ position: 'relative', right: '15px' }} type='link' onClick={() => goTo('home/projects/' + projectId)}>
					{projectName}
				</Button>
			),
			fixed: 'left',
			className: 'payments-name-col',
		},
		{
			title: 'Cost',
			dataIndex: 'amount',
			key: 'amount',
			render: amount => getConfigData().currency + ' ' + amount,
			className: 'payments-col',
		},
		// {
		// 	title: 'Bank Account',
		// 	dataIndex: 'bankAccount',
		// 	key: 'bankAccount',
		// },
		{
			title: 'Completed On',
			dataIndex: 'timeStamp',
			key: 'timeStamp',
			render: timeStamp => moment(timeStamp).format('DD/MM/YYYY'),
			className: 'payments-col',
		},
		{
			title: 'Invoice',
			dataIndex: 'projectId',
			key: 'invoice',
			render: (projectId, { projectName }) => (
				<Button style={{ position: 'relative', right: '15px' }} type='link' onClick={() => scope.mailSingleInvoice({ id: projectId, name: projectName })}>
					Mail
				</Button>
			),
			className: 'payments-col',
		},
	]
}
