import { Button, Card, Descriptions, Avatar } from 'antd'
import React from 'react'
import moment from 'moment'

const ActivePlanCard = props => {
	const { data, type, repurchase } = props
	const HEAD_PAINT = { ORGANIZATION_LICENSE_KEY: '#0FD2DE', PAID: '#9373FF', FREE_TRIAL: '#9373FF', PROJECT: '#FF6699' }
	return (
		<Card>
			<Avatar style={{ backgroundColor: HEAD_PAINT[data.planType], position: 'relative', bottom: '-29px' }}>{data.displayText[0]}</Avatar>
			<Descriptions
				style={{ position: 'relative', left: '42px', maxWidth: '55vw' }}
				title={data.displayText}
				column={3}
				headStyle={{ background: HEAD_PAINT[data.displayText[0]], color: 'white', borderRadius: '12px 12px 0px 0px' }}
			>
				<Descriptions.Item label='Flight Modules'>{data.modules}</Descriptions.Item>
				{type === 'expired' ? (
					<Descriptions.Item label={data.planType === 'PROJECT' ? 'Completed On' : 'Expired On'}>
						{moment
							.utc(data.endDate)
							.local()
							.format("MMM D 'YY")}
					</Descriptions.Item>
				) : (
					<>
						{data.planType === 'ORGANIZATION_LICENSE_KEY' || data.planType === 'FREE_TRIAL' || data.planType === 'PAID' ? (
							<Descriptions.Item label={data.planType === 'PAID' ? 'Bought On' : 'Activated On'}>
								{moment
									.utc(data.purchasedDate)
									.local()
									.format("MMM D 'YY")}
							</Descriptions.Item>
						) : null}
						<Descriptions.Item label='Valid Till'>
							{moment
								.utc(data.endDate)
								.local()
								.format("MMM D 'YY")}
						</Descriptions.Item>
						{data.planType === 'FREE_TRIAL' || data.planType === 'PROJECT' ? <Descriptions.Item label='Flight Quota'>{data.availableQuota}</Descriptions.Item> : null}
					</>
				)}
			</Descriptions>
			{type === 'expired' ? (
				<>
					{data.planType !== 'FREE_TRIAL' ? (
						<Button
							onClick={() => repurchase(data)}
							style={{ borderRadius: '8px', float: 'right', position: 'relative', bottom: '81px', right: '56px', width: '215px', height: '50px' }}
							type='primary'
						>
							Re-Purchase
						</Button>
					) : null}
				</>
			) : null}
		</Card>
	)
}
export default ActivePlanCard