import { CheckOutlined, CloseCircleFilled, CloseOutlined, MessageFilled } from '@ant-design/icons'
import { Avatar, Button, Descriptions } from 'antd'
import React, { useEffect, useState } from 'react'
import MatImage from '../../../AeroComponents/Image'
import { capitalize } from '../../../Services/UtilityService'
import { getWebConfig } from '../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const BidCard = props => {
	const { data, openBidActionModal, showModal, openPilotProfileModal, type, openBidHistory, openChat, projectState, webConfig, getWebConfig } = props
	const [configData, setConfigData] = useState(webConfig)
	const [waitForApi, setWaitForApi] = useState(false)

	useEffect(() => {
		if (!configData) {
			setWaitForApi(true)
			getWebConfig()
		}
	}, [configData])

	useEffect(() => {
		if (webConfig && waitForApi) {
			setWaitForApi(false)
			setConfigData(webConfig)
		}
	}, [webConfig])

	const renderContent = renderType => {
		return (
			<Descriptions
				colon={false}
				className={renderType === 'web' ? 'create-new-project-form' : 'create-new-project-form-mobile'}
				labelStyle={{ color: '#84818A' }}
				column={
					renderType === 'web'
						? data.twilio_chatid && (data.status === 'PENDING' || data.status === 'REJECTED' || data.status === 'WITHDRAWN')
							? 4
							: data.twilio_chatid || (data.status === 'PENDING' || data.status === 'REJECTED' || data.status === 'WITHDRAWN')
								? 3
								: 2
						: 1
				}
			>
				<Descriptions.Item
					label={
						data.pilot.profilePicture ? (
							<div className='circular-header-dp'>
								<MatImage style={{ borderRadius: '50%', maxWidth: '32px' }} src={data.pilot.profilePicture} />
							</div>
						) : (
							<Avatar>{data.pilot.firstName.substring(0, 1)}</Avatar>
						)
					}
				>
					<Button type='link' onClick={() => openPilotProfileModal(data.pilot, data.amount)}>
						{capitalize(data.pilot.firstName)}&nbsp;{data.pilot.lastName}
					</Button>
				</Descriptions.Item>
				<Descriptions.Item label={<span style={{ position: 'relative', top: '5px' }}>Bid Amount:</span>}>
					<span style={data.status === 'PENDING' || data.status === 'REJECTED' || data.status === 'WITHDRAWN' ? { position: 'relative', top: '5px' } : null}>
						{configData.currency + ' ' + data.amount} {data.is_modified ? <span className='modified bid-tag'>Modified</span> : null}{' '}
						{type === 'accepted' ? (
							<Button onClick={() => openBidHistory(data.quote_id)} type='link'>
								Bid History
							</Button>
						) : null}
					</span>
				</Descriptions.Item>

				{data.twilio_chatid ? (
					<Descriptions.Item>
						<Button
							type='link'
							onClick={() => {
								openChat(
									data.twilio_chatid,
									data.pilot.firstName + ' ' + data.pilot.lastName,
									data.amount,
									data.pilot.profilePicture,
									projectState === 'RESOLVED' || data.status === 'INVALID' || data.status === 'WITHDRAWN' ? true : false,
									data,
								)
							}}
						>
							{' '}
							<MessageFilled style={{ position: 'relative', bottom: '3px', color: '#17BAFF', fontSize: '16px' }} /> Chat &nbsp;{' '}
							{data.unreadMessagesCount ? <span className='modified bid-tag'> new messages</span> : ''}
						</Button>
					</Descriptions.Item>
				) : null}

				{data.status === 'PENDING' ? (
					<Descriptions.Item>
						<Button onClick={() => openBidActionModal('rejectBidModal', data.quote_id)} type='link'>
							<CloseOutlined style={{ position: 'relative', bottom: '3px' }} /> Reject
						</Button>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<Button onClick={() => showModal(data.pilot, data.quote_id)} type='link'>
							<CheckOutlined style={{ position: 'relative', bottom: '3px' }} />
							Accept
						</Button>
					</Descriptions.Item>
				) : data.status === 'REJECTED' || data.status === 'WITHDRAWN' ? (
					<Descriptions.Item>
						<span style={{ marginLeft: '10px' }} className='bid-tag rejected'>
							<CloseCircleFilled style={{ position: 'relative', bottom: '3px' }} />
							&nbsp;&nbsp;Bid {data.status === 'REJECTED' ? 'Rejected' : 'Withdrawn'}
						</span>
					</Descriptions.Item>
				) : null}
			</Descriptions>
		)
	}

	return (
		<>
			{renderContent('web')}
			{renderContent('mobile')}
		</>
	)
}
const mapStateToProps = state => {
	return {
		webConfig: state.UserSessionReducer.webConfig,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BidCard)
