import { post, get } from '../../Api/http-interceptor'
import { pushEventToMixpanel } from '../../Services/MixpanelService'

export const signUpApi = async body => {
	const res = await post('REGISTER', body)

	// MIXPANEL SIGNUP EVENT
	if (res?.status_code === 200) {
		const profileType = body.profileType === 'CONSUMER' ? 'Client' : 'Pilot'
		const payload = {
			[`${profileType} ID`]: res.data.id,
			'User Type': profileType,
			'First Name': res.data.firstName,
			'Last Name': res.data.lastName,
			Email: res.data.email,
			'Sign Up Date Time': new Date().getTime(),
			'Has Referral Code': res.data.referralCode ? true : false,
			'Sign Up Method': 'Email',
			'Mobile No': res.data.mobileNumber,
		}
		if (body.profileType === 'CONSUMER') {
			delete payload['Has Referral Code']
		}
		pushEventToMixpanel(body.profileType, 'Sign Up', payload)
	}

	return res
}

export const checkReferralCodeApi = queryParams => {
	return get('CHECK_REFERRAL_CODE', null, queryParams)
}
