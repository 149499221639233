import React from 'react'

import moment from 'moment'
import { PROJECT_TYPE } from '../../constants'
import ProjectStatusCard from './ProjectStatusCard'

export default function CompletedProjectsCards(props) {
	// extract props
	const { index, completedProjects, downloadProjectData, openAdditionalDataModal } = props

	// if current index is lesser than scheduled projects length
	if (!(index > completedProjects.length - 1)) {
		const mapLink = 'https://www.google.com/maps/place/' + completedProjects[index].latitude + ',' + completedProjects[index].longitude

		return (
			<ProjectStatusCard
				name={completedProjects[index].name}
				mapLink={mapLink}
				address={completedProjects[index].address.substring(0, 15) + '...'}
				status='completed'
				projectType={PROJECT_TYPE[completedProjects[index].type]}
				completedDate={moment(completedProjects[index].updated_at).format('DD/MM/YYYY')}
				downloadProjectData={downloadProjectData}
				projectId={completedProjects[index].projectId}
				projectData={completedProjects[index]}
				openAdditionalDataModal={openAdditionalDataModal}
			></ProjectStatusCard>
		)
	}
	// else return empty
	else return ''
}
