import * as ACTION from './actionTypes'

import {
	getAllContributionsApi,
	updateContributionApi,
	deleteContributionApi,
	submitSubmissionApi,
	getMultipartUrlsApi,
	createContributionApi,
	uploadMultipartFileApi,
  getPreviousSubmissionsApi, getSavedSubmissionsApi, createGettySubmissionApi,
  bulkUpdateContributionApi

} from './api'

 
 

export const getPreviousSubmissions = queryParams => {
	return {
		type: ACTION.GET_PREVIOUS_SUBMISSIONS,
		payload: getPreviousSubmissionsApi(queryParams),
	}
}

export const getSavedSubmissions = queryParams => {
	return {
		type: ACTION.GET_SAVED_SUBMISSIONS,
		payload: getSavedSubmissionsApi(queryParams),
	}
}

export const createGettySubmission = body => {
	return {
		type: ACTION.CREATE_GETTY_SUBMISSION,
		payload: createGettySubmissionApi(body),
	}
}
 


export const getAllContributions = (queryParams) => {
	return {
		type: ACTION.GET_ALL_CONTRIBUTIONS,
		payload: getAllContributionsApi(queryParams),
	}
}


export const updateContribution = (body) => {
	return {
		type: ACTION.UPDATE_CONTRIBUTION,
		payload: updateContributionApi(body),
	}
}


export const deleteContribution = (queryParams) => {
	return {
		type: ACTION.DELETE_CONTRIBUTION,
		payload: deleteContributionApi(queryParams),
	}
}

export const submitSubmission = (body) => {
	return {
		type: ACTION.SUBMIT_SUBMISSION,
		payload: submitSubmissionApi(body),
	}
}

export const getMultipartUrls = (body) => {

	return {
		type: ACTION.UPLOAD_CONTRIBUTION,
		payload: getMultipartUrlsApi(body)
			.then(res => {
				return { ...res, ...body }
			}),
	}
}

export const uploadMultipartFile = ({ body, submissionBatchId ,filename}) => {
	return {
		type: ACTION.UPLOAD_MULTIPART_FILE_GETTY,
		payload: uploadMultipartFileApi(body).then(res => {
			return { ...res, submissionBatchId: submissionBatchId, filename : filename }
		}),
	}
}

export const createContribution = ({ body, submissionBatchId }) => {
	return {
		type: ACTION.CREATE_CONTRIBUTION,
		payload: createContributionApi(body).then(res => {
			return { ...res, submissionBatchId: submissionBatchId }
		}),
	}
}

export const removeUpload = (body) => {
	return {
		type: ACTION.REMOVE_UPLOAD,
		payload: body,
	}
}

export const addToUpload = (body) => {
	return {
		type: ACTION.ADD_TO_UPLOAD,
		payload: body,
	}
}

export const addContribution = (body) => {
	  
		return {
			type: ACTION.ADD_CONTRIBUTION,
			payload: body,
		}

	 
}


export const removeContribution = (body) => {
	return {
		type: ACTION.REMOVE_CONTRIBUTION,
		payload: body,
	}
}


export const addFile = (body) => {
	return {
		type: ACTION.ADD_FILE,
		payload: body,
	}
}


export const removeFile = (body) => {
	return {
		type: ACTION.REMOVE_FILE,
		payload: body,
	}
}


export const bulkUpdate = (body) => {
	return {
		type: ACTION.BULK_UPDATE,
		payload: bulkUpdateContributionApi(body).then(res => {
			return { ...res, request: body }
		}),
	}
}

 
