import moment from 'moment'

import { post, get, put, deleteMethod } from '../../Api/http-interceptor'
import { pushEventToMixpanel } from '../../Services/MixpanelService'

export const updateAccountApi = body => {
	return post('UPDATE_ACCOUNT', body)
}

export const uploadStripeDocumentApi = body => {
	return post('UPLOAD_STRIPE_DOCUMENT', body)
}

export const addPaymentDetailsApi = token => {
	const data = { payment: token }
	return post('ADD_PAYMENT_DETAILS', data)
}

export const getAllBankAccountsApi = () => {
	return get('GET_ALL_BANK_ACCOUNTS')
}

export const makeDefaultAccountApi = body => {
	return put('MAKE_DEFAULT_ACCOUNT', body)
}

export const deleteBankAccountApi = queryParams => {
	return deleteMethod('DELETE_BANK_ACCOUNT', null, queryParams)
}

export const getReceivedPaymentsListApi = queryParams => {
	return get('GET_RECEIVED_PAYMENTS_LIST', null, queryParams)
}

export const getPaymentPageDetailsApi = () => {
	return get('GET_PAYMENT_PAGE_DETAILS')
}

export const getPilotEarningsApi = queryParams => {
	return get('GET_PILOT_EARNINGS', null, queryParams)
}

export const sendInvoiceForProjectApi = async body => {
	const res = await post('SEND_INVOICE_FOR_PROJECT', body)

	// Sending pilot mail invoice event to Mixpanel
	pushEventToMixpanel('', 'Pilot Mail Invoice', {
		'Is Request Generated': res.status_code === 200,
	})

	return res
}

export const sendTaxSummaryApi = async body => {
	const res = await post('SEND_TAX_SUMMARY', body)

	// For sending pilot mail tax summary
	if (body.mixpanel_type === 'pilot_mail_tax_summary' && res.status_code === 200) {
		pushEventToMixpanel('', 'Pilot Mail Tax Summary', {
			'From Date': moment(body.startDate, 'DD/MM/YYYY')
				.utc()
				.local()
				.format('MM/DD/YYYY'),
			'To Date': moment(body.endDate, 'DD/MM/YYYY')
				.utc()
				.local()
				.format('MM/DD/YYYY'),
			Email: body.email,
		})
	}

	return res
}

export const getStripeCheckoutUrlApi = body => post('GET_STRIPE_CHECKOUT_URL', body)

export const fetchStripeUrlApi = body => post('FETCH_STRIPE_URL', body)

export const getPilotCreditCardsApi = () => {
	return get('GET_PILOT_CREDIT_CARDS')
}

export const checkForSubmittedDetailsApi = queryParams => {
	return get('CHECK_FOR_SUBMITTED_DETAILS', null, queryParams)
}
