import React from 'react'
import { Input } from 'antd'
import { TEXTS } from '../../../Modules/OnBoarding/on-boarding.constants'

const BASIC_DETAILS_MODAL = {
	FN: 'firstName',
	MN: 'middleName',
	LN: 'lastName',
	EMAIL: 'email',
	PN: 'mobileNumber',
	PASS: 'password',
	CONFIRM_PASS: 'confirmPassword',
}

export const BASIC_DETAILS_FIELDS = {
	CONSUMER: [
		{
			fieldName: 'FN',
			fieldLabel: TEXTS.FN,
			fieldKey: BASIC_DETAILS_MODAL.FN,
			fileObject: {
				initialValue: '',
				rules: [
					{
						required: true,
						message: 'First name cannot be blank!',
						whitespace: true,
					},
				],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
		},
		{
			fieldName: 'MN',
			fieldLabel: TEXTS.MN,
			fieldKey: BASIC_DETAILS_MODAL.MN,
			fileObject: {
				initialValue: '',
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
		},
		{
			fieldName: 'LN',
			fieldLabel: TEXTS.LN,
			fieldKey: BASIC_DETAILS_MODAL.LN,
			fileObject: {
				initialValue: '',
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
		},
	],
	PILOT: [
		{
			fieldName: 'FN',
			fieldLabel: TEXTS.FN,
			fieldKey: BASIC_DETAILS_MODAL.FN,
			fileObject: {
				initialValue: '',
				rules: [
					{
						required: true,
						message: 'First name cannot be blank!',
						whitespace: true,
					},
				],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
		},
		{
			fieldName: 'MN',
			fieldLabel: TEXTS.MN,
			fieldKey: BASIC_DETAILS_MODAL.MN,
			fileObject: {
				initialValue: '',
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
		},
		{
			fieldName: 'LN',
			fieldLabel: TEXTS.LN,
			fieldKey: BASIC_DETAILS_MODAL.LN,
			fileObject: {
				initialValue: '',
				rules: [],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} />,
		},
	],
}
