import React from 'react'
import { Card } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import moment from 'moment'

const PlanCard = props => {
	const { data, selectPlan, selectedPlan, type } = props
	return (
		<div onClick={() => selectPlan(data)} className='my-plans-plan-wrapper'>
			{data.popular && (
				<div className='my-plans-pop-card' style={{ marginLeft: type === 'web' ? '55px' : '38px' }}>
					MOST POPULAR
				</div>
			)}
			<Card
				className='my-plans-plan-card'
				style={{
					border: selectedPlan && selectedPlan.planId === data.planId ? '2px solid #096DD9' : '0.5px solid #DDDDDE',
					maxWidth: type === 'web' ? '186px' : '150px',
					maxHeight: type === 'web' ? '145px' : '166px',
				}}
			>
				{selectedPlan && selectedPlan.planId === data.planId && <CheckOutlined className='my-plans-check-icon' />}
				<div style={{ textAlign: 'center' }}>
				{data.planType === 'FREE_TRIAL' ? 
						`Free Trial` : data.planType}
					{data.planType === 'FREE_TRIAL' ? (
						<div style={{ fontSize: '24px' }}>Free Trial</div>
					) : (
						<div>
							<span style={{ color: '#8D9097' }}>
								<s>
									{data.currencySymbol}
									{data.initialPrice}
								</s>
							</span>
							&nbsp;&nbsp;
							<span style={{ fontSize: '24px' }}>
								{data.currencySymbol}
								{data.price}
							</span>
						</div>
					)}

					<div style={{ color: '#8D9097' }}>Validity: {data.validity}</div>
				</div>
				<div
					className={
						selectedPlan && selectedPlan.planId === data.planId
							? type === 'web'
								? 'my-plans-plan-date-popular'
								: 'my-plans-plan-date-popular-mobile'
							: type === 'web'
							? 'my-plans-plan-date'
							: 'my-plans-plan-date-mobile'
					}
				>
					Start Date - {moment(data.startDate).format('Do MMM')}
				</div>
			</Card>
		</div>
	)
}

export default PlanCard
