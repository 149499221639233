import { Breadcrumb, Button, Checkbox, Alert, message, notification } from 'antd'
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import Details from './Components/Details/Details'
import Metadata from './Components/Metadata/Metadata'
import DetailsModal from './Components/Modals/DetailsModal'
import DeleteModal from './Components/Modals/DeleteModal'
import SubmitModal from './Components/Modals/SubmitModal'
import HowRoyalyWorksModal from './Components/Modals/HowRoyalyWorksModal'
import SelectFileModal from './Components/Modals/SelectFileModal'
import GettyReasonModal from './Components/Modals/GettyReasonModal'
import VideoPlayer from './Components/Modals/VideoPlayer'
import LearnMoreModal from './Components/Modals/LearnMoreModal'
import styles from './Contribution.module.scss'

import Status from './Components/Status/Status'

import AeroModal from '../../../AeroComponents/Modal'

import ContributionThumbnail from './Components/ContributionThumbnail/ContributionThumbnail'

import { getAllContributions, addContribution, submitSubmission } from '../../../Stores/Getty/action'
import { BULK_UPDATE_CHANGE_DESCRIPTION, FOOTAGE_CREDITS } from './constants'
import { CheckCircleTwoTone } from '@ant-design/icons'

function Contribution(props) {
	const {
		contributionList,
		submissionName,
		uploads,
		bulkUpdateSuccess,
		submitSuccess, // from state
		getAllContributions,
		addContribution,
		submitSubmission, // from dispatch
	} = props

	/* state */
	const submissionId = props.match.params.submissionId
	const [submission, setSubmission] = useState(submissionName)
	const [showSampleVideo, setShowSampleVideo] = useState(false)
	const [showDetailsModal, setShowDetailsModal] = useState(false)
	const [showLearnMoreModal, setShowLearnMoreModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showSubmitModal, setShowSubmitModal] = useState(false)
	const [showHowRoyalyWorksModal, setShowHowRoyalyWorksModal] = useState(false)
	const [showSelectFileModal, setShowSelectFileModal] = useState(false)
	const [showGettyReasonModal, setshowGettyReasonModal] = useState(false)
	const [gettyReasonTitle, setGettyReasonTitle] = useState('')
	const [gettyReasonContent, setGettyReasonContent] = useState('')
	const [contributions, setContributions] = useState(null)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [selected, setSelected] = useState(null)
	const [openPlayer, setOpenPlayer] = useState(false)
	const [tc, setTC] = useState(false)
	const [dataChanged, setDataChanged] = useState({})
	const [disablesubmitButton, setDisableSubmitButton] = useState(false)
	const [uploadPercent, setUploadPercent] = useState({})
	const [videoPlayerUrl, setVideoPlayerUrl] = useState('')
	const [isFirstRender, setIsFirstRender] = useState(true)

	/* Effects */
	useEffect(() => {
		if (submissionId) {
			getAllContributions({ submissionId: submissionId }).then(result => {
				if (uploads && uploads[submissionId]) {
					for (let filename in uploads[submissionId]) {
						addContribution({ filename: filename })
					}
				}
			})
		}
	}, [submissionId])

	useEffect(() => {
		if (contributionList) {
			setContributions(contributionList)
			if (selectedIndex > -1) {
				setSelected(contributionList[selectedIndex])
			} else {
				if (contributionList.length > 0) {
					setSelectedIndex(0)
					setSelected(contributionList[0])
				} else {
					setSelected(null)
				}
			}
		}
	}, [contributionList, selectedIndex])

	useEffect(() => {
		if (contributions) {
			if (validateData()) {
				setDisableSubmitButton(true)
			} else {
				setDisableSubmitButton(false)
			}
		}
	}, [contributions])

	useEffect(() => {
		if (uploads && uploads[submissionId]) {
			for (let filename in uploads[submissionId]) {
				setUploadPercent(prevState => {
					let newUploadPercent = { ...prevState }
					newUploadPercent[filename] = uploads[submissionId][filename]['percentage']
					return newUploadPercent
				})
			}
		}
	}, [uploads])

	useEffect(() => {
		setSubmission(submissionName)
	}, [submissionName])

	useEffect(() => {
		if (selected) {
			setVideoPlayerUrl(selected.storageUrl)
		}
	}, [selected])

	useEffect(() => {
		if (!isFirstRender && bulkUpdateSuccess && bulkUpdateSuccess.type === BULK_UPDATE_CHANGE_DESCRIPTION) {
			submitSubmission({ submissionId: submissionId })
		}
	}, [bulkUpdateSuccess])

	useEffect(() => {
		if (!isFirstRender && submitSuccess) {
			getAllContributions({ submissionId: submissionId })
			notification.open({
				duration: 15,
				message: ' Submission sent for review ',
				className: styles['notification'],
				icon: <CheckCircleTwoTone style={{ position: 'relative', top: '-3px' }} twoToneColor='#52c41a' />,
			})
		}
	}, [submitSuccess])

	useEffect(() => {
		setIsFirstRender(false)
		setContributions(null)
		setSelected(null)
	}, [])

	/* Functions */
	function isDataChanged() {
		if (Object.keys(dataChanged).length === 0) {
			setDataChanged({})
			return false
		} else {
			var r = window.confirm("You have changed metadata. Please click 'Save Video' button to save data or data will reset if you navigate to other video. Is that ok?")
			if (r === true) {
				setDataChanged({})
				return false
			} else {
				return true
			}
		}
	}

	function selectContribution(contributionId) {
		if (contributionId !== selected.id) {
			if (!isDataChanged()) {
				let index = contributions.findIndex(element => element.id === contributionId)
				setSelectedIndex(index)
			}
		}
	}

	function getPercentage(contribution) {
		return Math.floor(uploadPercent[contribution.fileName])
	}

	function getThumbnails() {
		if (contributions) {
			const listItems = contributions.map(contribution => {
				let isSelected = false
				let showDelete = true
				let isDownloadable = false
				let fileExt = contribution.fileName.split('.').pop()
				if (selected && contribution.id === selected.id) {
					isSelected = true
				}
				if (contribution.status !== 'PENDING_FILE_SUBMISSION') {
					showDelete = false
				}

				if (fileExt.toLowerCase() === 'mxf') {
					isDownloadable = true
				}

				if (contribution.isUploading && contribution.isUploading === true) {
					return (
						<ContributionThumbnail
							name={contribution.fileName}
							isUploading={true}
							showProgress={true}
							progress={getPercentage(contribution)}
							key={contribution.id}
							isSelected={isSelected}
							setShowDeleteModal={setShowDeleteModal}
							setOpenPlayer={setOpenPlayer}
							selectContribution={() => selectContribution(contribution.id)}
							id={contribution.id}
							thumbnailUrl={contribution.thumbnailUrl}
							showDelete={false}
							status={contribution.status}
						></ContributionThumbnail>
					)
				}

				return (
					<ContributionThumbnail
						name={contribution.fileName}
						isDownloadable={isDownloadable}
						url={contribution.storageUrl}
						key={contribution.id}
						isSelected={isSelected}
						setShowDeleteModal={setShowDeleteModal}
						setOpenPlayer={setOpenPlayer}
						selectContribution={() => selectContribution(contribution.id)}
						id={contribution.id}
						thumbnailUrl={contribution.thumbnailUrl}
						showDelete={showDelete}
						status={contribution.status}
					></ContributionThumbnail>
				)
			})

			return listItems
		} else {
			return ''
		}
	}

	function getStatus() {
		if (selected) {
			return (
				<Status
					timeline={selected.timeline.timeline}
					name={selected.fileName}
					contributionStatus={selected.status}
					setShowLearnMoreModal={setShowLearnMoreModal}
					setShowHowRoyalyWorksModal={setShowHowRoyalyWorksModal}
				></Status>
			)
		} else {
			return <Status timeline={null} setShowLearnMoreModal={setShowLearnMoreModal} setShowHowRoyalyWorksModal={setShowHowRoyalyWorksModal}></Status>
		}
	}

	function changeTC(event) {
		setTC(event.target.checked)
	}

	function validateData() {
		let valid = true
		if (contributions && contributions.length > 0) {
			for (let contribution of contributions) {
				for (const data in contribution) {
					if (data === 'status' && contribution['status'] !== 'PENDING_FILE_SUBMISSION') {
						valid = false
					}

					if (['headline', 'caption', 'countryOfShoot', 'clipType', 'iptcCategory', 'iptcSubjects', 'recordedDate', 'shotSpeed', 'keywords'].includes(data)) {
						if (contribution[data] === null || contribution[data] === undefined) {
							valid = false
						}

						if (data === 'iptcSubjects') {
							if (contribution[data] && contribution[data].length === 0) {
								valid = false
							}
						}
						if (data === 'keywords') {
							if (contribution[data] && contribution[data].length === 0) {
								valid = false
							}
						}
					}
				}
			}
		} else {
			valid = false
		}

		return valid
	}

	function formatDate(dateString, seperator) {
		if (seperator === '-') {
			return dateString.replace(/\//g, seperator)
		} else {
			return dateString.replace(/-/g, seperator)
		}
	}

	function buildDataForChangeDescription() {
		let requestData = {
			contributions: [],
			submissionId: selected.submissionId,
			type: BULK_UPDATE_CHANGE_DESCRIPTION,
		}

		requestData.contributions = contributions.map(contributionItem => {
			let tempContribution = { ...contributionItem }

			let tempDate = moment(formatDate(tempContribution.recordedDate, '/'), 'DD/MM/YYYY').format('MMMM DD')

			tempContribution.caption =
				tempContribution.provinceState + ',' + tempContribution.countryOfShoot + ' - ' + tempDate + ' : ' + tempContribution.caption + ' ' + FOOTAGE_CREDITS

			return tempContribution
		})
		return requestData
	}

	function submitSubmissionHandler() {
		if (validateData()) {
			setShowSubmitModal(true)
		} else {
			message.error('Please fill all the metadata of all videos to submit the submission')
		}
	}

	function getBanner() {
		if (selected) {
			switch (selected.status) {
				case 'REJECTED_AEROLOGIX': {
					return (
						<Alert
							closable
							closeText={<span style={{ color: 'white' }}>X</span>}
							message={<span style={{ color: 'white' }}>Uploaded media was not accepted by Aerologix</span>}
							className={[styles['alert'], styles['error-alert']]}
						></Alert>
					)
				}

				case 'REJECTED_GETTY': {
					return (
						<Alert
							closable
							closeText={<span style={{ color: 'white' }}>X</span>}
							message={<span style={{ color: 'white' }}>Uploaded media was not accepted by Getty</span>}
							className={[styles['alert'], styles['error-alert']]}
						></Alert>
					)
				}

				case 'REVISE_AND_RETRY_GETTY': {
					return (
						<Alert
							closable
							closeText={<span style={{ color: 'white' }}>X</span>}
							message={<span style={{ color: 'white' }}>You will be contacted by the Aerologix Admin shortly</span>}
							className={[styles['alert'], styles['warning-alert']]}
						></Alert>
					)
				}
				default: {
					return ''
				}
			}
		}
	}

	function isEligibleForUpload() {
		let valid = true
		if (contributions && contributions.length > 0) {
			for (let contribution of contributions) {
				if (contribution['status'] !== 'PENDING_FILE_SUBMISSION') {
					valid = false
				}
			}
			if (contributions.length >= 25) {
				valid = false
			}
		}
		return valid
	}

	function clickUpload() {
		setShowSelectFileModal(true)
	}

	return (
		<div className={styles['outer-card']}>
			{getBanner()}
			<br />
			<Breadcrumb>
				<Breadcrumb.Item>
					<NavLink to={'/getty/submission/previous/1/?search='}>Submissions</NavLink>
				</Breadcrumb.Item>
				<Breadcrumb.Item>{submission}</Breadcrumb.Item>
			</Breadcrumb>
			{openPlayer && selected ? <VideoPlayer setOpenPlayer={setOpenPlayer} src={videoPlayerUrl}></VideoPlayer> : <br />}
			{showDeleteModal && selected ? (
				<DeleteModal
					showDeleteModal={showDeleteModal}
					setShowDeleteModal={setShowDeleteModal}
					totalContributions={contributions.length}
					setSelectedIndex={setSelectedIndex}
					contributionId={selected.id}
					fileName={selected.fileName}
				></DeleteModal>
			) : (
				''
			)}
			{showSubmitModal ? (
				<SubmitModal
					buildDataForChangeDescription={buildDataForChangeDescription}
					showSubmitModal={showSubmitModal}
					setShowSubmitModal={setShowSubmitModal}
					submissionId={submissionId}
				></SubmitModal>
			) : (
				''
			)}
			{showHowRoyalyWorksModal ? (
				<HowRoyalyWorksModal showHowRoyalyWorksModal={showHowRoyalyWorksModal} setShowHowRoyalyWorksModal={setShowHowRoyalyWorksModal}></HowRoyalyWorksModal>
			) : (
				''
			)}
			{showSelectFileModal ? (
				<SelectFileModal showSelectFileModal={showSelectFileModal} setShowSelectFileModal={setShowSelectFileModal} submissionId={submissionId}></SelectFileModal>
			) : (
				''
			)}
			{showGettyReasonModal && selected ? (
				<GettyReasonModal
					title={gettyReasonTitle}
					content={gettyReasonContent}
					setshowGettyReasonModal={setshowGettyReasonModal}
					showGettyReasonModal={showGettyReasonModal}
				>
					{' '}
				</GettyReasonModal>
			) : (
				''
			)}
			<Details setShowDetailsModal={setShowDetailsModal}></Details>
			<DetailsModal setShowDetailsModal={setShowDetailsModal} showDetailsModal={showDetailsModal} />
			<br />
			{getStatus()}
			<LearnMoreModal setShowLearnMoreModal={setShowLearnMoreModal} showLearnMoreModal={showLearnMoreModal}>
				{' '}
			</LearnMoreModal>
			<AeroModal
				bodyStyle={{ backgroundColor: '#FFFFFF' }}
				visible={showSampleVideo}
				footer={false}
				width={800}
				title='Sample Submission'
				onCancel={() => {
					setShowSampleVideo(false)
				}}
			>
				<p style={{ textAlign: 'center' }}>
					<iframe width='750' height='600' src='https://www.youtube.com/embed/2_smod9t7ho'></iframe>
				</p>
			</AeroModal>
			<br />
			<div className={styles['contribution-content-holder']}>
				<div className={styles['contribution-content']}>
					{/* TODO: Uncomment after new video was released from adam */}
					{/* <div className={styles['contribution-sample-holder']} >
                        <div>  <MatImage style={{ height: '25px' }} src={IMAGES_SRC.BIG_WHITE_EXCLAMATION_ICON} />
                            <span> &nbsp;View Sample Submission to create flawless submission
                            </span>
                        </div>
                        <div> <span className={styles['view-now-button']} onClick={() => { setShowSampleVideo(true) }} >View Now</span></div>
                    </div> */}
					<div className={styles['contribution-content-scroll']}>
						{isEligibleForUpload() ? (
							<div className={styles['thumbnail-card']} onClick={() => clickUpload()}>
								<div>
									{' '}
									<div className={styles['plus']}> + </div>
									Upload
								</div>
							</div>
						) : (
							''
						)}
						{getThumbnails()}
					</div>
				</div>
				<Metadata
					setshowGettyReasonModal={setshowGettyReasonModal}
					setGettyReasonTitle={setGettyReasonTitle}
					setGettyReasonContent={setGettyReasonContent}
					contributions={contributions}
					contribution={selected}
					dataChanged={dataChanged}
					setDataChanged={setDataChanged}
				></Metadata>
			</div>
			{selected && selected['status'] !== 'PENDING_FILE_SUBMISSION' ? (
				''
			) : !selected ? (
				<span></span>
			) : (
				<div className={styles['contribution-footer']}>
					<div>
						{disablesubmitButton && tc ? (
							<Button className={styles['button']} type='primary' onClick={submitSubmissionHandler}>
								Send Submission for Review
							</Button>
						) : (
							<Button className={styles['disabled-button']}>Send Submission for Review</Button>
						)}
					</div>
					<div style={{ position: 'relative', top: '3px' }}>
						<Checkbox onChange={changeTC} checked={tc}>
							{' '}
							I agree to Aerologix Footage{' '}
							<Button type='link' target='_blank' style={{ position: 'relative', right: '6%' }} href='https://aerologix.com/terms/footage/'>
								T&C{' '}
							</Button>
						</Checkbox>
					</div>
				</div>
			)}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		contributionList: state.GettyReducer.contributionList, // all contribution data
		submissionName: state.GettyReducer.submissionName,
		uploads: state.GettyReducer.uploads,
		bulkUpdateSuccess: state.GettyReducer.bulkUpdateSuccess,
		submitSuccess: state.GettyReducer.submitSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAllContributions: bindActionCreators(getAllContributions, dispatch),
		addContribution: bindActionCreators(addContribution, dispatch),
		submitSubmission: bindActionCreators(submitSubmission, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Contribution)
