import React, { Component } from 'react'
import { goTo } from '../../Services/NavigationService'
import { getValueFromStorage } from '../../Services/LocalStorageService'

class LoggedInRedirect extends Component {
	componentDidMount() {
		if (getValueFromStorage('SUBROLE')) {
			if (getValueFromStorage('SUBROLE') === 'CLIENT_COLLABORATOR') {
				goTo('enterprise/orders')
			} else {
				goTo('enterprise/overview')
			}
		} else {
			goTo('home')
		}
	}

	render() {
		return <></>
	}
}

export default LoggedInRedirect
