/*eslint-disable*/
import React from 'react'
import './on-boarding.sass'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import MatText from '../../AeroComponents/Text'
import { TEXTS } from './on-boarding.constants'
import { Input, Button } from 'antd'

export default class ResetPassword extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	goTo = urlKey => {
		this.props.history.push('/login')
	}

	render() {
		return (
			<div className='flex-container'>
				<MatImage src={IMAGES_SRC.LOGO_WHITE} />
				<MatText text={TEXTS.CREATE_NEW_PASS} />
				<MatText text={TEXTS.PASS} />
				<Input />
				<Button>{TEXTS.CONTINUE}</Button>
				<Button type='link' onClick={() => this.goTo('LOG_IN')}>
					{TEXTS.RETURN_LOG_IN}
				</Button>
				{/* <Button type='link'>{TEXTS.CONTACT_SUPPORT}</Button> */}
			</div>
		)
	}
}
