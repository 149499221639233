/*eslint-disable*/
import React from 'react'
import { Elements } from 'react-stripe-elements'
import InjectedPaymentProfile from './stripe-card-info'

class StripePayment extends React.Component {
	constructor(props){
		super(props);
	}
	render() {
		return (
			<Elements>
				<InjectedPaymentProfile {...this.props} />
			</Elements>
		)
	}
}

export default StripePayment
