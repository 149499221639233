import React from 'react'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'

export const UPGRADES_AU = [
	{
		key: 0,
		img: IMAGES_SRC.INSURANCE_ICON,
		heading: 'Upgrade with a drone insurance',
		subheading: 'Over 60% of Aerologix clients prefer selecting pilots with an active drone insurance',
		btnLabel: 'UPGRADE',
	},
	{
		key: 1,
		img: IMAGES_SRC.UPGRADE_REMOTE_ICON,
		heading: "Upgrade with a Remote Pilot's license",
		subheading: (
			<span>
				Use Promo code <strong>“aerologix10”</strong> to get 10% off on a range of drone courses and certifications
			</span>
		),
		btnLabel: 'UPGRADE',
	},
	{
		key: 2,
		img: IMAGES_SRC.UPGRADE_DRONE_ICON,
		heading: 'Upgrade your drone',
		subheading: 'Save 10% off new drones when going through our partner at UASys',
		btnLabel: 'Visit Store',
	},
]

export const UPGRADES_NZ = [
	{
		key: 3,
		img: IMAGES_SRC.INSURANCE_ICON,
		heading: 'Upgrade with a drone insurance',
		subheading: 'Over 60% of Aerologix clients prefer selecting pilots with an active drone insurance',
		btnLabel: 'UPGRADE',
	},
	{
		key: 4,
		img: IMAGES_SRC.UPGRADE_REMOTE_ICON,
		heading: 'Upskill with drone courses',
		subheading: (
			<span>
				Starting your career as a drone pilot? Access training courses to develop your skills and kick start your pilot projects and Use the code{' '}
				<strong>"DronezUp Deal"</strong> on the contact form to receive a 10% discount.
			</span>
		),
		btnLabel: 'Check Courses',
	},
	{
		key: 5,
		img: IMAGES_SRC.UPGRADE_DRONE_ICON,
		heading: 'Upgrade your drone fleet',
		subheading: 'Check the latest drones on market. Use ‘aerologixnz’ to get 5% discount. Only applicable to non-sale items',
		btnLabel: 'Visit Store',
	},
]
