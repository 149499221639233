import { Card, Radio } from 'antd'
import React from 'react'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'

const slotContent = [
	{
		key: 'MORNING',
		heading: 'Morning',
		desc: 'Before 10 am',
		img: IMAGES_SRC.MORNING_SLOT_ICON,
	},
	{
		key: 'MIDDAY',
		heading: 'Midday',
		desc: '10 am - 2 pm',
		img: IMAGES_SRC.MIDDAY_SLOT_ICON,
	},
	{
		key: 'AFTERNOON',
		heading: 'Afternoon',
		desc: '2 am - 6 pm',
		img: IMAGES_SRC.AFTERNOON_SLOT_ICON,
	},
	{
		key: 'EVENING',
		heading: 'Evening',
		desc: 'After 6 pm',
		img: IMAGES_SRC.EVENING_SLOT_ICON,
	},
]
const SlotCards = props => {
	const { showSlots, handleSlotChange, selectedSlot } = props
	return (
		<div>
			{showSlots ? (
				<Radio.Group onChange={handleSlotChange} value={selectedSlot}>
					{slotContent.map(item => (
						<Card
							key={item.key}
							style={{
								borderRadius: '8px',
								width: '136px',
								height: '110px',
								display: 'inline-block',
								marginRight: '15px',
								marginBottom: '15px',
								border: selectedSlot === item.key ? '1px solid #00B3FF' : '1px solid #ABA8B0',
							}}
						>
							<MatImage alt={item.key} src={item.img} />
							<Radio style={{ position: 'relative', left: '50px', top: '15px' }} value={item.key}></Radio>
							<div>{item.heading}</div>
							<div style={{ fontSize: '12px', color: '#84818A' }}>{item.desc}</div>
						</Card>
					))}
				</Radio.Group>
			) : null}
		</div>
	)
}

export default SlotCards
