/*eslint-disable*/
import React from 'react'
import { Button } from 'antd'
import './on-boarding.sass'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { gotToLogIn } from '../../Services/NavigationService'

export default class EmailVerificationSuccess extends React.Component {
	render() {
		return (
			<>
				<div className='side-img'>
					<MatImage style={{ height: '100vh' }} alt='signup-select' src={IMAGES_SRC.SIGNUP_SELECT_IMG} />
				</div>
				<div className='top-img'>
					<MatImage style={{ padding: '20px' }} alt='white-logo' src={IMAGES_SRC.LOGO_WHITE} />
				</div>
				<div className='left-content-wrapper'>
					<div className='left-content-holder'>
						<MatImage alt='email-icn' src={IMAGES_SRC.EMAIL_SUCCESS_ICON} />
						<div style={{ fontSize: '18px', color: '#84818A', marginTop: '20px' }}>
							<b>Email verification successful!</b>
						</div>
						<div style={{ fontSize: '18px', color: '#84818A', marginTop: '10px' }}>
							To Login,
							<Button style={{ fontSize: '18px' }} type='link' onClick={gotToLogIn}>
								Click here
							</Button>
						</div>
					</div>
				</div>
			</>
		)
	}
}
