const setValueToStorage = (key, value) => {
	window.localStorage.setItem(key, value)
}

const removeValueFromStorage = key => {
	window.localStorage.removeItem(key)
}

const getValueFromStorage = key => {
	return window.localStorage.getItem(key)
}

const clearToken = () => {
	window.localStorage.removeItem('TOKEN')
}

const clearProfileType = () => {
	window.localStorage.removeItem('PROFILE_TYPE')
}

const clearLocalStore = () => {
	window.localStorage.clear()
}

const initializeAuth = () => {
	const isProfileType = getValueFromStorage('PROFILE_TYPE') === 'CONSUMER' || getValueFromStorage('PROFILE_TYPE') === 'PILOT'
	const isToken = getValueFromStorage('TOKEN') !== null
	const isLoggedIn = isProfileType && isToken
	return isLoggedIn
}

const getConfigData = () => {
	return JSON.parse(getValueFromStorage('WEB_CONFIG_DATA'))
}

const checkForEmailAndPhoneNumber = (hasEmail,hasMobileNumber) =>{
	if(getValueFromStorage('PROFILE_TYPE') === 'CONSUMER'){

		 return getValueFromStorage('PROJECT_DATA') 	? ( hasMobileNumber && hasEmail) : hasEmail
	}
	return hasEmail

}

export { getValueFromStorage, setValueToStorage, clearToken, clearProfileType, clearLocalStore, initializeAuth, removeValueFromStorage, getConfigData , checkForEmailAndPhoneNumber}
