import { Input, Button } from 'antd'
import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { getConfigData } from '../../Services/LocalStorageService'
import { urlConfig } from '../../Services/UtilityService'

export default class LocationSearchInput extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			address: props.address ? props.address : '',
			countryId: getConfigData() ? getConfigData().countryId : urlConfig().countryId,
		}
	}

	handleAddressChange = address => {
		this.setState({ address })
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.address !== this.props.address) {
			this.setState({ address: nextProps.address })
		}
	}

	render() {
		const { address, countryId } = this.state
		return (
			<PlacesAutocomplete
				searchOptions={{ componentRestrictions: { country: countryId === 1 ? 'au' : 'nz' } }}
				onChange={this.handleAddressChange}
				onSelect={this.props.onAddressSelect}
				value={address}
			>
				{({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
					<React.Fragment>
						{this.props.showSelectOnMap && (
							<Button
								type='link'
								style={{ position: 'absolute', top: this.props.source === 'p2pProfile' ? '-52px' : '-38px', right: '-15px' }}
								onClick={() => {
									this.props.openModal('GOOGLE_MAP')
								}}
							>
								Select On Map
							</Button>
						)}
						<Input
							{...getInputProps({
								id: 'address-input',
							})}
							placeholder='Search here'
							style={
								(this.props.source && this.props.source === 'p2p') || this.props.source === 'p2pProfile'
									? { borderRadius: '8px', height: '48px', width: '100%' }
									: null
							}
						/>
						<div style={{ position: 'absolute', zIndex: '2', top: '30px' }}>
							{loading ? <div style={{ backgroundColor: '#fafafa', padding: '10px' }}>Loading...</div> : null}
							{suggestions.map(suggestion => {
								const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
								const style = suggestion.active
									? { backgroundColor: '#aba8b0', cursor: 'pointer', padding: '10px' }
									: { backgroundColor: '#fafafa', cursor: 'pointer', padding: '10px' }

								const spread = {
									...getSuggestionItemProps(suggestion, {
										className,
										style,
									}),
								}

								return (
									<div {...spread} key={suggestion.id}>
										<div>{suggestion.description}</div>
									</div>
								)
							})}
						</div>
					</React.Fragment>
				)}
			</PlacesAutocomplete>
		)
	}
}
