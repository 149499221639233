import React from 'react'

import { Button, } from 'antd'
import { AeroModal } from '../../../../AeroComponents'
import MatImage from '../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../AeroComponents/Image/image.constants'
import styles from './modal.module.scss'

export default function ApproveModal(props) {

    // extract props
    const { approveModal, closeApproveModal, approveData } = props

    return (
        <AeroModal footer={false} onCancel={closeApproveModal} visible={approveModal}>
            <div className={styles['modal-wrapper']}>
                <MatImage src={IMAGES_SRC.APPOVE_ICON} />
                <div>
                    <div className={styles['header']}>Approve Project Data?</div>
                    <div className={styles['header-info']}>Project data will be accepted and the Project will be marked as Complete</div>
                    <div className={styles['body']}>
                        <Button className={styles['button']} onClick={closeApproveModal}>
                            No
                        </Button>
                        &nbsp;&nbsp;
                        <Button className={styles['button']} onClick={approveData} type='primary'>
                            Yes
                        </Button>
                    </div>
                </div>
            </div>
        </AeroModal>
    )
}
