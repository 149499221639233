import React, { useRef, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, message, Upload } from 'antd'
import SignaturePad from 'react-signature-canvas'
import moment from 'moment'

import styles from '../enterprisePilotPlus.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'

const SignatureModal = ({ setSignatureData, setSignModal, visible }) => {
	const signRef = useRef()
	const [esignModal, setEsignModal] = useState(false)
	const [eSignPreview, setESignPreview] = useState(null)

	const goToNextStep = () => {
		if (!eSignPreview) {
			return message.error('Please add E-sign')
		}
		setSignatureData(prev => ({
			...prev,
			eSignPreview,
			eSignComplete: true,
			eSignDateAndTime: moment().format(`DD MMM'YYYY, h:mmA`),
		}))
		setSignModal(false)
	}

	const hanlePreviewOfImage = file => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			setESignPreview(reader.result)
		}
	}

	const handleChange = ({ file }) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
		if (!isJpgOrPng) {
			return message.error('You can only upload JPG/PNG file!')
		}
		const isLt2M = file.size / 1024 / 1024 < 2
		if (!isLt2M) {
			return message.error('Image must smaller than 2MB!')
		}

		hanlePreviewOfImage(file)
	}

	return (
		<>
			<AeroModal visible={visible} onCancel={() => setSignModal(false)} title='' footer={false}>
				<div className={styles['eSign_main']}>
					<h1>Upload your e-sign</h1>

					{/* PREVIEW OF E-SIGN */}
					{eSignPreview && (
						<div className={styles['eSign_preview']}>
							<img src={eSignPreview} alt='Preview of e-sign' />
						</div>
					)}

					{/* UPLOAD OR PERFORM E-SIGN BUTTON */}
					<Upload beforeUpload={() => false} onChange={handleChange} accept={['image/png', 'image/jpeg']} maxCount={1} showUploadList={false}>
						<Button className={styles['eSign_button']} icon={<UploadOutlined />}>
							Upload E-sign
						</Button>
					</Upload>

					<Button className={styles['eSign_button']} onClick={() => setEsignModal(true)}>
						Perform E-sign
					</Button>

					<div className={styles['eSign_submit']}>
						<Button className={styles['eSign_submit_button']} type='primary' onClick={goToNextStep}>
							Submit
						</Button>
					</div>
				</div>
			</AeroModal>

			{/* E-SIGN MODAL */}
			<AeroModal className={styles['eSign_modal_antd']} visible={esignModal} onCancel={() => setEsignModal(false)} title='' footer={false}>
				<div className={styles['eSign_signature_canvas']}>
					<SignaturePad
						penColor='black'
						canvasProps={{
							width: 500,
							height: 500,
						}}
						clearOnResize={true}
						ref={signRef}
					/>
				</div>

				<div className={styles['eSign_buttons']} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1rem' }}>
					<Button
						className={styles['eSign_buttons_submit']}
						type='primary'
						onClick={() => {
							if (signRef.current.isEmpty()) {
								message.error('Please add e-sign')
								return
							}
							setESignPreview(signRef.current.getTrimmedCanvas().toDataURL('image/png'))
							setEsignModal(false)
						}}
					>
						Submit
					</Button>
					<Button
						className={styles['eSign_buttons_submit']}
						type='default'
						onClick={() => {
							signRef.current.clear()
							setESignPreview(null)
						}}
					>
						Reset
					</Button>
				</div>
			</AeroModal>
		</>
	)
}

export default SignatureModal
