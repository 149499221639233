import React, { Component } from 'react'
import { Button } from 'antd'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import './upgrades.sass'
import { pushEventToMixpanel } from '../../Services/MixpanelService'
import AeroModal from '../../AeroComponents/Modal'
import { getConfigData } from '../../Services/LocalStorageService'
import { UPGRADES_AU, UPGRADES_NZ } from './constants'

class Upgrades extends Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
		}
	}
	toggleVisible = () => this.setState(prevVal => ({ visible: !prevVal.visible }))
	handleRedirection = key => {
		switch (key) {
			case 0:
				window.open('https://login.precision-autonomy.com/landing/aon_au-aerologix', '_blank')
				break
			case 1:
				window.open('https://gdronesolutions.com/courses/aerologix/?coupon=aerologix10', '_blank')
				break
			case 2:
				window.open('https://uasys.com.au/aerologix/', '_blank')
				break
			case 3:
				this.toggleVisible()
				break
			case 4:
				window.open('https://dronezup.co.nz/contact-us/', '_blank')
				break
			case 5:
				window.open('https://www.ferntech.co.nz/', '_blank')
				break
			default:
				return null
		}
	}
	render() {
		const { visible } = this.state
		const cardMap = getConfigData().countryId === 1 ? UPGRADES_AU : UPGRADES_NZ
		return (
			<>
				<div style={{ padding: '40px' }}>
					<div className='projects-heading' style={{ marginLeft: '15px', marginBottom: '20px' }}>
						Upgrades
					</div>
					<div style={{ display: 'flex', flexWrap: 'wrap' }}>
						{cardMap.map(item => (
							<div key={item.key}>
								<div className='upgrades-card'>
									<div className='img-content'>
										<MatImage src={item.img} />
									</div>
									<div className='text-content'>
										<h2>{item.heading}</h2>
										<p>{item.subheading}</p>

										<Button
											type='primary'
											onClick={() => {
												this.handleRedirection(item.key)
												pushEventToMixpanel('', item.heading)
											}}
										>
											{item.btnLabel}
										</Button>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>

				<AeroModal visible={visible} onCancel={this.toggleVisible} footer={false}>
					<div style={{ textAlign: 'center' }}>
						<MatImage alt='info' src={IMAGES_SRC.BIG_INFO_ICON} />
						<div style={{ fontSize: '16px', padding: '25px' }}>
							Information collected by Precision Autonomy in New Zealand will be shared with AON New Zealand. AON New Zealand will organise a quote for your
							consideration
						</div>
						<Button
							type='primary'
							onClick={() => {
								this.toggleVisible()
								window.open('https://au.droneinsure.co/proposal', '_blank')
							}}
							style={{ height: '50px', width: '80%' }}
						>
							Continue
						</Button>
					</div>
				</AeroModal>
			</>
		)
	}
}

export default Upgrades
