import React, { useEffect, useState, useRef } from 'react'

import { Input, Button, Select } from 'antd'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form } from '@ant-design/compatible'
import AeroModal from '../../AeroComponents/Modal/index'
import AeroField from '../../AeroComponents/FormFields'
import styles from './AddEmailMobile.module.scss'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'

import { goTo } from '../../Services/NavigationService'

import { getValueFromStorage, removeValueFromStorage, getConfigData } from '../../Services/LocalStorageService'
import { logOut, getOtp, sendEmailOtp, sendOtpToEmailAndMobile, verifyEmailAndMobileOtp } from '../../Stores/UserSession/action'

import { validateMobileNumber } from '../../Services/UtilityService'

const { Option } = Select

function AddEmailMobileForm(props) {
	const { otpData, token, logOut, getOtp, sendEmailOtp, sendOtpToEmailAndMobile, verifyEmailAndMobileOtp, form } = props

	const [cancelModal, setCancelModal] = useState(false)
	const [changePage, setChangePage] = useState(false)
	const [page, setPage] = useState(1)
	const [isInitialRender, setIsInitialRender] = useState(true)
	const [emailVerificationId, setEmailVerificationId] = useState(null)
	const [otpKey, setOtpKey] = useState(null)
	const [emailId, setEmailId] = useState(false)
	const [mobileNo, setMobileNo] = useState(false)

	const [mobileOTPTimer, setMobileOTPTimer] = useState(0)
	const [emailOTPTimer, setEmailOTPTimer] = useState(0)

	const isdCode = getConfigData().isdCode
	const hasPhoneNumber = JSON.parse(getValueFromStorage('HAS_MOBILE')) !== true && getValueFromStorage('PROFILE_TYPE') !== 'PILOT' && getValueFromStorage('PROJECT_DATA')
	const queryString = window.location.search
	const searchParams = new URLSearchParams(queryString)
	const channel = searchParams.get('channel') == 'FACEBOOK' ? 'Facebook' : 'Apple'

	const emailOTPIntervalTimer = useRef(null)
	const mobileOTPIntervalTimer = useRef(null)

	useEffect(() => {
		setPage(3)
	}, [token])

	useEffect(() => {
		// check for inital render as we dont want to run this at first render. Above useeffect will be overridden in the first render by setting page 1
		if (isInitialRender) {
			setIsInitialRender(false)
			setPage(1)
		} else if (otpData) {
			if (typeof otpData === 'string') {
				setEmailVerificationId(otpData)
			} else {
				if (otpData.otp_key) {
					setOtpKey(otpData.otp_key)
				}
				if (otpData.verificationId) {
					setEmailVerificationId(otpData.verificationId)
				}
			}

			if (changePage) {
				setPage(2)
			}
		}
	}, [otpData])

	const selectBefore = (
		<Select
			defaultValue={isdCode}
			style={{
				width: 90,
			}}
		>
			<Option value={isdCode}>{isdCode}</Option>
		</Select>
	)

	const emailField = [
		{
			fieldName: 'email',
			fieldLabel: 'Email',
			fieldKey: 'email',
			fileObject: {
				initialValue: '',
				rules: [
					{
						type: 'email',
						message: 'The input is not valid E-mail!',
					},
					{
						message: 'Please input your E-mail!',
						required: true,
					},
				],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} placeholder='Type here' />,
		},
	]

	const emailOTPField = [
		{
			fieldName: 'emailOTP',
			fieldLabel: 'Enter OTP Sent to Email',
			fieldKey: 'emailOTP',
			fileObject: {
				initialValue: '',
				rules: [
					{
						required: true,
						message: 'Please enter the OTP Sent to Email ',
					},
				],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} placeholder='Type here' />,
		},
	]

	const mobileField = [
		{
			fieldName: 'mobile',
			fieldLabel: 'Phone No.',
			fieldKey: 'mobile',
			fileObject: {
				initialValue: '',
				rules: [
					{
						validator: validateMobileNumber,
						message: 'Please input valid Phone No!',
					},
				],
			},
			fieldToRender: <Input addonBefore={selectBefore} style={{ height: '48px', borderRadius: '8px' }} size='large' placeholder='Type here' />,
		},
	]

	const mobileOTPField = [
		{
			fieldName: 'mobileOTP',
			fieldLabel: 'Enter OTP Sent to Phone no.',
			fieldKey: 'mobileOTP',
			fileObject: {
				initialValue: '',
				rules: [
					{
						required: true,
						message: 'Please enter the OTP Sent to Phone number ',
					},
				],
			},
			fieldToRender: <Input style={{ height: '48px', borderRadius: '8px' }} placeholder='Type here' />,
		},
	]

	const toggleCancelModal = () => {
		setCancelModal(!cancelModal)
	}

	const goToSignIn = () => {
		logOut()
		goTo('signup-select')
	}

	const getCancelModal = () => {
		return (
			<AeroModal footer={false} visible={cancelModal} onCancel={toggleCancelModal}>
				<div style={{ textAlign: 'center' }}>
					<MatImage style={{ margin: '20px' }} alt='published' src={IMAGES_SRC.BIG_INFO_ICON} />
					<div style={{ fontSize: '20px', marginBottom: '15px' }}>Are you sure you want to cancel the account registration?</div>
					<div style={{ fontSize: '16px', color: 'black', width: '95%', margin: 'auto' }}>
						Your {hasPhoneNumber ? 'details are' : 'email address is'} required to complete the account creation process.
					</div>
				</div>
				<div className={styles['modal-buttons']}>
					<Button onClick={toggleCancelModal} type='primary' className={styles['back-button']} block style={{ height: '50px' }}>
						No, Return
					</Button>

					<Button onClick={goToSignIn} type='primary' block style={{ height: '50px' }}>
						Yes, Cancel
					</Button>
				</div>
			</AeroModal>
		)
	}

	const getPageOne = () => {
		return (
			<>
				<div className={styles['header']}>
					<MatImage style={{ marginTop: '15px' }} alt='logo' src={IMAGES_SRC.NEW_BLUE_LOGO} />
				</div>
				<div className={styles['content']}>
					<div className={styles['content-wrapper']}>
						<MatImage style={{ marginTop: '15px' }} alt='logo' src={hasPhoneNumber ? IMAGES_SRC.EMAIL_AND_PHONE_ICON : IMAGES_SRC.EMAIL_ICON} />
						<div className={styles['content-header']}> Hi there, welcome to Aerologix!! </div>
						<div className={styles['content-text']}>
							{' '}
							We noticed you're logging in with a {channel} account linked to a phone number. To provide you with the best experience and keep your account secure, we
							need your email address {hasPhoneNumber ? 'and phone number' : ''}. Please enter your email address {hasPhoneNumber ? 'and phone number' : ''} below. We
							respect your privacy and will treat your information in line with our Privacy Policy.{' '}
						</div>
						<div className={styles['content-form']}>
							<Form layout='vertical'>
								<AeroField fieldsToRender={emailField} {...props} />

								{hasPhoneNumber ? (
									<>
										{' '}
										<AeroField fieldsToRender={mobileField} {...props} />{' '}
									</>
								) : (
									''
								)}

								<Button
									htmlType='submit'
									className={styles['continue-button']}
									onClick={() => {
										sendOTPs()
									}}
								>
									Continue
								</Button>
								<br />
								<br />
								<Button type='primary' className={styles['back-button']} onClick={toggleCancelModal}>
									Go back to Login page
								</Button>
							</Form>
						</div>
					</div>
				</div>
			</>
		)
	}

	const getPageTwo = () => {
		return (
			<>
				<div className={styles['header']}>
					<MatImage style={{ marginTop: '15px' }} alt='logo' src={IMAGES_SRC.NEW_BLUE_LOGO} />
				</div>
				<div className={styles['content']}>
					<div
						className={styles['back-btn']}
						onClick={() => {
							setPage(1)
						}}
					>
						<MatImage style={{ marginTop: '15px' }} alt='logo' src={IMAGES_SRC.BACK_BTN} />
					</div>
					<div className={styles['content-wrapper']}>
						<MatImage style={{ marginTop: '15px' }} alt='logo' src={hasPhoneNumber ? IMAGES_SRC.EMAIL_AND_PHONE_ICON : IMAGES_SRC.EMAIL_ICON} />
						<div className={styles['content-header']}> Verify your email address {hasPhoneNumber ? '& phone number' : ''} </div>
						<div className={styles['content-text']}>
							{' '}
							Please enter a 4-digit verification code sent to your email address {hasPhoneNumber ? 'and phone number' : ''}{' '}
						</div>
						<div className={styles['content-form']}>
							<Form layout='vertical'>
								<AeroField fieldsToRender={emailOTPField} {...props} />

								{getEmailResend()}

								{hasPhoneNumber ? (
									<>
										{' '}
										<AeroField fieldsToRender={mobileOTPField} {...props} /> {getMobileResend()}{' '}
									</>
								) : (
									''
								)}

								<Button
									htmlType='submit'
									className={styles['continue-button']}
									onClick={() => {
										validateOTPs()
									}}
								>
									Continue
								</Button>
								<br />
								<br />
								<Button type='primary' className={styles['back-button']} onClick={toggleCancelModal}>
									Go back to Login page
								</Button>
							</Form>
						</div>
					</div>
				</div>
			</>
		)
	}

	const getPageThree = () => {
		return (
			<>
				<div className={styles['header']}>
					<MatImage style={{ marginTop: '15px' }} alt='logo' src={IMAGES_SRC.NEW_BLUE_LOGO} />
				</div>
				<div className={styles['content']}>
					<div className={styles['content-wrapper']}>
						<MatImage style={{ marginTop: '15px' }} alt='logo' src={hasPhoneNumber ? IMAGES_SRC.BIG_CHECK_ICON : IMAGES_SRC.EMAIL_SUCCESS_ICON} />
						<div className={styles['content-header']}> {hasPhoneNumber ? 'Verification successful!' : 'Email verification successful!'} </div>
						<div className={styles['content-text']}>
							You will be redirected to the Projects Page in 5 sec <br />
							If not,{' '}
							<Button
								className={styles['content-link-button']}
								onClick={() => {
									goToHome()
								}}
								type='link'
							>
								Click here{' '}
							</Button>
						</div>
					</div>
				</div>
			</>
		)
	}

	const getEmailResend = () => {
		if (emailOTPTimer == 0) {
			clearInterval(emailOTPIntervalTimer.current)
		}

		return emailOTPTimer > 0 ? (
			<div className={styles['content-resend-text']}>
				{' '}
				Resend Code in <span style={{ fontWeight: 'bold' }}>{emailOTPTimer}s </span>{' '}
			</div>
		) : (
			<div className={styles['content-resend-button']}>
				<span style={{ cursor: 'pointer' }} onClick={resendEmailOTP}>
					Resend code
				</span>{' '}
			</div>
		)
	}

	const getMobileResend = () => {
		if (mobileOTPTimer == 0) {
			clearInterval(mobileOTPIntervalTimer.current)
		}
		return mobileOTPTimer > 0 ? (
			<div className={styles['content-resend-text']}>
				{' '}
				Resend Code in <span style={{ fontWeight: 'bold' }}>{mobileOTPTimer}s</span>{' '}
			</div>
		) : (
			<div className={styles['content-resend-button']}>
				{' '}
				<span style={{ cursor: 'pointer' }} onClick={resendMobileOTP}>
					Resend code
				</span>{' '}
			</div>
		)
	}

	const goToHome = () => {
		removeValueFromStorage('TEMP_TOKEN')
		goTo('home')
	}

	const getSelectedPage = () => {
		switch (page) {
			case 1: {
				return getPageOne()
			}
			case 2: {
				return getPageTwo()
			}
			case 3: {
				setTimeout(() => {
					goToHome()
				}, 5000)
				return getPageThree()
			}
			default: {
				return getPageOne()
			}
		}
	}

	const sendOTPs = e => {
		form.validateFields((err, values) => {
			console.log(err)
			if (!err) {
				if (hasPhoneNumber) {
					let email = values.email
					let mobile = isdCode + values.mobile
					let body = { email: email, mobileNumber: mobile }
					setEmailId(email)
					setMobileNo(mobile)
					sendOtpToEmailAndMobile(body)
					setChangePage(true)
				} else {
					let email = values.email
					let body = { email: email }
					setEmailId(email)
					sendEmailOtp(body)
					setChangePage(true)
				}
			}
		})
	}

	const validateOTPs = e => {
		form.validateFields((err, values) => {
			if (!err) {
				let body = {
					verificationId: emailVerificationId,
					emailOtp: values.emailOTP,
					webUrl: window.location.origin,
				}
				if (hasPhoneNumber) {
					body = {
						...body,
						otpkey: otpKey,
						mobileOtp: values.mobileOTP,
					}
				}
				verifyEmailAndMobileOtp(body)
			}
		})
	}

	const resendEmailOTP = () => {
		setEmailOTPTimer(30)
		emailOTPIntervalTimer.current = setInterval(() => {
			// Update the seconds state to decrement by 1 every second
			setEmailOTPTimer(prevSeconds => prevSeconds - 1)
		}, 1000)
		sendEmailOtp({ email: emailId })
	}

	const resendMobileOTP = () => {
		setMobileOTPTimer(30)
		mobileOTPIntervalTimer.current = setInterval(() => {
			// Update the seconds state to decrement by 1 every second
			setMobileOTPTimer(prevSeconds => prevSeconds - 1)
		}, 1000)
		getOtp({ mobileNumber: mobileNo })
	}
	return (
		<div className={styles['outerFrame']}>
			{getSelectedPage()}
			{getCancelModal()}
		</div>
	)
}

const AddEmailMobile = Form.create({ name: 'ADD_EMAIL_FORM' })(AddEmailMobileForm)

const mapStateToProps = state => {
	return {
		otpData: state.UserSessionReducer.getOtpData,
		token: state.UserSessionReducer.token,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		logOut: bindActionCreators(logOut, dispatch),
		sendEmailOtp: bindActionCreators(sendEmailOtp, dispatch),
		sendOtpToEmailAndMobile: bindActionCreators(sendOtpToEmailAndMobile, dispatch),
		getOtp: bindActionCreators(getOtp, dispatch),
		verifyEmailAndMobileOtp: bindActionCreators(verifyEmailAndMobileOtp, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AddEmailMobile)
