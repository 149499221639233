import React, { useState, useEffect } from 'react'
import { Button, Upload, message, Progress, Tooltip, notification } from 'antd'
import { InboxOutlined, PaperClipOutlined, CloseOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { AeroModal } from '../../../../AeroComponents'
import MatImage from '../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../AeroComponents/Image/image.constants'
import styles from './modal.module.scss'
import { getMultipartUrls, uploadMultipartFile, completeMultipartUpload, abortMultipartUpload, checkUploadedPreview, checkUploadedData } from '../../../../Stores/Projects/action'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'


function MultiPartUpload(props) {
    const { Dragger } = Upload;
    // extract props
    const {
        modalOpenStatus, projectId, closeHandler, type, // from parent
        multiPartUploads, // from state
        getMultipartUrls, uploadMultipartFile, completeMultipartUpload, abortMultipartUpload, checkUploadedPreview, checkUploadedData  // from dispatch
    } = props

    /* state */
    const [fileName, setFileName] = useState('') // filename to be uploaded 
    const [progress, setProgress] = useState(0)
    const [showProgress, setShowProgress] = useState(false) // progress bar
    const [readyToUpload, setReadyToUpload] = useState(false)  // file selected and ready to upload
    const [fileToUpload, setFileToUpload] = useState(false)
    const [isUploading, setIsUploading] = useState(true) // upload button in modal
    const [showAbort, setShowAbort] = useState(false) // show abort modal


    // effects
    useEffect(() => {
        if (multiPartUploads && multiPartUploads[projectId]) {

            let uploadProgress = Math.min(Math.ceil(multiPartUploads[projectId].percentage), 100)
            setProgress(uploadProgress)
            setFileName(multiPartUploads[projectId].name)

            if (multiPartUploads[projectId].status) { // if uploading
                setShowProgress(true)
                closeHandler()
                setReadyToUpload(false)
            }
            else {
                setShowProgress(false)
            }


        }
        else {
            setShowProgress(false)
        }
    }, [multiPartUploads, projectId]);


    const beforeUpload = (options) => { // Validate upload file before upload
        let file = options.file

        if (!(file.type === 'application/zip' || file.type === 'application/x-zip-compressed')) {
            message.error("You can only upload zip file")
            return false
        }

        setReadyToUpload(true)
        setFileToUpload(file)
        setFileName(file.name)
    }


    const unloadFunction = function (event) { // function to confirm closing tab
        return ' '
    }

    const uploadFile = () => {

        let file = fileToUpload
        let fileSize = file.size
        let fileName = file.name
        let noOfParts = calculateNoOfParts(fileSize)

        let controller = new AbortController();
        let abortSignal = controller.signal;

        getNotifyPermission()  // get permission for window notification
        window.onbeforeunload = unloadFunction // set function to prvent closing the tab
        setIsUploading(false) //to disable upload button in modal

        getMultipartUrls({ projectId: projectId, abortController: controller }, { projectId: projectId, name: fileName, noOfParts: noOfParts, type: type }).then(urlResponse => {


            message.success('File Upload started')


            // urlResponse will have action and value 
            let urls = urlResponse.value.data.urls
            let uploadId = urlResponse.value.data.uploadId



            setIsUploading(true)

            uploadParts(file, urls, projectId, abortSignal).then(uploadResponse => {
                // uploadResponse[0] is response from part uploads, 
                // uploadResponse[1] is corresponding projectId


                let completedData = []
                uploadResponse[0].forEach(item => {

                    completedData.push(item.value.data)  // item will have action and value 
                })


                let responseBody = {
                    uploadId: uploadId,
                    parts: completedData,
                    type: type,
                    projectId: uploadResponse[1]
                }

                completeMultipartUpload({
                    body: JSON.stringify(responseBody), projectId: uploadResponse[1], abortSignal: abortSignal,
                }).then(completeResponse => {

                    // Send in app notification
                    notification.open({
                        duration: 10,
                        message: ' File uploaded successfully ' + fileName,
                        className: styles['notification'],
                        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />
                    });


                    // send window notification
                    if (!document.hasFocus()) {
                        notify(' File uploaded successfully ' + fileName)
                    }


                    if (!isUploadRemaining()) {
                        window.onbeforeunload = ''
                    }

                    // update the file to be dowloaded only if we are in same window as the uploaded file
                    if (window.sessionStorage.getItem('projectId') === projectId) {
                        (type === 'preview') ?
                            checkUploadedPreview({ type: type, project: projectId }) :
                            checkUploadedData({ type: type, project: projectId })
                    }

                }).catch(error => {
                    console.log(error)
                    setShowProgress(false)
                    setIsUploading(true)
                    message.error('Error while uploading. Please try again')

                })

            }).catch(error => {
                console.log(error.message)
                setShowProgress(false)
                if (error.message !== 'The user aborted a request.') {
                    abortUpload()
                }
                setIsUploading(true)

            })

        }).catch(error => {
            console.log(error.message)
            setShowProgress(false)
            setIsUploading(true)
        })

    }

    async function uploadParts(file, urls, projectId, abortSignal) {
        let noOfParts = calculateNoOfParts(file.size)
        let FILE_CHUNK_SIZE = 0
        FILE_CHUNK_SIZE = file.size / noOfParts
        if ((FILE_CHUNK_SIZE / (1024 * 1024)) < 6) {
            FILE_CHUNK_SIZE = 6 * 1024 * 1024
        }

        const keys = Object.keys(urls)
        const promises = []

        // create chuncks of the file and upload
        for (const indexStr of keys) {
            const index = parseInt(indexStr) - 1
            const start = index * FILE_CHUNK_SIZE
            const end = (index + 1) * FILE_CHUNK_SIZE
            const blob = index < keys.length
                ? file.slice(start, end)
                : file.slice(start)
            let body = {
                url: urls[index + 1],
                partNo: index + 1,
                body: blob,
                projectId: projectId
            }


            let chunk_p = uploadMultipartFile(body, abortSignal)


            promises.push(
                chunk_p
            )
        }

        const resParts = await Promise.all(promises) // eact part response will have etag and partnumber

        return [resParts, projectId]

    }


    // check if any other upload is pending
    function isUploadRemaining() {
        let uploadRemaining = false
        for (let item in multiPartUploads) {
            if (multiPartUploads[item].status) {
                uploadRemaining = true
            }
        }
        return uploadRemaining
    }

    // create a window notification
    function notify(message) {

        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
        }
        else if (Notification.permission === "granted") {
            let browser_notification = new Notification(message);
            browser_notification.onclick = () => {
                window.focus()
            }
        }

    }

    // get permission for window notification
    function getNotifyPermission() {

        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
        }
        else if (Notification.permission === "default") {
            Notification.requestPermission()
        }

    }

    // calculate number of chuncks the file has to be splitted
    function calculateNoOfParts(fileSize) {
        const toMB = (fileSize / (1024 * 1024))
        if (toMB > 600) {
            return 100
        }
        if (toMB < 6) {
            return 1
        }
        else {
            return Math.ceil(toMB / 6)
        }
    }



    function confirmAbort() {
        setShowAbort(true)
    }


    function abortUpload() {

        if (multiPartUploads[projectId] && !multiPartUploads[projectId].status) {
            message.info('File already uploaded!')
            setShowAbort(false)
            return
        }
        setIsUploading(true)
        multiPartUploads[projectId].abortController.abort();

        let responseBody = {
            uploadId: multiPartUploads[projectId].uploadId,
            type: type,
            projectId: projectId
        }

        abortMultipartUpload(projectId, { body: responseBody }).then(res => {
            message.error('File upload cancelled')
            setShowAbort(false)
            if (isUploadRemaining()) {
                window.onbeforeunload = ''
            }
        }).catch(error => {
            console.log(error.message)
            message.error('Error while aborting.')
        })

    }






    return (
        <>
            {/* Upload Modal */}
            <AeroModal footer={false} onCancel={() => { closeHandler(); setReadyToUpload(false) }}
                visible={modalOpenStatus}>
                <div className={styles['modal-wrapper']}>
                    {(readyToUpload) ? (
                        // After selecting file
                        <div>
                            <div className={styles['upload-ready']}>
                                <div className={styles['upload-name']}>
                                    <Tooltip placement='top' title={fileName}>
                                        <span><PaperClipOutlined /> </span>{' '} {fileName}
                                    </Tooltip>
                                </div>

                                <div className={styles['progress-right']}>
                                    <CloseOutlined className={styles['progress-close']} onClick={() => { setReadyToUpload(false) }} />
                                </div>
                            </div>
                            <br />
                            <Button className={styles['upload-button']} type='primary' onClick={uploadFile} disabled={!isUploading} >Upload</Button>

                        </div>


                    ) : (
                        // Before selecting file
                        <Dragger className={styles['dragger']} showUploadList={false} customRequest={beforeUpload} >
                            <p >
                                <InboxOutlined className={styles['upload-icon']} />
                            </p>
                            <div>
                                <div className={styles['header']}>Click or drag file to this area to upload</div>
                                <div className={styles['header-info']}>only zip format is supported</div>

                            </div>
                        </Dragger>
                    )}
                </div>
            </AeroModal>

            {/* Progress bar */}
            {(showProgress) ? (
                <div className={styles['progress-wrapper']}>
                    <div className={styles['progress']}>
                        <div className={styles['progress-left']}>
                            <PaperClipOutlined />
                        </div>

                        <div className={styles['progress-center']}>
                            <div className={styles['progress-center-top']}>
                                <div className={styles['progress-name']} >

                                    <Tooltip placement='top' title={fileName}>
                                        {fileName}
                                    </Tooltip>
                                </div>
                                <div>{progress}%</div>
                            </div>
                            <div className={styles['progress-bar']}>
                                <Progress percent={progress} showInfo={false} />
                            </div>
                        </div>

                        <div className={styles['progress-right']}>
                            <CloseOutlined className={styles['progress-close']} onClick={confirmAbort} />
                        </div>

                    </div>

                </div>

            ) : ''}

            {/* Abort modal */}
            <AeroModal footer={false} onCancel={() => { setShowAbort(false) }} visible={showAbort}>
                <div className={styles['modal-wrapper']}>
                    <MatImage className={styles['exclamation-icon']} src={IMAGES_SRC.RED_EXCLAMATION_ICON} />
                    <div>
                        <div className={styles['header']}>Cancel file upload?</div>
                        <div className={styles['header-info']}>You will lose all upload progress</div>
                        <div className={styles['body']}>
                            <Button className={styles['button']} onClick={() => { setShowAbort(false) }} type='primary'>
                                No
                            </Button>
                            &nbsp;&nbsp;
                            <Button className={styles['button']} onClick={abortUpload} >
                                Yes
                            </Button>
                        </div>
                    </div>
                </div>
            </AeroModal>

        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        multiPartUploads: state.ProjectsReducer.multiPartUpload,  // all upload data

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMultipartUrls: bindActionCreators(getMultipartUrls, dispatch),
        uploadMultipartFile: bindActionCreators(uploadMultipartFile, dispatch),
        completeMultipartUpload: bindActionCreators(completeMultipartUpload, dispatch),
        abortMultipartUpload: bindActionCreators(abortMultipartUpload, dispatch),
        checkUploadedPreview: bindActionCreators(checkUploadedPreview, dispatch),
        checkUploadedData: bindActionCreators(checkUploadedData, dispatch),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,

)(MultiPartUpload)
