import React from 'react'
import LocationSearchInput from '../../GoogleMap/SearchPlaceAndCurrentLocation'

export const ADDRESS_DETAILS_FIELDS = (scope, viewMode) => {
	return [
		{
			fieldName: 'ADD_STRING',
			fieldLabel: 'Address',
			fieldKey: 'address',
			fileObject: {
				rules: [
					{
						required: true,
						message: 'Please select your address!',
						whitespace: true,
					},
				],
			},
			fieldToRender: (
				<LocationSearchInput
					source='p2pProfile'
					showSelectOnMap
					openModal={scope.openMadalToAddDetails}
					viewMode={viewMode}
					address={scope.props.form.getFieldValue('address')}
					onChange={scope.handleAddressChange}
					onAddressSelect={scope.handleAddressSelect}
					clearAddress={scope.clearAddress}
				/>
			),
		},
	]
}
