/*eslint-disable*/
import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, message } from 'antd'
import AeroField from '../../../AeroComponents/FormFields'
import { CHANGE_PASSWORD_FIELDS } from '../../../AeroComponents/FormFields/Metadata/change-password.fields'

class ChangePasswordForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fieldsToRender: CHANGE_PASSWORD_FIELDS(this),
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.updatePassword(values)
				this.props.form.resetFields()
			} else {
				message.error('Please resolve the errors shown!')
			}
		})
	}

	render() {
		const { fieldsToRender } = this.state
		return (
			<Form layout='vertical' onSubmit={this.handleSubmit}>
				<div style={{ fontSize: '16px', color: '#47464A', marginBottom: '20px' }}>Change Password</div>
				<AeroField fieldsToRender={fieldsToRender} {...this.props} />
				<Button style={{ height: '48px' }} block type='primary' htmlType='submit'>
					Update
				</Button>
			</Form>
		)
	}
}

const ChangePassword = Form.create({ name: 'CHANGE_PASSWORD_FORM' })(ChangePasswordForm)

export default ChangePassword
