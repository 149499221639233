/*eslint-disable*/
import React from 'react'
import './on-boarding.sass'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Divider, Input, message, Popover } from 'antd'
import { TEXTS } from './on-boarding.constants'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { getValueFromStorage, setValueToStorage } from '../../Services/LocalStorageService'
import { SIGN_UP_FIELDS } from '../../AeroComponents/FormFields/Metadata/sign-up-fields'
import { goTo } from '../../Services/NavigationService'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { signUp, checkReferralCode } from '../../Stores/OnBoarding/action'
import AeroField from '../../AeroComponents/FormFields'
import { isEmpty } from 'lodash'
import { urlConfig } from '../../Services/UtilityService'
const countryConfig = urlConfig()
class SignUpForm extends React.Component {
	constructor(props) {
		super(props)
		const profileType = getValueFromStorage('PROFILE_TYPE')
		this.state = {
			profileType,
			loading: false,
			fieldsToRender: SIGN_UP_FIELDS(props.form),
			makeApplyVisible: false,
			suffixText: 'APPLY',
			codeApplied: false,
			referralResponse: props.location.state ? props.location.state.referralResponse : {},
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.signUpSuccess !== nextProps.signUpSuccess) {
			if (nextProps.signUpSuccess) {
				goTo('signup-success')
			}
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const { firstName, lastName, password, referralCode, email } = values
				if (firstName.length > 50 || lastName.length > 50 || password.length > 50 || referralCode?.length > 50 || email.length > 50) {
					return message.warning('The character limit is 50!')
				}
				const data = {
					...values,
					countryId: countryConfig.countryId,
					profileType: this.state.profileType,
				}
				const referralApplied = values.referralCode ? 'Referral Code Applied' : 'Referral Code Not Applied'
				this.props.signUp(data)
			}
		})
	}

	showApply = () => {
		this.setState({ makeApplyVisible: true })
	}

	validateReferralCode = () => {
		const referralCode = this.props.form.getFieldValue('referralCode')
		if (referralCode.trim().length === 0) {
			message.error('Referral code cannot be empty!')
		} else {
			this.props.checkReferralCode({ referralCode: referralCode, email: this.props.form.getFieldValue('email') })
			this.setState({ suffixText: 'REMOVE', codeApplied: true })
		}
	}

	removeCode = () => {
		this.props.form.setFieldsValue({ referralCode: '' })
		this.setState({ suffixText: 'APPLY', codeApplied: false })
	}

	changeProfileType = type => {
		setValueToStorage('PROFILE_TYPE', type)
		goTo('login')
	}

	updateUserType = () => {
		setValueToStorage('PROFILE_TYPE', this.state.profileType === 'PILOT' ? 'CONSUMER' : 'PILOT')
		goTo('signup-select')
	}

	render() {
		const { profileType, fieldsToRender, makeApplyVisible, suffixText, codeApplied, referralResponse } = this.state
		const { getFieldDecorator } = this.props.form
		return (
			<>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<MatImage style={{ marginTop: '15px' }} alt='logo' src={IMAGES_SRC.NEW_BLUE_LOGO} />
					<Popover
						trigger='click'
						content={
							<>
								<Button onClick={() => this.changeProfileType('PILOT')} type='link'>
									As a Pilot
								</Button>
								<br />
								<Button onClick={() => this.changeProfileType('CONSUMER')} type='link'>
									As a Client
								</Button>
							</>
						}
					>
						<Button style={{ marginTop: '15px' }} className='login-as-other-web' type='link'>
							Log In
						</Button>
					</Popover>
				</div>
				<Divider></Divider>
				<div className='ob-content-wrapper-form'>
					<div>
						<div style={{ fontSize: '24px', marginBottom: '20px' }}>
							<Button style={{ float: 'left' }} type='link' onClick={() => goTo('signup-select')}>
								<MatImage style={{ position: 'relative', bottom: '9px' }} src={IMAGES_SRC.BACK_BTN} />
							</Button>
							<span style={{ position: 'relative', right: '30px' }}>Sign up as a {profileType === 'PILOT' ? 'Pilot' : 'Client'}</span>
						</div>
						<div>
							{!isEmpty(referralResponse) ? (
								<div style={{ textAlign: 'center' }} className='box-wrapper'>
									<div className='invitee-block'>
										<MatImage src={IMAGES_SRC.SMALL_GIFT_ICON} /> Invited by {referralResponse.firstName}
									</div>
								</div>
							) : (
								''
							)}
							<Form style={{ width: '60%', margin: 'auto' }} layout='vertical' onSubmit={this.handleSubmit}>
								<AeroField profileType={profileType} fieldsToRender={fieldsToRender} {...this.props} />
								{profileType === 'PILOT' ? (
									<Form.Item label='Referral Code'>
										{getFieldDecorator('referralCode', { initialValue: !isEmpty(referralResponse) ? referralResponse.referralCode : '' })(
											<Input
												style={{ height: '48px', borderRadius: '8px' }}
												disabled={!isEmpty(referralResponse) ? true : false}
												onChange={this.showApply}
												suffix={
													makeApplyVisible ? (
														<Button
															style={codeApplied ? { color: 'red' } : {}}
															onClick={codeApplied ? this.removeCode : this.validateReferralCode}
															type='link'
														>
															{suffixText}
														</Button>
													) : (
														<span />
													)
												}
											/>,
										)}
									</Form.Item>
								) : (
									''
								)}
								<div style={{ fontSize: '10px' }}>
									By clicking "Sign Up", you agree that you are 18 years of age or older and You agree to Aerologix's
									<Button
										style={{ fontSize: '10px', position: 'relative', right: '10px' }}
										type='link'
										href={`https://aerologix.com/${profileType === 'PILOT' ? 'terms' : 'client-terms'}`}
										target='_blank'
									>
										Terms of Use
									</Button>
									<span style={{ position: 'relative', right: '20px' }}>and acknowledge you have read the</span>
									<Button style={{ fontSize: '10px', position: 'relative', right: '29px' }} type='link' href='https://aerologix.com/policy' target='_blank'>
										Privacy Policy
									</Button>
									.
								</div>
								<Form.Item style={{ marginTop: '10px' }}>
									<Button style={{ height: '48px' }} block type='primary' htmlType='submit'>
										{TEXTS.SIGN_UP}
									</Button>
								</Form.Item>
							</Form>
						</div>

						<div style={{ textAlign: 'center', padding: '30px' }}>
							<span style={{ color: '#84818A' }}>{profileType === 'PILOT' ? 'Want to book a project?' : 'Here to hire for a project?'}</span>{' '}
							<Button onClick={() => this.updateUserType()} type='link'>
								Join as a {profileType === 'PILOT' ? 'Client' : 'Pilot'}
							</Button>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const SignUp = Form.create({ name: 'SIGN_UP_FORM' })(SignUpForm)

const mapStateToProps = state => {
	return {
		signUpSuccess: state.OnBoardingReducer.signUpSuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		signUp: bindActionCreators(signUp, dispatch),
		checkReferralCode: bindActionCreators(checkReferralCode, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SignUp)
