export const LOG_IN = 'LOG_IN'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_CITIES = 'GET_CITIES'
export const GET_STATES = 'GET_STATES'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const GET_ALL_DRONES = 'GET_ALL_DRONES'
export const VERIFY_WITH_DVS = 'VERIFY_WITH_DVS'
export const SOCIAL_MEDIA_LOGIN = 'SOCIAL_MEDIA_LOGIN'
export const UPDATE_BASE_LOCATION = 'UPDATE_BASE_LOCATION'
export const GET_ALL_CASA_LICENSE_TYPES = 'GET_ALL_CASA_LICENSE_TYPES'
export const DELETE_CASA_LICENSE = 'DELETE_CASA_LICENSE'
export const ADD_CASA_LICENSE = 'ADD_CASA_LICENSE'
export const ADD_ARN_DETAILS = 'ADD_ARN_DETAILS'
export const SEND_REFERRAL_INVITE = 'SEND_REFERRAL_INVITE'
export const VERIFY_SHARABLE_LINK = 'VERIFY_SHARABLE_LINK'
export const GET_SOCIAL_AUTH = 'GET_SOCIAL_AUTH'
export const GET_OTP = 'GET_OTP'
export const REJECT_ENTERPRISE_PILOT_PROMPT = 'REJECT_ENTERPRISE_PILOT_PROMPT'
export const CREATE_ENTERPRISE_PILOT = 'CREATE_ENTERPRISE_PILOT'
export const GET_ENTERPRISE_PILOT_DATA = 'GET_ENTERPRISE_PILOT_DATA'
export const UPDATE_ENTERPRISE_PILOT = 'UPDATE_ENTERPRISE_PILOT'
export const DELETE_DRONE = 'DELETE_DRONE'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const ADD_COLLABORATOR = 'ADD_COLLABORATOR'
export const GET_ORDERS_NOT_OF_COLLABORATOR = 'GET_ORDERS_NOT_OF_COLLABORATOR'
export const DELETE_COLLABORATOR = 'DELETE_COLLABORATOR'
export const UPDATE_COLLABORATOR_PERMISSIONS = 'UPDATE_COLLABORATOR_PERMISSIONS'
export const CONTACT_SUPPORT = 'CONTACT_SUPPORT'
export const GET_PILOT_PORTFOLIO = 'GET_PILOT_PORTFOLIO'
export const GET_PORTFOLIO_UPLOAD_URL = 'GET_PORTFOLIO_UPLOAD_URL'
export const DELETE_PORTFOLIO_MEDIA = 'DELETE_PORTFOLIO_MEDIA'
export const UPDATE_CASA_LICENSE = 'UPDATE_CASA_LICENSE'
export const RECOVER_PILOT_ACCOUNT = 'RECOVER_PILOT_ACCOUNT'
export const GET_CHAT_ACCESS_TOKEN = 'GET_CHAT_ACCESS_TOKEN'
export const GET_ENTERPRISE_PLUS_CONTRACT_API = 'GET ENTERPRISE PLUS CONTRACT API'
export const CANCEL_ENTERPRISE_PLUS_PROMPT = 'CANCEL ENTERPRISE PLUS PROMPT'
export const COMPLETE_PILOT_ENTERPRISE_PLUS = 'COMPLETE PILOT ENTERPRISE PLUS'
export const UPDATE_DEVICE_TOKEN = 'UPDATE_DEVICE_TOKEN'
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS'
export const UPDATE_NOTIFICATION_READ_STATUS = 'UPDATE_NOTIFICATION_READ_STATUS'
export const PASSED_QUIZ = 'PASSED_QUIZ'
export const GET_ALL_LICENSE_TYPES = 'GET_ALL_LICENSE_TYPES'
export const GET_ALL_DRONE_INSURANCE_TYPES = 'GET_ALL_DRONE_INSURANCE_TYPES'
export const ADD_DRONE_INSURANCE = 'ADD_DRONE_INSURANCE'
export const GET_ALL_PURCHASED_INSURANCE = 'GET_ALL_PURCHASED_INSURANCE'
export const DELETE_DRONE_INSURANCE = 'DELETE_DRONE_INSURANCE'
export const UPDATE_DRONE_INSURANCE = 'UPDATE_DRONE_INSURANCE'
export const REPORT_ERROR = 'REPORT_ERROR'
export const GET_WEB_CONFIG = 'GET_WEB_CONFIG'
export const GET_EMAIL_OTP = 'GET_EMAIL_OTP'
export const GET_EMAIL_AND_MOBILE_OTP = 'GET_EMAIL_AND_MOBILE_OTP'
export const VERIFY_EMAIL_AND_MOBILE_OTP = 'VERIFY_EMAIL_AND_MOBILE_OTP'