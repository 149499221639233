import React, { useEffect, useState } from 'react'
import { Descriptions, Button, Tooltip } from 'antd'
import { PaperClipOutlined } from '@ant-design/icons'
import moment from 'moment'
import '../projects.sass'
import { timeDiffString } from '../../../Services/UtilityService'
import ProjectRequirements from './project-requirements'
import MatImage from '../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { pushEventToMixpanel } from '../../../Services/MixpanelService'
import { getWebConfig } from '../../../Stores/UserSession/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const SLOT_TEXT = {
	MORNING: ' before 10 am',
	MIDDAY: ' between 10 am - 2 pm',
	AFTERNOON: ' between 2 pm - 6 pm',
	EVENING: ' after 6 pm',
}

const ProjectDescription = props => {
	const { data, openBidHistory, profileType, webConfig, getWebConfig } = props
	const [configData, setConfigData] = useState(webConfig)
	const [waitForApi, setWaitForApi] = useState(false)

	useEffect(() => {
		if (!configData) {
			setWaitForApi(true)
			getWebConfig()
		}
	}, [configData])

	useEffect(() => {
		if (webConfig && waitForApi) {
			setWaitForApi(false)
			setConfigData(webConfig)
		}
	}, [webConfig])
	const descComp = type => {
		return (
			<Descriptions
				labelStyle={{ color: '#84818A' }}
				style={{ marginBottom: '20px' }}
				size='small'
				column={type === 'web' ? 2 : 1}
				layout={type === 'web' ? 'horizontal' : 'vertical'}
			>
				<Descriptions.Item
					label={
						data.enterprise_project
							? 'Your Earnings'
							: (profileType === 'CONSUMER' && data.state === 'ALLOCATE_PILOT') ||
							  (profileType === 'PILOT' &&
									(data.state === 'ALLOCATE_PILOT' ||
										data.quote?.status === 'PENDING' ||
										data.quote?.status === 'REJECTED' ||
										data.quote?.status === 'INVALID' ||
										data.quote?.status === 'WITHDRAWN'))
							? 'Budget'
							: 'Project Amount'
					}
				>{`${
					data.enterprise_project
						? data.quote?.amount === 0 && data.is_negotiable
							? 'Negotiable'
							: configData.currency + ' ' + data.quote?.amount
						: data.is_negotiable && data.total_fee === 0
						? 'Negotiable'
						: configData.currency + ' ' + data.total_fee
				}`}</Descriptions.Item>
				<Descriptions.Item label='Location'>
					<Tooltip placement='top' title={data.address}>
						<Button
							style={{ position: 'relative', bottom: '5px', right: '15px' }}
							type='link'
							target='_blank'
							onClick={() => {
								// Sending Location Hyperlink Clicked event to Mixpanel
								pushEventToMixpanel('', 'Location Hyperlink Clicked')
							}}
							rel='noopener noreferrer'
							href={'https://www.google.com/maps/place/' + data.latitude + ',' + data.longitude}
						>
							<span>{data.address.length <= 50 ? data.address : data.address.substring(0, 10) + '...'}</span>
						</Button>
					</Tooltip>
				</Descriptions.Item>
				<Descriptions.Item label={!data.deadline_type || data.deadline_type !== 'ON' ? 'Deadline' : 'Project Date'}>
					{data.deadline_type !== 'FLEXIBLE'
						? `To be completed ${data.deadline_type === 'ON' ? 'on ' : 'by '} ${moment
								.utc(data.deadline)
								.local()
								.format(`DD MMM 'YY`)}`
						: 'Flexible'}{' '}
				</Descriptions.Item>
				{data.deadline_slot ? <Descriptions.Item label='Time Constraints'>Data to be collected{SLOT_TEXT[data.deadline_slot]}</Descriptions.Item> : null}
			</Descriptions>
		)
	}
	return (
		<div style={{ marginTop: '30px' }}>
			<div style={{ fontSize: '24px', padding: '10px' }}>
				{data.name}
				<span className='web-posted-at-text'>Posted {timeDiffString(data.created_at)} ago</span>
			</div>
			<div className='mobile-posted-at-text'>Posted {timeDiffString(data.created_at)} ago</div>
			{!data.enterprise_project &&
			profileType === 'PILOT' &&
			(data.quote?.status === 'PENDING' || data.quote?.status === 'WITHDRAWN' || data.quote?.status === 'REJECTED' || data.quote?.status === 'INVALID') ? (
				<div style={{ border: '1px solid #ECEAEE', borderRadius: '8px', float: 'right', textAlign: 'center' }}>
					<div style={{ fontSize: '12px', color: '#84818A', padding: '7px' }}>
						<MatImage alt='sbmtd-icn' src={IMAGES_SRC.SUBMITTED_BID_ICON} /> {data.quote?.status !== 'PENDING' ? 'Last' : null} Submitted Bid
					</div>
					<div style={{ fontSize: '14px', marginBottom: '5px' }}>{configData.currency + ' ' + data.quote?.amount}</div>
					<div>
						<Button
							onClick={() => openBidHistory(data.quote?.id)}
							block
							style={{ fontSize: '12px', color: '#00B3FF', position: 'relative', top: '1px', borderRadius: '0px 0px 8px 8px' }}
							type='default'
						>
							Bid History
						</Button>
					</div>
				</div>
			) : null}

			<p style={{ padding: '10px', fontSize: '16px' }}>{data.additional_info}</p>
			{data.descriptionFile ? (
				<div className='attachment-wrapper-box'>
					<Button type='link' href={data.descriptionFile.url} target='_blank' rel='noopener noreferrer'>
						<PaperClipOutlined style={{ color: '#00b3ff', position: 'relative', bottom: '3px' }} /> Attachment
					</Button>
				</div>
			) : null}
			{data.enterprise_project ? <ProjectRequirements data={data} /> : null}
			<div style={{ marginLeft: '10px' }}>
				<div style={{ fontSize: '18px', color: '#84818A', marginBottom: '20px' }}>Project Details</div>
				<div className='web-desc-holder'>{descComp('web')}</div>
				<div className='mobile-desc-holder'>{descComp('mobile')}</div>
			</div>
		</div>
	)
}
const mapStateToProps = state => {
	return {
		webConfig: state.UserSessionReducer.webConfig,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProjectDescription)
