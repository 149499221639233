import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { Button, Layout } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './app.sass'
import SideBarNavigation from './SideBarMenu'
import Header from './Header'
import {
	logOut,
	getProfile,
	getCountries,
	getStates,
	getCities,
	updateDeviceToken,
	getAllNotifications,
	updateNotificationReadStatus,
	getWebConfig,
} from '../Stores/UserSession/action'
import { goTo, setNavigation } from '../Services/NavigationService'
import ScreenLoader from '../AeroComponents/Loader'
import { getValueFromStorage, setValueToStorage } from '../Services/LocalStorageService'
import { Client as TwilioClient } from '@twilio/conversations'
// import { fetchToken, onMessageListener } from '../Services/firebase-service'
import { SIDE_MENU_LIST } from './SideBarMenu/side-bar-menu-constants'
import { AeroModal } from '../AeroComponents'
import { IMAGES_SRC } from '../AeroComponents/Image/image.constants'
import MatImage from '../AeroComponents/Image'
import { NON_AUTH_ROUTES, PROFILE_ROUTES } from './route-constants'

const createBrowserHistory = require('history').createBrowserHistory
const history = createBrowserHistory()
const { Sider, Content } = Layout

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoggedIn: props.isAuthenticated,
			profileData: props.profileData,
			pageLoading: props.pageLoading,
			mobileSidebarVisible: false,
			isNotificationTokenFound: false,
			allNotifications: null,

			profileRoutes: [],
			nonAutRoutes: NON_AUTH_ROUTES(this),
			configData: props.webConfig,
		}
		setNavigation(history)
		// Notification.requestPermission()
		// fetchToken(this.setTokenFound, this.updateDeviceToken)
		// onMessageListener(this.updateNotifications, this.callReadApi)
		if (
			window.location.pathname === '/payment/success' ||
			window.location.pathname === '/payment/failure' ||
			window.location.pathname === '/payment/success/' ||
			window.location.pathname === '/payment/failure/'
		) {
			window.localStorage.removeItem('MOVED_OUT_FROM')
		} else {
			if (getValueFromStorage('MOVED_OUT_FROM')) {
				history.push(getValueFromStorage('MOVED_OUT_FROM'))
				window.localStorage.removeItem('MOVED_OUT_FROM')
			}
		}

		this.authStateRedirection(props.isAuthenticated, false)
		this.chatClientRef = React.createRef()
	}

	componentDidMount() {
		if (!this.state.configData) {
			this.props.getWebConfig()
		}
	}

	setTokenFound = val => this.setState({ isNotificationTokenFound: val })
	updateDeviceToken = val => this.props.updateDeviceToken({ deviceToken: val }).then(() => setValueToStorage('DEVICE_TOKEN', val))
	updateNotifications = payload => {
		const tempPayload = payload
		tempPayload.data.readStatus = payload.data.readStatus === 'true' ? true : false
		const notificationData = this.state.allNotifications
		notificationData.unreadCount = notificationData.unreadCount + 1
		notificationData.data.unshift(tempPayload.data)
		this.setState({ allNotifications: notificationData })
	}

	reduceUnreadCount = () => {
		const notificationData = this.state.allNotifications
		notificationData.unreadCount = notificationData.unreadCount - 1
		this.setState({ allNotifications: notificationData })
	}

	callReadApi = selectedObj => {
		const updatedList = this.state.allNotifications
		this.props.updateNotificationReadStatus({ id: selectedObj.id })
		const resultIndex = updatedList.data.findIndex(item => item.id === selectedObj.id)
		updatedList.data[resultIndex].readStatus = true
		if (this.state.allNotifications.unreadCount > 0) this.reduceUnreadCount()
		this.setState({ allNotifications: updatedList })
		const subrole = getValueFromStorage('SUBROLE')
		const profileType = getValueFromStorage('PROFILE_TYPE')
		const obj = SIDE_MENU_LIST[subrole && subrole in SIDE_MENU_LIST ? subrole : profileType].find(item => item.id === selectedObj.module)
		if (obj) {
			const path = obj.pathName.slice(1)
			goTo(path)
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
			this.setState({
				isLoggedIn: nextProps.isAuthenticated,
			})
			this.authStateRedirection(nextProps.isAuthenticated, true)
		}
		if (this.props.profileData !== nextProps.profileData) {
			if (nextProps.profileData) {
				this.setState({
					profileData: nextProps.profileData,
					profileRoutes: PROFILE_ROUTES(nextProps.profileData, this),
				})
			}
		}
		if (this.props.allNotifications !== nextProps.allNotifications) {
			if (nextProps.allNotifications) {
				this.setState({
					allNotifications: nextProps.allNotifications,
				})
			}
		}
		if (this.props.pageLoading !== nextProps.pageLoading) {
			this.setState({
				pageLoading: nextProps.pageLoading,
			})
		}
		if (this.props.userDeletionFlag !== nextProps.userDeletionFlag) {
			goTo('user-deletion', nextProps.loginData)
		}
		if (this.props.webConfig !== nextProps.webConfig) {
			this.setState({
				configData: nextProps.webConfig,
			})
		}
	}

	authStateRedirection = (isAuthenticated, redirect) => {
		if (isAuthenticated) {
			this.props.getProfile()
			redirect && history.push('/loggedInRedirect')
			this.props.getAllNotifications()
		} else {
			redirect && history.push('/login')
			// this.props.logOut();
		}
	}
	pushToClevertap = data => {
		window.clevertap.onUserLogin.push({
			Site: {
				Name: data.name,
				Identity: data.id,
				Email: data.email,
				Phone: this.state.configData.isdCode + data.mobileNumber, // Phone (with the country code)
				// optional fields. controls whether the user will be sent email, push etc.
				'MSG-email': false, // Disable email notifications
				'MSG-push': true, // Enable push notifications
				'MSG-sms': true, // Enable sms notifications
				'MSG-whatsapp': true, // Enable WhatsApp notifications
			},
		})
	}

	handleHamburger = () => {
		this.setState(prevState => ({
			mobileSidebarVisible: !prevState.mobileSidebarVisible,
		}))
	}

	createChatClient = (token, initializationCompleted, initializationFailed) => {
		this.chatClientRef.current = new TwilioClient(token)
		this.chatClientRef.current.addListener('initialized', initializationCompleted)
		this.chatClientRef.current.addListener('initFailed', initializationFailed)
	}

	render() {
		const { isLoggedIn, profileData, mobileSidebarVisible, allNotifications, profileRoutes, nonAutRoutes } = this.state
		return (
			<>
				<Router history={history}>
					{isLoggedIn ? (
						<Layout className='app-container'>
							{this.state.pageLoading && <ScreenLoader />}
							<Sider className='sidebar-on'>
								{profileData && (
									<SideBarNavigation
										history={history}
										handleHamburger={this.handleHamburger}
										mobileSidebarVisible={mobileSidebarVisible}
										logOut={this.props.logOut}
										profileData={profileData}
									/>
								)}
							</Sider>
							<Layout>
								<Header
									handleHamburger={this.handleHamburger}
									userName={profileData ? profileData.firstName : ''}
									profilePicture={profileData && profileData.profilePicture ? profileData.profilePicture : ''}
									enterpriseFlag={profileData && profileData.enterprisePilot ? true : false}
									vacationMode={profileData && profileData.vacationMode ? true : false}
									enterprisePlusPilot={profileData && profileData.enterprisePlusPilot}
									notifications={allNotifications}
									reduceUnreadCount={this.reduceUnreadCount}
									{...this.props}
								/>
								<Content style={{ overflow: 'scroll', height: '100%', width: '100%', background: 'white' }}>
									<Switch>
										{profileData && profileRoutes[getValueFromStorage('PROFILE_TYPE')].length
											? profileRoutes[getValueFromStorage('PROFILE_TYPE')].map(item => (
													<Route key={item.key} exact path={item.path} render={item.component} />
											  ))
											: null}
									</Switch>
								</Content>
							</Layout>
						</Layout>
					) : (
						<div>
							{this.state.pageLoading && <ScreenLoader />}
							<Switch>
								{nonAutRoutes.map(item => (
									<Route key={item.key} exact path={item.path} render={item.component} />
								))}
							</Switch>
						</div>
					)}
				</Router>

				<AeroModal footer={false} open={this.state.projectPublishedModal} onCancel={this.toggleProjectPublishModal}>
					<div style={{ textAlign: 'center' }}>
						<MatImage style={{ margin: '20px' }} alt='published' src={IMAGES_SRC.BIG_CHECK_ICON} />
						<div style={{ fontSize: '20px', marginBottom: '15px' }}>Your project has been published</div>
						<div style={{ fontSize: '16px', color: '#84818A', width: '95%', margin: 'auto' }}>
							You will soon start receiving bids for your project from our Pilot network
						</div>
					</div>
					<div style={{ textAlign: 'center', marginTop: '25px' }}>
						<Button onClick={this.toggleProjectPublishModal} type='primary' block style={{ height: '50px' }}>
							Okay, Got it
						</Button>
					</div>
				</AeroModal>
			</>
		)
	}
}
const mapStateToProps = state => {
	return {
		isAuthenticated: state.UserSessionReducer.authState.isAuthenticated,
		profileData: state.UserSessionReducer.profileData,
		pageLoading: state.UserSessionReducer.pageLoading,
		userDeletionFlag: state.UserSessionReducer.userDeletionFlag,
		loginData: state.UserSessionReducer.loginData,
		allNotifications: state.UserSessionReducer.allNotifications,
		webConfig: state.UserSessionReducer.webConfig,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		logOut: bindActionCreators(logOut, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
		getCountries: bindActionCreators(getCountries, dispatch),
		getStates: bindActionCreators(getStates, dispatch),
		getCities: bindActionCreators(getCities, dispatch),
		updateDeviceToken: bindActionCreators(updateDeviceToken, dispatch),
		getAllNotifications: bindActionCreators(getAllNotifications, dispatch),
		updateNotificationReadStatus: bindActionCreators(updateNotificationReadStatus, dispatch),
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(App)
