import { Button, Divider, Input } from 'antd'
import React from 'react'

const DroneDrawerContent = props => {
	const { handleDroneSearch, toggleSupportDrawer, data, type } = props
	return (
		<>
			{type === 'web' && <Input.Search placeholder='Search by name' style={{ borderRadius: '8px', margin: '10px' }} onChange={handleDroneSearch} />}
			{data.map(item => (
				<div key={item.type} style={{ marginTop: '10px' }}>
					{item.name}
					<Divider></Divider>
				</div>
			))}

			{!data.length && (
				<div style={{ textAlign: 'center', padding: '10px' }}>
					<p style={{ color: '#B8BABE', fontSize: '32px' }}>Oops!</p>
					<p style={{ color: '#515663', fontSize: '16px' }}>Drone model is not supported by Aerologix</p>
					<p>
						<Button
							onClick={toggleSupportDrawer}
							style={{
								borderRadius: '8px',
								width: '100%',
								height: '50px',
								background: type === 'mobile' ? '#00B3FF' : null,
								border: type === 'mobile' ? '0px' : null,
							}}
							type='primary'
						>
							Request for support
						</Button>
					</p>
				</div>
			)}
		</>
	)
}

export default DroneDrawerContent
