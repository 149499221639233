/*eslint-disable*/
import React, { Component } from 'react'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import { Link } from 'react-router-dom'

class PageNotFound extends Component {
	render() {
		return (
			<div style={{ textAlign: 'center' }}>
				<div style={{ fontSize: '33px', fontWeight: '300', color: '#373A3C', marginTop: '50px', marginBottom: '20px' }}>Error: 404 Page Not Found</div>
				<MatImage src={IMAGES_SRC.NOT_FOUND_ICON} />
				<div style={{ fontSize: '16px', marginTop: '30px' }}>
					An error ocurred and your request couldn’t be completed. <br />
					Either check the URL or <Link to='/home'>Go Home</Link>
				</div>
			</div>
		)
	}
}

export default PageNotFound
