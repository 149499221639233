import React, { useState } from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Input, Upload, message, Form } from 'antd'
import { AeroModal } from '../../../../AeroComponents'
import { enquireForOrder } from '../../../../Stores/Projects/action'

import styles from './modal.module.scss'


const { TextArea } = Input


function OrderEnquiryModalForm(props) {
	// extract props
	const {
		email,
		mobileNumber,
		enquiryModal,
		closeEnquiryModel,
		enquireForOrder, // from action

	} = props

	/* state */
	const [fileList, setFileList] = useState([]) // filelist to be uploaded

	/* constants */
	const [form] = Form.useForm();
	const MAX_FILE_LIMIT = 5


	const uploadProps = {
		// for upload  component
		onRemove: file => {
			let newFileList = fileList.filter((item) => item.name !== file.name);
			setFileList([...newFileList])
		},
		beforeUpload: (file, fileLists) => {
			const isJpgOrPngOrPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
			if (!isJpgOrPngOrPdf) {
				message.error('You can only upload JPG/PNG/PDF file!')
				return false
			}
			const isLt2M = file.size / 1024 / 1024 < 10
			if (!isLt2M) {
				message.error('File must be smaller than 10 MB!')
				return false
			}
			if (fileList.length + fileLists.length > MAX_FILE_LIMIT) {
				message.error('Maximun upload limit reached!')
				return
			}

			setFileList([...fileList, ...fileLists])
			return false
		},

		fileList,
	}



	const onFinish = (values) => {
		let body = new FormData()
		if (fileList.length !== 0) {
			fileList.forEach(file => {
				body.append('attachmentFile', file)
			})

		}
		body.append('orderSummary', values.orderSummary)
		body.append('orderDescription', values.orderDescription)
		body.append('email', values.email)
		body.append('contactNo', values.contactNo)
		closeEnquiryModel()
		form.resetFields()
		setFileList([])
		enquireForOrder(body)
	}

	const onFinishFailed = (val) => {
		message.error("Please fill all the required fields.")
	}
	const getOrderEnquiryFields = () => {
		return [

			{
				label: "Order Summary",
				name: "orderSummary",
				rules: [{ required: true, whitespace: true, message: 'Please enter the order summary!' }],
				fieldToRender: <Input showCount placeholder={'Type here'} maxLength={250} />
			},
			{
				label: "Order Description",
				name: "orderDescription",
				rules: [{ required: true, whitespace: true, message: 'Please enter the order description!' }],
				fieldToRender: <TextArea showCount placeholder={'Type here'} maxLength={5000} />
			},
			{
				label: "Email ID",
				name: "email",
				rules: [{ required: true, whitespace: true, message: 'Please enter the email!' }],
				fieldToRender: <Input placeholder={'Type here'} maxLength={100} />,
				initialValue: email
			},
			{
				label: "Contact No.",
				name: "contactNo",
				rules: [{ required: true, whitespace: true, message: 'Please enter the contact number' }],
				fieldToRender: <Input placeholder={'Type here'} maxLength={15} />,
				initialValue: mobileNumber
			},
			{
				label: "Upload Attachment (jpeg, png or pdf)",
				name: "uploads",
				fieldToRender: <Upload {...uploadProps} multiple  >
					<Button className={styles['upload-button-flex']}>Select file</Button>
				</Upload>
			},

		]

	}

	return (
		<AeroModal footer={false} onCancel={closeEnquiryModel}
			visible={enquiryModal}
			title={"Enquire for a New Order"}>
			<div className={styles['modal-wrapper']}>
				<div>

					{/* Form to get details about order enquiry */}
					<Form className={styles['body']} layout='vertical'

						encType='multipart/form-data'
						initialValues={{
							email: email,
							contactNo: mobileNumber
						}}
						form={form}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
					>

						{getOrderEnquiryFields().map(item => {
							return <Form.Item label={item.label}
								rules={item.rules}
								name={item.name}
							>
								{item.fieldToRender}
							</Form.Item>
						})}
						<div className={styles['body']}>

							<Button className={styles['upload-button-flex']} type='primary' htmlType='submit'>
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</AeroModal>
	)
}


const mapDispatchToProps = dispatch => {
	return {
		enquireForOrder: bindActionCreators(enquireForOrder, dispatch),
	}
}



export default connect(
	null,
	mapDispatchToProps,
)(OrderEnquiryModalForm)
