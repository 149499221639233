/*eslint-disable*/
import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Input, Radio } from 'antd'

const ID_OPTIONS = [{ value: 'Passport', displayValue: 'Passport' }, { value: 'DL', displayValue: 'Driving License' }, { value: 'Medicare', displayValue: 'Medicare' }]

class IdDetailsForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			idTypes: ID_OPTIONS,
			layout: props.viewMode ? 'inline' : 'vertical',
			label: '',
		}
	}

	componentDidMount() {
		if (this.props.viewMode) {
			if (this.props.data && this.props.data.identification) {
				this.props.form.setFieldsValue({
					idType: this.props.data.identification.type,
					idNumber: this.props.data.identification.number,
				})
				let label = null
				if (this.props.data.identification.type === 'DL') {
					label = 'Driving License'
				} else if (this.props.data.identification.type === 'Passport') {
					label = 'Passport'
				} else if (this.props.data.identification.type === 'Medicare') {
					label = 'Medicare'
				}
				this.setState({
					label,
				})
			}
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const data = {
					identification: {
						number: values.idNumber,
						type: values.idType,
					},
				}
				this.props.updateIdDetails(data)
			}
		})
	}

	render() {
		const { getFieldValue, getFieldDecorator } = this.props.form
		return (
			<Form layout={this.state.layout} onSubmit={this.handleSubmit}>
				{this.props.viewMode ? null : (
					<Form.Item label={'Select ID Type'}>
						{getFieldDecorator('idType', {
							initialValue: this.state.idTypes[0].value,
							rules: [],
						})(
							<Radio.Group buttonStyle='solid'>
								{this.state.idTypes.map((type, index) => (
									<Radio.Button value={type.value} key={index}>
										{type.displayValue}
									</Radio.Button>
								))}
							</Radio.Group>,
						)}
					</Form.Item>
				)}
				<Form.Item label={this.props.viewMode ? this.state.label : getFieldValue('idType')}>
					{getFieldDecorator('idNumber', {
						rules: [
							{
								required: true,
								whitespace: true,
								message: 'Please input ID Number',
							},
						],
					})(<Input disabled={this.props.viewMode} />)}
				</Form.Item>
				{this.props.viewMode ? null : (
					<Button block type='primary' htmlType='submit'>
						Add
					</Button>
				)}
			</Form>
		)
	}
}

const IdDetails = Form.create({ name: 'ID_DETAILS_FORM' })(IdDetailsForm)

export default IdDetails
