import { Button, Input, Segmented } from 'antd'
import React from 'react'

const WriteToUsDrawerContent = props => {
	const { storeSupportQuery, storeUseType, submitIssueFaced } = props
	return (
		<>
			<div style={{ padding: '10px' }}>
				<p style={{ fontSize: '20px' }}>
					<strong>Tell us about issues you faced with the Trial Plan</strong>
				</p>
				<p style={{ fontSize: '16px' }}>
					Aerologix will review your request and grant you an extension only in the case of a legitimate reason. You will be notified on your request over registered
					email
				</p>
				<p>
					<div style={{ marginBottom: '10px' }}>Type Issue</div>
					<Input style={{ borderRadius: '8px' }} onBlur={storeSupportQuery} />
				</p>
				<p>
					<div style={{ marginBottom: '10px' }}>What are you using AeroPath for?</div>
					<Segmented onChange={storeUseType} block options={['Personal', 'Commercial']} />
				</p>
				<p>
					<Button onClick={submitIssueFaced} style={{ borderRadius: '8px', width: '100%', height: '50px' }} type='primary'>
						Submit
					</Button>
				</p>
			</div>
		</>
	)
}

export default WriteToUsDrawerContent
