import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share'
import './refer.sass'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Card, Button, Input, Divider, Row, Col, message } from 'antd'
import { sendReferralInvite, getProfile } from '../../Stores/UserSession/action'
import { validateEmailAddress } from '../../Services/UtilityService'

class ReferForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			sharableLink: '',
			profileData: '',
			showReferral: false,
		}
	}

	componentDidMount() {
		this.props.getProfile()
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.profileData !== nextProps.profileData) {
			const baseUrl = window.location.origin
			const referralCode = nextProps.profileData.referralCode
			const sharableLink = baseUrl + '/share-referral/referralCode/' + referralCode
			this.setState({
				profileData: nextProps.profileData,
				sharableLink: sharableLink,
				showReferral: referralCode ? true : false,
			})
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const body = {
					refereeEmail: values.inviteEmail,
					referralURL: this.state.sharableLink,
				}
				this.props.sendReferralInvite(body)
			}
		})
	}

	copyLink = () => {
		const referralLink = this.state.sharableLink
		navigator.clipboard.writeText(referralLink)
		message.success('Link copied to clipboard!')
	}

	render() {
		const { sharableLink, showReferral } = this.state
		const { getFieldDecorator } = this.props.form
		return (
			<div className='flex-container'>
				<div style={{ position: 'relative', right: '435px' }}>
					<p style={{ fontSize: '32px', lineHeight: '42px', fontStyle: 'SemiBold', float: 'left' }}>Refer and Earn</p>
				</div>
				<React.Fragment>
					<div className='refer-card-top'>
						<div className='card-head-content'>
							<h1 style={{ color: 'white', position: 'relative', top: '115px', right: '321px' }}>Invite Friends and</h1>
							<h1 style={{ color: 'white', position: 'relative', top: '95px', right: '285px' }}> Earn Amazing Rewards</h1>

							<p style={{ color: 'white', position: 'relative', top: '88px', right: '193px' }}>
								Both you and your referee gets rewarded for every successful referral!
							</p>
						</div>
					</div>
					<Card className='refer-card-bottom card'>
						<div className='card-body-content'>
							{showReferral ? (
								<Form layout='vertical' onSubmit={this.handleSubmit}>
									<Form.Item label='Your Unique Referral Link'>
										<Input
											size='large'
											value={sharableLink}
											disabled
											suffix={
												<Button onClick={this.copyLink} type='link'>
													COPY LINK
												</Button>
											}
										/>
									</Form.Item>
									<Row gutter={24}>
										<Col span={12} className='same-line'>
											<Form.Item label='Invite By Email'>
												{getFieldDecorator('inviteEmail', {
													rules: [
														{ required: true, message: "Please input your friend's email!", whitespace: true },
														{ validator: validateEmailAddress },
													],
												})(<Input type='email' size='large' placeholder="Enter Your Friend's Email" />)}
											</Form.Item>
										</Col>
										<Col span={12} className='same-line'>
											<Divider style={{ height: '150px' }} type='vertical'>
												<div style={{ width: '200px' }}>
													<div className='share-line-top'>Invite By Social Media</div>
													<div className='share-line'>
														<FacebookShareButton url={sharableLink}>
															<FacebookIcon size={35} round={true} />
															&nbsp; Share On Facebook
														</FacebookShareButton>
													</div>
													<div className='share-line'>
														<TwitterShareButton url={sharableLink}>
															<TwitterIcon size={35} round={true} />
															&nbsp; Share On Twittter
														</TwitterShareButton>
													</div>
												</div>
											</Divider>
										</Col>
									</Row>
									<Button style={{ width: '150px' }} type='primary' htmlType='submit'>
										Send Invite
									</Button>
								</Form>
							) : (
								<div>
									<h3>Please complete your profile and payment details to access your referral code and earn rewards!</h3>
								</div>
							)}
						</div>
					</Card>
					<div style={{ fontSize: '14px' }}>
						<span style={{ color: '#8C8C8C' }}>Referral</span>{' '}
						<Button type='link' href='https://aerologix.com/referral-terms' rel='noopener noreferrer' target='_blank'>
							<u>Terms & Conditions</u>
						</Button>
					</div>
				</React.Fragment>
			</div>
		)
	}
}

const Refer = Form.create({ name: 'REFER_FORM' })(ReferForm)

const mapStateToProps = state => {
	return {
		profileData: state.UserSessionReducer.profileData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		sendReferralInvite: bindActionCreators(sendReferralInvite, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Refer)
