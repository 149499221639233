import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import AeroModal from '../../AeroComponents/Modal'
import { setValueToStorage } from '../../Services/LocalStorageService'
import { goTo } from '../../Services/NavigationService'
import { recoverPilotAccount } from '../../Stores/UserSession/action'

const UserDeletionModal = props => {
	const { recoverySuccess } = props
	const [visible1, setVisible1] = useState(true)
	const [visible2, setVisible2] = useState(false)

	useEffect(() => {
		setValueToStorage('TEMP_TOKEN', props.location.state.token)
	}, [])

	const closeAndOpen = () => {
		setVisible1(false)
		setVisible2(true)
	}

	const recoverAccount = () => {
		props.authState.authenticate()

		const propsData = props.location.state

		setValueToStorage('TOKEN', propsData.token)
		const webConfig = propsData.webConfig
		webConfig.pilotModules = propsData.webConfig.pilotModules
		webConfig.consumerModules = propsData.webConfig.consumerModules
		setValueToStorage('WEB_CONFIG_DATA', JSON.stringify(propsData.configData))
		if (propsData.subrole === 'CLIENT_ENTERPRISE' || propsData.subrole === 'CLIENT_COLLABORATOR') {
			setValueToStorage('SUBROLE', propsData.subrole)
			if (propsData.permissions.length !== 0) {
				setValueToStorage('PERMISSIONS', propsData.permissions)
			}
		}
		window.location.replace(window.location.origin)
	}

	useEffect(() => {
		if (recoverySuccess) {
			closeAndOpen()
		}
	}, [recoverySuccess])

	return (
		<>
			<AeroModal visible={visible1} footer={false} onCancel={() => goTo('login')}>
				<h2>This account is under the process of deletion</h2>
				<p>Logging in back will cancel this process. Are you sure you DO NOT want to delete your account?</p>
				<div style={{ padding: '15px', textAlign: 'center' }}>
					<Button onClick={() => props.recoverPilotAccount()} type='primary'>
						Recover Account
					</Button>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<Button onClick={() => goTo('login')}>Cancel</Button>
				</div>
			</AeroModal>
			<AeroModal visible={visible2} footer={false} onCancel={() => recoverAccount()}>
				<div className='modal-content'>
					<MatImage src={IMAGES_SRC.HAPPY_FACE} />
					<h1>We're happy to see you back</h1>
					<div className='line-2'>We’ll take your feedback to improve as much as we can. Thank you for trusting us!</div>
					<div className='line-3'>
						<Button type='primary' onClick={() => recoverAccount()}>
							Okay
						</Button>
					</div>
				</div>
			</AeroModal>
		</>
	)
}

const mapStateToProps = state => {
	return {
		authState: state.UserSessionReducer.authState,
		recoverySuccess: state.UserSessionReducer.recoverySuccess,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		recoverPilotAccount: bindActionCreators(recoverPilotAccount, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(UserDeletionModal)
