import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { AeroCard, AeroSearch, AeroTable } from '../../../../AeroComponents'
import { Descriptions, Radio } from 'antd'
import { getAllProjectsForOrder } from '../../../../Stores/Projects/action'
import styles from './orderDetail.module.scss'

function OrderDetailCard(props) {
	// extract props
	const {
		projectsData,
		orderData, // from state
		getAllProjectsForOrder, // from dispatch
		orderId,
		dataCols,
		updateDataTab,
		updateDataTabDone, // from parent  (downloadProjectData, openApproveModal, openDisapproveModal are used in table column)
		defaultTab,
	} = props
	/* state */

	const [selection, setSelection] = useState(defaultTab ? defaultTab : 'details')
	const [totalPage, setTotalPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1)
	const [projectsDataList, setProjectsDataList] = useState([])
	const [orderDataList, setorderDataList] = useState(orderData)

	/* Effects */
	useEffect(() => {
		if (projectsData) {
			setProjectsDataList(projectsData.data)
			setTotalPage(projectsData.count)
		}
	}, [projectsData])

	useEffect(() => {
		setorderDataList(orderData)
	}, [orderData])

	useEffect(() => {
		if (updateDataTab) {
			getAllProjectsForOrder({ orderId: orderId, pageNumber: currentPage })
			updateDataTabDone()
		}
	}, [updateDataTab])

	/* Constants */

	// when radio group changes
	const handleSelectionChange = e => {
		if (e.target.value === 'data') {
			getAllProjectsForOrder({ orderId: orderId })
		}
		setSelection(e.target.value)
	}

	// search for projects to show in table
	const searchProjects = value => {
		if (value) {
			getAllProjectsForOrder({ searchToken: value, orderId: orderId })
		} else {
			getAllProjectsForOrder({ orderId: orderId })
		}
	}

	//for pagination
	const changePage = page => {
		getAllProjectsForOrder({ orderId: orderId, pageNumber: page })
		setCurrentPage(page)
	}

	if (orderDataList && orderDataList.name) {
		return (
			<AeroCard className={styles['order-details-card']} title={orderDataList.name}>
				<Radio.Group onChange={handleSelectionChange} defaultValue={defaultTab ? defaultTab : 'details'} buttonStyle='solid'>
					<Radio.Button className={`${styles['radio-button']} ${styles['left']}`} value='details'>
						Details
					</Radio.Button>
					<Radio.Button className={`${styles['radio-button']} ${styles['right']}`} value='data'>
						Data
					</Radio.Button>
				</Radio.Group>
				<div className={styles['body']}>
					{selection === 'details' ? (
						<React.Fragment>
							<Descriptions>
								<Descriptions.Item label={<strong>Description</strong>}>{orderDataList.description}</Descriptions.Item>
							</Descriptions>
							<Descriptions>
								<Descriptions.Item label={<strong>Account Manager</strong>}>
									{orderDataList.accountManager?.firstName + ' ' + orderDataList.accountManager?.lastName}
								</Descriptions.Item>
								<Descriptions.Item label={<strong>Contact Number</strong>}>{orderDataList.accountManager?.mobileNumber}</Descriptions.Item>
								<Descriptions.Item label={<strong>Last Modified On</strong>}>
									{orderDataList.lastUpdatedByName + ', ' + moment(orderDataList.lastModifiedAt).format('DD MMMM YYYY')}
								</Descriptions.Item>
							</Descriptions>
						</React.Fragment>
					) : selection === 'data' ? (
						<React.Fragment>
							<AeroSearch className={styles['search-box']} placeholder='Search Project Name or Project Type' onSearch={searchProjects} />
							{projectsDataList && projectsDataList.length ? (
								<AeroTable
									pagination={{
										showQuickJumper: totalPage > 25,
										total: totalPage,
										defaultCurrent: 1,
										onChange: changePage,
									}}
									columns={dataCols}
									dataSource={projectsDataList}
									className={styles['body']}
								></AeroTable>
							) : (
								''
							)}
						</React.Fragment>
					) : null}
				</div>
			</AeroCard>
		)
	} else {
		return ''
	}
}

const mapStateToProps = state => {
	return {
		projectsData: state.ProjectsReducer.projectsData, // all projects data
		orderData: state.ProjectsReducer.orderData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAllProjectsForOrder: bindActionCreators(getAllProjectsForOrder, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(OrderDetailCard)
