import { PlusCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import DroneDetails from '../drone-details'

const DroneLicenseTab = props => {
	const { profileData, openMadalToAddDetails, deleteDrone, droneTypesData, completeProfile } = props
	return (
		<>
			<div style={{ fontSize: '16px', color: '#47464A' }}>Drone Details</div>
			<div style={{ color: '#84818A', marginTop: '10px' }}>Jobs will be allocated to you depending on the capabilities of your drone</div>
			<div style={{ marginTop: '20px' }}>
				{profileData ? (
					profileData['droneDetails']?.length !== 0 ? (
						<>
							<Button
								style={{ height: '48px', marginBottom: '20px' }}
								type='primary'
								onClick={() => {
									openMadalToAddDetails('DRONE_DETAILS')
								}}
							>
								<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} />
								Add Drone Details
							</Button>
							<DroneDetails deleteDrone={deleteDrone} viewMode data={profileData} droneTypesData={droneTypesData} updateDroneDetails={completeProfile} />
						</>
					) : (
						<DroneDetails deleteDrone={deleteDrone} data={profileData} droneTypesData={droneTypesData} updateDroneDetails={completeProfile} />
					)
				) : null}
			</div>
		</>
	)
}

export default DroneLicenseTab
