import React, { Component } from 'react'
import { DeleteOutlined, DownloadOutlined, InfoCircleFilled, MessageFilled, PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Table, Modal, Divider, message, Alert, Card, Popover, Input, Form, Skeleton, List, Avatar, Image, Descriptions, Spin, Badge, Upload } from 'antd'
import './projects.sass'
import ProjectDescription from './Components/project-description'
import { enterpriseProjectStatusDetails, projectStatusDetails, projectStatusDetailsColumns } from './tableColumns'
import { getValueFromStorage, setValueToStorage } from '../../Services/LocalStorageService'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import PageVisibility from 'react-page-visibility'
import {
	getQuotes,
	createTransaction,
	payment,
	updateQuote,
	getDownloadUrl,
	getPreviewUrl,
	markInFlight,
	markReadyToProcess,
	markComplete,
	markResolved,
	conflictResolutionPreview,
	conflictResolutionFinal,
	getProjectForConsumer,
	getProjectForPilot,
	checkUploadedPreview,
	checkUploadedData,
	getPaymentHistory,
	getProjectTimeline,
	getBoxCreds,
	downloadBoxFolder,
	invitePilots,
	getCommercialCommision,
	getBidHistory,
	modifyBid,
	cancelProject,
	chatWindowConsumerStatus,
	chatWindowPilotStatus,
	uploadFlightLogFile,
	deleteFlightLogFile,
} from '../../Stores/Projects/action'
import { getPilotPortfolio, getChatAccessToken, getWebConfig } from '../../Stores/UserSession/action'
import { connect } from 'react-redux'
import PaymentHistoryCard from './Components/payment-history'
import MatImage from '../../AeroComponents/Image'
import { IMAGES_SRC } from '../../AeroComponents/Image/image.constants'
import StripePayment from '../Payment/Components/stripe-payment'
import AeroModal from '../../AeroComponents/Modal'
import ProjectStatusSteps from './Components/project-status-steps'
import MultiPartUpload from './Components/MultiPartUpload/multipartupload.js'
import S3Uploader from './Components/s3-uploader'
import S3Downloader from './Components/s3-downloader'
import BoxUploader from './Components/box-uploader'
import BoxDownloader from './Components/box-downloader'
import { goTo } from '../../Services/NavigationService'
import BidCard from './Components/bid-card'
import InfiniteScroll from 'react-infinite-scroll-component'
import { capitalize, debounce } from '../../Services/UtilityService'
import ReactPlayer from 'react-player'
import ChatBox from './Components/ChatBox'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { CONSUMER_ALERT_TEXT, INITIALIZE_CHAT_ERROR, PILOT_ALERT_TEXT } from './projects.constants'
class Projects extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {},
			projectId: props.match.params.projectId,
			profileType: getValueFromStorage('PROFILE_TYPE'),
			quotesData: [],
			quoteId: '',
			pilotData: null,
			visible: false,
			modalVisible: false,
			addPayment: false,
			projectAllocate: false,
			transactionData: {},
			uploadUrlData: {},
			downloadUrlData: '',
			previewUrlData: '',
			checkPreviewData: '',
			checkUploadData: '',
			guestData: {},
			paymentHistoryData: [],
			projectTimelineData: [],
			knowMoreVisible: false,
			internalActionModal: false,
			mpuModalOpenStatus: false,
			mpuType: '',
			multiPartUploads: props.multiPartUploads,
			currentInvitedPage: 1,
			totalInvited: 0,
			withAction: false,
			boxCreds: {},
			checkPreviewDataFolderName: 'Preview',
			checkUploadDataFolderName: 'Data',
			commercialCommisionData: null,
			loading: false,
			rejectBidModal: false,
			pilotProfileModal: false,
			photos: [],
			videos: [],
			bidHistoryData: null,
			bidHistoryModal: false,
			choiceOfAction: null,
			chatLoaded: false,
			chatToken: null,
			showChat: false,
			selectedChatId: null,
			participantName: null,
			selectedChatBid: null,
			participantProfilePicture: null,
			cancelProjectModal: false,
			acceptBidModal: false,
			modifyBtn: false,
			disableSendMessage: false,
			feeContentModal: false,
			withdrawBidModal: false,
			startTime: moment(),
			selectedQuote: null,
			configData: props.webConfig,
			flightLogDeleteConfModal: false,
		}
		if (getValueFromStorage('localStore/0/bce.defaultViewMode')) {
			setValueToStorage('localStore/0/bce.defaultViewMode', '"grid"')
		}
		this.conversationsRef = React.createRef()
		this.bidAmountRef = React.createRef()
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.consumerProjectData !== nextProps.consumerProjectData) {
			if (nextProps.consumerProjectData) {
				this.setState({ data: nextProps.consumerProjectData })
				if (nextProps.consumerProjectData.state !== 'ALLOCATE_PILOT') {
					this.props.getPaymentHistory({ projectId: this.state.projectId })
				}
				if (nextProps.consumerProjectData.state === 'COMPLETED' || nextProps.consumerProjectData.state === 'RESOLVED') {
					if (nextProps.consumerProjectData.isBoxSupported) {
						this.props.getBoxCreds({ data: this.state.projectId })
					} else {
						this.props.getPreviewUrl({ type: 'preview', project: this.state.projectId })
						this.props.getDownloadUrl({ type: 'upload', project: this.state.projectId })
					}
				}
			}
		}
		if (this.props.pilotProjectData !== nextProps.pilotProjectData) {
			if (nextProps.pilotProjectData) {
				if (
					nextProps.pilotProjectData.state !== 'ALLOCATE_PILOT' &&
					nextProps.pilotProjectData.state !== 'ALLOTTED' &&
					nextProps.pilotProjectData.state !== 'ALLOCATE_PILOT' &&
					(nextProps.pilotProjectData?.quote?.status === 'ACCEPTED' || nextProps.pilotProjectData?.quote?.status === 'COMPLETED')
				) {
					if (nextProps.pilotProjectData.isBoxSupported) {
						this.props.getBoxCreds({ data: this.state.projectId })
					} else {
						this.props.checkUploadedPreview({ type: 'preview', project: this.state.projectId })
						this.props.checkUploadedData({ type: 'upload', project: this.state.projectId })
					}
				}
				this.setState(
					{
						data: nextProps.pilotProjectData,
						quotesData: [{ consumer: nextProps.pilotProjectData.consumer, quote_id: nextProps.pilotProjectData.quotation_id }],
						totalInvited: 1,
					},
					() => {
						if (nextProps.pilotProjectData?.quote?.twilio_chatid) this.getUnreadMessages()
					},
				)
			}
		}
		if (this.props.quotesData !== nextProps.quotesData) {
			if (nextProps.quotesData) {
				const { quotesData, currentInvitedPage } = this.state
				const prevValue = quotesData
				if (prevValue.length && currentInvitedPage === 1) {
					this.setState({ quotesData: nextProps.quotesData.data, totalInvited: nextProps.quotesData.count, loading: false }, () => {
						this.getUnreadMessages(nextProps.quotesData.data, [])
					})
				} else {
					this.setState({ quotesData: [...prevValue, ...nextProps.quotesData.data], totalInvited: nextProps.quotesData.count, loading: false }, () => {
						this.getUnreadMessages(nextProps.quotesData.data, prevValue)
					})
				}
			}
		}
		if (this.props.updateQuoteSuccess !== nextProps.updateQuoteSuccess) {
			if (nextProps.updateQuoteSuccess) {
				this.handleCancel(this.state.rejectBidModal ? 'rejectBidModal' : 'acceptBidModal')
				if (this.state.profileType === 'CONSUMER') {
					this.props.getProjectForConsumer({ consumer: this.state.projectId })
				} else {
					this.props.getProjectForPilot({ pilot: this.state.projectId })
				}
				if (!this.state.data.enterprise_project) {
					this.props.getQuotes({ project_id: this.state.projectId, pageNumber: this.state.currentInvitedPage, pageSize: 5 })
				}
			}
		}
		if (this.props.uploadUrlData !== nextProps.uploadUrlData) {
			if (nextProps.uploadUrlData) {
				this.setState({ uploadUrlData: nextProps.uploadUrlData })
			}
		}
		if (this.props.downloadUrlData !== nextProps.downloadUrlData) {
			if (nextProps.downloadUrlData) {
				this.setState({ downloadUrlData: nextProps.downloadUrlData })
			}
		}
		if (this.props.previewUrlData !== nextProps.previewUrlData) {
			if (nextProps.previewUrlData) {
				this.setState({ previewUrlData: nextProps.previewUrlData })
			}
		}
		if (this.props.boxCreds !== nextProps.boxCreds) {
			if (nextProps.boxCreds) {
				this.setState({ boxCreds: nextProps.boxCreds })
			}
		}
		if (this.props.createTransactionData !== nextProps.createTransactionData) {
			if (nextProps.createTransactionData) {
				this.setState({
					transactionData: nextProps.createTransactionData,
				})
			}
		}
		if (this.props.paymentSuccess !== nextProps.paymentSuccess) {
			if (nextProps.paymentSuccess) {
				this.setState({
					addPayment: false,
					projectAllocate: true,
					boxCreds: null,
				})
			}
		}
		if (this.props.markInFlightSuccess !== nextProps.markInFlightSuccess) {
			if (nextProps.markInFlightSuccess) {
				this.props.getProjectForPilot({ pilot: this.state.projectId }, { createChatToken: true })
			}
		}
		if (this.props.markReadyToProcessSuccess !== nextProps.markReadyToProcessSuccess) {
			if (nextProps.markReadyToProcessSuccess) {
				this.props.getProjectForPilot({ pilot: this.state.projectId }, { createChatToken: true })
			}
		}
		if (this.props.markCompleteSuccess !== nextProps.markCompleteSuccess) {
			if (nextProps.markCompleteSuccess) {
				this.props.getProjectForConsumer({ consumer: this.state.projectId }, { createChatToken: true })
			}
		}
		if (this.props.markResolvedSuccess !== nextProps.markResolvedSuccess) {
			if (nextProps.markResolvedSuccess) {
				this.props.getProjectForConsumer({ consumer: this.state.projectId }, { createChatToken: true })
			}
		}
		if (this.props.conflictResolutionPreviewSuccess !== nextProps.conflictResolutionPreviewSuccess) {
			if (nextProps.conflictResolutionPreviewSuccess) {
				this.props.getProjectForPilot({ pilot: this.state.projectId }, { createChatToken: true })
			}
		}
		if (this.props.conflictResolutionFinalSuccess !== nextProps.conflictResolutionFinalSuccess) {
			if (nextProps.conflictResolutionFinalSuccess) {
				this.props.getProjectForPilot({ pilot: this.state.projectId }, { createChatToken: true })
			}
		}
		if (this.props.checkPreviewData !== nextProps.checkPreviewData) {
			if (nextProps.checkPreviewData) {
				this.setState({
					checkPreviewData: nextProps.checkPreviewData,
				})
			}
		}
		if (this.props.checkUploadData !== nextProps.checkUploadData) {
			if (nextProps.checkUploadData) {
				this.setState({
					checkUploadData: nextProps.checkUploadData,
				})
			}
		}
		if (this.props.paymentHistoryData !== nextProps.paymentHistoryData) {
			if (nextProps.paymentHistoryData) {
				this.setState({
					paymentHistoryData: nextProps.paymentHistoryData,
				})
			}
		}
		if (this.props.projectTimelineData !== nextProps.projectTimelineData) {
			if (nextProps.projectTimelineData) {
				this.setState({
					projectTimelineData: nextProps.projectTimelineData,
				})
			}
		}
		if (this.props.multiPartUploads !== nextProps.multiPartUploads) {
			if (nextProps.multiPartUploads) {
				this.setState({
					multiPartUploads: nextProps.multiPartUploads,
				})
			}
		}
		if (this.props.downloadBoxFolderUrl !== nextProps.downloadBoxFolderUrl) {
			if (nextProps.downloadBoxFolderUrl) {
				message.success('Your download will begin now!')
				window.location.replace(nextProps.downloadBoxFolderUrl)
			}
		}
		if (this.props.invitePilotsSuccess !== nextProps.invitePilotsSuccess) {
			if (nextProps.invitePilotsSuccess) {
				message.success('Your bid has been submitted!')
				this.handleCancel('internalActionModal')
				this.props.getProjectForPilot({ pilot: this.state.projectId })
			}
		}
		if (this.props.commercialCommisionData !== nextProps.commercialCommisionData) {
			if (nextProps.commercialCommisionData) {
				this.setState({ commercialCommisionData: nextProps.commercialCommisionData, internalActionModal: true })
			}
		}
		if (this.props.pilotPortfolioData !== nextProps.pilotPortfolioData) {
			if (nextProps.pilotPortfolioData) {
				this.setState({
					photos: nextProps.pilotPortfolioData.photos,
					videos: nextProps.pilotPortfolioData.videos,
					pilotProfileModal: true,
				})
			}
		}
		if (this.props.bidHistoryData !== nextProps.bidHistoryData) {
			if (nextProps.bidHistoryData) {
				this.setState({ bidHistoryData: nextProps.bidHistoryData, bidHistoryModal: true })
			}
		}
		if (this.props.modifyBidSuccess !== nextProps.modifyBidSuccess) {
			if (nextProps.modifyBidSuccess) {
				if (nextProps.modifyBidSuccess.status === 'WITHDRAWN' || nextProps.modifyBidSuccess.status === 'RESOLVED') {
					this.setState({ disableSendMessage: true })
				}
				this.handleCancel('internalActionModal')
				this.handleCancel('withdrawBidModal')
				this.handleCancel('internalActionModal')
				message.success('Bid Updated!')
				this.props.getProjectForPilot({ pilot: this.state.projectId })
			}
		}

		if (this.props.chatAccessToken !== nextProps.chatAccessToken) {
			if (nextProps.chatAccessToken) {
				this.props.createChatClient(nextProps.chatAccessToken, this.chatInitialisationCompleted, this.chatInitialisationFailed)
			}
		}

		if (this.props.cancelProjectSuccess !== nextProps.cancelProjectSuccess) {
			if (nextProps.cancelProjectSuccess) {
				this.handleCancel('cancelProjectModal')
				goTo('home')
			}
		}
		if (this.props.webConfig !== nextProps.webConfig) {
			this.setState({
				configData: nextProps.webConfig,
			})
		}
		if (this.props.flightLogFileDeleted !== nextProps.flightLogFileDeleted) {
			if (nextProps.flightLogFileDeleted) {
				this.setState({
					flightLogDeleteConfModal: false,
				})
			}
		}
	}

	componentDidMount() {
		if (!this.state.configData) {
			this.props.getWebConfig()
		}
		this.conversationsRef.current = {}
		if (this.props.chatClientRef.current === null) {
			this.props.getChatAccessToken()
		} else {
			if (this.props.chatClientRef.current.connectionState === 'connected') {
				this.chatInitialisationCompleted()
			} else {
				this.props.getChatAccessToken()
			}
		}
		this.props.getProjectTimeline({ projectId: this.state.projectId })

		if (this.state.profileType === 'CONSUMER') {
			this.props.getQuotes({ project_id: this.state.projectId, pageNumber: this.state.currentInvitedPage, pageSize: 5 })
			this.props.getProjectForConsumer(
				{ consumer: this.state.projectId },
				{ createChatToken: true },
				{ mixpanel_type: 'project_details_viewed', totalBids: this.props.quotesData?.count },
			)
		} else {
			this.props.getProjectForPilot({ pilot: this.state.projectId }, { createChatToken: true }, { mixpanel_type: 'project_details_viewed' })
		}
		window.sessionStorage.setItem('projectId', this.state.projectId)
	}

	componentWillUnmount() {
		for (let conversation in this.conversationsRef.current) {
			if (this.conversationsRef.current[conversation]?._events?.messageAdded.length > 1) {
				for (let i = 1; i < this.conversationsRef.current[conversation]?._events?.messageAdded.length; i++) {
					this.conversationsRef.current[conversation]._events.messageAdded.pop() // manually remove the event listener as remove listerner is not working properly
				}
			}
		}
	}

	openChat = (selectedChatId, participantName, selectedChatBid, participantProfilePicture, disableSendMessage, selectedQuote) => {
		this.setState({
			selectedChatId: selectedChatId,
			participantName: participantName,
			selectedChatBid: selectedChatBid,
			participantProfilePicture: participantProfilePicture,
			disableSendMessage: disableSendMessage,
			selectedQuote: selectedQuote,
		})
		const selectedQuoteId = selectedQuote.quote_id
		if (selectedChatId) {
			this.setState({ showChat: true })
		}

		if (this.state.profileType === 'CONSUMER') {
			let quotes = this.state.quotesData
			quotes = quotes.map(quote => {
				if (quote.quote_id === selectedQuoteId) {
					// reset unread messages count if quote id is the opened chat id
					quote.unreadMessagesCount = 0
				}
				return quote
			})
			this.setState({ quotesData: quotes })
			this.props.chatWindowConsumerStatus({ open: true, quotationId: selectedQuote.quote_id })
		} else {
			let quote = this.state.data?.quote
			let data = Object.assign({}, this.state.data)
			if (quote.quote_id === selectedQuote.id) {
				// reset unread messages count if quote id is the opened chat id
				quote.unreadMessagesCount = 0
			}
			this.setState({ data: data })
			this.props.chatWindowPilotStatus({ open: true, quotationId: selectedQuote.id })
		}
	}

	chatInitialisationCompleted = () => {
		this.setState({ chatLoaded: true })
	}

	chatInitialisationFailed = reason => {
		this.setState({ chatLoaded: false })
		return message.error(INITIALIZE_CHAT_ERROR)
	}

	closeChat = () => {
		this.setState({ showChat: false, selectedChatId: null, participantName: null, selectedChatBid: null, participantProfilePicture: null })
		if (this.state.profileType === 'PILOT') {
			this.props.chatWindowPilotStatus({ open: false, quotationId: this.state.selectedQuote.id })
		} else {
			this.props.chatWindowConsumerStatus({ open: false, quotationId: this.state.selectedQuote.quote_id })
		}
	}

	recieveMessage = message => {
		if (!message.author?.includes(this.state.profileType) && this.state.selectedChatId !== message.conversation.sid) {
			// check if author is same profile type as reciever and message recieved is for the opened chat
			if (this.state.profileType === 'CONSUMER') {
				// if customer
				let quotes = this.state.quotesData
				if (quotes.length !== 0) {
					let newQuotes = quotes.map(quote => {
						// loop all the quotes
						if (quote.twilio_chatid === message.conversation.sid) {
							// check if the recieved message's chat id is same as the quote's chat id
							if (quote.unreadMessagesCount === null) {
								quote.unreadMessagesCount = 0
							}
							quote.unreadMessagesCount += 1
						}
						return quote
					})
					this.setState({ quotesData: newQuotes }) // set the updated quotes with unread messages count
				}
			} else {
				let quote = this.state.data?.quote
				let data = Object.assign({}, this.state.data)
				if (quote?.twilio_chatid === message?.conversation.sid) {
					// check if the recieved message's chat id is same as the quote's chat id
					if (quote.unreadMessagesCount === null) {
						quote.unreadMessagesCount = 0
					}
					quote.unreadMessagesCount += 1
					this.setState({ data: data }) // set the updated quotes with unread messages count
				}
			}
		}
	}

	getUnreadMessages = async (quotes, oldQuotes) => {
		// new quotes from api for processing and old quotes already there for concating
		if (this.state.profileType === 'CONSUMER') {
			if (quotes?.length !== 0) {
				let newQuotes = await Promise.all(
					quotes.map(async quote => {
						quote.unreadMessagesCount = quote.twilio_chatid ? await this.getUnreadCount(quote.twilio_chatid) : 0
						return quote
					}),
				)
				this.setState({ quotesData: [...oldQuotes, ...newQuotes] })
			}
		} else {
			let data = Object.assign({}, this.state.data)
			if (data?.quote?.twilio_chatid) {
				data.quote.unreadMessagesCount = await this.getUnreadCount(data.quote.twilio_chatid)
			}
			this.setState({ data: data })
		}
	}

	async getUnreadCount(chatId) {
		let conversation = await this.props.chatClientRef.current.getConversationBySid(chatId) // get conversation from twilio
		conversation.on('messageAdded', this.recieveMessage.bind(this)) // add new listener to the conversation

		this.conversationsRef.current[chatId] = conversation
		let message = await conversation.getMessages(1) // get the last message
		let count = await conversation.getUnreadMessagesCount()

		if (count === null) {
			// check if conversation not opened once (with no read messages - the read horizon is not set)
			if (!message?.items[0]?.author?.includes(this.state.profileType)) {
				// check if the last message is not send by current user
				count = message?.items.length
			} else {
				count = 0
			}
		}
		count = message?.items[0]?.author?.includes(this.state.profileType) ? 0 : count // set count as 0 if the last message is send by current user

		return count
	}

	showModal = (pilotData, quote_id) => {
		let body = {
			quote_id: quote_id,
		}
		this.props.createTransaction(body)
		this.setState({
			pilotData: pilotData,
			modalVisible: true,
		})
	}

	toggleDrawer = (id, data) => {
		this.setState(prevState => ({
			visible: !prevState.visible,
			quoteId: id,
			guestData: data,
		}))
	}

	handleCancel = modalType => {
		if (modalType === 'projectAllocate') {
			this.props.getProjectForConsumer({ consumer: this.state.projectId })
			this.props.getQuotes({ project_id: this.state.projectId, pageNumber: 1, pageSize: 5 })
		}
		this.setState({
			[modalType]: false,
		})
	}

	rejectBid = type => {
		let body = {
			amount: Number(this.state.data.total_fee),
			quoteId: this.state.quoteId,
			status: type,
			mixpanel_type: type === 'ACCEPTED' ? 'project_invitation_accepted' : 'project_invitation_rejected',
			projectData: this.props.pilotProjectData,
		}
		if (this.state.profileType === 'PILOT') {
			body.mixpanel_type = type === 'ACCEPTED' ? 'project_invitation_accepted' : 'project_invitation_rejected'
		} else {
			body.mixpanel_type = 'bid_rejected'
			body.consumerProjectData = this.props.consumerProjectData
		}
		this.props.updateQuote(body)
	}

	handleOk = () => {
		this.setState({
			modalVisible: false,
			addPayment: true,
		})
	}

	payNow = token => {
		let body = {
			bankToken: token,
			transactionId: this.state.transactionData.transaction_id,
			consumerProjectData: this.props.consumerProjectData,
			pilotData: this.state.pilotData,
			mixpanel_type: 'bid_accepted',
		}
		this.props.payment(body)
	}

	goToInvitePilots = () => {
		this.props.history.push({ pathname: '/project/list-pilots', state: { data: this.state.data, id: this.state.projectId } })
	}

	updateProjectState = projectState => {
		const projectData = this.state.data
		const body = {
			consumer_id: Number(projectData.consumer_id),
			pilot_id: Number(projectData.pilot_id),
			project_id: this.state.projectId,
			quote_id: projectData.quotation_id,
			projectData: this.props.pilotProjectData,
			mixpanel_type: projectState,
		}
		if (projectState === 'markComplete') {
			let body = {
				quote_id: projectData.quotation_id,
			}
			this.props.createTransaction(body)
			this.setState({
				pilotData: projectData.pilot,
				modalVisible: true,
			})
		} else if (projectState === 'markReadyToProcess' || projectState === 'conflictResolutionPreview' || projectState === 'conflictResolutionFinal') {
			if (this.state.data.isBoxSupported) {
				this.props[projectState](body)
			} else {
				if (this.state.checkPreviewData && this.state.checkUploadData) {
					this.props[projectState](body)
				} else {
					message.warning('You can Mark as done only after uploading both Preview and Complete data!')
				}
			}
		} else {
			this.props[projectState](body)
		}
	}

	currentFolderFlag = (event, folderType) => {
		this.setState({ [folderType]: event.id, [folderType + 'FolderName']: event.name })
	}

	editProjectDetails = () => {
		this.props.history.push({ pathname: '/project/create/' + this.state.projectId })
	}

	closeKnowMore = () => {
		this.setState({ knowMoreVisible: false })
	}

	openKnowMore = () => {
		this.setState({ knowMoreVisible: true })
	}

	reloadData = data => {
		this.setState({ data: data })
	}

	internalAction = type => {
		this.setState({ choiceOfAction: type })
		if (type === 'modify') this.setState({ modifyBtn: true })
		this.props.getCommercialCommision({ pilotFees: type === 'submit' ? 0 : this.state.data.quote.amount })
	}

	openMpuModal = type => {
		this.setState({ mpuModalOpenStatus: true, mpuType: type })
	}

	isUploading = () => {
		if (this.state.multiPartUploads[this.state.projectId]) {
			return this.state.multiPartUploads[this.state.projectId].status
		} else {
			return false
		}
	}

	changeInvitedPage = page => {
		this.setState({ currentInvitedPage: page })
		this.props.getQuotes({ project_id: this.state.projectId, pageNumber: page, pageSize: 5 })
	}

	downloadFolder = (folderType, mixpanelData) => {
		this.props.downloadBoxFolder(
			{
				folder_id: folderType === 'preview' ? this.state.checkPreviewData : this.state.checkUploadData,
				project_id: this.state.projectId,
				accessToken: this.state.boxCreds.accessToken,
			},
			mixpanelData,
		)
	}

	createQuote = values => {
		if (values.bidAmount.includes('.') || values.bidAmount.includes('-')) {
			return message.warning('Please input only positive whole numbers!')
		}
		if (this.state.choiceOfAction === 'modify') {
			const body = {
				amount: values.bidAmount,
				quoteId: this.state.data.quotation_id,
				status: this.state.data.quote.status,
				projectData: this.props.pilotProjectData,
				mixpanel_type: 'bid_modified',
			}
			this.props.modifyBid(body)
		} else if (this.state.data?.quote?.status === 'WITHDRAWN') {
			const body = {
				amount: values.bidAmount,
				quoteId: this.state.data.quotation_id,
				status: 'PENDING',
			}
			this.props.modifyBid(body)
		} else {
			const body = [
				{
					amount: values.bidAmount,
					consumer_id: this.state.data.consumer_id,
					project_id: this.state.projectId,
					projectData: this.props.pilotProjectData,
					mixpanel_type: 'bid_submitted',
				},
			]
			this.props.invitePilots(body)
		}
	}

	getCommercialCommision = () => {
		const val = this.bidAmountRef.current.input.value
		if (val) {
			if (val.includes('.') || val.includes('-')) {
				return message.warning('Please input only positive whole numbers!')
			}
			this.props.getCommercialCommision({ pilotFees: val })
		}
	}

	loadMoreData = () => {
		if (this.state.loading) return
		this.setState({ loading: true })
		this.changeInvitedPage(this.state.currentInvitedPage + 1)
	}

	openBidActionModal = (type, quoteId) => this.setState({ [type]: true, quoteId: quoteId })

	openPilotProfileModal = (pilot, bidAmount) => {
		this.setState({ pilotData: pilot })
		this.props.getPilotPortfolio({ [pilot.id]: 'portfolio' }, { mixpanel_type: 'pilot_profile_viewed', consumerProjectData: this.props.consumerProjectData, bidAmount })
	}

	openBidHistory = quoteId => {
		this.props.getBidHistory({ quote_id: quoteId })
	}

	cancelProject = () => {
		this.props.cancelProject({ projectId: this.state.projectId, consumerProjectData: this.props.consumerProjectData, mixpanel_type: 'project_cancelled' })
	}

	updateDisablity = () => {
		if (this.state.choiceOfAction === 'modify' && this.state.modifyBtn) {
			this.setState({ modifyBtn: false })
		}
	}

	withdrawBid = () => {
		const { data } = this.state
		const body = {
			amount: data.quote.amount,
			quoteId: data.quotation_id,
			status: 'WITHDRAWN',
			mixpanel_type: 'bid_withdrawn',
			projectData: this.props.pilotProjectData,
		}
		this.props.modifyBid(body)
	}

	boxTokenGenerator = () => {
		const duration = moment.duration(moment().diff(this.state.startTime))
		if (duration.asHours() >= 1) {
			return this.props
				.getBoxCreds({ data: this.state.projectId })
				.then(res => {
					this.setState({ startTime: moment() })
					return res?.value?.data?.accessToken
				})
				.catch(() => {
					this.setState({ startTime: moment() })
					return this.state.boxCreds.accessToken
				})
		} else return this.state.boxCreds.accessToken
	}

	handleVisiblityChange = isVisible => {
		const { showChat, profileType, selectedQuote } = this.state
		if (showChat) {
			if (!isVisible) {
				if (profileType === 'PILOT') {
					this.props.chatWindowPilotStatus({ open: false, quotationId: selectedQuote.id })
				}
				if (profileType === 'CONSUMER') {
					this.props.chatWindowConsumerStatus({ open: false, quotationId: selectedQuote.quote_id })
				}
			} else {
				if (profileType === 'PILOT') {
					this.props.chatWindowPilotStatus({ open: true, quotationId: selectedQuote.id })
				}
				if (profileType === 'CONSUMER') {
					this.props.chatWindowConsumerStatus({ open: true, quotationId: selectedQuote.quote_id })
				}
			}
		}
	}

	handleFileUpload = file => {
		const data = new FormData()
		data.append('file', file)
		data.append('projectId', this.state.projectId)
		this.props
			.uploadFlightLogFile(data)
			.then(() => {
				message.success('File Uploaded!')
				this.props.getProjectForPilot({ pilot: this.state.projectId })
			})
			.catch(err => console.log('Upload failed ', err))
	}

	customRequest = ({ onSuccess, onError, file }, index) => {
		this.handleFileUpload(file, index)
	}

	handleChange = info => {
		if (info.file.status === 'uploading') {
			return
		}
		if (info.file.status === 'done') {
			this.handleFileUpload(info.file.originFileObj)
		}
	}

	render() {
		const {
			data,
			quotesData,
			modalVisible,
			addPayment,
			transactionData,
			projectAllocate,
			pilotData,
			profileType,
			downloadUrlData,
			checkUploadData,
			paymentHistoryData,
			knowMoreVisible,
			projectTimelineData,
			internalActionModal,
			mpuModalOpenStatus,
			mpuType,
			totalInvited,
			boxCreds,
			checkUploadDataFolderName,
			projectId,
			commercialCommisionData,
			rejectBidModal,
			pilotProfileModal,
			photos,
			videos,
			bidHistoryData,
			bidHistoryModal,
			choiceOfAction,
			chatLoaded,
			showChat,
			selectedChatId,
			participantName,
			selectedChatBid,
			participantProfilePicture,
			cancelProjectModal,
			acceptBidModal,
			modifyBtn,
			disableSendMessage,
			feeContentModal,
			withdrawBidModal,
			configData,
			flightLogDeleteConfModal,
		} = this.state
		return (
			<PageVisibility onChange={this.handleVisiblityChange}>
				<Spin spinning={!chatLoaded && !configData}>
					<div style={{ padding: '20px' }}>
						{profileType === 'CONSUMER' && CONSUMER_ALERT_TEXT[data.state] ? (
							<Alert
								style={{ background: '#3487EB', textAlign: 'center', height: '58px', borderRadius: '12px' }}
								message={
									<div style={{ color: 'white' }}>
										{CONSUMER_ALERT_TEXT[data.state]}
										{data?.state === 'RESOLVED'
											? moment
													.utc(data['updated_at'])
													.local()
													.format('DD/MM/YYYY')
											: null}
									</div>
								}
								type='warning'
								closable
							/>
						) : (
							''
						)}
						{profileType === 'PILOT' &&
						(data?.quote?.status === 'ACCEPTED' || data?.quote?.status === 'COMPLETED' || data?.quote?.status === 'RESOLVED') &&
						PILOT_ALERT_TEXT[data.state] ? (
							<Alert
								style={
									data.state === 'DATA_REJECTED_FINAL'
										? { background: '#FF4D4F', textAlign: 'center', height: '58px', borderRadius: '12px' }
										: { background: '#3487EB', textAlign: 'center', height: '58px', borderRadius: '12px' }
								}
								message={<div style={{ color: 'white' }}>{PILOT_ALERT_TEXT[data.state]}</div>}
								type='warning'
								closable
							/>
						) : null}

						{profileType === 'PILOT' && !data.enterprise_project && data?.quote?.status === 'REJECTED' ? (
							<Alert
								style={{ background: '#FF4D4F', textAlign: 'center', height: '58px', borderRadius: '12px' }}
								message={<div style={{ color: 'white' }}>Your bid has been rejected. You can re-submit a bid if you wish to</div>}
								type='warning'
								closable
							/>
						) : null}

						{profileType === 'PILOT' && data?.quote?.status === 'INVALID' ? (
							<Alert
								style={{ background: '#FF4D4F', textAlign: 'center', height: '58px', borderRadius: '12px' }}
								message={<div style={{ color: 'white' }}>Sorry, the project got allocated to some other pilot.</div>}
								type='warning'
								closable
							/>
						) : null}

						{profileType === 'PILOT' && data?.quote?.status === 'WITHDRAWN' ? (
							<Alert
								style={{ background: '#FF4D4F', textAlign: 'center', height: '58px', borderRadius: '12px' }}
								message={
									<div style={{ color: 'white' }}>
										You’ve withdrawn your bid for this project. You can still submit a new bid for this project if you wish to.
									</div>
								}
								type='warning'
								closable
							/>
						) : (
							''
						)}
						<Button type='link' onClick={() => goTo('home')}>
							<MatImage src={IMAGES_SRC.BACK_BTN} style={{ marginTop: '10px', position: 'relative', right: '15px' }} />
						</Button>

						{profileType === 'PILOT' &&
						data?.quote?.twilio_chatid &&
						(data.enterprise_project ? data.enterprise_project && data.created_at >= moment(new Date('2023-03-20')).format() : true) ? (
							<Button
								icon={<MessageFilled style={{ fontSize: '18px', paddingBottom: '2px' }} />}
								onClick={() =>
									this.openChat(
										data.quote.twilio_chatid,
										data.enterprise_project ? 'Admin' : data.consumer.name,
										data.quote.amount,
										data.consumer.profilePicture,
										data?.state === 'RESOLVED' || data?.quote?.status === 'INVALID' || data?.quote?.status === 'WITHDRAWN' ? true : false,
										data?.quote,
									)
								}
								className='more-btn'
								style={{ marginLeft: '10px' }}
							>
								Chat &nbsp;{' '}
								<span style={{ width: '6px', minWidth: '6px' }}>
									{' '}
									<Badge dot={data?.quote?.unreadMessagesCount > 0 ? true : false} />{' '}
								</span>
							</Button>
						) : null}
						{profileType === 'CONSUMER' && data.state === 'ALLOCATE_PILOT' ? (
							<Popover
								trigger='click'
								placement='rightBottom'
								content={
									<>
										<Button onClick={() => goTo(`create-new-project/${projectId}`)} style={{ color: 'black' }} type='link'>
											<MatImage style={{ marginRight: '10px' }} src={IMAGES_SRC.MODIFY_ICON} alt='modify-btn' /> Modify Details
										</Button>

										<br />
										<Button onClick={() => this.setState({ cancelProjectModal: true })} style={{ color: 'black' }} type='link'>
											<MatImage style={{ marginRight: '10px' }} src={IMAGES_SRC.CANCEL_PROJECT} alt='cancel-btn' /> Cancel Project
										</Button>
									</>
								}
							>
								<Button style={{ width: '40px' }} className='more-btn'>
									<MatImage style={{ position: 'relative', right: '5px', bottom: '2px' }} src={IMAGES_SRC.MORE_VERTICAL} alt='more-btn' />
								</Button>
							</Popover>
						) : data.enterprise_project && data.quote.status === 'PENDING' ? (
							<>
								<Button className='more-btn' onClick={() => this.openBidActionModal('acceptBidModal', data.quote.id)}>
									<CheckOutlined style={{ position: 'relative', bottom: '3px' }} />
									Accept
								</Button>
								<Button style={{ marginRight: '15px' }} className='more-btn' onClick={() => this.openBidActionModal('rejectBidModal', data.quote.id)}>
									<CloseOutlined style={{ position: 'relative', bottom: '3px' }} /> Reject
								</Button>
							</>
						) : data.state === 'ALLOCATE_PILOT' && !data.quote && !data.enterprise_project ? (
							<Button onClick={() => this.internalAction('submit')} className='more-btn'>
								<MatImage style={{ marginRight: '10px' }} src={IMAGES_SRC.SUBMIT_BID_ICON} alt='submit-bid-btn' />
								Submit a Bid
							</Button>
						) : data.state === 'ALLOCATE_PILOT' && !data.enterprise_project && (data.quote.status === 'PENDING' || data.quote.status === 'REJECTED') ? (
							<Popover
								trigger='click'
								placement='rightBottom'
								content={
									<>
										<Button onClick={() => this.internalAction('modify')} style={{ color: 'black' }} type='link'>
											<MatImage style={{ marginRight: '10px' }} src={IMAGES_SRC.SUBMIT_BID_ICON} alt='submit-bid-btn' />
											Modify Bid
										</Button>
										{data.quote.status !== 'PENDING' ? null : (
											<>
												<br />
												<Button onClick={() => this.setState({ withdrawBidModal: true })} style={{ color: 'black' }} type='link'>
													<MatImage style={{ marginRight: '10px' }} src={IMAGES_SRC.WITHDRAW_SMALL_ICON} alt='withdraw-btn' /> Withdraw Bid
												</Button>
											</>
										)}
									</>
								}
							>
								<Button className='more-btn'>Bid Options</Button>
							</Popover>
						) : data?.quote?.status === 'WITHDRAWN' ? (
							<Button onClick={() => this.internalAction('submit')} className='more-btn'>
								<MatImage style={{ marginRight: '10px' }} src={IMAGES_SRC.SUBMIT_BID_ICON} alt='submit-bid-btn' />
								Submit a Bid
							</Button>
						) : null}

						{Object.keys(data).length ? <ProjectDescription data={data} openBidHistory={this.openBidHistory} profileType={profileType} /> : null}

						{data?.state !== 'ALLOCATE_PILOT' &&
						(data?.quote?.status === 'ACCEPTED' || data?.quote?.status === 'COMPLETED' || data?.quote?.status === 'RESOLVED') &&
						projectTimelineData &&
						projectTimelineData.length ? (
							<Card
								type='inner'
								title='Project Status'
								extra={
									<Button onClick={this.openKnowMore} type='link'>
										Know More
									</Button>
								}
							>
								<div style={{ padding: '30px' }}>
									<ProjectStatusSteps
										projectStateAction={this.updateProjectState}
										timeline={projectTimelineData}
										profileType={profileType}
										data={data}
										disapproveData={this.reloadData}
									/>
								</div>
							</Card>
						) : null}

						{profileType === 'PILOT' &&
						(((data.quote?.status === 'ACCEPTED' || data?.quote?.status === 'COMPLETED') && data.state === 'IN_FLIGHT') || data.state === 'DATA_REJECTED_FINAL') ? (
							<Card
								type='inner'
								style={{ marginTop: '20px' }}
								title={
									<>
										RTK/PPK Log File <span style={{ color: '#ABA8B0' }}>(Optional- Upload RTK/PPK Log File if the data is not georeferenced)</span>{' '}
									</>
								}
								extra={
									<Upload showUploadList={false} customRequest={this.customRequest} onChange={this.handleChange}>
										<Button style={{ color: '#00b3ff', border: '1px solid #00b3ff', width: '134px' }}>
											<UploadOutlined style={{ position: 'relative', bottom: '3px', fontSize: '16px' }} /> Upload Data
										</Button>
									</Upload>
								}
							>
								<div style={{ background: '#3487EB', color: 'white', borderRadius: '10px', textAlign: 'center', padding: '15px' }}>
									<InfoCircleFilled style={{ position: 'relative', bottom: '3px', right: '3px', fontSize: '18px' }} /> Please make sure to update RTK/PPK file if
									you are updating your project data.
								</div>
								{data.flight_log && data.flight_log !== 'DELETED' ? (
									<div style={{ marginTop: '20px', color: '#84818A' }}>
										<PaperClipOutlined style={{ position: 'relative', bottom: '3px', fontSize: '16px' }} /> RTK/PPK Log File:{' '}
										<Button href={data.flight_log} target='_blank' type='link'>
											<DownloadOutlined style={{ position: 'relative', bottom: '3px', fontSize: '16px' }} />
											Click to download
										</Button>{' '}
										<Button
											onClick={() => this.setState({ flightLogDeleteConfModal: true })}
											type='text'
											style={{ float: 'right', color: 'red', fontSize: '18px' }}
										>
											<DeleteOutlined style={{ position: 'relative', bottom: '3px' }} />
										</Button>
									</div>
								) : (
									<div style={{ textAlign: 'center', fontSize: '16px', padding: '30px', color: '#84818A' }}>No data has been uploaded yet</div>
								)}
							</Card>
						) : null}

						{profileType === 'CONSUMER' && Object.keys(data).length && data.state === 'ALLOCATE_PILOT' ? (
							<Card style={{ marginTop: '20px' }} type='inner' title='Received Bids'>
								{quotesData && quotesData.length ? (
									<>
										<div id='bidsDiv' style={{ overflow: 'auto', height: '25vh' }}>
											<InfiniteScroll
												dataLength={quotesData.length}
												next={() => this.loadMoreData()}
												hasMore={quotesData.length < totalInvited}
												loader={
													<Skeleton
														paragraph={{
															rows: 3,
														}}
														active
													/>
												}
												endMessage={<Divider></Divider>}
												scrollableTarget={'bidsDiv'}
											>
												<List
													dataSource={quotesData}
													renderItem={item => (
														<BidCard
															openPilotProfileModal={this.openPilotProfileModal}
															showModal={this.showModal}
															openBidActionModal={this.openBidActionModal}
															key={item.quote_id}
															data={item}
															type='received'
															openChat={this.openChat}
															projectState={data.state}
														/>
													)}
												/>
											</InfiniteScroll>
										</div>
									</>
								) : (
									<div style={{ color: '#84818A', textAlign: 'center' }}>You haven’t received any bid yet</div>
								)}
							</Card>
						) : null}

						{profileType === 'CONSUMER' && Object.keys(data).length && data.state !== 'ALLOCATE_PILOT' ? (
							<Card style={{ marginTop: '20px' }} type='inner' title='Accepted Bid'>
								{quotesData && quotesData.length ? (
									<BidCard
										openPilotProfileModal={this.openPilotProfileModal}
										showModal={this.showModal}
										openBidActionModal={this.openBidActionModal}
										data={quotesData[0]}
										type='accepted'
										openBidHistory={this.openBidHistory}
										openChat={this.openChat}
										projectState={data.state}
									/>
								) : (
									<div style={{ color: '#84818A', textAlign: 'center' }}>You haven’t received any bid yet</div>
								)}
							</Card>
						) : null}

						{profileType === 'CONSUMER' ? (
							data.state === 'COMPLETED' || data.state === 'RESOLVED' ? (
								<>
									{boxCreds && data.isBoxSupported ? (
										<BoxDownloader
											boxCreds={boxCreds}
											dataType={'Complete'}
											currentFolderFlag={this.currentFolderFlag}
											downloadFolder={this.downloadFolder}
											folderName={checkUploadDataFolderName}
											returnToken={this.boxTokenGenerator}
										/>
									) : (
										<S3Downloader dataType='COMPLETE' currentState={data.state} urlData={downloadUrlData} />
									)}
								</>
							) : null
						) : ((data.quote?.status === 'ACCEPTED' || data?.quote?.status === 'COMPLETED') && data.state === 'IN_FLIGHT') || data.state === 'DATA_REJECTED_FINAL' ? (
							<>
								{data && data.isBoxSupported && boxCreds ? (
									<BoxUploader
										boxCreds={boxCreds}
										dataType={'Complete'}
										currentFolderFlag={this.currentFolderFlag}
										downloadFolder={this.downloadFolder}
										folderName={checkUploadDataFolderName}
										projectData={this.props.pilotProjectData}
										returnToken={this.boxTokenGenerator}
									/>
								) : (
									<S3Uploader dataType={'Complete'} isUploading={this.isUploading} checkData={checkUploadData} handleOpenPicker={this.openMpuModal} />
								)}
							</>
						) : null}
						{data.enterprise_project &&
						profileType === 'PILOT' &&
						(data.quote?.status === 'ACCEPTED' || data?.quote?.status === 'COMPLETED' || data?.quote?.status === 'RESOLVED') ? (
							<Card style={{ marginTop: '20px' }} type='inner' title='Client Details'>
								<Descriptions colon={false}>
									<Descriptions.Item
										label={
											data.consumer.profilePicture ? (
												<div className='circular-header-dp'>
													<MatImage style={{ borderRadius: '50%', maxWidth: '32px' }} src={data.consumer.profilePicture} />
												</div>
											) : (
												<Avatar>{data.consumer.firstName.substring(0, 1)}</Avatar>
											)
										}
									>
										<span style={{ position: 'relative', top: '5px' }}>{data.consumer.name}</span>
									</Descriptions.Item>
								</Descriptions>
							</Card>
						) : null}

						{profileType === 'CONSUMER' && paymentHistoryData.length !== 0 ? <PaymentHistoryCard profileType={profileType} data={paymentHistoryData} /> : null}
						{profileType === 'PILOT' &&
						data.state === 'RESOLVED' &&
						(data.quote?.status === 'ACCEPTED' || data?.quote?.status === 'COMPLETED' || data?.quote?.status === 'RESOLVED') ? (
							<PaymentHistoryCard profileType={profileType} data={[data.pilot_transaction_data]} />
						) : null}

						{pilotData ? (
							<AeroModal visible={modalVisible} footer={false} onCancel={() => this.handleCancel('modalVisible')}>
								<div className='modal-content'>
									<MatImage src={IMAGES_SRC.ACCEPT_BID_ICON} />
									<h1>Allocate project to {pilotData.firstName}?</h1>
									<div className='line-3'>
										You're required to pay the bid amount now. Please note that this amount will be held by Aerologix and only be paid to the pilot once you
										approve the data within 7 days of data submission
									</div>
									<div className='modal-footer'>
										<Button style={{ height: '50px', width: '45%' }} onClick={() => this.handleCancel('modalVisible')}>
											Cancel
										</Button>
										<Button style={{ height: '50px', width: '50%' }} type='primary' onClick={this.handleOk}>
											Continue to Pay
										</Button>
									</div>
								</div>
							</AeroModal>
						) : null}
						<AeroModal title='Payment Option' footer={false} onCancel={() => this.handleCancel('addPayment')} visible={addPayment}>
							<Descriptions column={2} labelStyle={{ color: '#84818A' }}>
								<Descriptions.Item label='Project Name'>{data.name}</Descriptions.Item>
								<Descriptions.Item label='Bid Amount'>{configData?.currency + ' ' + transactionData.originalAmount}</Descriptions.Item>
								{transactionData.discount ? (
									<Descriptions.Item label={`Discount (${transactionData.discountLabel})`}>
										<span style={{ color: '#6CBE44' }}>{configData?.currency + ' ' + transactionData.discount}</span>
									</Descriptions.Item>
								) : null}
								<Descriptions.Item label='Amount to be paid'>{configData?.currency + ' ' + transactionData.amount}</Descriptions.Item>
							</Descriptions>
							<Divider></Divider>
							<StripePayment id='PAY_NOW' displayAmount={transactionData.amount} cancel={() => this.handleCancel('addPayment')} updateCardDetails={this.payNow} />
						</AeroModal>
						{pilotData ? (
							<AeroModal visible={projectAllocate} footer={false} onCancel={() => this.handleCancel('projectAllocate')}>
								<div className='modal-content'>
									<MatImage src={IMAGES_SRC.SUCCESS_ICON} />
									<h1>Payment Successful!</h1>
									<Divider></Divider>
									<div className='line-2'>Project has been allocated to {pilotData.firstName}</div>
									<div className='line-3'>You will get timely updates via email once Pilot starts doing the project.</div>
									<div style={{ textAlign: 'center', padding: '20px' }}>
										<Button onClick={() => this.handleCancel('projectAllocate')} type='primary' block style={{ height: '50px', borderRadius: '8px' }}>
											Okay, got it
										</Button>
									</div>
								</div>
							</AeroModal>
						) : null}
						<Modal style={{ minWidth: '800px' }} title='Project Status Details' visible={knowMoreVisible} onCancel={this.closeKnowMore} footer={false}>
							<div>
								<Table
									pagination={false}
									dataSource={data.enterprise_project ? enterpriseProjectStatusDetails : projectStatusDetails}
									columns={projectStatusDetailsColumns}
								/>
							</div>
						</Modal>
						<AeroModal visible={internalActionModal} footer={false} onCancel={() => this.handleCancel('internalActionModal')}>
							<div style={{ textAlign: 'center' }}>
								<MatImage src={IMAGES_SRC.SUBMIT_BID_HEAD_ICON} />
								<div>
									<h2 style={{ margin: '25px' }}>{choiceOfAction === 'submit' ? 'Submit' : 'Modify'} project Bid</h2>
									<div style={{ padding: '5px', color: '#84818A' }}>
										Indicate the amount you will charge the client for this job
										<br />
										Client will be notified about it.
										<div style={{ marginTop: '25px', marginLeft: '30px', textAlign: 'left' }}>
											Project Budget:{' '}
											<span style={{ color: 'black' }}>
												{data.total_fee === 0 && data.is_negotiable ? 'Negotiable' : configData?.currency + ' ' + data.total_fee}
											</span>
										</div>
									</div>

									<Form
										onFinish={this.createQuote}
										layout='vertical'
										style={{ padding: '30px' }}
										onFieldsChange={this.updateDisablity}
										initialValues={{
											bidAmount: choiceOfAction === 'modify' ? data.quote.amount : null,
										}}
									>
										<Form.Item
											style={{ marginBottom: '35px' }}
											label='Your Bid'
											name='bidAmount'
											rules={[{ required: true, whitespace: true, message: 'Please mention the bid amount!' }]}
										>
											<Input
												onChange={debounce(() => this.getCommercialCommision(), 500)}
												type='number'
												prefix={configData?.currency}
												placeholder='Type here'
												ref={this.bidAmountRef}
												style={{ borderRadius: '8px', height: '48px', width: '100%' }}
											></Input>
										</Form.Item>
										<Form.Item label='Your Earnings' name='earnings'>
											<>
												<Input
													disabled
													value={commercialCommisionData?.pilot_amount}
													placeholder='Type here'
													style={{ borderRadius: '8px', height: '48px', width: '100%' }}
												></Input>
												<div style={{ float: 'left', marginTop: '10px', position: 'relative', right: '10px' }}>
													<div>
														<span style={{ color: '#84818A' }}>Aerologix Commision: </span> {commercialCommisionData?.commission_percent}%
													</div>
													<div>
														<Button onClick={() => this.setState({ feeContentModal: true })} type='link'>
															Why Aerologix charges a fee
														</Button>
													</div>
												</div>
											</>
										</Form.Item>
										<div style={{ padding: '10px' }}>
											<Button style={{ height: '50px', width: '45%' }} onClick={() => this.handleCancel('internalActionModal')}>
												Cancel
											</Button>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<Button disabled={modifyBtn} htmlType='submit' style={{ height: '50px', width: '45%' }} type='primary'>
												Submit
											</Button>
										</div>
									</Form>
								</div>
							</div>
						</AeroModal>
						<AeroModal visible={rejectBidModal} footer={false} onCancel={() => this.handleCancel('rejectBidModal')}>
							<div style={{ textAlign: 'center' }}>
								<MatImage src={data.enterprise_project ? IMAGES_SRC.REJECT_INVITATION : IMAGES_SRC.REJECT_BID_ICON} />
								<div>
									<h2 style={{ margin: '25px' }}>Reject project {data.enterprise_project ? 'invitation' : 'Bid'}?</h2>
									<div style={{ padding: '5px', color: '#84818A' }}>
										{data.enterprise_project ? 'You will not be able to accept this project later and view project details' : 'Pilot will be notified about it'}
									</div>

									<div style={{ padding: '10px' }}>
										<Button style={{ height: '50px', width: '45%' }} onClick={() => this.handleCancel('rejectBidModal')}>
											Cancel
										</Button>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<Button onClick={() => this.rejectBid('REJECTED')} style={{ height: '50px', width: '45%' }} type='primary'>
											Reject
										</Button>
									</div>
								</div>
							</div>
						</AeroModal>
						<AeroModal visible={acceptBidModal} footer={false} onCancel={() => this.handleCancel('acceptBidModal')}>
							<div style={{ textAlign: 'center' }}>
								<MatImage src={IMAGES_SRC.ACCEPT_INVITATION} />
								<div>
									<h2 style={{ margin: '25px' }}>Accept project invitation?</h2>
									<div style={{ padding: '5px', color: '#84818A' }}>You will not be able to reject this project after this.</div>
									<div style={{ padding: '5px', color: '#84818A' }}>Payment for the project will be done once it’s completed </div>

									<div style={{ padding: '10px' }}>
										<Button style={{ height: '50px', width: '45%' }} onClick={() => this.handleCancel('acceptBidModal')}>
											Cancel
										</Button>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<Button onClick={() => this.rejectBid('ACCEPTED')} style={{ height: '50px', width: '45%' }} type='primary'>
											Accept
										</Button>
									</div>
								</div>
							</div>
						</AeroModal>

						<AeroModal title='Why Aerologix charges a fee?' visible={feeContentModal} footer={false} onCancel={() => this.handleCancel('feeContentModal')}>
							<p>
								To help Aerologix run smoothly and cover the costs of providing services that help you gain the most out of flying, we rely on fees. There’s a lot
								that goes into providing a marketplace and these fees help keep it running and growing. Some of the fees go towards:
							</p>
							<p>
								<ul>
									<li>Customer support</li>
									<li>Secure third-party payment systems</li>
									<li>Fraud protection for you and your work</li>
									<li>
										Marketing to potential clients via our different channels. These fees help us to continue improving our product and service experience for
										everyone in the marketplace, bringing you more opportunities to fly.
									</li>
								</ul>
							</p>
						</AeroModal>

						<AeroModal visible={cancelProjectModal} footer={false} onCancel={() => this.handleCancel('cancelProjectModal')}>
							<div style={{ textAlign: 'center' }}>
								<MatImage src={IMAGES_SRC.REJECT_BID_ICON} />
								<div>
									<h2 style={{ margin: '25px' }}>Do you want to cancel the project?</h2>

									<div style={{ padding: '10px' }}>
										<Button style={{ height: '50px', width: '45%' }} onClick={() => this.handleCancel('cancelProjectModal')}>
											Don't Cancel
										</Button>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<Button onClick={this.cancelProject} style={{ height: '50px', width: '45%' }} type='primary'>
											Cancel Project
										</Button>
									</div>
								</div>
							</div>
						</AeroModal>

						<AeroModal visible={withdrawBidModal} footer={false} onCancel={() => this.handleCancel('withdrawBidModal')}>
							<div style={{ textAlign: 'center' }}>
								<MatImage src={IMAGES_SRC.WITHDRAW_BIG_ICON} />
								<div>
									<h2 style={{ margin: '25px' }}>Do you want to withdraw your bid?</h2>
									<div style={{ padding: '5px', color: '#84818A' }}>Your chat with the client will be disabled once you withdraw your bid.</div>
									<div style={{ padding: '10px' }}>
										<Button type='primary' style={{ height: '50px', width: '45%' }} onClick={() => this.handleCancel('withdrawBidModal')}>
											Cancel
										</Button>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<Button onClick={this.withdrawBid} style={{ height: '50px', width: '45%' }}>
											Withdraw
										</Button>
									</div>
								</div>
							</div>
						</AeroModal>

						{pilotData ? (
							<AeroModal visible={pilotProfileModal} title='Pilot Profile' footer={false} onCancel={() => this.handleCancel('pilotProfileModal')}>
								<div style={{ textAlign: 'center' }}>
									<div style={{ margin: 'auto' }} className='circular'>
										{pilotData.profilePicture ? (
											<MatImage style={{ borderRadius: '50%', maxWidth: '100px' }} src={pilotData.profilePicture} />
										) : (
											<MatImage style={{ width: '100px', height: '100px' }} src={IMAGES_SRC.DP_PLACEHOLDER} />
										)}
									</div>
									<div>
										<h2 style={{ margin: '25px' }}>
											{capitalize(pilotData.firstName)} {pilotData.lastName}
										</h2>
										<div style={{ padding: '5px', color: '#84818A' }}>
											<MatImage alt='marker-icon' src={IMAGES_SRC.MARKER_ICON} />{' '}
											{pilotData.city ? pilotData.city : pilotData.state ? pilotData.state : 'Address not provided!'}
										</div>
										<div style={{ textAlign: 'left' }} className='line-3'>
											<strong style={{ color: '#595959' }}>ABOUT PILOT</strong> <br />
											{pilotData.aboutYou ? pilotData.aboutYou : '--'}
										</div>
										<div style={{ textAlign: 'left' }} className='line-3'>
											<strong style={{ color: '#595959' }}>PORTFOLIO</strong>
											{photos && photos.length !== 0 ? (
												<>
													<Divider />
													Images
													<Divider />
													<Image.PreviewGroup>
														{photos.map((item, index) => (
															<div className='thumbnail-wrapper'>
																<Image
																	style={{ float: 'left', width: '120px', height: '120px', objectFit: 'cover', borderRadius: '8px' }}
																	key={index}
																	src={item.thumbnails.smallThumbnail}
																	placeholder={true}
																	preview={{
																		src: item.file,
																		maskClassName: 'customize-mask',
																	}}
																/>
															</div>
														))}
													</Image.PreviewGroup>
												</>
											) : null}
											<Divider />
											<div>Video</div>
											<Divider />
											{videos && videos.length !== 0 ? (
												<div style={{ background: 'black', height: '130px', width: '130px', borderRadius: '8px' }}>
													<ReactPlayer
														ref='player'
														playing={true}
														controls={true}
														light={videos[0].thumbnails?.smallThumbnail}
														width={130}
														height={130}
														url={videos[0].file}
													/>
												</div>
											) : null}
											<Divider />
											<span style={{ color: '#8c8c8c' }}>Last Updated: </span>
										</div>
									</div>
								</div>
							</AeroModal>
						) : null}
						<AeroModal visible={bidHistoryModal} title='Bid History' footer={false} onCancel={() => this.handleCancel('bidHistoryModal')}>
							{bidHistoryData && bidHistoryData.length
								? bidHistoryData.map((item, index) => (
										<div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
											<span>
												{moment
													.utc(item.timeStamp)
													.local()
													.format('D MMM YYYY')}
											</span>
											<span>{configData?.currency + ' ' + item.amount}</span>
											<span>{item.status}</span>
										</div>
								  ))
								: null}

							<div style={{ textAlign: 'center', padding: '20px' }}>
								<Button onClick={() => this.handleCancel('bidHistoryModal')} type='primary' block style={{ height: '50px', borderRadius: '8px' }}>
									Okay, got it
								</Button>
							</div>
						</AeroModal>
						<AeroModal visible={flightLogDeleteConfModal} footer={false} onCancel={() => this.handleCancel('flightLogDeleteConfModal')}>
							<div style={{ textAlign: 'center' }}>
								<MatImage alt='info-icn' src={IMAGES_SRC.BIG_INFO_ICON} />
								<div style={{ fontSize: '20px', width: '70%', margin: 'auto', padding: '20px' }}>
									Are you sure you want to replace the existing RTK/PPK log file?
								</div>
								<div style={{ padding: '10px', display: 'flex', justifyContent: 'space-evenly' }}>
									<Button style={{ width: '169px', height: '50px' }} onClick={() => this.setState({ flightLogDeleteConfModal: false })}>
										Cancel
									</Button>
									<Button onClick={() => this.props.deleteFlightLogFile({ projectId: projectId })} style={{ width: '169px', height: '50px' }} type='primary'>
										Confirm
									</Button>
								</div>
							</div>
						</AeroModal>

						<MultiPartUpload
							modalOpenStatus={mpuModalOpenStatus}
							type={mpuType}
							projectId={this.state.projectId}
							closeHandler={() => this.handleCancel('mpuModalOpenStatus')}
						></MultiPartUpload>
						{chatLoaded && showChat ? (
							<ChatBox
								chatClientRef={this.props.chatClientRef}
								chatId={selectedChatId}
								userName={this.props.userName}
								participantName={participantName}
								profileType={profileType}
								projectData={this.props.pilotProjectData}
								consumerData={this.props.consumerProjectData}
								budget={data.total_fee}
								bidAmount={selectedChatBid}
								projectName={this.state.data?.name}
								closeChat={this.closeChat}
								participantProfilePicture={participantProfilePicture}
								userProfilePicture={this.props.profilePicture}
								disableSendMessage={disableSendMessage}
								selectedQuote={this.state.selectedQuote}
							></ChatBox>
						) : (
							''
						)}
					</div>
				</Spin>
			</PageVisibility>
		)
	}
}

const mapStateToProps = state => {
	return {
		quotesData: state.ProjectsReducer.quotesData,
		createTransactionData: state.ProjectsReducer.createTransactionData,
		paymentSuccess: state.ProjectsReducer.paymentSuccess,
		updateQuoteSuccess: state.ProjectsReducer.updateQuoteSuccess,
		uploadUrlData: state.ProjectsReducer.uploadUrlData,
		downloadUrlData: state.ProjectsReducer.downloadUrlData,
		previewUrlData: state.ProjectsReducer.previewUrlData,
		markInFlightSuccess: state.ProjectsReducer.markInFlightSuccess,
		markReadyToProcessSuccess: state.ProjectsReducer.markReadyToProcessSuccess,
		markCompleteSuccess: state.ProjectsReducer.markCompleteSuccess,
		markResolvedSuccess: state.ProjectsReducer.markResolvedSuccess,
		conflictResolutionPreviewSuccess: state.ProjectsReducer.conflictResolutionPreviewSuccess,
		conflictResolutionFinalSuccess: state.ProjectsReducer.conflictResolutionFinalSuccess,
		consumerProjectData: state.ProjectsReducer.consumerProjectData,
		pilotProjectData: state.ProjectsReducer.pilotProjectData,
		checkPreviewData: state.ProjectsReducer.checkPreviewData,
		checkUploadData: state.ProjectsReducer.checkUploadData,
		paymentHistoryData: state.ProjectsReducer.paymentHistoryData,
		projectTimelineData: state.ProjectsReducer.projectTimelineData,
		multiPartUploads: state.ProjectsReducer.multiPartUpload,
		boxCreds: state.ProjectsReducer.boxCreds,
		downloadBoxFolderUrl: state.ProjectsReducer.downloadBoxFolderUrl,
		invitePilotsSuccess: state.ProjectsReducer.invitePilotsSuccess,
		commercialCommisionData: state.ProjectsReducer.commercialCommisionData,
		pilotPortfolioData: state.UserSessionReducer.pilotPortfolioData,
		bidHistoryData: state.ProjectsReducer.bidHistoryData,
		modifyBidSuccess: state.ProjectsReducer.modifyBidSuccess,
		chatAccessToken: state.UserSessionReducer.chatAccessToken,
		cancelProjectSuccess: state.ProjectsReducer.cancelProjectSuccess,
		webConfig: state.UserSessionReducer.webConfig,
		flightLogFileDeleted: state.ProjectsReducer.flightLogFileDeleted,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getQuotes: bindActionCreators(getQuotes, dispatch),
		createTransaction: bindActionCreators(createTransaction, dispatch),
		payment: bindActionCreators(payment, dispatch),
		updateQuote: bindActionCreators(updateQuote, dispatch),
		getDownloadUrl: bindActionCreators(getDownloadUrl, dispatch),
		getPreviewUrl: bindActionCreators(getPreviewUrl, dispatch),
		markInFlight: bindActionCreators(markInFlight, dispatch),
		markReadyToProcess: bindActionCreators(markReadyToProcess, dispatch),
		markComplete: bindActionCreators(markComplete, dispatch),
		markResolved: bindActionCreators(markResolved, dispatch),
		conflictResolutionPreview: bindActionCreators(conflictResolutionPreview, dispatch),
		conflictResolutionFinal: bindActionCreators(conflictResolutionFinal, dispatch),
		getProjectForConsumer: bindActionCreators(getProjectForConsumer, dispatch),
		getProjectForPilot: bindActionCreators(getProjectForPilot, dispatch),
		checkUploadedPreview: bindActionCreators(checkUploadedPreview, dispatch),
		checkUploadedData: bindActionCreators(checkUploadedData, dispatch),
		getPaymentHistory: bindActionCreators(getPaymentHistory, dispatch),
		getProjectTimeline: bindActionCreators(getProjectTimeline, dispatch),
		getBoxCreds: bindActionCreators(getBoxCreds, dispatch),
		downloadBoxFolder: bindActionCreators(downloadBoxFolder, dispatch),
		invitePilots: bindActionCreators(invitePilots, dispatch),
		getCommercialCommision: bindActionCreators(getCommercialCommision, dispatch),
		getPilotPortfolio: bindActionCreators(getPilotPortfolio, dispatch),
		getBidHistory: bindActionCreators(getBidHistory, dispatch),
		modifyBid: bindActionCreators(modifyBid, dispatch),
		getChatAccessToken: bindActionCreators(getChatAccessToken, dispatch),
		cancelProject: bindActionCreators(cancelProject, dispatch),
		getWebConfig: bindActionCreators(getWebConfig, dispatch),
		chatWindowConsumerStatus: bindActionCreators(chatWindowConsumerStatus, dispatch),
		chatWindowPilotStatus: bindActionCreators(chatWindowPilotStatus, dispatch),
		uploadFlightLogFile: bindActionCreators(uploadFlightLogFile, dispatch),
		deleteFlightLogFile: bindActionCreators(deleteFlightLogFile, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Projects)
