import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { AeroModal } from '../../../../AeroComponents'
import { Player } from 'video-react'
import HLSSource from '../../../Multistream/HLSSource'
import styles from './modal.module.scss'

function LiveStreamModal(props) {

    // extract props
    const {
        liveStreamModal, closeLiveStreamModal, // props from parent
        getStreamDetailsData  // props from state
    } = props

    // state
    const [videoSrc, setVideoSrc] = useState(null)

    // set stream details to videoSrc
    const setStreamDetails = data => {
        let streamUrl = data.streamUrl
        let brokenUrl = streamUrl.split('//')
        streamUrl = '//' + brokenUrl[1] + '/' + data.secretKey
        setVideoSrc(streamUrl)
    }

    // update stream details if getStreamDetailsData got changed (from parent)
    useEffect(() => {
        if (getStreamDetailsData)
            setStreamDetails(getStreamDetailsData)
    }, [getStreamDetailsData])

    return (
        <AeroModal width={1000} visible={liveStreamModal} onCancel={closeLiveStreamModal} footer={false}>
            <div className={styles['player-wrapper']} >
                <Player width={950} height={700} fluid={false}>
                    {videoSrc ? <HLSSource isVideoChild src={videoSrc ? videoSrc : ''} /> : null}
                </Player>
            </div>
        </AeroModal>
    )
}

const mapStateToProps = state => {
    return {
        getStreamDetailsData: state.ProjectsReducer.getStreamDetailsData,
    }
}


export default connect(
    mapStateToProps,
)(LiveStreamModal)
