import React from 'react'
import { Card } from 'antd'
  


function AeroCard(props) {
    
    return (
        <Card {...props} >
            {props.children}
            </Card>
    )
}

export default AeroCard





