import React, { useEffect } from 'react'
import { Button, Card } from 'antd'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { DownloadOutlined } from '@ant-design/icons'
import { pushEventToMixpanel } from '../../../Services/MixpanelService'
const BoxUploader = props => {
	const { dataType, boxCreds, currentFolderFlag, downloadFolder, folderName, projectData, returnToken } = props
	useEffect(() => {
		const accessToken = boxCreds.accessToken
		if (dataType === 'Preview') {
			const previewFolderId = boxCreds.previewFolderId
			const previewExplorer = new window.Box.ContentExplorer()
			if (previewFolderId && accessToken) {
				previewExplorer.addListener('navigate', e => currentFolderFlag(e, 'checkPreviewData'))
				previewExplorer.show(previewFolderId, returnToken, {
					container: '.previewContainerBoxDev',
					logoUrl: IMAGES_SRC.LOGO_BLACK,
					canShare: false,
					contentUploaderProps: {
						fileLimit: 2500,
						isFolderUploadEnabled: true,
					},
				})
			}
		} else {
			const dataFolderId = boxCreds.dataFolderId
			const dataExplorer = new window.Box.ContentExplorer()
			if (dataFolderId && accessToken) {
				dataExplorer.addListener('navigate', e => currentFolderFlag(e, 'checkUploadData'))
				dataExplorer.addListener('upload', e => {
					// Sending Data Upload event to Mixpanel
					pushEventToMixpanel('', 'Data Upload', {
						Timestamp: new Date().getTime(),
						'Numbers Of Files Uploaded': e.length,
						'Were The Files Uploaded Successfully': true,
						'Project ID': projectData.projectId,
						'Project Type': projectData.order_id ? 'Enterprise' : 'Commercial',
					})
				})
				dataExplorer.show(dataFolderId, returnToken, {
					container: '.dataContainerBoxDev',
					logoUrl: IMAGES_SRC.LOGO_BLACK,
					canShare: false,
					contentUploaderProps: {
						fileLimit: 2500,
						isFolderUploadEnabled: true,
					},
				})
			}
		}
	}, [dataType, boxCreds])

	const callDownloadUrl = type => downloadFolder(type)
	return (
		<Card
			style={{ marginTop: '20px' }}
			type='inner'
			title='Project Data'
			extra={
				<Button style={{ color: '#00b3ff', border: '1px solid #00b3ff' }} onClick={() => callDownloadUrl(dataType.toLowerCase())}>
					<DownloadOutlined style={{ position: 'relative', bottom: '3px' }} /> Download {folderName} folder
				</Button>
			}
		>
			<div className={dataType === 'Preview' ? 'previewContainerBoxDev' : 'dataContainerBoxDev'}></div>
		</Card>
	)
}

export default BoxUploader
