/*eslint-disable*/
import React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button } from 'antd'
import { COMPANY_DETAILS_FIELDS_AU, COMPANY_DETAILS_FIELDS_NZ } from '../../../AeroComponents/FormFields/Metadata/company-details.fields'
import AeroField from '../../../AeroComponents/FormFields'
import { getConfigData } from '../../../Services/LocalStorageService'

class CompanyDetailsForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			fieldsToRender: getConfigData().countryId === 1 ? COMPANY_DETAILS_FIELDS_AU(props.viewMode) : COMPANY_DETAILS_FIELDS_NZ(props.viewMode),
			layout: 'vertical',
		}
	}

	componentDidMount() {
		if (this.props.viewMode) {
			if (this.props.data && this.props.data.corporateDetails) {
				const { companyAddress, companyName, businessNumber, acnNumber, gstNumber } = this.props.data.corporateDetails
				this.props.form.setFieldsValue({
					companyAddress,
					companyName,
					gstNumber,
					abnNumber: businessNumber,
					acnNumber,
				})
			}
		}
	}

	handleSubmit = e => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const data = {
					corporateDetails: {
						companyAddress: values.companyAddress,
						companyName: values.companyName,
						gstNumber: values.gstNumber,
						businessNumber: values.abnNumber,
						acnNumber: values.acnNumber,
					},
					mixpanel_type: 'company_details',
				}
				this.props.updateCompanyDetails(data)
			}
		})
	}

	render() {
		const { fieldsToRender } = this.state
		return (
			<Form layout={this.state.layout} onSubmit={this.handleSubmit}>
				<AeroField fieldsToRender={fieldsToRender} {...this.props} />
				{this.props.viewMode ? null : (
					<Button block type='primary' htmlType='submit' style={{ height: '48px', float: 'right', fontSize: '16px', width: '240px' }}>
						Save Details
					</Button>
				)}
			</Form>
		)
	}
}

const CompanyDetails = Form.create({ name: 'COMPANY_DETAILS_FORM' })(CompanyDetailsForm)

export default CompanyDetails
