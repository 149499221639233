
import React from 'react'
import styles from './DetailsModal.module.scss'
import AeroModal from '../../../../../AeroComponents/Modal'
import { Button } from "antd";

function LearnMoreModal(props) {

  // extract props
  const {
    setShowLearnMoreModal, // from parent  
    showLearnMoreModal
  } = props


  return (
    <AeroModal
      bodyStyle={{ backgroundColor: '#F5F5F5', borderRadius: '0px 0px 12px 12px' }}
      title="Learn More" footer={false} onCancel={() => { setShowLearnMoreModal(false) }} visible={showLearnMoreModal}>
      <div className={styles['details-modal']}  >


        <div>
          <span className={styles['bold-text']} > Pending File Submission:  </span> Waiting for you to upload Video/ Image file, add all associated metadata and send Submission to Aerologix
        </div>
        <br />

        <div>
          <span className={styles['bold-text']} > Under Aerologix Review:  </span> Video/ Image file and associated metadata cleared Aerologix review and successfully submitted to Getty for review
        </div>
        <br />

        <div>
          <span className={styles['bold-text']} > Under Getty Review:  </span> Video/ Image file and associated metadata cleared Aerologix review and successfully submitted to Getty for review
        </div>
        <br />

        <div>
          <span className={styles['bold-text']} > Published:  </span> Video/ Image file and associated metadata has been accepted and successfully published on the Getty Platform
        </div>
        <br />

        <div>
          <span className={styles['bold-text']} > Revise and Retry:  </span> Your Video/ Image needs some changes before it can be published, Aerologix is reviewing the requested changes
        </div>
        <br />

        <div>
          <span className={styles['bold-text']} > Rejected:  </span> Aerologix/ Getty has determined that the uploaded Video/ Image file cannot be published or listed on the Getty Platform. Please do not re-upload a Rejected Video/ Image file
        </div>
        <br />


        <br></br>
        <Button block className={styles['button']} type='primary' onClick={() => { setShowLearnMoreModal(false) }}>Okay, Got it</Button>

      </div>


    </AeroModal>
  )
}

export default LearnMoreModal;