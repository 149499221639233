/*eslint-disable*/
import React from 'react'
import { IMAGES_SRC } from '../../../AeroComponents/Image/image.constants'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Checkbox } from 'antd'
import ProjectTypeSelect from '../../Projects/Components/project-type-select'

const PROJECT_TYPE_FOR_SINGLE = [
	{ title: 'Images', src: IMAGES_SRC.PHOTOS_HOME_ICON, value: 'PHOTOS' },
	{ title: 'Videos', src: IMAGES_SRC.VIDEOS_HOME_ICON, value: 'VIDEOS' },
	{ title: 'Images and Videos', src: IMAGES_SRC.PHOTOS_VIDEOS_HOME_ICON, value: 'PHOTOS_AND_VIDEOS' },
	{ title: 'Tower Inspection', src: IMAGES_SRC.AEROPATH_PROJECT, value: 'AEROPATH' },
]
export default class SelectProjectType extends React.Component {
	render() {
		const { getFieldDecorator } = this.props.form
		return (
			<div style={{ overflow: 'auto' }}>
				<Form.Item>
					{getFieldDecorator('projectTypes', {
						rules: [
							{
								required: true,
								message: 'Please select a project type!',
							},
						],
						initialValue: this.props.data.projectType,
					})(
						<Checkbox.Group>
							{PROJECT_TYPE_FOR_SINGLE.map((data, index) => (
								<div key={index} className='project-card-container'>
									{/* <div style={{ height: '0px', marginLeft: '19px' }}>
												<div style={{ background: 'red', color: 'white', borderRadius: '3px', width: '120px', paddingLeft: '8px' }}>COMING SOON</div>
											</div> */}
									<div className='project-type-select'>
										<Checkbox value={data.value}>
											<ProjectTypeSelect icon={data.src} title={data.title} />
										</Checkbox>
									</div>
								</div>
							))}
						</Checkbox.Group>,
					)}
				</Form.Item>

			</div>
		)
	}
}
