import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Empty, Row, Divider, Card } from 'antd'
import React from 'react'
import MatImage from '../../../../AeroComponents/Image'
import { IMAGES_SRC } from '../../../../AeroComponents/Image/image.constants'
import AccountCard from '../account-card'

const BankDetailsTab = props => {
	const { creditCardData, allBankAccounts, makeDefault, deleteAccount, getStripeUrl } = props

	return (
		<>
			{allBankAccounts && allBankAccounts.length ? (
				<>
					<Button style={{ height: '48px', marginBottom: '20px' }} type='primary' onClick={getStripeUrl}>
						<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} />
						Add Bank Account
					</Button>
					<div style={{ color: '#8C8C8C', margin: '0px 20px 20px', fontWeight: 700 }}>Bank Details</div>
					<Row>
						{allBankAccounts.map(data => (
							<AccountCard
								key={data.id}
								accountDetails={data}
								makeDefault={() => {
									makeDefault(data.id)
								}}
								deleteAccount={() => {
									deleteAccount(data.id)
								}}
							/>
						))}
					</Row>
				</>
			) : (
				<>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No Bank account has been added yet' />
					<div style={{ textAlign: 'center' }}>
						<Button style={{ height: '48px', marginBottom: '20px' }} type='primary' onClick={getStripeUrl}>
							<PlusCircleOutlined style={{ position: 'relative', bottom: '3px' }} />
							Add Bank Account
						</Button>
					</div>
				</>
			)}
			<Divider></Divider>
			{creditCardData && creditCardData.length > 0 ? (
				<>
					<div style={{ color: '#8C8C8C', margin: '30px 20px', fontWeight: 700 }}>Credit Card</div>

					<Row>
						{creditCardData.map(data => (
							<Card size='small' className='account-card'>
								<p>
									<strong>{data.creditCard}</strong>
								</p>
								<p>{data.nameOnCard}</p>
							</Card>
						))}
					</Row>
				</>
			) : (
				''
			)}
			<div style={{ color: '#8C8C8C', textAlign: 'center', margin: '30px' }}>
				PAYMENTS POWERED BY <MatImage style={{ padding: '5px', marginTop: '-3px' }} src={IMAGES_SRC.STRIPE_LOGO} />
			</div>
		</>
	)
}

export default BankDetailsTab
